import { collection, type Reference, type Timestamp } from "@doitintl/models-types";

import { type AssetModel } from "./Asset";
import {
  type CommitmentPeriod,
  type ContractCredits,
  type ContractFile,
  type ContractModel,
  type DiscountPeriod,
  type UpdatedBy,
} from "./Contract";
import { type CustomerModel } from "./Customer";
import { type EntityModel } from "./Entity";

@collection("vendorContracts")
export class VendorContractModel {
  active!: boolean;

  assets?: Reference<AssetModel>[];

  commitmentPeriods?: CommitmentPeriod[];

  discountPeriods?: DiscountPeriod[];

  commitmentType!: VendorCommitmentType;

  contractFile!: ContractFile;

  credits?: ContractCredits;

  customer!: Reference<CustomerModel>;

  customerContract!: Reference<ContractModel>;

  discount!: number;

  endDate!: Timestamp;

  entity!: Reference<EntityModel> | null;

  startDate!: Timestamp;

  terminated!: boolean;

  timeCreated!: Timestamp;

  timestamp!: Timestamp;

  updatedBy!: UpdatedBy;

  vendor!: VendorType;
}

export type VendorCommitmentType = "B2B" | "B2B lite" | "PPA";

export type VendorType = "amazon-web-services" | "google-cloud";
