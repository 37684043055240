import { type DateObjectUnits } from "@doitintl/cmp-models";

export function isDateObjectUnits(value: unknown): value is DateObjectUnits {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  const { year, month, day, weekday, hour, minute } = value as Partial<DateObjectUnits>;

  for (const field of [year, month, day, weekday, hour, minute]) {
    if (field !== undefined && (typeof field !== "number" || isNaN(field))) {
      return false;
    }
  }

  return true;
}
