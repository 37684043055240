import { createContext, type Dispatch, type SetStateAction, useContext, useMemo, useState } from "react";

import { useParams } from "react-router-dom";
import { CloudFlowNodeType, type NodeConfigApiParametersBase } from "@doitintl/cmp-models";
import { type UseMutateFunction, useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";

import { useApiContext } from "../../../../api/context";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../utils";
import { testNode } from "../../api";
import { useTrackCloudflowEvent } from "../../hooks";
import { isTestNodeErrorResponse, parseResponse, TestNodeError } from "../utils/testUtils";
import { useNodeConfigurationContext } from "./NodeConfigurationContext";

type TestNodeState = {
  state: {
    data: any;
    status: string | number;
    isError: boolean;
  };
  mutate: UseMutateFunction;
  isLoading: boolean;
  testInput: TestInput;
  setTestInput: Dispatch<SetStateAction<TestInput>>;
  testInputValid: boolean;
  setTestInputValid: Dispatch<SetStateAction<boolean>>;
};

type TestInput = Pick<NodeConfigApiParametersBase, "formValues" | "configurationValues">;

const TestNodeContext = createContext<TestNodeState>({
  state: {
    data: undefined,
    status: "",
    isError: false,
  },
  mutate: () => {},
  isLoading: false,
  testInput: { formValues: {}, configurationValues: {} },
  setTestInput: () => {},
  testInputValid: false,
  setTestInputValid: () => {},
});

export const TestNodeProvider = ({ children }) => {
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const track = useTrackCloudflowEvent();

  const [testInput, setTestInput] = useState<TestInput>({
    formValues: {},
    configurationValues: {},
  });

  const [testInputValid, setTestInputValid] = useState(false);

  const [state, setState] = useState<{ data: any; status: string | number; isError: boolean }>({
    data: undefined,
    status: "",
    isError: false,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const res = await testNode(api, customer.id, {
        cloudflowId: flowId,
        nodeId: nodeConfig.id,
        input: {
          params: {
            ...testInput,
            provider: nodeConfig.parameters.provider,
            operation: nodeConfig.parameters.operation,
          },
        },
      });

      return parseResponse(res.data);
    },
    onSuccess: (data) => {
      setState({
        data: data.responseMessage,
        status: data.status,
        isError: data.isError,
      });

      const { id, service } = nodeConfig.parameters.operation;

      track("Node Tested", {
        flowId,
        apiName: `${service}.${id}`,
        apiProvider: nodeConfig.parameters.provider,
        nodeType: CloudFlowNodeType.ACTION,
        testSuccessful: true,
      });
    },
    onError: (e) => {
      consoleErrorWithSentry(e);

      if (isAxiosError(e)) {
        // server responded with a status code
        if (e.response) {
          const { data, status } = e.response;

          if (isTestNodeErrorResponse(data)) {
            setState({ data: data.error, isError: true, status });
            return;
          }
        }

        setState({
          data: e.message,
          isError: true,
          status: e.status ?? "500",
        });

        return;
      }

      if (e instanceof TestNodeError) {
        setState({
          data: e.message,
          isError: true,
          status: e.status,
        });

        return;
      }

      setState({
        data: undefined,
        isError: true,
        status: "Unknown",
      });

      track("Node Tested", {
        flowId,
        apiName: nodeConfig.parameters.operation.id,
        apiProvider: nodeConfig.parameters.provider,
        nodeType: CloudFlowNodeType.ACTION,
        testSuccessful: false,
      });
    },
  });

  const value = useMemo(
    () => ({ state, mutate, isLoading, testInput, setTestInput, testInputValid, setTestInputValid }),
    [state, mutate, isLoading, testInput, setTestInput, testInputValid, setTestInputValid]
  );

  return <TestNodeContext.Provider value={value}>{children}</TestNodeContext.Provider>;
};

export const useTestNode = () => useContext(TestNodeContext);
