import { useEffect, useState } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";

export const useIsABTesting = () => {
  const [supportData] = useDocumentDataOnce(getCollection(AppModel).doc("support"));

  return (supportData?.abTestingCounter ?? 0) % 2 === 0;
};

// Pass customerID as a parameter, because sometimes it is used outside the CustomerContext like the MPA Ops page
// Allow optional prop for cases where customerID may be undefined
export const useIsPLESCustomer = (customerId?: string) => {
  const [exists, setExists] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!customerId) {
        setExists(false);
        return;
      }

      // TODO: rename this collection. It's "PLES", not "PLPS". PLPS is a GCP contract.
      const ref = getCollection(AppModel).doc("support").collection("plpsCustomers").doc(customerId);

      const doc = await ref.get();

      setExists(doc.exists());
    })();
  }, [customerId]);

  return exists;
};
