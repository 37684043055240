import { type ReactNode, useEffect } from "react";

import { ModelReference } from "@doitintl/models-firestore";
import { Formik } from "formik";
import isEqualWith from "lodash/isEqualWith";
import type * as Yup from "yup";

import { useErrorSnackbar } from "../../SharedSnackbar/SharedSnackbar.context";
import { type CreateBillingProfileData } from "../BillingProfileForm.models";
import { AddressPanel } from "./panels/AddressPanel";
import { ContactPanel } from "./panels/ContactPanel";
import { GeneralPanel } from "./panels/GeneralPanel";
import { PaymentPanel } from "./panels/PaymentPanel";

export type BillingFormSubmitFn<BillingProfileData extends CreateBillingProfileData> = (
  values: BillingProfileData
) => Promise<void>;
export type PristineStateChangeFn = (isPristine: boolean) => void;

const ErrorsSnackbar = ({ isValid, submitCount }: { isValid: boolean; submitCount: number }) => {
  const errorSnackbar = useErrorSnackbar();
  useEffect(() => {
    !isValid && errorSnackbar("Please provide all required information.");
  }, [errorSnackbar, isValid, submitCount]);
  return null;
};

function refSafeComparator(value, other) {
  if (value instanceof ModelReference && other instanceof ModelReference) {
    return value.path === other.path;
  }
}

type BillingFormProps<BillingProfileData extends CreateBillingProfileData> = {
  onSubmit: BillingFormSubmitFn<BillingProfileData>;
  onPristineStateChange?: PristineStateChangeFn;
  initialValues: BillingProfileData;
  inEditMode?: boolean;
  children?: ReactNode;
  generalPanelChildren?: ReactNode;
  paymentsPanelChildren?: ReactNode;
  validationSchema: Yup.AnyObjectSchema;
};

export const BillingForm = <BillingProfileData extends CreateBillingProfileData = CreateBillingProfileData>({
  onSubmit,
  onPristineStateChange,
  initialValues,
  inEditMode,
  children,
  generalPanelChildren,
  paymentsPanelChildren,
  validationSchema,
}: BillingFormProps<BillingProfileData>) => {
  const editMode = inEditMode ?? false;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue, isValid, submitCount }) => {
        onPristineStateChange?.(isEqualWith(initialValues, values, refSafeComparator));
        return (
          <form
            noValidate
            id="billingForm"
            onSubmit={(event) => {
              handleSubmit();
              event?.preventDefault();
            }}
          >
            <AddressPanel
              handleChange={handleChange}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              inEditMode={editMode}
            />
            <GeneralPanel handleChange={handleChange} values={values} setFieldValue={setFieldValue} errors={errors}>
              {generalPanelChildren}
            </GeneralPanel>
            <ContactPanel handleChange={handleChange} values={values} errors={errors} />
            <PaymentPanel values={values} setFieldValue={setFieldValue} errors={errors} inEditMode={editMode}>
              {paymentsPanelChildren}
            </PaymentPanel>
            {children}
            <ErrorsSnackbar isValid={isValid} submitCount={submitCount} />
          </form>
        );
      }}
    </Formik>
  );
};
