import { useState } from "react";

import { CardContent, Stack } from "@mui/material";
import { DataGrid, type GridColDef, type GridRowsProp } from "@mui/x-data-grid";

import { useWidgetTablePageSize } from "../useWidgetTablePageSize";

type Props = {
  height: number;
  rows: GridRowsProp;
  columns: GridColDef[];
};

export function WidgetCardContentWithTable({ height, rows, columns }: Props) {
  const [page, setPage] = useState<number>(0);

  const pageSize = useWidgetTablePageSize(height);

  return (
    <CardContent
      sx={{
        height,
        p: 0,
        "&.MuiCardContent-root": {
          p: 0,
        },
      }}
    >
      <Stack
        direction={"column"}
        sx={{
          height: "100%",
          width: "100%",
          p: 1,
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns.map((column) => ({
            ...column,
            sortable: false,
          }))}
          pageSize={pageSize}
          pagination
          page={page}
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          disableSelectionOnClick
          disableColumnMenu
          density="compact"
          sx={{
            border: "none",
            flexGrow: 1,
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              "& .MuiDataGrid-columnHeader": {
                outline: "none",
                borderRight: "none",
              },
            },
            "& .MuiDataGrid-cell": {
              fontSize: "14px",
            },
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
          }}
          rowsPerPageOptions={[5]}
        />
      </Stack>
    </CardContent>
  );
}
