import illustration from "../../../assets/GKE-empty-illustration.svg";
import { lensEnticementTexts } from "../../../assets/texts";
import { LensEnticement } from "../../LensEnticement/LensEnticement";
import { type EnticementState } from "../../LensEnticement/types";
import useFetchAmTamEmail from "../../NoEntitlement/hooks";
import { ContactSalesButton } from "../../NoEntitlement/NoEntitlement";

export const MAPLensEnticement = ({ state }: { state: EnticementState }) => {
  const { accountManagers, loading } = useFetchAmTamEmail();

  return (
    <LensEnticement
      image={illustration}
      title={lensEnticementTexts.AWS_MAP_LENS_TITLE}
      listItems={lensEnticementTexts.AWS_MAP_LENS_LIST}
      actionButton={state === "upgrade" ? <ContactSalesButton sendTo={accountManagers} loading={loading} /> : undefined}
    />
  );
};
