import {
  type ApprovalConfig,
  type CloudFlowNodeType,
  NotificationProviders,
  type SlackChannel,
} from "@doitintl/cmp-models";
import LockIcon from "@mui/icons-material/Lock";
import {
  Alert,
  alpha,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { getIn, useFormikContext } from "formik";

import { useCustomerId } from "../../../../../../Components/hooks/useCustomerId";
import { useNodeConfigurationContext } from "../../NodeConfigurationContext";

type Props = {
  loadingSlackChannels: boolean;
  slackChannels: SlackChannel[];
};

type SlackNotificationProviderProps = Omit<Props, "onUpdateConfig"> & {
  slackChannelId?: string;
  onSlackChannelChange: (e: SelectChangeEvent<string>) => void;
};

const SlackNotificationProvider = ({
  loadingSlackChannels,
  slackChannels,
  slackChannelId,
  onSlackChannelChange,
}: SlackNotificationProviderProps) => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { recipient } = nodeConfig.approval || {};
  const customerId = useCustomerId();
  const theme = useTheme();

  const { errors } = useFormikContext<ApprovalConfig>();
  const slackChannelError = getIn(errors, "recipient.slackChannels");

  const isSlackConfigured = !loadingSlackChannels && slackChannels.length > 0;

  const selectedSlackChannel =
    recipient?.notificationProvider === NotificationProviders.SLACK ? recipient.slackChannels?.[0] : null;
  const isPublicChannel = selectedSlackChannel?.type === "public" || false;

  const groupedChannels = slackChannels.reduce(
    (acc, channel) => {
      const { type } = channel;
      if (type === "public") {
        acc.public.push(channel);
      } else if (type === "private") {
        acc.private.push(channel);
      }
      return acc;
    },
    { public: [] as SlackChannel[], private: [] as SlackChannel[] }
  );

  return (
    <Stack spacing={2}>
      {!loadingSlackChannels && slackChannels.length === 0 && (
        <Alert severity="warning">
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: 400,
              }}
            >
              Configure Slack integration to select your Slack channels
            </Typography>
            <Link
              color={alpha(theme.palette.warning.light, 0.15)}
              underline="hover"
              href={`/customers/${customerId}/integrations/slack`}
              target="_blank"
            >
              Configure
            </Link>
          </Stack>
        </Alert>
      )}
      <FormControl fullWidth required size="small" disabled={!isSlackConfigured}>
        <InputLabel error={Boolean(slackChannelError)}>Slack channel</InputLabel>
        <Select
          name="slackChannelId"
          label="Slack channel"
          value={slackChannelId || ""}
          onChange={onSlackChannelChange}
          error={Boolean(slackChannelError)}
          displayEmpty
          renderValue={(value) => {
            const item = slackChannels.find(({ id }) => id === value);
            return <Typography>{item?.name}</Typography>;
          }}
        >
          {loadingSlackChannels ? (
            <MenuItem disabled>Loading...</MenuItem>
          ) : (
            [
              <Typography key="private" variant="subtitle2" color="text.secondary" pl={1} py={1}>
                Private channels
              </Typography>,
              groupedChannels.private.map((channel) => (
                <MenuItem key={channel.id} value={channel.id}>
                  <LockIcon fontSize="small" sx={{ color: "text.primary" }} />
                  <Typography variant="body1" pl={0.5}>
                    {channel.name}
                  </Typography>
                </MenuItem>
              )),
              <Typography key="public" variant="subtitle2" color="text.secondary" pl={1} py={1}>
                Public channels
              </Typography>,
              groupedChannels.public.map((channel) => (
                <MenuItem key={channel.id} value={channel.id}>
                  <Typography variant="body1">{`# ${channel.name}`}</Typography>
                </MenuItem>
              )),
            ]
          )}
        </Select>
        {slackChannelError !== undefined && <FormHelperText error={true}>{slackChannelError}</FormHelperText>}
      </FormControl>
      {isPublicChannel && (
        <Alert severity="warning">
          {selectedSlackChannel?.name} is a public Slack channel, which means anybody in your workspace can join. To
          ensure approvals are secure, we recommend using private channels.
        </Alert>
      )}
    </Stack>
  );
};

export const NotificationProvider = ({ loadingSlackChannels, slackChannels }: Props) => {
  const { updateNode } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { values, errors } = useFormikContext<ApprovalConfig>();

  const emailError = getIn(errors, "recipient.emails");

  const getEmailValue = () => {
    if (values.recipient?.notificationProvider === NotificationProviders.EMAIL) {
      return values.recipient.emails?.[0] || "";
    }
    return "";
  };

  const getSlackChannelId = () => {
    if (values.recipient?.notificationProvider === NotificationProviders.SLACK) {
      return values.recipient.slackChannels?.[0]?.id || "";
    }
    return "";
  };

  return (
    <Stack
      spacing={3}
      sx={{
        pt: 1,
        pb: 1,
      }}
    >
      <FormControl fullWidth required size="small">
        <InputLabel>Notification provider</InputLabel>
        <Select
          name="notificationProvider"
          label="Notification provider"
          value={values.recipient.notificationProvider || ""}
          error={Boolean(errors.recipient?.notificationProvider)}
          onChange={(e) => {
            updateNode((prevNode) => ({
              approval: {
                ...prevNode.approval!,
                recipient: {
                  ...(e.target.value === NotificationProviders.EMAIL
                    ? {
                        notificationProvider: NotificationProviders.EMAIL,
                        emails: [],
                      }
                    : {
                        notificationProvider: NotificationProviders.SLACK,
                        slackChannels: [],
                      }),
                },
              },
            }));
          }}
        >
          {Object.values(NotificationProviders).map((provider: string) => (
            <MenuItem key={provider} value={provider}>
              {provider.charAt(0).toUpperCase() + provider.slice(1)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {values.recipient.notificationProvider === NotificationProviders.SLACK && (
        <SlackNotificationProvider
          loadingSlackChannels={loadingSlackChannels}
          slackChannels={slackChannels}
          slackChannelId={getSlackChannelId()}
          onSlackChannelChange={(e) => {
            const selectedChannel = slackChannels.find((channel) => channel.id === e.target.value);
            updateNode((prevNode) => ({
              approval: {
                ...prevNode.approval!,
                recipient: {
                  notificationProvider: NotificationProviders.SLACK,
                  slackChannels: selectedChannel ? [selectedChannel] : [],
                },
              },
            }));
          }}
        />
      )}
      {/* TODO: support mulitple emails */}
      {values.recipient.notificationProvider === NotificationProviders.EMAIL && (
        <TextField
          fullWidth
          name="emails"
          label="Email Address"
          type="email"
          value={getEmailValue()}
          onChange={(e) => {
            updateNode((prevNode) => ({
              approval: {
                ...prevNode.approval!,
                recipient: {
                  notificationProvider: NotificationProviders.EMAIL,
                  emails: e.target.value ? [e.target.value] : [],
                },
              },
            }));
          }}
          required
          error={Boolean(emailError)}
          helperText={emailError}
        />
      )}
    </Stack>
  );
};
