import { useHistory } from "react-router";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type TableNotification } from "./types";
import { filters as userFilters, headerColumns as userHeaderColumns } from "./userColumns";
import { UserNotificationRow } from "./UserNotificationRow";

type Props = {
  data: TableNotification[];
};

export const cyIds = {
  text: {
    noNotifications: {
      subtitle: "noUserNotificationsSubtitle",
      body: "noUserNotificationsBody",
    },
    title: "userNotificationsTableTitle",
  },
  button: "createUserNotificationButton",
};

export default function UserNotificationsTable({ data }: Props) {
  const theme = useTheme();
  const history = useHistory();
  const { isDoitEmployee } = useAuthContext();
  const { customer } = useCustomerContext();

  const RowUserWrapper = ({ row }: { row: TableNotification }) => <UserNotificationRow row={row} />;

  if (data.length) {
    return (
      <FilterTable<TableNotification>
        toolbarProps={{
          title: (
            <Typography variant="h4" data-cy={cyIds.text.title}>
              My notifications
            </Typography>
          ),
        }}
        rowComponent={RowUserWrapper}
        headerColumns={userHeaderColumns}
        filterColumns={userFilters}
        tableItems={data}
        showFilterBar={false}
        fillEmptySpace={false}
      />
    );
  }

  return (
    <Box>
      <Typography
        variant="h4"
        data-cy={cyIds.text.title}
        sx={{
          mb: 1,
        }}
      >
        My notifications
      </Typography>
      <Stack
        sx={{
          gap: 2,
          alignItems: "center",
          p: 4,
          border: `1px solid ${theme.palette.general.outlineBorder}`,
        }}
      >
        <Typography variant="subtitle1" data-cy={cyIds.text.noNotifications.subtitle}>
          {isDoitEmployee
            ? "You don’t have any notifications set up for this customer"
            : "You don’t have any personal notifications set up"}
        </Typography>
        <Typography variant="body2" data-cy={cyIds.text.noNotifications.body}>
          Configure personal notifications for invoices, cost anomalies and more
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            history.push(`/customers/${customer.id}/user/notifications/create`);
          }}
          data-cy={cyIds.button}
        >
          Set up notifications
        </Button>
      </Stack>
    </Box>
  );
}
