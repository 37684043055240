import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";

import { cloudflowTexts } from "../../../assets/texts";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { customersRoute } from "../../../Navigation/core/utils";
import { useCloudflow } from "../hooks";

type Props = {
  flowId: string;
};

const ViewCloudFlowButton = ({ flowId }: Props) => {
  const customerId = useCustomerId();
  const { cloudflow, cloudflowLoading } = useCloudflow(flowId);

  return (
    <Tooltip title={!cloudflow && !cloudflowLoading && cloudflowTexts.EXECUTION_HISTORY.CLOUDFLOW_DELETED}>
      <span>
        <LoadingButton
          loading={cloudflowLoading}
          disabled={!cloudflow}
          variant="outlined"
          to={customersRoute(customerId, `cloudflow/edit/${flowId}`)}
          component={Link}
        >
          View CloudFlow
        </LoadingButton>
      </span>
    </Tooltip>
  );
};
export default ViewCloudFlowButton;
