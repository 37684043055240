import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { AppModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { useDocument } from "@doitintl/models-firestore/src/react-firebase-hooks/firestore/useDocument";
import { Box, Button, Stack, Typography } from "@mui/material";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { Loader } from "../../../Components/Loader";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { downloadFromBucket } from "../../../utils/firebaseStorage";
import { useCustomerContracts } from "../hooks";
import { filters, headerColumns } from "./columns";
import ContractRow from "./ContractRow";
import { DisabledContractEditingBanner } from "./DisabledContractEditingBanner";
import { type FormattedContract, type FormattedVendorContract } from "./types";

export const ContractsList = () => {
  const { customer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();
  const snackbar = useSnackbar();
  const { contracts, loading } = useCustomerContracts();
  const [appContracts] = useDocument(getCollection(AppModel).doc("contracts"));

  // filtering out discount field for non doit employees
  const filteredHeaderColumns = useMemo(
    () => headerColumns.filter((col) => col.value !== "data.discount" || isDoitEmployee),
    [isDoitEmployee]
  );
  const filteredFilters = useMemo(
    () => filters.filter((filter) => filter.path !== "data.discount" || isDoitEmployee),
    [isDoitEmployee]
  );

  const handleDownload = async (contract: FormattedContract | FormattedVendorContract) => {
    let error;

    const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID as string;
    const bucketName = projectId === "me-doit-intl-com" ? "hello-contracts" : `hello-contracts-${projectId}`;

    if (contract?.data.contractFile.storage) {
      error = await downloadFromBucket({
        bucketName,
        path: contract.data.contractFile.storage,
        fileName: contract.data.contractFile.name,
      });

      if (!error) {
        return;
      }
    }

    snackbar.onOpen({
      message: error ?? "Missing file or test environment",
      variant: "error",
      autoHideDuration: 5000,
      withClose: true,
    });
  };

  const contractRowWrapper = ({ row }) => <ContractRow contract={row} handleDownload={handleDownload} />;

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          mx: 1,
          my: 1,
        }}
      >
        <Typography variant="h1">Contracts</Typography>
        {isDoitEmployee && (
          <Button
            data-cy="create-contract"
            variant="contained"
            aria-label="Create a Contract"
            component={RouterLink}
            to={`/customers/${customer.id}/contracts/contracts-list/create`}
            disabled={appContracts?.asModelData()?.disableEditing}
          >
            Create contract
          </Button>
        )}
      </Stack>
      {appContracts?.asModelData()?.showDisabledEditingBanner && <DisabledContractEditingBanner />}
      <Loader loading={loading}>
        <FilterTable<FormattedContract>
          showRowsSelection={false}
          tableItems={contracts}
          headerColumns={filteredHeaderColumns}
          rowComponent={contractRowWrapper}
          filterColumns={filteredFilters}
          persistenceKey="contracts_filter"
          defaultSortingColumnValue="data._product"
          rowDataCyId
        />
      </Loader>
    </Box>
  );
};

export default ContractsList;
