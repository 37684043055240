import { useCallback, useState } from "react";

import { isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import { type CloudFlowNodeType, type ConditionExpression, ModelType } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { DateTime } from "luxon";

import { cmpBaseColors } from "../../../../cmpBaseColors";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { ReferencedFieldChip } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldChip";
import { ReferencedFieldContextProvider } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldContextProvider";
import { type NodeWitOutputModel } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";
import { FilterDialog } from "./FilterDialog";
import { getModelByFieldReference } from "./utils";

type FilterConditionProps = {
  condition: ConditionExpression;
  groupIndex: number;
  conditionIndex: number;
  selectedNode: NodeWitOutputModel | undefined;
  referenceableNodes: NodeWitOutputModel[];
};

const FilterCondition = ({
  condition,
  groupIndex,
  conditionIndex,
  selectedNode,
  referenceableNodes,
}: FilterConditionProps) => {
  const [editConditionOpen, setEditConditionOpen] = useState(false);
  const isDarkTheme = useDarkThemeCheck();
  const theme = useTheme();

  const { updateNode } = useNodeConfigurationContext<CloudFlowNodeType.FILTER>();

  const handleEditFilter = useCallback(
    async (updatedCondition: ConditionExpression) => {
      updateNode((prevNode) => {
        const conditionGroups = prevNode.parameters?.conditionGroups ?? [];
        const updatedConditionGroups = [...conditionGroups];

        const groupConditions = [...updatedConditionGroups[groupIndex].conditions];
        groupConditions[conditionIndex] = updatedCondition;

        updatedConditionGroups[groupIndex] = {
          ...updatedConditionGroups[groupIndex],
          conditions: groupConditions,
        };

        return {
          parameters: {
            ...prevNode.parameters!,
            conditionGroups: updatedConditionGroups,
          },
        };
      });

      setEditConditionOpen(false);
    },
    [conditionIndex, groupIndex, updateNode]
  );

  const handleDeleteFilter = useCallback(() => {
    updateNode((prevNode) => {
      const conditionGroups = prevNode.parameters?.conditionGroups ?? [];
      const updatedConditionGroups = [...conditionGroups];

      const groupConditions = [...updatedConditionGroups[groupIndex].conditions];
      groupConditions.splice(conditionIndex, 1);

      updatedConditionGroups[groupIndex] = {
        ...updatedConditionGroups[groupIndex],
        conditions: groupConditions,
      };

      return {
        parameters: {
          ...prevNode.parameters!,
          conditionGroups: updatedConditionGroups,
        },
      };
    });
  }, [conditionIndex, groupIndex, updateNode]);

  const getStaticValue = useCallback(
    function toText(value: unknown) {
      if (value === null) {
        return "";
      }

      if (Array.isArray(value)) {
        value = value.map(toText);
      }

      if (isReferencedNodeValue(value)) {
        const nodeName = referenceableNodes.find(({ id }) => id === value.referencedNodeId)?.name;
        return `${nodeName}.${value.referencedField.join(".")}`;
      }

      const model = selectedNode ? getModelByFieldReference(selectedNode.outputModel, condition.field) : undefined;

      if (model && model.type === ModelType.TIMESTAMP) {
        const timestampFormat = model.timestampFormat ?? "X";
        let dateTime: DateTime;

        if (typeof value === "number" || typeof value === "string") {
          if (timestampFormat === "X") {
            dateTime =
              typeof value === "number" ? DateTime.fromSeconds(value) : DateTime.fromSeconds(parseFloat(value));
          } else {
            dateTime = DateTime.fromFormat(String(value), timestampFormat);
          }

          if (dateTime.isValid) {
            return dateTime.toLocaleString(DateTime.DATETIME_MED);
          } else {
            return String(value);
          }
        } else {
          return JSON.stringify(value);
        }
      }

      return typeof value === "string" ? value : JSON.stringify(value);
    },
    [condition.field, referenceableNodes, selectedNode]
  );

  return (
    <>
      {selectedNode && (
        <FilterDialog
          open={editConditionOpen}
          referenceableNodes={referenceableNodes}
          mode="edit"
          selectedNode={selectedNode}
          condition={condition}
          handleClose={() => {
            setEditConditionOpen(false);
          }}
          handleAction={handleEditFilter}
        />
      )}
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid",
          borderColor: theme.palette.general.outlineBorder,
          borderRadius: 1,
          width: "100%",
          backgroundColor: isDarkTheme ? cmpBaseColors.backgroundDark : cmpBaseColors.backgroundLight,
          minHeight: "47px",
          px: 2,
        }}
      >
        <Tooltip
          placement="top"
          title={`${selectedNode?.name}.${condition.field.join(".")} ${condition.comparisonOperator} ${getStaticValue(condition.value)}`}
        >
          <Stack
            direction={"row"}
            sx={{
              gap: 0.5,
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Typography noWrap variant="body2" sx={{ flexShrink: "0.5" }}>
              {`${condition.field.join(".")} ${condition.comparisonOperator} `}
            </Typography>
            {/* TODO: consult with Jack how to show list of referenced values */}
            {!isReferencedNodeValue(condition.value) ? (
              <Typography noWrap variant="body2">
                {getStaticValue(condition.value)}
              </Typography>
            ) : (
              <Box sx={{ overflow: "hidden" }}>
                <ReferencedFieldContextProvider referenceableNodes={referenceableNodes}>
                  <ReferencedFieldChip value={condition.value} />
                </ReferencedFieldContextProvider>
              </Box>
            )}
          </Stack>
        </Tooltip>
        <Stack
          direction={"row"}
          sx={{
            gap: 0.5,
            p: 0,
          }}
        >
          <IconButton
            onClick={() => {
              setEditConditionOpen(true);
            }}
            sx={{ p: 0.5 }}
          >
            <EditIcon sx={{ fontSize: "16px" }} />
          </IconButton>
          <IconButton onClick={handleDeleteFilter} sx={{ p: 0.5 }}>
            <CloseIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </Stack>
      </Stack>
    </>
  );
};

export default FilterCondition;
