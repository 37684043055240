import { type MouseEvent, type ReactNode, useState } from "react";

import { Box, ClickAwayListener } from "@mui/material";

import HtmlTooltip from "../../../../Components/Tooltip/HtmlTooltip";

export type WrappedHtmlTooltipProps = {
  triggerText: string;
  tooltipComponent: ReactNode;
};

export const WrappedHtmlTooltip = ({ triggerText, tooltipComponent }: WrappedHtmlTooltipProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = (e: MouseEvent) => {
    e.preventDefault();
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <span>
        <HtmlTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={isTooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={tooltipComponent}
        >
          <Box
            sx={{ borderBottom: "1px dotted", borderColor: "text.primary", cursor: "pointer" }}
            component="span"
            onClick={handleTooltipOpen}
          >
            {triggerText}
          </Box>
        </HtmlTooltip>
      </span>
    </ClickAwayListener>
  );
};
