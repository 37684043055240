import { createContext, type ReactNode, useContext, useMemo } from "react";

import { type DataHubMetrics, type ExtendedMetric, type ReportConfig } from "@doitintl/cmp-models";

import { type AffectedResources } from "./attributions/DeleteValidationDialog";

export type Transforms = {
  [p: string]: ((value: string) => any) | ((value: string, nullFallback?: any) => string | null);
};

export type CloudAnalyticsContextProps = {
  filterAlerts?: Record<string, any> | null;
  handleMissingPermission: (message: string) => void;
  transforms: Transforms;
  extendedMetrics: ExtendedMetric[];
  datahubMetrics: DataHubMetrics[];
  defaultReportConfig: ReportConfig;
  defaultConfigLoading: boolean;
  handleSaveDefaultReportConfig: (config: ReportConfig) => Promise<void>;
  hasMetadata: boolean | undefined;
  handleDeleteValidationResponse: (data: AffectedResources[]) => void;
  handleDeleteAttributionGroupValidationResponse: (data: AffectedResources[]) => void;
  handleFavoriteReportTemplateUpdate: (id: string) => void;
  getTemplateIdByReportId: (reportId: string) => string | undefined;
  hasDataHub: boolean;
  loadAnalyticsLabels: () => void;
};

const cloudAnalyticsContext = createContext({} as CloudAnalyticsContextProps);

export const useAnalyticsContext = () => useContext(cloudAnalyticsContext);

export const CloudAnalyticsContextProvider = cloudAnalyticsContext.Provider;

export const CloudAnalyticsContextProviderForTesting = ({
  children,
  value,
}: {
  children: ReactNode;
  value?: Partial<CloudAnalyticsContextProps>;
}) => {
  const actualValue = useMemo(
    (): Partial<CloudAnalyticsContextProps> => ({
      ...value,
    }),
    [value]
  );

  return (
    <cloudAnalyticsContext.Provider value={actualValue as CloudAnalyticsContextProps}>
      {children}
    </cloudAnalyticsContext.Provider>
  );
};
