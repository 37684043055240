import { isDci } from "../Settings/General/utils";

type TemplateFunction = (displayName: string) => string;

type PaymentRequirement = "free" | "fourFree" | "paid";

export type AcceleratorEntitlementKey =
  | "services:accelerator:included"
  | "services:accelerator:included:4"
  | "services:accelerator:paid";
export const acceleratorEntitlementKeys: AcceleratorEntitlementKey[] = [
  "services:accelerator:included",
  "services:accelerator:included:4",
  "services:accelerator:paid",
] as const;

export type WorkshopEntitlementKey = "services:workshop:included" | "services:workshop:paid";
export const workshopEntitlementKeys: WorkshopEntitlementKey[] = [
  "services:workshop:included",
  "services:workshop:paid",
] as const;

export type ProfessionalServicesEntitlementKey =
  | AcceleratorEntitlementKey
  | WorkshopEntitlementKey
  | "services:training";

export const expandDciDisplayName = (tierDisplayName: string): string =>
  tierDisplayName.replace("DCI", "DoiT Cloud Intelligence™");

export const dciHelperTemplates: Record<"free" | "paid", TemplateFunction> = {
  free: (displayName: string): string => {
    const expandedName = expandDciDisplayName(displayName);
    return `Included with the ${expandedName} plan`;
  },
  paid: (displayName: string): string => {
    const expandedName = expandDciDisplayName(displayName);
    return `Available on-demand for a fee with the ${expandedName} plan`;
  },
};

export const solveHelperTemplates: Record<PaymentRequirement, TemplateFunction> = {
  free: (displayName: string): string =>
    `Included with the Cloud ${displayName.includes("Solve") ? "" : "Solve "}${displayName} plan`,
  fourFree: (displayName: string): string =>
    `Four Accelerators included with the Cloud ${displayName.startsWith("Solve") ? "" : "Solve "}${displayName} plan`,
  paid: (): string => `Available on-demand for an additional fee`,
};

/**
 * Get the helper text for the Professional Service offering detailing whether the user must pay.
 * @param entitlement the entitlement key representing the entitlement being used to authorize this particular professional service.
 * @param tierDisplayName the customer's tier name
 */
export const getHelperText = (
  entitlement?: ProfessionalServicesEntitlementKey,
  tierDisplayName?: string
): string | undefined => {
  if (!tierDisplayName || !entitlement) {
    return undefined;
  }

  const heritage: boolean = tierDisplayName.includes("Heritage");
  const basic: boolean = tierDisplayName.includes("Basic");
  if (heritage) {
    tierDisplayName = tierDisplayName.replace(/\s?Heritage\s?/, " ").trim();
  }
  if (basic) {
    tierDisplayName = tierDisplayName.replace(/\s?Basic\s?/, " ").trim();
  }

  const dciPerk: boolean = isDci(tierDisplayName);
  let output: string;
  const entitlementHandlers: Record<
    ProfessionalServicesEntitlementKey,
    (tierDisplayName: string, isDci: boolean) => string
  > = {
    "services:training": (tierDisplayName, dciPerk) =>
      dciPerk ? dciHelperTemplates.free(tierDisplayName) : solveHelperTemplates.free(tierDisplayName),
    "services:accelerator:included": (tierDisplayName, dciPerk) =>
      dciPerk ? dciHelperTemplates.free(tierDisplayName) : solveHelperTemplates.free(tierDisplayName),
    "services:accelerator:included:4": (tierDisplayName) => solveHelperTemplates.fourFree(tierDisplayName),
    "services:accelerator:paid": (tierDisplayName, dciPerk) =>
      dciPerk ? dciHelperTemplates.paid(tierDisplayName) : solveHelperTemplates.paid(tierDisplayName),
    "services:workshop:included": (tierDisplayName, dciPerk) =>
      dciPerk ? dciHelperTemplates.free(tierDisplayName) : solveHelperTemplates.free(tierDisplayName),
    "services:workshop:paid": (tierDisplayName, dciPerk) =>
      dciPerk ? dciHelperTemplates.paid(tierDisplayName) : solveHelperTemplates.paid(tierDisplayName),
  };

  if (entitlement in entitlementHandlers) {
    output = entitlementHandlers[entitlement](tierDisplayName, dciPerk);
  } else {
    return undefined;
  }

  if (heritage || basic) {
    output = output.replace("the", "your");
  }

  return output;
};
