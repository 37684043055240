import { useEffect, useState } from "react";

import { AssetModel, AssetTypeGSuite, AssetTypeOffice365 } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useCustomerContext } from "./CustomerContext";
import { type WidgetDescription } from "./useDashboardsContext";

export const useAssetsWidgets = () => {
  const { customer } = useCustomerContext();
  const [assetsWidgets, setAssetsWidgets] = useState<WidgetDescription[]>([]);

  useEffect(() => {
    getCollection(AssetModel)
      .where("customer", "==", customer.ref)
      .where("type", "in", [AssetTypeGSuite, AssetTypeOffice365])
      .get()
      .then((query) => {
        setAssetsWidgets(
          query.docs.map((asset) => {
            const assetData = asset.asModelData();

            return {
              title:
                assetData.type === "office-365"
                  ? assetData.properties.subscription.offerName
                  : assetData.properties.subscription.skuName,
              description: assetData.properties.customerDomain,
              name:
                assetData.type === "office-365"
                  ? `office365AssetCard::${assetData.type}-${assetData.properties.subscription.id}`
                  : `gsuiteAssetCard::${assetData.type}-${assetData.properties.subscription.subscriptionId}`,
            };
          })
        );
      });
  }, [customer.ref]);

  return { assetsWidgets };
};
