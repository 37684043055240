import { NodeExecutionStatus } from "@doitintl/cmp-models";
import { type Timestamp } from "@doitintl/models-types";
import { DateTime } from "luxon";

import { getTimeTaken } from "../utils";

export const formatCloudFlowRunDetailsTime = (time: Timestamp | undefined) => {
  if (time?.toDate) {
    return DateTime.fromJSDate(time.toDate()).toLocaleString({
      ...DateTime.DATE_MED,
      ...DateTime.TIME_WITH_SECONDS,
    });
  }
  return "N/A";
};

export const calculateDuration = (status: NodeExecutionStatus, startTime?: Timestamp, endTime?: Timestamp) => {
  if (status === NodeExecutionStatus.PENDING || status === NodeExecutionStatus.PENDING_APPROVAL) {
    return "Paused";
  }

  if (status === NodeExecutionStatus.IN_PROGRESS) {
    return "Ongoing";
  }

  if (!startTime || !endTime) {
    return "Invalid time";
  }

  return getTimeTaken(startTime, endTime);
};
