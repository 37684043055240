import { useCallback, useMemo, useState } from "react";

import { AccessLevels } from "@doitintl/cmp-models";

import { usePermissionCategories } from "../usePermissionCategories";
import { getScopedCategoryPermissions } from "../utils";

export function useSelectItemList<T>(initialState: T[]): [T[], (value: T, isSelected: boolean) => void] {
  const [selectedItems, setSelectedItems] = useState(initialState);

  const onSelectItem = useCallback(
    (value: T, isSelected: boolean) => {
      setSelectedItems((current) => {
        if (isSelected) {
          return [...current, value];
        }

        return current.filter((currentId) => currentId !== value);
      });
    },
    [setSelectedItems]
  );

  return [selectedItems, onSelectItem];
}

export const usePermissionCategoriesByType = (type: "organization" | "project") => {
  const categories = usePermissionCategories();

  return useMemo(() => {
    // For projects, take only categories that allow project level access, then filter permissions
    if (type === "project") {
      return categories
        .filter((category) => category.allowProjectAccessLevel)
        .map((category) => ({
          ...category,
          permissions: getScopedCategoryPermissions(category, AccessLevels.PROJECT),
        }));
    }

    // For organizations, all categories apply, then filter permissions
    return categories.map((category) => ({
      ...category,
      permissions: getScopedCategoryPermissions(category, AccessLevels.ORGANIZATION),
    }));
  }, [categories, type]);
};
