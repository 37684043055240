import { type Cluster, ClusterDiscoveryStatus, ClusterType } from "@doitintl/cmp-models";
import { CircularProgress } from "@mui/material";

import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";

interface ClusterThreeDotsMenuProps {
  cluster: Cluster;
  onTestConnection?: () => Promise<void>;
  onActivateCluster?: () => Promise<void>;
  onDeactivateCluster?: () => Promise<void>;
  onDeleteCluster?: () => void;
  onEditCluster?: () => void;
  loading?: boolean;
}

const PRIVATE_CLUSTER_TOOLTIP =
  "The control plane of this cluster is not accessible through the internet. Private control planes are currently not supported.";

const DELETED_CLUSTER_TOOLTIP =
  "This cluster has been deleted. If you think this is an error, please contact support or check the connection configuration.";

const ClusterThreeDotsMenu = ({
  cluster,
  onTestConnection,
  onActivateCluster,
  onDeactivateCluster,
  onDeleteCluster,
  onEditCluster,
  loading = false,
}: ClusterThreeDotsMenuProps) => {
  const clusterIsDeleted = cluster.discoveryStatus === ClusterDiscoveryStatus.DELETED;

  const getOptionParameters = () => {
    if (clusterIsDeleted) {
      return {
        tooltip: DELETED_CLUSTER_TOOLTIP,
        disabled: true,
      };
    }

    if (cluster.isPrivate) {
      return {
        tooltip: PRIVATE_CLUSTER_TOOLTIP,
        disabled: true,
      };
    }

    return {
      disabled: false,
    };
  };

  const options: ThreeDotsMenuOption[] = [
    {
      ...getOptionParameters(),
      label: "Test connection",
      key: "test-cluster-connection",
      action: onTestConnection,
    },
    cluster.isActivated
      ? {
          ...getOptionParameters(),
          label: "Deactivate",
          key: "deactivate-cluster",
          action: onDeactivateCluster,
        }
      : {
          ...getOptionParameters(),
          label: "Activate",
          key: "activate-cluster",
          action: onActivateCluster,
        },
  ];

  if (cluster.type === ClusterType.SELF_MANAGED) {
    options.push(
      {
        label: "Delete",
        key: "delete-cluster",
        action: onDeleteCluster,
      },
      {
        label: "Edit",
        key: "edit-cluster",
        action: onEditCluster,
      }
    );
  }

  options.forEach((option) => {
    option.tooltipPlacement = "left";
    option.dataCy = option.dataCy ?? option.key;
  });

  if (loading) {
    return <CircularProgress size={24} />;
  }

  return <ThreeDotsMenu options={options} size="small" closeAfterSelect />;
};

export default ClusterThreeDotsMenu;
