import { useCallback, useEffect, useMemo, useState } from "react";

import {
  type BQLensBillingMode,
  type BQLensMeasurement,
  type BQLensMetric,
  type BQLensMetrics,
  type BQLensTimeFrame,
  BQLensTimeFrames,
  type SpendProfile,
} from "@doitintl/cmp-models";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useCachedHook } from "../../../Context/DynamicContext/useCachedHook";
import { BillingMode } from "../../../Pages/Customer/constants";
import { defaultMeasurement, defaultMetricType } from "./constants";
import { useBqSpendProfile, useRollsUpData } from "./hooks";
import { getFilteredUnits, getMeasurementsForMetric, setInitialBillingMode } from "./utils";

type BqDashboardValues = {
  selectedMetric: BQLensMetric;
  selectedBillingMode: BQLensBillingMode;
  selectedMeasurement: BQLensMeasurement;
  availableUnitOptions: string[];
  selectedTimeframe: BQLensTimeFrame;
  spendProfile: SpendProfile;
};

export type BigQueryLensDashboardContextType = BqDashboardValues & {
  setDashboardWidgetContextValue: <TKey extends keyof BqDashboardValues>(
    key: TKey,
    value: BqDashboardValues[TKey]
  ) => void;
  setDashboardWidgetContextMultipleValues: (payload: Partial<BqDashboardValues>) => void;
  loading: boolean;
};

async function setInitialBQLensDashboardValues(
  customerId: string,
  spendProfile: SpendProfile,
  initialMetric: BQLensMetrics | undefined
): Promise<BqDashboardValues> {
  const selectedBillingMode = setInitialBillingMode(spendProfile);
  const selectedMetric = initialMetric ? initialMetric : defaultMetricType;
  const measurementOptions = getMeasurementsForMetric(selectedBillingMode, selectedMetric);
  const selectedTimeframe = BQLensTimeFrames.pastThirtyDays;

  const filteredUnits = await getFilteredUnits({
    unitsToCheck: measurementOptions,
    customerId,
    metricType: selectedMetric,
    selectedTimeframe,
    selectedBillingMode,
  });

  return {
    selectedMetric,
    selectedBillingMode,
    selectedMeasurement: measurementOptions[0],
    availableUnitOptions: filteredUnits,
    selectedTimeframe,
    spendProfile,
  };
}

const defaultBqDashboardValues: BqDashboardValues = {
  selectedMetric: defaultMetricType,
  selectedBillingMode: BillingMode.onDemand,
  selectedMeasurement: defaultMeasurement,
  availableUnitOptions: [],
  selectedTimeframe: BQLensTimeFrames.pastThirtyDays,
  spendProfile: {
    isOnDemand: false,
    isStandardEdition: false,
    isEnterpriseEdition: false,
    isEnterprisePlusEdition: false,
  },
};

const useNonCachedBigQueryLensDashboardContext = () => {
  const { bqSpendProfile: bqlensSpendProfile } = useBqSpendProfile();
  const [dashboardValues, setDashboardValues] = useState<BqDashboardValues>({ ...defaultBqDashboardValues });
  const [loading, setLoading] = useState(true);

  const { defaultMetric, loading: loadingRollsUpData } = useRollsUpData(dashboardValues.selectedBillingMode);
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();

  const setDashboardWidgetContextMultipleValues = useCallback((payload: Partial<BqDashboardValues>) => {
    setDashboardValues((prev) => ({ ...prev, ...payload }));
  }, []);

  const setDashboardWidgetContextValue = useCallback(
    <TKey extends keyof BqDashboardValues>(key: TKey, value: BqDashboardValues[TKey]) => {
      setDashboardValues((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  useEffect(() => {
    const initializeBqDashboardValues = async () => {
      if (bqlensSpendProfile && !loadingRollsUpData) {
        const initialDashboardValues = await setInitialBQLensDashboardValues(
          customer.id,
          bqlensSpendProfile,
          defaultMetric
        );
        setDashboardValues(initialDashboardValues);
        setLoading(false);
      }
    };

    void initializeBqDashboardValues();
  }, [bqlensSpendProfile, defaultMetric, customer.id, loadingRollsUpData]);

  return useMemo<BigQueryLensDashboardContextType>(
    () => ({
      ...dashboardValues,
      setDashboardWidgetContextMultipleValues,
      setDashboardWidgetContextValue,
      loading,
    }),
    [dashboardValues, loading, setDashboardWidgetContextMultipleValues, setDashboardWidgetContextValue]
  );
};

export function useBigQueryLensDashboardContext() {
  return useCachedHook(useNonCachedBigQueryLensDashboardContext);
}
