import { type Theme } from "@mui/system";

import { type TreeChartSeriesRecord } from "../../useChartsSeries";

export function colorTreeSeries(treeSeries: TreeChartSeriesRecord[], theme: Theme, colors: string[]) {
  return treeSeries.map((node, index) => ({
    ...node,
    itemStyle: {
      color: colors[index % colors.length],
    },
    label: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      color: theme.palette.getContrastText(colors[index % colors.length]),
    },
    children: node.children?.map((child, childIndex) => ({
      ...child,
      itemStyle: {
        color: colors[(index + childIndex + 1) % colors.length],
      },
      label: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        color: theme.palette.getContrastText(colors[(index + childIndex + 1) % colors.length]),
      },
    })),
  }));
}
