import { useEffect, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

import { cloudflowTexts } from "../../../../assets/texts";

type ConfirmUnpublishDialogProps = {
  isDialogOpened: boolean;
  hasActiveExecutions: boolean | undefined;
  handleCloseDialog: () => void;
  handleUnpublish: () => void;
};

const ConfirmUnpublishDialog = ({
  isDialogOpened,
  hasActiveExecutions,
  handleCloseDialog,
  handleUnpublish,
}: ConfirmUnpublishDialogProps) => {
  /*
   * Cache hasActiveExecutions and only update it when the dialog opens
   * to prevent changing the displayed text after CloudFlow unpublished.
   */
  const [hasExecutions, setHasExecutions] = useState(hasActiveExecutions);
  useEffect(() => {
    if (isDialogOpened) {
      setHasExecutions(hasActiveExecutions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpened]);

  return (
    <Dialog open={isDialogOpened} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle>{cloudflowTexts.CONFIRM_UNPUBLISH_TITLE}</DialogTitle>
      <DialogContent>
        {hasExecutions ? (
          <Stack spacing={3}>
            <Typography>{cloudflowTexts.CONFIRM_UNPUBLISH_CONTENT.LINE_1}</Typography>
            <Typography>{cloudflowTexts.CONFIRM_UNPUBLISH_CONTENT.LINE_2}</Typography>
            <Typography>{cloudflowTexts.CONFIRM_UNPUBLISH_CONTENT.LINE_3}</Typography>
          </Stack>
        ) : (
          <>
            <Typography>{cloudflowTexts.CONFIRM_UNPUBLISH_CONTENT.LINE_1}</Typography>
            <Typography>{cloudflowTexts.CONFIRM_UNPUBLISH_CONTENT.LINE_3}</Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary" data-cy="cancel-button">
          {cloudflowTexts.CANCEL}
        </Button>
        <Button onClick={handleUnpublish} color="primary" variant="contained" data-cy="unpublish-button">
          {cloudflowTexts.UNPUBLISH}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUnpublishDialog;
