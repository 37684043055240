import { type ExecutionNodeModel } from "@doitintl/cmp-models";
import { TableCell } from "@mui/material";

import StatusChip, { nodeExecutionStatus } from "../Components/StatusChip";
import { calculateDuration, formatCloudFlowRunDetailsTime } from "./utils";

type Props = {
  row: ExecutionNodeModel;
};

const CloudflowRunDetailsRow = ({ row }: Props) => (
  <>
    <TableCell>{row.nodeName}</TableCell>
    <TableCell>
      <StatusChip status={row.status} statusMap={nodeExecutionStatus} />
    </TableCell>
    <TableCell>{formatCloudFlowRunDetailsTime(row.startedAt)}</TableCell>
    <TableCell>{formatCloudFlowRunDetailsTime(row.finishedAt)}</TableCell>
    <TableCell>{calculateDuration(row.status, row.startedAt, row.finishedAt)}</TableCell>
  </>
);

export default CloudflowRunDetailsRow;
