import { Box, CardHeader, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import RampPlanGraphicLight from "../../../../assets/ramp-graphic.svg";
import RampPlanGraphicDark from "../../../../assets/ramp-graphic-dark.svg";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import ButtonCreateRampPlan from "../../../../Pages/RampPlans/components/ButtonCreateRampPlan";

const RampsOnboard = () => {
  const isDarkMode = useDarkThemeCheck();
  return (
    <Grid
      container
      spacing={0}
      data-cy="rampsonboard"
      sx={{
        justifyContent: "center",
      }}
    >
      <Grid
        size={{
          xs: 12,
          md: 10,
          lg: 7,
        }}
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Grid
          size={{
            xs: 12,
            xl: 12,
          }}
        >
          <CardHeader title="Ramp plans" titleTypographyProps={{ variant: "h1" }} />
        </Grid>
        <Grid
          size={{
            sm: 12,
            md: 6,
          }}
        >
          <Typography variant="body1" color="textSecondary" sx={{ ml: 2 }}>
            Compare your current spend to your planned spend for AWS or GCP all tracked along the baseline of your
            contracted commit spend.
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ ml: 2, mt: 2 }}>
            Define the scope of your ramp plan with our preset attributions. If you need to customize what contributes
            your commit spend you can build a custom attribution.
          </Typography>
          <Box sx={{ ml: 2, mt: 3 }}>
            <ButtonCreateRampPlan takenNamesArr={[]} />
          </Box>
        </Grid>
        <Grid
          size={{
            sm: 12,
            md: 6,
          }}
        >
          <img src={isDarkMode ? RampPlanGraphicDark : RampPlanGraphicLight} style={{ width: "100%" }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RampsOnboard;
