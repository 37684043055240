import { type Cluster } from "@doitintl/cmp-models";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, Stack, Typography } from "@mui/material";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { downloadFile } from "../../../../utils/files";
import { OpenAWSConsole } from "../../../EKS/Components/OpenAWSConsole";
import { type Feature, getRbacYamlFileContent } from "../features";
import Fsk8sFeatureSelector from "./components/Fsk8sFeatureSelector";
import SelectedAccountAndCluster from "./components/SelectedAccountAndCluster";

type SelectedAccountAndClusterProps = {
  accountId: string | null;
  cluster: Cluster | null;
  selectedFeatures: Feature[];
  setSelectedFeatures: (features: Feature[]) => void;
  onDownloadYaml: () => void;
};

const baseCommand = `kubectl apply -f $FILE_NAME`;

const EnableFeaturesStep = ({
  accountId,
  cluster,
  selectedFeatures,
  setSelectedFeatures,
  onDownloadYaml,
}: SelectedAccountAndClusterProps) => {
  const fileName = `doit-${cluster?.region}-${cluster?.name}-eks-sa-group-setup.yaml`;

  const handleDownloadYaml = () => {
    const content = getRbacYamlFileContent(cluster, selectedFeatures);
    downloadFile(fileName, content, "text/yaml");
    onDownloadYaml();
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">Enable features for this cluster</Typography>
        <SelectedAccountAndCluster accountId={accountId} cluster={cluster} />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="body2">1. Select which features you want to enable for this cluster</Typography>
        <Fsk8sFeatureSelector selectedFeatures={selectedFeatures} onSelect={setSelectedFeatures} />
      </Stack>

      <Stack spacing={4}>
        <Stack spacing={2}>
          <Typography variant="body2">
            2. Download the YAML file to your local device. You will need this to complete step 3
          </Typography>
          <Button
            onClick={handleDownloadYaml}
            sx={{ width: "fit-content" }}
            startIcon={<DownloadIcon />}
            variant="outlined"
            color="primary"
            disabled={selectedFeatures.length === 0}
          >
            Download
          </Button>
        </Stack>

        <Stack spacing={2}>
          <Typography variant="body2">
            3. After downloading the YAML file, run the following commands on the AWS Console or a method of your choice
          </Typography>
          <OpenAWSConsole region={cluster?.region ?? ""} />
        </Stack>

        <Stack spacing={1}>
          <Typography variant="body2" color="text.secondary">
            Apply the Kubernetes permissions configuration file
          </Typography>
          <CopyCodeBlock
            base={baseCommand}
            variables={{
              $FILE_NAME: { value: fileName, name: "File Name" },
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EnableFeaturesStep;
