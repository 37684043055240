import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { capitalize, Link, TableCell } from "@mui/material";
import isEmail from "is-email";
import { DateTime } from "luxon";

import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { type CloudflowExecutionSnap } from "../../../types/Cloudflow";
import StatusChip, { executionStatus } from "../Components/StatusChip";
import { getTimeTaken } from "../utils";

type CloudflowHistoryRowProps = {
  row: CloudflowExecutionSnap;
};

const CloudflowHistoryRow = ({ row }: CloudflowHistoryRowProps) => {
  const routeMatchURL = useRouteMatchURL();

  const formattedEndTime = useMemo(() => {
    if (!row.data?.endTime) return "";
    const date = DateTime.fromJSDate(row.data.endTime.toDate());
    if (date?.isValid) {
      return date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    }
  }, [row.data?.endTime]);

  const triggeredByText = isEmail(row.data.triggeredBy)
    ? `Manually by ${row.data.triggeredBy}`
    : capitalize(row.data.triggeredBy);

  const timeTaken = getTimeTaken(row.data.startTime, row.data.endTime);

  return (
    <>
      <TableCell>
        <Link
          sx={{ cursor: "pointer" }}
          component={RouterLink}
          color="inherit"
          to={`${routeMatchURL}/${row.ref.id}`}
          variant="body2"
        >
          {row.data.cloudflow?.data?.name}
        </Link>
      </TableCell>
      <TableCell>
        <StatusChip status={row.data.status} statusMap={executionStatus} />
      </TableCell>
      <TableCell>{triggeredByText}</TableCell>
      <TableCell>{formattedEndTime}</TableCell>
      <TableCell>{timeTaken}</TableCell>
    </>
  );
};

export default CloudflowHistoryRow;
