import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { AnalyticsResourceType } from "@doitintl/cmp-models";

import { useRoles } from "../../Components/hooks/IAM/useRoles";
import { useInvites, useUsers } from "../../Components/hooks/IAM/useUsersOrInvites";
import { useErrorSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import {
  PresetAllAwsResources,
  PresetAllGcpResources,
  useAttributionsContext,
} from "../../Context/AttributionsContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useImpersonateContext } from "../../Context/impersonateContext";
import { type IAMPageId } from "../../Navigation/config/pages";
import { consoleErrorWithSentry } from "../../utils";
import { text } from "../UserView/consts";
import { getUserIdByEmail } from "../UserView/db";
import { UserTab } from "../UserView/types";
import { UserView } from "../UserView/UserView";
import AccountAssumption from "./AccountAssumption";
import IamTabs from "./IamTabs";
import EditOrg from "./Organizations/EditOrg";
import RoleView from "./Role";

const accountAssumptionEnabled = true;

export default function IAM({ pageId }: { pageId: IAMPageId }) {
  const { customer } = useCustomerContext();
  const { handleImpersonateUserOfCustomer } = useImpersonateContext();
  const { roleId, orgId } = useParams<{ roleId: string; orgId: string }>();
  const history = useHistory();
  const [successfulImpersonation, setSuccessfulImpersonation] = useState(true);
  const errorSnackbar = useErrorSnackbar();
  const { roles } = useRoles();
  const { users } = useUsers(roles);
  const { invites } = useInvites(roles);

  useEffect(() => {
    if (!successfulImpersonation) {
      errorSnackbar(text.ACCOUNT_IMPERSONATION_UNSUCCESSFUL);
    }
  }, [errorSnackbar, successfulImpersonation]);

  const profileTab = useMemo(() => (pageId === "iam:users:profile:api" ? UserTab.Api : UserTab.Profile), [pageId]);

  const { filteredAttributions: attributions } = useAttributionsContext();
  const filteredAttributions = useMemo(
    () =>
      attributions.filter(
        (attribution) =>
          attribution.data.type === AnalyticsResourceType.CUSTOM ||
          attribution.ref.id === PresetAllGcpResources ||
          attribution.ref.id === PresetAllAwsResources
      ),
    [attributions]
  );

  const handleImpersonateUserWithUserId = useCallback(
    async (userId: string) => {
      try {
        const success = await handleImpersonateUserOfCustomer(customer.id, userId);
        if (!success) {
          setSuccessfulImpersonation(success);
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [customer.id, handleImpersonateUserOfCustomer]
  );

  const handleInviteAccepted = useCallback(
    async (email: string) => {
      const newUserId = await getUserIdByEmail(email);
      history.push(`/customers/${customer.id}/iam/users/${newUserId}`);
    },
    [customer.id, history]
  );

  return (
    <>
      {["iam:users", "iam:roles", "iam:organizations"].includes(pageId) && (
        <IamTabs
          pageId={pageId}
          usersTabParams={{ users, invites }}
          roles={roles}
          setSuccessfulImpersonation={setSuccessfulImpersonation}
        />
      )}

      {pageId === "iam:roles:details" && <RoleView roleId={roleId} roles={roles} />}

      {["iam:users:details", "iam:users:profile:api"].includes(pageId) && (
        <UserView
          isProfile={pageId === "iam:users:profile:api"}
          userTab={profileTab}
          canManageUsers={true}
          onImpersonateUser={handleImpersonateUserWithUserId}
          onInviteAccepted={handleInviteAccepted}
        />
      )}

      {["iam:organizations:details", "iam:organizations:create"].includes(pageId) && (
        <EditOrg
          orgId={orgId === "create" ? "" : orgId}
          users={[...(users ?? []), ...(invites ?? [])]}
          attributions={filteredAttributions}
        />
      )}

      {accountAssumptionEnabled && pageId === "iam:account-assumption" && <AccountAssumption />}
    </>
  );
}
