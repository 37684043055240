import { AvaLoadingAnimation } from "@doitintl/ava-components/src/Messenger/AvaLoadingAnimation";
import { AvaReportLoader } from "@doitintl/ava-components/src/Messenger/AvaReportLoader";

import { useDarkThemeCheck } from "../hooks/useDarkThemeCheck";

type Props = {
  isGeneratingReport?: boolean;
};

export const AvaLoading = ({ isGeneratingReport }: Props) => {
  const isDarkMode = useDarkThemeCheck();

  return isGeneratingReport ? <AvaReportLoader /> : <AvaLoadingAnimation isDarkMode={isDarkMode} />;
};
