import { CloudAnalyticsModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";

import { useCachedHook } from "../../../Context/DynamicContext/useCachedHook";

function useNonCachedExtendedMetrics() {
  return useDocumentDataOnce(
    getCollection(CloudAnalyticsModel).doc("configs").collection("cloudAnalyticsConfigs").doc("extended-metrics")
  );
}

export function useExtendedMetrics() {
  return useCachedHook(useNonCachedExtendedMetrics);
}
