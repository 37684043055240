import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import CloudDownload from "@mui/icons-material/CloudDownload";
import {
  Card,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import { LicensesCardWithData } from "../../Components/Dashboard/LicensesCard";
import { FilterContextProvider } from "../../Components/FilterTable/Context";
import EnhancedTableFilter from "../../Components/FilterTable/EnhancedTableFilter";
import Hide from "../../Components/HideChildren/Hide";
import useTableState from "../../Components/hooks/useTableState";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type Customer } from "../../types";
import { type DataColumns } from "../../types/FilterTable";
import { sanitizeDate } from "../../utils/common";
import { exportCSVFile } from "../../utils/csv";
import { useFullScreen } from "../../utils/dialog";
import { type GraphData, type Orders } from "./types";

const columns = [
  { id: "timestamp", numeric: false, disablePadding: false, label: "Date" },
  { id: "email", numeric: false, disablePadding: false, label: "User" },
  { id: "skuName", numeric: false, disablePadding: false, label: "Subscription" },
  { id: "domain", numeric: false, disablePadding: false, label: "Domain" },
  { id: "quantity", numeric: false, disablePadding: false, label: "Quantity" },
];
const filterColumns: DataColumns<any> = [
  {
    label: "Date",
    path: "timestamp",
    type: "DateTime",
    comparators: ["<", "<=", ">", ">=", "==", "!="],
    placeholder: "YYYY-MM-DD",
    transform: (value) => sanitizeDate(DateTime.fromFormat(value, "yyyy-LL-dd", { zone: "utc" })),
    validate: (value) => DateTime.fromFormat(value, "yyyy-LL-dd")?.isValid,
    toOption: (value) => {
      const strValue = value.toFormat("yyyy-LL-dd");
      return {
        value: strValue,
        label: strValue,
      };
    },
  },
  {
    label: "User",
    path: "email",
  },
  {
    label: "Subscription",
    path: "skuName",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: "Domain",
    path: "domain",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: "Quantity",
    path: "quantity",
    type: "Number",
    comparators: ["<", "<=", ">", ">=", "==", "!="],
    placeholder: "number",
    transform: (value) => parseFloat(value),
    validate: (value) => !isNaN(parseFloat(value)),
  },
];

const downloadCSV = (data: Orders, customer: Customer) => {
  const headers = {
    date: "Date",
    user: "User",
    subscription: "Subscription",
    domain: "Domain",
    qty: "Qty",
  };

  const orderItems = data.map((order) => ({
    date: order.timestamp.toLocaleString(DateTime.DATE_SHORT),
    user: order.email,
    subscription: order.skuName,
    domain: order.domain,
    quantity: order.quantity,
  }));
  const dateNow = DateTime.utc().toLocaleString(DateTime.DATE_SHORT);
  const fileTitle = `doitintl-orders-${customer.name}-${dateNow}`;
  exportCSVFile(headers, orderItems, fileTitle);
};

function EnhancedTableHead({ order, orderBy, onRequestSort, showDomain }) {
  return (
    <TableHead>
      <TableRow>
        {columns
          .filter((column) => showDomain || column.id !== "domain")
          .map((column) => (
            <Hide
              mdDown={
                column.label.toLowerCase() === "domain" ||
                column.label.toLowerCase() === "user" ||
                column.label.toLowerCase() === "qty"
              }
              key={column.id}
            >
              <TableCell
                align={column.numeric ? "right" : "left"}
                padding={column.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === column.id ? order : false}
              >
                <TableSortLabel active={orderBy === column.id} direction={order} onClick={onRequestSort(column.id)}>
                  {column.label}
                </TableSortLabel>
              </TableCell>
            </Hide>
          ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  textField: {
    minWidth: 300,
  },
  noHistory: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: 40,
    padding: 6,
  },
  tableToolbar: {
    padding: theme.spacing(1),
  },
}));

type EnhancedTableToolbarProps = {
  data: Orders;
};

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { customer } = useCustomerContext();

  const pageHeaderAction = (
    <>
      <Hide mdDown>
        <Grid container spacing={2} wrap="nowrap">
          <Grid>
            <Tooltip title="Download as CSV">
              <span>
                <IconButton
                  aria-label="Download as CSV"
                  disabled={props.data.length === 0}
                  onClick={() => {
                    downloadCSV(props.data, customer);
                  }}
                  size="large"
                >
                  <CloudDownload />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Hide>
    </>
  );
  return (
    <Card sx={{ border: "none", boxShadow: "none" }}>
      <CardHeader
        title="Order History"
        sx={{ width: "100%", px: 0, py: 1 }}
        titleTypographyProps={{
          variant: "h5",
          sx: {
            fontWeight: 500,
            letterSpacing: "-0.01562em",
          },
        }}
        subheaderTypographyProps={{
          variant: "body2",
        }}
        action={pageHeaderAction}
      />
    </Card>
  );
};

type Props = {
  data: Orders;
  noHistory: boolean;
  loading: boolean;
  showDomain: boolean | null;
  graphData: GraphData;
};

function OrderHistoryTable(props: Props) {
  const classes = useToolbarStyles();
  const { isMobile } = useFullScreen();
  const {
    tableState,
    filteredRows,
    rows,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleRequestFilter,
  } = useTableState(props.data, {
    sort: "timestamp",
  });

  return (
    <Grid container spacing={1}>
      <Grid size={12}>
        <EnhancedTableToolbar data={filteredRows} />
      </Grid>
      {props.noHistory && (
        <div className={classes.noHistory}>
          <Typography variant="h5" color="textSecondary">
            No Orders History
          </Typography>
        </div>
      )}
      {!props.loading && props.graphData.length > 0 && (
        <Grid size={12}>
          <LicensesCardWithData data={props.graphData} fallbackComponent={<></>} />
        </Grid>
      )}
      {!props.loading && (
        <Grid size={12}>
          <Paper>
            <FilterContextProvider persistenceKey={undefined} defaultValue={undefined} columns={filterColumns}>
              <Toolbar disableGutters className={classes.tableToolbar}>
                <EnhancedTableFilter
                  data={props.data}
                  columns={filterColumns}
                  onFilter={handleRequestFilter}
                  placeholder="Filter orders"
                  style={undefined}
                  defaultValue={undefined}
                  limitTags={undefined}
                />
              </Toolbar>
              <TableContainer>
                <Table aria-labelledby="Order history" size="small">
                  <EnhancedTableHead
                    order={tableState.direction}
                    orderBy={tableState.sort}
                    onRequestSort={handleRequestSort}
                    showDomain={props.showDomain}
                  />
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow hover key={row.id}>
                        <TableCell component="th" id={`row-${index}`} scope="row" padding="normal">
                          {isMobile
                            ? row.timestamp.toFormat("dd/LL/yyyy").toLocaleString()
                            : row.timestamp.toLocaleString(DateTime.DATETIME_MED)}
                        </TableCell>
                        <Hide mdDown>
                          <TableCell align="left">{row.email}</TableCell>
                        </Hide>
                        <TableCell align="left">
                          {isMobile ? (
                            <span style={{ whiteSpace: "nowrap" }}>
                              {row.skuName} ({row.quantity}){" "}
                            </span>
                          ) : (
                            row.skuName
                          )}
                        </TableCell>
                        <Hide mdDown>
                          {props.showDomain === true && <TableCell align="left">{row.domain}</TableCell>}
                          <TableCell style={{ padding: 0 }} align="left">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: row.quantity > 0 ? green[600] : red[600],
                              }}
                            >
                              <span style={{ width: 35, textAlign: "right" }}>
                                {row.quantity > 0 ? `+${row.quantity}` : row.quantity}
                              </span>
                              <span style={{ paddingTop: 5, textAlign: "left" }}>
                                {row.quantity > 0 ? <ArrowDropUp /> : <ArrowDropDown />}
                              </span>
                            </div>
                          </TableCell>
                        </Hide>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    {filteredRows.length > 0 && (
                      <TableRow>
                        <TablePagination
                          count={filteredRows.length}
                          page={tableState.page}
                          rowsPerPage={tableState.rowsPerPage}
                          rowsPerPageOptions={tableState.rowsPerPageOptions}
                          labelRowsPerPage="Per page"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to > -1 ? to : count} of ${count}`}
                          slotProps={{
                            actions: {
                              previousButton: {
                                "aria-label": "Previous Page",
                              },
                              nextButton: {
                                "aria-label": "Next Page",
                              },
                            },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    )}
                  </TableFooter>
                </Table>
              </TableContainer>
            </FilterContextProvider>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

export default OrderHistoryTable;
