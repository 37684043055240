import React from "react";

import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
  error: string;
};
export const AwsDirectSupportFailureDialog = ({ open, onClose, error }: Props) => {
  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle data-cy="title">AWS Support</DialogTitle>
      <DialogContent>
        <Alert severity="error" data-cy="error">
          <AlertTitle>
            Could not access AWS Account using DoiT AWS Ops - please check if the role in AWS Account is still
            installed.
          </AlertTitle>
          {error}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            handleClose(e);
          }}
          color="primary"
          variant="contained"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
