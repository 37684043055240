import { TableCell, Typography } from "@mui/material";

type Props = {
  children: React.ReactNode;
};

export const HeaderCell = ({ children, ...props }: Props) => (
  <TableCell {...props}>
    <Typography variant="caption">{children}</Typography>
  </TableCell>
);
