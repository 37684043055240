import { DashboardProcessingContextProvider } from "../../Context/DashboardProcessingContext";
import { type PageId } from "../../Navigation/config/pages";
import { CustomerDashboards } from "../Customer/CustomerDashboards";
import { HomePage } from "./HomePage";

export default function HomePageOrDashboards({
  pageId,
}: {
  pageId: Extract<PageId, "home-page" | "customer-dashboards">;
}) {
  return pageId === "home-page" ? (
    <HomePage />
  ) : (
    <DashboardProcessingContextProvider>
      <CustomerDashboards />
    </DashboardProcessingContextProvider>
  );
}
