import { type SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import { AssetTypeGoogleCloud, AssetTypeLooker, ContractModel, DoitRole } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import maxBy from "lodash/maxBy";

import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import { type FormattedContract } from "../../../ContractsList/types";
import { useCustomerContracts } from "../../../hooks";
import { useContractFormContext } from "../../ContractsFormContext";
import { getContractLabel } from "../../utils";
import { AssetsAutocomplete } from "../components/AssetsAutocomplete";

export const GcpPLPSStep = () => {
  const { state, handleChange, setState } = useContractFormContext();
  const [contractOptions, setContractOptions] = useState<FormattedContract[]>([]);
  const { contracts } = useCustomerContracts();
  const isContractAdmin = useDoitRoleCheck(DoitRole.ContractAdmin);
  const isContractOwner = useDoitRoleCheck(DoitRole.ContractOwner);

  const handleContractChange = useCallback(
    (_, value) => {
      setState({
        ...state,
        gcpContract: value.filter((v) => !!v).map((v) => getCollection(ContractModel).doc(v.id)),
        errors: {
          ...state.errors,
          gcpContract: false,
        },
      });
    },
    [setState, state]
  );

  const selectedContracts = useMemo(
    () =>
      contractOptions.map((contract) => ({
        id: contract.id,
        type: contract.data.type,
        label: getContractLabel(contract),
      })),
    [contractOptions]
  );

  const selectedContractsValue = useMemo(
    () => selectedContracts.filter((option) => state.gcpContract.findIndex((v) => v.id === option.id) > -1),
    [selectedContracts, state.gcpContract]
  );

  const addMostRecentContracts = (gcpContracts, contractType, latestContracts) => {
    const contract = maxBy(
      gcpContracts.filter((contract) => contract.data.type === contractType),
      "data.startDate"
    );

    if (contract) {
      latestContracts.push(contract);
    }
  };

  useEffect(() => {
    if (!contractOptions.length) {
      const customEvent: SyntheticEvent<Element, Event> = new Event("change") as unknown as SyntheticEvent<
        Element,
        Event
      >;

      const gcpContracts = contracts.filter(
        (item) =>
          (item.data.type === AssetTypeGoogleCloud || item.data.type === AssetTypeLooker) &&
          item.data._status === "active"
      );

      const latestContracts: FormattedContract[] = [];
      addMostRecentContracts(gcpContracts, AssetTypeGoogleCloud, latestContracts);
      addMostRecentContracts(gcpContracts, AssetTypeLooker, latestContracts);

      setContractOptions(gcpContracts);
      if (state.gcpContract.length) {
        handleContractChange(customEvent, state.gcpContract);
      } else {
        handleContractChange(customEvent, latestContracts);
      }
    }
  }, [contractOptions.length, contracts, handleContractChange, state.gcpContract]);

  return (
    <Container maxWidth="sm" data-cy="contract-step">
      <Grid container spacing={1}>
        <Grid container spacing={1}>
          <Grid size={12}>
            <Typography
              variant="h4"
              sx={{
                mb: 1,
                mt: 1,
              }}
            >
              Partner-Led Premium Support
            </Typography>
            <Autocomplete
              multiple
              filterSelectedOptions
              disableCloseOnSelect
              options={selectedContracts}
              getOptionLabel={(option) => option.label}
              fullWidth
              onChange={handleContractChange}
              value={selectedContractsValue}
              disabled={state.editMode && (!isContractAdmin || !isContractOwner)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Google Cloud contract"
                  required
                  margin="dense"
                  error={state.errors?.gcpContract}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                    },
                  }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip size="small" label={option.label} {...getTagProps({ index })} key={option.id} />
                ))
              }
            />
          </Grid>
          <Grid size={12}>
            <AssetsAutocomplete
              disabled={state.isEditForbidden || state.editMode}
              label="Billing Account(s)"
              textFieldProps={{
                error: state.errors?.assets,
                required: true,
              }}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              fullWidth
              value={state.plpsPercent}
              onChange={handleChange("plpsPercent")}
              label="Customer charge"
              margin="dense"
              type="number"
              helperText="Charge can be between 0%-10%"
              required
              slotProps={{
                input: {
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                },
              }}
              error={state.errors?.plpsPercent}
              disabled={state.editMode && (!isContractAdmin || !isContractOwner)}
            />
          </Grid>
          <Grid size={12}>
            <Alert severity="info">
              Selecting multiple Billing Accounts will create a Partner-Led Premium Support contract for each Billing
              Account
            </Alert>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
