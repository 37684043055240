import { type AuthenticationProvider, type CustomerAuth } from "@doitintl/cmp-models";
import cloneDeep from "lodash/cloneDeep";

import { getCallbackUrl, getSpProviderId, isAuthProviderType } from "./helpers";
import { allAuthProvidersVal, type AuthAction, AuthActionKind, type AuthState } from "./types";

export const getInitialState = (
  primaryDomain: string,
  allowAuthProvider: AuthenticationProvider | null | undefined,
  customerAuth: CustomerAuth | undefined
): AuthState => ({
  ssoEnabled: false,
  loading: false,
  error: false,
  saml: {
    enabled: false,
    spEntityId: getSpProviderId(primaryDomain),
    callbackUrl: getCallbackUrl(!!customerAuth?.sso?.useSsoWithoutProxy),
  },
  oidc: { enabled: false, callbackUrl: getCallbackUrl(!!customerAuth?.sso?.useSsoWithoutProxy) },
  authProvider: isAuthProviderType(allowAuthProvider) ? allowAuthProvider : allAuthProvidersVal,
  autoProvision:
    // in case the customer never configured the auto-provision we want to set a default. the way to check if the customer configured it is to check if the enabled property is undefined
    customerAuth?.autoProvision?.enabled === undefined
      ? {
          // setting default values for autoProvision with primaryDomain as the only allowed domain
          enabled: false,
          allowedDomains: primaryDomain ? [primaryDomain] : [],
        }
      : {
          enabled: customerAuth?.autoProvision?.enabled || false,
          allowedDomains: customerAuth?.autoProvision?.allowedDomains || [],
        },
});

const reducer = (state: AuthState, action: AuthAction): AuthState => {
  const newState: AuthState = cloneDeep(state);
  switch (action.type) {
    case AuthActionKind.SET_SSO_PROVIDER_CONFIG:
      newState.saml = action.payload.saml ? action.payload.saml : newState.saml;
      newState.oidc = action.payload.oidc ? action.payload.oidc : newState.oidc;
      newState.ssoEnabled = newState.saml?.enabled || newState.oidc?.enabled;
      break;
    case AuthActionKind.SET_AUTH_SETTINGS:
      newState.authProvider = action.payload.authProvider;
      newState.autoProvision = action.payload.autoProvision;
      newState.ssoEnabled = newState.saml?.enabled || newState.oidc?.enabled;
      break;
    case AuthActionKind.PENDING_REQUEST:
      newState.loading = true;
      newState.error = false;
      newState.errorMessage = undefined;
      break;
    case AuthActionKind.RESET_ERROR_STATE:
      newState.error = false;
      newState.errorMessage = undefined;
      break;
    case AuthActionKind.SUCCESSFUL_REQUEST:
      newState.loading = false;
      newState.error = false;
      newState.errorMessage = undefined;
      break;
    case AuthActionKind.FAILED_REQUEST:
      newState.loading = false;
      newState.error = true;
      newState.errorMessage = action.payload;
      break;
    case AuthActionKind.TOGGLE_SSO:
      newState.ssoEnabled = action.payload;
      break;
    default: {
      // do nothing
    }
  }
  return newState;
};

export default reducer;
