import { type ChangeEvent, useRef, useState } from "react";

import { AskButton } from "@doitintl/ava-components/src/Messenger/AskButton";
import { StopGenerationButton } from "@doitintl/ava-components/src/Messenger/StopGenerationButton";
import { Stack, TextField, Typography } from "@mui/material";

import { useConversationIdChanged } from "./hooks";

type Props = {
  disableInput: boolean;
  onAskQuestionClicked: (question: string) => Promise<void>;
  isHelpCenter?: boolean;
  showStopButton: boolean;
  handleStopGeneration: () => void;
};

export const MessengerFooter = ({
  disableInput,
  onAskQuestionClicked,
  isHelpCenter,
  showStopButton,
  handleStopGeneration,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [question, setQuestion] = useState("");
  const [isComposing, setIsComposing] = useState(false);

  useConversationIdChanged(() => {
    ref.current?.focus();
  });

  const handleAskQuestion = async () => {
    if (question.trim().length === 0) {
      return;
    }
    onAskQuestionClicked(question.trim());
    setQuestion("");
  };

  return (
    <Stack
      sx={{
        backgroundColor: "background.paper",
        px: 15,
        pt: 1,
        borderTopWidth: 1,
        borderTopColor: "divider",
        borderTopStyle: "solid",
        mt: 0,
      }}
    >
      <Stack direction="row">
        <TextField
          inputRef={ref}
          onCompositionStart={() => {
            setIsComposing(true);
          }}
          onCompositionEnd={() => {
            setIsComposing(false);
          }}
          fullWidth
          multiline
          autoFocus
          label="Ask Ava"
          value={question}
          placeholder={isHelpCenter ? "How do I create a report?" : "Determine my total cloud cost for the last month"}
          disabled={disableInput && !showStopButton}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !event.shiftKey && !isComposing) {
              if (showStopButton) {
                event.preventDefault();
                handleStopGeneration();
                return;
              }
              return handleAskQuestion();
            }
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              paddingRight: 2,
            },
          }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (!showStopButton) {
              setQuestion(event.target.value);
            } else {
              setQuestion("");
            }
          }}
          data-cy="ask-question-input"
          InputProps={{
            endAdornment: !showStopButton ? (
              <AskButton handleAskQuestion={handleAskQuestion} question={question} processingQuestion={disableInput} />
            ) : (
              <StopGenerationButton handleStopGeneration={handleStopGeneration} />
            ),
          }}
        />
      </Stack>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          pb: 0.5,
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "text.disabled",
            width: isHelpCenter ? "100%" : "76%",
          }}
        >
          Ava may give inaccurate answers, so make sure to verify them.
        </Typography>
      </Stack>
    </Stack>
  );
};
