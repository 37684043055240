import TransformIcon from "@mui/icons-material/Transform";

import { type RFNode } from "../../types";
import { SourceHandle, TargetHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: RFNode;
  selected?: boolean;
};

export const TransformNode = ({ id, data, selected }: Props) => (
  <>
    <NodeWrapper
      title="Transform"
      status={data.nodeData.status || "validated"}
      onEdit={data.onEditNode}
      onDelete={data.onDeleteNode}
      isActive={!!selected}
      icon={<TransformIcon color="primary" fontSize="small" />}
      name={`${data.stepNumber}. ${data.nodeData.name || "Transform"}`}
    />
    <SourceHandle id={id} />
    <TargetHandle id={id} />
  </>
);
