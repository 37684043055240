import { useCallback, useMemo } from "react";

import {
  type CloudFlowNodeType,
  CustomFrequencies,
  type DateObjectUnits,
  Frequencies,
  NODE_STATUS,
} from "@doitintl/cmp-models";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Autocomplete, Box, Button, Card, CardHeader, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import * as Yup from "yup";

import { cloudflowTexts } from "../../../../../assets/texts";
import { allTimezones } from "../../../../../Components/NotificationSubscription/allTimeZones";
import { RequiredLabel } from "../../../../../Components/NotificationSubscription/helpers";
import DateType from "../../../../../utils/dateType";
import { isDateObjectUnits } from "../../utils/timeUtils";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import { ScheduleInfo } from "./ScheduleInfo";

const validationSchema = Yup.object({
  timeZone: Yup.string().required(cloudflowTexts.VALIDATION.TIME_ZONE_REQUIRED).default(null),
  startDate: Yup.date()
    .typeError(cloudflowTexts.VALIDATION.INVALID_DATE)
    .required(cloudflowTexts.VALIDATION.START_DATE_REQUIRED)
    .default(null),
  time: Yup.date()
    .typeError(cloudflowTexts.VALIDATION.INVALID_TIME)
    .required(cloudflowTexts.VALIDATION.TIME_REQUIRED)
    .default(null),
  frequency: Yup.string()
    .oneOf(Object.values(Frequencies))
    .required(cloudflowTexts.VALIDATION.FREQUENCY_REQUIRED)
    .default(null),
  customFrequencyAmount: Yup.number()
    .when("frequency", ([frequency], schema) =>
      frequency === Frequencies.Custom
        ? schema
            .required(cloudflowTexts.VALIDATION.REPEATS_EVERY_REQUIRED)
            .integer()
            .typeError(cloudflowTexts.VALIDATION.INVALID_NUMBER)
            .min(1, cloudflowTexts.VALIDATION.REPEATS_EVERY_MIN)
        : schema.notRequired().nullable()
    )
    .default(null),
  customFrequency: Yup.string()
    .when("frequency", ([frequency], schema) =>
      frequency === Frequencies.Custom
        ? schema
            .oneOf(Object.values(CustomFrequencies), cloudflowTexts.VALIDATION.CUSTOM_FREQUENCY_REQUIRED)
            .required(cloudflowTexts.VALIDATION.CUSTOM_FREQUENCY_REQUIRED)
        : schema.notRequired().nullable()
    )
    .default(null),
});

const ScheduleTab = () => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.TRIGGER>();
  const { updateNode, onChangeTriggerType } = useNodeConfigurationContext<CloudFlowNodeType.TRIGGER>();

  const reorderedTimezones = useMemo(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return [tz, ...allTimezones.filter((zone) => zone !== tz)];
  }, []);

  const initialValues = useMemo(() => {
    const defaultValues = validationSchema.getDefault();
    const params = { ...defaultValues, ...nodeConfig.parameters };
    const startDate = isDateObjectUnits(params.startDate) ? DateTime.fromObject(params.startDate) : null;
    const time = isDateObjectUnits(params.time) ? DateTime.fromObject(params.time) : null;

    const convertedParams = {
      ...params,
      startDate: startDate?.isValid ? startDate : null,
      time: time?.isValid ? time : null,
    };

    return convertedParams;
  }, [nodeConfig.parameters]);

  const { errors, values, handleBlur, touched, setValues, validateForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
  });

  const saveParameters = useCallback(
    async (newParams) => {
      const params = { ...values, ...newParams };
      setValues(params);
      const formErrors = await validateForm(params);
      const isValid = Object.keys(formErrors).length === 0;
      const errorText = Object.values(formErrors).filter(Boolean).join(", ");
      const startDate: DateObjectUnits | null =
        DateType.isDateTime(params.startDate) && params.startDate.isValid
          ? {
              year: params.startDate.year,
              month: params.startDate.month,
              day: params.startDate.day,
              weekday: params.startDate.weekday,
            }
          : null;

      const time: DateObjectUnits | null =
        DateType.isDateTime(params.time) && params.time.isValid
          ? { hour: params.time.hour, minute: params.time.minute }
          : null;

      updateNode((prevNode) => {
        const updatedNode = {
          ...prevNode,
          parameters: {
            ...params,
            startDate,
            time,
          },
          status: isValid ? NODE_STATUS.VALIDATED : NODE_STATUS.ERROR,
          errors: isValid
            ? { ...prevNode.errors, param_error: undefined }
            : { ...prevNode.errors, param_error: errorText },
        };
        return updatedNode;
      });
    },
    [setValues, validateForm, updateNode, values]
  );

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Stack
        spacing={2}
        sx={{
          alignContent: "center",
        }}
      >
        <Card sx={{ "&:hover": { borderColor: (theme) => theme.palette.primary.main } }}>
          <CardHeader
            subheader="Trigger set on a custom schedule"
            title="Scheduled"
            titleTypographyProps={{ variant: "body2", textDecoration: "none" }}
            subheaderTypographyProps={{ variant: "caption" }}
            action={<Button onClick={onChangeTriggerType}>Change</Button>}
            sx={{ ".MuiCardHeader-action": { alignSelf: "center" }, p: 1, pr: 2 }}
            avatar={<TimerOutlinedIcon color="primary" />}
          />
        </Card>
        <Typography variant="subtitle2">Parameters</Typography>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Autocomplete
              options={reorderedTimezones}
              value={values.timeZone}
              onChange={(_event, newValue) => {
                saveParameters({ timeZone: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={RequiredLabel("Time zone")}
                  variant="outlined"
                  fullWidth
                  name="timeZone"
                  onBlur={handleBlur}
                  error={touched.timeZone && Boolean(errors.timeZone)}
                  helperText={touched.timeZone && errors.timeZone}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label={RequiredLabel("Start date")}
                value={values.startDate}
                onChange={(newValue) => saveParameters({ startDate: newValue })}
                minDate={DateTime.now()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    name="startDate"
                    onBlur={handleBlur}
                    error={touched.startDate && Boolean(errors.startDate)}
                    helperText={touched.startDate && errors.startDate?.toString()}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid size={12}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <TimePicker
                ampm={false}
                label={RequiredLabel("Time")}
                value={values.time}
                onChange={(value) => {
                  saveParameters({ time: value });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                    name="time"
                    onBlur={handleBlur}
                    error={touched.time && Boolean(errors.time)}
                    helperText={touched.time && errors.time?.toString()}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid size={12}>
            <Autocomplete
              options={Object.values(Frequencies)}
              value={values.frequency}
              onChange={(_event, frequency) => {
                saveParameters({ frequency });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={RequiredLabel("Frequency")}
                  variant="outlined"
                  fullWidth
                  name="frequency"
                  onBlur={handleBlur}
                  error={touched.frequency && Boolean(errors.frequency)}
                  helperText={touched.frequency && errors.frequency}
                />
              )}
              fullWidth
              aria-required={true}
            />
          </Grid>
          <Grid size={12}>
            {values.frequency === Frequencies.Custom && (
              <Grid container spacing={2}>
                <Grid size={6}>
                  <TextField
                    label={RequiredLabel("Repeats every")}
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={values.customFrequencyAmount}
                    onChange={(event) => {
                      saveParameters({ customFrequencyAmount: parseInt(event.target.value) });
                    }}
                    onBlur={handleBlur}
                    name="customFrequencyAmount"
                    error={touched.customFrequencyAmount && Boolean(errors.customFrequencyAmount)}
                    helperText={touched.customFrequencyAmount && errors.customFrequencyAmount}
                    slotProps={{
                      htmlInput: { min: 1 },
                    }}
                  />
                </Grid>
                <Grid size={6}>
                  <Autocomplete
                    options={Object.values(CustomFrequencies)}
                    value={values.customFrequency}
                    onChange={(_event, customFrequency) => {
                      saveParameters({ customFrequency });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={RequiredLabel("Frequency")}
                        variant="outlined"
                        fullWidth
                        name="customFrequency"
                        onBlur={handleBlur}
                        error={touched.customFrequency && Boolean(errors.customFrequency)}
                        helperText={touched.customFrequency && errors.customFrequency}
                      />
                    )}
                    fullWidth
                    aria-required={true}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <ScheduleInfo {...values} />
      </Stack>
    </Box>
  );
};

export default ScheduleTab;
