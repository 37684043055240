import { type FC, Fragment, useCallback, useEffect, useState } from "react";

import { type CloudFlowNodeType, type ConditionExpressionsGroup } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import { Button, Stack, Typography } from "@mui/material";

import { cloudflowTexts } from "../../../../assets/texts";
import { type NodeWitOutputModel } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";
import FilterGroupComp from "./FilterGroup";

type ConditionFormProps = {
  selectedNode: NodeWitOutputModel | undefined;
  referenceableNodes: NodeWitOutputModel[];
  texts: {
    addNewGroupText: string;
    defineCriteriaText: string;
    fromChosenFieldText: string;
    groupText: string;
    addNewText: string;
  };
};

const ConditionForm: FC<ConditionFormProps> = ({
  selectedNode,
  referenceableNodes,
  texts: { addNewGroupText, defineCriteriaText, fromChosenFieldText, groupText, addNewText },
}) => {
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.FILTER>();

  const conditionGroups = nodeConfig.parameters.conditionGroups;

  const [isDefaultGroupAdded, setIsDefaultGroupAdded] = useState(() => !!conditionGroups?.length);

  const handleAddGroup = useCallback(() => {
    const newGroup: ConditionExpressionsGroup = {
      conditions: [],
    };

    updateNode((prevNode) => ({
      parameters: { ...prevNode.parameters!, conditionGroups: [...conditionGroups, newGroup] },
    }));
  }, [conditionGroups, updateNode]);

  const handleDeleteConditionGroup = useCallback(
    (groupIndex: number) => {
      updateNode((prevNode) => ({
        parameters: {
          ...prevNode.parameters!,
          conditionGroups: conditionGroups.filter((_, index) => index !== groupIndex),
        },
      }));
    },
    [conditionGroups, updateNode]
  );

  useEffect(() => {
    if (selectedNode && !isDefaultGroupAdded && conditionGroups?.length === 0) {
      handleAddGroup();
      setIsDefaultGroupAdded(true);
    }
  }, [selectedNode, isDefaultGroupAdded, handleAddGroup, conditionGroups?.length]);

  return (
    <Stack>
      <Stack
        sx={{
          mb: 1.5,
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          {defineCriteriaText}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          {fromChosenFieldText}
        </Typography>
      </Stack>
      <Stack
        sx={{
          alignItems: "flex-start",
        }}
      >
        {conditionGroups.map((_, index) => (
          <Fragment key={index}>
            <FilterGroupComp
              groupIndex={index}
              selectedNode={selectedNode}
              referenceableNodes={referenceableNodes}
              texts={{
                groupText,
                addNewText,
              }}
              onDeleteConditionGroup={() => {
                handleDeleteConditionGroup(index);
              }}
            />

            {index + 1 !== conditionGroups.length && (
              <Typography
                variant="caption"
                sx={{
                  mt: 1,
                  mb: 0.5,
                }}
              >
                {cloudflowTexts.OR}
              </Typography>
            )}
          </Fragment>
        ))}
        <Button
          startIcon={<AddIcon fontSize="small" />}
          onClick={handleAddGroup}
          color="primary"
          disabled={!selectedNode}
          sx={{ textAlign: "left", my: 0.8 }}
        >
          {addNewGroupText}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ConditionForm;
