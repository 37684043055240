import { useCallback, useState } from "react";

import { Box, Dialog, DialogTitle } from "@mui/material";

import { ChatToolbar } from "../../Ava2/ChatToolbar";
import { Messenger } from "../../Ava2/Messenger";
import { AvaMixpanelCallbackFunc } from "../utils";

type Props = {
  handleClose: (withoutConversation: boolean, mixpanelCallback?: (event: string) => void) => void;
};

export const AVADialog = ({ handleClose }: Props) => {
  const [conversationHasOccurred, setConversationHasOccurred] = useState<boolean>(false);

  const closeDialog = useCallback(() => {
    handleClose(!conversationHasOccurred, AvaMixpanelCallbackFunc);
  }, [conversationHasOccurred, handleClose]);

  return (
    <Dialog fullWidth maxWidth="md" open={true} onClose={closeDialog}>
      <DialogTitle sx={{ borderBottom: 1, borderColor: "divider" }}>
        <ChatToolbar onCloseClicked={closeDialog} />
      </DialogTitle>
      <Box
        sx={{
          height: "75vh",
        }}
      >
        <Messenger
          conversationCallback={() => {
            setConversationHasOccurred(true);
          }}
        />
      </Box>
    </Dialog>
  );
};
