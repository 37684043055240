import { useEffect, useMemo, useState } from "react";

import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { type FilterTableToolbarProps } from "../../../Components/FilterTable/Toolbar/FilterTableToolbar";
import { useAsyncCurrency } from "../../../Components/hooks/useCurrency";
import useFiltersFromUrl from "../../../Components/hooks/useFiltersFromUrl";
import mixpanel from "../../../utils/mixpanel";
import { useInsights } from "../../Insights/api";
import { useJiraApi } from "../../Integrations/Jira/useJiraApi";
import { useMultipleJiraIssues, useThreads } from "../api";
import { createImpactFormatter, getImpactAndUnits } from "../utils";
import { threadsOverviewColumns, threadsOverviewHeaders } from "./ThreadsOverviewColumns";
import { ThreadsOverviewHeader } from "./ThreadsOverviewHeader";
import { ThreadsOverviewNoFilterResults } from "./ThreadsOverviewNoFilterResults";
import { ThreadsOverviewNoJiraConnection } from "./ThreadsOverviewNoJiraConnection";
import { ThreadsOverviewRow } from "./ThreadsOverviewRow";
import { ThreadsOverviewTableEmpty } from "./ThreadsOverviewTableEmpty";

export type ThreadRowData = {
  id: string;
  issueKey: string;
  name: string;
  source: string;
  sourceLink: string;
  targetLink: string;
  jiraLink: string;
  impact: number;
  impactUnits: string;
  impactFormatter: (impact: number) => string;
  assignee: string;
  priority: string;
  status: string;
  errorLoadingJiraIssue: boolean;
  showSkeleton: boolean;
  sourceKey: string;
};

export const ThreadsOverview = () => {
  const { jiraInstanceData } = useJiraApi();

  useEffect(() => {
    mixpanel.track("threads.list.open");
  }, []);

  const { insights, isLoading: isLoadingInsights } = useInsights();

  const { threads, isFetching: isFetchingThreads } = useThreads();

  const issueKeys = useMemo(
    () => threads?.map((thread) => thread.targetData.issueKey).filter(Boolean) || [],
    [threads]
  );

  const jiraIssues = useMultipleJiraIssues(issueKeys);

  const allJiraIssuesLoaded = Object.values(jiraIssues).every((jiraIssue) => !jiraIssue.isLoading);

  const { asyncConvertCurrency, customerCurrencySymbol } = useAsyncCurrency();

  const [enrichedThreads, setEnrichedThreads] = useState<ThreadRowData[]>([]);

  const filtersFromQueryString = useFiltersFromUrl<ThreadRowData>();

  useEffect(() => {
    if (!threads) {
      return;
    }

    Promise.all(
      threads.map(async (thread) => {
        const matchingInsight = (insights || []).find((insight) => insight.key === thread.sourceData.insightKey);

        let sourceLink;

        if (matchingInsight) {
          sourceLink = `insights/${matchingInsight.providerId}/${matchingInsight.key}`;
        }

        const {
          value: impact,
          units: impactUnits,
          isCurrency,
        } = await getImpactAndUnits(matchingInsight, asyncConvertCurrency, customerCurrencySymbol);

        const jiraIssue = jiraIssues[thread.targetData.issueKey] || null;

        return {
          id: thread.id,
          assignee: jiraIssue?.data?.assignee || "Unassigned",
          impact,
          impactUnits,
          impactFormatter: createImpactFormatter(impactUnits, isCurrency),
          issueKey: thread.targetData.issueKey,
          name: jiraIssue?.data?.summary || "",
          priority: jiraIssue?.data?.priority || "",
          source: thread.source,
          sourceLink,
          targetLink: `threads/${thread.id}`,
          jiraLink: thread.targetData.issueURL,
          status: jiraIssue?.data?.status || "",
          errorLoadingJiraIssue: jiraIssue?.isError || false,
          showSkeleton: !allJiraIssuesLoaded,
          sourceKey: `${thread.sourceData.providerID}/${thread.sourceData.insightKey}`,
        };
      })
    ).then(setEnrichedThreads);
  }, [allJiraIssuesLoaded, asyncConvertCurrency, customerCurrencySymbol, insights, jiraIssues, threads]);

  // If we're still fetching Jira instance data, insights, or threads...
  if (jiraInstanceData === undefined || isLoadingInsights || isFetchingThreads) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 6,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (jiraInstanceData === null || jiraInstanceData.status !== "connected") {
    return <ThreadsOverviewNoJiraConnection />;
  }

  const toolbar: FilterTableToolbarProps = {
    title: <ThreadsOverviewHeader />,
  };

  return (
    <>
      <FilterTable<ThreadRowData>
        toolbarProps={toolbar}
        tableItems={enrichedThreads}
        emptyTableComponent={
          enrichedThreads.length > 0 ? <ThreadsOverviewNoFilterResults /> : <ThreadsOverviewTableEmpty />
        }
        rowComponent={ThreadsOverviewRow}
        headerColumns={threadsOverviewHeaders}
        filterColumns={threadsOverviewColumns}
        filterBarPlaceholder="Filter threads"
        defaultSortingColumnValue="impact"
        defaultSortDirection="desc"
        defaultFilters={filtersFromQueryString}
        showFilterBar
        showHeader
      />
    </>
  );
};
