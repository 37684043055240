import { useMemo } from "react";

import { useIsFeatureEntitledMultiple } from "../../Context/TierProvider";

function UseFilteredCategories(categories) {
  const categoryFeaturesEntitlementMap = useIsFeatureEntitledMultiple([
    "pdi:datahub",
    "governance:insights",
    "governance:threads",
    "snowflake:connect",
    "enablement:training",
  ]);

  return useMemo(() => {
    const {
      "pdi:datahub": dataHubEntitled,
      "governance:insights": insightsEntitled,
      "governance:threads": threadsEntitled,
      "snowflake:connect": snowflakeLensEntitled,
      "enablement:training": enablementTrainingEntitled,
    } = categoryFeaturesEntitlementMap;

    let filteredCategories = categories;

    if (!dataHubEntitled) {
      filteredCategories = filteredCategories.filter((category) => category.id !== "cmp/datahub");
    }

    if (!insightsEntitled) {
      filteredCategories = filteredCategories.filter((category) => category.id !== "cmp/insights");
    }

    if (!threadsEntitled) {
      filteredCategories = filteredCategories.filter((category) => category.id !== "cmp/threads");
    }

    if (!snowflakeLensEntitled) {
      filteredCategories = filteredCategories.filter((category) => category.id !== "cmp/snowflake-lens");
    }

    if (!enablementTrainingEntitled) {
      filteredCategories = filteredCategories.filter((category) => category.id !== "cmp/product-training");
    }

    return filteredCategories;
  }, [categoryFeaturesEntitlementMap, categories]);
}

export default UseFilteredCategories;
