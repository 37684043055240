import { useEntitlements, useTiers } from "../../Context/TierProvider";
import { type PageId } from "../../Navigation/config/pages";
import EntitlementsTable from "./Entitlements/EntitlementsTable";
import EditTierEntitlements from "./Tiers/EditTier/EditTierEntitlements";
import TiersTable from "./Tiers/TiersTable";

type Props = {
  pageId: PageId;
};

const TierManagement = ({ pageId }: Props) => {
  const [tiers, loadingTiers] = useTiers();

  const [entitlements, loadingEntitlements] = useEntitlements();

  if (pageId === "tier-management") {
    return <TiersTable tiers={tiers} loading={loadingTiers} />;
  }
  if (pageId === "entitlements-management") {
    return <EntitlementsTable entitlements={entitlements} loading={loadingEntitlements} />;
  }

  if (pageId === "tier-management:edit-tier") {
    return <EditTierEntitlements />;
  }
  return null;
};

export default TierManagement;
