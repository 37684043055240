import { type ChartSeriesRecord } from "../../useChartsSeries";
import { type LegendConfig } from "../types";

const AVERAGE_CHAR_WIDTH_PX = 7;
const DEFAULT_LEGEND_WIDTH = 1000;
const DEFAULT_ITEM_GAP = 35;
const DEFAULT_PAGE_ICON_SIZE = 17;

export const shouldLegendWrap = (isCustomMode: boolean, allSeries: ChartSeriesRecord[]): boolean => {
  if (isCustomMode) {
    return false;
  }

  const estimatedLegendWidth = allSeries?.reduce<number>((acc, item, currentIndex) => {
    let res = 0;
    if (item?.name) {
      res = item.name.length * AVERAGE_CHAR_WIDTH_PX;
      if (currentIndex !== allSeries.length - 1) {
        res += DEFAULT_ITEM_GAP + DEFAULT_PAGE_ICON_SIZE;
      }
    }
    return acc + res;
  }, 0);

  return estimatedLegendWidth > DEFAULT_LEGEND_WIDTH;
};

export const getLegendConfig = (
  isCustomMode: boolean,
  fontColor: string,
  pageIconColor: string,
  isLegendWrapped: boolean
): LegendConfig | LegendConfig[] => {
  const commonLegendSettings: LegendConfig = {
    show: true,
    animation: false,
    type: "scroll",
    icon: "circle",
    pageIconColor,
    textStyle: {
      color: fontColor,
    },
    pageTextStyle: {
      color: fontColor,
    },
  };

  const defaultLegend: LegendConfig = {
    ...commonLegendSettings,
    bottom: true,
    width: DEFAULT_LEGEND_WIDTH,
    pageIconSize: DEFAULT_PAGE_ICON_SIZE,
    itemGap: DEFAULT_ITEM_GAP,
  };

  const extendedLegend: LegendConfig = {
    ...commonLegendSettings,
    orient: "none", // workaround for multiline legend
    left: 30,
    width: "100%",
    height: 50,
    itemGap: 20,
    pageIconSize: 15,
  };

  return isCustomMode || !isLegendWrapped
    ? defaultLegend
    : [
        {
          ...extendedLegend,
          bottom: 20,
          pageIconSize: 0,
          pageTextStyle: {
            color: "transparent",
          },
        },
        {
          ...extendedLegend,
          left: "auto",
          right: "50%",
          inactiveColor: "transparent",
          bottom: -20,
          itemStyle: {
            color: "transparent",
          },
          textStyle: {
            color: "transparent",
          },
          selectedMode: false,
        },
      ];
};
