import { collection, type Reference } from "@doitintl/models-types";

import { type AWSSupportData } from "./Asset";
import { type ContractModel } from "./Contract";
import { type CustomerModel } from "./Customer";
import { type EntityModel, type EntityModelBucketModel } from "./Entity";

export type AssetSettingModelAutomation = {
  enabled: boolean;
  quantity: number;
  email: string;
};

export type AssetType =
  | "amazon-web-services"
  | "amazon-web-services-standalone"
  | "amazon-web-services-reseller"
  | "google-cloud-project"
  | "google-cloud"
  | "google-cloud-project-standalone"
  | "google-cloud-standalone"
  | "google-cloud-direct"
  | "google-cloud-reseller"
  | "g-suite"
  | "office-365"
  | "microsoft-azure"
  | "microsoft-azure-standalone"
  | "bettercloud"
  | "zendesk"
  | "looker"
  | "navigator"
  | "solve"
  | "doit-one-time-service-fee"
  | "snowflake"
  | "doit-cloud-intelligence"
  | "kubernetes";

@collection("assetSettings")
export class AssetSettingModel {
  customer!: Reference<CustomerModel> | null;

  entity!: Reference<EntityModel> | null;

  contract?: Reference<ContractModel> | null;

  bucket?: Reference<EntityModelBucketModel> | null;

  automation?: AssetSettingModelAutomation | null;

  type!: AssetType;

  tags?: string[] | null;

  settings?: { support?: AWSSupportData };
}
