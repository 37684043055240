import { type EntityModel, type PaymentMethodType } from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";
import { type AxiosInstance, type AxiosResponse } from "axios";

import { type BillingAddressForm } from "../../../types";
import mixpanel from "../../../utils/mixpanel";
import { type CreateBillingProfileData, type EditBillingProfileData } from "../BillingProfileForm.models";

export type EntityFromPriority = {
  CUSTOMERS: {
    CUSTNAME: string;
    CUSTDES: string;
    INACTIVEFLAG: boolean;
    EMAIL: string;
    ADDRESS: string;
    ADDRESS2: string;
    STATE: string;
    STATEA: string;
    STATECODE: string;
    STATENAME: string;
    ZIP: string;
    COUNTRYNAME: string;
    WTAXNUM: string;
    WTAXNUMEXPL: string;
    CODE: string;
    CUSTPERSONNEL_SUBFORM: [
      {
        NAME: string;
        FIRSTNAME: string;
        LASTNAME: string;
        PHONENUM: string;
        EMAIL: string;
        CIVFLAG: string;
        FIRM: string;
      },
    ];
  };
  FNCCUST: {
    CUSTNAME: string;
    CUSTDES: string;
    CODE: string;
    IVTYPE: string;
    PAYCODE: string;
    PAYDES: string;
  };
};

export type PaymentTerm = {
  PAYCODE: string;
  PAYDES: string;
};

export enum PaymentMethodCategory {
  WIRE_TRANSFER = "wire_transfer",
  BILL_COM = "bill_com",
  STRIPE = "stripe",
}

export enum PaymentMethodDisabledReason {
  EXPIRED = "expired",
  MANDATE_INACTIVE = "mandate_inactive",
  PROCCESSING = "processing",
  REQUIRES_ACTION = "requires_action",
}

export type PaymentMethodCardBrand =
  | "amex"
  | "diners"
  | "discover"
  | "jcb"
  | "mastercard"
  | "unionpay"
  | "visa"
  | "unknown";

export type SavedPaymentMethod = {
  id: string;
  type: PaymentMethodType;
  // checks:   *stripe.PaymentMethodCardChecks ,
  last4: string;
  expYear: number;
  expMonth: number;
  created: number;
  brand: PaymentMethodCardBrand;
  bankName: string;
  name: string;
  email: string;
  bankCode: string;
  disabledReason?: PaymentMethodDisabledReason;
  verificationUrl?: string;
};

export class PriorityApi {
  private readonly apiPrefix: string;

  constructor(
    private readonly api: AxiosInstance,
    customerId: string
  ) {
    this.apiPrefix = `/priority/customers/${customerId}`;
  }

  public async createNewEntity(formValues: BillingAddressForm): Promise<ModelId<EntityModel>> {
    const {
      companyName,
      taxId,
      vatRegistrationType,
      currency,
      address2,
      state,
      stateCode,
      city,
      country,
      address,
      zip,
      firstName,
      lastName,
      email,
      phone,
    } = formValues;

    const customer = {
      CUSTDES: companyName,
      WTAXNUM: taxId,
      WTAXNUMEXPL: vatRegistrationType,
      CODE: currency,
      ADDRESS2: address2,
      STATEA: city,
      STATENAME: state,
      STATECODE: stateCode,
      COUNTRYNAME: country,
      ADDRESS: address,
      ZIP: zip,
      CUSTPERSONNEL_SUBFORM: [
        {
          FIRSTNAME: firstName,
          LASTNAME: lastName,
          EMAIL: email?.toLowerCase().trim(),
          PHONENUM: phone,
        },
      ],
    };

    const response = await this.api.post(`${this.apiPrefix}/entities/`, customer);
    if (!response.data) {
      throw new Error("Missing priority ID value.");
    }
    return response.data;
  }

  public updateContactDetails(
    entityId: string,
    formValues: CreateBillingProfileData,
    entityFromPriority: EntityFromPriority
  ) {
    const [{ NAME, FIRM }] = entityFromPriority.CUSTOMERS.CUSTPERSONNEL_SUBFORM;
    const { firstName: FIRSTNAME, lastName: LASTNAME, email: EMAIL, phone: PHONENUM } = formValues;

    mixpanel.track("entity.set.contact", {
      firstName: FIRSTNAME,
      lastName: LASTNAME,
      email: EMAIL,
      phone: PHONENUM,
    });

    return this.api.request({
      method: "patch",
      url: `${this.apiPrefix}/entities/${entityId}`,
      data: {
        type: "contact",
        FIRM,
        NAME,
        FIRSTNAME,
        LASTNAME,
        EMAIL,
        PHONENUM,
      },
    });
  }

  public updateGeneralDetails(entityId: string, formValues: CreateBillingProfileData) {
    const { companyName: CUSTDES, taxId: WTAXNUM, vatRegistrationType: WTAXNUMEXPL } = formValues;

    mixpanel.track("entity.set.name", {
      name: CUSTDES,
      companyNumber: WTAXNUM,
      vatRegistrationType: WTAXNUMEXPL,
    });

    return this.api.request({
      method: "patch",
      url: `${this.apiPrefix}/entities/${entityId}`,
      data: {
        type: "general",
        CUSTDES,
        WTAXNUM,
        WTAXNUMEXPL,
      },
    });
  }

  public updateAddress(entityId: string, formValues: CreateBillingProfileData) {
    const { state: STATENAME, stateCode: STATECODE, city: STATEA, address: ADDRESS, zip: ZIP } = formValues;

    mixpanel.track("entity.set.address", {
      address: ADDRESS,
      state: STATENAME,
      city: STATEA,
      zip: ZIP,
    });
    return this.api.request({
      method: "patch",
      url: `${this.apiPrefix}/entities/${entityId}`,
      data: {
        type: "address",
        STATENAME,
        STATECODE,
        STATEA,
        ADDRESS,
        ZIP,
      },
    });
  }

  public updateBillingDetails(entityId: string, formValues: EditBillingProfileData) {
    return this.api.request({
      method: "patch",
      url: `${this.apiPrefix}/entities/${entityId}`,
      data: {
        type: "billing",
        PAYCODE: formValues.payCode,
      },
    });
  }

  public async getEntity(entityId: string): Promise<EntityFromPriority> | never {
    const response = await this.api.get(`${this.apiPrefix}/entities/${entityId}`);
    if (!response.data) {
      throw new Error("Unable to load data from priority");
    }
    return response.data;
  }

  public async getPaymentTerms(priorityCompany: string): Promise<PaymentTerm[]> {
    const response = await this.api.get(`/priority/${priorityCompany}/pay`);
    if (!response.data) {
      throw new Error("Unable to load payment terms list");
    }
    return response.data;
  }
}

export class V1Api {
  private readonly apiPrefix: string;

  constructor(
    private readonly api: AxiosInstance,
    customerId: string
  ) {
    this.api = api;
    this.apiPrefix = `/v1/customers/${customerId}`;
  }

  public updatePaymentMethod(entityId: string, formValues: EditBillingProfileData) {
    return this.api.request({
      method: "patch",
      url: `${this.apiPrefix}/entities/${entityId}/paymentMethods`,
      data:
        formValues.paymentMethodCategory === PaymentMethodCategory.STRIPE
          ? {
              payment_method_id: formValues.paymentMethodId,
              type: formValues.paymentMethodType,
            }
          : {
              type: formValues.paymentMethodCategory,
            },
    });
  }

  public async getSavedPaymentMethods(entityId: string) {
    const response = await this.api.request<unknown, AxiosResponse<SavedPaymentMethod[], void>>({
      method: "get",
      url: `${this.apiPrefix}/entities/${entityId}/paymentMethods`,
    });

    return response.data;
  }

  public async deleteSavedPaymentMethod(entityId: string, paymentMethodType: string, paymentMethodId: string) {
    const response = await this.api.request<unknown, AxiosResponse<SavedPaymentMethod[], void>>({
      method: "delete",
      url: `${this.apiPrefix}/entities/${entityId}/paymentMethods`,
      data: {
        type: paymentMethodType,
        payment_method_id: paymentMethodId,
      },
    });

    return response.data;
  }
}
