import { type AttributionFilter, type Metadata } from "@doitintl/cmp-models";
import { FormControl, MenuItem, Select, type SelectChangeEvent, Stack } from "@mui/material";

import { FilterDialogContainer } from "../../Components/Dialogs/CloudAnalytics/FilterDialogContainer";
import { useDimension } from "../../Components/Dialogs/CloudAnalytics/hooks";
import { type FixedFilters } from "../CloudAnalytics/utilities";
import { AttributionChips } from "./AttributionChips";

type Props = {
  dimensionId: `${Metadata}:${FixedFilters}`;
  value: AttributionFilter;
  onChange: (value: AttributionFilter) => void;
};

export const AttributeSelector = ({ dimensionId, value, onChange }: Props) => {
  const dimension = useDimension(dimensionId);

  if (!dimension) {
    return null;
  }

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    onChange({ ...value, inverse: event.target.value === "false" });
  };

  return (
    <Stack spacing={3} direction="row">
      <FilterDialogContainer
        onSave={(filter) => {
          onChange(filter);
        }}
        dimension={dimension}
      />
      <FormControl variant="outlined" size="small" sx={{ width: 100, minWidth: 100 }}>
        <Select value={value?.inverse ? "false" : "true"} onChange={handleDropdownChange}>
          <MenuItem value="true">is</MenuItem>
          <MenuItem value="false">is not</MenuItem>
        </Select>
      </FormControl>
      <AttributionChips filter={value} dimension={dimension} />
    </Stack>
  );
};
