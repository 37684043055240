import { useEffect, useState } from "react";

import { UserModel } from "@doitintl/cmp-models/src";
import { getCollection } from "@doitintl/models-firestore/src";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, type AlertColor } from "@mui/material";

import { getAlert } from "../utils";
import type { Insight, StatusDetails } from "../types";

const mapping = {
  success: <CheckCircleOutlineIcon />,
  info: <InfoOutlinedIcon />,
  error: <ErrorOutlineOutlinedIcon />,
};

export const InsightStatusInfoBar = ({ insight }: { insight: Insight }) => {
  const [statusDetails, setStatusDetails] = useState<StatusDetails | null>(null);
  const [alert, setAlert] = useState<{ label: string; severity: AlertColor } | null>(null);

  useEffect(() => {
    const fetchStatusDetails = async () => {
      if (!insight.userStatusChanges?.userRef?.id) {
        return;
      }

      const userDoc = await getCollection(UserModel).doc(insight.userStatusChanges.userRef.id).get();
      const user = userDoc.asModelData();

      if (user) {
        const details: StatusDetails = {
          date: insight.userStatusChanges.timestamp.toDate().toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }),
          userDisplayName: user.displayName,
        };

        setStatusDetails(details);
      }
    };

    fetchStatusDetails();
  }, [insight]);

  useEffect(() => {
    const newAlert = getAlert(insight, statusDetails);

    setAlert(newAlert);
  }, [insight, statusDetails]);

  if (!alert) {
    return null;
  }

  return (
    <Alert iconMapping={mapping} severity={alert.severity}>
      {alert.label}
    </Alert>
  );
};
