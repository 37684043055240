import { forwardRef } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Box, Button, type ButtonProps } from "@mui/material";

export const AppendButton = forwardRef<HTMLButtonElement, ButtonProps>(function AppendButton(props, ref) {
  const { children, ...otherProps } = props;
  return (
    <Button
      sx={{
        alignSelf: "flex-start",
        mt: "12px",
        mb: "1px",
        maxWidth: "100%",
      }}
      size="small"
      startIcon={<AddIcon />}
      {...otherProps}
      ref={ref}
    >
      <Box sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{children}</Box>
    </Button>
  );
});
