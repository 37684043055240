import { Avatar, Box, Stack, Typography } from "@mui/material";

import { type InsightPublishingUser } from "../types";

type AgentProfileCardProps = { agent: InsightPublishingUser };
export const AgentProfileCard = ({ agent }: AgentProfileCardProps) => (
  <Stack direction="row" spacing={2} sx={{ mb: 3, mt: 3 }}>
    <Avatar sx={{ height: 64, width: 64 }} src={agent.avatarUrl} />
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stack spacing={0} sx={{ mb: 1 }}>
        <Typography color="text.primary" variant="body2" sx={{ mb: 0, mt: 0 }} fontWeight="500">
          {agent.displayName}
        </Typography>
        <Typography color="text.secondary" variant="body2" sx={{ mb: 0, mt: 0 }}>
          {agent.title}
        </Typography>
      </Stack>
    </Box>
  </Stack>
);
