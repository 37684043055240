import { useCallback, useState } from "react";

import {
  type AmazonWebServicesAssetModel,
  type AmazonWebServicesStandaloneAssetModel,
  AssetTypeAwsStandalone,
} from "@doitintl/cmp-models";
import { type ModelData } from "@doitintl/models-firestore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useIsPLESCustomer } from "../../../../Support/Components/TicketSteps/hooks";
import { useGetLoginUrl } from "../../../../utils/useGetLoginUrl";
import { PLESAssetIndicator } from "../../assetUtils";
import { AwsDirectSupportFailureDialog } from "../../Dialogs/AwsDirectSupportFailureDialog";
import OnboardingReadOnlyAccessButton from "./OnboardingReadOnlyAccessButton";

type Props = {
  assetData?: ModelData<AmazonWebServicesAssetModel> | ModelData<AmazonWebServicesStandaloneAssetModel>;
  returnToAssets: () => void;
};

const AwsAssetHeader = ({ assetData, returnToAssets }: Props) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();
  const theme = useTheme();
  const isPLES = assetData?.properties?.support?.isPLESAsset || false;
  const [openAwsDirectSupportFailure, setOpenAwsDirectSupportFailure] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const isPLESCustomer = useIsPLESCustomer(customer.id);
  const getLoginUrl = useGetLoginUrl();

  const openAWSSupport = async () => {
    if (assetData?.properties.accountId) {
      try {
        const url = await getLoginUrl(assetData.properties.accountId, isDoitEmployee, customer.id);
        window.open(url, "_blank");
      } catch (error: any) {
        setErrorMessage(error.message);
        setOpenAwsDirectSupportFailure(true);
      }
    }
  };

  const awsSupportButton =
    assetData?.type === "amazon-web-services" && !isPLESCustomer ? (
      <Button
        variant="outlined"
        component="a"
        target="_blank"
        onClick={openAWSSupport}
        disabled={!assetData}
        data-cy="awsSupportButton"
      >
        AWS Support
        <OpenInNewIcon aria-hidden fontSize="small" sx={{ ml: 1 }} />
      </Button>
    ) : (
      <></>
    );

  const notStandalone = assetData?.type !== AssetTypeAwsStandalone;

  const handleCloseSauronFailure = useCallback(() => {
    setOpenAwsDirectSupportFailure(false);
    setErrorMessage("");
  }, []);

  const isDoitMpaEmail = (email?: string) =>
    !!email && (email.includes("@doit-intl.com") || email.includes("@doit.com"));

  return (
    <>
      <Stack direction="row" sx={{ my: 1 }}>
        <IconButton aria-label="Back to assets list" onClick={returnToAssets} data-cy="backToAssetsButton">
          <ArrowBackIcon sx={{ color: theme.palette.text.primary }} />
        </IconButton>
        <Typography variant="h3" data-cy="title" sx={{ pt: 1, fontWeight: "fontWeightMedium" }}>
          Amazon Web Services asset
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ pb: 4, alignItems: "center" }}>
        {notStandalone && isDoitEmployee && !isDoitMpaEmail(assetData?.properties.organization?.email) && (
          <OnboardingReadOnlyAccessButton awsAccountId={assetData?.properties.accountId} />
        )}
        <Button
          variant="outlined"
          component="a"
          href={assetData?.properties ? `https://${assetData.properties.accountId}.signin.aws.amazon.com/console` : "#"}
          target="_blank"
          disabled={!assetData}
          data-cy="awsConsoleButton"
        >
          AWS Console
          <OpenInNewIcon aria-hidden fontSize="small" sx={{ ml: 1 }} />
        </Button>
        {notStandalone && awsSupportButton}
        {isPLES && <Typography variant="body2">{PLESAssetIndicator}</Typography>}

        <AwsDirectSupportFailureDialog
          error={errorMessage}
          open={openAwsDirectSupportFailure}
          onClose={handleCloseSauronFailure}
        />
      </Stack>
    </>
  );
};

export default AwsAssetHeader;
