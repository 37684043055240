import { Box, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { InnerScreenHeader } from "../../Components/InnerScreenHeader";
import { useAWSAccountInfo } from "../hooks";
import { FeaturesSelection } from "./FeaturesSelection/FeaturesSelection";

type Props = {
  backPageCallback: () => void;
  accountId: string;
};

/**
 * Screen to handle update of the permissions for existing account
 */
export const UpdatePermissions = ({ backPageCallback, accountId }: Props) => {
  const { customer } = useCustomerContext();

  const [supportedFeaturesNames, roleName, isLoading] = useAWSAccountInfo(accountId);

  return (
    <>
      <Card>
        <InnerScreenHeader
          title="Add feature permissions"
          subtitle={customer.name}
          backPageCallback={backPageCallback}
        />
        <CardContent>
          <Grid container>
            <Grid
              size={{
                md: 3,
              }}
            />
            <Grid
              size={{
                xs: 12,
                md: 6,
              }}
            >
              <Box
                sx={{
                  pt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1">Select features you'd like to add to your account</Typography>
                </Box>

                {!isLoading && (
                  <FeaturesSelection
                    onUpdate={backPageCallback}
                    roleToUpdate={roleName}
                    accountSupportedFeatures={supportedFeaturesNames}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
