import { Alert } from "@mui/material";
import Link from "@mui/material/Link";

import { ActiveWidgetsListText } from "../../../assets/texts";

const BigQueryLensUnhealthyInfo = () => {
  const vpcLink = (
    <Link href="https://cloud.google.com/vpc-service-controls/docs/overview" target="_blank">
      {ActiveWidgetsListText.VPCServiceControls}
    </Link>
  );

  const helpCenterLink = (
    <Link href="https://help.doit.com/" target="_blank">
      {ActiveWidgetsListText.helpCenter}
    </Link>
  );

  return (
    <Alert severity="error">
      Your organization is using a {vpcLink} policy and prohibits access from DoiT’s network. Please refer to the{" "}
      {helpCenterLink} for more information.
    </Alert>
  );
};
export default BigQueryLensUnhealthyInfo;
