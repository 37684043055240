import { AvaContextProvider, TrpcContextProvider } from "@doitintl/ava-components";

import { AVADialog } from "../Ava/Messenger/AVADialog";
import { useFetcher } from "../Ava/Messenger/hooks";

type Props = {
  handleClose: (withoutConversation: boolean, mixpanelCallback?: (event: string) => void) => void;
  dashboardId?: string;
  existingConversationId?: string;
};

export const AVADialogWrapper = (props: Props) => {
  const fetcher = useFetcher();

  return (
    <AvaContextProvider fetcher={fetcher} dashboardId={props.dashboardId} conversationId={props.existingConversationId}>
      <TrpcContextProvider value={{ baseURL: window.location.origin }}>
        <AVADialog {...props} />
      </TrpcContextProvider>
    </AvaContextProvider>
  );
};
