import { useMemo } from "react";

import { Box, Container, Stack } from "@mui/material";

import { CircularProgressLoader } from "../../Components/Loader";
import { PremiumFeatureCard } from "../../Components/Trial/TrialPremiumFeatureCard";
import { useCustomerContext } from "../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { getPremiumFeatureCardText } from "../CloudAnalytics/utilities";
import DisableFlexsave from "./AWS/DisableFlexsave";
import EnableFlexsaveRDS from "./AWS/EnableFlexsaveRDS";
import EnableFlexsaveSageMaker from "./AWS/EnableFlexsaveSageMaker";
import {
  useCustomerSavingsPlans,
  useFlexsaveComputeData,
  useFlexsaveRDSData,
  useFlexsaveSageMakerData,
} from "./AWS/hooks";
import JourneyAWS from "./AWS/JourneyAWS";
import StateInfoHeader from "./AWS/StateInfoHeader";
import AWSFAQ from "./AWSOnboarding/FAQ";
import { useJourneyStep } from "./AWSOnboarding/hooks";
import { SavingsChart } from "./AWSOnboarding/utils";
import ComputeReport from "./Reports/ComputeReport";
import SageMakerReport from "./Reports/SageMakerReport";
import { CustomerSavingsPlansTable } from "./Resold/Overview/AWS/CustomerSavingsPlansTable/CustomerSavingsPlansTable";
import SavingsByService from "./SavingsByService";
import { shouldShowSelfEnableSageMakerButton } from "./utils";

export const FlexsaveAWS = () => {
  const { customerOrPresentationModeCustomer: customer, customer: originalCustomer } = useCustomerContext();
  const customerSavingsPlans = useCustomerSavingsPlans(customer);
  const { data: computeData, loading: computeLoading } = useFlexsaveComputeData();
  const { sageMakerData, loading: sageMakerLoading } = useFlexsaveSageMakerData();
  const { rdsData, loading: rdsLoading } = useFlexsaveRDSData();
  const journeyStep = useJourneyStep(computeData);
  const hasSavingsPlans = customerSavingsPlans.length > 0;
  const hasActiveResold = computeData?.hasActiveResold;

  const premiumFeatureCardText = useMemo(() => getPremiumFeatureCardText("flexSave"), []);

  if (computeLoading || sageMakerLoading || rdsLoading) {
    return <CircularProgressLoader />;
  }

  return (
    <>
      {isCustomerInPresentationMode(originalCustomer) && premiumFeatureCardText && (
        <PremiumFeatureCard title={premiumFeatureCardText.title} detail={premiumFeatureCardText.details} />
      )}
      <Stack sx={{ mt: -2, mb: 2, mr: -2, ml: -2 }}>
        <StateInfoHeader journeyStep={journeyStep} data={computeData} />

        {shouldShowSelfEnableSageMakerButton({ hasActiveResold, sageMakerData }) ? <EnableFlexsaveSageMaker /> : null}

        {rdsData?.canBeEnabledBasedOnRecommendations && !rdsData.timeEnabled ? <EnableFlexsaveRDS /> : null}

        <SavingsChart
          journeyStep={journeyStep}
          computeData={computeData}
          sageMakerData={sageMakerData}
          rdsData={rdsData}
        />

        {journeyStep !== "onboarded" && <JourneyAWS step={journeyStep} />}

        {["invoicing", "generatingSavings", "onboarded"].includes(journeyStep) && <SavingsByService />}

        {computeData?.enabled && hasActiveResold && <ComputeReport customerId={customer.id} />}

        <SageMakerReport customerId={customer.id} />

        <Container>
          {hasSavingsPlans && (
            <CustomerSavingsPlansTable customerName={originalCustomer.name} data={customerSavingsPlans} />
          )}
        </Container>

        <AWSFAQ step={journeyStep} />

        <Box
          sx={{
            mb: 6,
            mt: 4,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <DisableFlexsave />
        </Box>
      </Stack>
    </>
  );
};
