import { type ReactNode, useCallback, useMemo, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { type AssetModel, type CreditType, type CustomerModel, EntityModel, ProductEnum } from "@doitintl/cmp-models";
import { getBatch, getCollection, type ModelReference, type WithFirebaseModel } from "@doitintl/models-firestore";
import { type Reference } from "@doitintl/models-types";
import AddIcon from "@mui/icons-material/AddBoxRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import { CardContent } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { green, purple, red } from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import capitalize from "lodash/capitalize";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import trim from "lodash/trim";
import { DateTime } from "luxon";
import Papa from "papaparse";

import { FilterContextProvider } from "../../Components/FilterTable/Context";
import { TableFilterBar } from "../../Components/FilterTable/Filterbar/TableFilterBar";
import Hide from "../../Components/HideChildren/Hide";
import { CircularProgressLoader } from "../../Components/Loader";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../Context/AuthContext";
import { type CreditDataType, useCreditsContext } from "../../Context/CreditsContext";
import { useUserContext } from "../../Context/UserContext";
import { assetTypeName, formatCurrency, sanitizeDate } from "../../utils/common";
import { serverTimestamp } from "../../utils/firebase";
import CreditFormDialog from "./CreditFormDialog";

const options = [
  {
    label: "Google Cloud",
    value: ProductEnum.GoogleCloud,
  },
  {
    label: "Amazon Web Services",
    value: ProductEnum.AmazonWebServices,
  },
] as const;

const statusColumn = {
  path: "data._status",
  label: "Status",
  toOption: (value: any) => ({ value, label: capitalize(value) }),
} as const;

const getFilterColumns = (withCustomer: boolean) => {
  const baseColumns = [
    {
      path: "data.name",
      label: "Name",
      comparators: ["==", "!=", "contains"],
    },
    statusColumn,
    {
      path: "data.type",
      label: "Type",
      toOption: (value: any) => ({ value, label: assetTypeName(value) }),
    },
    {
      path: "data.amount",
      label: "Amount",
      type: "Number",
      comparators: ["<", "<=", ">", ">=", "==", "!="],
      placeholder: "number",
      transform: (value: any) => parseFloat(value.replace(/,/g, "")),
      validate: (value: any) => !isNaN(parseFloat(value.replace(/,/g, ""))),
    },
    {
      path: "data._remaining",
      label: "Remaining",
      type: "Number",
      comparators: ["<", "<=", ">", ">=", "==", "!="],
      placeholder: "number",
      transform: (value: any) => parseFloat(value.replace(/,/g, "")),
      validate: (value: any) => !isNaN(parseFloat(value.replace(/,/g, ""))),
    },
    {
      label: "Start Date",
      path: "data.startDate",
      type: "DateTime",
      comparators: ["<", "<=", ">", ">=", "==", "!="],
      placeholder: "YYYY-MM-DD",
      transform: (value: any) => sanitizeDate(DateTime.fromFormat(value, "yyyy-LL-dd", { zone: "utc" })),
      validate: (value: any) => DateTime.fromFormat(value, "yyyy-LL-dd")?.isValid,
      toOption: (value: any) => {
        const strValue = value.toFormat("yyyy-LL-dd");
        return {
          value: strValue,
          label: strValue,
        };
      },
    },
    {
      label: "End Date",
      path: "data.endDate",
      type: "DateTime",
      comparators: ["<", "<=", ">", ">=", "==", "!="],
      placeholder: "YYYY-MM-DD",
      transform: (value: any) => sanitizeDate(DateTime.fromFormat(value, "yyyy-LL-dd", { zone: "utc" })),
      validate: (value: any) => DateTime.fromFormat(value, "yyyy-LL-dd")?.isValid,
      toOption: (value: any) => {
        const strValue = value.toFormat("yyyy-LL-dd");
        return {
          value: strValue,
          label: strValue,
        };
      },
    },
  ] as const;

  if (withCustomer) {
    return [
      {
        path: "data._entity",
        label: "Billing profile",
      },
      ...baseColumns,
    ] as const;
  } else {
    return [
      {
        path: "data.metadata.customer.primaryDomain",
        label: "Customer",
      },
      ...baseColumns,
    ] as const;
  }
};

const getColumns = (withCustomer: boolean) => {
  const baseColumns = [
    {
      id: "data.name",
      align: "left",
      label: "Name",
      tooltip: "Credit name",
      hidden: { xsDown: true },
      padding: "none",
    },
    {
      id: "data.type",
      align: "left",
      label: "Type",
      tooltip: "Type",
      padding: "none",
      hidden: { xsDown: true },
    },
    {
      id: "data.assets",
      align: "left",
      label: "Assets",
      tooltip: "Assets",
      padding: "none",
      hidden: { xsDown: true },
    },
    {
      id: "data._status",
      align: "left",
      label: "Status",
      tooltip: "Credit status",
      padding: "none",
      hidden: {},
    },
    {
      id: "data.startDate",
      align: "left",
      label: "From",
      tooltip: "Credit start date",
      padding: "none",
      hidden: { xsDown: true },
    },
    {
      id: "data.endDate",
      align: "left",
      label: "To",
      tooltip: "Credit end date",
      padding: "none",
      hidden: { xsDown: true },
    },
    {
      id: "data.depletionDate",
      align: "left",
      label: "Depleted On",
      tooltip: "Credit funds depletion date",
      padding: "none",
      hidden: { xsDown: true },
    },
    {
      id: "data.amount",
      align: "right",
      label: "Total",
      tooltip: "Credit total amount",
      padding: "none",
      hidden: { xsDown: true },
    },
    {
      id: "data._remaining",
      align: "right",
      label: "Remaining",
      tooltip: "Credit funds remaining",
      padding: "normal",
      hidden: {},
    },
  ] as const;

  if (withCustomer) {
    return [
      {
        id: "data._entity",
        align: "left",
        label: "Billing profile",
        tooltip: "Billing profile",
        hidden: {},
        padding: "none",
      },
      ...baseColumns,
    ] as const;
  } else {
    return [
      {
        id: "data.metadata.customer.primaryDomain",
        align: "left",
        label: "Customer",
        tooltip: "Customer primary domain",
        padding: "none",
        hidden: {},
      },
      ...baseColumns,
    ] as const;
  }
};

const useColoredTableCellStyles = makeStyles((theme) => ({
  typography: {
    color: (props: { status: string | undefined }) => {
      switch (props.status) {
        case "expired":
          return red[500];
        case "depleted":
          return purple[500];
        case "active":
          return green[500];
        default:
          return theme.palette.text.primary;
      }
    },
  },
}));

const ColoredTableCell = (props: {
  status: string | undefined;
  padding?: "normal" | "checkbox" | "none";
  children: ReactNode;
}) => {
  const { children, status, ...other } = props;
  const classes = useColoredTableCellStyles({ status });
  return (
    <TableCell {...other}>
      <Typography variant="body2" className={classes.typography}>
        {children}
      </Typography>
    </TableCell>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(0.5),
  },
  link: {
    textDecoration: "underline",
  },
  fileInput: {
    display: "none",
  },
}));

type Row = {
  type: CreditType;
  name: string;
  amount: number;
  start_date: string;
  end_date: string;
  billing_profile: string;
};

const CreditsTab = ({ withCustomer }: { withCustomer: boolean }) => {
  const { credits, loading } = useCreditsContext();
  const { isDoitDeveloper } = useAuthContext();
  const { userModel } = useUserContext({ allowNull: false });
  const classes = useStyles();
  const sharedSnackbar = useSnackbar();
  const [selected, setSelected] = useState<CreditDataType | null>();
  const [creditFormOpen, setCreditFormOpen] = useState(false);
  const [creditForm, setCreditForm] = useState<CreditType | null>();
  const [anchorEl, setAnchorEl] = useState<any>();
  const [tableState, setTableState] = useState<{
    sort: string;
    direction: "asc" | "desc";
    page: number;
    rowsPerPage: number;
    filter?: (credits: CreditDataType[]) => CreditDataType[];
  }>({
    sort: "data.endDate",
    direction: "desc",
    page: 0,
    rowsPerPage: 10,
  });

  const handleRequestFilter = useCallback((filter) => {
    setTableState((tableState) => ({ ...tableState, filter, page: 0 }));
  }, []);

  const filteredCredits = useMemo(() => tableState.filter?.(credits) ?? credits, [credits, tableState]);

  function handleCloseMenu() {}

  const handleRequestSort = (sort: string) => () => {
    let direction: "asc" | "desc" = "desc";
    if (tableState.sort === sort && tableState.direction === "desc") {
      direction = "asc";
    }
    setTableState((tableState) => ({ ...tableState, sort, direction }));
  };

  const isSelected = (creditId: string) => Boolean(selected && selected.ref.id === creditId);

  const handleBatchUpload = (filesList: FileList) => {
    const files = Array.from(filesList);
    if (files.length !== 1) {
      return;
    }
    const file = files[0];
    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      return;
    }
    Papa.parse<Row>(file, {
      header: true,
      dynamicTyping: (field) => field === "amount",
      complete: async (results) => {
        try {
          const header = ["type", "billing_profile", "name", "amount", "start_date", "end_date"];
          if (!isEqual(results.meta.fields, header)) {
            throw Error("Invalid csv file schema");
          }
          if (results.data.length === 0) {
            throw Error("No credits to create");
          }
          if (results.data.length > 100) {
            throw Error("Cannot create more than 100 credits");
          }
          const batch = getBatch();
          const rows = await Promise.all(
            results.data.map(async (row) => {
              if (!["google-cloud", "amazon-web-services"].includes(row.type)) {
                return Promise.reject(Error(`Invalid credit type ${row.type}`));
              }
              if (!row.name) {
                return Promise.reject(Error("Missing credit name"));
              }
              if (!row.amount || isNaN(row.amount) || row.amount <= 0) {
                return Promise.reject(Error("Invalid credit amount"));
              }
              const startDate = DateTime.fromFormat(row.start_date, "yyyy-LL-dd", { zone: "utc" });
              const endDate = DateTime.fromFormat(row.end_date, "yyyy-LL-dd", { zone: "utc" });
              if (!startDate.isValid) {
                return Promise.reject(Error(`Invalid start date ${row.start_date}: must match pattern YYYY-MM-DD.`));
              }
              if (!endDate.isValid) {
                return Promise.reject(Error(`Invalid end date ${row.end_date}: must match pattern YYYY-MM-DD.`));
              }
              if (startDate >= endDate) {
                return Promise.reject(Error("Start date must be before end date"));
              }

              const querySnap = await getCollection(EntityModel)
                .where("priorityId", "==", row.billing_profile)
                .limit(1)
                .get();
              if (querySnap.empty) {
                return Promise.reject(Error(`Billing profile ${row.billing_profile} not found`));
              }

              const entityDocSnap = querySnap.docs[0];
              const entity = {
                data: entityDocSnap.asModelData(),
                ref: entityDocSnap.modelRef,
              };

              let customer: { data: WithFirebaseModel<CustomerModel>; ref: ModelReference<CustomerModel> } | undefined =
                undefined;
              if (entity.data.customer) {
                const customerDocSnap = await entity.data.customer.get();
                const data = customerDocSnap.asModelData();
                if (data) {
                  customer = {
                    data,
                    ref: customerDocSnap.modelRef,
                  };
                }
              }

              if (customer) {
                const docRef = customer.ref.collection("customerCredits").newDoc();

                batch.set(docRef, {
                  name: trim(row.name),
                  amount: row.amount,
                  currency: "USD",
                  startDate: startDate.toJSDate(),
                  endDate: endDate.toJSDate(),
                  depletionDate: null,
                  utilization: {},
                  type: row.type,
                  customer: customer?.ref,
                  entity: entity.ref,
                  assets: null,
                  timestamp: serverTimestamp(),
                  updatedBy: { name: userModel.displayName, email: userModel.email },
                  metadata: {
                    customer: customer
                      ? {
                          primaryDomain: customer.data.primaryDomain,
                          name: customer.data.name,
                          priorityId: entity.data.priorityId,
                        }
                      : undefined,
                  },
                  scope: row.type === "google-cloud" ? [] : undefined,
                });
              }

              return row;
            })
          );

          await batch.commit();

          sharedSnackbar.onOpen({
            message: `${rows.length} credits create successfully`,
            variant: "success",
            autoHideDuration: 5000,
            action: [
              <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
                <CloseIcon />
              </IconButton>,
            ],
          });
        } catch (error: any) {
          sharedSnackbar.onOpen({
            message: error.message ?? "Failed to create credits",
            variant: "error",
            autoHideDuration: 10000,
            action: [
              <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
                <CloseIcon />
              </IconButton>,
            ],
          });
        }
      },
    });
  };

  const defaultFilters = useMemo(
    () =>
      [
        {
          column: statusColumn,
          ...statusColumn.toOption("active"),
        },
      ] as const,
    []
  );

  const filterColumns = useMemo(() => getFilterColumns(withCustomer), [withCustomer]);
  const columns = useMemo(() => getColumns(withCustomer), [withCustomer]);
  const getCreditAssetsLabel = (assets: Reference<AssetModel>[] | null) => {
    if (!!assets && assets.length > 0) {
      return `${assets.length} asset${assets.length === 1 ? "" : "s"}`;
    }

    return "All assets";
  };

  return (
    <FilterContextProvider columns={filterColumns} defaultValue={defaultFilters}>
      {isDoitDeveloper && creditFormOpen && !!creditForm && (
        <CreditFormDialog
          type={creditForm}
          credit={selected}
          onClose={() => {
            setCreditFormOpen(false);
          }}
        />
      )}
      <CardContent>
        <Grid
          container
          spacing={1}
          sx={{
            alignItems: "flex-start",
          }}
        >
          <Grid size="grow">
            <TableFilterBar
              items={credits}
              onFilter={handleRequestFilter}
              filterColumn={filterColumns}
              defaultFilters={defaultFilters}
            />
          </Grid>

          {isDoitDeveloper && (
            <Grid className={classes.actions}>
              <IconButton
                onClick={() => {
                  setCreditForm(selected?.data.type);
                  setCreditFormOpen(true);
                }}
                disabled={!selected}
                size="large"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-controls="credit-menu"
                aria-haspopup="true"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
                size="large"
              >
                <AddIcon />
              </IconButton>
              <Menu
                id="credit-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => {
                      setCreditForm(option.value);
                      setSelected(null);
                      setCreditFormOpen(true);
                      handleCloseMenu();
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}

                <input
                  id="file-select-button"
                  type="file"
                  className={classes.fileInput}
                  onChange={(event) => {
                    if (event.target.files) {
                      handleBatchUpload(event.target.files);
                    }
                  }}
                />
                <label htmlFor="file-select-button">
                  <MenuItem>Batch Upload (.CSV)</MenuItem>
                </label>
              </Menu>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding="checkbox" />
            {columns.map((column) => (
              <Hide {...column.hidden} key={column.id}>
                <TableCell
                  padding={column.padding}
                  align={column.align}
                  sortDirection={tableState.sort === column.id ? tableState.direction : false}
                >
                  <Tooltip title={column.tooltip}>
                    <TableSortLabel
                      active={tableState.sort === column.id}
                      direction={tableState.direction}
                      onClick={handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              </Hide>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <CircularProgressLoader />}
          {!loading &&
            orderBy(filteredCredits, [tableState.sort], [tableState.direction])
              .slice(
                tableState.page * tableState.rowsPerPage,
                tableState.page * tableState.rowsPerPage + tableState.rowsPerPage
              )
              .map((credit, i) => {
                const isItemSelected = isSelected(credit.ref.id);
                const labelId = `table-checkbox-${i}`;

                return (
                  <TableRow key={credit.ref.id} hover>
                    <TableCell align="center" padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={() => {
                          setSelected(isSelected(credit.ref.id) ? null : credit);
                        }}
                      />
                    </TableCell>
                    {withCustomer ? null : (
                      <TableCell component="th" scope="row" padding="none">
                        <Link
                          component={RouterLink}
                          to={`/customers/${credit.data.customer.id}`}
                          variant="body2"
                          color="inherit"
                          className={classes.link}
                        >
                          {credit.data.metadata.customer.primaryDomain}
                        </Link>
                      </TableCell>
                    )}
                    {withCustomer && <TableCell padding="none">{credit.data._entity}</TableCell>}
                    <Hide smDown>
                      <TableCell padding="none">{credit.data.name}</TableCell>
                      <TableCell padding="none">{assetTypeName(credit.data.type)}</TableCell>
                      <TableCell padding="none">{getCreditAssetsLabel(credit.data.assets)}</TableCell>
                    </Hide>
                    <ColoredTableCell status={credit.data._status} padding="none">
                      {capitalize(credit.data._status)}
                    </ColoredTableCell>
                    <Hide smDown>
                      <TableCell padding="none">{credit.data.startDate.toLocaleString(DateTime.DATE_MED)}</TableCell>
                      <TableCell padding="none">{credit.data.endDate.toLocaleString(DateTime.DATE_MED)}</TableCell>
                      <TableCell padding="none">
                        {credit.data.depletionDate
                          ? sanitizeDate(DateTime.fromJSDate(credit.data.depletionDate.toDate())).toLocaleString(
                              DateTime.DATE_MED
                            )
                          : "N/A"}
                      </TableCell>
                      <TableCell align="right" padding="none">
                        {formatCurrency(credit.data.amount, credit.data.currency, 2)}
                      </TableCell>
                    </Hide>
                    <TableCell align="right">
                      {formatCurrency(credit.data._remaining, credit.data.currency, 2)}
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
        <TableFooter>
          {filteredCredits.length > 0 && (
            <TableRow>
              <TablePagination
                count={filteredCredits.length}
                rowsPerPage={tableState.rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
                page={tableState.page}
                slotProps={{
                  actions: {
                    previousButton: {
                      "aria-label": "Previous Page",
                    },
                    nextButton: {
                      "aria-label": "Next Page",
                    },
                  },
                }}
                onPageChange={(_event, page) => {
                  setTableState((tableState) => ({
                    ...tableState,
                    page,
                  }));
                }}
                onRowsPerPageChange={(event) => {
                  setTableState((tableState) => ({
                    ...tableState,
                    page: 0,
                    rowsPerPage: parseInt(event.target.value),
                  }));
                }}
                labelRowsPerPage="per page"
              />
            </TableRow>
          )}
        </TableFooter>
      </Table>
      {filteredCredits.length === 0 && !loading && (
        <Toolbar>
          <Typography variant="body2">No credits were found.</Typography>
        </Toolbar>
      )}
    </FilterContextProvider>
  );
};

export default CreditsTab;
