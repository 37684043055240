import { CategoryStatus } from "@doitintl/cmp-models";
import {
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { SafeHtml } from "../../../../Components/SafeHtml";
import { ServiceAccountStatusChip } from "../ServiceAccountStatusChip";
import { CollapsableTableRow } from "./CollapsableTableRow";
import { usePermissionCategoriesByType } from "./hooks";

export type FeaturesTableProps = {
  type: "organization" | "project";
  selectedFeatures: string[];
  onFeatureSelect: (feature: string, selected: boolean) => void;
  forceIncludeCategoryIds?: string[];
  categoryStatuses?: Record<string, CategoryStatus>;
};

export const FeaturesTable = ({
  type,
  selectedFeatures,
  onFeatureSelect,
  forceIncludeCategoryIds = [],
  categoryStatuses = {},
}: FeaturesTableProps) => {
  const availableCategories = usePermissionCategoriesByType(type);

  if (!availableCategories.length) {
    return <CircularProgress />;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={15} />
          <TableCell>Available features</TableCell>
          <TableCell align="right" padding="checkbox">
            Include
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {availableCategories.map((category) => (
          <CollapsableTableRow
            key={category.id}
            hiddenContent={
              <Box m={1}>
                <Typography variant="subtitle1">
                  <SafeHtml html={category.description} />
                </Typography>

                <ul>
                  {category.permissions.map((permission) => (
                    <li key={permission}>{permission}</li>
                  ))}
                </ul>
              </Box>
            }
          >
            <TableCell component="th" scope="row">
              <Stack direction="row" justifyContent="space-between">
                {category.name}

                <ServiceAccountStatusChip status={categoryStatuses[category.id] || CategoryStatus.NotConfigured} />
              </Stack>
            </TableCell>

            <TableCell padding="none">
              {/* Don't allow unselecting force included categories (defaults / previously selected), but in case they are not selected for some reason, allow selecting them */}
              <Checkbox
                color="primary"
                disabled={forceIncludeCategoryIds.includes(category.id) && selectedFeatures.includes(category.id)}
                checked={selectedFeatures.includes(category.id)}
                onChange={(event) => {
                  onFeatureSelect(category.id, event.target.checked);
                }}
              />
            </TableCell>
          </CollapsableTableRow>
        ))}
      </TableBody>
    </Table>
  );
};
