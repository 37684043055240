import { type Dispatch, useCallback } from "react";

import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Checkbox, Divider, FormControlLabel, Link, Stack, TextField, Typography } from "@mui/material";
import { type InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { Form, Formik } from "formik";
import { bool as YupBool, object as YupObject } from "yup";

import { loginTexts } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import { useEmailSchema } from "../helpers";
import { useHandleSignUp, useSignUpForm } from "../hooks";
import { type LoginHookAction, LoginHookActionType, type LoginState } from "../types";
import AuthProviders from "./AuthProviders";
import { AuthProvidersPolicyDialog } from "./AuthProvidersPolicyDialog";
import Disclosure from "./Disclosure";

type Values = {
  username: string;
};

type Helpers = {
  setSubmitting: (isSubmitting: boolean) => void;
};

const SignUpForm = ({ state, dispatch }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const {
    policyAgreementActionRef,
    policyAgreementRef,
    policyAgreementCallback,
    isAuthProvidersPolicyDialogOpen,
    setIsAuthProvidersPolicyDialogOpen,
    clickedAuthProvider,
    setClickedAuthProvider,
  } = useSignUpForm();
  const handleSignUp = useHandleSignUp({ dispatch });

  const emailInputHandler: StandardInputProps["onChange"] = useCallback(
    (e) => {
      dispatch({ type: LoginHookActionType.setEmail, email: e.currentTarget.value });
    },
    [dispatch]
  );

  const { t } = useTranslation("login");
  const EmailSchema = useEmailSchema();

  return (
    <>
      <Formik
        validateOnMount
        initialValues={{ username: state.email || "", policyAgreement: false }}
        validationSchema={() =>
          YupObject().shape({
            username: EmailSchema.fields.email,
            policyAgreement: YupBool().oneOf([true], "You must accept policy agreement"),
          })
        }
        onSubmit={(values: Values, helpers: Helpers) => {
          if (!values.username) {
            return;
          }
          return handleSignUp({ email: values.username }, helpers);
        }}
      >
        {({ isValid, errors, isSubmitting, values, handleChange, setFieldTouched, touched }) => (
          <Form>
            <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
              <AuthProviders
                loading={state.isLoading}
                customHandler={policyAgreementCallback(!values.policyAgreement)}
                type="signup"
                setClickedAuthProvider={setClickedAuthProvider}
              />
              <Divider>
                <Typography variant="caption" align="center">
                  {t("caption.signup")}
                </Typography>
              </Divider>
            </Stack>
            <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
              <TextField
                data-cy="email-input"
                autoFocus
                value={values.username || ""}
                onChange={(e) => {
                  handleChange(e);
                  emailInputHandler(e);
                }}
                onBlur={() => {
                  if (!touched.username && values.username) {
                    setFieldTouched("username", true);
                  }
                }}
                error={touched.username && Boolean(errors.username)}
                helperText={touched.username && errors.username ? errors.username : " "}
                label={t("login.email.label")}
                type="email"
                name="username"
                autoComplete="email"
                margin="none"
                variant="outlined"
                fullWidth
                disabled={state.isLoading}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!values.policyAgreement}
                    value={values.policyAgreement}
                    onChange={(e, checked) => {
                      setIsAuthProvidersPolicyDialogOpen(!checked);
                      handleChange(e);
                    }}
                    name="policyAgreement"
                    size="small"
                    sx={{ ml: -1.5 }}
                    action={policyAgreementActionRef}
                    inputRef={policyAgreementRef}
                  />
                }
                label={
                  <Typography>
                    {`${t("signup.agree")} `}
                    <Link
                      type="button"
                      underline="none"
                      href={loginTexts.NAVIGATOR_AGREEMENT_POLICY_URL}
                      target="_blank"
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      {t("signup.terms")}
                      <OpenInNewIcon sx={{ fontSize: 16, marginLeft: 1 }} />
                    </Link>
                  </Typography>
                }
              />
              <LoadingButton
                data-cy="login-cta"
                variant="contained"
                fullWidth
                type="submit"
                loading={state.isLoading}
                disabled={isSubmitting || state.isLoading || !values.username || !isValid}
                mixpanelEventId="login.email-form-template.continue"
              >
                {t("signup.label")}
              </LoadingButton>
            </Stack>
            {isAuthProvidersPolicyDialogOpen && clickedAuthProvider && (
              <AuthProvidersPolicyDialog
                clickedAuthProvider={clickedAuthProvider}
                isAuthProvidersPolicyDialogOpen={isAuthProvidersPolicyDialogOpen}
                setIsAuthProvidersPolicyDialogOpen={setIsAuthProvidersPolicyDialogOpen}
                onAuthProvidersPolicyCheckboxChange={handleChange}
                policyAgreementActionRef={policyAgreementActionRef}
                policyAgreementRef={policyAgreementRef}
                isPolicyAgreed={!!values.policyAgreement}
              />
            )}
          </Form>
        )}
      </Formik>
      <Stack spacing={2}>
        <Disclosure />
      </Stack>
    </>
  );
};

export default SignUpForm;
