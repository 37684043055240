import IconButton, { type IconButtonProps } from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export const ExpandMore = ({ expand, ...other }: ExpandMoreProps) => {
  const theme = useTheme();

  const styles = {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    transform: expand ? "rotate(180deg)" : "rotate(0deg)",
  };

  return <IconButton style={styles} {...other} />;
};
