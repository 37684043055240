import { useEffect } from "react";

import { type HookFunction, useDynamicStore } from "./dynamicStore";

type Props = {
  name: string;
  useHook: HookFunction;
  args: unknown[];
};

export function DynamicHookLoaderWrapper({ name, useHook, args }: Props) {
  const value = useHook(...args);

  const setState = useDynamicStore((state) => state.setState);

  useEffect(() => {
    setState(name, value);
  }, [name, setState, value]);

  return null;
}
