import { createContext, type ReactNode, useCallback, useContext, useMemo, useState } from "react";

import { type FirestoreTimestamp } from "../utils/firebase";
import { useCurrentDashboardContext } from "./useCurrentDashboardContext";

export type WidgetStateRecord = Record<string, { hasData: boolean; timeRefreshed?: FirestoreTimestamp }>;

export type DashboardWidgetState = {
  [dashboardName: string]: WidgetStateRecord;
};

export type DashboardProcessingData = {
  refreshTime?: number;
  dashboardWidgetStates: DashboardWidgetState;
  setDashboardWidgetStates: (widgetId: string, hasData: boolean, timeRefreshed?: FirestoreTimestamp) => void;
  setRefreshTriggerTime: () => void;
};

const DashboardProcessingContext = createContext<DashboardProcessingData>({
  dashboardWidgetStates: {},
  setDashboardWidgetStates: () => {},
  setRefreshTriggerTime: () => {},
});

export const DashboardProcessingContextProvider = ({ children }: { children?: ReactNode }) => {
  const { currentDashboard } = useCurrentDashboardContext();
  const [dashboardWidgetStates, setDashboardWidgetStates] = useState<DashboardWidgetState>({});
  const [refreshTime, setRefreshTime] = useState<number | undefined>();

  const updateWidgetLoadingStates = useCallback(
    (widgetId: string, hasData: boolean, timeRefreshed?: FirestoreTimestamp) => {
      if (currentDashboard?.name) {
        setDashboardWidgetStates((prvState = {}) => {
          const currentState = prvState[currentDashboard.name] ?? {};
          return { [currentDashboard.name]: { ...currentState, [widgetId]: { hasData, timeRefreshed } } };
        });
      }
    },
    [currentDashboard?.name]
  );

  const setRefreshTriggerTime = useCallback(() => {
    setRefreshTime(Date.now());
  }, []);

  const contextValue: DashboardProcessingData = useMemo(
    () => ({
      refreshTime,
      dashboardWidgetStates,
      setDashboardWidgetStates: updateWidgetLoadingStates,
      setRefreshTriggerTime,
    }),
    [dashboardWidgetStates, refreshTime, setRefreshTriggerTime, updateWidgetLoadingStates]
  );

  return <DashboardProcessingContext.Provider value={contextValue}>{children}</DashboardProcessingContext.Provider>;
};

export const useDashboardProcessingContext = (): DashboardProcessingData => useContext(DashboardProcessingContext);
