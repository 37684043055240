import { useEffect, useMemo } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export type BigQueryDatasetLocationSelectProps = {
  location: string | null;
  setLocation: (location: string | null) => void;
};

export const BigQueryDatasetLocationSelect = ({ location, setLocation }: BigQueryDatasetLocationSelectProps) => {
  const [data] = useDocumentDataOnce(getCollection(AppModel).doc("cloud-connect"));

  const datasetLocations = useMemo(() => data?.datasetLocations.gcp || [], [data]);

  // Set first option (US) as default once locations are loaded
  useEffect(() => {
    if (location || !datasetLocations.length) {
      return;
    }

    setLocation(datasetLocations[0].value);
  }, [datasetLocations, location, setLocation]);

  // The `Autocomplete` component behaves a bit strangely when not clearable (changing from uncontrolled to controlled component)
  // To avoid React freaking out, show it only once we have a value (set above in `useEffect` once locations are loaded)
  const showSelect = !!location;

  return (
    <Box mb={3}>
      <Typography variant="body1" mb={2}>
        Please specify a location for storing your BigQuery Lens dataset. After you create the dataset, the location
        cannot be changed.{" "}
        <Button
          variant="text"
          color="primary"
          href="https://cloud.google.com/bigquery/docs/locations#supported_regions"
          target="_blank"
          endIcon={<OpenInNewIcon />}
        >
          Read more
        </Button>
      </Typography>

      {showSelect ? (
        <Autocomplete
          size="small"
          options={datasetLocations}
          getOptionLabel={(option) => `${option.title} (${option.value})`}
          renderInput={(params) => <TextField {...params} label="Select Location" variant="outlined" />}
          disableClearable={true}
          value={datasetLocations.find((loc) => loc.value === location)}
          onChange={(_event, selectedItem) => {
            setLocation(selectedItem?.value || null);
          }}
        />
      ) : (
        <CircularProgress size={33} />
      )}
    </Box>
  );
};
