import { doitConnectSA, roleID, serviceAccountName } from "./constants";

export const getOrgLevelCommands = (permissionList: string[]) => [
  {
    header: "Create service account",
    body: `gcloud iam service-accounts create ${serviceAccountName} --display-name "DoiT Console" --description "DoiT International Console"`,
  },
  {
    header: "Enable service usage API",
    body: "gcloud services enable serviceusage.googleapis.com",
  },
  {
    header: "Discover your Google IAM organization ID",
    body: "ORG=$(gcloud organizations list --format 'value(ID)')",
  },
  {
    header: "Create custom role",
    body: `gcloud iam roles create ${roleID} --organization $ORG --title "DoiT Console Service Account" --stage GA --permissions ${permissionList.join(
      ","
    )}`,
  },
  {
    header: "Bind the role to the service account under your organization",
    body: `gcloud organizations add-iam-policy-binding $ORG --member=serviceAccount:${serviceAccountName}@$(gcloud config get-value project).iam.gserviceaccount.com --role=organizations/$ORG/roles/${roleID}`,
  },
  {
    header: "Bind ServiceAccountTokenCreator role to the service account under DoiT organization",
    body: `gcloud iam service-accounts add-iam-policy-binding ${serviceAccountName}@$(gcloud config get-value project).iam.gserviceaccount.com  --member=serviceAccount:${doitConnectSA} --role=roles/iam.serviceAccountTokenCreator`,
  },
  {
    header: "Download the service account details",
    body: `gcloud iam service-accounts describe ${serviceAccountName}@$(gcloud config get-value project).iam.gserviceaccount.com --format="json" > doit_cmp_sa_details.json`,
  },
  {
    header: "Upload the 'doit_cmp_sa_details.json' file created in the previous step",
  },
];

export const getProjectLevelCommands = (permissionList: string[]) => [
  {
    header: "Discover your Google IAM Project ID",
    body: "PROJ=<YOUR_PROJECT_ID>",
  },
  {
    header: "Create service account",
    body: `gcloud iam service-accounts create ${serviceAccountName} --project=$PROJ --display-name "DoiT Console" --description "DoiT International Console"`,
  },
  {
    header: "Enable service usage and resource manager APIs",
    body: "gcloud services enable serviceusage.googleapis.com cloudresourcemanager.googleapis.com --project=$PROJ",
  },
  {
    header: "Create custom role",
    body: `gcloud iam roles create ${roleID} --project=$PROJ --title "DoiT Console Service Account" --stage GA --permissions ${permissionList.join(
      ","
    )}`,
  },
  {
    header: "Bind the role to the service account under your project",
    body: `gcloud projects add-iam-policy-binding $PROJ --member=serviceAccount:${serviceAccountName}@$PROJ.iam.gserviceaccount.com --role=projects/$PROJ/roles/${roleID}`,
  },
  {
    header: "Bind the ServiceAccountTokenCreator role to the service account",
    body: `gcloud iam service-accounts add-iam-policy-binding ${serviceAccountName}@$PROJ.iam.gserviceaccount.com --project=$PROJ --member=serviceAccount:${doitConnectSA} --role=roles/iam.serviceAccountTokenCreator`,
  },
  {
    header: "Download the service account details",
    body: `gcloud iam service-accounts describe ${serviceAccountName}@$PROJ.iam.gserviceaccount.com --project=$PROJ --format="json" > doit_cmp_sa_details.json`,
  },
  {
    header: "Upload the 'doit_cmp_sa_details.json' file created in the previous step",
  },
];
