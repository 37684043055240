import React from "react";

import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import { Chip, Tooltip } from "@mui/material";
import { type TooltipProps } from "@mui/material/Tooltip";

interface EasyWinChipProps {
  tooltipText: string;
  tooltipPlacement?: TooltipProps["placement"];
}

export const EasyWinChip: React.FC<EasyWinChipProps> = ({ tooltipText, tooltipPlacement }) => (
  <Tooltip title={tooltipText} placement={tooltipPlacement} arrow>
    <Chip
      label="Easy win"
      variant="filled"
      size="small"
      color="primary"
      icon={
        <OfflineBoltIcon
          sx={{
            color: "white !important",
            padding: "1px",
          }}
        />
      }
    />
  </Tooltip>
);
