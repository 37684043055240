import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Link, Stack, Typography } from "@mui/material";

import { PresetPrompts } from "./PresetPrompts";

type Props = {
  onAskQuestion: (question: string) => void;
};

export const PresetFooter = ({ onAskQuestion }: Props) => (
  <Stack
    sx={{
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden auto",
      backgroundColor: "general.backgroundDark",
    }}
  >
    <Stack alignItems="center">
      <PresetPrompts onAskQuestion={onAskQuestion} />
      <Typography mt={2}>
        <Link href="https://help.doit.com/docs/general/ava" target="_blank" rel="noopener noreferrer" underline="hover">
          Learn more about how to interact with Ava
          <OpenNewIcon color="primary" sx={{ fontSize: 16, verticalAlign: "middle" }} />
        </Link>
      </Typography>
    </Stack>
  </Stack>
);
