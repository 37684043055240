import { type Dispatch, type SetStateAction } from "react";

import { type ContractStateType, type VendorContract } from "../../types";

export const validateDiscountStep =
  (isVendorContract: boolean) =>
  (baseContract: ContractStateType, setState: Dispatch<SetStateAction<ContractStateType>>) => {
    let isValid = true;
    const errors: Record<string, boolean>[] = [];
    const contract = isVendorContract ? (baseContract.vendorContract as VendorContract) : baseContract;
    const discountPeriods = isVendorContract
      ? baseContract.vendorContract?.discountPeriods
      : baseContract.discountPeriods;

    for (const [i, discount] of (discountPeriods ?? []).entries()) {
      errors.push({});
      if (discount.startDate < contract.startDate) {
        isValid = false;
        errors[i].startDate = true;
      }

      if (contract.endDate && discount.startDate > contract.endDate) {
        isValid = false;
        errors[i].startDate = true;
      }

      if (discount.endDate && discount.startDate > discount.endDate) {
        isValid = false;
        errors[i].endDate = true;
      }

      if (contract.endDate && discount.endDate && discount.endDate > contract.endDate) {
        isValid = false;
        errors[i].endDate = true;
      }

      // Only last period can have null endDate
      if (!discount.endDate && i !== contract.discountPeriods.length - 1) {
        isValid = false;
        errors[i].endDate = true;
      }

      if (!discount.endDate && !discount.hasNoEndDate) {
        isValid = false;
        errors[i].endDate = true;
      }

      if (i > 0 && discount.startDate < contract.discountPeriods[i - 1].endDate!) {
        isValid = false;
        errors[i].startDate = true;
      }

      if (discount.discount === 0) {
        isValid = false;
        errors[i].discount = true;
      }
    }

    if (isVendorContract) {
      setState((prevState) => ({
        ...prevState,
        vendorContract: {
          ...(prevState.vendorContract as VendorContract),
          errors: { ...prevState.vendorContract?.errors, discountPeriods: errors },
        },
      }));
    } else {
      setState((prevState) => ({ ...prevState, errors: { ...prevState.errors, discountPeriods: errors } }));
    }

    return isValid;
  };
