import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";
import { TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";

import { attributionGroupsText } from "../../../assets/texts";
import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";

export const UnallocatedCosts = () => (
  <Paper
    variant="outlined"
    sx={{
      py: 2,
      px: 3,
    }}
  >
    <Typography variant="subtitle1" fontWeight={500} mb={2}>
      {allocationTxt.UNALLOCATED_COSTS}
    </Typography>

    <Typography variant="body2" color="text.secondary" mb={2}>
      {allocationTxt.UNALLOCATED_COSTS_DESC}

      <Link href="https://help.doit.com/docs/cloud-analytics/attribution-groups" underline="none" target="_blank">
        <Typography
          component="span"
          variant="body2"
          color="primary"
          sx={{ display: "inline-flex", alignItems: "center", gap: 0.5 }}
        >
          {allocationTxt.COST_ALLOCATION}
          <OpenInNewOutlined fontSize="inherit" />
        </Typography>
      </Link>
    </Typography>

    <TextField
      name="title"
      variant="outlined"
      placeholder={attributionGroupsText.UNALLOCATED}
      sx={{ maxWidth: 540, width: "100%" }}
    />
    <Typography color="text.secondary" fontSize={12}>
      {allocationTxt.UNALLOCATED_COSTS_HELPER_TEXT}
    </Typography>
  </Paper>
);
