import { type CloudFlowNodeType, CloudFlowProvider } from "@doitintl/cmp-models";
import ApiOutlinedIcon from "@mui/icons-material/ApiOutlined";
import { Box, Chip } from "@mui/material";

import AWSLogoLight from "../../../../assets/aws-logo.svg";
import AWSLogoDark from "../../../../assets/aws-logo-dark-mode.svg";
import DoitLogo from "../../../../assets/doit-logo-hero-square.svg";
import GCPLogo from "../../../../assets/gcp-logo.svg";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { colors } from "../../../../muiTheme";
import { type RFNode } from "../../types";
import { SourceHandle, TargetHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  selected?: boolean;
  data: RFNode<CloudFlowNodeType.ACTION>;
};

const getIcon = (isDarkMode: boolean, provider?: CloudFlowProvider) => {
  const AWSLogo = isDarkMode ? AWSLogoDark : AWSLogoLight;
  switch (provider) {
    case CloudFlowProvider.AWS:
      return (
        <Box
          component="img"
          src={AWSLogo}
          sx={{
            width: 18,
            height: 18,
          }}
        />
      );
    case CloudFlowProvider.GCP:
      return (
        <Box
          component="img"
          src={GCPLogo}
          sx={{
            width: 18,
            height: 18,
          }}
        />
      );
    case CloudFlowProvider.DoiT:
      return (
        <Box
          component="img"
          src={DoitLogo}
          sx={{
            width: 18,
            height: 18,
          }}
        />
      );
    default:
      return <ApiOutlinedIcon color="primary" width={18} height={18} />;
  }
};

export const ActionNode = ({ id, data, selected }: Props) => {
  const isDarkMode = useDarkThemeCheck();

  return (
    <>
      <NodeWrapper
        title={`Service: ${data.nodeData.parameters?.operation.service}`}
        status={data.nodeData.status || "error"}
        errorMessages={data.nodeData.errorMessages || {}}
        onEdit={data.onEditNode}
        onDelete={data.onDeleteNode}
        isActive={!!selected}
        icon={getIcon(isDarkMode, data.nodeData.parameters?.provider)}
        name={`${data.stepNumber}. ${data.nodeData.name}`}
        httpOperationLoading={data.httpOperationLoading}
      >
        {data.nodeData.approval?.required && (
          <Chip
            label="Approval needed"
            size="small"
            sx={{
              background: colors.light.info.background,
            }}
          />
        )}
      </NodeWrapper>
      <SourceHandle id={id} />
      <TargetHandle id={id} />
    </>
  );
};
