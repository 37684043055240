import React, { type ReactNode } from "react";

import { BarChart } from "@mui/icons-material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Card, CardContent, Grid2, Typography } from "@mui/material";

import { useDarkThemeCheck } from "../hooks/useDarkThemeCheck";

type PresetQuestion = {
  displayQuestion: string;
  detailedQuestion: string;
  icon: ReactNode;
};

type Props = {
  onAskQuestion: (question: string) => void;
};

export const PresetPrompts = ({ onAskQuestion }: Props) => {
  const isDarkMode = useDarkThemeCheck();

  const presetQuestions: PresetQuestion[] = [
    {
      displayQuestion: "Show me my top 3 services by spend for the past month",
      detailedQuestion: "Generate a report showing my top 3 services by spend in the past month",
      icon: <BarChart fontSize="small" color="primary" />,
    },
    {
      displayQuestion: "How do I run workloads on EC2 Spot Instances?",
      detailedQuestion:
        "Provide detailed information about how DoiT Spot Scaling can help me run workloads reliably on EC2 Spot Instances",
      icon: <ChatBubbleOutlineIcon fontSize="small" color="primary" />,
    },
    {
      displayQuestion: "Show my top services by spend in the last 2 weeks",
      detailedQuestion: "Show my top services by spend in the last 2 weeks",
      icon: <BarChart fontSize="small" color="primary" />,
    },
    {
      displayQuestion: "How do I grant DoiT Console access to my colleagues?",
      detailedQuestion: "Explain the steps to invite a new user to the DoiT console",
      icon: <ChatBubbleOutlineIcon fontSize="small" color="primary" />,
    },
  ];

  return (
    <Grid2 container spacing={2} sx={{ margin: 0, width: "60%" }} justifyContent="center">
      {presetQuestions.map((question) => (
        <Grid2 size={{ xs: 1, sm: 6 }} key={question.detailedQuestion}>
          <Card
            sx={{
              width: "100%",
              height: "100%",
              bgcolor: "background.paper",
              borderRadius: "16px",
              borderColor: isDarkMode ? "#FFFFFF1F" : "#0000001F",
              cursor: "pointer",
              "&:hover": {
                borderColor: "primary.main",
              },
              "& .MuiCardContent-root": {
                color: isDarkMode ? "primary.main" : "#00000099",
              },
            }}
            onClick={() => {
              onAskQuestion(question.detailedQuestion);
            }}
          >
            <CardContent>
              {question.icon}
              <Typography variant="body2" color="text.secondary">
                {question.displayQuestion}
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      ))}
    </Grid2>
  );
};
