import { TableCell } from "@mui/material";

export type EllipsisCellProps = {
  /**
   * The content of the cell - should be a string, as anything else might not work with the ellipsis styling.
   * Only widen this type if you're sure it'll look fine.
   */
  children: string;

  /**
   * The width after which to truncate the text and show an ellipsis.
   */
  maxWidth: string;
};

export const EllipsisCell = ({ children, maxWidth }: EllipsisCellProps) => (
  <TableCell sx={{ maxWidth, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }} title={children}>
    {children}
  </TableCell>
);
