import { isProduction } from "../constants";

// NOTE: This is for fallback only. Remove when it's time to remove the fallback
const devSauronURL = "https://api.sauron-dev.leepackham.com/api/";
const prodSauronURL = "https://api.aws-ops.doit-intl.com/api/";

const getCreTicketURL_DEV = "https://getcre-server-6m3tp25p7q-ew.a.run.app";
const getCreTicketURL_PROD = "https://getcre-server-eb6y5t7axq-ew.a.run.app";

export const sauronUrl = isProduction ? prodSauronURL : devSauronURL;

export const getCreTicketURL = isProduction ? getCreTicketURL_PROD : getCreTicketURL_DEV;

// NOTE: This is for fallback only. Remove when it's time to remove the fallback
export const getSauronManagementArn = (accountId: string) => {
  if (isProduction) {
    return `arn:aws:iam::${accountId}:role/DoiT_Management_Role_DO-NOT-DELETE`;
  }

  return `arn:aws:iam::${accountId}:role/Soteria_Management_Role_DO-NOT-DELETE`;
};
