import { useEffect, useState } from "react";

import PhoneInput from "react-phone-input-2";
import { Avatar, Box, Button, Container, MenuItem, TextField, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useAccountAssumption } from "../../../Components/hooks/useAccountAssumption";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useDashboardsContext } from "../../../Context/useDashboardsContext";
import { getGravatar } from "../../../Layout/GravatarApi";
import { ThemeModes } from "../../../muiThemeTypes";
import { type User, type UserOrInvite } from "../../../types";
import { capitalizeStartCase, jobFunction as jobFunctionOptions } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import { LanguageSelector } from "./LanguageSelector";
import { ProfileHeader, useStyles } from "./UserProfileTabStyles";

export type UserProfileTabProps = {
  userOrInvite: UserOrInvite;
  updateUser: (updateData: Partial<User>) => Promise<void>;
  onImpersonateUser?: () => Promise<void>;
};

export const UserProfileTab = ({ userOrInvite, updateUser, onImpersonateUser }: UserProfileTabProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile } = useFullScreen();
  const { dashboards, setDefaultDashboard, defaultDashboard } = useDashboardsContext();
  const [firstName, setFirstName] = useState(userOrInvite.firstName ?? "");
  const [lastName, setLastName] = useState(userOrInvite.lastName ?? "");
  const [phone, setPhone] = useState(userOrInvite.phone ?? "");
  const [phoneExtension, setPhoneExtension] = useState(userOrInvite.phoneExtension ?? "");
  const [gravatar, setGravatar] = useState<string | null>();
  const { customer } = useCustomerContext();
  const accountAssumptionAllowed = useAccountAssumption(customer);
  const [language, setLanguage] = useState(userOrInvite?.language ?? "en");

  const [firstNameInvalidMessage, setFirstNameInvalidMessage] = useState<string>("");
  const [lastNameInvalidMessage, setLastNameInvalidMessage] = useState<string>("");

  const validName = (str: string) => {
    // allow only alphanumeric, and hyphen if it's not the first character
    const regex = new RegExp(/^[A-Za-z0-9][A-Za-z0-9-]*$/);
    return regex.test(str);
  };

  const getAvatarElement = () => {
    const getStyle = () => {
      if (gravatar) {
        return classes.iconAvatar;
      } else {
        if (userOrInvite.enrichment?.avatar) {
          return classes.iconAvatar;
        } else {
          return classes.avatar;
        }
      }
    };

    const getProps = () => {
      if (gravatar) {
        return { alt: "avatar", src: gravatar };
      } else {
        if (userOrInvite.enrichment?.avatar) {
          return { alt: "avatar", src: userOrInvite.enrichment.avatar };
        } else {
          return { children: [firstName[0]?.toUpperCase()] };
        }
      }
    };

    return <Avatar className={getStyle()} style={{ margin: "0 auto 12px auto" }} {...getProps()} />;
  };

  useEffect(() => {
    let didCancel = false;

    getGravatar(userOrInvite.email).then((gravatarResponse) => {
      if (!didCancel) {
        setGravatar(gravatarResponse);
      }
    });

    return () => {
      didCancel = true;
    };
  }, [userOrInvite.email]);

  return (
    <Container maxWidth="sm" disableGutters>
      <Box
        style={{
          padding: isMobile ? "50px 50px 180px" : "50px 50px 250px",
        }}
      >
        {getAvatarElement()}
        <ProfileHeader>{userOrInvite.email}</ProfileHeader>
        {accountAssumptionAllowed && !userOrInvite.invite && (
          <div className={classes.viewAs}>
            <Button variant="outlined" color="primary" onClick={onImpersonateUser}>
              View as {firstName || userOrInvite.email}
            </Button>
          </div>
        )}
        <Grid container spacing={1}>
          <Grid
            size={{
              md: 6,
              xs: 12,
            }}
          >
            <TextField
              value={capitalizeStartCase(firstName)}
              onChange={(e) => {
                if (!validName(e.target.value)) {
                  setFirstNameInvalidMessage("Only letters, numbers or hyphens allowed");
                } else {
                  setFirstNameInvalidMessage("");
                }
                setFirstName(e.target.value);
              }}
              onBlur={async () => {
                if (!firstNameInvalidMessage) {
                  await updateUser({
                    firstName,
                    displayName: `${firstName} ${lastName}`.trim(),
                  });
                }
              }}
              style={{ borderRadius: 0 }}
              margin="dense"
              label="First Name"
              variant="outlined"
              fullWidth
              error={!!firstNameInvalidMessage}
              helperText={firstNameInvalidMessage}
              slotProps={{
                htmlInput: {
                  "data-cy": "profile-first-name",
                },
              }}
            />
          </Grid>
          <Grid
            size={{
              md: 6,
              xs: 12,
            }}
          >
            <TextField
              value={capitalizeStartCase(lastName)}
              onChange={(e) => {
                if (!validName(e.target.value)) {
                  setLastNameInvalidMessage("Only letters, numbers or hyphens allowed");
                } else {
                  setLastNameInvalidMessage("");
                }
                setLastName(e.target.value);
              }}
              onBlur={async () => {
                if (!lastNameInvalidMessage) {
                  await updateUser({ lastName, displayName: `${firstName} ${lastName}` });
                }
              }}
              margin="dense"
              label="Last Name"
              variant="outlined"
              fullWidth
              error={!!lastNameInvalidMessage}
              helperText={lastNameInvalidMessage}
              slotProps={{
                htmlInput: {
                  "data-cy": "profile-last-name",
                },
              }}
            />
          </Grid>
        </Grid>
        <TextField
          value={userOrInvite.jobFunction?.toString() ?? ""}
          onChange={(e) => updateUser({ jobFunction: Number(e.target.value) })}
          margin="dense"
          label="Job Function"
          variant="outlined"
          select
          fullWidth
        >
          {jobFunctionOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

        <Grid container spacing={1}>
          <Grid
            size={{
              md: 6,
              xs: 12,
            }}
          >
            <PhoneInput
              value={phone ?? ""}
              onChange={(value) => {
                setPhone(value);
              }}
              onBlur={async () => {
                await updateUser({ phone });
              }}
              containerStyle={{
                marginTop: 8,
                marginBottom: 4,
              }}
              inputStyle={{
                height: 40,
                width: "100%",
                backgroundColor: "transparent",
                color: theme.palette.text.primary,
              }}
              searchStyle={{
                margin: 0,
                width: "calc(100% - 10px)",
              }}
              buttonStyle={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.secondary,
              }}
              dropdownStyle={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.mode === ThemeModes.DARK ? theme.palette.primary.main : theme.palette.text.primary,
              }}
              country="us"
              enableSearch
              disableSearchIcon
              searchPlaceholder="Search..."
              inputProps={{
                "data-cy": "profile-phone",
              }}
            />
          </Grid>
          <Grid
            size={{
              md: 6,
              xs: 12,
            }}
          >
            <TextField
              value={phoneExtension ?? ""}
              onChange={(e) => {
                setPhoneExtension(e.target.value.replace(/\D/g, ""));
              }}
              onBlur={async () => {
                await updateUser({ phoneExtension });
              }}
              margin="dense"
              label="Phone Ext."
              name="phoneExtension"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid
            size={{
              md: 12,
              xs: 12,
            }}
          >
            <TextField
              value={dashboards.length === 0 ? "" : defaultDashboard}
              onChange={(e) => setDefaultDashboard(e.target.value)}
              margin="dense"
              label="Default Dashboard"
              variant="outlined"
              disabled={userOrInvite.invite}
              select
              fullWidth
            >
              {dashboards.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid
            size={{
              md: 12,
              xs: 12,
            }}
          >
            <LanguageSelector
              onChange={(lng) => {
                setLanguage(lng);
                updateUser({ language: lng });
              }}
              value={language}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
