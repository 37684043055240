import { useEffect, useState } from "react";

import { useAvaContext } from "@doitintl/ava-components";

export const useNewQuestionAsked = () => {
  const [prevConversationId, setPrevConversationId] = useState<string>();
  const [newQuestionAsked, setNewQuestionAsked] = useState(false);
  const { conversationId } = useAvaContext();

  useEffect(() => {
    if (conversationId !== prevConversationId) {
      if (prevConversationId) {
        setNewQuestionAsked(false);
      }
      setPrevConversationId(conversationId);
    }
  }, [conversationId, setNewQuestionAsked, prevConversationId]);

  return {
    newQuestionAsked,
    setNewQuestionAsked,
  };
};

export const useConversationIdChanged = (callback: () => void) => {
  const [prevConversationId, setPrevConversationId] = useState<string>();
  const { conversationId } = useAvaContext();

  useEffect(() => {
    if (conversationId !== prevConversationId) {
      setPrevConversationId(conversationId);
      callback();
    }
  }, [conversationId, setPrevConversationId, prevConversationId, callback]);

  return prevConversationId;
};
