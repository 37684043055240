import { useCallback, useState } from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, Dialog, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";

import LoadingButton from "../../../../Components/LoadingButton";
import { type Dashboard } from "../../../../Context/useDashboardsContext";

type Props = {
  open: boolean;
  selected: Dashboard[];
  unselected: Dashboard[];
  onSelectionChange: (selected: Dashboard[]) => Promise<unknown>;
  onClose: () => void;
};

export function OrganizationDashboardsDialog({
  open,
  selected,
  unselected,
  onSelectionChange,
  onClose,
}: Readonly<Props>) {
  const [localSelected, setLocalSelected] = useState(selected);
  const [loading, setLoading] = useState(false);

  const handleSelection = useCallback(async () => {
    setLoading(true);
    await onSelectionChange(localSelected);
    setLoading(false);
    onClose();
  }, [onSelectionChange, localSelected, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add and remove organization dashboards</DialogTitle>
      <DialogContent>
        <Autocomplete
          data-cy="dashboard-name"
          sx={{ pt: 2, width: "100%" }}
          size="small"
          multiple
          value={localSelected}
          options={[...selected, ...unselected]}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          onChange={(event: any, newSelected: Dashboard[] | null) => {
            setLocalSelected(newSelected ?? []);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: "100%" }}
              label="Dashboard name"
              placeholder="Add and remove organization dashboards"
            />
          )}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={loading}
          onClick={handleSelection}
          mixpanelEventId="dashboard.attach"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
