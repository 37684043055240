import { memo, Suspense } from "react";

import { DynamicHookLoaderWrapper } from "./DynamicHookLoaderWrapper";
import { type DynamicStore } from "./dynamicStore";

type Props = {
  customerId: string;
  loadedHooks: DynamicStore["hooks"];
};

function DynamicCachedHooksLoaderListImpl({ customerId, loadedHooks }: Props) {
  return (
    <>
      {Array.from(loadedHooks.entries()).map(([hookKey, extra]) => (
        <Suspense key={hookKey + customerId}>
          <DynamicHookLoaderWrapper name={hookKey} useHook={extra.hookFunc} args={extra?.args ?? []} />
        </Suspense>
      ))}
    </>
  );
}

export const DynamicCachedHooksLoaderList = memo(DynamicCachedHooksLoaderListImpl);
