import { useMemo } from "react";

import { getModelByPath, getModelRequiredTupleByPath, isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import {
  ComparisonOperator,
  type ConditionExpression,
  ConditionExpressionType,
  type ListApiServiceModelDescriptor,
  type Member,
  ModelType,
  type ReferencedNodeValue,
  type StringApiServiceModelDescriptor,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import * as Yup from "yup";

import { type NodeWitOutputModel } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { generateApiActionParametersSchema } from "../../ApiActionParametersForm/useApiActionParametersSchema";

const defaultModel: StringApiServiceModelDescriptor = { type: ModelType.STRING };

const defaultOperators = [ComparisonOperator.EQUALS, ComparisonOperator.NOT_EQUALS];
const listOfPrimitivesOperators = [
  ComparisonOperator.CONTAINS,
  ComparisonOperator.NOT_CONTAINS,
  ComparisonOperator.IS_NULL,
  ComparisonOperator.IS_NOT_NULL,
];
const numericOperators = [
  ComparisonOperator.EQUALS,
  ComparisonOperator.NOT_EQUALS,
  ComparisonOperator.IS_NULL,
  ComparisonOperator.IS_NOT_NULL,
  ComparisonOperator.GREATER_THAN,
  ComparisonOperator.GREATER_THAN_OR_EQUAL,
  ComparisonOperator.LESS_THAN,
  ComparisonOperator.LESS_THAN_OR_EQUAL,
];
const stringOperators = [
  ComparisonOperator.EQUALS,
  ComparisonOperator.NOT_EQUALS,
  ComparisonOperator.IS_NULL,
  ComparisonOperator.IS_NOT_NULL,
  ComparisonOperator.IN,
  ComparisonOperator.NOT_IN,
];
const booleanOperators = [
  ComparisonOperator.EQUALS,
  ComparisonOperator.NOT_EQUALS,
  ComparisonOperator.IS_NULL,
  ComparisonOperator.IS_NOT_NULL,
];

export type FilterDialogFormValues = ConditionExpression & {
  fieldReference: ReferencedNodeValue;
};

export const isComplexModelType = (type: ModelType) =>
  [ModelType.LIST, ModelType.STRUCTURE, ModelType.MAP, ModelType.UNION].includes(type);

function isListOfPrimitives(model: UnwrappedApiServiceModelDescriptor): model is ListApiServiceModelDescriptor<Member> {
  return model.type === ModelType.LIST && !isComplexModelType(model.member.model.type);
}

export const getOperatorsByModel = (model: UnwrappedApiServiceModelDescriptor) => {
  switch (true) {
    case isListOfPrimitives(model):
      return listOfPrimitivesOperators;
    case [ModelType.INTEGER, ModelType.FLOAT, ModelType.TIMESTAMP].includes(model.type):
      return numericOperators;
    case model.type === ModelType.STRING:
      return stringOperators;
    case model.type === ModelType.BOOLEAN:
      return booleanOperators;
    default:
      return defaultOperators;
  }
};

export function getCriteriaValueModel(
  referencedModel: UnwrappedApiServiceModelDescriptor,
  operator: ComparisonOperator
): UnwrappedApiServiceModelDescriptor {
  if (isListOfPrimitives(referencedModel)) {
    return referencedModel.member.model;
  }
  if (isComplexModelType(referencedModel.type)) {
    return defaultModel;
  }
  if ([ComparisonOperator.IN, ComparisonOperator.NOT_IN].includes(operator)) {
    return {
      type: ModelType.LIST,
      member: {
        model: referencedModel,
      },
      memberName: "Value",
    };
  }
  return referencedModel;
}

export function useFilterFormSchema(
  selectedNode: NodeWitOutputModel,
  referenceableNodes: NodeWitOutputModel[],
  condition: ConditionExpression | undefined
) {
  const schema = useMemo(
    () =>
      Yup.object({
        fieldReference: Yup.object({
          referencedNodeId: Yup.string().defined().default(selectedNode.id),
          referencedField: Yup.array<Yup.AnyObject, string>().defined().default<string[]>([]),
        }).test(
          "referenced-field-model",
          "Referenced field must reference a primitive or a list of primitives field",
          (value) => {
            const referencedModel = getModelByPath(selectedNode.outputModel, value.referencedField);
            return (
              !isComplexModelType(referencedModel.type) ||
              (referencedModel.type === ModelType.LIST && !isComplexModelType(referencedModel.member.model.type))
            );
          }
        ),

        field: Yup.ref<string[]>("fieldReference.referencedField"),

        type: Yup.mixed<ConditionExpressionType>()
          .defined()
          .default(ConditionExpressionType.STATIC)
          .when("value", ([value], schema) =>
            schema.transform(() =>
              isReferencedNodeValue(value) ? ConditionExpressionType.REFERENCED : ConditionExpressionType.STATIC
            )
          ),

        comparisonOperator: Yup.mixed<ComparisonOperator>()
          .defined()
          .default(ComparisonOperator.EQUALS)
          .when("fieldReference.referencedField", ([referencedField], schema) => {
            if (!Array.isArray(referencedField)) {
              return schema;
            }
            const referencedModel = getModelByPath(selectedNode.outputModel, referencedField);
            const operators = getOperatorsByModel(referencedModel);
            return schema
              .oneOf(operators)
              .default(operators[0])
              .transform(function t(value) {
                return operators.includes(value) ? value : this.getDefault();
              });
          }),
        value: Yup.mixed<ReferencedNodeValue>()
          .defined()
          .when(["fieldReference.referencedField", "comparisonOperator"], ([referencedField, operator]) => {
            if (operator === ComparisonOperator.IS_NULL || operator === ComparisonOperator.IS_NOT_NULL) {
              return Yup.string().nullable().default(null);
            }

            if (!Array.isArray(referencedField)) {
              return Yup.string().default("");
            }

            const referencedModel = getModelByPath(selectedNode.outputModel, referencedField);
            const model = getCriteriaValueModel(referencedModel, operator);
            const [, isRequired] = getModelRequiredTupleByPath(selectedNode.outputModel, referencedField);

            return generateApiActionParametersSchema(
              model,
              isRequired || isComplexModelType(model.type),
              {
                referenceableNodes,
                model: { type: ModelType.STRUCTURE, members: { value: { model } } },
              },
              "Value",
              [ComparisonOperator.IN, ComparisonOperator.NOT_IN].includes(operator)
            );
          }),
      }),
    [referenceableNodes, selectedNode.id, selectedNode.outputModel]
  );

  const initialValues: FilterDialogFormValues = useMemo(
    () =>
      condition
        ? { ...condition, fieldReference: { referencedField: condition.field, referencedNodeId: selectedNode.id } }
        : { ...schema.getDefault(), field: [] },
    [condition, schema, selectedNode.id]
  );

  return [schema, initialValues] as const;
}
