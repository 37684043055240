import { type ReactNode } from "react";

import { Box, Card, CardContent, CardHeader, Skeleton, Typography, useTheme } from "@mui/material";

type MetadataCardProps = {
  title: string;
  children: ReactNode;
  loading?: boolean;
  error?: boolean;
  errorContent?: ReactNode;
};

export const MetadataCard = ({ title, children, loading, error = false, errorContent }: MetadataCardProps) => {
  const theme = useTheme();

  const content = error
    ? errorContent || (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" color="textSecondary">
            An error occurred while loading content.
          </Typography>
        </Box>
      )
    : children;

  return (
    <Card
      sx={{
        flex: 1,
        "& .MuiCardHeader-root": {
          backgroundColor: theme.palette.general.backgroundDark,
          borderBottom: `1px solid ${theme.palette.general.divider}`,
        },
      }}
    >
      <CardHeader
        titleTypographyProps={{
          variant: "body1",
          fontWeight: 500,
        }}
        title={loading ? <Skeleton width="40%" /> : title}
      />
      <CardContent>{content}</CardContent>
    </Card>
  );
};
