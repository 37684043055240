import { useMemo } from "react";

import { CloudflowEngineModel, type CloudflowExecutionModel, type ExecutionNodeModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData, useDocumentData } from "@doitintl/models-firestore";

export function useExecutionModel(executionId: string): {
  execution: CloudflowExecutionModel | undefined;
  loading: boolean;
  error: Error | undefined;
} {
  const query = useMemo(
    () => getCollection(CloudflowEngineModel).doc("cloudflows").collection("cloudflowExecutions").doc(executionId),
    [executionId]
  );

  const [execution, loading, error] = useDocumentData(query);

  return { execution, loading, error };
}

export function useExecutionNodes(executionId: string): {
  nodes: ExecutionNodeModel[] | undefined;
  loading: boolean;
  error: Error | undefined;
} {
  const query = useMemo(
    () =>
      getCollection(CloudflowEngineModel)
        .doc("cloudflows")
        .collection("cloudflowExecutions")
        .doc(executionId)
        .collection("executionNodes")
        .orderBy("startedAt", "asc"),
    [executionId]
  );

  const [nodes, loading, error] = useCollectionData(query);
  return { nodes, loading, error };
}
