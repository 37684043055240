import { type JSX, useCallback, useEffect, useMemo, useState } from "react";

import { Link as RouterLink, useParams } from "react-router-dom";
import {
  type AccountManagerModel,
  AppModel,
  isPerkPlatform,
  isPerkSolutionType,
  isPerkType,
  PerkModel,
  type PerkPlatform,
  type PerkSolutionType,
  type PerkType,
  PerkTypes,
  professionalServicePerkTypes,
  SolveTierPackageSKUs,
  TierPackageTypes,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useApiContext } from "../../api/context";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useIsFeatureEntitled, useIsFeatureEntitledMultiple, useTier } from "../../Context/TierProvider";
import { useUserContext } from "../../Context/UserContext";
import { type PerksWData } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import mixpanel from "../../utils/mixpanel";
import { isDci } from "../Settings/General/utils";
import { PerksApi } from "./api";
import { getPlatformNonPromotion, getSingularTypeText, toBeautifiedName } from "./helpers";
import {
  type FilterablePerkType,
  type GroupedPerks,
  type PerkViewFields,
  type RegisterInterestFields,
  RegisterInterestFromType,
  type SectionTitles,
} from "./types";
import {
  type AcceleratorEntitlementKey,
  acceleratorEntitlementKeys,
  getHelperText,
  type ProfessionalServicesEntitlementKey,
  type WorkshopEntitlementKey,
  workshopEntitlementKeys,
} from "./utils";
import type { Ticket } from "../../Support/types";
import type { PerkFilterMobileBasic } from "./PerkFilter/PerkFilterMobile";

export function usePerksApi() {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  return useMemo(() => new PerksApi(api, customer.id), [api, customer.id]);
}

type PerksHook = {
  perks: PerksWData[];
  loading: boolean;
};
export const usePerks = (): PerksHook => {
  const { isDoitEmployee } = useAuthContext();

  const [perks, setPerks] = useState<PerksWData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let query = getCollection(PerkModel).where("fields.deleted", "==", false);

    if (!isDoitEmployee) {
      query = query.where("fields.active", "==", true);
    }

    return query.onSnapshot((snapshot) => {
      const allPerks = snapshot.docs.reduce((acc: PerksWData[], doc) => {
        const perk = {
          id: doc.id,
          data: doc.asModelData(),
        };

        acc.push(perk);
        return acc;
      }, []);

      mixpanel.track("perks.list", {});
      setPerks(allPerks);
      setLoading(false);
    });
  }, [isDoitEmployee]);
  return { perks, loading };
};

export const useIsProfessionalService = (): ((type: PerkType) => boolean) =>
  useCallback<(type: PerkType) => boolean>((perkType) => {
    if (!perkType) {
      return false;
    }
    return professionalServicePerkTypes.some((serviceType: PerkType) => serviceType === perkType);
  }, []);

export const usePerkTagsHandler = ({
  perk,
  isDoitEmployee,
}: {
  perk: PerkModel | undefined;
  isDoitEmployee: boolean;
}) =>
  useCallback(() => {
    if (!perk?.fields) {
      return [];
    }

    const { type } = perk.fields;
    const tags: string[] = [];

    if (!["training", "pro-serv", "workshop"].includes(type)) {
      tags.push("cmp/service/perk");
    }

    if (isDoitEmployee) {
      tags.push("requested_by_doer");
    }

    return tags;
  }, [isDoitEmployee, perk?.fields]);

export const useMarketplaceListingHandler = (
  registerInterest: (interestFrom: RegisterInterestFromType) => Promise<void>
) =>
  useCallback(
    async (interestFrom: RegisterInterestFromType, url: string) => {
      await registerInterest(interestFrom);
      window.open(url, "_blank", "noopener,noreferrer");
    },
    [registerInterest]
  );

export const useServicesMixpanelHandler = () => {
  const { userModel } = useUserContext();
  const { getCustomerTier } = useTier();

  const mixpanelObject = useMemo(
    () => ({
      customerName: userModel?.customer?.name,
      customerTier: {
        [TierPackageTypes.SOLVE]: getCustomerTier(TierPackageTypes.SOLVE)?.displayName,
        [TierPackageTypes.NAVIGATOR]: getCustomerTier(TierPackageTypes.NAVIGATOR)?.displayName,
      },
    }),
    [userModel?.customer?.name, getCustomerTier]
  );

  const mixpanelServicesHandler = useCallback(
    (mixpanelTracker: string) => {
      mixpanel.track(mixpanelTracker, mixpanelObject);
    },
    [mixpanelObject]
  );

  return { mixpanelServicesHandler };
};

type ProfessionalServicesEntitlements = {
  acceleratorEntitlement?: AcceleratorEntitlementKey;
  workshopEntitlement?: WorkshopEntitlementKey;
  trainingEntitlement?: "services:training";
};

/**
 * Provides the highest (most privileged) accelerator, workshop, and training entitlements the customer has.
 * If any of the entitlements are undefined, that means that the customer is not entitled to that particular service.
 */
export const useProfessionalServicesEntitlements = (): ProfessionalServicesEntitlements => {
  const ascendingAcceleratorsMap = useIsFeatureEntitledMultiple(acceleratorEntitlementKeys);
  const ascendingWorkshopsMap = useIsFeatureEntitledMultiple(workshopEntitlementKeys);
  const trainingEntitled = useIsFeatureEntitled("services:training");

  const { acceleratorEntitlement, workshopEntitlement, trainingEntitlement } =
    useMemo<ProfessionalServicesEntitlements>(() => {
      const result: ProfessionalServicesEntitlements = {
        acceleratorEntitlement: undefined,
        workshopEntitlement: undefined,
        trainingEntitlement: undefined,
      };

      acceleratorEntitlementKeys.forEach((entitlement) => {
        if (ascendingAcceleratorsMap[entitlement]) {
          result.acceleratorEntitlement = entitlement;
        }
      });

      workshopEntitlementKeys.forEach((entitlement) => {
        if (ascendingWorkshopsMap[entitlement]) {
          result.workshopEntitlement = entitlement;
        }
      });

      if (trainingEntitled) {
        result.trainingEntitlement = "services:training";
      }

      return result;
    }, [ascendingAcceleratorsMap, ascendingWorkshopsMap, trainingEntitled]);

  return { acceleratorEntitlement, workshopEntitlement, trainingEntitlement };
};

export const useInfoText = (perkType: PerkType, hideForDoer: boolean = false) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { getCustomerTier } = useTier();
  const solveTier = getCustomerTier(TierPackageTypes.SOLVE);
  const navigatorTier = getCustomerTier(TierPackageTypes.NAVIGATOR);
  const { acceleratorEntitlement, workshopEntitlement, trainingEntitlement } = useProfessionalServicesEntitlements();

  const tierDisplayName = useMemo<string | undefined>(() => {
    if (isDci(navigatorTier?.displayName)) {
      return navigatorTier!.displayName;
    }
    if (
      (
        [
          SolveTierPackageSKUs.ADVANTAGE_ONLY,
          SolveTierPackageSKUs.LEGACY_RESOLD,
          SolveTierPackageSKUs.TRIAL,
          "",
        ] as string[]
      ).includes(solveTier?.sku ?? "")
    ) {
      return "DCI";
    }

    return solveTier?.displayName;
  }, [navigatorTier, solveTier?.displayName, solveTier?.sku]);

  const infoText = useMemo<string | undefined>(() => {
    if (hideForDoer && isDoitEmployee) {
      return;
    }

    const entitlementsByProfessionalServiceType: Record<
      PerkTypes.Training | PerkTypes.Accelerator | PerkTypes.Workshop,
      ProfessionalServicesEntitlementKey | undefined
    > = {
      [PerkTypes.Training]: trainingEntitlement,
      [PerkTypes.Accelerator]: acceleratorEntitlement,
      [PerkTypes.Workshop]: workshopEntitlement,
    };

    if ([PerkTypes.Training, PerkTypes.Accelerator, PerkTypes.Workshop].every((t) => t !== perkType)) {
      return;
    }

    if (perkType === PerkTypes.Training && !trainingEntitlement && isDci(navigatorTier?.displayName)) {
      return "Training is not included in the DCI Essentials plan";
    }

    return getHelperText(entitlementsByProfessionalServiceType[perkType], tierDisplayName);
  }, [
    acceleratorEntitlement,
    hideForDoer,
    isDoitEmployee,
    navigatorTier,
    perkType,
    tierDisplayName,
    trainingEntitlement,
    workshopEntitlement,
  ]);

  return { infoText };
};

export const useIncludedProfessionalServices = (perkType?: PerkType) => {
  const { acceleratorEntitlement, workshopEntitlement, trainingEntitlement } = useProfessionalServicesEntitlements();

  return useMemo<boolean>(() => {
    if (!perkType) {
      return false;
    }

    switch (perkType) {
      case PerkTypes.Accelerator:
        return acceleratorEntitlement === "services:accelerator:included";
      case PerkTypes.Training:
        return trainingEntitlement === "services:training";
      case PerkTypes.Workshop:
        return workshopEntitlement === "services:workshop:included";
      default:
        return false;
    }
  }, [acceleratorEntitlement, perkType, trainingEntitlement, workshopEntitlement]);
};

/**
 * returns the doit account manager for a customer as opposed to using customer.accountManager,
 * which counterintuitively may not actually BE an active account manager
 */
export const useCustomerAccountManager = (): AccountManagerModel | undefined => {
  const { customer } = useCustomerContext();
  const [accountManager, setAccountManager] = useState<AccountManagerModel | undefined>();

  useEffect(() => {
    const getAmName = async () => {
      if (!customer?.accountTeam) {
        return;
      }
      const activeDoitAccountManagers: (AccountManagerModel | undefined)[] = [];
      const activeDoitAccountManagersDocs = await Promise.all(
        customer.accountTeam.filter((am) => am.company === "doit").map((amDetails) => amDetails.ref.get())
      );
      activeDoitAccountManagers.push(...activeDoitAccountManagersDocs.map((doc) => doc.asModelData()));

      let amList: AccountManagerModel[] = [];
      let samList: AccountManagerModel[] = [];

      ({ amList, samList } = activeDoitAccountManagers.reduce(
        (acc, manager) => {
          if (!manager || manager.status !== "ACTIVE") {
            return acc;
          }

          if (manager.role === "account_manager") {
            acc.amList.push(manager);
          } else if (manager.role === "strategic_accounts_manager") {
            acc.samList.push(manager);
          }
          return acc;
        },
        { amList, samList }
      ));

      if (amList.length) {
        setAccountManager(amList[0]);
      } else if (samList.length) {
        setAccountManager(samList[0]);
      }
    };

    getAmName().catch(consoleErrorWithSentry);
  }, [customer.accountTeam]);

  return accountManager;
};

/**
 * Provides the basic information required to render the information in the PerkView component.
 * @returns {PerkViewFields}
 * @property {boolean} disabledPerk - A perk is usually disabled because the customer already has an open request for it
 * @property {boolean} perkLoading - Indicates whether the perk is still loading rather than merely blank
 * @property {PerkModel} [perk] - The perk data
 * @property {SectionTitles} [sectionTitles] - Section titles for displaying within the perk
 * @property {Ticket} [requestTicket] - The request ticket for the perk, if it exists and is still open
 * @property {function(Ticket):void} setRequestTicket - Function to set the request ticket.
 * @property {function(boolean):void} setDisabledPerk - Function to set the disabled state of the perk.
 */
export const usePerkView = (): PerkViewFields => {
  const defaultSectionTitles: SectionTitles = useMemo<SectionTitles>(
    () => ({
      overview: "Overview",
      checklist: "Pricing",
      agenda: "Support",
      modules: "Features",
    }),
    []
  );

  const { perkId } = useParams<{ perkId: string }>();
  const { customer } = useCustomerContext();
  const { isDoitEmployee, userId } = useAuthContext({ mustHaveUser: true });
  const perksApi = usePerksApi();

  const [perk, setPerk] = useState<PerkModel>();
  const [requestTicket, setRequestTicket] = useState<Ticket>();
  const [sectionTitles, setSectionTitles] = useState<SectionTitles>(defaultSectionTitles);
  const [perkLoading, setPerkLoading] = useState<boolean>(false);
  const [disabledPerk, setDisabledPerk] = useState<boolean>(true);

  useEffect(() => {
    setPerkLoading(true);
    Promise.all([
      getCollection(PerkModel).doc(perkId).get(),
      getCollection(AppModel).doc("perks").get(),
      getCollection(PerkModel).doc(perkId).collection("requestedUsers").doc(userId).get(),
    ])
      .then(([readPerkDoc, configDoc, ticketSnapshot]) => {
        const config = configDoc.asModelData();
        const readPerk = readPerkDoc.asModelData();
        setPerk(readPerk);

        const customSectionTitles = config?.perksType?.find((el) => el.id === (readPerk?.fields.type ?? "promotion"));
        if (customSectionTitles) {
          Object.keys(defaultSectionTitles).forEach((key) => {
            if (customSectionTitles[key]) {
              defaultSectionTitles[key] = customSectionTitles[key];
            }
          });
          setSectionTitles(defaultSectionTitles);
        }

        const tickets = ticketSnapshot.asModelData();
        const ticketId = isDoitEmployee ? tickets?.ticketsOnBehalfOfCustomers?.[customer.id] : tickets?.ticketId;

        if (!ticketId || !readPerk) {
          setDisabledPerk(false);
          return;
        }

        return perksApi
          .getTicketById(readPerk.fields.name, ticketId)
          .then((resp) => {
            const perkDisabled = resp.data.ticket.status !== "closed" && resp.data.ticket.status !== "solved";
            setRequestTicket(resp.data.ticket);
            setDisabledPerk(perkDisabled);
          })
          .catch((error) => {
            setDisabledPerk(true);
            consoleErrorWithSentry(error);
          });
      })
      .finally(() => {
        setPerkLoading(false);
      });
  }, [customer.id, defaultSectionTitles, isDoitEmployee, perkId, perksApi, userId]);

  return {
    perk,
    requestTicket,
    setRequestTicket,
    sectionTitles,
    perkLoading,
    disabledPerk,
    setDisabledPerk,
  };
};

/**
 * Provides a callback to register interest in a perk, and a boolean indicating whether the registration is in progress.
 * @param setRequestServiceDialogOpen setter for whether the {@link RequestProfessionalServiceDialog} is open (used for professional services).
 * @param setRegisterDialogOpen setter for whether the {@link RegisterInterestDialog} is open (used for ISVs).
 */
export const useRegisterInterest = (
  setRequestServiceDialogOpen: (open: boolean) => void,
  setRegisterDialogOpen: (open: boolean) => void
): RegisterInterestFields => {
  const { isDoitEmployee, userId, currentUser } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();
  const { getCustomerTier } = useTier();

  const [processing, setProcessing] = useState<boolean>(false);

  const { perkId } = useParams<{ perkId: string }>();
  const { perk, setDisabledPerk, setRequestTicket } = usePerkView();
  const { mixpanelServicesHandler } = useServicesMixpanelHandler();
  const snackbar = useSnackbar();
  const isIncluded = useIncludedProfessionalServices();
  const getPerkTags = usePerkTagsHandler({ perk, isDoitEmployee });
  const perksApi = usePerksApi();
  const accountManager = useCustomerAccountManager();
  const isProfessionalService = useIsProfessionalService();

  const registerInterestProServ = useCallback(async () => {
    if (!perk) {
      return;
    }

    setProcessing(true);
    const tags = [...getPerkTags(), ...(perk?.fields.zendeskTags || [])];
    if (perk.fields.type === PerkTypes.Training && !tags.includes("cre/training")) {
      tags.push("cre/training");
    }

    try {
      const ticketRes = await perksApi.registerInterestTicket({
        domain: customer.primaryDomain,
        perkId,
        perkName: perk.fields.name,
        perkType: perk.fields.type ?? "",
        tags: [...getPerkTags(), ...(perk.fields.zendeskTags || [])],
        platform: getPlatformNonPromotion(perk),
        userEmail: currentUser.email,
        accountManager: accountManager?.email,
        isDoitEmployee,

        // new fields
        solutionType: perk.fields.type,
        solutionOffer: perk.fields.name,
        customerSolvePlan: getCustomerTier(TierPackageTypes.SOLVE)?.name,
        actionRequired: isIncluded
          ? "Enroll the customer on professional service"
          : "Sell professional service to customer as required", // enroll the customer, or upsell them. Depends on entitlement.
      });

      if (!ticketRes.data.id) {
        return;
      }

      try {
        const ticketSnapshot = await getCollection(PerkModel)
          .doc(perkId)
          .collection("requestedUsers")
          .doc(userId)
          .get();

        if (isDoitEmployee) {
          const ticketsOnBehalfOfCustomers = ticketSnapshot.asModelData()?.ticketsOnBehalfOfCustomers ?? {};
          ticketsOnBehalfOfCustomers[customer.id] = ticketRes.data.id;
          await ticketSnapshot.modelRef.set({ ticketsOnBehalfOfCustomers });
        } else {
          await ticketSnapshot.modelRef.set({ ticketId: ticketRes.data.id });
        }
      } catch (e) {
        consoleErrorWithSentry(e);
      }

      setDisabledPerk(true);
      setRequestTicket(ticketRes.data);
      const successMessage: JSX.Element = (
        <Typography component="span" variant="body1">
          {`You successfully requested ${getSingularTypeText(perk.fields.type ?? "pro-serv")}, follow the ticket `}
          <Link
            component={RouterLink}
            variant="body1"
            color="inherit"
            to={`/customers/${customer.id}/support/tickets/${ticketRes.data.id}`}
            sx={{
              mr: "0.5ch",
            }}
          >{`#${ticketRes.data.id}`}</Link>
          here
        </Typography>
      );

      if (isProfessionalService(perk.fields.type)) {
        mixpanelServicesHandler(
          `nav.services.ps.${perk.fields.name.toLowerCase().replace(" ", "-")}.request-service.send-request`
        );
      }
      snackbar.onOpen({
        message: successMessage,
        variant: "success",
        autoHideDuration: 5000,
      });
    } catch (err: any) {
      snackbar.onOpen({
        message: err.message,
        variant: "error",
        autoHideDuration: 5000,
      });
    } finally {
      setRequestServiceDialogOpen(false);
      setProcessing(false);
    }
  }, [
    getPerkTags,
    isProfessionalService,
    perk,
    perksApi,
    customer.primaryDomain,
    customer.id,
    perkId,
    currentUser.email,
    accountManager?.email,
    isDoitEmployee,
    getCustomerTier,
    isIncluded,
    userId,
    setDisabledPerk,
    setRequestTicket,
    snackbar,
    mixpanelServicesHandler,
    setRequestServiceDialogOpen,
  ]);

  const registerInterestIsvSolutions = useCallback(
    async (interestFrom: RegisterInterestFromType) => {
      setProcessing(true);
      try {
        await perksApi.registerInterestEmail({
          perkName: perk?.fields.name ?? "",
          userEmail: currentUser.email,
          clickedOn: interestFrom,
        });
        setProcessing(false);
        if (interestFrom === RegisterInterestFromType.InterestButton) {
          setRegisterDialogOpen(true);
        }
      } catch (err: any) {
        snackbar.onOpen({
          message: err.message,
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    },
    [perksApi, perk?.fields.name, currentUser.email, setRegisterDialogOpen, snackbar]
  );

  const registerInterest = useCallback(
    async (interestFrom: RegisterInterestFromType) => {
      if (!perk?.fields.type || !perk?.fields.name) {
        return;
      }

      if (isProfessionalService(perk?.fields.type)) {
        mixpanelServicesHandler(`nav.services.ps.${perk.fields.name.toLowerCase().replace(" ", "-")}.request-service`);
        setRequestServiceDialogOpen(true);
      } else {
        await registerInterestIsvSolutions(interestFrom);
      }
    },
    [
      isProfessionalService,
      mixpanelServicesHandler,
      perk?.fields.name,
      perk?.fields.type,
      registerInterestIsvSolutions,
      setRequestServiceDialogOpen,
    ]
  );

  return { registerInterest, registerInterestProServ, processing };
};

/**
 * Builds mobile filter options based on grouped perks and the page ID.
 *
 * @param groupedPerks - The grouped-perks data.
 * @param pageId - The ID of the current page.
 * @returns buildMobileFilters function.
 */
export const useBuildMobileFilters = (groupedPerks: GroupedPerks, pageId: string) =>
  useCallback(() => {
    const filterOptions: PerkFilterMobileBasic[] = [];
    filterOptions.push(
      ...Object.keys(groupedPerks.type)
        .filter((key: string) => isPerkType(key))
        .map((key: PerkType) => ({
          filterGroupDisplayName: "Type",
          filterValue: key,
          filterNiceName: toBeautifiedName(key, groupedPerks.type[key].length),
        }))
    );
    filterOptions.push(
      ...Object.keys(groupedPerks.platform)
        .filter((key: string) => isPerkPlatform(key))
        .map((key: PerkPlatform) => ({
          filterGroupDisplayName: "Cloud Marketplace",
          filterValue: key,
          filterNiceName: toBeautifiedName(key, groupedPerks.platform[key].length),
        }))
    );

    if (pageId === "isv-solutions") {
      filterOptions.push(
        ...Object.keys(groupedPerks.platform)
          .filter((key: string) => isPerkPlatform(key))
          .map((key: PerkPlatform) => ({
            filterGroupDisplayName: "Cloud Marketplace",
            filterValue: key,
            filterNiceName: toBeautifiedName(key, groupedPerks.platform[key].length),
          }))
      );
      filterOptions.push(
        ...Object.keys(groupedPerks.solution)
          .filter((key: string) => isPerkSolutionType(key))
          .map((key: PerkSolutionType) => ({
            filterGroupDisplayName: "Solution types",
            filterValue: key,
            filterNiceName: toBeautifiedName(key, groupedPerks.solution[key].length),
          }))
      );
    }

    return filterOptions;
  }, [groupedPerks.platform, groupedPerks.solution, groupedPerks.type, pageId]);

export const useHandleCheckboxChange = ({
  platformFilter,
  setPlatformFilter,
  solutionFilter,
  setSolutionFilter,
  perkTypeFilter,
  setPerkTypeFilter,
}) =>
  useCallback(
    (checked: boolean, key: FilterablePerkType) => {
      if (isPerkPlatform(key)) {
        setPlatformFilter({
          ...platformFilter,
          [key]: checked,
        });
        return;
      }

      if (isPerkSolutionType(key)) {
        setSolutionFilter({
          ...solutionFilter,
          [key]: checked,
        });
      }

      if (isPerkType(key)) {
        setPerkTypeFilter({
          ...perkTypeFilter,
          [key]: checked,
        });
      }
    },
    [perkTypeFilter, platformFilter, setPerkTypeFilter, setPlatformFilter, setSolutionFilter, solutionFilter]
  );
