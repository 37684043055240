import { type ReactNode, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";

export type CollapsableTableRowProps = {
  /**
   * We are expecting table cells here, everything else would be weird
   */
  children: ReactNode;
  hiddenContent: ReactNode;
};

export const CollapsableTableRow = ({ children, hiddenContent }: CollapsableTableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // We are expecting the user to pass in table cells here - if they pass in multiple,
  // this will be an array, so we take the length and add 1 to account for the arrow cell
  // If this is not an array, the row has exactly 1 additional cell, so we need to span 2
  const colSpan = Array.isArray(children) ? children.length + 1 : 2;

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset !important" } }}>
        {/* Render an additional table cell with the arrow to collapse the row */}
        <TableCell>
          <IconButton
            size="small"
            onClick={() => {
              setIsExpanded((current) => !current);
            }}
          >
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {/* Then render the rest of the cells passed in as children */}
        {children}
      </TableRow>

      <TableRow>
        {/* If we have multiple cells passed in, this will be an array, and we*/}
        <TableCell colSpan={colSpan} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit={true}>
            {hiddenContent}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
