import { type Renderer } from "@doitintl/cmp-models";
import { Card, CardHeader, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ThemeModes } from "../../../muiThemeTypes";
import CloudReportCard from "../../Dashboard/Analytics/CloudReportCard";
import { type AvaWidgetData } from "../types";
import { AvaWidgetHeader } from "./AvaWidgetHeader";
import { WidgetViewSelect } from "./WidgetViewSelect";

const useStyles = makeStyles({
  hoverUnderline: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  clickableHeader: {
    "&:hover": {
      backgroundColor: ThemeModes.DARK ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.08)",
      cursor: "pointer",
    },
  },
});
type AvaWidgetCardProps = {
  widgetKey: string;
  onWidgetRendererChange: (rendererOption: Renderer, widgetKey: string) => void;
  widgetData?: AvaWidgetData;
  handleReportCreate: (widgetKey: string) => Promise<void>;
};

export const AvaWidgetCard = ({
  widgetKey,
  onWidgetRendererChange,
  widgetData,
  handleReportCreate,
}: AvaWidgetCardProps) => {
  const classes = useStyles();

  if (!widgetData) {
    return null;
  }

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          alignItems: "flex-end",
        }}
      >
        <Stack
          sx={{
            width: "40%",
          }}
        >
          <WidgetViewSelect
            widgetKey={widgetKey}
            currentWidget={widgetData.widget}
            currentWidgetIndex={widgetKey}
            onWidgetRendererChange={onWidgetRendererChange}
          />
        </Stack>
      </Stack>
      <Card>
        <CardHeader
          className={classes.clickableHeader}
          action={<AvaWidgetHeader widgetKey={widgetKey} handleReportCreate={handleReportCreate} />}
          title={
            <Typography className={classes.hoverUnderline} color="inherit">
              Open this Ava generated report in Cloud Analytics
            </Typography>
          }
          onClick={() => handleReportCreate(widgetKey)}
        />
        <CloudReportCard
          key={widgetKey}
          widgetId=""
          widgetHeight={widgetData.height ?? 400}
          reportWithData={widgetData.widget}
        />
      </Card>
    </>
  );
};
