import { useEffect, useState } from "react";

import { AppModel, type CloudConnectCategory } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../../Context/CustomerContext";

const EMPTY_COLLECTION = [];

export function usePermissionCategories() {
  const [permissionsByCategory, setPermissionsByCategory] = useState<CloudConnectCategory[]>(EMPTY_COLLECTION);
  const { customer } = useCustomerContext();

  useEffect(() => {
    (async () => {
      const cloudConnectSnap = await getCollection(AppModel).doc("cloud-connect").get();
      const data = cloudConnectSnap.asModelData();

      const categories = data?.categories ?? EMPTY_COLLECTION;

      // Some categories are feature flagged, filter them out
      const availableCategories = categories.filter(
        (category) => !category.featureFlag || customer.earlyAccessFeatures?.includes(category.featureFlag)
      );

      setPermissionsByCategory(availableCategories);
    })();
  }, [customer.earlyAccessFeatures]);

  return permissionsByCategory;
}
