import { type Cluster } from "@doitintl/cmp-models";

import { FilterTable, type FilterTableProps } from "../../../Components/FilterTable/FilterTable";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import Tabs, { type TabConfig } from "../../../Components/Tabs";
import { type HeaderColumn } from "../../../types/FilterTable";
import { useClusters } from "../hooks";
import ClusterTableRow from "./ClusterRow";
import { splitActiveAndInactiveClusters } from "./utils";

const getItemDescriptions = (isActiveClustersTable: boolean): FilterTableItemDescriptor<Cluster>[] => [
  {
    headerText: "Name",
    value: "name",
    filterType: "string",
  },
  {
    headerText: "Type",
    value: "type",
    filterType: "string",
  },
  {
    headerText: "Region",
    value: "region",
    filterType: "string",
  },
  {
    headerText: "Project ID",
    value: "projectId",
    filterType: "string",
  },
  {
    headerText: "Version",
    value: "kubernetesControlPlaneVersion",
    filterType: "string",
    hiddenByDefault: !isActiveClustersTable,
  },
  {
    headerText: "Status",
    value: "connectionStatus",
    filterType: "string",
    hiddenByDefault: !isActiveClustersTable,
  },
  {
    headerText: "Is activated",
    value: "isActivated",
    filterType: "boolean",
    hiddenByDefault: !isActiveClustersTable,
  },
];

const threeDotsHeaderItem: HeaderColumn = {
  value: "",
  disableSort: true,
};

const ClusterTable = () => {
  const [clusters] = useClusters();
  const { activeClusters, inactiveClusters } = splitActiveAndInactiveClusters(clusters);

  const getTableProps = (isActiveClustersTable: boolean): FilterTableProps<Cluster> => {
    const itemDescriptions = getItemDescriptions(isActiveClustersTable);
    const { headerColumns, filters } = generateHeadersAndFilters(itemDescriptions);
    const extendedHeaderColumns = [...headerColumns, threeDotsHeaderItem];

    return {
      rowComponent: ClusterTableRow,
      persistenceKey: isActiveClustersTable ? "active-clusters-table" : "inactive-clusters-table",
      toolbarProps: {
        allowToEditColumns: true,
      },
      tableItems: isActiveClustersTable ? activeClusters : inactiveClusters,
      headerColumns: extendedHeaderColumns,
      filterColumns: filters,
    };
  };

  const tabs: TabConfig[] = [
    {
      label: `Active clusters (${activeClusters.length})`,
      key: "active-clusters",
      content: <FilterTable<Cluster> {...getTableProps(true)} />,
    },
    {
      label: `Inactive clusters (${inactiveClusters.length})`,
      key: "inactive-clusters",
      content: <FilterTable<Cluster> {...getTableProps(false)} />,
    },
  ];

  return <Tabs tabs={tabs} />;
};
export default ClusterTable;
