import { useCallback, useState } from "react";

import { useApiContext } from "../../../../api/context";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import { updateTierEntitlements } from "../../db";

export const useUpdateTier = (tierId: string) => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const updateTier = useCallback(
    async (entitlements: string[]) => {
      try {
        setLoading(true);
        await updateTierEntitlements(api, tierId, entitlements);
        successSnackbar("Successfully updated tier entitlements");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Error updating tier entitlements");
      } finally {
        setLoading(false);
      }
    },
    [api, errorSnackbar, successSnackbar, tierId]
  );

  return [updateTier, loading] as const;
};
