import { type Dispatch, useEffect, useState } from "react";

import { Divider, Stack, Typography } from "@mui/material";

import { loginTexts } from "../../../assets/texts";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useUtmParams } from "../../../utils/utmParams/hooks";
import {
  type CompleteSignupProviderType,
  constructAuthProviderRoute,
  isCompleteSignupProvider,
  useEmailAndPasswordSchema,
} from "../helpers";
import { useCompleteSignupQueryParams, useHandleCompleteSignUp, useOnCompleteSignupError } from "../hooks";
import {
  type LoginHookAction,
  LoginHookActionType,
  type LoginState,
  type LoginType,
  SupportedAuthProviders,
} from "../types";
import AuthProviders from "./AuthProviders";
import Disclosure from "./Disclosure";
import EmailAndPasswordFormTemplate from "./EmailAndPasswordFormTemplate";
import SsoLoginButton from "./SsoLoginButton";

export const CompleteSignupForm = ({ state, dispatch }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const errSnackbar = useErrorSnackbar(15);
  const utmParams = useUtmParams();
  const [provider, setProvider] = useState<CompleteSignupProviderType | undefined>(undefined);
  const [focusOnPassword, setFocusOnPassword] = useState<boolean>(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState<boolean>(false);
  const handleCompleteSignUp = useHandleCompleteSignUp({ dispatch });

  const onCompleteSignupError = useOnCompleteSignupError(dispatch, errSnackbar);

  const loginTypeForOAuthRedirect: LoginType = "completeSignup";
  const EmailAndPasswordSchema = useEmailAndPasswordSchema();

  useEffect(() => {
    // if email is set and provider is email, focus on password for the first time
    if (focusOnPassword) {
      return;
    }
    if (state.email && provider === "email") {
      setFocusOnPassword(true);
    }
  }, [state.email, provider, focusOnPassword]);

  useCompleteSignupQueryParams({
    onEmail: (email: string) => {
      dispatch({ type: LoginHookActionType.setEmail, email });
      setIsEmailDisabled(true);
    },
    onError: onCompleteSignupError,
    onProvider: (provider) => {
      if (isCompleteSignupProvider(provider)) {
        setProvider(provider);
      }
      if (provider === "google" || provider === "microsoft") {
        const relativeRoute = constructAuthProviderRoute({
          provider: provider === "google" ? SupportedAuthProviders.Google : SupportedAuthProviders.Microsoft,
          utmParams,
          isCompleteSignup: true,
        });
        return new URL(relativeRoute, window.location.origin).toString();
      }
    },
    onCheckInbox: () => {
      dispatch({ type: LoginHookActionType.setFormType, formType: "checkEmailSuccess" });
    },
  });

  return (
    <>
      <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
        <AuthProviders loading={state.isLoading} type={loginTypeForOAuthRedirect} isCompleteSignup={true} />
        <SsoLoginButton
          disabled={state.isLoading}
          handleLoginSso={() => {
            dispatch({ type: LoginHookActionType.setFormType, formType: "sso" });
          }}
          loginType={loginTypeForOAuthRedirect}
        />
        <Divider>
          <Typography variant="caption" align="center">
            {loginTexts.CAPTION_SIGN_UP}
          </Typography>
        </Divider>
      </Stack>
      <EmailAndPasswordFormTemplate
        enableReinitialize
        email={state.email}
        password={state.password}
        focusOnPassword={focusOnPassword}
        isLoading={state.isLoading}
        validationSchema={EmailAndPasswordSchema}
        isSignup={state.formType === "completeSignup"}
        handleSignUp={handleCompleteSignUp}
        isEmailDisabled={isEmailDisabled}
      />
      <Stack spacing={2}>
        <Disclosure />
      </Stack>
    </>
  );
};
