import "./codemirror.css";

import { cloneElement, useContext, useEffect, useState } from "react";

import { type BQLensMetric } from "@doitintl/cmp-models";
import { Card, Stack, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import Highcharts from "highcharts";
import addAccessibilityModule from "highcharts/modules/accessibility";
import CustomEvents from "highcharts-custom-events";
import HighchartsReact from "highcharts-react-official";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { getTreeMapColors } from "../../../Pages/CloudAnalytics/utilities";
import { WidgetCardHeader } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { WidgetMenuWrapperContext } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/widgetMenuContext";
import { consoleErrorWithSentry } from "../../../utils";
import { formatCurrency, formatDecimalNumber } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import { SkeletonCard } from "../SkeletonCard";
import { type WidgetItemProps } from "../types";
import ChipMenu from "./ChipMenu";
import {
  availableUnitOptions as availableUnitOptionsKey,
  defaultMetricOptions,
  slots,
  timeframeOptions,
} from "./constants";
import { useRollsUpData } from "./hooks";
import QueryDialog from "./QueryDialog";
import { useBigQueryLensDashboardContext } from "./useBigQueryLensDashboardContext";
import { getFilteredUnits, getFilteredUnitsBasedOnMetricValue, makeBaseRollUpsQuery } from "./utils";

addAccessibilityModule(Highcharts);
CustomEvents(Highcharts);

type Series = { name: string; y: number; label: string };

export default function RollUpsCard({ raised, fallbackComponent, widgetHeight = 200 }: WidgetItemProps) {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const {
    selectedBillingMode,
    selectedMetric,
    selectedMeasurement,
    selectedTimeframe,
    setDashboardWidgetContextValue,
    setDashboardWidgetContextMultipleValues,
    availableUnitOptions,
  } = useBigQueryLensDashboardContext();

  const [_deleteWidgetOperation, threeDotsDeleteMenu] = useContext(WidgetMenuWrapperContext);

  const theme = useTheme();
  const [data, setData] = useState<any>();
  const [graphOptions, setGraphOptions] = useState<HighchartsWithCustomEvents.Options>();
  const [openQueryViewer, setOpenQueryViewer] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState<{ point: { point: Highcharts.Point } }>();
  const [selectedSeries, setSelectedSeries] = useState<Series[]>([]);
  const { fullScreen } = useFullScreen();
  const { availableMetrics } = useRollsUpData(selectedBillingMode);

  useEffect(
    () =>
      makeBaseRollUpsQuery({
        metricType: selectedMetric,
        customerId: customer.id,
        selectedTimeframe,
        measurement: selectedMeasurement,
        selectedBillingMode,
      }).onSnapshot((snapshot) => {
        const removeEmptyObjects = (obj: Record<string, any>) =>
          Object.keys(obj).reduce((acc, key) => {
            if (Object.keys(obj[key]).length > 0) {
              acc[key] = obj[key];
            }
            return acc;
          }, {});

        const data = snapshot.asModelData();

        const cleanData = removeEmptyObjects(data ?? {});

        if (!data || Object.keys(cleanData).length === 0) {
          setData(null);
          return;
        }

        const graphObj = {
          data: cleanData,
          ref: snapshot.ref,
          type: selectedMetric,
        };

        setData(graphObj);
      }),
    [customer.id, selectedBillingMode, selectedMeasurement, selectedMetric, selectedTimeframe]
  );

  useEffect(() => {
    if (availableUnitOptions.length > 0) {
      return;
    }

    const prepareUnitOptions = async () => {
      if (!customer.id) {
        return;
      }

      const filteredUnits = await getFilteredUnits({
        unitsToCheck: defaultMetricOptions,
        customerId: customer.id,
        metricType: selectedMetric,
        selectedTimeframe,
        selectedBillingMode,
      });

      setDashboardWidgetContextValue(availableUnitOptionsKey, filteredUnits);
    };
    prepareUnitOptions().catch(consoleErrorWithSentry);
  }, [
    customer,
    availableUnitOptions,
    selectedBillingMode,
    selectedMetric,
    setDashboardWidgetContextValue,
    selectedTimeframe,
  ]);

  useEffect(() => {
    const graphData = data?.data;

    if (!graphData) {
      return;
    }

    const seriesData: Series[] = [];

    Object.keys(graphData ?? {}).forEach((row) => {
      const obj = {
        name: row,
        y: graphData[row][selectedMeasurement],
        label: graphData[row],
      };
      seriesData.push(obj);
    });

    // do not re-render chart if data is not yet available
    if (seriesData[0]?.label[selectedMeasurement] === undefined) {
      return;
    }

    seriesData.sort((a, b) => {
      const keyA = a.y;
      const keyB = b.y;
      if (keyA < keyB) {
        return 1;
      }
      if (keyA > keyB) {
        return -1;
      }
      return 0;
    });

    const formattedData = (point) => {
      if (selectedMeasurement.includes("TB")) {
        return `${formatDecimalNumber(point.y, 2)} TB`;
      }

      if (selectedMeasurement === slots) {
        return formatDecimalNumber(point.y, 2);
      }

      return formatCurrency(point.y, "USD");
    };

    setGraphOptions({
      chart: {
        type: "bar",
        height: widgetHeight === 1 ? 180 : 280,
        borderColor: getTreeMapColors(theme.palette.mode)[0],
        borderWidth: 0,
        backgroundColor: "transparent",
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      xAxis: {
        type: "category",
        labels: {
          useHTML: true,
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
            width: 150,
            whiteSpace: "nowrap",
            textDecoration: "underline",
            cursor: "pointer",
            color: theme.palette.text.primary,
          },
          formatter() {
            return `<div style="width:150px;overflow:hidden;text-overflow: ellipsis;">${this.value}</div>`;
          },
          events: {
            click() {
              setSelectedPoint({
                point: { point: this.chart.series[0].data[this.pos] },
              });
              setOpenQueryViewer(true);
            },
          },
        },
      },
      yAxis: {
        gridLineColor: theme.palette.general.divider,
        min: 0,
        title: {
          text: null,
        },
      },
      accessibility: {
        keyboardNavigation: {
          focusBorder: {
            style: {
              lineWidth: 0,
            },
          },
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            formatter() {
              return formattedData(this);
            },
            style: {
              color: theme.palette.text.primary,
              textOutline: "0px contrast",
            },
          },
        },
        series: {
          cursor: "pointer",
          point: {
            events: {
              click(point) {
                setSelectedPoint({ point });
                setOpenQueryViewer(true);
              },
            },
          },
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        outside: true,
        hideDelay: 50,
        formatter() {
          return `<span>${this.key}<br/>${formattedData(this)}</span>`;
        },
      },
      series: [
        {
          name: "",
          data: seriesData,
          color: getTreeMapColors(theme.palette.mode)[0],
          borderColor: getTreeMapColors(theme.palette.mode)[0],
          type: "bar",
        },
      ],
    });

    setSelectedSeries(seriesData);
  }, [data, theme, widgetHeight, selectedMetric, selectedMeasurement]);

  const subheader = timeframeOptions.find((item) => item.value === selectedTimeframe)?.label;
  const title = "Explorer";

  if (data === null) {
    if (!fallbackComponent) {
      return null;
    }
    return cloneElement(fallbackComponent, { title, subheader, noData: true });
  }

  if (!graphOptions) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Grid>
      <Card raised={raised}>
        <WidgetCardHeader
          title={title}
          subheader={subheader}
          action={
            graphOptions && (
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  mt: 0.5,
                }}
              >
                <ChipMenu
                  items={availableMetrics}
                  onSelected={async (newMetricValue: BQLensMetric) => {
                    const filteredUnits = await getFilteredUnitsBasedOnMetricValue({
                      metricType: newMetricValue,
                      selectedBillingMode,
                      customerId: customer.id,
                      selectedTimeframe,
                    });

                    if (filteredUnits.length === 0) {
                      throw new Error(`No units available for this metric. ${newMetricValue} ${selectedBillingMode}`);
                    }

                    const newMeasurementValue = filteredUnits.includes(selectedMeasurement)
                      ? selectedMeasurement
                      : filteredUnits[0];

                    setDashboardWidgetContextMultipleValues({
                      selectedMetric: newMetricValue,
                      selectedMeasurement: newMeasurementValue,
                      availableUnitOptions: filteredUnits,
                    });
                  }}
                  chipIndex={0}
                  filters={[selectedMetric, selectedMeasurement]}
                />

                <Typography style={{ width: fullScreen ? 5 : 76, textAlign: "center" }} variant="caption">
                  {fullScreen ? "" : "Metric"}
                </Typography>

                <ChipMenu
                  items={availableUnitOptions.length > 0 ? availableUnitOptions : defaultMetricOptions}
                  onSelected={(newMeasurementValue) => {
                    setDashboardWidgetContextValue("selectedMeasurement", newMeasurementValue);
                  }}
                  chipIndex={2}
                  filters={[selectedMetric, selectedMeasurement]}
                />
                {threeDotsDeleteMenu}
              </Stack>
            )
          }
        />

        <CardContent sx={{ height: widgetHeight }}>
          <div style={{ position: "relative" }}>
            {graphOptions && <HighchartsReact allowChartUpdate={true} highcharts={Highcharts} options={graphOptions} />}
          </div>
        </CardContent>
      </Card>
      <QueryDialog
        selectedSeries={selectedSeries}
        point={selectedPoint}
        onClose={() => {
          setSelectedPoint(undefined);
          setOpenQueryViewer(false);
        }}
        open={openQueryViewer}
      />
    </Grid>
  );
}
