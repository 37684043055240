import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { AvaOpeningSource, useAvaDialogContext } from "@doitintl/ava-components/src/Common/AvaDialogContextProvider";
import { DashboardType } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { useApiContext } from "../../api/context";
import { dashboardsTxt } from "../../assets/texts/Customer/dashboards";
import ProcessingState from "../../Components/Dashboard/Common/ProcessingState/ProcessingState";
import { AddDashboardType, CreateDashboardDialog } from "../../Components/Dashboard/CreateDashboardDialog";
import { DashboardGuard } from "../../Components/Dashboard/DashboardGuard";
import { isHomeDashboard, useDashboardNavigation } from "../../Components/Dashboard/DashboardNavigartionContext";
import { Loader } from "../../Components/Dashboard/Loader";
import {
  areAllWidgetsEmpty,
  excludeWidgetsByName,
  filterWidgetsByName,
  getDashboardType,
  isDashboardLoading,
} from "../../Components/Dashboard/utils/widgetUtils";
import { WidgetsList } from "../../Components/Dashboard/WidgetsList";
import DeleteDialog from "../../Components/DeleteDialog";
import { DoitConsoleTitle } from "../../Components/DoitConsoleTitle";
import { useDashboardsMetadata } from "../../Components/hooks/cloudAnalytics/useDashboardsMetadata";
import { useSessionStorage } from "../../Components/hooks/storageHooks";
import { useDashboardWidgetDataRefresh } from "../../Components/hooks/useDashboardWidgetDataRefresh";
import NoEntitlement from "../../Components/NoEntitlement/NoEntitlement";
import { useInfoSnackbar, useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../Components/ThreeDotsMenu";
import { isProduction } from "../../constants";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useDashboardProcessingContext } from "../../Context/DashboardProcessingContext";
import { useIsFeatureEntitled, useTier } from "../../Context/TierProvider";
import { useCloudReportsWidgets } from "../../Context/useCloudReportsWidgets";
import { useCurrentDashboardContext, type Widget } from "../../Context/useCurrentDashboardContext";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { useDashboardsContext } from "../../Context/useDashboardsContext";
import { useUserContext } from "../../Context/UserContext";
import PhoneDialog from "../../Support/Components/PhoneDialog";
import { consoleErrorWithSentry } from "../../utils";
import { getUserOrgID } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import mixpanel from "../../utils/mixpanel";
import { getWidgetId } from "../../utils/widgets";
import { useFormatLink } from "../CloudAnalytics/analyticsResources/hooks";
import { useNewReportHandler } from "../CloudAnalytics/report/hooks";
import DashboardEmptyState from "./DashboardEmptyState";
import { DashboardHeader } from "./DashboardHeader";
import { updateVisibleDashboards } from "./db";
import { DashboardLayout } from "./NewDashboards/DashboardLayout";
import { DashboardSideMenu } from "./NewDashboards/DashboardSideMenu";
import { AddReportDialog } from "./NewDashboards/Dialogs/AddReport/AddReportDialog";
import { EmptyDashboard } from "./NewDashboards/EmptyDashboard";
import { type DashboardOperations } from "./NewDashboards/useDashboardTypes";
import { WidgetsGridPageWrapper } from "./NewDashboards/WidgetsGrid/WidgetsGridPageWrapper";
import DataImportBanner from "./SaaSConsoleOnboarding/DataImportBanner";

const BQLensDashboardName = "BQ Lens";

export const CustomerDashboards = () => {
  const api = useApiContext();
  const { partnerCompany, isDoitPartner, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const isDoitPartnerOrDoitEmployee = (isDoitPartner && !!partnerCompany) || isDoitEmployee;
  const {
    entities,
    entitiesLoading,
    customer: originalCustomer,
    customerOrPresentationModeCustomer: customer,
    userOrganization,
    isProductOnlyCustomer,
  } = useCustomerContext();
  const { getFeatureName } = useTier();
  const { userRoles } = useUserContext();
  const { isMobile } = useFullScreen();

  const isEntitledDashboardCustom = useIsFeatureEntitled("dashboard:custom");
  const isEntitledAvaIcon = useIsFeatureEntitled("platform:ava:icon");

  // dashboard hooks
  const { dashboards, defaultDashboard, setDefaultDashboard, deleteDashboardByName, detachDashboardByName } =
    useDashboardsContext();
  const { historyGotoDashboard } = useDashboardNavigation();
  const { changeDashboardByName, currentDashboard, isCustomDashboard } = useCurrentDashboardContext();

  const widgets = useMemo(() => currentDashboard?.widgets ?? [], [currentDashboard?.widgets]);

  const { dashboardWidgetStates, setRefreshTriggerTime } = useDashboardProcessingContext();
  const [handleDashboardMetadataUpdate] = useDashboardsMetadata();
  const [currentDashboardOperations, setCurrentDashboardOperations] = useState<DashboardOperations>({});

  // widgets hooks
  const [cloudReportsWidgets, loadingCloudReportWidgets] = useCloudReportsWidgets();

  // inner state
  const [widgetLibraryOpen, setWidgetLibraryOpen] = useState(false);
  const [addDashboardType, setAddDashboardType] = useState<AddDashboardType>();
  const { name } = useParams<{ name: string }>();
  const [lastOpenedDashboard, setLastOpenedDashboard] = useSessionStorage<string | number | undefined>(
    `last_opened_dashboard`,
    undefined
  );
  const [isPhoneOpen, setIsPhoneOpen] = useState(false);
  const [isCreateDashboardDialogOpen, setIsCreateDashboardDialogOpen] = useState(false);
  const [hasCloudReportWidgets, setHasCloudReportWidgets] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddReportDialog, setOpenAddReportDialog] = useState(false);

  // components
  const sharedSnackbar = useSnackbar();
  const showInfoSnackbar = useInfoSnackbar();

  const { handleOpenAvaDialog } = useAvaDialogContext();
  const reportLink = useFormatLink({ type: "report" });
  const handleNewReport = useNewReportHandler({
    baseUrl: reportLink,
    mixpanelEventName: "analytics.reports.new",
    additionalState: {
      dashboardId: currentDashboard?.id,
      dashboardName: currentDashboard?.name,
    },
  });

  const emptyDashboard = widgets.length === 0;

  const notAllowedToAddReport = !isEntitledDashboardCustom || (!userRoles?.cloudAnalytics && !isDoitEmployee);

  const hasAccessToAva = isDoitEmployee || !!isEntitledAvaIcon;

  const updateUserDefaultDashboard = useCallback(async () => {
    if (!currentDashboard) {
      return;
    }

    mixpanel.track("dashboards.set-as-default");
    await setDefaultDashboard(currentDashboard.id);
    sharedSnackbar.onOpen({
      message: `'${isHomeDashboard(currentDashboard.name) ? "Account" : currentDashboard.name}' is set as your default dashboard`,
      variant: "success",
      autoHideDuration: 4000,
      action: [
        <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
          <CloseIcon />
        </IconButton>,
      ],
    });
  }, [currentDashboard, setDefaultDashboard, sharedSnackbar]);

  const disableDashboardCreation = Boolean(
    (userOrganization?.data.disableAccountDashboard && userOrganization.data.dashboards.length === 0) ||
      !isEntitledDashboardCustom
  );
  const shouldRefreshDashboardWidgets = useDashboardWidgetDataRefresh(
    customer.id,
    getUserOrgID(isDoitEmployee, userOrganization),
    currentDashboard?.id ?? ""
  );

  const isPresetDashboard = Boolean(currentDashboard?.dashboardType);

  useEffect(() => {
    if (isProduction && isDoitPartnerOrDoitEmployee && currentDashboard?.ref?.path && shouldRefreshDashboardWidgets) {
      updateVisibleDashboards(api, customer.id, [currentDashboard.ref.path]);
    }
  }, [currentDashboard, api, customer, isDoitPartnerOrDoitEmployee, shouldRefreshDashboardWidgets]);
  /**
   * Retrieves the list of widgets to be rendered based on the current dashboard and cloud report states.
   */
  const [widgetsToRender, noCloudReportsData] = useMemo((): [Widget[], boolean] => {
    if (!currentDashboard || !isPresetDashboard) {
      return [widgets, false];
    }
    const dashboardType = getDashboardType(name);

    // Filter widgets related to cloud reports
    const cloudReportWidgets = filterWidgetsByName(widgets, "cloudReports");
    const widgetStates = dashboardWidgetStates[currentDashboard.name] || {};

    // Check if all cloud reports are empty
    const areCloudReportsEmpty = areAllWidgetsEmpty(widgetStates);

    // Remove fixed widgets if no cloud report is available
    if (widgets.length > 0 && areCloudReportsEmpty) {
      if (dashboardType === DashboardType.AwsLens || dashboardType === DashboardType.SaaSAwsLens) {
        return [excludeWidgetsByName(widgets, "flexSaveAws"), areCloudReportsEmpty];
      }
    }

    // Continue using the original widget array until all cloud reports are processed
    if (widgets.length > 0 && Object.keys(widgetStates).length !== cloudReportWidgets.length) {
      return [widgets, areCloudReportsEmpty];
    }

    // Filter cloud report widgets based on the availability of data
    const filteredWidgets = widgets.filter((item) => {
      if (item.name.includes("cloudReports")) {
        const widgetId = getWidgetId(item);

        return widgetStates[widgetId]?.hasData && item?.state !== "failed";
      }
      return true;
    });

    return [filteredWidgets, areCloudReportsEmpty];
  }, [currentDashboard, dashboardWidgetStates, name, widgets, isPresetDashboard]);

  const changeDashboardByNameAndUI = useCallback(
    (name: string | number) => {
      setLastOpenedDashboard(name);
      historyGotoDashboard(name);
    },
    [historyGotoDashboard, setLastOpenedDashboard]
  );

  const handleDashboardDelete = async () => {
    if (!currentDashboard) {
      return;
    }
    changeDashboardByNameAndUI(0);
    await deleteDashboardByName(currentDashboard.id);
    setOpenDeleteDialog(false);
  };

  const handleCreateReportUsingAva = useCallback(() => {
    handleOpenAvaDialog(AvaOpeningSource.DASHBOARDS, undefined, currentDashboard?.id);
  }, [currentDashboard?.id, handleOpenAvaDialog]);

  const changeDashboardByNameAndUIRef = useRef(changeDashboardByNameAndUI);

  useEffect(() => {
    changeDashboardByNameAndUIRef.current = changeDashboardByNameAndUI;
  });

  useEffect(() => {
    if (!defaultDashboard) {
      return;
    }

    if (!name) {
      changeDashboardByNameAndUI("");
    }
  }, [name, defaultDashboard, changeDashboardByNameAndUI]);

  useEffect(() => {
    if (!name && lastOpenedDashboard) {
      changeDashboardByNameAndUI(lastOpenedDashboard);
    }

    if (!name || !defaultDashboard) {
      return;
    }

    if (name.localeCompare("default", undefined, { sensitivity: "base" }) === 0) {
      changeDashboardByNameAndUI(defaultDashboard ?? "Account");
      return;
    }

    changeDashboardByName?.(name).catch(consoleErrorWithSentry);
  }, [name, changeDashboardByName, changeDashboardByNameAndUI, defaultDashboard, lastOpenedDashboard]);

  const optionsForDashboard = useMemo(() => {
    const isAccountDashboard = currentDashboard?.name === "Account";
    const options: ThreeDotsMenuOption[] = [
      {
        key: "edit-dashboard-settings",
        label: "Edit dashboard settings",
        action: () => {
          mixpanel.track("dashboards.settings");
          setAddDashboardType(AddDashboardType.Edit);
          setIsCreateDashboardDialogOpen(true);
        },
        disabled: !currentDashboardOperations.allowToEdit || isAccountDashboard,
      },
      {
        key: "set-default-dashboard",
        label: "Set as default",
        action: updateUserDefaultDashboard,
        disabled: defaultDashboard === currentDashboard?.name,
      },
    ];

    options.push({
      key: "refresh-all",
      label: "Refresh all widgets",
      action: async () => {
        setRefreshTriggerTime();
      },
    });

    if (!isAccountDashboard && currentDashboardOperations.allowToDelete) {
      options.push({
        key: "delete-dashboard",
        label: "Delete dashboard",
        action: () => {
          setOpenDeleteDialog(true);
        },
        color: "error.main",
      });
    }

    if (!isAccountDashboard && currentDashboardOperations.allowToRemove && currentDashboard?.id) {
      options.push({
        key: "remove-dashboard",
        label: "Remove dashboard",
        action: async () => {
          changeDashboardByNameAndUI(0);
          await detachDashboardByName(currentDashboard.id);
        },
      });
    }

    return options;
  }, [
    changeDashboardByNameAndUI,
    currentDashboard?.id,
    currentDashboard?.name,
    currentDashboardOperations.allowToDelete,
    currentDashboardOperations.allowToEdit,
    currentDashboardOperations.allowToRemove,
    defaultDashboard,
    detachDashboardByName,
    setRefreshTriggerTime,
    updateUserDefaultDashboard,
  ]);

  const processedWidgets = useMemo(
    () => (!currentDashboard ? {} : (dashboardWidgetStates?.[currentDashboard.name] ?? {})),
    [currentDashboard, dashboardWidgetStates]
  );

  const showNoEntitlement = useMemo(
    () =>
      isCustomDashboard &&
      !isEntitledDashboardCustom &&
      !(isCustomerInPresentationMode(originalCustomer) && getDashboardType(name) === BQLensDashboardName),
    [isCustomDashboard, isEntitledDashboardCustom, name, originalCustomer]
  );

  const loading = isDashboardLoading(widgets, name, processedWidgets);

  const [updatedTimeLastAccessed, setUpdatedTimeLastAccessed] = useState<{ [key: string]: boolean }>({});

  const currentDashboardId = currentDashboard ? currentDashboard.id : "";

  useEffect(() => {
    if (isProduction && hasCloudReportWidgets && isDoitPartnerOrDoitEmployee && shouldRefreshDashboardWidgets) {
      showInfoSnackbar(dashboardsTxt.DASHBOARDS_ARE_BEING_UPDATED);
    }
  }, [
    hasCloudReportWidgets,
    showInfoSnackbar,
    isDoitPartnerOrDoitEmployee,
    currentDashboard?.name,
    shouldRefreshDashboardWidgets,
  ]);

  useEffect(() => {
    if (currentDashboardId !== "" && !updatedTimeLastAccessed[currentDashboardId] && !loading) {
      const cloudReportsWidgets = filterWidgetsByName(widgetsToRender, "cloudReports");

      const cloudReportsWidgetsWithData = cloudReportsWidgets.filter((item) => {
        const widgetId = getWidgetId(item);
        return processedWidgets[widgetId];
      });

      if (cloudReportsWidgetsWithData.length > 0) {
        setHasCloudReportWidgets(true);
        const orgId = getUserOrgID(isDoitEmployee, userOrganization);

        handleDashboardMetadataUpdate(customer.id, orgId, currentDashboardId);

        setUpdatedTimeLastAccessed((prevState) => ({
          ...prevState,
          [currentDashboardId]: true,
        }));
      }
    }
  }, [
    currentDashboardId,
    customer.id,
    handleDashboardMetadataUpdate,
    isDoitEmployee,
    loading,
    processedWidgets,
    updatedTimeLastAccessed,
    userOrganization,
    widgetsToRender,
  ]);

  if (dashboards.length === 0 || !currentDashboard?.id) {
    return <Loader />;
  }

  if (
    !isCustomerInPresentationMode(originalCustomer) &&
    !isProductOnlyCustomer &&
    !entitiesLoading &&
    (entities.length === 0 || !entities.some((x) => x.active))
  ) {
    return <DashboardEmptyState />;
  }

  const splitButtonOptions = [
    {
      label: "Add existing report",
      action: () => {
        setOpenAddReportDialog(true);
      },
      disabled: notAllowedToAddReport,
    },
    {
      label: "Add widget",
      action: () => {
        setWidgetLibraryOpen(true);
      },
    },
    {
      label: "Create a new report",
      action: handleNewReport,
      disabled: notAllowedToAddReport,
    },
    ...(hasAccessToAva
      ? [
          {
            label: "Create a report using Ava",
            action: handleCreateReportUsingAva,
            disabled: notAllowedToAddReport,
          },
        ]
      : []),
  ].sort((a) => (a.disabled ? 1 : -1));

  // -2 restore the padding that the header will start from zero
  return (
    <Box
      sx={{
        mx: -2,
        mt: -1,
      }}
    >
      <DoitConsoleTitle pageName="Dashboard" pageLevel1={currentDashboard.name} />
      <Stack spacing={1}>
        <DashboardLayout
          menu={
            <DashboardSideMenu
              selectedDashboardId={currentDashboardId}
              onNavigateToDashboard={changeDashboardByNameAndUI}
              disableNewDashboardCreation={disableDashboardCreation}
              onUpdateCurrentDashboardOperations={setCurrentDashboardOperations}
              onNewDashboardClicked={() => {
                setAddDashboardType(AddDashboardType.Create);
                setIsCreateDashboardDialogOpen(true);
              }}
            />
          }
          operations={<ThreeDotsMenu closeAfterSelect options={optionsForDashboard} />}
        >
          <Box>
            {loading ? (
              <Grid
                sx={{
                  mb: 1,
                  px: 2,
                }}
                size={12}
              >
                <ProcessingState dashboardType={getDashboardType(name)} />
              </Grid>
            ) : (
              <Grid
                container
                spacing={1}
                columnSpacing={0}
                rowSpacing={0}
                sx={{
                  ml: "-0.5rem",
                  mt: "-0.5rem",
                  pl: {
                    xs: 2,
                    sm: 1,
                  },

                  pr: {
                    xs: 1,
                    sm: 0,
                  },
                }}
              >
                <Suspense>
                  <DashboardGuard>
                    <>
                      {!isMobile && (
                        <DashboardHeader
                          threeDotsMenuOptions={optionsForDashboard}
                          splitButtonOptions={splitButtonOptions}
                          allowToAddWidgets={!!currentDashboardOperations.allowToAddWidgets}
                        />
                      )}

                      <DataImportBanner dashboardType={getDashboardType(name)} />

                      {showNoEntitlement && (
                        <NoEntitlement
                          feature={getFeatureName("dashboard:custom") ?? "this feature"}
                          inPresentationMode={originalCustomer.presentationMode?.enabled}
                        />
                      )}

                      {!showNoEntitlement && emptyDashboard && (
                        <EmptyDashboard
                          onAddExistingReportClicked={() => {
                            setOpenAddReportDialog(true);
                          }}
                          onNewReportClicked={handleNewReport}
                          onNewWidgetClicked={() => {
                            setWidgetLibraryOpen(true);
                          }}
                          onAvaClicked={handleCreateReportUsingAva}
                          showActions={Boolean(currentDashboardOperations?.allowToAddWidgets)}
                          showAva={hasAccessToAva}
                        />
                      )}

                      {!showNoEntitlement && !emptyDashboard && (
                        <WidgetsGridPageWrapper
                          showSummaryCards={
                            currentDashboard.dashboardType === DashboardType.Pulse && !noCloudReportsData
                          }
                          disableAutoRefreshing={isPresetDashboard}
                          widgets={widgetsToRender}
                        />
                      )}
                    </>
                  </DashboardGuard>
                </Suspense>
              </Grid>
            )}
          </Box>
        </DashboardLayout>
      </Stack>
      {!isDoitPartner && (
        <>
          {isPhoneOpen && (
            <PhoneDialog
              onClose={() => {
                setIsPhoneOpen(false);
              }}
            />
          )}
        </>
      )}
      {isCreateDashboardDialogOpen && addDashboardType && (
        <CreateDashboardDialog
          type={addDashboardType}
          selectedDashboardId={currentDashboardId}
          handleClose={(name) => {
            setIsCreateDashboardDialogOpen(false);
            if (name) {
              historyGotoDashboard(name);
            }
          }}
        />
      )}
      <DeleteDialog
        open={openDeleteDialog}
        title="Delete dashboard?"
        message="Are you sure you want to delete the dashboard?"
        onDelete={handleDashboardDelete}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        deleteButtonText="Delete dashboard"
      />
      {!notAllowedToAddReport && openAddReportDialog && (
        <AddReportDialog
          open={openAddReportDialog}
          loading={loadingCloudReportWidgets}
          availableWidgets={cloudReportsWidgets}
          selectedWidgetNames={widgetsToRender.map((w) => w.name)}
          onClose={() => {
            setOpenAddReportDialog(false);
          }}
        />
      )}
      {widgetLibraryOpen && (
        <WidgetsList
          onClose={() => {
            setWidgetLibraryOpen(false);
          }}
        />
      )}
    </Box>
  );
};
