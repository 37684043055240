import { memo, type ReactNode } from "react";

import { type SxProps, TableCell as MuiTableCell, type Theme } from "@mui/material";

const getForecastCellBackgroundColor = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: theme.palette.mode === "light" ? "#FAFAFA" : "#2D2D39",
});

const getTotalsBackgroundColor = (theme: Theme) => (theme.palette.mode === "light" ? "#D8DAEC" : "#4E4F61");

const getSubtotalsBackgroundColor = (theme: Theme) => (theme.palette.mode === "light" ? "#E1E3F3" : "#3B3C4A");

const getSubtotalsBorderColor = (theme: Theme) =>
  theme.palette.mode === "light"
    ? `solid ${theme.typography.pxToRem(2)} #898EBD`
    : `solid ${theme.typography.pxToRem(2)} #A5A4A6`;

const getTotalsTableHeadStyles = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: getTotalsBackgroundColor(theme),
  fontWeight: 500,
});

const getTotalsTableSumBottomStyles = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: getTotalsBackgroundColor(theme),
  fontWeight: 700,
});

const getTotalsTableSumRightStyles = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: getTotalsBackgroundColor(theme),
  fontWeight: 500,
});

const getSubtotalsHeadTableCellStyles = (theme: Theme): SxProps<Theme> => ({
  borderBottom: getSubtotalsBorderColor(theme),
  backgroundColor: getSubtotalsBackgroundColor(theme),
  fontWeight: 500,
});

const getSubtotalsSumTableCellStyles = (theme: Theme): SxProps<Theme> => ({
  borderBottom: getSubtotalsBorderColor(theme),
  backgroundColor: getSubtotalsBackgroundColor(theme),
  fontWeight: 700,
});

const getSubtotalsTableCellStyles = (theme: Theme): SxProps<Theme> => ({
  borderBottom: getSubtotalsBorderColor(theme),
  fontWeight: 500,
});

const getTableCellStyles = (theme: Theme): SxProps<Theme> => ({
  borderRight: `solid ${theme.typography.pxToRem(1)} ${theme.palette.general.divider}`,
  borderBottom: `solid ${theme.typography.pxToRem(1)} ${theme.palette.general.divider}`,
  borderCollapse: "separate",
  whiteSpace: "nowrap",
  fontSize: theme.typography.pxToRem(12),
  padding: theme.spacing(0.25, 0.5),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.default,
});

const getTableHeadCellStyles = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: theme.palette.general.cellBackground,
  fontWeight: 500,
});

const getTableSpacerCellStyles = (theme: Theme): SxProps<Theme> => ({
  width: theme.spacing(5),
});

const getMuiTableCellStyles =
  (variant?: string, totalVariant?: string, spacer?: boolean, sx?: SxProps<Theme>) => (theme: Theme) => {
    let variantStyle: SxProps<Theme>;
    switch (totalVariant) {
      case "totalHeadCell":
        variantStyle = getTotalsTableHeadStyles(theme);
        break;
      case "totalSumBottomCell":
        variantStyle = getTotalsTableSumBottomStyles(theme);
        break;
      case "totalSumRightCell":
        variantStyle = getTotalsTableSumRightStyles(theme);
        break;
      case "subtotalHeadCell":
        variantStyle = getSubtotalsHeadTableCellStyles(theme);
        break;
      case "subtotalSumCell":
        variantStyle = getSubtotalsSumTableCellStyles(theme);
        break;
      case "subtotalCell":
        variantStyle = getSubtotalsTableCellStyles(theme);
        break;
      case "forecastCell":
        variantStyle = getForecastCellBackgroundColor(theme);
        break;
      default:
        variantStyle = {};
    }

    const headStyles = variant === "head" ? getTableHeadCellStyles(theme) : {};
    const spacerStyles = spacer ? getTableSpacerCellStyles(theme) : {};

    return {
      ...getTableCellStyles(theme),
      ...spacerStyles,
      ...headStyles,
      ...variantStyle,
      ...sx,
    };
  };

type TableCellProps = {
  align?: "left" | "center" | "right";
  children?: ReactNode;
  colSpan?: number;
  rowSpan?: number;
  spacer?: boolean;
  sx?: SxProps<Theme>;
  variant?: "head";
  totalVariant?:
    | "totalHeadCell"
    | "totalSumBottomCell"
    | "totalSumRightCell"
    | "subtotalCell"
    | "subtotalHeadCell"
    | "subtotalSumCell"
    | "forecastCell";
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: () => void;
  ariaOwns?: string;
};

export const CustomTableCell = memo((props: TableCellProps) => {
  const { children, spacer, sx, variant, totalVariant, onMouseEnter, onMouseLeave, ariaOwns, ...other } = props;

  return (
    <MuiTableCell
      align="center"
      sx={getMuiTableCellStyles(variant, totalVariant, spacer, sx)}
      {...other}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-owns={ariaOwns}
      aria-haspopup={true}
    >
      {children}
    </MuiTableCell>
  );
});

CustomTableCell.displayName = "CustomTableCell";
