import { type JSX } from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import Skeleton from "@mui/material/Skeleton";

import { CardsSkeleton } from "../../Common/CardsSkeleton";
import { AsgsConfigurationTitles } from "./AsgsConfigurationTitles";
import { DEFAULT_HEIGHT } from "./consts";

export const AsgsSkeleton = () => {
  const commonHeighs = { current: DEFAULT_HEIGHT, recommended: DEFAULT_HEIGHT };
  const list: JSX.Element[] = [];
  for (let i = 0; i < 10; ++i) {
    list.push(
      <ListItem key={i} style={{ height: 36 }}>
        <Skeleton variant="rectangular" width={250} height={20} />
      </ListItem>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <CardsSkeleton />
      <Box
        sx={{
          display: "flex",
        }}
      >
        <AsgsConfigurationTitles instanceTypeHeights={commonHeighs} azHeights={commonHeighs} />
        <List dense subheader={<ListSubheader component="div">Current Values</ListSubheader>}>
          {list}
        </List>
      </Box>
    </Box>
  );
};
