import { isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import { ModelType, type ReferencedNodeValue, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";

import { type NodeWitOutputModel, useReferencedFieldContext } from "./useReferencedFieldContext";

type ReferenceFieldMenuItemData = {
  name: string;
  caption?: string;
  hasContinuation: boolean;
  disabled?: boolean;
  selected?: boolean;
};

type NodeMenuItemData = ReferenceFieldMenuItemData & {
  type: "node";
  nodeId: string;
  disabled: boolean;
};

type TokenMenuItemData = ReferenceFieldMenuItemData & {
  type: "token";
};

export type MenuItemData = NodeMenuItemData | TokenMenuItemData;

export function hasOtherReferencedFields(
  values: unknown,
  fieldPath: string,
  referenceableNodes: NodeWitOutputModel[],
  currentFieldPath = ""
): [boolean, string] {
  let referencedNodeId = "";
  let foundOther = false;
  switch (true) {
    case isArray(values): {
      values.some((paramValue, idx) => {
        [foundOther, referencedNodeId] = hasOtherReferencedFields(
          paramValue,
          fieldPath,
          referenceableNodes,
          `${currentFieldPath}[${idx}]`
        );
        return foundOther;
      });
      return [foundOther, referencedNodeId];
    }

    case isObject(values): {
      if (isReferencedNodeValue(values) && referenceableNodes.find(({ id }) => values.referencedNodeId === id)) {
        return [currentFieldPath !== fieldPath, values.referencedNodeId];
      }
      Object.entries(values).some(([paramName, paramValue]) => {
        [foundOther, referencedNodeId] = hasOtherReferencedFields(
          paramValue,
          fieldPath,
          referenceableNodes,
          `${currentFieldPath}.${paramName}`
        );
        return foundOther;
      });
      return [foundOther, referencedNodeId];
    }
  }

  return [foundOther, referencedNodeId];
}

export function useReferencedFieldMenuItems(fieldValue: ReferencedNodeValue | unknown, fieldPath: string) {
  const { referenceableNodes, values } = useReferencedFieldContext();

  let currentlyDisplayedPath: string[] = [];
  let menuItems: MenuItemData[] = [];
  if (isReferencedNodeValue(fieldValue) && referenceableNodes.find(({ id }) => fieldValue.referencedNodeId === id)) {
    const referencedNode = referenceableNodes.find(({ id }) => id === fieldValue.referencedNodeId);
    if (referencedNode) {
      menuItems = getNextTokens(referencedNode.outputModel, fieldValue.referencedField);
      currentlyDisplayedPath = fieldValue.referencedField;

      if (menuItems.length === 0) {
        currentlyDisplayedPath = Array.from(fieldValue.referencedField);
        const currentlySelectedToken = currentlyDisplayedPath.pop();

        menuItems = getNextTokens(referencedNode.outputModel, currentlyDisplayedPath);
        const currentlySelectedMenuItem = menuItems.find(({ name }) => name === currentlySelectedToken);
        if (currentlySelectedMenuItem) {
          currentlySelectedMenuItem.selected = true;
        }
      }
    }
  } else {
    const [thereAreOthers, otherReferencedNodeId] = hasOtherReferencedFields(values, fieldPath, referenceableNodes);

    menuItems = referenceableNodes.map(({ id, name }) => ({
      name,
      type: "node",
      nodeId: id,
      hasContinuation: true,
      disabled: thereAreOthers && id !== otherReferencedNodeId,
    }));
  }

  return { menuItems, currentlyDisplayedPath };
}

function getNextTokens(model: UnwrappedApiServiceModelDescriptor, referencedField: string[]): TokenMenuItemData[] {
  switch (model.type) {
    case ModelType.LIST:
      return getNextTokens(model.member.model, referencedField);

    case ModelType.STRUCTURE: {
      const [current, ...restOfReferencedField] = referencedField;
      if (current) {
        return getNextTokens(model.members[current].model, restOfReferencedField);
      }
      return Object.entries(model.members)
        .map<TokenMenuItemData>(([label, { model }]) => ({
          name: label,
          caption: generateCaption(model),
          type: "token",
          hasContinuation: doesModelHasContinuation(model),
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    case ModelType.MAP: {
      const [current, ...restOfReferencedField] = referencedField;
      if (current) {
        return getNextTokens(
          model.keyMemberName === current ? model.keyMember.model : model.valueMember.model,
          restOfReferencedField
        );
      }
      return [
        {
          name: model.keyMemberName,
          caption: generateCaption(model.keyMember.model),
          type: "token",
          hasContinuation: doesModelHasContinuation(model.keyMember.model),
        },
        {
          name: model.valueMemberName,
          caption: generateCaption(model.valueMember.model),
          type: "token",
          hasContinuation: doesModelHasContinuation(model.valueMember.model),
        },
      ];
    }
    default:
      return [];
  }
}

function generateCaption(model: UnwrappedApiServiceModelDescriptor): string {
  if (
    model.type === ModelType.LIST &&
    ![ModelType.STRUCTURE, ModelType.LIST, ModelType.MAP, ModelType.UNION].includes(model.member.model.type)
  ) {
    return `${model.member.model.type} ${model.type}`;
  }
  return model.type;
}

function doesModelHasContinuation(model: UnwrappedApiServiceModelDescriptor) {
  return (
    [ModelType.STRUCTURE, ModelType.MAP].includes(model.type) ||
    (model.type === ModelType.LIST && [ModelType.LIST, ModelType.STRUCTURE].includes(model.member.model.type))
  );
}

function isObject(value: unknown): value is Record<string | number, object> {
  return value !== null && typeof value === "object";
}
function isArray<T>(value: T | T[]): value is T[] {
  return Array.isArray(value);
}
