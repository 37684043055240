import { type ApprovalConfig, type CloudFlowNodeType, TimeUnits } from "@doitintl/cmp-models";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useFormikContext } from "formik";

import { useNodeConfigurationContext } from "../../NodeConfigurationContext";

export const AutoRejectApproval = () => {
  const { updateNode } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  const { values, errors } = useFormikContext<ApprovalConfig>();
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name="rejectApprovalAfterTime"
            checked={values.rejectApprovalAfterTime}
            onChange={(e) => {
              updateNode((prevNode) => ({
                approval: {
                  ...prevNode.approval!,
                  rejectApprovalAfterTime: e.target.checked,
                },
              }));
            }}
          />
        }
        label="Reject approval after certain time"
      />

      {values.rejectApprovalAfterTime && (
        <Stack
          spacing={2}
          direction={"row"}
          sx={{
            pt: 1,
          }}
        >
          <TextField
            fullWidth
            name="rejectTimeValue"
            label="Number"
            type="number"
            value={values.rejectTimeValue}
            error={Boolean(errors.rejectTimeValue)}
            helperText={errors.rejectTimeValue}
            onChange={(e) => {
              updateNode((prevNode) => ({
                approval: {
                  ...prevNode.approval!,
                  rejectTimeValue: parseInt(e.target.value),
                },
              }));
            }}
            slotProps={{
              input: { inputProps: { min: 1 } },
            }}
          />
          <FormControl fullWidth size="small">
            <InputLabel error={Boolean(errors.rejectTimeUnit)}>Time unit</InputLabel>
            <Select
              name="rejectTimeUnit"
              label="Time unit"
              value={values.rejectTimeUnit || ""}
              error={Boolean(errors.rejectTimeUnit)}
              onChange={(e) => {
                updateNode((prevNode) => ({
                  approval: {
                    ...prevNode.approval!,
                    rejectTimeUnit: e.target.value as TimeUnits,
                  },
                }));
              }}
            >
              {Object.values(TimeUnits).map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
            {errors.rejectTimeUnit !== undefined && (
              <FormHelperText error={true}>{errors.rejectTimeUnit}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      )}
    </>
  );
};
