import { Fragment, type ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { type AccessLevel, AccessLevels, type CloudConnectCategory } from "@doitintl/cmp-models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Card, CardContent, Drawer, Link, Typography } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import uniq from "lodash/uniq";

import { CopyCodeBlock } from "../../../Components/CopyCodeBlock/CopyCodeBlock";
import { ThemeModes } from "../../../muiThemeTypes";
import { getOrgLevelCommands, getProjectLevelCommands } from "./commands";
import { roleID } from "./constants";
import { getScopedCategoryPermissions } from "./utils";

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: 650,
  },
  fullList: {
    width: "auto",
  },
  code: {
    padding: 24,
    backgroundColor: theme.palette.mode === ThemeModes.DARK ? theme.palette.background.default : undefined,
    border: 0,
    overflow: "auto",
    font: "500 90%/1 Roboto Mono,monospace",
    fontSize: 14,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  copyIcon: {
    marginRight: 6,
  },
}));
type command = {
  header: string;
  body?: string;
};

type Props = {
  open: boolean;
  onCloseDialog: (a: boolean) => void;
  children?: ReactNode;
  selectedCategories: CloudConnectCategory[];

  isEditRole: boolean;
  accessLevel: AccessLevel;
};

const Intro = () => (
  <>
    <Typography
      variant="body2"
      sx={{
        mt: 2,
      }}
    >
      Run these{" "}
      <Link target="_blank" href="https://cloud.google.com/sdk">
        gcloud
      </Link>{" "}
      commands in Google Cloud Shell to create a service account and role with selected permissions.
    </Typography>
    <Box
      sx={{
        mt: 2,
        mb: 4,
      }}
    >
      <Button
        variant="outlined"
        size="medium"
        startIcon={<OpenInNewIcon />}
        target="_blank"
        href="https://shell.cloud.google.com/?fromcloudshell=true&show=terminal"
      >
        Open Google Cloud Shell
      </Button>
    </Box>
  </>
);

export default function ConnectDialog({
  open,
  onCloseDialog,
  children,
  selectedCategories,
  isEditRole,
  accessLevel,
}: Props) {
  const classes = useStyles();
  const [permissionList, setPermissionList] = useState<string[]>([]);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  useEffect(() => {
    if (open) {
      const arr: string[] = [];
      selectedCategories.forEach((selected) => {
        arr.push(...getScopedCategoryPermissions(selected, accessLevel));
      });
      setPermissionList(uniq(arr));
    }
  }, [open, selectedCategories, accessLevel]);

  const toggleDrawer = (_, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, right: open });
  };
  const updateAndCloseDialog = () => {
    onCloseDialog(true);
  };

  const orgLevelcommands: command[] = useMemo(() => getOrgLevelCommands(permissionList), [permissionList]);

  const projLevelCommands: command[] = useMemo(() => getProjectLevelCommands(permissionList), [permissionList]);

  const commands: () => command[] = useCallback(() => {
    if (accessLevel === AccessLevels.ORGANIZATION) {
      return orgLevelcommands;
    }
    return projLevelCommands;
  }, [accessLevel, orgLevelcommands, projLevelCommands]);

  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Card elevation={0}>
        <CardContent>
          {!isEditRole && (
            <>
              <Typography variant="h6">Connect a new organization</Typography>
              <Intro />
              {commands().map((command) => (
                <Box
                  key={command.header}
                  data-cy="command-block"
                  sx={{
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 1,
                    }}
                  >
                    {command.header}
                  </Typography>
                  {command.body !== undefined && <CopyCodeBlock base={command.body} />}
                </Box>
              ))}
              {children}
            </>
          )}
          {isEditRole && (
            <>
              <Typography variant="h6">Update Custom Role</Typography>
              <Intro />
              <Box
                sx={{
                  mb: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                  }}
                >
                  Discover your Google IAM Organization ID
                </Typography>
                <CopyCodeBlock base="ORG=$(gcloud organizations list --format 'value(ID)')" />
              </Box>
              <Box
                sx={{
                  mb: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                  }}
                >
                  Update Custom Role
                </Typography>
                <CopyCodeBlock
                  base={`gcloud iam roles update ${roleID} --organization $ORG --permissions ${permissionList.join(
                    ","
                  )}`}
                />
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );

  return (
    <div>
      <Fragment key="right">
        <Drawer anchor="right" open={open} onClose={updateAndCloseDialog}>
          {list("right")}
        </Drawer>
      </Fragment>
    </div>
  );
}
