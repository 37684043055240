import { type Cluster } from "@doitintl/cmp-models";

import { buildRbacYamlFileContent, type KubernetesRbacConfig } from "./rbac";

export type Feature = {
  key: string;
  name: string;
  description: string;
  rbacConfig: KubernetesRbacConfig;
};

export const getRbacYamlFileContent = (cluster: Cluster | null, features: Feature[]): string => {
  if (!cluster) {
    throw new Error("Cluster is required");
  }

  const yamlContent = buildRbacYamlFileContent(cluster, features);

  return yamlContent;
};

export const KUBERNETES_FEATURES: Feature[] = [
  {
    key: "cloudflow-k8s",
    name: "CloudFlow for Kubernetes",
    description: "Build custom automations for your clusters using the DoiT feature CloudFlow",
    rbacConfig: {
      pods: ["get", "list"],
      namespaces: ["get", "list"],
    },
  },
  {
    key: "fsk8s",
    name: "Flexsave for Kubernetes",
    description: "Receive recommendations and gain insight into your clusters",
    rbacConfig: {
      pods: ["get", "list"],
      namespaces: ["get", "list"],
    },
  },
];
