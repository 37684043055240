import { subCollection, type Timestamp } from "@doitintl/models-types";

export class SKUsForNotificationModel {
  skus!: string[];

  cycle!: number;

  sent!: boolean;
}
@subCollection("metadataCopies")
export class MetadataCopyModel {
  billingAccountId!: string;

  type!: string;

  data!: string[];

  timestamp!: Timestamp;

  notifications!: SKUsForNotificationModel[];

  currentCycle!: number;
}
