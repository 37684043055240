import React from "react";

import { InfoOutlined } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import LockIcon from "@mui/icons-material/Lock";
import { Box, Button, Divider, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";

import { EasyWinChip } from "../EasyWin/EasyWin";

type Props = {
  service: string;
  title: string;
  potentialSavings: string;
  noSavingsAvailable?: boolean;
  headerRow?: boolean;
  easyWinDescription?: string;
};

export const Insight = ({
  service,
  title,
  potentialSavings,
  noSavingsAvailable,
  headerRow,
  easyWinDescription,
}: Props) => {
  const below1244 = useMediaQuery("(max-width:1244px)");
  const isMobile = useMediaQuery("(max-width:700px)");

  if (below1244) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        pt={headerRow ? 0 : 1.5}
        pb={1.5}
        sx={{ borderBottom: 1, borderColor: "divider" }}
        gap={2}
      >
        <Typography
          variant="subtitle2"
          fontWeight={noSavingsAvailable ? 400 : 500}
          lineHeight={headerRow ? "24px" : "22px"}
          color={noSavingsAvailable ? "text.secondary" : "text.primary"}
          fontSize={14}
        >
          {title}
        </Typography>
        {!headerRow && (
          <Typography
            variant="subtitle2"
            fontWeight={noSavingsAvailable ? 400 : 500}
            lineHeight={headerRow ? "24px" : "22px"}
            fontSize={14}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {noSavingsAvailable ? (
              <Stack direction="row" alignItems="center" justifyContent="space-between" color="text.secondary">
                Optimized&nbsp;
                {!isMobile && (
                  <Tooltip
                    title={
                      <>
                        <Box>There are currently no savings opportunities available on this service.</Box>
                        <Box mt={1}>
                          DoiT Cloud Intelligence™ checks every day for any potential savings associated with this
                          insight based on your recent billing data, excluding the last three days.
                        </Box>
                      </>
                    }
                  >
                    <InfoOutlined sx={{ color: "text.secondary", height: 16, width: 16 }} />
                  </Tooltip>
                )}
              </Stack>
            ) : (
              potentialSavings
            )}
          </Typography>
        )}
      </Stack>
    );
  }

  return (
    <>
      <Stack>
        <Typography
          variant="subtitle2"
          fontWeight={noSavingsAvailable ? 400 : 500}
          lineHeight={headerRow ? "24px" : "30px"}
          mt={headerRow ? 0 : 1.5}
          color={noSavingsAvailable ? "text.secondary" : "text.primary"}
        >
          {service}
        </Typography>
        <Divider sx={{ mt: 1.5 }} />
      </Stack>
      <Stack>
        <Typography
          variant="subtitle2"
          fontWeight={noSavingsAvailable ? 400 : 500}
          lineHeight={headerRow ? "24px" : "30px"}
          mt={headerRow ? 0 : 1.5}
          color={noSavingsAvailable ? "text.secondary" : "text.primary"}
        >
          {title}
        </Typography>
        <Divider sx={{ mt: 1.5 }} />
      </Stack>
      <Stack>
        <Typography
          variant="subtitle2"
          fontWeight={noSavingsAvailable ? 400 : 500}
          lineHeight={headerRow ? "24px" : "30px"}
          mt={headerRow ? 0 : 1.5}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {noSavingsAvailable ? (
            <Stack direction="row" alignItems="center" justifyContent="space-between" color="text.secondary">
              Optimized&nbsp;
              <Tooltip
                title={
                  <>
                    <Box>There are currently no savings opportunities available on this service.</Box>
                    <Box mt={1}>
                      DoiT Cloud Intelligence™ checks every day for any potential savings associated with this insight
                      based on your recent billing data, excluding the last three days.
                    </Box>
                  </>
                }
              >
                <InfoOutlined sx={{ color: "text.secondary", height: 16, width: 16 }} />
              </Tooltip>
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                variant="subtitle2"
                fontWeight={500}
                color="text.primary"
                lineHeight={headerRow ? "24px" : "30px"}
                mt={headerRow ? 0 : 1.5}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {potentialSavings}
                {headerRow && <ArrowDownwardIcon sx={{ ml: 0.5, height: 16, width: 16, color: "action.active" }} />}
              </Typography>
              {easyWinDescription && <EasyWinChip tooltipText={easyWinDescription} tooltipPlacement={"right-end"} />}
            </Stack>
          )}
        </Typography>
        <Divider sx={{ mt: 1.5 }} />
      </Stack>
      {headerRow ? (
        <Stack justifyContent="flex-end" height={headerRow ? 37 : 43}>
          <Divider />
        </Stack>
      ) : (
        <Stack justifyContent="flex-end">
          <Stack alignItems="flex-end">
            <Button
              startIcon={<LockIcon />}
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ width: 122, whiteSpace: "nowrap", mt: "10.5px" }}
              disabled
            >
              View details
            </Button>
          </Stack>
          <Divider sx={{ mt: "10.5px" }} />
        </Stack>
      )}
    </>
  );
};
