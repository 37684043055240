import { useCallback } from "react";

import { useHistory, useParams } from "react-router";
import {
  AssetTypeAmazonWebServices,
  AssetTypeBetterCloud,
  AssetTypeGcpPartnerLedPremiumSupport,
  AssetTypeGoogleCloud,
  AssetTypeZendesk,
  ContractModel,
  InternalTierNames,
  TierPackageTypes,
  VendorContractModel,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  MobileStepper,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useApiContext } from "../../../api/context";
import LoadingButton from "../../../Components/LoadingButton";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { pickerContext, PickerContextProvider } from "../../../Context/GapiContext";
import { useTier } from "../../../Context/TierProvider";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { consoleErrorWithSentry } from "../../../utils";
import { useFullScreen } from "../../../utils/dialog";
import { serverTimestamp } from "../../../utils/firebase";
import { useContractTypesContext } from "../../ContractsTabs/ContractsTypesContext";
import { useTurnOffPresentationMode } from "../api";
import { ContractFormContextProvider, useContractFormContext } from "./ContractsFormContext";
import { getStepsConfig } from "./Steps/StepsConfig";
import { contractApiFormattedPayload, formatContractToFs, formatVendorContractToFs, isDoitCloudProduct } from "./utils";

export const ContractForm = () => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const { customer, entities, assets } = useCustomerContext();
  const { customerId, contractId } = useParams<{ customerId: string; contractId: string }>();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const api = useApiContext();
  const { isMobile } = useFullScreen();
  const { getInternalTiers } = useTier();
  const turnOffPresentationMode = useTurnOffPresentationMode(api);
  const { state, setState } = useContractFormContext();
  const handleBackToList = useCallback(() => {
    history.push(`/customers/${customerId}/contracts/contracts-list`);
  }, [customerId, history]);
  const stepsConfig = getStepsConfig(state);
  const { loading: contractTypesLoading } = useContractTypesContext();

  const createUpdateVendorContract = async (): Promise<void | ModelReference<VendorContractModel>> => {
    if (!state.isCommitment || !state.vendorContract?.commitmentType) {
      return;
    }

    const vendorContract = formatVendorContractToFs(state, customer, currentUser, assets);

    try {
      if (state.vendorContract?.id) {
        await getCollection(VendorContractModel).doc(state.vendorContract.id).update(vendorContract);
        return;
      }

      const createdVendorContractRef = await getCollection(VendorContractModel).add(vendorContract);

      return createdVendorContractRef;
    } catch (error) {
      consoleErrorWithSentry("createUpdateVendorContractError", {
        ...vendorContract,
        error,
        id: state.vendorContract?.id,
      });
      throw error;
    }
  };

  const createUpdateContract = async (
    createdVendorContractRef: void | ModelReference<VendorContractModel>
  ): Promise<null | string> => {
    const contract = formatContractToFs(state, customer, currentUser, assets);
    if (createdVendorContractRef) {
      contract.vendorContract = createdVendorContractRef;
    }

    let createdContractId: string | null = null;
    let contractRef: ModelReference<ContractModel>;

    try {
      // create contract for every asset
      if (!state.editMode && state.type === AssetTypeGcpPartnerLedPremiumSupport) {
        state.assets.forEach(async (asset) => {
          const FormattedContract = formatContractToFs({ ...state, assets: [asset] }, customer, currentUser, assets);
          contractRef = await getCollection(ContractModel).add(FormattedContract);
        });
        return createdContractId;
      }

      if (contractId) {
        contractRef = getCollection(ContractModel).doc(contractId);
        await contractRef.update(contract);
      } else {
        contractRef = await getCollection(ContractModel).add(contract);
        createdContractId = contractRef.id;

        const tiers = getInternalTiers(TierPackageTypes.NAVIGATOR);

        if (isCustomerInPresentationMode(customer)) {
          await turnOffPresentationMode(customer.id, {
            navigatorTierId: tiers?.[InternalTierNames.ZERO_ENTITLEMENTS]?.id,
          });
        }
      }

      if (createdVendorContractRef) {
        await createdVendorContractRef.update({ customerContract: contractRef });
      }
    } catch (error) {
      consoleErrorWithSentry("createUpdateContractError", {
        ...contract,
        error,
        id: contractId,
      });
      throw error;
    }

    return createdContractId;
  };

  const createGCPUpdateEvent = async (contractId: string) => {
    if (state.type !== AssetTypeGoogleCloud) {
      return;
    }
    // when the event is handled by cloud analytics, we delete the doc
    await getCollection(ContractModel).doc(contractId).collection("contractUpdates").add({
      type: state.type,
      timestamp: serverTimestamp(),
    });
  };

  const updateAWSPricebooks = () =>
    Object.entries(state.selectedPricebooks)
      .map(([payer_id, price_book_id]) => ({
        price_book_id,
        customer_id: customerId,
        payer_id,
        contract_id: contractId,
      }))
      .map((payload) => api.post("/v1/pricebooks/amazon-web-services/assignments", payload));

  const createUpdateContractWithAPI = async () => {
    const contract = formatContractToFs(state, customer, currentUser, assets);
    const requestPayload = contractApiFormattedPayload(contract);
    const endpoint = state.editMode ? `update/${contractId}` : "create";
    return api.post(`/v1/contract/${endpoint}`, requestPayload);
  };

  const handleSubmit = async () => {
    if (!stepsConfig[state.activeStep]?.isValid(state, setState)) {
      return;
    }

    setState({ ...state, loading: true });

    try {
      if (state.type === AssetTypeAmazonWebServices) {
        await Promise.all(updateAWSPricebooks()).catch((err) => {
          throw new Error(`Pricebook assignment error: ${err.response?.data?.errors}`);
        });
      }

      if (isDoitCloudProduct(state.type)) {
        await createUpdateContractWithAPI();
      } else {
        const createdVendorContractRef = await createUpdateVendorContract();
        const createdContractId = await createUpdateContract(createdVendorContractRef);
        await createGCPUpdateEvent(createdContractId ?? contractId);
      }

      if (state.type === AssetTypeGcpPartnerLedPremiumSupport) {
        api.put(`/support/customers/${customerId}/add-plps-private-message`);
      }

      snackbar.onOpen({
        message: "Contract saved successfully",
        variant: "success",
        autoHideDuration: 5000,
        withClose: true,
      });

      setState({ ...state, loading: false });

      if ([AssetTypeZendesk, AssetTypeBetterCloud].includes(state.type)) {
        history.push(`/customers/${customerId}/assets/${state.type}/create`);
        return;
      }
      handleBackToList();
    } catch (error: any) {
      consoleErrorWithSentry(error);
      setState({ ...state, loading: false });
      snackbar.onOpen({
        message: `Failed to save contract. ${error.message}`,
        variant: "error",
        autoHideDuration: 5000,
        withClose: true,
      });
    }
  };

  const steps = [...stepsConfig.map((step) => step.title)];

  const handleNext = () => {
    if (stepsConfig[state.activeStep]?.isValid(state, setState)) {
      let nextStep = state.activeStep + 1;

      while (stepsConfig[nextStep]?.skip?.()) {
        nextStep += 1;
      }

      setState({ ...state, activeStep: nextStep });
    }
  };

  const handleBack = () => {
    let newStep = state.activeStep - 1;
    while (stepsConfig[newStep]?.skip?.()) {
      newStep -= 1;
    }

    setState({ ...state, activeStep: newStep });
  };

  const { activeStep, loading } = state;

  if ((contractId && !state.editMode) || contractTypesLoading) {
    return null;
  }

  return (
    <>
      <Card sx={{ border: "none", boxShadow: "none", paddingTop: 4, paddingBottom: 8 }} data-cy="contract-form">
        {isMobile && (
          <CardHeader
            avatar={
              <IconButton aria-label="Back" onClick={handleBackToList} size="large">
                <BackIcon color="primary" />
              </IconButton>
            }
            title={state.editMode ? "Edit Contract" : "Create a Contract"}
            subheader={customer.name}
          />
        )}
        {Boolean(entities) && (
          <CardContent>
            {!isMobile && (
              <Container maxWidth="lg">
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step
                      key={label}
                      completed={activeStep > index && !stepsConfig[index]?.skip?.()}
                      disabled={stepsConfig[index]?.skip?.()}
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Container>
            )}
            <Box sx={{ pt: 3 }}>{stepsConfig[activeStep]?.component}</Box>
          </CardContent>
        )}
        {isMobile && (
          <MobileStepper
            steps={steps.length}
            position="bottom"
            activeStep={activeStep}
            variant="progress"
            nextButton={
              activeStep >= steps.length - 1 ? (
                <LoadingButton
                  color="primary"
                  size="small"
                  onClick={handleSubmit}
                  data-cy="submit-button"
                  disabled={loading || state.isEditForbidden}
                  loading={loading}
                  mixpanelEventId="contracts.submit"
                >
                  Submit
                  <KeyboardArrowRight />
                </LoadingButton>
              ) : (
                <Button
                  color="primary"
                  size="small"
                  data-cy="next-button"
                  onClick={handleNext}
                  disabled={stepsConfig.length <= 2}
                >
                  Next
                  <KeyboardArrowRight />
                </Button>
              )
            }
            backButton={
              <Button
                color="primary"
                size="small"
                data-cy="back-button"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        )}
      </Card>
      {!isMobile && (
        <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1 }}>
          <Grid
            container
            sx={{
              justifyContent: "center",
            }}
          >
            <Grid size={12}>
              <Divider />
            </Grid>

            <Grid size={5}>
              <CardActions>
                <Button
                  color="primary"
                  variant="outlined"
                  data-cy="back-button"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </Button>
              </CardActions>
            </Grid>
            <Grid>
              <CardActions>
                <Button
                  sx={{ mx: 1 }}
                  color="primary"
                  aria-label="cancel"
                  data-cy="cancel-button"
                  onClick={handleBackToList}
                >
                  Cancel
                </Button>
                {activeStep >= steps.length - 1 ? (
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    data-cy="submit-button"
                    onClick={handleSubmit}
                    disabled={loading || state.isEditForbidden}
                    loading={loading}
                    mixpanelEventId="contracts.submit"
                  >
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    data-cy="next-button"
                    onClick={handleNext}
                    disabled={stepsConfig.length <= 2}
                  >
                    Next
                  </Button>
                )}
              </CardActions>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

const ContractFormWrapper = (props) => (
  <PickerContextProvider>
    <pickerContext.Consumer>
      {({ pickerApiLoaded }) => (
        <ContractFormContextProvider>
          <ContractForm pickerApiLoaded={pickerApiLoaded} {...props} />
        </ContractFormContextProvider>
      )}
    </pickerContext.Consumer>
  </PickerContextProvider>
);

export default ContractFormWrapper;
