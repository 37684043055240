import { type FC, type ReactNode } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton, Stack, type SxProps } from "@mui/material";

const spacing: SxProps = { pt: 1, "&:not(:first-child)": { pt: 2 }, pl: 0.5 };

export const OptionalParamWrapper: FC<{ children: ReactNode; onRemove?: () => void }> = ({ children, onRemove }) => {
  if (!onRemove) {
    return (
      <Box sx={spacing} minWidth={0} flexGrow={1}>
        {children}
      </Box>
    );
  }

  return (
    <Stack direction="row" alignItems="flex-start" sx={spacing}>
      {children}
      <IconButton size="small" onClick={onRemove} sx={{ mt: 0.75 }}>
        <ClearIcon fontSize="inherit" />
      </IconButton>
    </Stack>
  );
};
