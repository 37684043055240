import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import ForkRightRoundedIcon from "@mui/icons-material/ForkRightRounded";
import { Card, CardHeader, Stack } from "@mui/material";
import { useTheme } from "@mui/system";

import { cloudflowTexts } from "../../../../../assets/texts";
import ConditionForm from "../../FilterAction/ConditionForm";
import FieldReferenceForm from "../../FilterAction/FieldReferenceForm";
import SwitchDataSourceDialog from "../../FilterAction/SwitchDataSourceDialog";
import { useNodeDataSourceUpdate } from "../hooks";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

export const ConditionParametersTab = () => {
  const {
    palette: { primary },
  } = useTheme();

  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.CONDITION>();

  const {
    selectedNode,
    setSelectedNode,
    referenceableNodes,
    switchDataSourceOpen,
    handleConfirm,
    handleClose,
    confirmSwitchDataSource,
  } = useNodeDataSourceUpdate({
    nodeId: nodeConfig.id,
    referencedNodeId: nodeConfig.parameters.referencedNodeId,
  });

  return (
    <Stack
      sx={{
        px: 2,
        justifyContent: "center",
        gap: 2,
        mt: 1,
      }}
    >
      <Card sx={{ "&:hover": { borderColor: primary.main } }}>
        <CardHeader
          avatar={<ForkRightRoundedIcon color="primary" />}
          subheader="Funnel data down a defined path"
          title="IF statement"
          titleTypographyProps={{ variant: "body2", textDecoration: "none" }}
          subheaderTypographyProps={{ variant: "caption" }}
          sx={{ ".MuiCardHeader-action": { alignSelf: "center" }, p: 1, pr: 2 }}
        />
      </Card>
      <FieldReferenceForm
        selectedNode={selectedNode}
        confirmSwitchDataSource={confirmSwitchDataSource}
        setSelectedNode={setSelectedNode}
        referenceableNodes={referenceableNodes}
        texts={{
          datasource: {
            title: cloudflowTexts.SELECT_DATA_SOURCE,
            subtitle: cloudflowTexts.PICK_DATA_SOURCE_FOR_IF_STATEMENT,
            label: cloudflowTexts.DATA_SOURCES,
          },
          referenceField: {
            title: cloudflowTexts.SELECT_FIELD_TO_DEFINE_CRITERIA,
            subtitle: cloudflowTexts.FROM_CHOOSEN_FIELD_AND_FILTER,
            label: cloudflowTexts.REFERENCED_FIELD,
            placeholderTooltip: cloudflowTexts.SELECT_VALUE_FROM_DATA_SOURCE,
          },
        }}
      />
      <ConditionForm
        selectedNode={selectedNode}
        referenceableNodes={referenceableNodes}
        texts={{
          addNewGroupText: cloudflowTexts.ADD_NEW_CRITERIA_GROUP,
          defineCriteriaText: cloudflowTexts.DEFINE_FILTER_CRITERIA,
          fromChosenFieldText: cloudflowTexts.DEFINE_TRUE_PATH_CRITERIA_DESCRIPTION,
          groupText: cloudflowTexts.CRITERIA_GROUP,
          addNewText: cloudflowTexts.ADD_NEW_CRITERIA,
        }}
      />
      <SwitchDataSourceDialog
        isDialogOpened={switchDataSourceOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </Stack>
  );
};
