import get from "lodash/get";
import setWith from "lodash/setWith";
import uniq from "lodash/uniq";

import { type TreeChartSeriesRecord } from "./useChartsSeries";
import type ReportData from "../ReportData";

type TreeNode = {
  id: string;
  parent?: string;
  name: string;
  value: number;
  [key: string]: unknown;
};

export const getChartsTreeSeries = (data: ReportData) => {
  const isRowTotalChildOfPath = (path: string[], rowTotal: string[]): boolean => {
    for (let i = 0; i < path.length; i++) {
      if (path[i] !== rowTotal[i]) {
        return false;
      }
    }
    return true;
  };

  const getTreeStructureWithValues = (treeStructure: Record<string, TreeNode>, treePaths: string[]) => {
    const treeStructureWithValues = { ...treeStructure };
    const rowTotals = data.getRowTotals();

    treePaths.forEach((path) => {
      Object.keys(rowTotals).forEach((rowTotalKey) => {
        const key = path.split("\u0000");
        if (isRowTotalChildOfPath(key, rowTotalKey.split("\u0000"))) {
          const keyWithValue = [...key, "value"];
          setWith(
            treeStructureWithValues,
            keyWithValue,
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            get(treeStructureWithValues, keyWithValue, 0) + rowTotals[rowTotalKey].sum
          );
        }
      });
    });

    return treeStructureWithValues;
  };

  const treeStructure: Record<string, TreeNode> = {};
  let treePaths: string[] = [];

  for (let i = 0; i < data.rows.length; i++) {
    data.rowKeys.forEach((row) => {
      if (!row[0]) return;

      const key = row.slice(0, i + 1).join(".");
      const id = row.slice(0, i + 1).join("_");
      const name = row.slice(i, i + 1).join();
      treePaths.push(row.slice(0, i + 1).join("\u0000"));
      const parent = row.slice(0, i).join("_") || undefined;

      if (!get(treeStructure, key)) {
        setWith(treeStructure, row.slice(0, i + 1), { id, parent, name });
      }
    });
  }

  treePaths = uniq(treePaths);
  const treeWithValues = getTreeStructureWithValues(treeStructure, treePaths);

  const buildTree = (nodes: Record<string, TreeNode>): TreeChartSeriesRecord[] => {
    const flatNodes: TreeNode[] = [];
    const processNode = (obj: Record<string, unknown>, path: string[] = []) => {
      if (obj && typeof obj === "object") {
        if ("id" in obj && "name" in obj) {
          flatNodes.push(obj as TreeNode);
        }
        Object.entries(obj).forEach(([key, value]) => {
          if (value && typeof value === "object") {
            processNode(value as Record<string, unknown>, [...path, key]);
          }
        });
      }
    };
    processNode(nodes);

    const nodesByParent: Record<string, TreeNode[]> = {};
    flatNodes.forEach((node) => {
      const parentId = node.parent || "root";
      nodesByParent[parentId] = nodesByParent[parentId] || [];
      nodesByParent[parentId].push(node);
    });

    const buildNodeTree = (parentId: string): TreeChartSeriesRecord[] => {
      const children = nodesByParent[parentId] || [];
      return children.map((node) => ({
        name: node.name,
        value: node.value || 0,
        children: buildNodeTree(node.id),
      }));
    };

    return buildNodeTree("root");
  };

  return buildTree(treeWithValues);
};
