import { useMemo } from "react";

import { type ReportsValues, useReports } from "../../../Components/hooks/cloudAnalytics/reports/useReports";
import {
  type ReportTemplateFavoriteValues,
  useReportTemplateFavorites,
} from "../../../Components/hooks/cloudAnalytics/useReportTemplateFavorites";
import {
  type ReportTemplatesValues,
  useReportTemplates,
} from "../../../Components/hooks/cloudAnalytics/useReportTemplates";
import { useCachedHook } from "../../../Context/DynamicContext/useCachedHook";

export type AnalyticsReportsContextType = {
  reportTemplates: ReportTemplatesValues;
  reportTemplateFavorites: ReportTemplateFavoriteValues;
  reports: ReportsValues;
  loading: boolean;
};

function useNonCachedAnalyticsReportsContext() {
  const [reportTemplatesWithVersions, dispatchReportTemplates, managedReportsIds, reportsTemplatesLoading] =
    useReportTemplates();

  const reportTemplateFavorites = useReportTemplateFavorites(reportTemplatesWithVersions);

  const [filteredReportTemplateFavorites] = reportTemplateFavorites;

  const reports = useReports(reportTemplatesWithVersions, managedReportsIds, filteredReportTemplateFavorites);

  return useMemo<AnalyticsReportsContextType>(() => {
    const [, , reportsLoading] = reports;

    return {
      reportTemplates: [
        reportTemplatesWithVersions,
        dispatchReportTemplates,
        managedReportsIds,
        reportsTemplatesLoading,
      ],
      reportTemplateFavorites,
      reports,
      loading: reportsLoading,
    };
  }, [
    dispatchReportTemplates,
    managedReportsIds,
    reportTemplateFavorites,
    reportTemplatesWithVersions,
    reports,
    reportsTemplatesLoading,
  ]);
}

export function useAnalyticsReportsContext() {
  return useCachedHook(useNonCachedAnalyticsReportsContext);
}
