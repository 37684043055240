import React, { type ReactNode, useCallback, useMemo, useState } from "react";

import { type CaseIQBaseURLType } from "@doitintl/case-iq/src/app";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import type { CaseIQRouter } from "@doitintl/case-iq/src/router";

import { useAuthContext } from "../../../Context/AuthContext";
import { type FirebaseUserWithEmail } from "../../../Context/UserContext";
import { useCurrentLanguage } from "../../../utils/useCurrentLanguage";
import { SeverityLevels } from "../../types";

let caseIqSessionId = "";
let zdTicketId = "";
export const setCaseIqSessionId = (sessionId: string) => {
  caseIqSessionId = sessionId;
};
export const setZdTicketId = (ticketId: string) => {
  zdTicketId = ticketId;
};

const caseIQBaseUrl: CaseIQBaseURLType = "/api/caseiq/v1/trpc";

export const caseIQTrpc = createTRPCReact<CaseIQRouter>();

export const CaseIQClientProvider = ({ children }: { children: ReactNode }) => {
  const { currentUser } = useAuthContext();

  const getToken = useCallback(
    async (forceRefresh?: boolean) => currentUser?.getIdTokenResult(forceRefresh),
    [currentUser]
  );
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    caseIQTrpc.createClient({
      links: [
        httpBatchLink({
          url: `${window.location.origin}${caseIQBaseUrl}`,
          async headers() {
            const currentToken = await getToken();
            return {
              Authorization: currentToken ? `Bearer ${currentToken.token}` : undefined,
              "x-caseiq-session-id": caseIqSessionId,
              "x-zendesk-ticket-id": zdTicketId,
            };
          },
        }),
      ],
    })
  );

  return (
    <caseIQTrpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </caseIQTrpc.Provider>
  );
};

export function getClient(currentUser: FirebaseUserWithEmail | null | undefined) {
  return createTRPCProxyClient<CaseIQRouter>({
    links: [
      httpBatchLink({
        url: `${window.location.origin}${caseIQBaseUrl}`,
        async headers() {
          const currentToken = await currentUser?.getIdTokenResult();
          return {
            Authorization: currentToken ? `Bearer ${currentToken.token}` : undefined,
            "x-caseiq-session-id": caseIqSessionId,
            "x-zendesk-ticket-id": zdTicketId,
          };
        },
      }),
    ],
  });
}

const supportedProducts = [
  "gcp/compute-engine",
  "gcp/bigquery",
  "aws/amazon-ec2",
  "aws/amazon-rds",
  "gcp/cloud-load-balancing",
  "gcp/cloud-composer",
  "gcp/identity-and-access-management-iam",
  "aws/amazon-elastic-kubernetes-service-eks",
  "gcp/cloud-storage",
  "gcp/app-engine",
  "gcp/cloud-functions",
  "gcp/cloud-run",
  "gcp/cloud-sql",
  "gcp/google-kubernetes-engine-gke",
];

type Props = {
  severity: SeverityLevels | undefined;
  cloudProduct: string | undefined;
};

export const useABTestingCaseIQ = ({ severity, cloudProduct }: Props): boolean => {
  const lang = useCurrentLanguage();

  return useMemo(() => {
    if (!severity || !cloudProduct) {
      return false;
    }

    if (![SeverityLevels.high, SeverityLevels.urgent].includes(severity)) {
      return false;
    }

    if (!supportedProducts.includes(cloudProduct)) {
      return false;
    }

    if (lang !== "en") {
      return false;
    }

    return true;
  }, [lang, severity, cloudProduct]);
};

export const stripHtmlTags = (html: string): string => html.replace(/<\/?[^>]+(>|$)/g, "");
