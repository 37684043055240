import { useMemo } from "react";

import { AppModel, type OneTimeServiceType } from "@doitintl/cmp-models";
import { getCollection, type ModelData, useCollectionDataOnce } from "@doitintl/models-firestore";
import { FormControl, FormHelperText, InputLabel, ListSubheader, MenuItem, Select, Typography } from "@mui/material";

type Props = {
  value?: string;
  onChange: (event) => void;
  error: boolean;
};

const productTypeLabels = {
  "amazon-web-services": "Amazon Web Services",
  "google-cloud": "Google Cloud",
};

export const OneTimeServiceTypeSelector = ({ value, onChange, error }: Props) => {
  const [oneTimeServiceTypeOptions, loading] = useCollectionDataOnce(
    useMemo(() => getCollection(AppModel).doc("contracts").collection("doit-one-time-service-types"), []),
    {
      idField: "id",
    }
  );

  const oneTimeServiceTypeOptionsByCloud = useMemo(() => {
    if (loading || !oneTimeServiceTypeOptions) {
      return {};
    }

    return oneTimeServiceTypeOptions.reduce(
      (acc: { [key: string]: ModelData<OneTimeServiceType, "id", string | undefined>[] }, curr) => {
        if (!acc[curr.cloud]) {
          acc[curr.cloud] = [];
        }

        acc[curr.cloud].push(curr);
        return acc;
      },
      {}
    );
  }, [oneTimeServiceTypeOptions, loading]);

  return (
    <FormControl size="small" fullWidth margin="normal" error={error}>
      <InputLabel id="one-time-service-type-selector-label">One-Time Services Type</InputLabel>
      <Select
        labelId="one-time-service-type-selector-label"
        data-cy="one-time-service-type-selector"
        onChange={onChange}
        value={value ?? ""}
        fullWidth
        variant="outlined"
        label="One-Time Services Type"
      >
        {Object.keys(oneTimeServiceTypeOptionsByCloud).map((key) => [
          <ListSubheader key={key}>
            <Typography
              sx={{
                fontSize: 12,
                color: "text.primary",
              }}
            >
              {productTypeLabels[key]}
            </Typography>
          </ListSubheader>,
          oneTimeServiceTypeOptionsByCloud[key].map(({ id, label }: { id: string; label: string }) => (
            <MenuItem key={id} value={id} sx={{ pl: 4 }}>
              {label}
            </MenuItem>
          )),
        ])}
      </Select>
      <FormHelperText>Select the one-time service type</FormHelperText>
    </FormControl>
  );
};
