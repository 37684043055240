import { type Cluster } from "@doitintl/cmp-models";

import { type Feature } from "./features";

export const DOIT_EKS_GROUP = "doit-eks-group";

export type KubernetesResourceVerb =
  | "get"
  | "list"
  | "watch"
  | "create"
  | "update"
  | "patch"
  | "delete"
  | "deletecollection"
  | "impersonate";

const apiGroupsByResource: Record<string, string[]> = {
  // Core API group (empty string)
  pods: [""],
  services: [""],
  configmaps: [""],
  secrets: [""],
  persistentvolumeclaims: [""],
  nodes: [""],
  persistentvolumes: [""],

  // Apps API group
  deployments: ["apps"],
  daemonsets: ["apps"],
  statefulsets: ["apps"],
  replicasets: ["apps"],

  // Batch API group
  jobs: ["batch"],
  cronjobs: ["batch"],

  // Policy API group
  poddisruptionbudgets: ["policy"],
  podsecuritypolicies: ["policy"],

  // Networking API group
  networkpolicies: ["networking.k8s.io"],
  ingresses: ["networking.k8s.io"],

  // RBAC API group
  roles: ["rbac.authorization.k8s.io"],
  rolebindings: ["rbac.authorization.k8s.io"],
  clusterroles: ["rbac.authorization.k8s.io"],
  clusterrolebindings: ["rbac.authorization.k8s.io"],

  // Storage API group
  storageclasses: ["storage.k8s.io"],
  volumeattachments: ["storage.k8s.io"],

  // Admissionregistration API group
  mutatingwebhookconfigurations: ["admissionregistration.k8s.io"],
  validatingwebhookconfigurations: ["admissionregistration.k8s.io"],

  // Autoscaling API group
  horizontalpodautoscalers: ["autoscaling"],

  // Events API group
  events: ["events.k8s.io"],

  default: [""],
};

export type KubernetesRbacConfig = Partial<Record<string, KubernetesResourceVerb[]>>;

const getYamlContentForFeature = (cluster: Cluster, feature: Feature): string => {
  const { key, name, rbacConfig } = feature;

  const clusterPrefix = `doit-${cluster.region}-${cluster.name}`;
  const roleName = `${clusterPrefix}-${key}-role`;
  const roleBindingName = `${clusterPrefix}-${key}-binding`;

  const rules = Object.entries(rbacConfig).map(
    ([resource, verbs]) =>
      `  - apiGroups: ["${(apiGroupsByResource[resource] || apiGroupsByResource.default).join('", "')}"]
    resources: ["${resource}"]
    verbs: ["${verbs?.join('", "')}"]`
  );

  return `# Define a ClusterRole with permissions for the feature: ${name}
apiVersion: rbac.authorization.k8s.io/v1
kind: ClusterRole
metadata:
  name: ${roleName}
  labels:
    feature: ${key}
rules:
${rules.join("\n\n")}

---
# Bind the ClusterRole to a Group
apiVersion: rbac.authorization.k8s.io/v1
kind: ClusterRoleBinding
metadata:
  name: ${roleBindingName}
  labels:
    feature: ${key}
subjects:
  - kind: Group
    name: ${DOIT_EKS_GROUP}
roleRef:
  kind: ClusterRole
  name: ${roleName}
  apiGroup: rbac.authorization.k8s.io

`;
};

export const buildRbacYamlFileContent = (cluster: Cluster, features: Feature[]): string =>
  features.map((f) => getYamlContentForFeature(cluster, f)).join("\n---\n");
