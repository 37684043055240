import { useCallback } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useSauronAPI, useSauronStaffApi } from "../api/endpoints";
import { useSnackbar } from "../Components/SharedSnackbar/SharedSnackbar.context";
import { useConcedefy } from "../Pages/MPA/hooks/useConcedefy";
import { consoleErrorWithSentry } from "./errorHandling";

export function useGetLoginUrl() {
  const { getAuthorizedAWSAccountLink } = useConcedefy();
  const { onOpen: showSnackbar } = useSnackbar();
  const api = useSauronAPI();

  return useCallback(
    async (accountId: string, isStaff: boolean, customerId: string, isPlesCustomer?: boolean): Promise<string> => {
      // it is preferred that isPlesCustomer be defined by a hook in the parent component,
      // but that may not be possible (e.g. the MPA Table with records from more than one customer, or outside the CustomerContext)
      if (isPlesCustomer === undefined) {
        const doc = await getCollection(AppModel).doc("support").collection("plpsCustomers").doc(customerId).get();
        isPlesCustomer = doc.exists();
      }

      // concedefy func
      const concedefyApi = async (): Promise<string> => {
        const { data } = await getAuthorizedAWSAccountLink("support-request-passthrough", accountId, customerId);
        return data.console_url;
      };
      // sauron func
      const sauronApi = async (): Promise<string> => {
        const accountDetails = await api.account(accountId, isStaff);
        if (accountDetails.data?.account_number) {
          const supportRes = await api.supportConsole(accountId, isStaff);
          return supportRes.data?.url ?? "#";
        }

        consoleErrorWithSentry("Failed to get account details");
        return "#";
      };

      // PLES customers attempt Concedefy before Sauron
      if (isPlesCustomer) {
        try {
          return await concedefyApi();
        } catch (e) {
          consoleErrorWithSentry(e);

          // sauron, as fallback until this is deprecated and removed
          try {
            return await sauronApi();
          } catch (e) {
            consoleErrorWithSentry(e);
            showSnackbar({
              message: "Error connecting to AWS. Please contact support.",
              variant: "error",
              autoHideDuration: 5000,
            });
          }
        }
        return "#";
      }

      // Not PLES customers, use Sauron
      try {
        return await sauronApi();
      } catch (e) {
        consoleErrorWithSentry(e);
      }

      // URL goes nowhere, does nothing
      return "#";
    },
    [api, getAuthorizedAWSAccountLink, showSnackbar]
  );
}

export function useCredentials(accountId: string) {
  const api = useSauronAPI();
  const staffApi = useSauronStaffApi(accountId);

  return useCallback(async () => {
    const accountDetails = await api.account(accountId, true);
    if (accountDetails.data?.has_role) {
      const supportRes = await staffApi.getSupportCredentials();
      return supportRes.data?.credentials;
    }
  }, [api, staffApi, accountId]);
}
