import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CommitmentManagerGraphic from "../../assets/image_first_screen_CM.png";
import { homeBullets } from "./consts";

export const Onboarding = () => (
  <Grid
    container
    spacing={8}
    data-cy="rampsonboard"
    size={{
      xs: 12,
      md: 10,
      lg: 7,
    }}
    sx={{
      margin: "auto",
    }}
  >
    <Grid
      size={{
        sm: 12,
        md: 6,
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontWeight: 500,
          pb: 2,
        }}
      >
        Commitment manager
      </Typography>
      <Stack>
        <Typography
          sx={{
            color: "text.secondary",
            pb: 3,
          }}
        >
          Compare your current cloud spend to your planned spend, all tracked along the baseline of your contracted
          commit spend.
        </Typography>
        {homeBullets.map(({ bold, text }, index) => (
          <Typography
            key={index}
            variant="body1"
            sx={{
              color: "text.secondary",
              display: "list-item",
              listStyleType: "disc",
              marginLeft: 2,
              pb: 1,
              listStylePosition: "inside",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                display: "inline",
                mr: 1,
              }}
            >
              {bold}
            </Typography>
            {text}
          </Typography>
        ))}

        <Button
          component={NavLink}
          variant="contained"
          to="./commitment-manager/create"
          sx={{ mt: 2, width: "fit-content" }}
        >
          Create commitment plan
        </Button>
      </Stack>
    </Grid>

    <Grid
      size={{
        sm: 12,
        md: 6,
      }}
    >
      <img src={CommitmentManagerGraphic} alt="Commitment manager graphic" />
    </Grid>
  </Grid>
);
