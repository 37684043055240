import { type ChangeEvent, type ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import {
  type MasterPayerAccountsFeatures,
  type MasterPayerAccountsModel,
  type Services,
  type ServiceTier,
  type ServiceType,
  type Support,
  type SupportModel,
  type SupportTier,
} from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { type AlertColor } from "@mui/material/Alert";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import { CustomerPicker } from "../../../Components/CustomerPicker/CustomerPicker";
import { type CustomerDetails } from "../../../Components/CustomerPicker/useAlgoliaCustomerSearch";
import { useCountries } from "../../../Components/hooks/useCountries";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../../Components/SimpleDialog";
import { isNordia, isProduction } from "../../../constants";
import { useAuthContext } from "../../../Context/AuthContext";
import { type Customer } from "../../../types";
import { TimestampFromDate } from "../../../utils/firebase";
import accountNumberExists from "../accountNumberExists";
import { availableSupportTiers, availableSupportTiersLabels, serviceTypes, supportModels } from "../consts";
import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";
import { UpdatePendingMasterPayerAccountError } from "./EditPendingMPADialog";
import { getServiceTiers, getServiceTypeLabel } from "./utils";

export type MpaSubmitObject = Pick<
  MasterPayerAccountsModel,
  | "customerId"
  | "support"
  | "features"
  | "status"
  | "requestedBy"
  | "expectedOnboardingDate"
  | "domain"
  | "rootEmail"
  | "accountNumber"
  | "tags"
  | "existingCustomer"
  | "existingCustomerTier"
  | "services"
>;

export type NewMpaDialogProps<OnSubmitResult> = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (submitObject: MpaSubmitObject) => Promise<OnSubmitResult>;
  masterPayerAccounts?: MasterPayerAccountsModelSnapshot[];
  confirmButtonCy?: string;
  masterPayerAccount?: ModelId<MasterPayerAccountsModel>;
  successMessage: ReactNode | ((mpa: OnSubmitResult) => ReactNode);
  successVariant?: AlertColor | ((mpa: OnSubmitResult) => AlertColor);
  failMessage: ReactNode;
  forCustomer?: Customer;
};

const NewMpaDialog = <T,>({
  open,
  onCancel,
  onSubmit,
  masterPayerAccounts,
  confirmButtonCy,
  masterPayerAccount,
  successMessage,
  successVariant = "success",
  failMessage,
  forCustomer,
}: NewMpaDialogProps<T>) => {
  const [customerId, setCustomerId] = useState<string>("");
  const [customer, setCustomer] = useState<Partial<CustomerDetails> | null>();

  const [supportModel, setSupportModel] = useState<SupportModel | "">("");
  const [supportTier, setSupportTier] = useState<SupportTier | "">("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [validAccountNumber, setValidAccountNumber] = useState<boolean>(false);
  const [invalidAccountReason, setInvalidAccountReason] = useState<string>("");
  const [isAccountNumberBlurred, setIsAccountNumberBlurred] = useState<boolean>(false);
  const [awsCaseIdSupportTicketUrl, setAwsCaseIdSupportTicketUrl] = useState<string>("");
  const [tamLocation, setTamLocation] = useState<string>("");
  const [enterpriseSupportStart, setEnterpriseSupportStart] = useState<Date | null>(null);
  const [includeMemberAccounts, setIncludeMemberAccounts] = useState<boolean>(true);
  const [organizationImport, setOrganizationImport] = useState<boolean>(true);
  const [noImportOrgReason, setNoImportOrgReason] = useState<string>("");
  const [edpPpa, setEdpPpa] = useState<boolean>(false);
  const [noRootAccess, setNoRootAccess] = useState<boolean>(false);
  const [activateCredits, setActivateCredits] = useState<boolean>(false);
  const [existingCustomer, setExistingCustomer] = useState<boolean>(false);
  const [existingCustomerTier, setExistingCustomerTier] = useState<string>("");
  const [services, setServices] = useState<Services[]>([]);
  const [nraExpectedMrr, setNraExpectedMrr] = useState<number>(0);
  const [nraLinkToMaterials, setNraLinkToMaterials] = useState<string>("");
  const [expectedOnboardingDate, setExpectedOnboardingDate] = useState<Date | null>(null);
  const [domain, setCustomerPrimaryDomain] = useState<string>("");

  const [title, setTitle] = useState("Create new MPA");
  const [awsTicketUrlIsValid, setAwsTicketUrlIsValid] = useState<boolean>(false);
  const [confirmButtonText, setConfirmButtonText] = useState<string>("Create");

  const [infoAlertVisible, setInfoAlertVisible] = useState<boolean>(!masterPayerAccount);
  const [warnAlertVisible, setWarnAlertVisible] = useState<boolean>(masterPayerAccount?.status === "active");

  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const snackbar = useSnackbar();

  const { countries, countriesLoading } = useCountries();
  const countriesTextList = useMemo(() => countries.map((country) => country.name).sort(), [countries]);

  const supportTiersWithIncludesMemberAccounts = useMemo(() => ["business", "enterprise"], []);

  // Has the MPA already been activated? (cannot be activated from this dialog)
  const isActiveMpa = masterPayerAccount?.status === "active";

  const isAccountNumberRequired = organizationImport || masterPayerAccount?.status === "pending";
  const allFieldsFilled = customerId && supportModel && supportTier && (isAccountNumberRequired ? accountNumber : true);
  const validateImportOrg =
    isActiveMpa || organizationImport || (!organizationImport && noImportOrgReason.trim() !== "");
  const validateNra =
    !noRootAccess ||
    masterPayerAccount?.status === "active" || // old record, already active
    (nraExpectedMrr > 0 && nraLinkToMaterials.trim() !== "");
  const validateEnterprise = supportTier !== "enterprise" || (tamLocation !== "" && enterpriseSupportStart !== null);
  const validateUpdateSupportModelTier =
    (awsTicketUrlIsValid && masterPayerAccount?.status === "active") || masterPayerAccount?.status !== "active";
  const disableConfirm =
    !allFieldsFilled ||
    !validateImportOrg ||
    !validateNra ||
    !validateEnterprise ||
    !validateUpdateSupportModelTier ||
    (isAccountNumberRequired && !validAccountNumber);

  const clearFields = () => {
    setCustomerId("");
    setCustomer(undefined);
    setSupportModel("");
    setSupportTier("");
    setAccountNumber("");
    setAwsCaseIdSupportTicketUrl("");
    setAwsTicketUrlIsValid(false);
    setIncludeMemberAccounts(false);
    setTamLocation("");
    setEnterpriseSupportStart(null);
    setExpectedOnboardingDate(null);
    setOrganizationImport(true); // default state is true/checked.
    setNoImportOrgReason("");
    setEdpPpa(false);
    setNoRootAccess(false);
    setActivateCredits(false);
    setNraExpectedMrr(0);
    setNraLinkToMaterials("");
    setExistingCustomer(false);
    setExistingCustomerTier("");
    setServices([]);
  };

  const rootEmail = useMemo<string>(() => {
    const domainCount = masterPayerAccounts?.filter((mpa) => mpa.domain === domain).length ?? 0;
    const alias = domainCount > 0 ? `+${domainCount}` : "";
    return `mpa.${domain.replaceAll(".", "-")}${alias}@doit.com`;
  }, [domain, masterPayerAccounts]);

  // handles validation and submit to DB
  const onConfirm = useCallback(async () => {
    const support: Support = {
      tier: supportTier ? supportTier : "basic",
      model: supportModel ? supportModel : "resold",
    };
    if (supportTiersWithIncludesMemberAccounts.includes(supportTier ?? "")) {
      support.includeMemberAccounts = includeMemberAccounts;
    }
    if (supportTier === "enterprise") {
      support.supportStart = enterpriseSupportStart ? TimestampFromDate(new Date(enterpriseSupportStart)) : undefined;
      support.enterpriseTAMLocation = tamLocation;
    }
    if (masterPayerAccount?.status === "active") {
      support.awsCaseIdSupportTicketUrl = awsCaseIdSupportTicketUrl;
    }

    const features: MasterPayerAccountsFeatures = {
      "no-root-access": noRootAccess,
      "import-org": organizationImport,
      "edp-ppa": edpPpa,
    };
    if (!organizationImport) {
      features["no-import-org-reason"] = noImportOrgReason;
    }
    if (noRootAccess) {
      features["nra-expected-mrr"] = nraExpectedMrr;
      features["nra-link-to-materials"] = nraLinkToMaterials;
    }

    const submitObject: MpaSubmitObject = {
      customerId,
      domain,
      expectedOnboardingDate: expectedOnboardingDate ? TimestampFromDate(new Date(expectedOnboardingDate)) : undefined,
      support,
      features,
      status: "pending",
      requestedBy: currentUser.email,
      rootEmail,
      accountNumber,
      tags: activateCredits ? ["aws/credits/activate"] : [],
      existingCustomer,
      existingCustomerTier,
      services,
    };

    try {
      const onSubmitResult = await onSubmit(submitObject);
      clearFields();

      snackbar.onOpen({
        autoHideDuration: 3000,
        message: successMessage instanceof Function ? successMessage(onSubmitResult) : successMessage,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        withClose: true,
        variant: successVariant instanceof Function ? successVariant(onSubmitResult) : successVariant,
      });
    } catch (error) {
      // only open an "error" snackbar. The error is logged when the DB call fails.
      snackbar.onOpen({
        autoHideDuration: 3000,
        message: error instanceof UpdatePendingMasterPayerAccountError ? error.message : failMessage,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        withClose: true,
        variant: "error",
      });
    }
  }, [
    supportTier,
    supportModel,
    supportTiersWithIncludesMemberAccounts,
    masterPayerAccount?.status,
    noRootAccess,
    organizationImport,
    edpPpa,
    customerId,
    domain,
    expectedOnboardingDate,
    currentUser.email,
    rootEmail,
    accountNumber,
    activateCredits,
    existingCustomer,
    existingCustomerTier,
    services,
    includeMemberAccounts,
    enterpriseSupportStart,
    tamLocation,
    awsCaseIdSupportTicketUrl,
    noImportOrgReason,
    nraExpectedMrr,
    nraLinkToMaterials,
    onSubmit,
    snackbar,
    successMessage,
    successVariant,
    failMessage,
  ]);

  useEffect(() => {
    setCustomerId(customer?.objectID ?? "");
    setCustomerPrimaryDomain(customer?.primaryDomain ?? "");
  }, [customer]);

  useEffect(() => {
    if (forCustomer) {
      setCustomerId(forCustomer.id);
      setCustomerPrimaryDomain(forCustomer.primaryDomain);
    }
  }, [forCustomer]);

  useEffect(() => {
    if (edpPpa) {
      setOrganizationImport(false);
      setNoRootAccess(false);
      setActivateCredits(false);
    }
  }, [edpPpa]);

  useEffect(() => {
    if (!masterPayerAccount) {
      setConfirmButtonText("Create");
    } else {
      setConfirmButtonText(masterPayerAccount?.status === "active" ? "Update" : "Modify");
    }
  }, [masterPayerAccount]);

  useEffect(() => {
    if (masterPayerAccount) {
      setCustomerId(masterPayerAccount.customerId);
      setCustomerPrimaryDomain(masterPayerAccount?.domain ?? "");
      setCustomer(undefined); // not applicable when EDIT mode begins, so set as empty

      setSupportModel(masterPayerAccount.support.model);
      setSupportTier(masterPayerAccount.support.tier);
      setAccountNumber(masterPayerAccount.accountNumber);
      setTamLocation(masterPayerAccount.support.enterpriseTAMLocation ?? "");
      setIncludeMemberAccounts(masterPayerAccount.support.includeMemberAccounts ?? false);
      setOrganizationImport(masterPayerAccount.features?.["import-org"] === true);
      setNoImportOrgReason(masterPayerAccount.features?.["no-import-org-reason"] ?? "");
      setEdpPpa(masterPayerAccount.features?.["edp-ppa"] === true);
      setNoRootAccess(masterPayerAccount.features?.["no-root-access"] === true);
      setActivateCredits(masterPayerAccount.tags?.includes("aws/credits/activate") ?? false);
      setExistingCustomer(masterPayerAccount.existingCustomer ?? false);
      setExistingCustomerTier(masterPayerAccount.existingCustomerTier ?? "");
      setServices(masterPayerAccount.services ?? []);
      switch (masterPayerAccount.status) {
        case "active":
          setTitle(`Update support details for ${masterPayerAccount.name}`);
          break;
        case "pending":
          setTitle(`Edit MPA for ${masterPayerAccount.friendlyName}`);
          break;
        default:
          setTitle(``);
      }

      setNraExpectedMrr(masterPayerAccount.features?.["nra-expected-mrr"] ?? 0);
      setNraLinkToMaterials(masterPayerAccount.features?.["nra-link-to-materials"] ?? "");

      // this is an optional field, allow NULL use-cases
      // linter doesn't like checking for nulls and doing math on the same line
      if (masterPayerAccount.expectedOnboardingDate?.seconds) {
        setExpectedOnboardingDate(new Date(masterPayerAccount.expectedOnboardingDate?.seconds * 1000));
      } else {
        setExpectedOnboardingDate(null);
      }

      if (masterPayerAccount?.support?.supportStart?.seconds) {
        const utcInputDate = DateTime.fromMillis(masterPayerAccount?.support?.supportStart?.seconds * 1000)
          .toUTC()
          .toJSDate();
        setEnterpriseSupportStart(utcInputDate);
      } else {
        setEnterpriseSupportStart(null);
      }
    }
  }, [masterPayerAccount]);

  useEffect(() => {
    const validateAccountNumber = async (accountNumber: string) => {
      const validFormat = /^\d{12}$/.test(accountNumber);
      if (!validFormat) {
        setInvalidAccountReason("Invalid account number format");
        setValidAccountNumber(false);
        return;
      }

      const excludeId = masterPayerAccount?.id ?? null;
      const exists = await accountNumberExists(accountNumber, excludeId);
      setInvalidAccountReason(exists ? "Account number already exists" : "");
      setValidAccountNumber(!exists);
    };

    isAccountNumberRequired && validateAccountNumber(accountNumber);
  }, [isAccountNumberRequired, accountNumber, masterPayerAccount?.id]);

  // from the Checkbox documentation
  const updateOrganizationImport = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value === "true") {
      setEdpPpa(false);
    }
    setOrganizationImport(event.target.value === "true");
  }, []);
  const updateEdpPpA = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setEdpPpa(event.target.checked);
  }, []);
  const updateNoRootAccess = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setNoRootAccess(event.target.checked);
  }, []);
  const updateActivateCredits = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setActivateCredits(event.target.checked);
  }, []);
  const updateExistingCustomer = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setExistingCustomer(event.target.checked);
  }, []);

  const updateIncludeMemberAccounts = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setIncludeMemberAccounts(event.target.checked);
  }, []);

  const updateServiceType = useCallback((event: React.ChangeEvent<HTMLInputElement>, serviceType: ServiceType) => {
    setServices((prevServices) => {
      const serviceIndex = prevServices.findIndex((service) => service.type === serviceType);

      if (serviceIndex > -1) {
        const newServices = [...prevServices];
        newServices.splice(serviceIndex, 1);
        return newServices;
      }

      return [...prevServices, { type: serviceType, tier: null }];
    });
  }, []);

  const handleServiceTier = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, serviceType: ServiceType) => {
      const serviceTier: ServiceTier = e.target.value as ServiceTier;
      setServices((prevServices) =>
        prevServices.map((item) => (item.type === serviceType ? { ...item, tier: serviceTier } : item))
      );
    },
    []
  );

  const infoAlertBody =
    isNordia || isProduction ? (
      <>
        By requesting a new MPA, the following actions will be executed:
        <ul>
          <li>Google Group for root account email will be created</li>
          <li>1password entry will be created under "AWS MPAs" vault</li>
          <li>gCRE request will be sent to the onboarding team</li>
          <li>If you select NRA, the finance team will be notified</li>
        </ul>
      </>
    ) : (
      <>
        By requesting a new MPA, the following actions will be executed:
        <ul>
          <li>No action will be taken in the development environment</li>
        </ul>
      </>
    );

  const warnAlertBody = <>You can only change the support model/tier here if you've made the same change on AWS APN.</>;

  const validateAwsTicketUrl = (caseTicketUrl: string): boolean =>
    caseTicketUrl.toLowerCase().indexOf("https://partnercentral.awspartner.com") === 0;

  const handleAwsCaseTicketChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setAwsCaseIdSupportTicketUrl(e.target.value);
  }, []);

  const handleCloseWarning = useCallback((): void => {
    setWarnAlertVisible(false);
  }, []);

  useEffect(() => {
    setAwsTicketUrlIsValid(validateAwsTicketUrl(awsCaseIdSupportTicketUrl));
  }, [awsCaseIdSupportTicketUrl]);

  const checkedServices = (service: ServiceType): boolean => services.some((item) => item.type === service);

  const newMpaInputForm = (
    <Box sx={{ maxHeight: 480, overflow: "auto" }}>
      <Stack spacing={2} sx={{ mx: 3, my: 2 }} data-cy="new-mpa-dialog">
        {infoAlertVisible && (
          <Alert
            variant="standard"
            severity="info"
            data-cy="new-mpa-dialog-infoalert"
            onClose={() => {
              setInfoAlertVisible(false);
            }}
          >
            {infoAlertBody}
          </Alert>
        )}
        {(!masterPayerAccount || masterPayerAccount?.status === "pending") && (
          <CustomerPicker
            value={customerId}
            onChange={(_event, newCustomer) => {
              setCustomer(newCustomer);
            }}
            getOptionLabel={({ primaryDomain, hasActiveBillingProfile }) =>
              hasActiveBillingProfile ? primaryDomain : `${primaryDomain} (inactive)`
            }
            getOptionDisabled={({ hasActiveBillingProfile }) => !hasActiveBillingProfile}
            TextFieldProps={{
              label: "Domain",
              size: "small",
              required: true,
              variant: "outlined",
              InputLabelProps: { shrink: true },
            }}
            data-cy="new-mpa-dialog-domain-autocomplete"
          />
        )}

        <TextField
          required={true}
          label="Support model"
          value={supportModel ?? ""}
          onChange={(e) => {
            setSupportModel(e.target.value as SupportModel);
          }}
          select
          size="small"
          defaultValue=""
          data-cy="new-mpa-dialog-select-support-model"
          slotProps={{
            inputLabel: { shrink: true },
          }}
        >
          {supportModels.map((item) => (
            <MenuItem key={item} value={item}>
              {capitalize(item)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          required={true}
          label="Support tier"
          value={supportTier}
          onChange={(e) => {
            setSupportTier(e.target.value as SupportTier);
          }}
          select
          size="small"
          defaultValue=""
          data-cy="new-mpa-dialog-select-support-tier"
          slotProps={{
            inputLabel: { shrink: true },
          }}
        >
          {supportModel ? (
            availableSupportTiers[supportModel]?.map((item) => (
              <MenuItem key={item} value={item}>
                {availableSupportTiersLabels[item] ?? capitalize(item?.toString())}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>Please select a Support Model.</MenuItem>
          )}
        </TextField>

        {masterPayerAccount?.status === "active" && (
          <TextField
            required={true}
            label="AWS Case ID ticket URL"
            placeholder="https://partnercentral.awspartner.com/..."
            value={awsCaseIdSupportTicketUrl}
            error={!awsTicketUrlIsValid && awsCaseIdSupportTicketUrl !== ""}
            onChange={handleAwsCaseTicketChange}
            size="small"
            helperText="Must be a valid URL"
            data-cy="new-mpa-dialog-aws-ticket-url"
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
        )}

        {supportTier === "enterprise" && (
          <>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Autocomplete
                id="tam-location"
                autoSelect
                filterSelectedOptions
                loading={countriesLoading}
                options={countriesTextList}
                value={tamLocation}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(_event, newValue) => {
                  newValue ? setTamLocation(newValue) : setTamLocation("");
                }}
                sx={{ flexGrow: 3, mr: 1 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={true}
                    label="TAM location"
                    size="small"
                    data-cy="new-mpa-dialog-enterpriseTAMLocation"
                    slotProps={{
                      input: {
                        ...params.InputProps,
                      },

                      inputLabel: { shrink: true },
                    }}
                  />
                )}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  renderInput={(enterpriseStartSupportTextFieldProps) => (
                    <TextField
                      {...enterpriseStartSupportTextFieldProps}
                      data-cy="new-mpa-dialog-enterpriseStartSupport"
                      required={true}
                      sx={{ flexGrow: 1 }}
                    />
                  )}
                  label="Support start date"
                  value={enterpriseSupportStart}
                  onChange={(newValue) => {
                    setEnterpriseSupportStart(newValue);
                  }}
                />
              </LocalizationProvider>
            </Box>
          </>
        )}

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            ampm={false}
            renderInput={(onboardingTimeTextFieldProps) => (
              <TextField
                {...onboardingTimeTextFieldProps}
                helperText="If known, enter the date and time of your scheduled customer onboarding call"
                data-cy="new-mpa-dialog-expectedOnboarding"
              />
            )}
            label="Expected onboarding date and time"
            value={expectedOnboardingDate}
            onChange={(newValue) => {
              setExpectedOnboardingDate(newValue);
            }}
          />
        </LocalizationProvider>

        {supportTiersWithIncludesMemberAccounts.includes(supportTier ?? "") && (
          <FormControlLabel
            data-cy="new-mpa-dialog-includeMemberAccounts"
            control={<Checkbox onChange={updateIncludeMemberAccounts} checked={includeMemberAccounts} />}
            label="Apply the above Support tier to all member accounts"
          />
        )}

        {!isActiveMpa && (
          <>
            <RadioGroup
              data-cy="new-mpa-dialog-orgimport"
              name="orgimport"
              value={organizationImport}
              onChange={updateOrganizationImport}
            >
              <FormLabel>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "text.primary",
                  }}
                >
                  Consolidation type
                </Typography>
              </FormLabel>
              <FormControlLabel sx={{ ml: 0 }} value={true} control={<Radio />} label="Organization import" />
              <FormControlLabel
                sx={{ ml: 0 }}
                value={false}
                control={<Radio />}
                label="Non-org import / Create new MPA"
              />
            </RadioGroup>
          </>
        )}

        {!isActiveMpa && !organizationImport && (
          <TextField
            required={true}
            label="Reason for not importing organization"
            value={noImportOrgReason}
            onChange={(e) => {
              setNoImportOrgReason(e.target.value);
            }}
            size="small"
            data-cy="new-mpa-dialog-no-org-import-reason"
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
        )}

        {isAccountNumberRequired && (
          <TextField
            required={true}
            label="AWS Account ID"
            value={accountNumber}
            onBlur={() => {
              setIsAccountNumberBlurred(true);
            }}
            error={isAccountNumberBlurred && !validAccountNumber}
            helperText={isAccountNumberBlurred ? invalidAccountReason : ""}
            onChange={(e) => {
              setAccountNumber(e.target.value);
            }}
            size="small"
            data-cy="new-mpa-dialog-select-aws-account-id"
            slotProps={{
              inputLabel: { shrink: true },
            }}
          />
        )}

        {!isActiveMpa && (
          <>
            <FormControlLabel
              data-cy="new-mpa-dialog-edpppa"
              control={
                <Checkbox onChange={updateEdpPpA} checked={edpPpa} disabled={organizationImport || noRootAccess} />
              }
              label={
                <>
                  <Typography variant="subtitle1" sx={{ mb: -1 }}>
                    Provisional MPA for EDP/PPA Contract Drafting
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    Warning - do not commit Account ID before a pre consolidation call
                  </Typography>
                </>
              }
            />
            <FormControlLabel
              data-cy="new-mpa-dialog-nra"
              control={<Checkbox onChange={updateNoRootAccess} checked={noRootAccess} disabled={edpPpa} />}
              label="No root access"
            />
            <FormControlLabel
              control={<Checkbox onChange={updateActivateCredits} checked={activateCredits} />}
              label="Activate credits"
              data-cy="new-mpa-dialog-activate-credits"
            />
          </>
        )}

        <FormControlLabel
          control={<Checkbox onChange={updateExistingCustomer} checked={existingCustomer} />}
          label="Existing customer"
          data-cy="new-mpa-dialog-existing-customer"
        />
        {existingCustomer && (
          <TextField
            sx={{ pl: 4, mt: -1 }}
            required={true}
            label="Service tier"
            value={existingCustomerTier}
            onChange={(e) => {
              setExistingCustomerTier(e.target.value);
            }}
            size="small"
            data-cy="new-mpa-dialog-existing-customer-tier"
            helperText="What kind of customer are they already?"
            slotProps={{
              inputLabel: { shrink: true, sx: { pl: 5.5 } },
            }}
          />
        )}

        <Stack>
          <Typography
            variant="subtitle2"
            sx={{
              color: "text.primary",
            }}
          >
            DoiT Plans
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "text.primary",
            }}
          >
            Select the plans the customer will sign up for
          </Typography>
        </Stack>
        {serviceTypes.map((service) => (
          <Stack
            key={service}
            spacing={2}
            sx={{
              pl: 1.5,
            }}
          >
            <Box sx={{ ml: -2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => {
                      updateServiceType(event, service);
                    }}
                    checked={checkedServices(service)}
                  />
                }
                label={getServiceTypeLabel(service)}
                data-cy={`new-mpa-dialog-${service}`}
              />
              <FormHelperText sx={{ pl: 4, mt: -1 }}>
                Is this a DoiT Cloud {capitalize(service)} customer?
              </FormHelperText>
            </Box>

            {checkedServices(service) && service !== "advantage" && (
              <TextField
                sx={{ pl: 4, mt: -1 }}
                required={true}
                label="Service tier"
                onChange={(e) => {
                  handleServiceTier(e, service);
                }}
                select
                size="small"
                defaultValue=""
                data-cy="new-mpa-dialog-select-service-tier"
                key={service}
                value={services.find((item) => item.type === service)?.tier ?? ""}
                slotProps={{
                  inputLabel: { shrink: true, sx: { pl: 5.5 } },
                }}
              >
                {getServiceTiers(service).map((item) => (
                  <MenuItem key={`${item}${service}`} value={item?.toString()}>
                    {capitalize(item?.toString())}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Stack>
        ))}

        {!isActiveMpa && noRootAccess && (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <TextField
              required={true}
              label="Expected monthly recurring revenue"
              value={nraExpectedMrr}
              type="number"
              onChange={(e) => {
                setNraExpectedMrr(parseInt(e.target.value));
              }}
              size="small"
              data-cy="new-mpa-dialog-mrr-expected"
              sx={{ flexGrow: 1, mr: 1 }}
              slotProps={{
                inputLabel: { shrink: true },
              }}
            />
            <TextField
              required={true}
              label="Link to supporting materials"
              value={nraLinkToMaterials}
              onChange={(e) => {
                setNraLinkToMaterials(e.target.value);
              }}
              placeholder="https://drive.google.com/file/d/xxxxxx"
              size="small"
              data-cy="new-mpa-dialog-mrr-supporting-materials-link"
              sx={{ flexGrow: 3 }}
              slotProps={{
                inputLabel: { shrink: true },
              }}
            />
          </Box>
        )}
        {warnAlertVisible && masterPayerAccount?.status === "active" && (
          <Alert
            variant="standard"
            severity="warning"
            data-cy="new-mpa-dialog-aws-warnalert"
            onClose={handleCloseWarning}
          >
            {warnAlertBody}
          </Alert>
        )}
      </Stack>
    </Box>
  );
  return (
    <SimpleDialog
      open={open}
      title={title}
      confirmButtonText={confirmButtonText}
      cancelButtonText="Cancel"
      cancelButtonColor="primary"
      onConfirm={onConfirm}
      onCancel={() => {
        // clear fields if exiting from EDIT mode only
        if (masterPayerAccount) {
          clearFields();
        }
        onCancel();
      }}
      dialogProps={{ fullWidth: true }}
      disableConfirm={disableConfirm}
      data-cy="new-mpa-dialog-simpledialog"
      confirmButtonCy={confirmButtonCy}
    >
      {newMpaInputForm}
    </SimpleDialog>
  );
};

export default NewMpaDialog;
