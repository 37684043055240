import { collection, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type CustomerModel } from "./Customer";

export enum ZendeskTicketStatus {
  NEW = "new",
  OPEN = "open",
  PENDING = "pending",
  HOLD = "hold",
  SOLVED = "solved",
  CLOSED = "closed",
}

export enum ZendeskPriority {
  URGENT = "urgent",
  HIGH = "high",
  NORMAL = "normal",
  LOW = "low",
}

export type SatisfactionRating =
  | "offered"
  | "unoffered"
  | {
      score: "good" | "bad";
      comment: string;
    };

@subCollection("tickets")
export class SupportZendeskTicketModel {
  customerRef!: Reference<CustomerModel>;

  firestoreAddedDate!: Timestamp;

  firestoreUpdatedDate!: Timestamp;

  sandbox!: boolean;

  ticket!: {
    id: number;
    subject: string;
    description: string;
    status: ZendeskTicketStatus;
    priority: ZendeskPriority;
    created_at: Timestamp;
    updated_at: Timestamp;
    requester_id: number;
    assignee_id: number | null;
    organization_id: number;
    collaborator_ids: number[];
    email_cc_ids: number[];
    is_public: boolean;
    tags: string[];
    fields: {
      id: number;
      value: string;
    }[];
    satisfaction_rating: SatisfactionRating | null;
  };

  zendeskId!: number;
}

@collection("support")
export class SupportZendeskModel {
  subCollections?: {
    tickets: SupportZendeskTicketModel;
  };
}
