import { useCallback, useMemo, useState } from "react";

import { CustomerModel, DashboardModel, type DashboardRowHeight, type DashboardType } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { arrayMoveImmutable } from "array-move";
import findIndex from "lodash/findIndex";

import { getDashboardType } from "../Components/Dashboard/utils/widgetUtils";
import { GlobalDashboardId } from "../utils/common";
import { filterExcludeWidgetsIfStandaloneCustomer } from "../utils/widgets";
import { useAuthContext } from "./AuthContext";
import { useCustomerContext } from "./CustomerContext";
import { useInitGCPLensOrAWSLens } from "./Dashboards/InitGCPOrAWSLens";
import { useCachedHook } from "./DynamicContext/useCachedHook";
import { useIsFeatureEntitled } from "./TierProvider";
import { type Dashboard, type DashboardsContextType, useDashboardsContext } from "./useDashboardsContext";

export type CurrentDashboardContextType = {
  dashboardType?: DashboardType | null;
  dashboardIndex: number;
  currentDashboard?: DashboardsContextType["dashboards"][number];
  widgets: NonNullable<DashboardsContextType["dashboards"][number]["widgets"]>;
  clearDashboardWidgets: () => Promise<void>;
  moveWidget: (activeName: string, overName: string) => Promise<void>;
  removeWidgetFromDashboard: (widgetId: string) => Promise<void>;
  addWidgetToDashboard: (widgetId: string) => Promise<void>;
  changeWidgetWidth: (widgetId: string, width: number) => Promise<void>;
  changeDashboardByName: (name: string) => Promise<void>;
  isAllowToEditDashboard: boolean;
  isCustomDashboard: boolean;
  updateWidgets(widgets: Widget[]): Promise<void>;
  updateHeights(heights: DashboardRowHeight[]): Promise<void>;
};

export type Widget = DashboardsContextType["dashboards"][number]["widgets"][number];

const useNonCachedCurrentDashboardContext = () => {
  const { customer, isProductOnlyCustomer } = useCustomerContext();

  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const {
    dashboards,
    attachDashboard,
    defaultDashboard,
    removeWidgetFromDashboard: removeWidgetFromDashboardById,
    addWidgetToDashboard: addWidgetToDashboardById,
    clearDashboardWidgets: clearDashboardWidgetsById,
  } = useDashboardsContext();
  const [dashboardIndex, setDashboardIndex] = useState(-1);
  const isEntitledDashboardCustom = !!useIsFeatureEntitled("dashboard:custom");

  const currentDashboard = useMemo(
    () => (dashboardIndex >= 0 && dashboardIndex < dashboards.length ? dashboards[dashboardIndex] : undefined),
    [dashboardIndex, dashboards]
  );

  useInitGCPLensOrAWSLens(currentDashboard);

  const widgets = useMemo(
    () =>
      currentDashboard?.widgets?.filter((widget) =>
        filterExcludeWidgetsIfStandaloneCustomer(widget.name, isProductOnlyCustomer)
      ) ?? [],
    [currentDashboard?.widgets, isProductOnlyCustomer]
  );

  const dashboardId = useMemo(() => currentDashboard?.id, [currentDashboard?.id]);

  const newDashboardDocRef = useMemo(
    () =>
      dashboardId
        ? getCollection(DashboardModel)
            .doc("customization")
            .collection("users")
            .doc(currentUser.uid)
            .collection("duc")
            .doc(customer.id)
            .collection("dashboards")
            .doc(dashboardId)
        : undefined,
    [currentUser.uid, dashboardId, customer.id]
  );

  const publicDashboardsDocRef = useMemo(() => {
    if (!dashboardId) {
      return;
    }
    return getCollection(CustomerModel).doc(customer.id).collection("publicDashboards").doc(dashboardId);
  }, [dashboardId, customer.id]);

  const findDashboard = useCallback(
    (nameOrId: string) => findIndex<Dashboard>(dashboards, (d) => d.name === nameOrId || d.id === nameOrId),
    [dashboards]
  );

  const changeDashboardByName = useCallback(
    async (name: string) => {
      if (dashboards.length === 0) {
        return;
      }

      const dashboardName =
        name.localeCompare("default", undefined, { sensitivity: "base" }) === 0 ? defaultDashboard : name;

      const selectedDashboardIndex = dashboardName ? findDashboard(dashboardName) : 0;

      if (selectedDashboardIndex === -1) {
        const successAttached = await attachDashboard(name);
        if (successAttached) {
          return;
        }
      }

      setDashboardIndex(selectedDashboardIndex >= 0 ? selectedDashboardIndex : 0);
    },
    [dashboards.length, defaultDashboard, findDashboard, attachDashboard]
  );

  const moveWidget = useCallback(
    async (activeName: string, overName: string) => {
      const oldIndex = findIndex(widgets, { name: activeName });
      const newIndex = findIndex(widgets, { name: overName });
      const newWidgetsOrder = arrayMoveImmutable(widgets, oldIndex, newIndex);
      if (currentDashboard?.isPublic) {
        await publicDashboardsDocRef?.update({
          widgets: newWidgetsOrder,
        });

        return;
      }

      await newDashboardDocRef?.update({
        widgets: newWidgetsOrder,
      });
    },
    [currentDashboard, newDashboardDocRef, publicDashboardsDocRef, widgets]
  );

  const updateWidgets = useCallback(
    async (widgets: Widget[]) => {
      if (currentDashboard?.isPublic) {
        await publicDashboardsDocRef?.update({
          widgets,
        });
        return;
      }

      await newDashboardDocRef?.update({
        widgets,
      });
    },
    [currentDashboard, newDashboardDocRef, publicDashboardsDocRef]
  );

  const updateHeights = useCallback(
    async (heights: DashboardRowHeight[]) => {
      if (currentDashboard?.isPublic) {
        await publicDashboardsDocRef?.update({
          heights,
        });
        return;
      }

      await newDashboardDocRef?.update({
        heights,
      });
    },
    [currentDashboard, newDashboardDocRef, publicDashboardsDocRef]
  );

  const changeWidgetWidth = useCallback(
    async (widgetId: string, width: number) => {
      const updateWidgets = () =>
        widgets.map((widget) => ({
          ...widget,
          cardWidth: widgetId === widget.name ? width : widget.cardWidth,
        }));

      if (currentDashboard?.isPublic) {
        await publicDashboardsDocRef?.update({
          widgets: updateWidgets(),
        });

        return;
      }

      await newDashboardDocRef?.update({
        widgets: updateWidgets(),
      });
    },
    [currentDashboard?.isPublic, newDashboardDocRef, publicDashboardsDocRef, widgets]
  );

  const dashboardType = currentDashboard?.dashboardType;

  const isAllowToEditDashboard = useMemo(
    () =>
      !(
        currentDashboard &&
        !currentDashboard.allowToEdit &&
        currentDashboard.isPublic &&
        currentDashboard.ownerId !== currentUser.uid
      ) &&
      (currentDashboard?.id === GlobalDashboardId.Home || isEntitledDashboardCustom),
    [currentDashboard, currentUser.uid, isEntitledDashboardCustom]
  );

  const isCustomDashboard = useMemo(
    () =>
      !!(
        currentDashboard &&
        currentDashboard.id !== GlobalDashboardId.Home &&
        getDashboardType(currentDashboard.name) === currentDashboard.name
      ),
    [currentDashboard]
  );

  const removeWidgetFromDashboard = useCallback(
    async (name: string) => {
      if (!currentDashboard) {
        return;
      }
      await removeWidgetFromDashboardById(currentDashboard?.id, name);
    },
    [currentDashboard, removeWidgetFromDashboardById]
  );

  const addWidgetToDashboard = useCallback(
    async (name: string) => {
      if (!currentDashboard) {
        return;
      }
      await addWidgetToDashboardById(currentDashboard?.id, name);
    },
    [currentDashboard, addWidgetToDashboardById]
  );

  const clearDashboardWidgets = useCallback(async () => {
    if (!currentDashboard) {
      return;
    }
    await clearDashboardWidgetsById(currentDashboard?.id);
  }, [currentDashboard, clearDashboardWidgetsById]);

  return useMemo<CurrentDashboardContextType>(
    () => ({
      dashboardType,
      widgets,
      moveWidget,
      updateWidgets,
      updateHeights,
      currentDashboard,
      dashboardIndex,
      changeWidgetWidth,
      isAllowToEditDashboard,
      isCustomDashboard,
      removeWidgetFromDashboard,
      addWidgetToDashboard,
      changeDashboardByName,
      clearDashboardWidgets,
    }),
    [
      addWidgetToDashboard,
      changeDashboardByName,
      changeWidgetWidth,
      clearDashboardWidgets,
      currentDashboard,
      dashboardIndex,
      dashboardType,
      isAllowToEditDashboard,
      isCustomDashboard,
      moveWidget,
      updateWidgets,
      updateHeights,
      removeWidgetFromDashboard,
      widgets,
    ]
  );
};

export function useCurrentDashboardContext() {
  return useCachedHook(useNonCachedCurrentDashboardContext);
}
