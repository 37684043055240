import { useLocation } from "react-router-dom";
import { Box, Container, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useHideLoadingAnimation } from "../../Components/hooks/useHideLoadingAnimation";
import RandomLoadingMessage from "../../Components/RandomLoadingMessage";
import { useFullScreen } from "../../utils/dialog";
import { AllAuthForms } from "./components/AllAuthForms";
import FormSwitch from "./components/FormSwitch";
import LoginLogo from "./components/LoginLogo";
import { paths } from "./helpers";
import { useDynamicContent, useHandleEmailVerificationStatusFromQuery, useLoginForm } from "./hooks";
import { type LoginType } from "./types";

const Login = ({ loginType }: { loginType?: LoginType }) => {
  useHideLoadingAnimation();
  const location = useLocation();
  const { state, dispatch } = useLoginForm({ loginType });
  const content = useDynamicContent({ loginType });
  const { isMobile } = useFullScreen();

  useHandleEmailVerificationStatusFromQuery();

  if (location?.pathname === paths.loginImpersonate) {
    return <RandomLoadingMessage />;
  }

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Grid
        sx={{ height: "100vh", minWidth: { xs: "100%", sm: "auto" } }}
        size={{
          sm: 12,
          md: 4,
        }}
      >
        <Stack
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box sx={{ pt: { xs: 2, sm: 3 }, pl: { xs: 2, sm: 3 }, alignSelf: "flex-start" }}>
            <LoginLogo />
          </Box>
          <Container maxWidth="xs">
            <AllAuthForms state={state} dispatch={dispatch} />
          </Container>
          <Container maxWidth="xs">
            <FormSwitch state={state} dispatch={dispatch} />
          </Container>
        </Stack>
      </Grid>
      {!isMobile && <Grid size={8}>{content}</Grid>}
    </Grid>
  );
};

export default Login;
