import { type Dispatch, type FC, type ReactNode, useCallback } from "react";

import { ModelType, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { Form, Formik, useFormikContext } from "formik";
import noop from "lodash/noop";

import { FormChangesListener } from "../Common/FormChangesListener";
import { BooleanParam } from "./parameters/BooleanParam";
import { ListParam } from "./parameters/ListParam";
import { MapParam } from "./parameters/MapParam";
import { NumberParam } from "./parameters/NumberField";
import { StringParam } from "./parameters/StringParam";
import { StructureParam } from "./parameters/StructureParam";
import { TimestampParam } from "./parameters/TimestampParam";
import { getInitialValueForModel, useApiActionParametersSchema } from "./useApiActionParametersSchema";

export const GenericForm: FC<{
  fieldPath: string;
  inputModel: UnwrappedApiServiceModelDescriptor;
  label: string;
  onRemove?: () => void;
  disallowReferencedField?: boolean;
  renderAsNotRequired?: boolean;
  isListItem?: boolean;
}> = ({ inputModel, fieldPath, onRemove, label, disallowReferencedField, renderAsNotRequired, isListItem }) => {
  const formikProps = useFormikContext();

  const fieldProps = formikProps.getFieldProps(fieldPath);

  if (fieldProps.value === undefined) {
    return null;
  }

  switch (inputModel.type) {
    case ModelType.STRING:
      return (
        <StringParam
          inputModel={inputModel}
          fieldProps={fieldProps}
          label={label}
          onRemove={onRemove}
          disallowReferencedField={disallowReferencedField}
          renderAsNotRequired={renderAsNotRequired}
        />
      );

    case ModelType.TIMESTAMP:
      return (
        <TimestampParam
          inputModel={inputModel}
          fieldProps={fieldProps}
          label={label}
          onRemove={onRemove}
          disallowReferencedField={disallowReferencedField}
          renderAsNotRequired={renderAsNotRequired}
        />
      );

    case ModelType.INTEGER:
    case ModelType.FLOAT:
      return (
        <NumberParam
          inputModel={inputModel}
          fieldProps={fieldProps}
          label={label}
          onRemove={onRemove}
          disallowReferencedField={disallowReferencedField}
          renderAsNotRequired={renderAsNotRequired}
        />
      );

    case ModelType.BOOLEAN:
      return (
        <BooleanParam
          inputModel={inputModel}
          fieldProps={fieldProps}
          label={label}
          onRemove={onRemove}
          disallowReferencedField={disallowReferencedField}
          renderAsNotRequired={renderAsNotRequired}
        />
      );

    case ModelType.LIST:
      return (
        <ListParam
          fieldPath={fieldPath}
          fieldProps={fieldProps}
          label={label}
          inputModel={inputModel}
          onRemove={onRemove}
          isFormItem={renderAsNotRequired !== undefined}
        />
      );

    case ModelType.STRUCTURE:
      return (
        <StructureParam
          fieldPath={fieldPath}
          fieldProps={fieldProps}
          label={label}
          inputModel={inputModel}
          onRemove={onRemove}
          isListItem={isListItem}
        />
      );

    case ModelType.MAP:
      return (
        <MapParam
          fieldPath={fieldPath}
          fieldProps={fieldProps}
          label={label}
          inputModel={inputModel}
          onRemove={onRemove}
        />
      );

    default:
      return null;
  }
};

type ApiActionParametersFormProps = {
  inputModel: UnwrappedApiServiceModelDescriptor;
  values?: object;
  onValuesChange?: (parameters: unknown) => void;
  onValidityChange: Dispatch<boolean>;
  enableReinitialize?: boolean;
  children?: ReactNode;
};

export const ApiActionParametersForm: FC<ApiActionParametersFormProps> = ({
  inputModel,
  values,
  onValuesChange,
  onValidityChange,
  enableReinitialize = false,
  children,
}) => {
  const validationSchema = useApiActionParametersSchema(inputModel);

  const castAndDispatchValues = useCallback(
    (values: unknown) => {
      if (onValuesChange) {
        onValuesChange(
          validationSchema.cast(values, {
            context: {
              castingPhase: "outgoing",
            },
          })
        );
      }
    },
    [onValuesChange, validationSchema]
  );

  return (
    <Formik
      validateOnChange
      validateOnBlur
      validateOnMount
      enableReinitialize={enableReinitialize}
      initialValues={values ? validationSchema.cast(values) : getInitialValueForModel(inputModel)}
      validationSchema={validationSchema}
      onSubmit={noop}
    >
      <Form>
        {children}
        <FormChangesListener onValuesChange={castAndDispatchValues} onValidityChange={onValidityChange} />
      </Form>
    </Formik>
  );
};

export const GenericApiActionParametersForm: FC<ApiActionParametersFormProps> = (props) => (
  <ApiActionParametersForm {...props}>
    <GenericForm inputModel={props.inputModel} fieldPath="" label="" />
  </ApiActionParametersForm>
);
