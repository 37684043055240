import { type JSX } from "react";

import { Link } from "react-router-dom";
import { type BillingProfileStatus, DoitRole } from "@doitintl/cmp-models";
import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

import { CellsWrapper } from "../../Components/FilterTable/Toolbar/CellsWrapper";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { useDoitRoleCheck } from "../../Components/hooks/useDoitRoles";
import { menuItemKey, ThreeDotsMenu, type ThreeDotsMenuOption } from "../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../Context/AuthContext";
import { type Entity } from "../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { statusStyles } from "../../utils/common";
import { createLocationDescriptorWithReferrer, useGoToURLWithReferrer } from "../Entity/hooks";
import { paymentTypeColumnText } from "./BillingProfilesFilterTableColumns";

const cyData = (selector: string): string => `bpTableData-${selector}`;
const cyMenu = (selector: string): string => `bpTableMenu-${selector}`;

export const billingProfilesCyIds = {
  columns: {
    name: cyData("name"),
    id: cyData("priorityId"),
    currency: cyData("currency"),
    paymentMethod: cyData("paymentMethod"),
    status: cyData("status"),
    invoiceEmail: cyData("invoiceEmail"),
    threeDots: cyData("threeDots"),
  },
  menu: {
    edit: cyMenu("edit"),
    activate: cyMenu("activate"),
    deactivate: cyMenu("deactivate"),
    detach: cyMenu("detach"),
  },
};

type BillingProfilesTableRowProps = {
  entity: Entity;
  onActivateDeactivateClick: (entity: Entity) => void | Promise<void>;
  onDetachClick: (entity: Entity) => void | Promise<void>;
};

const BillingProfilesTableRow = ({
  entity,
  onActivateDeactivateClick,
  onDetachClick,
}: BillingProfilesTableRowProps) => {
  const { isDoitEmployee } = useAuthContext();
  const isDoitDeveloper = useDoitRoleCheck(DoitRole.Developers);

  const { customer } = useCustomerContext();
  const dark = useDarkThemeCheck();
  const entityEditFormPathname = `/customers/${customer.id}/entities/${entity.id}/edit`;
  const goToEntityEditForm = useGoToURLWithReferrer(entityEditFormPathname);

  const baseStatusStyles = statusStyles(dark);
  const billingProfileStatusStyles = {
    active: baseStatusStyles.green,
    inactive: baseStatusStyles.red,
  };

  const statusChip: Record<BillingProfileStatus, JSX.Element> = {
    active: (
      <Chip
        size="small"
        label="Active"
        sx={billingProfileStatusStyles.active}
        data-cy={`${billingProfilesCyIds.columns.status}-chip-${entity.priorityId}`}
      />
    ),
    inactive: (
      <Chip
        size="small"
        label="Inactive"
        sx={billingProfileStatusStyles.inactive}
        data-cy={`${billingProfilesCyIds.columns.status}-chip-${entity.priorityId}`}
      />
    ),
  };

  const permissionsCheckDeactivate = useDoitRoleCheck(DoitRole.BillingProfileAdmin);
  const menuOptions: ThreeDotsMenuOption[] = [
    // edit
    {
      label: <Typography variant="body2">Edit</Typography>,
      key: menuItemKey("EditBillingProfile", "1"),
      action: goToEntityEditForm,
      dataCy: `${billingProfilesCyIds.menu.edit}-${entity.priorityId}`,
    },
  ];

  // activate
  if (!entity.active && isDoitEmployee) {
    menuOptions.push({
      label: <Typography variant="body2">Activate</Typography>,
      key: menuItemKey("ActivateBillingProfile", "2"),
      action: () => onActivateDeactivateClick(entity),
      dataCy: `${billingProfilesCyIds.menu.activate}-${entity.priorityId}`,
    });
  }

  // deactivate - only with additional permission
  if (entity.active && permissionsCheckDeactivate) {
    menuOptions.push({
      label: (
        <Typography variant="body2" color="error">
          Deactivate
        </Typography>
      ),
      key: menuItemKey("deactivateBillingProfile", "2"),
      action: () => onActivateDeactivateClick(entity),
      dataCy: `${billingProfilesCyIds.menu.deactivate}-${entity.priorityId}`,
    });
  }

  // detach - delete billing profile
  if (isDoitDeveloper) {
    menuOptions.push({
      label: (
        <Typography variant="body2" color="error">
          Detach
        </Typography>
      ),
      key: menuItemKey("DetachBillingProfile", "3"),
      action: () => onDetachClick(entity),
      dataCy: `${billingProfilesCyIds.menu.detach}-${entity.priorityId}`,
    });
  }

  return (
    <CellsWrapper>
      <TableCell data-cy={`${billingProfilesCyIds.columns.name}-${entity.priorityId}`}>
        <Typography
          component={Link}
          to={createLocationDescriptorWithReferrer(entityEditFormPathname)}
          variant="body2"
          sx={{ textDecoration: "underline", color: "inherit" }}
        >
          {entity.name}
        </Typography>
      </TableCell>

      <TableCell
        data-cy={`${billingProfilesCyIds.columns.id}-${entity.priorityId}`}
        sx={{
          display: {
            xs: "none",
            sm: "table-cell",
          },
        }}
      >
        {entity.priorityId}
      </TableCell>
      <TableCell
        data-cy={`${billingProfilesCyIds.columns.currency}-${entity.priorityId}`}
        sx={{
          display: {
            xs: "none",
            sm: "table-cell",
          },
        }}
      >
        {entity.currency}
      </TableCell>
      <TableCell
        data-cy={`${billingProfilesCyIds.columns.paymentMethod}-${entity.priorityId}`}
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        {paymentTypeColumnText(entity.payment)}
      </TableCell>
      <TableCell
        data-cy={`${billingProfilesCyIds.columns.status}-${entity.priorityId}`}
        sx={{
          display: {
            xs: "none",
            lg: "table-cell",
          },
        }}
      >
        {statusChip[entity.active ? "active" : "inactive"]}
      </TableCell>
      <TableCell
        data-cy={`${billingProfilesCyIds.columns.invoiceEmail}-${entity.priorityId}`}
        sx={{
          display: {
            xs: "none",
            xl: "table-cell",
          },
        }}
      >
        {entity.contact?.email}
      </TableCell>
      <TableCell
        align="right"
        padding="none"
        data-cy={`${billingProfilesCyIds.columns.threeDots}-${entity.priorityId}`}
      >
        <ThreeDotsMenu options={menuOptions} size="small" />
      </TableCell>
    </CellsWrapper>
  );
};

export default BillingProfilesTableRow;
