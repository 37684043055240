import { useCallback, useEffect, useMemo, useState } from "react";

import { DashboardType } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import orderBy from "lodash/orderBy";

import { type SectionItem, SideNavigationMenu } from "../../../Components/Menu/SideNavigationMenu/SideNavigationMenu";
import { type Dashboard } from "../../../Context/useDashboardsContext";
import { getDashboardNameById } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { OrganizationDashboardsDialog } from "./Dialogs/OrganizationDashboardsDialog";
import { type DashboardOperations, useDashboardTypes } from "./useDashboardTypes";

type Props = {
  selectedDashboardId: string;
  disableNewDashboardCreation: boolean;
  onNavigateToDashboard: (name: string | number, interactive: boolean) => void;
  onNewDashboardClicked: () => void;
  onUpdateCurrentDashboardOperations: (operations: DashboardOperations) => void;
};

export function DashboardSideMenu({
  selectedDashboardId,
  disableNewDashboardCreation,
  onNavigateToDashboard,
  onNewDashboardClicked,
  onUpdateCurrentDashboardOperations,
}: Props) {
  const {
    preset,
    user,
    selectedOrganization,
    unselectedOrganization,
    currentDashboardOperations,
    updateOrganizations,
    currentDashboard,
  } = useDashboardTypes();

  const [openOrganizationsDialog, setOpenOrganizationsDialog] = useState(false);

  useEffect(() => {
    onUpdateCurrentDashboardOperations(currentDashboardOperations);
  }, [currentDashboardOperations, onUpdateCurrentDashboardOperations]);

  const itemsFromDashboards = useCallback(
    (dashboards: Dashboard[]) =>
      dashboards.map((dashboard) => ({
        name: getDashboardNameById(dashboard.id, dashboard.name),
        selected: dashboard.id === selectedDashboardId,
        onClick: () => {
          onNavigateToDashboard(dashboard.name, true);
        },
      })),
    [selectedDashboardId, onNavigateToDashboard]
  );

  const sortedPreset = useMemo(() => {
    const getOrderByType = (dashboard: Dashboard) => {
      switch (dashboard.dashboardType) {
        case DashboardType.Pulse:
          return 1;
        case DashboardType.AwsLens:
        case DashboardType.SaaSAwsLens:
          return 2;
        case DashboardType.GcpLens:
        case DashboardType.SaaSGcpLens:
          return 3;
        case DashboardType.BqLens:
          return 4;
        case DashboardType.GkeLensV2:
          return 5;
        case DashboardType.AzureLens:
          return 6;
        case DashboardType.EKSLens:
          return 7;
        case DashboardType.AWSMAPLens:
          return 8;
        default:
          return 9;
      }
    };
    return orderBy(preset, (dashboard) => getOrderByType(dashboard), ["asc"]);
  }, [preset]);

  const presetSection = useMemo(() => {
    const section: SectionItem = {
      name: "Preset dashboards",
      emptyItemsLabel: "No dashboards available",
      items: itemsFromDashboards(sortedPreset),
    };
    return section;
  }, [itemsFromDashboards, sortedPreset]);

  const userSection = useMemo(
    () => ({
      name: "My dashboards",
      emptyItemsLabel: "No dashboards created",
      sideIcon: <AddIcon />,
      sideIconOnClick: () => {
        mixpanel.track("dashboards.create-new-dashboard");
        onNewDashboardClicked();
      },
      sideIconDisabled: disableNewDashboardCreation,
      items: itemsFromDashboards(user),
    }),
    [user, itemsFromDashboards, onNewDashboardClicked, disableNewDashboardCreation]
  );

  const organizationSection = useMemo(
    () => ({
      name: "Organization dashboards",
      emptyItemsLabel: "No dashboards added",
      sideIcon: <SearchIcon />,
      sideIconOnClick: () => {
        mixpanel.track("dashboards.browse-org-dashboards");
        setOpenOrganizationsDialog(true);
      },
      items: itemsFromDashboards(selectedOrganization),
    }),
    [itemsFromDashboards, selectedOrganization]
  );

  return (
    <>
      {openOrganizationsDialog && (
        <OrganizationDashboardsDialog
          open={openOrganizationsDialog}
          onClose={() => {
            setOpenOrganizationsDialog(false);
          }}
          selected={selectedOrganization}
          unselected={unselectedOrganization}
          onSelectionChange={(newSelected: Dashboard[]) => {
            const currentInArray = (dashboards: Dashboard[]) =>
              dashboards.findIndex((dashboard) => dashboard.name === currentDashboard?.name) > -1;

            const currentDashboardInPreviousSelection = currentInArray(selectedOrganization);
            const currentDashboardInNewSelection = currentInArray(newSelected);

            if (currentDashboardInPreviousSelection && !currentDashboardInNewSelection) {
              onNavigateToDashboard(0, true);
            }

            return updateOrganizations(newSelected);
          }}
        />
      )}
      <SideNavigationMenu sections={[presetSection, userSection, organizationSection]} />
    </>
  );
}
