import { type FC, type ReactNode } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box, Collapse, IconButton, Stack, type SxProps, type Theme, Typography, useTheme } from "@mui/material";

import { useToggle } from "../../../../../../utils/useToggle";
import { GuidingLineConnector } from "../common/GuidingLineConnector";

const MARGIN_LEFT = 2;

export const Fieldset: FC<{
  children: ReactNode;
  label?: string;
  onRemove?: () => void;
  nestedInList?: boolean;
  isListItem?: boolean;
  isFormItem?: boolean;
}> = ({ children, label, onRemove, nestedInList, isListItem, isFormItem }) => {
  const theme = useTheme();
  const [expanded, , , toggleExpanded] = useToggle(true);

  if (!label) {
    return <>{children}</>;
  }

  const guidingLineStyles: SxProps<Theme> = {
    position: "absolute",
    bottom: theme.spacing(2),
    backgroundColor: "divider",
    opacity: expanded ? 1 : 0,
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
    }),
  };

  return (
    <Stack sx={{ position: "relative", top: isFormItem ? theme.spacing(-5) : 0, minWidth: 0, flexGrow: 1 }}>
      {isListItem && <GuidingLineConnector sx={{ top: 24 }} />}
      {nestedInList && (
        <Stack>
          <Box sx={{ width: "1px", top: theme.spacing(4), ...guidingLineStyles }} />
          <Box
            sx={{
              width: theme.spacing(MARGIN_LEFT),
              height: "1px",
              ...guidingLineStyles,
            }}
          />
        </Stack>
      )}
      <Stack direction="row" sx={{ height: 40 }} alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2" sx={{ pt: 0.25 }}>
          {label}
          <IconButton
            onClick={toggleExpanded}
            size="small"
            sx={(theme) => ({
              verticalAlign: "sub",
              transform: `rotate(${expanded ? 0 : 180}deg)`,
              transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
              }),
            })}
          >
            <ExpandLessIcon fontSize="inherit" />
          </IconButton>
        </Typography>
        {onRemove && (
          <IconButton onClick={onRemove} size="small">
            <ClearIcon fontSize="inherit" />
          </IconButton>
        )}
      </Stack>
      <Collapse in={expanded} sx={{ marginLeft: MARGIN_LEFT, position: "relative" }}>
        {children}
      </Collapse>
    </Stack>
  );
};
