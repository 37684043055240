import { useCallback, useMemo, useReducer, useState } from "react";

import { useHistory } from "react-router-dom";
import {
  CloudAnalyticsModel,
  type Collaborator,
  type Collaborators,
  type PublicAccess,
  Roles,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Typography } from "@mui/material";
import { type Method } from "axios";
import cloneDeep from "lodash/cloneDeep";

import { useApiContext } from "../../../api/context";
import { budgetText, httpMethods } from "../../../assets/texts";
import { budgetTxt } from "../../../assets/texts/CloudAnalytics/budget";
import useBudgetInvestigate from "../../../Components/hooks/cloudAnalytics/budgets/useBudgetInvestigate";
import useAnalyticsUsers from "../../../Components/hooks/cloudAnalytics/useAnalyticsUsers";
import { useCAOwnerAssignerCheck } from "../../../Components/hooks/useCAOwnerAssignerCheck";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useInfoSnackbar, useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../Context/AuthContext";
import { useEntitiesContext } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { type Budget, type DraftBudgetData } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { serverTimestamp } from "../../../utils/firebase";
import mixpanel from "../../../utils/mixpanel";
import Invites from "../../IAM/InviteUserDialog/handleInvite";
import { type AnalyticsResourcesBudgets } from "../analyticsResources/types";
import { useAnalyticsContext } from "../CloudAnalyticsContext";
import {
  BudgetMenuOptions,
  budgetMenuOptions,
  checkAllowedSubdomains,
  getNewCollaborators,
  getNewUsers,
  userAccess,
} from "../utilities";
import { deleteManyBudgets, updateBudgetSubscriptions } from "./db";
import { shareBudget } from "./shared";
import { budgetStateReducer } from "./utils";

export const useCreateBudgetHandler = ({
  baseUrl,
  mixpanelEventName,
  prevPageUrl,
}: {
  baseUrl: string;
  mixpanelEventName: string;
  prevPageUrl?: string;
}) => {
  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();

  return useCallback(async () => {
    mixpanel.track(mixpanelEventName);
    history.push(`${baseUrl}/create`, { prevPage: prevPageUrl ?? routeMatchURL });
  }, [mixpanelEventName, history, baseUrl, routeMatchURL, prevPageUrl]);
};

export const useCopyBudget = ({ budget }: { budget: DraftBudgetData }) => {
  const infoSnackbar = useInfoSnackbar(3);
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();

  return useCallback(async () => {
    const newCollaborator: Collaborator = {
      email: currentUser.email,
      role: Roles.OWNER,
    };
    const docRef = getCollection(CloudAnalyticsModel).doc("budgets").collection("cloudAnalyticsBudgets").newDoc();
    mixpanel.track("analytics.budgets.create", { budgetId: docRef.id });
    await docRef.set({
      customer: customer.ref,
      name: `Copy of ${budget.name || "budget"}`,
      description: budget.description,
      config: budget.config,
      public: null,
      collaborators: [newCollaborator],
      recipients: [currentUser.email],
      recipientsSlackChannels: [],
      timeCreated: serverTimestamp(),
      timeModified: serverTimestamp(),
      isValid: true,
    });
    infoSnackbar(budgetTxt.DUPLICATE_SUCCESSFUL);
    return docRef.id;
  }, [budget.config, budget.description, budget.name, currentUser.email, customer.ref, infoSnackbar]);
};

type BudgetParams = {
  budgetId: string;
  isBudgetValid?: boolean;
  isNewBudget?: boolean;
};

type BudgetRefreshParams = {
  isViewPage: boolean;
  budgetParams: BudgetParams;
};

export const useBudgetRefresh = ({ isViewPage, budgetParams }: BudgetRefreshParams) => {
  const { budgetId, isBudgetValid, isNewBudget } = budgetParams;

  const { customer: genuineCustomer } = useCustomerContext();
  const api = useApiContext();
  const [budgetStates, dispatchBudgetStates] = useReducer(budgetStateReducer, {
    shouldRefreshData: false,
    shouldRefreshPreview: false,
    refreshPreview: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const refreshBudgetUsageData = useCallback(async () => {
    setLoading(true);

    try {
      if ((!isViewPage && (!isBudgetValid || isNewBudget)) || (isViewPage && !budgetId)) {
        return;
      }
      dispatchBudgetStates({ type: "startedRefreshData" });
      await api.request({
        method: httpMethods.GET as Method,
        url: `/v1/customers/${genuineCustomer.id}/analytics/budgets/${budgetId}`,
      });
    } catch (error) {
      dispatchBudgetStates({ type: "shouldRefreshData" });
      consoleErrorWithSentry(error);
    } finally {
      setLoading(false);
    }
  }, [api, budgetId, genuineCustomer.id, isBudgetValid, isNewBudget, isViewPage]);

  const handleRefresh = useCallback(async () => {
    if (budgetStates.shouldRefreshPreview) {
      dispatchBudgetStates({ type: "startedRefreshPreview" });
    }

    await refreshBudgetUsageData();
  }, [budgetStates.shouldRefreshPreview, refreshBudgetUsageData]);

  return { loading, budgetStates, setLoading, dispatchBudgetStates, handleRefresh, refreshBudgetUsageData };
};

export const useSubscribeHandler = ({ row }) => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { onOpen: showSharedSnackbar } = useSnackbar();

  return useCallback(async () => {
    const newRecipients = row.data?.recipients || [];
    let message: string;
    if (newRecipients.includes(currentUser.email)) {
      newRecipients.splice(newRecipients.indexOf(currentUser.email), 1);
      message = "Unsubscribed from Budget";
    } else {
      newRecipients.push(currentUser.email);
      message = "Subscribed to Budget";
    }

    try {
      await updateBudgetSubscriptions(api, customer.id, row.ref.id, newRecipients);
      showSharedSnackbar({ message });
    } catch (e) {
      consoleErrorWithSentry(e);
      showSharedSnackbar({ message: budgetText.SUBSCRIBE_ERROR, variant: "error" });
    }
  }, [api, customer.id, currentUser.email, row, showSharedSnackbar]);
};

export const useBudgetInvestigateHandler = ({
  budget,
  openInNewTab = true,
}: {
  budget: Budget | AnalyticsResourcesBudgets;
  openInNewTab?: boolean;
}) => {
  const getInvestigationReportUrl = useBudgetInvestigate(budget);
  const history = useHistory();

  return useCallback(async () => {
    const path = await getInvestigationReportUrl();
    if (openInNewTab) {
      window.open(path, "_blank");
    } else {
      history.push(path);
    }
  }, [getInvestigationReportUrl, history, openInNewTab]);
};

export const buildBudgetEditPath = (customerId: string, budgetId: string) =>
  `/customers/${customerId}/analytics/budgets/${budgetId}`;

export const buildBudgetViewPath = (customerId: string, budgetId: string) =>
  `/customers/${customerId}/analytics/budgets/${budgetId}/view`;

export const useEditBudgetHandler = ({ budget }: { budget: Budget | AnalyticsResourcesBudgets }) => {
  const history = useHistory();

  return useCallback(() => {
    const path = buildBudgetEditPath(budget.data.customer.id, budget.id);
    history.push(path);
  }, [history, budget.data.customer?.id, budget.id]);
};

export const useBudgetThreeDotsMenu = ({
  row,
  handleShare,
  handleDelete,
  tierLimitReached,
}: {
  row: AnalyticsResourcesBudgets | Budget;
  handleShare: () => void;
  handleDelete: () => void;
  tierLimitReached: boolean;
}) => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const {
    viewer: isCurrentUserViewer,
    editor: isCurrentUserEditor,
    owner: isCurrentUserOwner,
  } = userAccess(currentUser.email, row.data);
  const isCAOwnershipAssigner = useCAOwnerAssignerCheck();
  const isCurrentUserAllowsToShare = isCurrentUserEditor || isCAOwnershipAssigner;

  const handleSubscribe = useSubscribeHandler({ row });
  const handleCopyBudget = useCopyBudget({ budget: row.data });
  const handleInvestigate = useBudgetInvestigateHandler({ budget: row, openInNewTab: true });
  const handleEdit = useEditBudgetHandler({ budget: row });

  const budgetActionHandler = useCallback(
    (action: string) => {
      switch (action) {
        case BudgetMenuOptions.SUBSCRIBE:
          return () => void handleSubscribe();
        case BudgetMenuOptions.EDIT_BUDGET:
          return handleEdit;
        case BudgetMenuOptions.SHARE:
          return handleShare;
        case BudgetMenuOptions.DUPLICATE:
          return () => void handleCopyBudget();
        case BudgetMenuOptions.VIEW_IN_REPORTS:
          return () => void handleInvestigate();
        case BudgetMenuOptions.DELETE:
          return handleDelete;
      }
    },
    [handleSubscribe, handleEdit, handleShare, handleInvestigate, handleDelete, handleCopyBudget]
  );

  const threeDotsMenuOptions: ThreeDotsMenuOption[] = useMemo(
    () =>
      budgetMenuOptions
        .filter(
          (option) =>
            !(
              (option.value === BudgetMenuOptions.DELETE && !isCurrentUserOwner) ||
              (option.value === BudgetMenuOptions.SHARE && !isCurrentUserAllowsToShare) ||
              (option.value === BudgetMenuOptions.SUBSCRIBE && isCurrentUserViewer) ||
              (option.value === BudgetMenuOptions.EDIT_BUDGET && !isCurrentUserEditor)
            )
        )
        .map((option): ThreeDotsMenuOption => {
          let color:
            | "inherit"
            | "initial"
            | "primary"
            | "secondary"
            | "textPrimary"
            | "textSecondary"
            | "error"
            | undefined = "textPrimary";

          let text = option.value;
          if (option.secondary) {
            color = "error";
          }

          if (
            option.value === BudgetMenuOptions.SUBSCRIBE &&
            !!row.data.recipients?.find((r) => r === currentUser.email)
          ) {
            text = `Un${option.value.toLowerCase()}`;
          }

          if (option.value === BudgetMenuOptions.DUPLICATE && tierLimitReached) {
            option.disabled = true;
          }

          return {
            key: option.value,
            label: <Typography color={color}>{text}</Typography>,
            action: budgetActionHandler(option.value),
            disabled: option?.disabled ?? false,
          } as const;
        }),
    [
      isCurrentUserViewer,
      isCurrentUserEditor,
      isCurrentUserOwner,
      isCurrentUserAllowsToShare,
      row.data.recipients,
      tierLimitReached,
      budgetActionHandler,
      currentUser.email,
    ]
  );

  return <ThreeDotsMenu closeAfterSelect options={threeDotsMenuOptions} />;
};

export const useShareBudgetHandler = ({ selected, setLoading, closeDialog }) => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { entities } = useEntitiesContext();
  const { customer } = useCustomerContext();
  const { onOpen: showSharedSnackbar } = useSnackbar();
  const { handleMissingPermission } = useAnalyticsContext();
  const { invites, userEmails, allUsersAndInvites } = useAnalyticsUsers();
  const api = useApiContext();
  const isCurrentUserUserManager = userRoles.usersManager || userRoles.doitEmployee;

  const inviteAction = useCallback(
    async (collaborators, entity) => {
      const emails = await getNewUsers({
        collaborators,
        users: userEmails,
        invites,
        baseEntity: entity,
        allUsersAndInvites,
      });
      if (emails.length && currentUser) {
        await Invites.handleInvite({
          newEmails: emails,
          customer,
          currentUser,
          api,
          entities,
        });
      }
    },
    [api, currentUser, customer, invites, userEmails, allUsersAndInvites, entities]
  );
  return useCallback(
    async (collaborators: Collaborators, publicAccess: PublicAccess) => {
      try {
        setLoading(true);
        for (const budget of selected) {
          if (isCurrentUserUserManager) {
            try {
              await inviteAction(collaborators, budget);
            } catch (e) {
              handleMissingPermission(e as string);
              setLoading(false);
              return;
            }
          }
          const verifiedRecipients = budget.data.recipients.filter(
            (r: string) => checkAllowedSubdomains(r) || collaborators.find((c) => c.email === r)
          );
          await shareBudget({
            api,
            customer,
            budget,
            publicAccess: publicAccess === "mixed" ? budget.data.public : publicAccess,
            collaborators: getNewCollaborators(collaborators, budget.data.collaborators),
            recipients: verifiedRecipients,
            recipientsSlackChannels: cloneDeep(budget.data.recipientsSlackChannels) ?? [],
          });
        }

        showSharedSnackbar({ message: "Budget sharing and alerting saved successfully" });
      } catch (error: any) {
        showSharedSnackbar({
          message: error.response.data.error || budgetText.SHARE_ERROR,
          variant: "error",
        });
        consoleErrorWithSentry(error);
      } finally {
        closeDialog();
        setLoading(false);
      }
    },
    [
      setLoading,
      showSharedSnackbar,
      selected,
      isCurrentUserUserManager,
      api,
      customer,
      inviteAction,
      handleMissingPermission,
      closeDialog,
    ]
  );
};

export const useDeleteBudgetHandler = ({
  selected,
  closeDialog,
}: {
  selected: AnalyticsResourcesBudgets[] | Budget[];
  closeDialog: () => void;
}) => {
  const { onOpen: showSharedSnackbar } = useSnackbar();
  const api = useApiContext();
  const customerId = useCustomerId();

  return useCallback(async () => {
    const selectedIds = selected.map((s: AnalyticsResourcesBudgets | Budget) => s.id);
    try {
      await deleteManyBudgets(api, customerId, selectedIds);

      showSharedSnackbar({
        message: budgetText.DELETE_BUDGET_SUCCESS,
        variant: "success",
        autoHideDuration: 5000,
      });
      mixpanel.track("analytics.budgets.delete", {
        budgetIds: selectedIds,
      });
    } catch (error) {
      consoleErrorWithSentry(error);
      showSharedSnackbar({
        message: budgetText.DELETE_BUDGET_ERROR,
        variant: "error",
        autoHideDuration: 5000,
      });
    } finally {
      closeDialog();
    }
  }, [closeDialog, selected, showSharedSnackbar, api, customerId]);
};
