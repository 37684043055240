import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type ButtonProps = {
  dataCy?: string;
  endIcon?: React.ReactNode;
  onClick: () => void;
  startIcon?: React.ReactNode;
  text: string;
  variant: "contained" | "outlined" | "text";
};

type LensEnticementProps = {
  buttons?: ButtonProps[];
  actionButton?: React.JSX.Element;
  videoUrl?: string;
  learnMoreUrl?: string;
  image?: string;
  imageElement?: React.JSX.Element;
  imageAlt?: string;
  listItems: string[];
  title: string;
  children?: React.ReactNode;
};

const LensEnticementCard = ({
  buttons,
  actionButton,
  videoUrl,
  learnMoreUrl,
  children,
  image,
  imageElement,
  imageAlt,
  listItems,
  title,
}: LensEnticementProps) => (
  <Box
    sx={{
      width: {
        xs: "auto",
      },
      "@media (min-width: 601px) and (max-width: 1280px)": {
        width: "530px",
      },
      "@media (min-width: 1281px)": {
        width: "1030px",
      },
    }}
  >
    <Stack direction="row" justifyContent="center">
      <Box>
        <Typography
          variant="h1"
          sx={{
            paddingBottom: "1rem",
          }}
        >
          {title}
        </Typography>
        <List
          sx={{
            paddingBottom: "2rem",
            paddingLeft: "1rem",
          }}
        >
          {listItems.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                padding: "0",
                alignItems: "flex-start",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "1.5rem",
                  paddingRight: "0.5rem",
                }}
              >
                <CheckIcon
                  sx={{
                    color: "primary.main",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={item} sx={{ color: "text.secondary", margin: "0" }} />
            </ListItem>
          ))}
        </List>
        {children}
        <Stack spacing={2} direction="row">
          {actionButton}
          {learnMoreUrl && (
            <Button data-cy="learn-more-button" target="_blank" variant="text" sx={{ px: 1 }} href={learnMoreUrl}>
              Learn more
            </Button>
          )}
          {videoUrl && (
            <Button
              target="_blank"
              data-cy="watch-video"
              variant="text"
              sx={{ px: 1 }}
              startIcon={<PlayCircleOutlineIcon />}
              href={videoUrl}
            >
              Watch video
            </Button>
          )}
          {buttons?.map((button, index) => (
            <Button
              key={index}
              variant={button.variant || "contained"}
              startIcon={button.startIcon}
              endIcon={button.endIcon}
              onClick={button.onClick}
              data-cy={button.dataCy}
            >
              {button.text}
            </Button>
          ))}
        </Stack>
      </Box>
      {!!image && (
        <Box
          component="img"
          src={image}
          alt={imageAlt}
          sx={{
            maxWidth: "408px",
            marginLeft: "80px",

            "@media (max-width: 1280px)": {
              display: "none",
            },
          }}
        />
      )}
      {!image && !!imageElement && (
        <Box
          sx={{
            maxWidth: "408px",
            marginLeft: "80px",

            "@media (max-width: 1280px)": {
              display: "none",
            },
          }}
        >
          {imageElement}
        </Box>
      )}
    </Stack>
  </Box>
);

export default LensEnticementCard;

export const LensEnticement = (props: LensEnticementProps) => (
  <Box
    sx={{
      display: "grid",
      justifyContent: "center",
      width: "100%",
      mt: "170px",
    }}
  >
    <LensEnticementCard {...props} />
  </Box>
);
