import { useCallback, useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";
import {
  AssetModelBillingAnomalyModel,
  NotificationAudienceType,
  NotificationProviderType,
} from "@doitintl/cmp-models";
import { getCollectionGroup, useCollectionData } from "@doitintl/models-firestore";
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { type DateTime } from "luxon";

import { FilterTable } from "../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../Components/FilterTable/FilterTableSkeleton";
import { useLocalStorage } from "../../Components/FilterTable/hook";
import { useNotificationDescriptors } from "../../Components/Notifications/useNotificationDescriptors";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { getCachingKeys } from "../../utils/cachingKeys";
import mixpanel from "../../utils/mixpanel";
import { useDateTime } from "../../utils/timeHooks";
import {
  anomaliesWithAttrNames,
  getAnomalyInactiveDate,
  getCostOfAnomaly,
  getElementTimestamp,
  getMiniGraph,
} from "./AlertTools";
import { anomalyColumns, anomalyFilterColumns } from "./columns";
import { CostAnomaliesRow } from "./CostAnomaliesRow";
import { EmptyAlertList } from "./EmptyAlertList";
import { NoAlertsMatchingFilters } from "./NoAlertsMatchingFilters";
import { type CostAnomaliesItem } from "./types";
import { getAnomalyLevel, getSource, timeFrame } from "./utils";

type TimeFilter = "Last24hours" | "Last7days" | "LastMonth" | "LastQuarter" | "AllTime";

const timeRangeFilters = [
  {
    label: "Last 24 hours",
    value: "Last24hours",
  },
  {
    label: "Last 7 days",
    value: "Last7days",
  },
  {
    label: "Last month",
    value: "LastMonth",
  },
  {
    label: "Last quarter",
    value: "LastQuarter",
  },
  {
    label: "All time",
    value: "AllTime",
  },
] as const;

const useTimeRangeFilter = () => {
  const { currentHour } = useDateTime();

  const getTimeRange = useCallback(
    (timeFilter: TimeFilter) => {
      switch (timeFilter) {
        case "Last24hours":
          return currentHour.minus({ hours: 24 });
        case "Last7days":
          return currentHour.minus({ days: 7 });
        case "LastMonth":
          return currentHour.minus({ months: 1 });
        case "LastQuarter":
          return currentHour.minus({ months: 4 });
      }
    },
    [currentHour]
  );

  return { getTimeRange };
};

const useAlertsData = (
  timeFilter: TimeFilter,
  getTimeRange: (timeFilter: TimeFilter) => DateTime<true> | undefined
) => {
  const { customerOrPresentationModeCustomer } = useCustomerContext();

  const query = useMemo(() => {
    const query = getCollectionGroup(AssetModelBillingAnomalyModel).where(
      "customer",
      "==",
      customerOrPresentationModeCustomer.ref
    );
    const queryStartTime = getTimeRange(timeFilter);
    if (!queryStartTime) {
      // all time no time filter
      return query;
    }

    return query.where("timestamp", ">", queryStartTime.toJSDate()).orderBy("timestamp", "desc");
  }, [customerOrPresentationModeCustomer.ref, getTimeRange, timeFilter]);

  return useCollectionData(query, {
    idField: "id",
    refField: "ref",
    caching: true,
    cachingKeys: getCachingKeys(customerOrPresentationModeCustomer.ref.id),
  });
};

export default function AlertList() {
  const [mappedData, setMappedData] = useState<CostAnomaliesItem[]>();
  const [timeRangeFilter, setTimeRangeFilter] = useLocalStorage<TimeFilter>("anomalies_list_time_filter", "Last7days");
  const { customer } = useCustomerContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { getTimeRange } = useTimeRangeFilter();
  const [alertsData] = useAlertsData(timeRangeFilter, getTimeRange);

  useEffect(() => {
    mixpanel.track("anomalies.list.view");
  }, []);

  useEffect(() => {
    if (!alertsData) {
      return;
    }
    const arr = alertsData.map((element) => {
      const data: Omit<CostAnomaliesItem, "attribution_name"> = {
        ...element.metadata,
        id: element.id,
        ref: element.ref,
        context: element.metadata.context ?? timeFrame[1].id,
        miniGraph: getMiniGraph(element),
        cost_of_anomaly: getCostOfAnomaly(element),
        _ts: getElementTimestamp(element),
        _severity: getAnomalyLevel(element.metadata),
        status: element.status,
        inactiveDate: getAnomalyInactiveDate(element),
        acknowledged: !!element.customerFeedback,
        _source: getSource(element.metadata),
      };

      return {
        ...data,
        attribution: element.attribution?.id,
        project_id: data.project_id ?? "All",
        sku_name: data.sku_name ?? "All",
      };
    });

    anomaliesWithAttrNames(arr).then((withNames) => {
      setMappedData(withNames);
    });
  }, [alertsData]);

  const [notificationDescriptors, loadingNotificationDescriptors] = useNotificationDescriptors(
    NotificationAudienceType.COMPANY,
    NotificationProviderType.SLACK,
    userRoles.permissions
  );

  const slackSettings = `/customers/${customer.id}/integrations/slack`;

  const showManageNotifications =
    !loadingNotificationDescriptors &&
    notificationDescriptors?.some((descriptor) => descriptor.enabled) &&
    userRoles.cloudAnalytics;

  if (!mappedData) {
    return <FilterTableSkeleton />;
  }

  return (
    <FilterTable<CostAnomaliesItem>
      showHeader={mappedData.length > 0}
      rowComponent={CostAnomaliesRow}
      mixpanelEventNames={{
        searchBarFilter: "anomalies.list.filter.update",
      }}
      filterBarPlaceholder="Filter cost anomalies"
      toolbarProps={{
        gap: 2,
        title: "Cost anomalies",
        allowToEditColumns: true,
        customSlot: showManageNotifications ? (
          <Box
            sx={{
              mr: "auto",
            }}
          >
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              sx={{
                alignItems: "center",
              }}
            >
              <Box />
              <Box
                sx={{
                  ml: 1,
                }}
              >
                <Button component={Link} to={slackSettings}>
                  Manage notifications
                </Button>
              </Box>
            </Stack>
          </Box>
        ) : undefined,
      }}
      headerColumns={anomalyColumns}
      filterColumns={anomalyFilterColumns}
      tableItems={mappedData}
      defaultSortingColumnValue="usage_start_time"
      defaultSortDirection="asc"
      persistenceKey="alerts_table"
      data-cy="aws-assets-table"
      searchBarFilters={
        <Grid>
          <FormControl fullWidth size="small">
            <InputLabel id="time-range-filter-label" data-cy="time-range-filter-label">
              Time range
            </InputLabel>
            <Select
              id="time-range-filter"
              data-cy="time-range-filter"
              labelId="time-range-filter-label"
              label="Time range"
              value={timeRangeFilter}
              onChange={({ target: { value } }: any) => {
                setTimeRangeFilter(value);
              }}
              style={{
                width: "215px",
              }}
            >
              {timeRangeFilters.map(({ value, label }) => (
                <MenuItem value={value} key={label} data-cy={label}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      }
      emptyTableComponent={mappedData.length !== 0 ? NoAlertsMatchingFilters() : EmptyAlertList()}
    />
  );
}
