import { Stack } from "@mui/material";

import AWSIconWhite from "../../../assets/amazon-web-services-logo-white.png";
import AWSIcon from "../../../assets/amazon-web-services-new-square.png";
import GCPIcon from "../../../assets/google-cloud.png";
import SnowflakeIcon from "../../../assets/snowflake-logo.svg";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { type Insight } from "../types";

export function CloudProvidersIcons({
  cloudTags,
  style,
}: {
  readonly cloudTags: Insight["cloudTags"];
  style?: React.CSSProperties;
}) {
  const isDarkMode = useDarkThemeCheck();

  return (
    <Stack direction="row" spacing={1}>
      {cloudTags.includes("aws") && (
        <img src={isDarkMode ? AWSIconWhite : AWSIcon} alt="AWS Icon" style={{ height: "24px", ...style }} />
      )}
      {cloudTags.includes("gcp") && <img src={GCPIcon} alt="GCP Icon" style={{ height: "24px", ...style }} />}
      {cloudTags.includes("snowflake") && (
        <img src={SnowflakeIcon} alt="Snowflake Icon" style={{ height: "24px", ...style }} />
      )}
    </Stack>
  );
}
