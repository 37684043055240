import { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import { CellsWrapper } from "../../Components/FilterTable/Toolbar/CellsWrapper";
import Hide from "../../Components/HideChildren/Hide";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { defaultCurrencySymbol } from "../../constants/common";
import { asyncConvertCurrencyTo } from "../../Context/AsyncCurrencyConverterContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../Context/TierProvider";
import { formatCurrency, getCloudIcon } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import SparkLineTable from "./SparkLine/SparkLineTable";
import { type CostAnomaliesItem } from "./types";
import { getAnomalyDuration, levelChipColor, levelText, statusChipColor } from "./utils";

const chipStyle = {
  height: "20px",
  ".MuiChip-label": {
    padding: "0px 6.5px",
  },
};

type Props = {
  row: CostAnomaliesItem;
};

export const CostAnomaliesRow = ({ row }: Props) => {
  const { isMobile } = useFullScreen();
  const { customer } = useCustomerContext();
  const history = useHistory();
  const isEntitledCostAnomaliesAcknowledgement = useIsFeatureEntitled("governance:costAnomalies:acknowledgement");
  const [costConvertedAndFormated, setCostConvertedAndFormated] = useState<string>();
  const isDarkMode = useDarkThemeCheck();
  const customerCurrency = customer.settings?.currency ?? defaultCurrencySymbol;

  useEffect(() => {
    asyncConvertCurrencyTo(row.cost_of_anomaly, new Date(), defaultCurrencySymbol, customerCurrency).then(
      (converted) => {
        setCostConvertedAndFormated(formatCurrency(converted, customerCurrency));
      }
    );
  }, [customerCurrency, row.cost_of_anomaly]);

  const getLinkUrl = (row: CostAnomaliesItem) => row.ref.path.split("/")[1];

  return (
    <CellsWrapper>
      <TableCell align="left" data-cy="start-time">
        {row._ts?.toLocaleString(isMobile ? DateTime.DATE_SHORT : DateTime.DATETIME_MED, { locale: "en-gb" })}
        <br />
        <Typography
          sx={{
            fontSize: "14px",
            lineHeight: "22px",
            color: "text.secondary",
          }}
        >
          {getAnomalyDuration(row)}
        </Typography>
      </TableCell>
      <Hide mdDown>
        <TableCell data-cy="status">
          <Chip label={capitalize(row.status ?? "INACTIVE")} sx={{ ...chipStyle, ...statusChipColor(row) }} />
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              minWidth: 120,
            }}
          >
            {isEntitledCostAnomaliesAcknowledgement && (row.acknowledged ? "Acknowledged" : "Not acknowledged")}
          </Typography>
        </TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="project-account">{row.project_id}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="service">
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Avatar src={getCloudIcon(row.platform, isDarkMode)} sx={{ height: 27, width: 27 }} />
            <div>{row.service_name}</div>
          </Stack>
        </TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="sku">{row.sku_name}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="source">{row._source}</TableCell>
      </Hide>

      <Hide mdDown>
        <TableCell data-cy="severity">
          <Chip
            label={row._severity === 1 ? "Info" : levelText[row._severity]}
            sx={{ ...chipStyle, ...levelChipColor[row._severity] }}
          />
        </TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="anomaly-cost">{costConvertedAndFormated}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="attribution">{row.attribution_name}</TableCell>
      </Hide>
      <TableCell data-cy="anomaly">
        <SparkLineTable miniGraph={row.miniGraph} />
      </TableCell>
      <TableCell component="th" scope="row" data-cy="view-cta">
        <Link
          style={{ textDecoration: "none" }}
          to={`${history.location.pathname.replace(/\/$/, "")}/${getLinkUrl(row)}/${row.id}`}
        >
          <Button variant="outlined" color="primary" size="small">
            View
          </Button>
        </Link>
      </TableCell>
    </CellsWrapper>
  );
};
