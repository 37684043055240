import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { type InAppNotificationReadModel } from "@doitintl/cmp-models";
import { type QuerySnapshotModel } from "@doitintl/models-firestore";
import { useFirestoreIdealHookOnce } from "Components/hooks/useFirestoreIdeal";
import { DateTime } from "luxon";

import { consoleErrorWithSentry } from "../../utils";
import { useAuthContext } from "../AuthContext";
import { useCustomerContext } from "../CustomerContext";
import { useUserContext } from "../UserContext";
import { Alert } from "./mappers/alert";
import { Budget } from "./mappers/budget";
import { CostAnomaly } from "./mappers/costAnomaly";
import { InvoiceReminder } from "./mappers/invoiceReminder";
import { KnownIssues } from "./mappers/knownIssues";
import { NewInvoice } from "./mappers/newInvoice";
import {
  type InAppNotification,
  type InAppNotificationType,
  type Mapper,
  maxDays,
  type RealTimeNotification,
} from "./types";

const enabledNotifications: Mapper[] = [
  new Budget(),
  new CostAnomaly(),
  new NewInvoice(),
  new InvoiceReminder(),
  new Alert(),
  new KnownIssues(),
];

export type InAppNotifications = {
  notifications: Map<string, InAppNotification>;
  setNotifications: Dispatch<SetStateAction<Map<string, InAppNotification>>>;
  userRead?: Map<string, InAppNotificationReadModel>;
  loading: boolean;
  realTimeQueue: RealTimeNotification[];
  setRealTimeQueue: Dispatch<SetStateAction<RealTimeNotification[]>>;
};

const inAppNotificationsContext = createContext<InAppNotifications>({
  notifications: new Map<string, InAppNotification>(),
  loading: true,
  setNotifications: () => {},
  realTimeQueue: [],
  setRealTimeQueue: () => {},
});

export const InAppNotificationsContextProvider = ({ children }: { children?: ReactNode }) => {
  const { customer } = useCustomerContext({ allowNull: true });
  const { userRoles, userModel } = useUserContext({ allowNull: false });
  const { isDoitEmployee } = useAuthContext();

  const [loading, setLoading] = useState(true);
  const [loadedStatus, setLoadedStatus] = useState<Map<InAppNotificationType, boolean>>(new Map());
  const [notifications, setNotifications] = useState<Map<string, InAppNotification>>(new Map());
  const [userRead, setUserRead] = useState<Map<string, InAppNotificationReadModel> | undefined>();
  const [realTimeQueue, setRealTimeQueue] = useState<RealTimeNotification[]>([]);
  const prevNotifications = useRef<Map<string, InAppNotification>>(new Map());
  const [idealFirestore, setIdealFirestore] = useState(false);

  const maxPerType = 10;

  useFirestoreIdealHookOnce("inAppNotifications", 5000, () => {
    setIdealFirestore(true);
  });

  // clear notifications on customer change
  useEffect(() => {
    setNotifications(new Map());
    setRealTimeQueue([]);
    setLoading(true);
    setLoadedStatus(new Map());
    prevNotifications.current = new Map();
  }, [customer?.ref]);

  // load user read status
  useEffect(() => {
    if (!userModel?.ref || !customer?.ref || !idealFirestore) {
      return;
    }

    const onSnapshot = (snapshot: QuerySnapshotModel<InAppNotificationReadModel>) => {
      const readStatus = new Map<string, InAppNotificationReadModel>();
      if (snapshot.empty) {
        setUserRead(readStatus);
        return;
      }
      snapshot.forEach((doc) => {
        readStatus.set(doc.id, doc.data());
      });
      setUserRead(readStatus);
    };

    const colRef = userModel.ref.collection("inAppNotificationRead");

    if (isDoitEmployee) {
      return colRef
        .where("customer", "==", customer?.ref) // Doers may have multiple customers
        .onSnapshot(onSnapshot);
    } else {
      return colRef.onSnapshot(onSnapshot);
    }
  }, [idealFirestore, userModel?.ref, customer?.ref, isDoitEmployee]);

  // load notifications, add listener for real-time updates, and set loading status
  useEffect(() => {
    if (!customer?.ref || !idealFirestore) {
      return;
    }

    const startTime = DateTime.utc().minus({ days: maxDays }).toJSDate();
    const setLoadingStatusForType = (type: InAppNotificationType, status: boolean) => {
      setLoadedStatus((prev) => {
        const newLoadedStatus = new Map(prev);
        newLoadedStatus.set(type, status);
        return newLoadedStatus;
      });
    };

    const subscribe = (notificationType: Mapper) => {
      if (!isDoitEmployee && !notificationType.userHasPermission(userRoles)) {
        setLoadingStatusForType(notificationType.type, false);
        return;
      }

      const col = notificationType
        .getCollection(customer.ref, startTime, userModel.email, isDoitEmployee)
        .limit(maxPerType);
      return col.onSnapshot(
        async (snapshot) => {
          let loadSuccess = false;
          try {
            const changes = snapshot.docChanges();
            const notifications: (InAppNotification | null)[] = await Promise.all(
              changes.map(async (change) => {
                // ignore removed
                if (change.type === "removed") {
                  return null;
                }
                return notificationType.toNotification(change.doc);
              })
            );

            setNotifications((prev) => {
              const newNotifications = new Map(prev);
              notifications.forEach((n) => {
                if (n) {
                  newNotifications.set(n.id, n);
                }
              });
              return newNotifications;
            });
            loadSuccess = true;
          } catch (e) {
            consoleErrorWithSentry(e, {
              message: `InAppNotifications: loading notifications ${notificationType.type}`,
            });
          }
          setLoadingStatusForType(notificationType.type, loadSuccess);
        },
        async (e) => {
          setLoadingStatusForType(notificationType.type, false);
          consoleErrorWithSentry(e, { message: `InAppNotifications: loading notifications ${notificationType.type}` });
        }
      );
    };
    const unSubs = enabledNotifications
      .map((notificationType) => subscribe(notificationType))
      .filter((sub): sub is () => void => sub !== undefined);

    return () => {
      unSubs.forEach((unSub) => {
        unSub();
      });
    };
  }, [idealFirestore, customer?.ref, isDoitEmployee, userModel.email, userRoles]);

  useEffect(() => {
    if (loadedStatus.size === enabledNotifications.length) {
      setLoading(false);
    }
  }, [loadedStatus]);

  // add new or updated notifications to real-time queue
  useEffect(() => {
    // ignore initial load
    if (loading) {
      return;
    }
    if (prevNotifications.current.size !== 0) {
      notifications.forEach((n) => {
        const prev = prevNotifications.current.get(n.id);
        if (!prev) {
          setRealTimeQueue((prev) => [
            ...prev,
            {
              ...n,
              newOrUpdated: "New",
            },
          ]);
        } else if (prev.updated?.seconds !== n.updated?.seconds) {
          setRealTimeQueue((prev) => [
            ...prev,
            {
              ...n,
              newOrUpdated: "Updated",
            },
          ]);
        }
      });
    }

    prevNotifications.current = notifications;
  }, [notifications, loading]);

  return (
    <inAppNotificationsContext.Provider
      value={{ notifications, userRead, setNotifications, loading, realTimeQueue, setRealTimeQueue }}
    >
      {children}
    </inAppNotificationsContext.Provider>
  );
};

export const useInAppNotifications = (): InAppNotifications => useContext(inAppNotificationsContext);
