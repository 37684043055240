import { useCallback, useMemo, useRef } from "react";

import { WidgetState } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { useApiContext } from "../../api/context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useDashboardsContext } from "../../Context/useDashboardsContext";
import { refreshCachedReport } from "../../Pages/CloudAnalytics/handlers/updateWidgets";
import { ErrorCode } from "../../Pages/CloudAnalytics/utilities";
import { type ReportWSnap } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import { getWidgetId } from "../../utils/widgets";
import { useWidgetData } from "../Dashboard/Analytics/hooks";

const CACHE_DASHBOARD = "Reports cache";
export const CACHE_DASHBOARD_ID = "cloud-analytics-reports-cache";
const WIDGET_STALE_DAYS_THRESHOLD = 7;
const WIDGET_REFRESH_INTERVAL_MINUTES = 10;

export enum ReportCacheType {
  UserCached = "userCached",
  WidgetCached = "widgetCached",
}

type Props = {
  report: ReportWSnap;
  isTable: boolean;
  handleReportError: (code: ErrorCode) => void;
};

const useReportCache = ({ report, isTable, handleReportError }: Props) => {
  const { dashboards, publicDashboards, addWidgetToDashboard, createDashboard, removeWidgetFromDashboard } =
    useDashboardsContext();
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const cacheDashboard = useMemo(() => publicDashboards.find((d) => d.id === CACHE_DASHBOARD_ID), [publicDashboards]);
  const isDashboardCreated = useRef(false);

  const dashboardsContextLoaded = useMemo(() => dashboards.length > 0, [dashboards]);

  const reportWidget = useMemo(
    () => ({
      width: isTable ? 3 : 1,
      name: `cloudReports::${customer.id}_${report.snapshot.id}`,
      state: WidgetState.Success,
    }),
    [customer.id, isTable, report.snapshot.id]
  );

  const userCachedReportWidget = useMemo(
    () => cacheDashboard?.widgets.find((w) => w.name === reportWidget.name),
    [cacheDashboard, reportWidget.name]
  );

  const widgetId = useMemo(() => {
    if (userCachedReportWidget) {
      return getWidgetId(userCachedReportWidget);
    }
    return `${customer.id}_${report.snapshot.id}`;
  }, [userCachedReportWidget, customer.id, report.snapshot.id]);

  const { data, loading: widgetDataLoading } = useWidgetData(widgetId);

  const reportCacheType = useMemo(() => {
    if (userCachedReportWidget) {
      return ReportCacheType.UserCached;
    }
    const timeRefreshed = data?.rawReport?.timeRefreshed?.toDate();
    if (
      timeRefreshed &&
      Math.abs(DateTime.fromJSDate(timeRefreshed).diffNow().as("days")) <= WIDGET_STALE_DAYS_THRESHOLD
    ) {
      return ReportCacheType.WidgetCached;
    }
  }, [data?.rawReport?.timeRefreshed, userCachedReportWidget]);

  const isReportCacheRecentlyRefreshed = useMemo(() => {
    const timeRefreshed = data?.rawReport?.timeRefreshed?.toDate();
    return (
      timeRefreshed &&
      Math.abs(DateTime.fromJSDate(timeRefreshed).diffNow().as("minutes")) < WIDGET_REFRESH_INTERVAL_MINUTES
    );
  }, [data?.rawReport?.timeRefreshed]);

  const createCacheDashboard = useCallback(async () => {
    try {
      return await createDashboard({
        id: CACHE_DASHBOARD_ID,
        name: CACHE_DASHBOARD,
        isPublic: true,
        hidden: true,
        widgets: [reportWidget],
      });
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  }, [createDashboard, reportWidget]);

  const addCacheWidgetToDashboard = useCallback(async () => {
    if (cacheDashboard) {
      try {
        if (!userCachedReportWidget) {
          await addWidgetToDashboard(cacheDashboard.id, reportWidget.name, {
            width: reportWidget.width,
            isPublicDashboard: true,
          });
        }
        await refreshCachedReport(api, customer.id, report.snapshot.id);
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    }
  }, [
    cacheDashboard,
    userCachedReportWidget,
    api,
    customer.id,
    report.snapshot.id,
    addWidgetToDashboard,
    reportWidget.name,
    reportWidget.width,
  ]);

  const handleCacheReport = useCallback(async () => {
    try {
      if (cacheDashboard) {
        await addCacheWidgetToDashboard();
      } else if (!isDashboardCreated.current) {
        const id = await createCacheDashboard();
        if (id) {
          isDashboardCreated.current = true;
        }
        await refreshCachedReport(api, customer.id, report.snapshot.id);
      }
    } catch (error: any) {
      if (error.response?.status === 507) {
        handleReportError(ErrorCode.EXCEEDED_FIRESTORE_LIMIT);
      }
      consoleErrorWithSentry(error);
    }
  }, [
    addCacheWidgetToDashboard,
    api,
    cacheDashboard,
    createCacheDashboard,
    customer.id,
    handleReportError,
    report.snapshot.id,
  ]);

  const rawReport = useMemo(() => data?.rawReport, [data?.rawReport]);

  const removeReportFromCache = useCallback(async () => {
    try {
      if (cacheDashboard) {
        await removeWidgetFromDashboard(cacheDashboard.id, reportWidget.name, { isPublicDashboard: true });
      }
    } catch (error: any) {
      consoleErrorWithSentry(error);
    }
  }, [cacheDashboard, removeWidgetFromDashboard, reportWidget.name]);

  return {
    dashboardsContextLoaded,
    handleCacheReport,
    isReportCacheLoading: widgetDataLoading,
    isReportCacheRecentlyRefreshed,
    rawCacheReport: rawReport,
    removeReportFromCache,
    reportCacheType,
    userCachedReportWidget,
  };
};

export default useReportCache;
