import { useMemo } from "react";

import { useHistory } from "react-router";
import { UserNotification } from "@doitintl/cmp-models";
import { Button, Stack, Typography } from "@mui/material";

import { useIsEntitledToCurrentDashboardType } from "../../Components/Dashboard/hooks/useIsEntitledByDashboardType";
import { useCustomerId } from "../../Components/hooks/useCustomerId";
import { useDashboardSubscriptions } from "../../Components/NotificationSubscription/db";
import { SubscribeButton } from "../../Components/NotificationSubscription/SubscribeButton";
import SplitButton from "../../Components/SplitButton";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../Context/AuthContext";
import { useIsFeatureEntitled } from "../../Context/TierProvider";
import { useCurrentDashboardContext } from "../../Context/useCurrentDashboardContext";
import { useUserContext } from "../../Context/UserContext";
import { getDashboardNameById, GlobalDashboardId } from "../../utils/common";

type Props = {
  threeDotsMenuOptions: ThreeDotsMenuOption[];
  splitButtonOptions: Array<{ action: () => void; label: string; disabled?: boolean }>;
  allowToAddWidgets: boolean;
};

export const DashboardHeader = ({ threeDotsMenuOptions, splitButtonOptions, allowToAddWidgets }: Props) => {
  const history = useHistory();
  const { userRoles } = useUserContext();
  const customerId = useCustomerId();
  const { currentDashboard, isCustomDashboard } = useCurrentDashboardContext();
  const isEntitledForCurrentDashboardType = useIsEntitledToCurrentDashboardType();
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const isEntitledDashboardCustom = useIsFeatureEntitled("dashboard:custom");
  const subscriptions = useDashboardSubscriptions({ dashboardPath: currentDashboard?.ref?.path ?? null });

  const isEksDashboard = currentDashboard?.id === GlobalDashboardId.EKSLens;

  const emptyDashboard = useMemo(() => !currentDashboard?.widgets?.length, [currentDashboard?.widgets]);

  const showSplitButton = useMemo(() => !emptyDashboard && allowToAddWidgets, [allowToAddWidgets, emptyDashboard]);

  const showSubscribeButton = useMemo(
    () =>
      !emptyDashboard &&
      (userRoles?.cloudAnalytics || isDoitEmployee) &&
      isEntitledForCurrentDashboardType &&
      (!isCustomDashboard || isEntitledDashboardCustom),
    [
      emptyDashboard,
      isCustomDashboard,
      isDoitEmployee,
      isEntitledDashboardCustom,
      isEntitledForCurrentDashboardType,
      userRoles?.cloudAnalytics,
    ]
  );

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" py={2} sx={{ width: "100%" }}>
      <Typography variant="h1" py={1}>
        {getDashboardNameById(currentDashboard?.id, currentDashboard?.name ?? "")}
      </Typography>

      <Stack direction="row" alignItems="center">
        <Stack spacing={2} direction="row" alignItems="center">
          {showSplitButton && <SplitButton options={splitButtonOptions} />}

          {showSubscribeButton && (
            <SubscribeButton
              subscriptions={subscriptions}
              subscriptionType={UserNotification.DashboardSubscription}
              subscriptionParent={currentDashboard}
            />
          )}

          {isEksDashboard && (
            <Button
              variant="contained"
              sx={{ width: "fit-content" }}
              onClick={() => {
                history.push(`/customers/${customerId}/eks-onboarding`);
              }}
            >
              See your list of clusters
            </Button>
          )}
        </Stack>

        {<ThreeDotsMenu closeAfterSelect options={threeDotsMenuOptions} />}
      </Stack>
    </Stack>
  );
};
