import { type Cluster, ClusterType } from "@doitintl/cmp-models";
import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent, Skeleton } from "@mui/material";

import { useClusters } from "../../../hooks";

interface ClusterSelectorProps {
  selectedAccountId: string | null;
  onSelect: (cluster: Cluster) => void;
  value?: Cluster | null;
}

const ClusterSelector = ({ value = null, selectedAccountId, onSelect }: ClusterSelectorProps) => {
  const [clusters, clustersAreLoading] = useClusters();
  const filteredClusters = clusters?.filter((c) => c.type === ClusterType.EKS && c.projectId === selectedAccountId);

  const handleChange = (e: SelectChangeEvent<string>) => {
    const selectedCluster = clusters?.find((c) => c.id === e.target.value);
    if (selectedCluster) {
      onSelect(selectedCluster);
    }
  };

  if (!filteredClusters) {
    return <Skeleton variant="rectangular" width={350} height={56} />;
  }

  return (
    <FormControl required>
      <InputLabel id="cluster-selector-label">Cluster</InputLabel>
      <Select
        label="Cluster"
        id="cluster-selector"
        labelId="cluster-selector-label"
        value={value?.id ?? ""}
        onChange={handleChange}
        disabled={clustersAreLoading}
        sx={{ width: 350 }}
      >
        {filteredClusters?.map((cluster) => (
          <MenuItem key={cluster.id} value={cluster.id}>
            {cluster.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ClusterSelector;
