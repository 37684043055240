import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { budgetTxt } from "../../../assets/texts/CloudAnalytics/budget";
import { ToolTipWrapper } from "../../../Components/TooltipWrapper";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Budget } from "../../../types";
import { userAccess } from "../utilities";
import { useBudgetInvestigateHandler, useEditBudgetHandler } from "./hooks";

export const ViewBudgetHeader = ({ budget }: { budget: Budget }) => {
  const { customer } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const handleInvestigateBudget = useBudgetInvestigateHandler({ budget, openInNewTab: true });
  const handleEdit = useEditBudgetHandler({ budget });

  const { editor: isCurrentUserEditor, owner: isCurrentUserOwner } = userAccess(currentUser.email, budget.data);
  const currentUserCanEdit = isCurrentUserOwner || isCurrentUserEditor;

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        mb: 3,
        mt: 6,
        justifyContent: "space-between",
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
        }}
      >
        <IconButton aria-label="Back" component={Link} to={`/customers/${customer.id}/analytics/budgets`} size="small">
          <ArrowBackIcon color="primary" />
        </IconButton>
        <Typography
          variant="h1"
          sx={{
            ml: 1,
            mr: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {budget?.data.name}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          gap: 2,
        }}
      >
        <Button startIcon={<OpenInNewIcon />} onClick={handleInvestigateBudget}>
          {budgetTxt.OPEN_IN_REPORT}
        </Button>
        <ToolTipWrapper title={currentUserCanEdit ? "" : budgetTxt.NO_EDIT_PERMISSION_TOOLTIP}>
          <Button variant="outlined" onClick={handleEdit} disabled={!currentUserCanEdit}>
            {budgetTxt.EDIT_BUDGET}
          </Button>
        </ToolTipWrapper>
      </Stack>
    </Stack>
  );
};
