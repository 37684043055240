import { useCallback, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import {
  type CloudAnalyticsModelAttributionGroupsModel,
  type Collaborators,
  type DashboardModelAttributionModel,
  type NotificationSettings,
  type PublicAccess,
  UserNotification,
  type UserPersonalNotificationModel,
} from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import { CircularProgress, Typography } from "@mui/material";

import { useApiContext } from "../../../api/context";
import { attributionText } from "../../../assets/texts";
import { useCAOwnerAssignerCheck } from "../../../Components/hooks/useCAOwnerAssignerCheck";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import {
  useErrorSnackbar,
  useSnackbar,
  useSuccessSnackbar,
} from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { type Attribution } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { type AnalyticsResourcesAttributions } from "../analyticsResources/types";
import {
  type AttributionsMenuOption,
  attributionsMenuOptions,
  AttributionsMenuOptionsValues,
  getNewCollaborators,
  isOwner,
  isUserEditor,
} from "../utilities";
import { type AllocationWRefRowItem } from "./AllocationBrowser";
import { shareAttribution } from "./db";
import { useDeleteAttributionRowAdapter } from "./DeleteAttributionValidation";

export const useCreateAllocationHandler = ({
  baseUrl,
  mixpanelEventName,
  newTab,
  prevPageUrl,
}: {
  baseUrl: string;
  mixpanelEventName: string;
  prevPageUrl?: string;
  newTab?: boolean;
}) => {
  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();

  return useCallback(async () => {
    const nextPageUrl = `${baseUrl}/create`;

    mixpanel.track(mixpanelEventName);

    if (newTab) {
      window.open(nextPageUrl, "_blank");
    } else {
      history.push(nextPageUrl, { prevPage: prevPageUrl || routeMatchURL });
    }
  }, [baseUrl, mixpanelEventName, newTab, history, prevPageUrl, routeMatchURL]);
};

export const useAttributionSubscribeHandler = (
  updateUserEmailNotification: (selectedNotifications: NotificationSettings) => Promise<void>,
  notificationSettings?: NotificationSettings
) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { userModel } = useUserContext();
  const { id } = userModel ?? {};
  const snackbar = useSnackbar();

  return useCallback(
    async ({
      attributionRef,
      isUserSubscribedToAttribution,
    }: {
      attributionRef: ModelReference<DashboardModelAttributionModel | CloudAnalyticsModelAttributionGroupsModel>;
      isUserSubscribedToAttribution: boolean;
    }) => {
      let dailyDigests = notificationSettings?.[UserNotification.DailyDigest]?.attributions || [];

      if (isUserSubscribedToAttribution) {
        dailyDigests = dailyDigests.filter((d) => d.id !== attributionRef.id);
      } else {
        dailyDigests = [...dailyDigests, attributionRef];
      }

      try {
        if (id && notificationSettings) {
          if (isDoitEmployee) {
            // a temporary hard-coded text, that should be removed sooner or later, this is why i don't extract it out to texts.js
            throw new Error("Doit employees cannot subscribe to daily digest at the moment");
          }
          if (dailyDigests.length === 0) {
            delete notificationSettings[UserNotification.DailyDigest];
          } else {
            notificationSettings[UserNotification.DailyDigest] = {
              attributions: dailyDigests,
            };
          }
          await updateUserEmailNotification(notificationSettings);
          snackbar.onOpen({
            message: isUserSubscribedToAttribution
              ? attributionText.SUCCESSFULLY_UNSUBSCRIBED
              : attributionText.SUCCESSFULLY_SUBSCRIBED,
            variant: "success",
            autoHideDuration: 5000,
          });
        }
      } catch (e: any) {
        snackbar.onOpen({
          message: e.message,
          variant: "error",
          autoHideDuration: 5000,
        });

        consoleErrorWithSentry(e);
      }
    },
    [snackbar, isDoitEmployee, notificationSettings, updateUserEmailNotification, id]
  );
};

export const useAllocationsThreeDotsMenu = ({
  row,
  handleShare,
  handleSettings,
  userEmailNotification,
  updateUserEmailNotification,
}: {
  row: AnalyticsResourcesAttributions | AllocationWRefRowItem;
  handleShare: () => void;
  handleSettings: () => void;
  userEmailNotification?: UserPersonalNotificationModel;
  updateUserEmailNotification: (selectedNotifications: NotificationSettings) => Promise<void>;
}) => {
  const [deleting, setDeleting] = useState(false);
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const isCAOwnershipAssigner = useCAOwnerAssignerCheck();
  const displayShareMenu = useMemo(
    () => row.data.type !== "preset" && (isUserEditor(currentUser.email, row.data) || isCAOwnershipAssigner),
    [currentUser.email, isCAOwnershipAssigner, row]
  );
  const attributionId = "id" in row ? row.id : row.ref.id;
  const attributionRef = row.ref;
  const dailyDigestAttributions =
    userEmailNotification?.selectedNotifications?.[UserNotification.DailyDigest]?.attributions;
  const isUserSubscribedToAttribution = dailyDigestAttributions?.map((a) => a.id).includes(attributionId) ?? false;

  const menuOptions = useMemo(
    () =>
      attributionsMenuOptions
        .filter((option) => {
          switch (option.value) {
            case AttributionsMenuOptionsValues.SUBSCRIBE:
              return !isUserSubscribedToAttribution && !isDoitEmployee;
            case AttributionsMenuOptionsValues.UNSUBSCRIBE:
              return isUserSubscribedToAttribution && !isDoitEmployee;
            case AttributionsMenuOptionsValues.DELETE:
              return isOwner(currentUser.email, { collaborators: row.data.collaborators } as Attribution);
            case AttributionsMenuOptionsValues.SHARE:
              return displayShareMenu;
            default:
              return true;
          }
        })
        .sort((b, a) => b.order - a.order),
    [isUserSubscribedToAttribution, row.data.collaborators, isDoitEmployee, currentUser.email, displayShareMenu]
  );
  const subscribeAction = useAttributionSubscribeHandler(
    updateUserEmailNotification,
    userEmailNotification?.selectedNotifications
  );
  const deleteAction = useDeleteAttributionRowAdapter({ row });

  const handleSelection = useCallback(
    async ({ option }: { option: AttributionsMenuOption }) => {
      switch (option.value) {
        case AttributionsMenuOptionsValues.SUBSCRIBE:
        case AttributionsMenuOptionsValues.UNSUBSCRIBE:
          await subscribeAction({ attributionRef, isUserSubscribedToAttribution });
          break;

        case AttributionsMenuOptionsValues.SHARE:
          handleShare();
          break;

        case AttributionsMenuOptionsValues.DELETE:
          setDeleting(true);
          await deleteAction({ attributionId });
          setDeleting(false);
          break;

        case AttributionsMenuOptionsValues.ANOMALY_DETECTION:
          handleSettings();
          break;
      }
    },
    [
      subscribeAction,
      isUserSubscribedToAttribution,
      handleShare,
      deleteAction,
      handleSettings,
      attributionRef,
      attributionId,
    ]
  );

  const calculateOption = useCallback(
    (total, option) => [
      ...total,
      {
        label: <Typography color={option.secondary ? "error" : undefined}>{option.label}</Typography>,
        action: () =>
          handleSelection({
            option,
          }),
        key: option.value,
      },
    ],
    [handleSelection]
  );
  const options = useMemo<Array<ThreeDotsMenuOption>>(
    () => menuOptions.reduce(calculateOption, []),
    [calculateOption, menuOptions]
  );
  return deleting ? <CircularProgress size={24} /> : <ThreeDotsMenu options={options} />;
};

export const useShareAttributionHandler = ({ attributions, closeDialog, setShareLoading }) => {
  const errorSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();
  const api = useApiContext();
  const { customer } = useCustomerContext();

  const attributionsWithoutPresets = useMemo(() => attributions.filter((x) => x.type !== "preset"), [attributions]);

  return useCallback(
    async (collaborators: Collaborators, role: PublicAccess) => {
      setShareLoading(true);

      try {
        for (const attribution of attributionsWithoutPresets) {
          await shareAttribution(
            api,
            customer.id,
            attribution.id,
            getNewCollaborators(collaborators, attribution.collaborators),
            role === "mixed" ? attribution.public : role
          );
        }
        successSnackbar(attributionText.SHARE_SUCCESS);
      } catch (e) {
        errorSnackbar(attributionText.SHARE_FAILED);
      } finally {
        closeDialog();
      }

      setShareLoading(false);
    },
    [setShareLoading, closeDialog, successSnackbar, attributionsWithoutPresets, api, customer.id, errorSnackbar]
  );
};
