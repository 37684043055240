import { useMetadata } from "../../Components/Dialogs/CloudAnalytics/hooks";
import { WizardForm } from "../../Components/WizardForm/WizardForm";
import { useCreateCommitmentApiRequest } from "./hooks";
import { CommitmentStep } from "./Steps/CommitmentStep";
import { ConfigurationStep } from "./Steps/ConfigurationStep";
import { GeneralStep } from "./Steps/GeneralStep";
import { type CommitmentFormValues } from "./types";
import { validationSchema } from "./utils";

export const CreateCommitmentFlow = () => {
  const createCommitmentApiRequest = useCreateCommitmentApiRequest();
  useMetadata();

  const initialValues: CommitmentFormValues = {
    contractType: "",
    planName: "",
    selectedContracts: [],
    currency: "USD",
    startDate: new Date(),
    numberOfPeriods: 1,
    periods: [],
    excess: false,
    excessRollOverPercentage: "",
    shortfall: false,
    shortfallRollOverPercentage: "",
  };

  const steps = [GeneralStep, CommitmentStep, ConfigurationStep];

  const handleSubmit = async (values: CommitmentFormValues) => {
    await createCommitmentApiRequest(values);
  };

  return (
    <WizardForm
      initialValues={initialValues}
      steps={steps}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    />
  );
};
