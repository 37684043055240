import { createContext, type FC, type ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { getModelByPath } from "@doitintl/cloudflow-commons";
import {
  type CloudFlowNodeType,
  ModelType,
  type NodeConfigTransformParameters,
  type NodeTransformation,
  type NodeTransformationType,
  type ReferencedNodeValue,
} from "@doitintl/cmp-models";
import { Form, Formik } from "formik";
import noop from "lodash/noop";
import * as yup from "yup";

import { type UpdateNodeData } from "../../../../types";
import { type NodeWitOutputModel } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { FormChangesListener } from "../../../Common/FormChangesListener";
import { baseTransformationSchema } from "./TransformationActionPicker";

export type TransformationNodeFormValues = {
  referencedNodeField: ReferencedNodeValue | undefined;
  transformation: NodeTransformation | null;
  type: NodeTransformationType | null;
};

export type TransformationNodeFormContext = {
  referenceableNodes: NodeWitOutputModel[];
  setTransformationSchema: (schema: yup.AnyObjectSchema) => void;
};

const TransformationNodeFormContext = createContext<TransformationNodeFormContext>({
  referenceableNodes: [],
  setTransformationSchema: noop,
});

function generateTransformationNodeSchema(referenceableNodes: NodeWitOutputModel[]) {
  return yup
    .object({
      referencedNodeField: yup
        .object({
          referencedNodeId: yup.string().required().default(""),
          referencedField: yup.array().of(yup.string().required()).default([]).required(),
        })
        .test(
          "referenced-field-model",
          "Referenced field must reference a field with type structure or list of structures",
          (value) => {
            const referencedNodeModel = referenceableNodes.find(({ id }) => id === value.referencedNodeId)?.outputModel;
            if (referencedNodeModel === undefined) {
              return false;
            }
            const referencedModel = getModelByPath(referencedNodeModel, value.referencedField);
            return (
              (referencedModel.type === ModelType.LIST && referencedModel.member.model.type === ModelType.STRUCTURE) ||
              referencedModel.type === ModelType.STRUCTURE
            );
          }
        ),
    })
    .concat(baseTransformationSchema)
    .noUnknown();
}

function generateFormInput(
  nodeId: string,
  nodeParameters: NodeConfigTransformParameters
): { nodeId: string; initialValues: TransformationNodeFormValues } {
  return {
    nodeId,
    initialValues: {
      referencedNodeField: {
        referencedNodeId: nodeParameters?.referencedNodeId,
        referencedField: nodeParameters?.referencedField,
      },
      type: nodeParameters?.transformations?.[0]?.type ?? null,
      transformation: nodeParameters?.transformations?.[0] ?? null,
    },
  };
}

export function useTransformationNodeSchemaContext() {
  return useContext(TransformationNodeFormContext);
}

export const TransformationNodeForm: FC<{
  children: ReactNode;
  nodeId: string;
  nodeParameters: NodeConfigTransformParameters;
  onNodeConfigChange: (delta: UpdateNodeData<CloudFlowNodeType.TRANSFORMATION>) => void;
  referenceableNodes: NodeWitOutputModel[];
}> = ({ children, nodeId, nodeParameters, onNodeConfigChange, referenceableNodes }) => {
  const [formInput, setFormInput] = useState(generateFormInput(nodeId, nodeParameters));

  useEffect(() => {
    setFormInput((current) => (current.nodeId === nodeId ? current : generateFormInput(nodeId, nodeParameters)));
  }, [nodeParameters, nodeId]);

  const [validationSchema, setValidationSchema] = useState<yup.Schema>(
    generateTransformationNodeSchema(referenceableNodes)
  );

  const [context, setContext] = useState<TransformationNodeFormContext>({
    referenceableNodes,
    setTransformationSchema: (transformation: yup.AnyObjectSchema) => {
      setValidationSchema((currentSchema) => currentSchema.concat(yup.object({ transformation })));
    },
  });
  useEffect(() => {
    setValidationSchema((currentSchema) => currentSchema.concat(generateTransformationNodeSchema(referenceableNodes)));
    setContext((context) => ({
      ...context,
      referenceableNodes,
    }));
  }, [referenceableNodes]);

  const onValuesChange = useCallback(
    ({
      referencedNodeField,
      transformation,
      type,
    }: {
      referencedNodeField: ReferencedNodeValue | undefined;
      transformation: NodeTransformation;
      type: NodeTransformationType;
    }) => {
      onNodeConfigChange({
        parameters: {
          referencedField: referencedNodeField?.referencedField ?? [],
          referencedNodeId: referencedNodeField?.referencedNodeId ?? "",
          transformations: !type ? [] : [transformation],
        },
      });
    },
    [onNodeConfigChange]
  );

  const onValidityChange = useCallback(
    (isValid: boolean) => {
      const errors: Record<string, string> = {};
      if (!isValid) {
        errors.params = "Invalid params";
      }
      onNodeConfigChange({ errors });
    },
    [onNodeConfigChange]
  );

  return (
    <TransformationNodeFormContext.Provider value={context}>
      <Formik
        validateOnChange
        validateOnBlur
        validateOnMount
        initialValues={formInput.initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={noop}
      >
        <Form>
          <FormChangesListener onValidityChange={onValidityChange} onValuesChange={onValuesChange} />
          {children}
        </Form>
      </Formik>
    </TransformationNodeFormContext.Provider>
  );
};
