import { type JSX } from "react";

import {
  AssetTypeDoiTCloudIntelligence,
  AssetTypeDoiTCloudNavigator,
  AssetTypeDoiTCloudSolve,
  type CommitmentPeriod,
  type ContractModel,
  type DiscountPeriod,
} from "@doitintl/cmp-models";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

import DataCouplet from "../../Components/DataCouplet";
import { isProduction } from "../../constants";
import { type Asset } from "../../types";
import { formatNumber, sanitizeDate } from "../../utils/common";
import { type FirestoreTimestamp } from "../../utils/firebase";
import { NotAvailable } from "../Assets/Tabs/components/typographics";
import { type ContractType } from "./ContractForm/types";

export function getAssetLabel(asset: Asset): string {
  switch (asset.data.type) {
    case "google-cloud":
      return `${asset.data.properties.billingAccountId} (${asset.data.properties.displayName})`;
    case "amazon-web-services":
      return `${asset.data.properties.accountId} (${asset.data.properties.name})`;
    case "g-suite":
      return `${asset.data.properties.subscription.skuName} (${asset.data.properties.customerDomain})`;
    case "google-cloud-project":
      return `Google Cloud Project (${asset.data.properties.projectId})`;
    case "office-365":
      return `${asset.data.properties.subscription.offerName} (${asset.data.properties.customerDomain})`;
    case "microsoft-azure":
      return `${asset.data.properties.subscription.skuDescription} (${asset.data.properties.customerDomain})`;
    case "zendesk":
      return `${asset.data.properties.subscription.skuName} (${asset.data.properties.customerDomain})`;
    case "bettercloud":
      return `${asset.data.properties.subscription.skuName} (${asset.data.properties.customerDomain})`;
    default:
      return asset.id;
  }
}

/**
 * Format a firebase timestamp to a date string compliant with the contract page date format
 * @param timestamp
 */
export function contractDate(timestamp?: ContractModel["startDate"] | null): string {
  if (!timestamp) {
    return NotAvailable;
  }

  return sanitizeDate(DateTime.fromJSDate(timestamp.toDate())).toLocaleString(DateTime.DATE_MED);
}

/**
 * Build a commitment period block for contract pages
 *
 * @param period - the commitment period in question. Could be one of many on a contract.
 * @param index - the index of the commitment period in the contract's commitment period array
 */
export const buildCommitmentPeriodBlock = (period: CommitmentPeriod, index: number): JSX.Element => (
  <Box component="section" key={`commitment-period-section-${index}`} data-cy={`commitmentBlock-${index}`}>
    <Typography
      variant="h3"
      sx={{
        pt: "1em",
      }}
    >{`Commitment period ${index + 1}`}</Typography>
    <Stack component="dl" spacing={2}>
      <DataCouplet key={`start-date-${index}`} field="Start date" value={contractDate(period.startDate)} />
      <DataCouplet key={`end-date-${index}`} field="End date" value={contractDate(period.endDate)} />
      <DataCouplet
        key={`commitment-amount-${index}`}
        field="Commitment amount"
        value={`$${formatNumber(period.value, 0)}`}
      />
      {!!period.discount && (
        <DataCouplet
          field="Period discount"
          value={`${formatNumber(period.discount, 0)}%`}
          key={`commitment-discount-${index}`}
        />
      )}
    </Stack>
  </Box>
);

export const isNext10Contract = (contractType: ContractType) =>
  [AssetTypeDoiTCloudNavigator, AssetTypeDoiTCloudSolve].includes(contractType);

export const isDciContract = (contractType: ContractType) => contractType === AssetTypeDoiTCloudIntelligence;

export const isStateDateWithinCurrentMonth = (contractStartDate: FirestoreTimestamp) => {
  const currentDate = new Date();
  return (
    currentDate.getMonth() === contractStartDate.toDate().getMonth() &&
    currentDate.getFullYear() === contractStartDate.toDate().getFullYear()
  );
};

export const canEditNext10Contract = ({
  isDoitContractOwner,
  isDoitContractAdmin,
  startDate,
  isActive,
}: {
  isDoitContractOwner: boolean;
  isDoitContractAdmin: boolean;
  startDate: FirestoreTimestamp;
  isActive: boolean;
}) =>
  isDoitContractOwner ||
  ((isDoitContractAdmin || !isProduction) &&
    ((isActive && isStateDateWithinCurrentMonth(startDate)) || (!isActive && startDate.toDate() > new Date())));

export const getDiscountPeriodDateTooltip = (period: DiscountPeriod): string => {
  const startDate = DateTime.fromJSDate(period.startDate.toDate()).toLocaleString(DateTime.DATE_MED);
  const endDate = period.endDate && DateTime.fromJSDate(period.endDate.toDate()).toLocaleString(DateTime.DATE_MED);

  if (!endDate) {
    return `(${startDate})`;
  }

  return `(${startDate} - ${endDate})`;
};
