import { useCallback, useEffect, useState } from "react";

import {
  Aggregator,
  AnalyticsDataSource,
  type AttributionFilter,
  Metadata,
  type MetricFilter,
  Positions,
  type ReportConfig,
  type ReportOptionalField,
  type TimeInterval,
} from "@doitintl/cmp-models";
import groupBy from "lodash/groupBy";
import intersection from "lodash/intersection";
import orderBy from "lodash/orderBy";
import partition from "lodash/partition";

import { useLocalStorage } from "../../../../Components/FilterTable/hook";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useTier } from "../../../../Context/TierProvider";
import { getMetadataByTypeKey, type KeyTypeValues } from "../../../../Pages/CloudAnalytics/api";
import { useReportSaverContext } from "../../../../Pages/CloudAnalytics/Context";
import { type Limit } from "../../../../Pages/CloudAnalytics/dialogs/limitsFilterDialog/limitsFilterDialog";
import {
  disableDimension,
  getChipsOrderForMetadata,
  getGroupOrderForMetadata,
  getLimitsFilterMD,
  getMetricFilterMD,
  getNewPosition,
  getOrganizationRef,
  limitsExist,
  timeIntervalOptions,
} from "../../../../Pages/CloudAnalytics/utilities";
import { type AttributionWRef, type MetadataOption, type MetricWSnap } from "../../../../types";
import { type AnalyticsMetadata } from "../useAnalyticsMetadata";
import { createFilter, groupByDataSource, isAccessibleAG } from "./analyticsDimensionsParser";
import { useDimensionManager } from "./useDimensionManager";

const MAX_RECENT_DIMENSIONS = 3;

export type UseAnalyticsDimensionsProps = {
  metadataSnapshots: AnalyticsMetadata;
  attributions?: AttributionWRef[];
  initialFilters?: ReportOptionalField[];
  timeInterval?: TimeInterval;
  config?: ReportConfig;
  calculatedMetric?: MetricWSnap;
  metricFilters?: MetricFilter[];
  isReport?: boolean;
  defaultDataSource?: AnalyticsDataSource;
};

export type UseAnalyticsDimensionsReturnType = {
  dimensions: MetadataOption[] | undefined;
  recentDimensions?: string[];
  dataSources?: string[];
  getLabelsDimensions: (
    v: KeyTypeValues[],
    filter?: AttributionFilter,
    position?: Positions,
    updateRecentDimensions?: boolean
  ) => Promise<{ newLabels: MetadataOption[]; removedRecentKey: string }>;
  resetLimit: () => boolean;
  handleDimensionSelect: (selectedChip: MetadataOption[], fromList: boolean, filterExistingChip: boolean) => void;
  handleAggregatorChange: (aggregator: Aggregator) => void;
  handleMetricFiltersChange: (filters: MetricFilter[]) => void;
  handleLimitsFilterChange: (limits: Limit[]) => void;
  handleDeleteDimension: (dimension: MetadataOption[]) => void;
  handleReportFilterChange: (filterId: string, type: string, value, inverse?: boolean) => void;
  handleTimeIntervalChange: (newTimeInterval: string) => void;
  handleTreemapChange: () => void;
  handleMetricChange: () => void;
  handleSortComparativeDimensions: () => void;
  handleAttributionMdSelect: () => void;
  handleDimensionLabelsChange: (
    values: KeyTypeValues[],
    filter?: AttributionFilter,
    position?: Positions
  ) => Promise<MetadataOption | null>;
  handleDraggableItemsUpdate: (items: Record<string, string[]>) => void;
  handleIncludeDatahubUpdated: (
    dataSource: AnalyticsDataSource.BILLING | AnalyticsDataSource.BILLING_DATAHUB,
    timeInterval: string
  ) => void;
};

type DataSourceDimension = {
  [key in AnalyticsDataSource]: MetadataOption[] | undefined;
};

export const useAnalyticsDimensions = ({
  metadataSnapshots,
  attributions,
  timeInterval,
  initialFilters,
  config,
  calculatedMetric,
  metricFilters,
  isReport,
  defaultDataSource: dataSource = AnalyticsDataSource.BILLING,
}: UseAnalyticsDimensionsProps): UseAnalyticsDimensionsReturnType => {
  const { isFeatureEntitled } = useTier();
  const { userOrganization, customerOrPresentationModeCustomer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const [dimensions, setDimensions] = useState<DataSourceDimension | undefined>();
  const [dataSources, setDataSources] = useState<string[]>([]);
  const [recentDimensions, setRecentDimensions] = useLocalStorage<string[]>("recentDimensions", []);
  const {
    reportSaver: { hasUnsavedChanges },
  } = useReportSaverContext();

  const { createDimension, initialDimensionFromSnapshot, getDimensionSubtype } = useDimensionManager({
    attributions,
    timeInterval,
    config,
    calculatedMetric,
    recentDimensions,
  });

  const getLabelsDimensions = useCallback(
    async (
      values: KeyTypeValues[],
      filter?: AttributionFilter,
      position?: Positions,
      updateRecentDimensions?: boolean
    ) => {
      if (!values.length) {
        return { newLabels: [], removedRecentKey: "" };
      }

      const organization = getOrganizationRef(
        isDoitEmployee,
        userOrganization,
        customerOrPresentationModeCustomer.ref.id
      );
      const querySnaps = await getMetadataByTypeKey(values, organization, customerOrPresentationModeCustomer);
      const filteredQuery = querySnaps.filter((querySnap) => !querySnap.empty);
      let removedRecentKey = "";

      const newLabels = filteredQuery.flatMap((querySnap) => {
        const metadataDocSnapsByDataSource = groupByDataSource(querySnap.docs);
        let dataSourceDocSnaps = metadataDocSnapsByDataSource[dataSource];
        if (!dataSourceDocSnaps?.length && dataSource === AnalyticsDataSource.BILLING_DATAHUB) {
          dataSourceDocSnaps = metadataDocSnapsByDataSource[AnalyticsDataSource.BILLING];
        }
        const { valuesUnion, gcpValues, awsValues, azureValues, data, datahub, snowflake } =
          initialDimensionFromSnapshot(dataSourceDocSnaps);
        const labelKey = data.label.startsWith("EKS") || data.label.startsWith("eks:") ? data.label : "";
        const md = createDimension({
          data,
          labelKey,
          values: valuesUnion,
          awsValues,
          gcpValues,
          azureValues,
          snowflake,
          id: dataSourceDocSnaps[0].id,
          existingFilter: filter,
          dataSource,
          datahub,
        });

        if (updateRecentDimensions) {
          const newRecentDimensions = [...recentDimensions];
          if (!newRecentDimensions.includes(md.id)) {
            newRecentDimensions.push(md.id);
            md.data.subType = Metadata.RECENT;
            if (newRecentDimensions.length > MAX_RECENT_DIMENSIONS) {
              removedRecentKey = newRecentDimensions.shift() ?? "";
            }
          }
          setRecentDimensions(newRecentDimensions);
        }

        if (position) {
          md._position = position;
          md._visible = true;
        }
        return md;
      });

      return { newLabels, removedRecentKey };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isDoitEmployee,
      customerOrPresentationModeCustomer.ref,
      userOrganization,
      dataSource,
      attributions,
      recentDimensions,
    ]
  );

  const spliceDimensions = (
    originalDimensions: MetadataOption[],
    newDimensions: MetadataOption[],
    placeNewDimensionsAtEnd: boolean = false
  ) => {
    if (placeNewDimensionsAtEnd) {
      newDimensions.forEach((md) => {
        const i = originalDimensions.findIndex((prevMd) => prevMd.id === md.id);
        if (i > -1) {
          originalDimensions.splice(i, 1);
        }
      });
      return [...originalDimensions, ...newDimensions];
    }

    originalDimensions.forEach((md, index) => {
      const i = newDimensions.findIndex((newMd) => newMd.id === md.id && newMd.dataSource === md.dataSource);
      if (i > -1) {
        originalDimensions.splice(index, 1, newDimensions[i]);
      }
    });

    return originalDimensions;
  };

  const deleteDimension = (dimensions: MetadataOption[], dimension: MetadataOption) => {
    const deleteIndex = dimensions.findIndex((md) => md.id === dimension.id);
    if (dimensions[deleteIndex]) {
      dimensions[deleteIndex] = {
        ...dimensions[deleteIndex],
        ...createFilter(),
        _visible: false,
        _position: Positions.UNUSED,
      };

      // the removed chip is the METRIC one
      if (dimension.data.type === Metadata.METRIC && dimension._metricFilter) {
        dimensions[deleteIndex]._metricFilter = false;
      }
    }
  };

  const updateLimitDimension = useCallback((dimensions: MetadataOption[]) => {
    // check if there is rows, and set LIMITS disabled to false
    const doesRowExists = dimensions.some((md) => md._position === Positions.ROW);
    const limitIndex = dimensions.findIndex((md) => md.data.type === Metadata.LIMITS);
    if (limitIndex === -1) {
      return;
    }
    dimensions[limitIndex]._disabled = !doesRowExists;
    if (dimensions[limitIndex]._disabled) {
      deleteDimension(dimensions, dimensions[limitIndex]);
    }

    if (doesRowExists) {
      // if row exists, check if there also limit
      const doesMdRowWithLimitsExists = dimensions.some(
        (md) => md._position === Positions.ROW && md._limit && md._limit > 0
      );
      if (!doesMdRowWithLimitsExists && dimensions[limitIndex]._metricFilter) {
        dimensions[limitIndex]._metricFilter = false;
      }
    }
  }, []);

  const dimensionSelectSetter = useCallback(
    (
      prevDimensions: MetadataOption[],
      selectedDimensions: MetadataOption[],
      fromList: boolean,
      filterExistingChip: boolean,
      disabled?: boolean,
      dataSourceChanged?: boolean,
      recentDimensions?: string[]
    ) => {
      selectedDimensions.forEach((dimension) => {
        const i = prevDimensions.findIndex((md) => md.id === dimension.id);
        if (i > -1) {
          const md = prevDimensions[i];
          md._isDragging = false;
          md._position = getNewPosition(dimension._position, fromList);
          md._visible = true;
          if (dataSourceChanged) {
            md._filter = intersection(md.data.values, dimension._filter);
          }
          md._inverse = dimension._inverse;
          md._regexp = dimension._regexp;
          if (md._position !== Positions.ROW) {
            md._limit = null;
            md._limitOrder = null;
            md._limitMetric = null;
          }
          md._disabled = disabled ?? md._disabled;
          updateLimitDimension(prevDimensions);
          if (!filterExistingChip) {
            prevDimensions.push(prevDimensions.splice(i, 1)[0]);
          }

          if (recentDimensions?.includes(md.id)) {
            md.data.subType = Metadata.RECENT;
          }
        }
      });
      return prevDimensions;
    },
    [updateLimitDimension]
  );

  const handleDimensionSelect = useCallback(
    (
      selectedDimension: MetadataOption[],
      fromList: boolean,
      filterExistingChip: boolean,
      customDataSource?: AnalyticsDataSource
    ) => {
      const dataSourceToSet = customDataSource || dataSource;

      setDimensions((prevDimensions) => {
        const dataSourcedDimension = prevDimensions?.[dataSourceToSet];
        if (!prevDimensions || !dataSourcedDimension) {
          return prevDimensions;
        }

        const newRecentDimensions = [...recentDimensions];
        const newDataSourcedDimension = [...dataSourcedDimension];

        selectedDimension.forEach(({ id }) => {
          if (!newRecentDimensions.includes(id)) {
            newRecentDimensions.push(id);
            if (newRecentDimensions.length > MAX_RECENT_DIMENSIONS) {
              const removedRecentKey = newRecentDimensions.shift();
              const removedRecentMd = newDataSourcedDimension.find((md) => md.id === removedRecentKey);
              if (removedRecentMd) {
                removedRecentMd.data.subType = getDimensionSubtype(removedRecentMd);
              }
            }
          }
        });

        setRecentDimensions(newRecentDimensions);
        return {
          ...prevDimensions,
          [dataSourceToSet]: dimensionSelectSetter(
            newDataSourcedDimension,
            selectedDimension,
            fromList,
            filterExistingChip,
            false,
            dataSourceToSet !== dataSource,
            newRecentDimensions
          ),
        };
      });
    },
    [dataSource, recentDimensions, setRecentDimensions, dimensionSelectSetter, getDimensionSubtype]
  );

  const handleAggregatorChange = useCallback(
    (aggregator: Aggregator) => {
      setDimensions((prevDimensions) => {
        const dataSourcedDimension = prevDimensions?.[dataSource];
        if (!prevDimensions || !dataSourcedDimension) {
          return prevDimensions;
        }
        let updatedDataSourcedDimension;
        if (aggregator !== Aggregator.COUNT) {
          updatedDataSourcedDimension = dataSourcedDimension.map((dimension) => {
            if (dimension._position === Positions.COUNT) {
              return {
                ...dimension,
                _position: Positions.UNUSED,
                _visible: false,
              };
            }
            return dimension;
          });
        } else {
          const temp = [...dataSourcedDimension];
          const lastDimension = temp.findLast((dimension) => dimension._position === Positions.ROW);
          if (lastDimension) {
            lastDimension._position = Positions.COUNT;
          }
          updatedDataSourcedDimension = temp;
        }

        return {
          ...prevDimensions,
          [dataSource]: updatedDataSourcedDimension,
        };
      });
    },
    [dataSource]
  );

  const handleMetricFiltersChange = useCallback(
    (filters: MetricFilter[]) => {
      setDimensions((prevDimensions) => {
        const dataSourcedDimension = prevDimensions?.[dataSource];
        if (!prevDimensions || !dataSourcedDimension) {
          return prevDimensions;
        }
        const updatedDataSourcedDimension = dataSourcedDimension.map((md) => {
          if (md.data.type === Metadata.METRIC) {
            md._metricFilter = filters.length > 0;
          }
          return md;
        });
        return {
          ...prevDimensions,
          [dataSource]: updatedDataSourcedDimension,
        };
      });
    },
    [dataSource]
  );

  const handleLimitsFilterChange = useCallback(
    (limits: Limit[]) => {
      setDimensions((prevDimensions) => {
        const dataSourcedDimension = prevDimensions?.[dataSource];
        if (!prevDimensions || !dataSourcedDimension) {
          return prevDimensions;
        }
        const limitsMap = new Map();
        for (const l of limits) {
          limitsMap.set(l.id, l);
        }

        const updatedDataSourcedDimension = dataSourcedDimension.map((md) => {
          if (limitsMap.has(md.id)) {
            const l = limitsMap.get(md.id);
            md._limit = parseInt(l.limit) || 0;
            md._limitOrder = l.limitOrder;
            md._limitMetric = l.limitMetric;
          }

          if (md.data.type === Metadata.LIMITS) {
            md._metricFilter = limits.length > 0;
          }
          return md;
        });
        return {
          ...prevDimensions,
          [dataSource]: updatedDataSourcedDimension,
        };
      });
    },
    [dataSource]
  );

  const deleteLimitDimension = (dimensions: MetadataOption[], dimension: MetadataOption) => {
    if (dimension.data.type === Metadata.LIMITS && dimension._metricFilter) {
      return dimensions.map((md) => ({
        ...md,
        _limit: null,
        limitOrder: null,
        _limitMetric: null,
      }));
    }
    return dimensions;
  };

  const handleDeleteDimension = useCallback(
    (dimensions: MetadataOption[]) => {
      setDimensions((prevDimensions) => {
        const dataSourcedDimension = prevDimensions?.[dataSource];
        if (!prevDimensions || !dataSourcedDimension) {
          return prevDimensions;
        }
        let temp = [...dataSourcedDimension];
        dimensions.forEach((dimension) => {
          deleteDimension(temp, dimension);
          temp = deleteLimitDimension(temp, dimension);
          updateLimitDimension(temp);
        });
        return {
          ...prevDimensions,
          [dataSource]: temp,
        };
      });
    },
    [updateLimitDimension, dataSource]
  );

  const handleReportFilterChange = useCallback(
    (filterId: string, type: string, value, inverse?: boolean) => {
      setDimensions((prevDimensions) => {
        const dataSourcedDimension = prevDimensions?.[dataSource];
        if (!prevDimensions || !dataSourcedDimension) {
          return prevDimensions;
        }
        const temp = [...dataSourcedDimension];
        const i = temp.findIndex((md) => md.id === filterId);
        if (type === "regexp") {
          temp[i]._filter = null;
          temp[i]._regexp = value;
        } else {
          temp[i]._filter = value;
          temp[i]._regexp = null;
        }
        temp[i]._inverse = inverse ?? false;
        return {
          ...prevDimensions,
          [dataSource]: temp,
        };
      });
    },
    [dataSource]
  );

  const handleTimeIntervalChange = useCallback(
    (newTimeInterval: string, customDataSource?: AnalyticsDataSource) => {
      const dataSourceToSet = customDataSource || dataSource;
      setDimensions((prevDimensions) => {
        const dataSourcedDimension = prevDimensions?.[dataSourceToSet];
        if (!prevDimensions || !dataSourcedDimension) {
          return prevDimensions;
        }
        const [datetimeMetadata, otherMetadata] = partition(
          dataSourcedDimension,
          (md) => md.data.type === Metadata.DATETIME
        );
        const timeIntervalOption = timeIntervalOptions.find((t) => t.value === newTimeInterval);
        const updatedDataSourcedDimension = [
          ...otherMetadata,
          ...orderBy(datetimeMetadata, "data.order").map((md) => {
            md._visible = !!timeIntervalOption?.visible.includes(md.data.key);
            md._available = timeIntervalOption?.available.includes(md.data.key);
            md._position = Positions.COL;
            return md;
          }),
        ];
        return {
          ...prevDimensions,
          [dataSourceToSet]: updatedDataSourcedDimension,
        };
      });
    },
    [dataSource]
  );

  const handleTreemapChange = useCallback(() => {
    setDimensions((prevDimensions) => {
      const dataSourcedDimension = prevDimensions?.[dataSource];
      if (!prevDimensions || !dataSourcedDimension) {
        return prevDimensions;
      }
      const temp = [...dataSourcedDimension];
      const colChips = temp.filter((md) => md._position === Positions.COL);
      colChips.forEach((chip) => {
        chip._position = Positions.UNUSED;
        chip._limit = null;
        if (chip.data.type === Metadata.DATETIME) {
          chip._visible = false;
        }
      });
      return {
        ...prevDimensions,
        [dataSource]: temp,
      };
    });
  }, [dataSource]);

  const resetLimit = useCallback((): boolean => {
    let mdWasReset = false;
    setDimensions((prevDimensions) => {
      const dataSourcedDimension = prevDimensions?.[dataSource];
      if (!prevDimensions || !dataSourcedDimension) {
        return prevDimensions;
      }
      const temp = [...dataSourcedDimension];
      temp.forEach((md) => {
        if (md._position === Positions.ROW && md._limit) {
          md._limit = null;
          md._limitOrder = null;
          md._limitMetric = null;

          // check if it changed correctly
          mdWasReset = true;
        }
      });
      return {
        ...prevDimensions,
        [dataSource]: temp,
      };
    });
    return mdWasReset;
  }, [dataSource]);

  const handleMetricChange = useCallback(() => {
    setDimensions((prevDimensions) => {
      const dataSourcedDimension = prevDimensions?.[dataSource];
      if (!prevDimensions || !dataSourcedDimension) {
        return prevDimensions;
      }
      const temp = [...dataSourcedDimension];
      temp.forEach((md) => {
        if (md._position === Positions.UNUSED && md.data.type === Metadata.ATTRIBUTION) {
          md._visible = false;
          md._disabled = false;
          md._filter = [];
        }
      });
      return {
        ...prevDimensions,
        [dataSource]: temp,
      };
    });
  }, [dataSource]);

  const getTimeOptionRelatedChips = (datetimeMetadata: MetadataOption[], newTimeInterval?: TimeInterval) => {
    const timeIntervalOption = timeIntervalOptions.find((t) => t.value === newTimeInterval);
    return orderBy(datetimeMetadata, "data.order").map((md) => {
      const isIncluded = timeIntervalOption?.visible.includes(md.data.key);
      md._visible = !!isIncluded;
      md._available = isIncluded;
      md._position = isIncluded ? Positions.COL : Positions.UNUSED;
      return md;
    });
  };

  const handleSortComparativeDimensions = useCallback(() => {
    setDimensions((prevDimensions) => {
      const dataSourcedDimension = prevDimensions?.[dataSource];
      if (!prevDimensions || !dataSourcedDimension) {
        return prevDimensions;
      }
      const [datetimeMetadata, otherMetadata] = partition(
        dataSourcedDimension,
        (md) => md.data.type === Metadata.DATETIME
      );
      const selectedTimeChips = datetimeMetadata.filter((md) => md._position === Positions.COL && md._visible);
      const otherDimensions = otherMetadata.map((md) => {
        if (md._position === Positions.COL) {
          return disableDimension(md);
        }
        return md;
      });
      let updatedDataSourcedDimension;
      if (!selectedTimeChips.length) {
        const newTimeChips = getTimeOptionRelatedChips(datetimeMetadata, timeInterval);
        updatedDataSourcedDimension = [...newTimeChips, ...otherDimensions];
      } else {
        updatedDataSourcedDimension = [...orderBy(datetimeMetadata, "data.order"), ...otherDimensions];
      }
      return {
        ...prevDimensions,
        [dataSource]: updatedDataSourcedDimension,
      };
    });
  }, [timeInterval, dataSource]);

  const handleAttributionMdSelect = useCallback(() => {
    setDimensions((prevDimensions) => {
      const dataSourcedDimension = prevDimensions?.[dataSource];
      if (!prevDimensions || !dataSourcedDimension) {
        return prevDimensions;
      }
      const temp = [...dataSourcedDimension];
      let updatedDataSourcedDimension;
      const attributionMd = temp.find((md) => md.data.type === Metadata.ATTRIBUTION);
      if (attributionMd) {
        attributionMd._position = Positions.UNUSED;
        attributionMd._disabled = true;
        updatedDataSourcedDimension = dimensionSelectSetter(temp, [attributionMd], true, false, true);
      } else {
        updatedDataSourcedDimension = temp;
      }
      return {
        ...prevDimensions,
        [dataSource]: updatedDataSourcedDimension,
      };
    });
  }, [dimensionSelectSetter, dataSource]);

  const handleDimensionLabelsChange = useCallback(
    async (values: KeyTypeValues[], filter?: AttributionFilter, position?: Positions) => {
      const { newLabels, removedRecentKey } = await getLabelsDimensions(values, filter, position, true);

      setDimensions((prevDimensions) => {
        const dataSourcedDimension = prevDimensions?.[dataSource];
        if (!prevDimensions || !dataSourcedDimension) {
          return prevDimensions;
        }

        const newDataSourcedDimension = [...dataSourcedDimension];

        if (removedRecentKey) {
          const removedRecentMd = newDataSourcedDimension.find((md) => md.id === removedRecentKey);
          if (removedRecentMd) {
            removedRecentMd.data.subType = getDimensionSubtype(removedRecentMd);
          }
        }

        return { ...prevDimensions, [dataSource]: spliceDimensions(dataSourcedDimension, newLabels, true) };
      });

      return newLabels.length ? newLabels[0] : null;
    },
    [getLabelsDimensions, dataSource, getDimensionSubtype]
  );

  const updateOptionProps = useCallback((option: MetadataOption, position: Positions) => {
    if (option._position !== position) {
      option._position = position;

      if (position !== Positions.ROW) {
        option._limit = null;
        option._limitOrder = null;
        option._limitMetric = null;
      }
      return true;
    }
    return false;
  }, []);

  const updateDimensionOnDrag = useCallback(
    (newDimensions: MetadataOption[], values: string[], positionKey: Positions) => {
      let prevOptionIdx = newDimensions.findIndex((option) => option.id === values[0]);
      const prevOption = newDimensions[prevOptionIdx];
      const prevOptionUpdated = updateOptionProps(prevOption, positionKey);
      prevOptionUpdated && newDimensions.splice(prevOptionIdx, 1, prevOption);

      for (let i = 1; i < values.length; i++) {
        const curOptionIdx = newDimensions.findIndex((option) => option.id === values[i]);
        const curOption = newDimensions[curOptionIdx];
        const updated = updateOptionProps(curOption, positionKey);

        if (curOptionIdx < prevOptionIdx) {
          newDimensions.splice(prevOptionIdx + 1, 0, curOption);
          newDimensions.splice(curOptionIdx, 1);
        } else {
          updated && newDimensions.splice(curOptionIdx, 1, curOption);
          prevOptionIdx = curOptionIdx;
        }
      }
    },
    [updateOptionProps]
  );

  const handleDraggableItemsUpdate = useCallback(
    (items: Record<Positions, string[]>) => {
      setDimensions((prevDimensions) => {
        const dataSourcedDimension = prevDimensions?.[dataSource];
        if (!prevDimensions || !dataSourcedDimension) {
          return prevDimensions;
        }
        const newDimensions = [...(dataSourcedDimension || [])];

        for (const [positionKey, values] of Object.entries(items)) {
          if (values.length === 0) {
            continue;
          }
          updateDimensionOnDrag(newDimensions, values, positionKey as Positions);
        }
        updateLimitDimension(newDimensions);
        return {
          ...prevDimensions,
          [dataSource]: newDimensions,
        };
      });
    },
    [updateDimensionOnDrag, updateLimitDimension, dataSource]
  );

  useEffect(() => {
    (async () => {
      if (hasUnsavedChanges) {
        // avoiding reset of the dimensions when there are unsaved changes -
        // can happen if another user on another browser changes some attribution
        return;
      }
      const dataSourceQueries = groupByDataSource(metadataSnapshots);
      const dataSourcesFromMd = Object.keys(dataSourceQueries);
      setDataSources(dataSourcesFromMd);
      const aggGroups = Object.entries(dataSourceQueries).flatMap(([dataSource, metadata]) =>
        Object.entries(groupBy(metadata, "id")).flatMap(([mdId, docSnaps]) => {
          const { valuesUnion, gcpValues, awsValues, azureValues, data, datahub, snowflake } =
            initialDimensionFromSnapshot(docSnaps);
          // if there are no values, this means that the customer does not have access or reports with attributions configured
          if (data.type === Metadata.ATTRIBUTION && valuesUnion.length === 0) {
            return [];
          }

          if (
            data.type === Metadata.ATTRIBUTION_GROUP &&
            !isDoitEmployee &&
            !isAccessibleAG(data, config, isFeatureEntitled)
          ) {
            return [];
          }

          if (data.type === Metadata.OPTIONAL) {
            return valuesUnion.map((labelKey) =>
              createDimension({ data, labelKey, dataSource: dataSource as AnalyticsDataSource })
            );
          }

          return createDimension({
            data,
            values: valuesUnion,
            awsValues,
            gcpValues,
            azureValues,
            snowflake,
            datahub,
            id: mdId,
            dataSource: dataSource as AnalyticsDataSource,
          });
        })
      );

      const mdGroups = groupBy(aggGroups, (agg) => agg.data.type);
      let newMetadata = getGroupOrderForMetadata(mdGroups, isReport);

      if (metricFilters) {
        const hasRowItems = newMetadata.some((md) => md._position === Positions.ROW);
        const metric = getMetricFilterMD(metricFilters);
        const limit = getLimitsFilterMD(limitsExist(newMetadata), hasRowItems);
        Object.values(AnalyticsDataSource).forEach((dataSource) => {
          metric.dataSource = dataSource;
          limit.dataSource = dataSource;
          newMetadata.unshift({ ...metric }, { ...limit });
        });
      }

      if (config) {
        newMetadata = getChipsOrderForMetadata(newMetadata, config);
      }
      if (initialFilters?.length) {
        const { newLabels } = await getLabelsDimensions(initialFilters);
        newMetadata = spliceDimensions(newMetadata, newLabels);
        updateLimitDimension(newMetadata);
      }
      const groupedByDataSource = groupBy(newMetadata, (md) => md.dataSource) as DataSourceDimension;
      setDimensions(groupedByDataSource);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributions, metadataSnapshots, setDimensions, hasUnsavedChanges]);

  const handleIncludeDatahubUpdated = (
    newDataSource: AnalyticsDataSource.BILLING | AnalyticsDataSource.BILLING_DATAHUB,
    timeInterval: string
  ) => {
    const visibleDimensions = dimensions?.[dataSource]?.filter((md) => md._visible) || [];
    const updatedDimensions = visibleDimensions.map((md) => ({ ...md, dataSource: newDataSource }));
    handleDimensionSelect(updatedDimensions, true, false, newDataSource);
    handleTimeIntervalChange(timeInterval, newDataSource);
  };

  return {
    dimensions: dimensions?.[dataSource],
    recentDimensions,
    dataSources,
    getLabelsDimensions,
    handleDimensionSelect,
    handleAggregatorChange,
    handleMetricFiltersChange,
    handleLimitsFilterChange,
    handleDeleteDimension,
    handleReportFilterChange,
    handleTimeIntervalChange,
    handleTreemapChange,
    resetLimit,
    handleMetricChange,
    handleSortComparativeDimensions,
    handleAttributionMdSelect,
    handleDimensionLabelsChange,
    handleDraggableItemsUpdate,
    handleIncludeDatahubUpdated,
  };
};
