import { type FC } from "react";

import { type MapApiServiceModelDescriptor, type Member } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";
import { FieldArray, type FieldInputProps } from "formik";

import { GenericForm } from "../ApiActionParametersForm";
import { getInitialValueForModel } from "../useApiActionParametersSchema";
import { AppendButton } from "./common/AppendButton";
import { Fieldset } from "./wrappers/Fieldset";

export const MapParam: FC<{
  fieldPath: string;
  fieldProps: FieldInputProps<{ key: unknown; value: unknown }[]>;
  label: string;
  onRemove?: () => void;
  inputModel: MapApiServiceModelDescriptor<Member>;
}> = ({ fieldPath, fieldProps, label, onRemove, inputModel }) => (
  <Fieldset label={label} onRemove={onRemove}>
    <FieldArray
      name={fieldProps.name}
      render={(arrayHelpers) => (
        <Stack>
          {fieldProps.value.map((_, index) => (
            <Fieldset
              key={fieldPath + index.toString()}
              label={`${label} ${index + 1}`}
              onRemove={() => arrayHelpers.remove(index)}
              nestedInList
            >
              <GenericForm
                inputModel={inputModel.keyMember.model}
                fieldPath={`${fieldPath}[${index}].${inputModel.keyMemberName}`}
                label={inputModel.keyMemberName}
                disallowReferencedField
              />
              <GenericForm
                inputModel={inputModel.valueMember.model}
                fieldPath={`${fieldPath}[${index}].${inputModel.valueMemberName}`}
                label={inputModel.valueMemberName}
              />
            </Fieldset>
          ))}
          <AppendButton
            onClick={() => {
              arrayHelpers.push({
                [inputModel.keyMemberName]: getInitialValueForModel(inputModel.keyMember.model),
                [inputModel.valueMemberName]: getInitialValueForModel(inputModel.valueMember.model),
              });
            }}
          >
            Append {label}
          </AppendButton>
        </Stack>
      )}
    />
  </Fieldset>
);
