import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";

import BlurredChart from "../../assets/ava/blurred-chart.png";
import { ContactSalesButton } from "../NoEntitlement/NoEntitlement";

export const UpsellNotification = () => (
  <Card style={{ position: "relative" }}>
    <CardMedia component="img" image={BlurredChart} alt="blurred chart" />
    <CardContent
      style={{
        position: "absolute",
        top: "5%",
        left: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <Typography variant="subtitle1" style={{ color: "#000000DE", fontWeight: 500, fontFamily: "Roboto" }}>
        Ava can give you cost insights in Analytics
      </Typography>
      <Typography mt={1} variant="body1" style={{ color: "#00000099", fontWeight: 400, fontFamily: "Roboto" }}>
        Upgrade your DoiT Cloud Navigator plan to get cost insights
      </Typography>
      <Typography
        variant="body1"
        style={{ color: "#00000099", fontWeight: 400, fontFamily: "Roboto", textAlign: "center" }}
      >
        into your cloud spend
      </Typography>
      <Box mt={1}>
        <ContactSalesButton customMixpanelEventId="ava.upselling-contact.button" />
      </Box>
    </CardContent>
  </Card>
);
