import { type ServiceTier, type ServiceType } from "@doitintl/cmp-models";
import capitalize from "lodash/capitalize";

import { dciTiers, serviceTiers } from "../consts";

export const getServiceTypeLabel = (serviceType: ServiceType): string => {
  if (serviceType === "doit-cloud-intelligence") {
    return "DoiT Cloud Intelligence";
  }

  return `DoiT ${capitalize(serviceType)}`;
};

export const getServiceTiers = (serviceType: ServiceType): ServiceTier[] => {
  if (serviceType === "doit-cloud-intelligence") {
    return dciTiers;
  }

  return serviceTiers;
};
