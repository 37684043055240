import { useMemo } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { type DateTime } from "luxon";

import { sanitizeKeepingLocalDate } from "../../../../../../utils/common";
import { type DiscountPeriodType } from "../../../types";

type Props = {
  index: number;
  discount: DiscountPeriodType;
  errors?: Record<string, boolean>;
  isLastPeriod: boolean;
  minDiscountDate: DateTime;
  maxDiscountDate: DateTime | null;
  isCommitment?: boolean;
  onChange: (property: keyof DiscountPeriodType, value: DateTime | number | boolean | null) => void;
  onDeleteDiscountPeriod: () => void;
};

const ContractDiscountPeriod = ({
  index,
  discount,
  isLastPeriod,
  minDiscountDate,
  maxDiscountDate,
  isCommitment,
  onChange,
  onDeleteDiscountPeriod,
  errors,
}: Props) => {
  const startDateError = useMemo(() => {
    if (!errors?.startDate) {
      return "";
    }

    if (discount.startDate <= minDiscountDate) {
      return "Enter a date after the previous period's end date";
    }
  }, [discount.startDate, errors?.startDate, minDiscountDate]);

  const endDateError = useMemo(() => {
    if (!errors?.endDate) {
      return "";
    }

    if (discount.startDate && discount.endDate && discount.startDate > discount.endDate) {
      return "End date must be after start date";
    }

    if ((!isLastPeriod || !discount.hasNoEndDate) && !discount.endDate) {
      return "End date is required";
    }
  }, [discount.endDate, discount.hasNoEndDate, discount.startDate, errors?.endDate, isLastPeriod]);

  return (
    <Grid container spacing={2} data-cy={`discount-period-${index}}`}>
      <Grid
        container
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
          }}
        >
          Discount period {index + 1}
        </Typography>
        <Button startIcon={<DeleteIcon />} onClick={onDeleteDiscountPeriod} color="error">
          Delete period
        </Button>
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 6,
        }}
      >
        <DatePicker
          label="Discount start date"
          value={discount.startDate}
          onChange={(newValue) => {
            if (newValue) {
              onChange("startDate", sanitizeKeepingLocalDate(newValue));
            }
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              error={errors?.startDate}
              helperText={startDateError}
              data-cy={`discount-${index}-start-date`}
              required
            />
          )}
          minDate={minDiscountDate}
          maxDate={maxDiscountDate}
          inputFormat="dd LLL, yyyy"
        />
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 6,
        }}
      >
        <DatePicker
          label="Discount end date (exclusive)"
          value={discount.endDate}
          onChange={(newValue) => {
            if (newValue) {
              onChange("endDate", sanitizeKeepingLocalDate(newValue));
            }
          }}
          disabled={discount.hasNoEndDate}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              required={!isLastPeriod}
              error={errors?.endDate}
              helperText={endDateError}
              data-cy={`discount-${index}-end-date`}
            />
          )}
          minDate={discount.startDate}
          maxDate={maxDiscountDate}
          defaultCalendarMonth={discount.startDate}
          inputFormat="dd LLL, yyyy"
        />
      </Grid>
      {!isCommitment && (
        <Grid size={12}>
          <Tooltip
            title={!isLastPeriod && "You must enter a discount end date, since you have several discount periods"}
            arrow
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={discount.hasNoEndDate}
                  onChange={(event) => {
                    onChange("hasNoEndDate", event.target.checked);
                  }}
                  color="primary"
                />
              }
              disabled={!isLastPeriod}
              label="Discount has no end date"
            />
          </Tooltip>
        </Grid>
      )}
      <Grid size={12}>
        <TextField
          label="Period discount"
          type="number"
          value={discount.discount}
          onChange={(e) => {
            onChange("discount", parseFloat(e.target.value));
          }}
          error={errors?.discount}
          fullWidth
          data-cy={`discount-${index}-discount`}
          slotProps={{
            input: {
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            },
            htmlInput: { min: 0, max: 100 },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ContractDiscountPeriod;
