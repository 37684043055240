import { type FC } from "react";

import { type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { TextField } from "@mui/material";
import { type FieldInputProps } from "formik";

import { useFieldCommonProps } from "../useFieldCommonProps";
import { OptionalParamWrapper } from "./wrappers/OptionalParamWrapper";
import { ReferencedFieldWrapper } from "./wrappers/ReferencedField/ReferencedFieldWrapper";

export const NumberParam: FC<{
  fieldProps: FieldInputProps<number>;
  label: string;
  inputModel: UnwrappedApiServiceModelDescriptor;
  onRemove?: () => void;
  disallowReferencedField?: boolean;
  renderAsNotRequired?: boolean;
}> = ({ fieldProps, label, inputModel, onRemove, disallowReferencedField, renderAsNotRequired }) => {
  const commonFieldProps = useFieldCommonProps(fieldProps, label, onRemove === undefined && !renderAsNotRequired);

  const value = commonFieldProps.value ?? "";

  const textField = <TextField type="number" fullWidth {...commonFieldProps} value={value} />;

  return (
    <OptionalParamWrapper onRemove={onRemove}>
      {disallowReferencedField ? (
        textField
      ) : (
        <ReferencedFieldWrapper commonFieldProps={commonFieldProps} model={inputModel}>
          {textField}
        </ReferencedFieldWrapper>
      )}
    </OptionalParamWrapper>
  );
};
