import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { CLOUD_FLOW_CREATION_STATUS, type CloudflowCreationStatus, CloudFlowNodeType } from "@doitintl/cmp-models";
import { AppBar, Button, Divider, Skeleton, Stack, Tooltip, useTheme } from "@mui/material";
import { Box } from "@mui/system";

import doitLogo from "../../../../assets/doit-logo-hero-square.svg";
import { cloudflowTexts } from "../../../../assets/texts";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useCloudflow, useHasActiveCloudflowExecutions } from "../../../../Pages/Cloudflow/hooks";
import { type UpdateCloudflowNodes } from "../../../../Pages/Cloudflow/types";
import { useNodeEdgeManager } from "../Common/NodeEdgeManagerProvider";
import Title from "../Common/Title";
import { mapToUpdateNodePayload } from "../utils/nodeTransformUtils";
import ConfirmEditDialog from "./ConfirmEditDialog";
import ConfirmUnpublishDialog from "./ConfirmUnpublishDialog";
import { StatusChip } from "./StatusChip";

type Props = {
  flowTouched: boolean;
  cloudflowName: string;
  isPublished: boolean;
};

const WorkflowActions = ({ isPublished, flowTouched, cloudflowName }: Props) => {
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const [confirmEditOpen, setConfirmEditOpen] = useState(false);
  const [confirmUnpublishOpen, setConfirmUnpublishOpen] = useState(false);
  const history = useHistory();
  const hasActiveExecutions = useHasActiveCloudflowExecutions(flowId);

  const {
    nodes,
    updateCloudflow,
    publishCloudflow,
    unpublishCloudflow,
    triggerCloudflow,
    updateNodes,
    httpOperationLoading,
  } = useNodeEdgeManager();

  const { customer } = useCustomerContext();

  const onSaveCloudflow = useCallback(async () => {
    const updateNodesPayload: UpdateCloudflowNodes = {
      name: cloudflowName || "",
      flowId,
      updatedNodes: nodes
        .filter((n) => n.type !== CloudFlowNodeType.GHOST && n.data.touched)

        .map((n) => ({
          node: mapToUpdateNodePayload(n),
        }))
        .filter((n) => n.node),
    };
    await updateNodes(customer.id, updateNodesPayload);
  }, [cloudflowName, customer.id, flowId, nodes, updateNodes]);

  const onPublishCloudflow = useCallback(
    () => publishCloudflow(customer.id, flowId),
    [customer.id, flowId, publishCloudflow]
  );

  const onUnpublishCloudflow = useCallback(async () => {
    await unpublishCloudflow(customer.id, flowId);
    setConfirmUnpublishOpen(false);
  }, [customer.id, flowId, unpublishCloudflow]);

  const onEditCloudflow = useCallback(async () => {
    await updateCloudflow(customer.id, flowId, {
      status: CLOUD_FLOW_CREATION_STATUS.DRAFT,
    });
    setConfirmEditOpen(false);
  }, [customer.id, flowId, updateCloudflow]);

  const onRunCloudflow = useCallback(async () => {
    await triggerCloudflow(customer.id, flowId);
  }, [customer.id, flowId, triggerCloudflow]);

  const onClose = useCallback(() => {
    history.push(`/customers/${customer.id}/cloudflow`);
  }, [customer.id, history]);

  return (
    <Stack direction="row" sx={{ gap: 1 }}>
      <ConfirmEditDialog
        isDialogOpened={confirmEditOpen}
        handleCloseDialog={() => {
          setConfirmEditOpen(false);
        }}
        handleEdit={onEditCloudflow}
      />
      <ConfirmUnpublishDialog
        isDialogOpened={confirmUnpublishOpen}
        handleCloseDialog={() => {
          setConfirmUnpublishOpen(false);
        }}
        handleUnpublish={onUnpublishCloudflow}
        hasActiveExecutions={hasActiveExecutions}
      />
      {isPublished ? (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setConfirmEditOpen(true);
            }}
          >
            {cloudflowTexts.EDIT}
          </Button>
          <Tooltip
            title={
              hasActiveExecutions
                ? "This CloudFlow is currently executing and cannot be triggered again until the current execution is complete."
                : ""
            }
          >
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={onRunCloudflow}
                disabled={hasActiveExecutions}
                sx={{
                  height: "100%",
                }}
              >
                {cloudflowTexts.RUN}
              </Button>
            </Box>
          </Tooltip>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setConfirmUnpublishOpen(true);
            }}
            disabled={httpOperationLoading}
          >
            {cloudflowTexts.UNPUBLISH}
          </Button>
        </>
      ) : (
        <>
          <Button variant="contained" color="primary" onClick={onPublishCloudflow} disabled={httpOperationLoading}>
            {cloudflowTexts.PUBLISH}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onSaveCloudflow}
            disabled={httpOperationLoading || !flowTouched}
          >
            {cloudflowTexts.SAVE}
          </Button>
        </>
      )}
      <Button variant="text" onClick={onClose}>
        {cloudflowTexts.CLOSE}
      </Button>
    </Stack>
  );
};

const Topbar = () => {
  const { flowId } = useParams<{ customerId: string; flowId: string }>();

  const [status, setStatus] = useState<CloudflowCreationStatus | undefined>();

  const { cloudflow, cloudflowLoading } = useCloudflow(flowId);

  const { nodes } = useNodeEdgeManager();

  const theme = useTheme();
  const [cloudflowName, setCloudflowName] = useState<string>("");

  const isPublished = useMemo(() => status === CLOUD_FLOW_CREATION_STATUS.PUBLISHED, [status]);

  const flowTouched = useMemo(
    () => nodes.some((n) => n.data.touched) || cloudflow?.data.name !== cloudflowName,
    [cloudflow?.data.name, cloudflowName, nodes]
  );
  const handleUpdateTitle = useCallback((title: string) => {
    setCloudflowName(title);
  }, []);

  const handleBlurTitle = useCallback(() => {
    if (!cloudflowName) {
      setCloudflowName(cloudflow?.data?.name || "");
    }
  }, [cloudflowName, cloudflow?.data?.name]);

  useEffect(() => {
    if (!cloudflowLoading && cloudflow?.data.name) {
      setCloudflowName(cloudflow.data.name);
    }
    if (!cloudflowLoading && cloudflow?.data.status) {
      setStatus(cloudflow.data.status);
    }
  }, [cloudflow?.data.name, cloudflow?.data.status, cloudflowLoading]);

  return (
    <AppBar
      elevation={0}
      position="sticky"
      sx={{ background: theme.palette.general.backgroundDefault, mt: -7, width: "100%" }}
    >
      <Stack>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            py: 1,
            px: 2,
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              gap: 0.5,
            }}
          >
            {cloudflowLoading ? (
              <>
                <Skeleton variant="circular" width={36} height={36} />
                <Skeleton variant="text" width={200} height={36} />
              </>
            ) : (
              <>
                <Box component="img" src={doitLogo} sx={{ height: "36px", width: "36px" }} />
                <Title
                  title={cloudflowName}
                  onUpdateTitle={handleUpdateTitle}
                  placeholder="Add CloudFlow name"
                  disabled={cloudflowLoading || isPublished}
                  onBlur={handleBlurTitle}
                />
                <StatusChip status={status} />
              </>
            )}
          </Stack>
          {cloudflowLoading ? (
            <Skeleton variant="rectangular" width={120} height={36} />
          ) : (
            <WorkflowActions flowTouched={flowTouched} isPublished={isPublished} cloudflowName={cloudflowName} />
          )}
        </Stack>
        <Divider sx={{ width: "100%" }} />
      </Stack>
    </AppBar>
  );
};

export default Topbar;
