import { type FC } from "react";

import { Box } from "@mui/material";
import { type SxProps, type Theme } from "@mui/system";

export const GuidingLineConnector: FC<{
  sx?: SxProps<Theme>;
}> = ({ sx }) => (
  <Box
    sx={{
      width: 12,
      height: "1px",
      backgroundColor: "divider",
      ml: -2,
      position: "relative",
      top: 36,
      ...sx,
    }}
  />
);
