import orderBy from "lodash/orderBy";

import { formatDecimalNumber } from "../../../utils/common";
import { type NoAccessInsight } from "../types";
import { type DisplayInsight, type InsightByCloud } from "./types";

export const convertInsights = (
  rawInsight: NoAccessInsight,
  supportCategoryToServiceMapping: Record<string, string>,
  currency: string
): DisplayInsight => {
  const dailySavings = rawInsight.potentialDailySavings?.value || 0;
  const yearlySavings = dailySavings * 365;
  const potentialSavings = `${currency}${formatDecimalNumber(yearlySavings, 0)}/year`;

  return {
    title: rawInsight.title,
    service: supportCategoryToServiceMapping[rawInsight?.supportCategory] || "-",
    easyWinDescription: (rawInsight.potentialDailySavings?.value || 0) !== 0 ? rawInsight.easyWinDescription : "",
    potentialSavings,
    potentialSavingsValue: yearlySavings,
    noSavingsAvailable: Math.floor(dailySavings) === 0,
  };
};

export const partitionByCloudTags = (
  data,
  supportCategoryToServiceMapping: Record<string, string>,
  currency: string
): InsightByCloud => {
  const result = {};

  data.forEach((item) => {
    item.cloudTags.forEach((tag) => {
      if (!result[tag]) {
        result[tag] = [];
      }
      result[tag].push(convertInsights(item, supportCategoryToServiceMapping, currency));
    });
    item.cloudTags.forEach((tag) => {
      result[tag] = orderBy(result[tag], "potentialSavingsValue", "desc");
    });
  });

  return result;
};

export const calculateROI = (netProfit, investmentCost) => ((netProfit - investmentCost) / investmentCost) * 100;
