import { useCallback, useEffect, useRef, useState } from "react";

import { useApiContext } from "../../../api/context";
import { cloudAnalyticsText } from "../../../assets/texts";
import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type AttributionWRef } from "../../../types";
import { QueryType } from "../report/ReportQuery";
import { type PreviewData } from "./PreviewReport";
import { runReport } from "./runPreviewReport";
import { getFilteredAttributions, getReportDataWForecasts } from "./utils";
import type ReportData from "../ReportData";

type Props = {
  attributions: AttributionWRef[];
  disablePreview?: boolean;
  initalRenderer?: string;
  previewData: PreviewData;
  runQuery: boolean;
  setRunQuery: (runQuery: boolean) => void;
  queryRunning: boolean;
  setQueryRunning: (queryRunning: boolean) => void;
  reportDataWForecasts: React.MutableRefObject<ReportData | null>;
  setReportError: (error: string | null) => void;
  forecastMode: boolean;
  queryType?: QueryType;
};

export const useQueryRun = ({
  attributions,
  previewData,
  queryRunning,
  reportDataWForecasts,
  runQuery,
  setQueryRunning,
  setReportError,
  setRunQuery,
  forecastMode = false,
  queryType,
}: Props) => {
  const api = useApiContext();
  const isInit = useRef(false);
  const [abortController, setAbortController] = useState<AbortController>();
  const [reRunQuery, setReRunQuery] = useState(false);

  const { metadata: metadataSnapshots, transforms } = useCloudAnalyticsContext();
  const { customer } = useCustomerContext();

  const isEmptyData = useCallback(() => {
    if (!metadataSnapshots) {
      return true;
    }

    for (const attributionGroup of previewData.attributionGroups) {
      if (attributionGroup.attributions.length) {
        return false;
      }
    }

    if (previewData.filters.length || previewData.scope.length || queryType === QueryType.TEMPLATE) {
      return false;
    }
    return true;
  }, [metadataSnapshots, previewData, queryType]);

  const handleRunPreview = useCallback(async () => {
    if (isEmptyData()) {
      reportDataWForecasts.current = null;
      return;
    }
    const ac = new AbortController();
    setAbortController(ac);
    setQueryRunning(true);
    setReportError(null);
    const attributionIds = previewData.scope;
    const filteredAttributions = getFilteredAttributions({ attributionIds, attributions });

    const response = await runReport({
      abortController: ac,
      api,
      attributionGroups: previewData.attributionGroupsPayload,
      attributions: filteredAttributions,
      cols: previewData.cols,
      currency: previewData.currency,
      customerId: customer.id,
      filters: previewData.filters,
      metadataSnapshots,
      timeInterval: previewData.timeInterval,
      forecastMode,
      timeRangeOptions: previewData.timeRangeOptions,
      rows: previewData.rows,
      cloudProviders: previewData.cloudProviders,
      dataSource: previewData.dataSource,
      forecastSettings: previewData.forecastSettings,
    });

    if (response.data && response.rows && response.cols) {
      reportDataWForecasts.current = getReportDataWForecasts(response, transforms);
    } else {
      if (response.code === cloudAnalyticsText.PREVIEW.ERR_CANCELED) {
        setReRunQuery(true);
        return;
      }
      reportDataWForecasts.current = null;
      setReportError(response.code || "request_failed");
    }

    setQueryRunning(false);
  }, [
    api,
    attributions,
    customer.id,
    forecastMode,
    isEmptyData,
    metadataSnapshots,
    previewData,
    reportDataWForecasts,
    setQueryRunning,
    setReportError,
    transforms,
  ]);

  // Set as initialized once metadata is ready
  useEffect(() => {
    if (!isInit.current && metadataSnapshots?.length) {
      isInit.current = true;
      handleRunPreview();
    }
  }, [isInit, metadataSnapshots, handleRunPreview]);

  // Re-run query when runQuery is set to true
  useEffect(() => {
    if (reRunQuery) {
      setRunQuery(false);
      return;
    }
    if (runQuery) {
      setRunQuery(false);
      if (queryRunning) {
        abortController?.abort();
      } else {
        handleRunPreview();
      }
    }
  }, [runQuery, setRunQuery, queryRunning, abortController, handleRunPreview, reRunQuery]);

  // Re-run query when reRunQuery is set to true with a delay after aborting the previous query
  useEffect(() => {
    if (reRunQuery) {
      setReRunQuery(false);
      setTimeout(() => {
        setQueryRunning(false);
        setRunQuery(true);
      }, 2000);
    }
  }, [reRunQuery, setReRunQuery, setQueryRunning, setRunQuery]);
};
