import { useCallback, useEffect, useState } from "react";

import { Add } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";

import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import { DraggableContainer } from "../../../Components/DragAndDrop/DraggableContainer";
import { DraggableGroup } from "../../../Components/DragAndDrop/DraggableGroup";
import { type AttributionWRef } from "../../../types";
import { AllocationDraggableGroupContent } from "./AllocationDraggableGroupContent";
import { AllocationDraggableItem } from "./AllocationDraggableItem";
import AllocationSegment from "./AllocationSegment";
import { UnallocatedCosts } from "./UnallocatedCosts";

type Props = {
  attributions: AttributionWRef[];
  attributionsInGroup: Record<string, string[]>;
  setAttributionsInGroup: (newGroups: Record<string, string[]>) => void;
};

export const AllocationDragAndDrop = ({ attributions, attributionsInGroup, setAttributionsInGroup }: Props) => {
  const [attributionsState, setAttributionsState] = useState(attributionsInGroup);

  useEffect(() => {
    if (attributionsState.main.length !== attributionsInGroup.main.length) {
      setAttributionsState(attributionsInGroup);
    }
  }, [attributionsInGroup, attributionsState.length, attributionsState.main.length]);

  const removeAttributionFromGroup = useCallback(
    (attributionId: string) => {
      const items = { ...attributionsInGroup };
      items.main = items.main.filter((id) => id !== attributionId);
      setAttributionsInGroup(items);
    },
    [attributionsInGroup, setAttributionsInGroup]
  );

  const handleAddAttribution = useCallback(() => {
    const items = { ...attributionsInGroup };
    items.main = [...items.main, ""];
    setAttributionsInGroup(items);
  }, [attributionsInGroup, setAttributionsInGroup]);

  const replaceAttributionInGroup = useCallback(
    (newAttributionId: string, oldAttributionId: string) => {
      const items = { ...attributionsInGroup };
      items.main = items.main.map((id) => (id === oldAttributionId ? newAttributionId : id));
      setAttributionsInGroup(items);
      setAttributionsState(items);
    },
    [attributionsInGroup, setAttributionsInGroup]
  );

  const renderDraggingItem = useCallback(
    (id: string) => (
      <AllocationDraggableItem
        initialAttributionID={id}
        grabbing
        attributions={attributions}
        removeAttributionFromGroup={removeAttributionFromGroup}
        replaceAttributionInGroup={replaceAttributionInGroup}
        disabledAttributions={attributionsInGroup.main}
      />
    ),
    [attributions, attributionsInGroup.main, replaceAttributionInGroup, removeAttributionFromGroup]
  );

  const renderContent = useCallback(
    (items: string[]) => (
      <AllocationDraggableGroupContent
        items={items}
        attributions={attributions}
        removeAttributionFromGroup={removeAttributionFromGroup}
        disabledAttributions={attributionsInGroup.main}
        replaceAttributionInGroup={replaceAttributionInGroup}
      />
    ),
    [attributions, attributionsInGroup.main, replaceAttributionInGroup, removeAttributionFromGroup]
  );

  return (
    <Stack gap={2}>
      <Stack>
        <DraggableContainer
          values={attributionsState}
          renderDraggingItem={renderDraggingItem}
          onItemsOrderChanged={setAttributionsInGroup}
        >
          <DraggableGroup id="main" renderContent={renderContent} />
        </DraggableContainer>
        <Box>
          <Button variant="outlined" size="medium" startIcon={<Add />} onClick={handleAddAttribution}>
            {allocationTxt.ADD_ALLOCATION}
          </Button>
        </Box>
      </Stack>
      <Stack gap={2}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 500 }}
        >{`Rule ${attributionsInGroup.main.length + 1}`}</Typography>
        <UnallocatedCosts />
      </Stack>
      {/* TODO: handle onAddUnallocatedCosts, adjust markup */}
      <AllocationSegment onAddAllocation={handleAddAttribution} onAddUnallocatedCosts={() => {}} />
    </Stack>
  );
};
