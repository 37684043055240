import { CloudFlowNodeType } from "@doitintl/cmp-models";
import { DateTime, Duration, type DurationObjectUnits } from "luxon";
import type { Timestamp } from "@doitintl/models-types";

import { type BaseNodeData, type BaseNodeEvent } from "./types";

export function getTimeTaken(start: Timestamp | undefined, end: Timestamp | undefined): string {
  if (!start) {
    return "-";
  }

  const s = DateTime.fromJSDate(start.toDate());
  let e: DateTime = DateTime.now();

  if (end) {
    e = DateTime.fromJSDate(end.toDate());
  }

  // Better Duration.toHuman support https://github.com/moment/luxon/issues/1134#issuecomment-1637008762
  const diff = e.diff(s).shiftTo("hours", "minutes", "seconds").toObject();
  if ("seconds" in diff) {
    diff.seconds = Math.round(diff.seconds!);
  }

  const cleanedDuration = Object.fromEntries(Object.entries(diff).filter(([_k, v]) => v !== 0)) as DurationObjectUnits;

  if (Object.keys(cleanedDuration).length === 0) {
    cleanedDuration.seconds = 0;
  }

  return Duration.fromObject(cleanedDuration).toHuman();
}

export function isBaseNodeDataAction(node: BaseNodeData): node is BaseNodeData<CloudFlowNodeType.ACTION> {
  return node.type === CloudFlowNodeType.ACTION;
}

export function createBaseNodeEvent(node: BaseNodeData) {
  let event: BaseNodeEvent;
  if (isBaseNodeDataAction(node)) {
    let apiName = "action parameters undefined";
    if (node.parameters) {
      const { id, service } = node.parameters.operation;
      apiName = `${service}.${id}`;
    }

    event = {
      nodeType: CloudFlowNodeType.ACTION,
      apiProvider: node.parameters?.provider ?? "action parameters undefined",
      apiName,
    };
  } else {
    event = {
      nodeType: node.type,
    };
  }

  return event;
}
