import { type EntityModel } from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { IconButton, TableCell, Tooltip } from "@mui/material";

import { assetTypeName, formatCurrency } from "../../../../utils/common";
import { type InvoiceTableRow } from "./types";

const formatEntityRow = (entity: ModelId<EntityModel>) => {
  if (entity.priorityId) {
    return `${entity.priorityId} - ${entity.name}`;
  }

  return entity.name;
};

const InvoiceRow = ({ row, navigateToView }: { row: InvoiceTableRow; navigateToView: () => void }) => (
  <>
    <TableCell data-cy={`${row.id}-cloud`}>{assetTypeName(row.type)}</TableCell>
    <TableCell data-cy={`${row.id}-entity`}>{formatEntityRow(row.entity)}</TableCell>
    <TableCell data-cy={`${row.id}-details`}>{row.details}</TableCell>
    <TableCell data-cy={`${row.id}-total`}>{formatCurrency(row.total, row.currency, 2)}</TableCell>
    <TableCell data-cy={`${row.id}-status`}>{row.status}</TableCell>
    <TableCell data-cy={`${row.id}-created-at`}>{row.transform.createdAt}</TableCell>
    <TableCell data-cy={`${row.id}-issued-at`}>{row.transform.issuedAt}</TableCell>
    <TableCell data-cy={`${row.id}-canceled-at`} align={row.transform.canceledAt ? "left" : "center"}>
      {row.transform.canceledAt || "-"}
    </TableCell>
    <TableCell>
      <Tooltip title="Click to view invoice">
        <IconButton onClick={navigateToView} size="large" data-cy={`${row.id}-show-invoice`}>
          <OpenInNew fontSize="small" />
        </IconButton>
      </Tooltip>
    </TableCell>
  </>
);
export default InvoiceRow;
