import { type FlexsaveType, FlexsaveTypes } from "@doitintl/cmp-models";
import { type AxiosInstance } from "axios";

import mixpanel from "../../utils/mixpanel";

enum FlexsaveUrlPrefix {
  aws = "aws",
  gcp = "gcp",
}

export class FlexsaveApi {
  private readonly apiPrefix: string;

  constructor(
    private readonly api: AxiosInstance,
    private readonly customerId: string,
    private readonly type: FlexsaveType
  ) {
    this.api = api;
    this.customerId = customerId;
    this.apiPrefix = `/v1/customers/${this.customerId}/flexsave/${
      type === FlexsaveTypes.AWS ? FlexsaveUrlPrefix.aws : FlexsaveUrlPrefix.gcp
    }`;
    this.type = type;
  }

  public async enable() {
    mixpanel.track(`flexsave-${this.type.toLowerCase()}.enable`);

    return this.api.post(`${this.apiPrefix}/enable`);
  }

  public disable() {
    mixpanel.track(`flexsave-${this.type.toLowerCase()}.disable`);

    return this.api.post(`${this.apiPrefix}/disable`);
  }

  public canDisable() {
    return this.api.get(`${this.apiPrefix}/can-disable`);
  }
}
