import { useMemo } from "react";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import { ToolTipWrapper } from "../../../../../Components/TooltipWrapper";
import { useContractFormContext } from "../../ContractsFormContext";
import ContractDiscountPeriod from "./components/ContractDiscountPeriod";
import { useDiscountPeriods } from "./useDiscountPeriods";

type Props = {
  isVendorContract?: boolean;
};

const GeneralContractDiscountStep = ({ isVendorContract }: Props) => {
  const { state } = useContractFormContext();
  const { discountPeriods, errors, handleAddPeriod, handlePeriodChange, handleDeletePeriod } =
    useDiscountPeriods(isVendorContract);

  const contractStartDate = isVendorContract ? state.vendorContract!.startDate : state.startDate;
  const contractEndDate = isVendorContract ? state.vendorContract!.endDate : state.endDate;

  const newDiscountPeriodDisabled = useMemo(
    () =>
      !!discountPeriods.length &&
      (discountPeriods[discountPeriods.length - 1].hasNoEndDate ||
        (!!contractEndDate && discountPeriods[discountPeriods.length - 1].endDate?.equals(contractEndDate))),
    [discountPeriods, contractEndDate]
  );

  return (
    <Container maxWidth="sm" data-cy="discount-step">
      <Grid container spacing={3}>
        <Grid size={12}>
          <Typography variant="h4">Discount</Typography>
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
            }}
          >
            Enter discounts chronologically
          </Typography>
        </Grid>
        {discountPeriods.map((discount, index) => (
          <Grid key={index} size={12}>
            <ContractDiscountPeriod
              errors={errors?.[index]}
              index={index}
              discount={discount}
              isLastPeriod={index === discountPeriods.length - 1}
              minDiscountDate={discountPeriods[index - 1]?.endDate ?? contractStartDate.startOf("day")}
              maxDiscountDate={contractEndDate}
              onChange={handlePeriodChange(index)}
              onDeleteDiscountPeriod={() => {
                handleDeletePeriod(index);
              }}
              isCommitment={state.isCommitment}
            />
          </Grid>
        ))}
        <Grid>
          <ToolTipWrapper
            title={
              discountPeriods[discountPeriods.length - 1]?.hasNoEndDate
                ? "You can't add another discount period if the previous one has no end date"
                : ""
            }
            arrow
          >
            <Button
              startIcon={<AddIcon />}
              disabled={newDiscountPeriodDisabled}
              onClick={handleAddPeriod}
              data-cy={"add-discount-button"}
            >
              Add new discount period
            </Button>
          </ToolTipWrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

export const ContractDiscountStep = () => <GeneralContractDiscountStep isVendorContract={false} />;
export const VendorContractDiscountStep = () => <GeneralContractDiscountStep isVendorContract={true} />;
