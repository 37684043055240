import { useState } from "react";

import { type Cluster } from "@doitintl/cmp-models";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, CircularProgress, Divider, Stack, Typography, useTheme } from "@mui/material";

import { type Feature } from "../features";
import SelectedAccountAndCluster from "./components/SelectedAccountAndCluster";

interface TestConnectionStepProps {
  accountId: string | null;
  cluster: Cluster | null;
  selectedFeatures: Feature[];
}

const TestConnectionStep = ({ accountId, cluster, selectedFeatures }: TestConnectionStepProps) => {
  // TODO - Replace with the real logic once we can test feature connections
  const [connectionHasBeenTested, setConnectionHasBeenTested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [retryLoading, setRetryLoading] = useState(false);

  const theme = useTheme();

  const handleTestConnection = () => {
    setConnectionHasBeenTested(false);
    setLoading(true);
    setTimeout(() => {
      try {
        setConnectionHasBeenTested(true);
      } finally {
        setLoading(false);
      }
    }, 2000);
  };

  const handleRetryConnection = () => {
    setRetryLoading(true);
    setTimeout(() => {
      setRetryLoading(false);
    }, 2000);
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">Enable features for this cluster</Typography>
        <SelectedAccountAndCluster accountId={accountId} cluster={cluster} />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="body2">
          1. Test cluster connection to the DoiT Console and activate successful clusters
        </Typography>
        <LoadingButton
          onClick={handleTestConnection}
          sx={{ width: "fit-content" }}
          variant="outlined"
          loading={loading}
          disabled={connectionHasBeenTested}
        >
          Test connection and activate clusters
        </LoadingButton>
      </Stack>

      {connectionHasBeenTested && (
        <Card>
          <CardContent sx={{ backgroundColor: theme.palette.general.backgroundDark }}>
            <Box display="flex" justifyContent="space-between">
              <Stack spacing={1}>
                <Typography fontWeight={500} variant="body1">
                  Activation summary
                </Typography>
                <Typography variant="body2">Activation status for each selected feature</Typography>
              </Stack>
              <Box>
                <LoadingButton
                  onClick={handleRetryConnection}
                  loading={retryLoading}
                  variant="outlined"
                  sx={{ width: "fit-content" }}
                >
                  Retry
                </LoadingButton>
              </Box>
            </Box>
          </CardContent>
          {selectedFeatures.map((feature, index) => (
            <div key={feature.key}>
              <Divider />
              <CardContent key={feature.key}>
                <Stack direction="row" spacing={2}>
                  <Box>
                    {index % 2 === 0 ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <>{retryLoading ? <CircularProgress size={24} /> : <ErrorIcon color="error" />}</>
                    )}
                  </Box>
                  <Box>
                    <Typography fontWeight={400} variant="body1">
                      {feature.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {index % 2 === 0 ? "Successfully activated" : "Failed to activate. Error goes here"}
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </div>
          ))}
        </Card>
      )}
    </Stack>
  );
};

export default TestConnectionStep;
