import { type CloudAnalyticsModelAttributionGroupsModel } from "@doitintl/cmp-models";
import { type ModelReference, type WithFirebaseModel } from "@doitintl/models-firestore";

export type AttributionTransform = {
  owner?: string;
  timeModified: string;
};

export type AttributionGroupWithRef = {
  data: WithFirebaseModel<CloudAnalyticsModelAttributionGroupsModel>;
  ref: ModelReference<CloudAnalyticsModelAttributionGroupsModel>;
  transform: AttributionTransform;
};

export enum RuleFilterOptions {
  IS = "is",
  STARTS_WITH = "starts with",
  ENDS_WITH = "ends with",
  CONTAINS = "contains",
  MATCHES_REGEX = "matches regex",
}
