import { Box, Checkbox, Divider, Stack, Typography } from "@mui/material";

import { type Feature, KUBERNETES_FEATURES } from "../../features";

interface Fsk8sFeatureSelectorProps {
  selectedFeatures: Feature[];
  onSelect: (features: Feature[]) => void;
}

const Fsk8sFeatureSelector = ({ onSelect, selectedFeatures }: Fsk8sFeatureSelectorProps) => {
  const selectedKeys = selectedFeatures.map((f) => f.key);

  const handleToggleFeature = (featureKey: string) => () => {
    const newSelectedFeatures = selectedKeys.includes(featureKey)
      ? selectedFeatures.filter((f) => f.key !== featureKey)
      : [...selectedFeatures, KUBERNETES_FEATURES.find((f) => f.key === featureKey)!];

    onSelect(newSelectedFeatures);
  };

  return (
    <Stack>
      {KUBERNETES_FEATURES.map((feature) => (
        <Box key={feature.key}>
          <Box sx={{ display: "flex", padding: 2, gap: 1 }}>
            <Box sx={{ width: 40, alignContent: "start", justifyContent: "center", display: "flex" }}>
              <Checkbox onChange={handleToggleFeature(feature.key)} checked={selectedKeys.includes(feature.key)} />
            </Box>
            <Stack>
              <Typography variant="body2">{feature.name}</Typography>
              <Typography variant="caption" color="text.secondary">
                {feature.description}
              </Typography>
            </Stack>
          </Box>
          <Divider />
        </Box>
      ))}
    </Stack>
  );
};

export default Fsk8sFeatureSelector;
