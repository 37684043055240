import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import Typography, { type TypographyProps } from "@mui/material/Typography";

import { type EntitlementRow } from "./EditTierEntitlements";

type DisableableTypographyProps = {
  disabled: boolean;
} & TypographyProps;

const DisableableTypography = ({ disabled, children, ...rest }: DisableableTypographyProps) => (
  <Typography
    sx={{ color: (theme) => (disabled ? theme.palette.text.disabled : theme.palette.text.primary) }}
    {...rest}
  >
    {children}
  </Typography>
);

const EntitlementRowItem = ({ row }: { row: EntitlementRow }) => (
  <>
    {row.disabled ? (
      <TableCell>
        <Tooltip title="Entitlement is disabled as another entitlement with the same key has already been selected">
          <span>
            <DisableableTypography disabled={row.disabled}>{row.displayName}</DisableableTypography>
          </span>
        </Tooltip>
      </TableCell>
    ) : (
      <TableCell>
        <DisableableTypography disabled={row.disabled}>{row.displayName}</DisableableTypography>
      </TableCell>
    )}
    <TableCell>
      <DisableableTypography disabled={row.disabled}>{row.description}</DisableableTypography>
    </TableCell>
    <TableCell>
      <DisableableTypography disabled={row.disabled}>{row.type}</DisableableTypography>
    </TableCell>
    <TableCell>
      <DisableableTypography disabled={row.disabled}>{row.limit ?? "Feature Gate"}</DisableableTypography>
    </TableCell>
    <TableCell>
      <DisableableTypography disabled={row.disabled}>{row.key}</DisableableTypography>
    </TableCell>
  </>
);

export default EntitlementRowItem;
