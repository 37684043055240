// handle login and sing-up if user is not yet logged in
import { Redirect, Switch } from "react-router-dom";

import { routes } from "../../constants/navigation";
import { paths } from "../../Pages/Login/helpers";
import Login from "../../Pages/Login/Login";
import { LoginSuccess } from "../../Pages/Login/LoginSuccess";
import { AnnouncekitAuthRedirect } from "../AnnouncekitAuth/AnnouncekitAuthRedirect";
import { NoTraceRoute, TraceRoute } from "./TraceRoutes";

export const NoUserRoutes = () => (
  <Switch>
    <TraceRoute exact path="/signup/flexsave" render={() => <Redirect to="/signup" />} />
    <TraceRoute exact path="/signup/console" render={() => <Redirect to="/signup" />} />
    <TraceRoute exact path="/trial" render={() => <Redirect to="/signup" />} />

    <TraceRoute exact path="/signup" render={() => <Login loginType="signup" />} />
    <TraceRoute exact path={paths.completeSignup} render={() => <Login loginType="completeSignup" />} />
    {
      <TraceRoute
        // login is legacy, sign-in is the one used instead
        path="/login"
        render={(props) => (
          <Redirect
            to={{
              pathname: props.history.location.pathname.replace("/login", routes.signin),
              search: props.history.location.search,
            }}
          />
        )}
      />
    }
    <TraceRoute exact path={paths.loginSuccess} render={() => <LoginSuccess />} />
    <TraceRoute exact path={paths.loginError} render={() => <Login />} />
    <TraceRoute path={routes.signin} render={() => <Login />} />
    <NoTraceRoute exact path="/auth/google" />
    <NoTraceRoute exact path="/auth/microsoft" />
    <NoTraceRoute path="/announcekit-auth" render={() => <AnnouncekitAuthRedirect />} />
    <Redirect
      to={{
        pathname: routes.signin,
        search: location.pathname !== "/" ? new URLSearchParams({ redirect: location.pathname }).toString() : "",
      }}
    />
  </Switch>
);
