import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import EscalateIcon from "@mui/icons-material/NotificationsActiveRounded";
import { Button, Tooltip } from "@mui/material";
import { Box, Stack } from "@mui/system";

import Hide from "../../Components/HideChildren/Hide";
import { useIsDCIEssentialsTier } from "../hooks/useIsDCIEssentialsTier";

type Props = {
  isShowMarkSolved: boolean;
  isEscalated: boolean;
  loading: boolean;
  setIsMarkSolvedOpen: () => void;
  setIsEscalateOpen: () => void;
  isShowEscalate: boolean;
};

export default function SolveEscalateTicket({
  isShowMarkSolved,
  loading,
  setIsMarkSolvedOpen,
  setIsEscalateOpen,
  isEscalated,
  isShowEscalate,
}: Props) {
  const { t } = useTranslation("services");
  const isEssentials = useIsDCIEssentialsTier();

  return (
    <Box
      sx={{
        padding: 1.5,
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
        }}
      >
        <div>&nbsp;</div>
        <Hide mdDown>
          {isShowMarkSolved ? (
            <Button color="primary" variant="outlined" onClick={setIsMarkSolvedOpen} disabled={loading}>
              {t("TicketDetailsContainer.solved")}
            </Button>
          ) : null}

          {isEscalated || !isShowEscalate ? null : (
            <>
              <Button
                disabled={isEscalated || loading}
                color="primary"
                variant={isEscalated ? "text" : "outlined"}
                onClick={setIsEscalateOpen}
                endIcon={isEscalated ? <EscalateIcon /> : undefined}
              >
                {isEscalated ? t("TicketDetailsContainer.escalated") : t("TicketDetailsContainer.escalate")}
              </Button>

              {isEssentials ? (
                <Tooltip title={t("TicketDetailsContainer.tooltip")} placement="top">
                  <InfoIcon mode="outlined" fontSize="inherit" />
                </Tooltip>
              ) : null}
            </>
          )}
        </Hide>
      </Stack>
    </Box>
  );
}
