import { type MouseEvent } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import GoogleLogo from "../../../assets/google.svg";
import OfficeLogo from "../../../assets/microsoft.svg";
import { useUtmParams } from "../../../utils/utmParams/hooks";
import { constructAuthProviderRoute, getAuthProvidersButtonText } from "../helpers";
import { type LoginType, type SupportedAuthProviders } from "../types";

export const AuthProviderButton = ({
  handleClick,
  provider,
  disabled,
  loginType,
  isCompleteSignup,
}: {
  handleClick: (e: MouseEvent, provider: SupportedAuthProviders) => void;
  provider: SupportedAuthProviders;
  disabled?: boolean;
  loginType: LoginType;
  isCompleteSignup: boolean | undefined;
}) => {
  const theme = useTheme();
  const location = useLocation();
  const utmParams = useUtmParams();
  const query = new URLSearchParams(location.search);
  const logo = provider === "Google" ? GoogleLogo : OfficeLogo;
  const href = constructAuthProviderRoute({
    provider,
    utmParams,
    redirect: query.get("redirect"),
    isCompleteSignup,
  });

  const { t } = useTranslation("login");
  const text = getAuthProvidersButtonText({ loginType, provider }, t);

  return (
    <Button
      fullWidth
      sx={{
        color: theme.palette.text.primary,
        borderColor: theme.palette.grey.A400,
        textTransform: "none",
        height: 41,
      }}
      startIcon={<img width={24} height={24} src={logo} alt={provider} />}
      data-cy={`${provider === "Google" ? "google" : "microsoft"}-login`}
      variant="outlined"
      onClick={(e) => {
        handleClick(e, provider);
      }}
      href={href}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
