import { useMemo } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";

export const usePriorityErpStatus = (enabled = true) => {
  const query = useMemo(() => (enabled ? getCollection(AppModel).doc("priority-v3") : undefined), [enabled]);

  const [data] = useDocumentData(query, {
    caching: true,
    cachingKeys: ["priority-v3"],
  });

  return data?.status ?? { available: true };
};
