import { type ChangeEvent, useEffect, useState } from "react";

import {
  type CurrencyCode,
  CurrencyCodes,
  type GSuiteAssetModel,
  type GSuitePlanName,
  type Office365AssetModel,
  type Office365PlanName,
} from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import { globalText } from "../../../assets/texts";
import { type LegacyDataFormat } from "../../../Components/Catalog/Catalog.context";
import LoadingButton from "../../../Components/LoadingButton";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { type AnyAsset, type Asset } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { priorityCompanyDefaultCurrency } from "../../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";

const CurrencyOptions: CurrencyCode[] = [
  CurrencyCodes.USD,
  CurrencyCodes.EUR,
  CurrencyCodes.GBP,
  CurrencyCodes.AUD,
  CurrencyCodes.NOK,
  CurrencyCodes.DKK,
  CurrencyCodes.BRL,
] as const;

export const isDisabled = ({ currency, entity }: { currency: CurrencyCode | undefined; entity?: Entity }) => {
  const priorityCompanyDefault = entity?.priorityCompany
    ? entity?.priorityCompany && priorityCompanyDefaultCurrency[entity.priorityCompany]
    : "USD";
  const billingProfileCurrency = entity?.currency;
  const disabled = priorityCompanyDefault !== billingProfileCurrency && billingProfileCurrency !== currency;

  return {
    disabled,
    billingProfileCurrency,
  };
};

type Props = {
  onClose: () => void;
  entity?: Entity;
  asset: AnyAsset<GSuiteAssetModel | Office365AssetModel>;
  catalogItem: LegacyDataFormat | null | undefined;
};

const AssetSettingsDialog = ({ onClose, entity, asset, catalogItem }: Props) => {
  const api = useApiContext();
  const { onOpen: showSnackbar, onClose: hideSnackbar } = useSnackbar();
  const { fullScreen } = useFullScreen();

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [planName, setPlanName] = useState<GSuitePlanName | Office365PlanName | "">("");
  const [payment, setPayment] = useState<"MONTHLY" | "YEARLY" | "">("");
  const [currency, setCurrency] = useState<CurrencyCode | "">("");
  const [startTime, setStartTime] = useState<DateTime>();
  const [endTime, setEndTime] = useState<DateTime>();

  useEffect(() => {
    let initialPlanName: GSuitePlanName | Office365PlanName | "" = "";
    let initialCurrency: CurrencyCode | "" = "";
    let initialPayment: "MONTHLY" | "YEARLY" | "" = "";
    let initialStartTime: DateTime<true | false> = DateTime.utc();
    let initialEndTime: DateTime<true | false> = initialStartTime.plus({ year: 1 });

    if (asset.data.type === "g-suite") {
      if (
        asset.data.properties.subscription.plan.isCommitmentPlan &&
        asset.data.properties.subscription.plan.commitmentInterval
      ) {
        initialStartTime = DateTime.fromMillis(asset.data.properties.subscription.plan.commitmentInterval.startTime)
          .toUTC()
          .startOf("day");
        initialEndTime = DateTime.fromMillis(asset.data.properties.subscription.plan.commitmentInterval.endTime)
          .toUTC()
          .startOf("day");
      }
    } else if (asset.data.type === "office-365") {
      const d = DateTime.fromISO(asset.data.properties.subscription.commitmentEndDate);
      if (d?.isValid) {
        initialEndTime = d.toUTC().startOf("day");
        initialStartTime = d.minus({ years: 1 }).toUTC().startOf("day");
      }
    }

    if (asset.data.properties.settings) {
      if (asset.data.properties.settings.plan) {
        initialPlanName = asset.data.properties.settings.plan.planName || "";
        if (asset.data.properties.settings.plan.isCommitmentPlan) {
          if (asset.data.properties.settings.plan.commitmentInterval.startTime) {
            initialStartTime = DateTime.fromMillis(asset.data.properties.settings.plan.commitmentInterval.startTime)
              .toUTC()
              .startOf("day");
          }
          if (asset.data.properties.settings.plan.commitmentInterval.endTime) {
            initialEndTime = DateTime.fromMillis(asset.data.properties.settings.plan.commitmentInterval.endTime)
              .toUTC()
              .startOf("day");
          }
        }
      }

      initialPayment = asset.data.properties.settings.payment ?? "";
      initialCurrency = asset.data.properties.settings.currency ?? "";
    } else {
      const { billingProfileCurrency } = isDisabled({ currency: undefined, entity });
      if (billingProfileCurrency && CurrencyOptions.includes(billingProfileCurrency)) {
        initialCurrency = billingProfileCurrency;
      }
    }

    setPlanName(initialPlanName);
    setPayment(initialPayment);
    setStartTime(initialStartTime);
    setEndTime(initialEndTime);
    setCurrency(initialCurrency);
  }, [asset, entity]);

  useEffect(() => {
    if (planName === "FLEXIBLE" && payment === "YEARLY") {
      setPayment("MONTHLY");
    }
  }, [planName, payment]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "planName") {
      setPlanName(value as typeof planName);
    } else if (name === "payment") {
      setPayment(value as typeof payment);
    } else if (name === "currency") {
      setCurrency(value as CurrencyCode);
    }
  };

  const handleUpdate = async (reset: boolean) => {
    if (!reset && !catalogItem) {
      throw Error("Invalid asset settings");
    }

    setLoadingSave(!reset);
    setLoadingReset(reset);
    try {
      const url = `/v1/customers/${asset.data.properties.customerId}/assets/settings/${asset.id}`;
      if (reset) {
        await api.request({
          method: "DELETE",
          url,
        });
        mixpanel.track(`assets.${asset.data.type}.settings.reset`, {
          reseller: asset.data.properties.reseller,
          sku:
            asset.data.type === "g-suite"
              ? (asset as Asset<GSuiteAssetModel>).data.properties.subscription.skuId
              : undefined,
          domain: asset.data.properties.customerDomain,
          subscriptionId:
            asset.data.type === "g-suite"
              ? (asset as Asset<GSuiteAssetModel>).data.properties.subscription.subscriptionId
              : undefined,
          settings: {
            before: asset.data.properties.settings,
            after: null,
          },
        });
      } else {
        const getPlan = () => {
          if (planName) {
            if (planName === "ANNUAL") {
              if (!startTime || !endTime) {
                throw Error("Invalid start/end time");
              }
              return {
                planName,
                isCommitmentPlan: true,
                commitmentInterval: {
                  startTime: startTime.toUTC().startOf("day").toMillis(),
                  endTime: endTime.toUTC().startOf("day").toMillis(),
                },
              };
            } else {
              return {
                planName,
                isCommitmentPlan: false,
              };
            }
          }
        };

        const settings = {
          plan: getPlan(),
          payment: payment ? payment : undefined,
          currency: currency ? currency : undefined,
        };

        await api.request({
          method: "POST",
          url,
          data: settings,
        });
        mixpanel.track(`assets.${asset.data.type}.settings.reset`, {
          reseller: asset.data.properties.reseller,
          sku:
            asset.data.type === "g-suite"
              ? (asset as Asset<GSuiteAssetModel>).data.properties.subscription.skuId
              : undefined,

          domain: asset.data.properties.customerDomain,
          subscriptionId:
            asset.data.type === "g-suite"
              ? (asset as Asset<GSuiteAssetModel>).data.properties.subscription.subscriptionId
              : undefined,

          settings: {
            before: asset.data.properties.settings,
            after: settings,
          },
        });
      }
      showSnackbar({
        message: "Asset settings updated successfully",
        variant: "success",
        autoHideDuration: 5000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      onClose();
    } catch (error) {
      showSnackbar({
        message: "Failed to update asset settings",
        variant: "error",
        autoHideDuration: 5000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      consoleErrorWithSentry(error);
    } finally {
      setLoadingSave(false);
      setLoadingReset(false);
    }
  };

  const handleChangeDatetime = (name: string) => (date: DateTime | null) => {
    if (name === "startTime") {
      setStartTime(date ?? undefined);
    } else if (name === "endTime") {
      setEndTime(date ?? undefined);
    }
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="dialog-title"
      onClose={preventOnCloseWhile(loadingSave || loadingReset, onClose)}
      fullScreen={fullScreen}
      maxWidth="sm"
    >
      <DialogTitle id="dialog-title">Asset settings</DialogTitle>

      <DialogContent>
        <TextField
          select
          name="planName"
          label="Plan"
          value={planName ?? ""}
          onChange={handleChange}
          disabled={loadingSave || loadingReset}
          margin="normal"
          variant="outlined"
          fullWidth
        >
          <MenuItem value="ANNUAL">Annual</MenuItem>
          <MenuItem value="FLEXIBLE">Flexible</MenuItem>
        </TextField>
        <TextField
          select
          name="payment"
          label="Payment"
          value={payment}
          onChange={handleChange}
          disabled={loadingSave || loadingReset}
          margin="normal"
          variant="outlined"
          fullWidth
        >
          {planName !== "FLEXIBLE" && <MenuItem value="YEARLY">Yearly</MenuItem>}
          <MenuItem value="MONTHLY">Monthly</MenuItem>
        </TextField>
        <DatePicker
          label="Start date"
          renderInput={(params) => <TextField fullWidth margin="normal" {...params} />}
          value={startTime}
          onChange={handleChangeDatetime("startTime")}
          components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
          inputFormat="dd LLLL, yyyy"
          disabled={planName !== "ANNUAL" || loadingSave || loadingReset}
          maxDate={endTime}
        />
        <DatePicker
          label="End date"
          renderInput={(params) => <TextField fullWidth margin="normal" {...params} />}
          value={endTime}
          onChange={handleChangeDatetime("endTime")}
          components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
          inputFormat="dd LLLL, yyyy"
          disabled={planName !== "ANNUAL" || loadingSave || loadingReset}
          minDate={startTime}
        />
        <TextField
          select
          name="currency"
          label="Currency"
          value={currency}
          onChange={handleChange}
          disabled={loadingSave || loadingReset}
          margin="normal"
          variant="outlined"
          fullWidth
        >
          {CurrencyOptions.map((c) => {
            const { disabled } = isDisabled({ currency: c, entity });
            return (
              <MenuItem key={c} value={c} disabled={disabled}>
                {c}
              </MenuItem>
            );
          })}
        </TextField>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <LoadingButton
          color="primary"
          variant="text"
          onClick={() => {
            void handleUpdate(true);
          }}
          disabled={loadingSave || loadingReset}
          loading={loadingReset}
          mixpanelEventId="assets.asset-settings.reset"
        >
          {globalText.RESET}
        </LoadingButton>
        <Box>
          <Button color="primary" variant="text" onClick={onClose} disabled={loadingSave || loadingReset}>
            {globalText.CANCEL}
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => {
              void handleUpdate(false);
            }}
            disabled={loadingSave || loadingReset}
            loading={loadingSave}
            mixpanelEventId="assets.asset-settings.save"
          >
            {globalText.SAVE}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AssetSettingsDialog;
