import { type JSX } from "react";

import { type Key } from "@doitintl/cmp-models";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

import { useCustomerId } from "../../hooks/useCustomerId";
import { type WidgetWithExtraData } from "./hooks";
import { getFiltersByType, getLabelOfKey } from "./utils";

type Props = {
  rawReport?: WidgetWithExtraData;
  cloudAnalyticsKey?: Key;
  selectedFilter: string;
  menu?: JSX.Element;
  onChangeFilter: (e: SelectChangeEvent) => void;
};

export const CloudReportHeaderAction = ({
  rawReport,
  cloudAnalyticsKey,
  selectedFilter,
  menu,
  onChangeFilter,
}: Props) => {
  const customerId = useCustomerId();
  const selectLabel = cloudAnalyticsKey && rawReport ? getLabelOfKey(rawReport.config.rows, cloudAnalyticsKey) : "";

  return (
    <Stack
      direction="row"
      onClick={(e) => {
        e.stopPropagation();
      }}
      spacing={1}
    >
      {cloudAnalyticsKey && rawReport && (
        <FormControl sx={{ width: 250 }}>
          <InputLabel>{selectLabel}</InputLabel>
          <Select label={selectLabel} value={selectedFilter} onChange={onChangeFilter}>
            <Box sx={{ ml: 2, height: "32px", pt: 0.5 }}>
              <Typography variant="caption">Top 10 {selectLabel}s</Typography>
            </Box>
            {getFiltersByType(rawReport, cloudAnalyticsKey, customerId).map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {menu}
    </Stack>
  );
};
