import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";

import { lensEnticementTexts, lensUrls } from "../../../../assets/texts";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { LensEnticement } from "../../../LensEnticement/LensEnticement";
import { type EnticementState } from "../../../LensEnticement/types";
import useFetchAmTamEmail from "../../../NoEntitlement/hooks";
import { ContactSalesButton } from "../../../NoEntitlement/NoEntitlement";
import ChartsArea from "./ChartsArea";
import { bQEmptyStateDashboardTexts } from "./texts";

export const BQLensEnticement = ({ state }: { state: EnticementState }) => {
  const { accountManagers, loading } = useFetchAmTamEmail();
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext({ allowNull: true });
  const actionButton = useMemo(() => {
    if (state === "activate") {
      return (
        <Button
          component={RouterLink}
          variant="contained"
          to={`/customers/${customer?.id}/settings/gcp`}
          color="primary"
          aria-label={bQEmptyStateDashboardTexts.ENABLE_BQ_BUTTON}
          data-cy="bqEmptyStateDashboard_enableBQbtn"
        >
          {bQEmptyStateDashboardTexts.ENABLE_BQ_BUTTON}
        </Button>
      );
    }

    if (state === "upgrade") {
      return <ContactSalesButton sendTo={accountManagers} loading={loading} />;
    }
  }, [accountManagers, customer?.id, loading, state]);

  return (
    <LensEnticement
      imageElement={<ChartsArea />}
      title={lensEnticementTexts.BQ_LENS_TITLE}
      listItems={lensEnticementTexts.BQ_LENS_LIST}
      actionButton={actionButton}
      videoUrl={lensUrls.BQ_LENS_VIDEO}
      learnMoreUrl={lensUrls.BQ_LENS_LEARN_MORE}
    >
      <Stack data-cy="bqEmptyStateDashboard_testimonialTexts" direction="column" spacing={1} mb={3}>
        <Typography variant="body1">
          "Thanks to the BigQuery Lens' recommendations, we invested time in partitioning our key tables and were able
          to{" "}
          <Box
            component="span"
            sx={{
              fontWeight: "fontWeightMedium",
            }}
          >
            reduce
          </Box>{" "}
          our BigQuery expenses by{" "}
          <Box
            component="span"
            sx={{
              fontWeight: "fontWeightMedium",
            }}
          >
            25% a month"
          </Box>
        </Typography>
        <Typography variant="caption" data-cy="bqEmptyStateDashboard_tesimonialQuotee">
          {bQEmptyStateDashboardTexts.QUOTEE}
        </Typography>
      </Stack>
    </LensEnticement>
  );
};
