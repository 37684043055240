import { useCallback, useEffect, useMemo, useState } from "react";

import { type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { FormControl, MenuItem, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { type FormikProps } from "formik";

import VirtualizedAutocomplete from "../../../../Components/Autocomplete/VirtualizedAutocomplete";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useCloudConnectData } from "../../../Settings/GCP/useCloudConnectData";
import { useGetGcpProjects } from "../../hooks";
import { useFieldValidationProps } from "./useFieldValidationProps";

const GCPParameterForm = ({
  inputModel,
  formikProps,
}: {
  inputModel: StructureApiServiceModelDescriptor<Member>;
  formikProps: FormikProps<any>;
}) => {
  const { customer } = useCustomerContext();
  const [gcpCloudConnectData] = useCloudConnectData(customer.id);

  const [organization, setOrganization] = useState<string>(formikProps?.values?.organization || "");
  const organizations = useMemo(
    () =>
      gcpCloudConnectData.flatMap(
        (gcpCloudConnect) =>
          gcpCloudConnect.data?.organizations?.map((org) => ({
            name: org.name,
            displayName: org.displayName,
            serviceAccount: gcpCloudConnect.data.clientEmail,
          })) || []
      ),
    [gcpCloudConnectData]
  );
  const { data: projects, isLoading: projectLoading } = useGetGcpProjects(customer.id, organization);

  const projectIdFieldName = (Object.keys(inputModel.members) || []).find(
    (value) => value !== "organization" && value !== "serviceAccount"
  );
  const projectIdProps = useMemo(
    () => formikProps.getFieldProps(projectIdFieldName || ""),
    [formikProps, projectIdFieldName]
  );
  const { showError, errorMessage } = useFieldValidationProps(formikProps, projectIdProps);

  const handleOrganizationChange = useCallback(
    async (organizationName: string) => {
      setOrganization(organizationName);
      const serviceAccount = organizations.find((org) => org.name === organizationName)?.serviceAccount;

      await Promise.all([
        formikProps.setFieldValue("organization", organizationName),
        formikProps.setFieldValue("serviceAccount", serviceAccount),
      ]);
    },
    [organizations, formikProps]
  );

  useEffect(() => {
    if (!organization && organizations.length > 0) {
      handleOrganizationChange(organizations[0]?.name);
    }
  }, [organization, organizations, handleOrganizationChange, formikProps.values.organization]);

  if (!organizations || organizations.length === 0) return null;

  return (
    <Stack
      sx={{
        gap: 2,
      }}
    >
      <FormControl fullWidth>
        <TextField
          id="organization"
          name="organization"
          variant="outlined"
          select
          label="Organization"
          value={organization}
          onChange={(event) => {
            handleOrganizationChange(event.target.value);
          }}
          required
        >
          {organizations.map((org) => (
            <MenuItem key={org.name} value={org.name}>
              {org.displayName}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
      {projectIdFieldName && (
        <FormControl fullWidth>
          <VirtualizedAutocomplete
            loading={projectLoading}
            id={"project"}
            options={projects.map((project) => project.projectId)}
            onChange={(_, value) => {
              formikProps.setFieldValue(projectIdFieldName, value || "");
            }}
            renderOption={(props, option) => (
              <MenuItem {...props} value={option}>
                {option}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                type="text"
                label="Project"
                name="Project"
                fullWidth
                error={showError}
                helperText={errorMessage}
                variant="outlined"
                required={true}
              />
            )}
            size="small"
            fullWidth
            value={formikProps?.values?.[projectIdFieldName] || null}
          />
        </FormControl>
      )}
    </Stack>
  );
};

export default GCPParameterForm;
