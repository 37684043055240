import { useCallback, useEffect, useMemo, useState } from "react";

import { Link, useHistory, useParams } from "react-router-dom";
import {
  AssetModel,
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  CurrencyCodes,
  Metadata,
  TimeInterval,
  type TimeSettingsConfig,
  TimeSettingsMode,
} from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import AnnotationsModel from "highcharts/modules/annotations";
import HighchartsReact from "highcharts-react-official";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import { cloudAnalyticsText } from "../../../assets/texts";
import { highchartColors } from "../../../cmpBaseColors";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { defaultCurrencySymbol } from "../../../constants/common";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import useGenerateReport from "../../../Pages/CloudAnalytics/generateReport";
import ReportData from "../../../Pages/CloudAnalytics/ReportData";
import { consoleErrorWithSentry } from "../../../utils";
import { formatCurrency } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { type ConfigField, type createReportParams } from "../../CloudAnalytics/generateReport/types";
import { executeQueryRequest } from "../../CloudAnalytics/report/ReportQuery";
import { FixedFilters, MetricOptions } from "../../CloudAnalytics/utilities";
import {
  anomalyPreviewColumns,
  anomalyPreviewColumnsWithOperation,
  anomalyPreviewHeaders,
  anomalyPreviewHeadersWithOperation,
} from "../anomalyPreviewTableColumns";
import { anomalyDetailsText } from "../texts";
import { type AnomalyPreviewTableRow } from "../types";
import {
  type AlertData,
  getAnomalyPreviewTableRows,
  getAnomalyStartTime,
  getAttributionId,
  getPreviewQueryRequestPayload,
} from "../utils";
import AnomalyDetailsContent from "./AnomalyDetailsContent";
import AnomalySidePanel from "./AnomalySidePanel";
import { dataSeriesLabels } from "./consts";
import { asyncGetNewAnomalyDataSeries, asyncGetOldAnomalyDataSeries, getChartPointValue, isNewAnomaly } from "./utils";

HighchartsMore(Highcharts);
AnnotationsModel(Highcharts);
Highcharts.setOptions({
  lang: {
    thousandsSep: "\u002C",
  },
});

export const s3ServiceName = "Amazon Simple Storage Service";

const ResourcesRowComponent = ({ row }) => (
  <>
    <TableCell>
      <Typography variant="body1" component="span">
        {row.sku}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography variant="body1" component="span">
        {row.resource}
      </Typography>
    </TableCell>
    {row.operation && (
      <TableCell>
        <Typography variant="body1" component="span">
          {row.operation}
        </Typography>
      </TableCell>
    )}
    <TableCell sx={{ textAlign: "right" }}>
      <Typography variant="body1" component="span">
        {formatCurrency(row.cost, CurrencyCodes.USD, 2)}
      </Typography>
    </TableCell>
  </>
);

const CostAnomalyDetails = () => {
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const history = useHistory();
  const theme = useTheme();
  const [, , , color3, color4, color5, color6, color7, , color9, color10, color11] =
    highchartColors[theme.palette.mode];

  const { assetId, alertId } = useParams<{ assetId: string; alertId: string }>();
  const [graphOptions, setGraphOptions] = useState<Highcharts.Options | null>(null);
  const [alertData, setAlertData] = useState<AlertData | null>(null);
  const [whatTheme, setWhatTheme] = useState(theme);
  const { customer } = useCustomerContext();
  const { isMobile } = useFullScreen("md", { noSsr: true }); // noSsr flag needed to get exact response on first render

  useEffect(() => {
    mixpanel.track("anomalies.detail.view");
  }, []);

  const [rawData] = useDocumentData(
    useMemo(
      () => getCollection(AssetModel).doc(assetId).collection("billingAnomalies").doc(alertId),
      [alertId, assetId]
    )
  );

  const generateReport = useGenerateReport();
  const customerCurrency = customer.settings?.currency ?? defaultCurrencySymbol;

  useEffect(() => {
    const getAlert = async () => {
      if (!rawData) {
        return;
      }

      if (theme !== whatTheme) {
        theme && setWhatTheme(theme);
      }

      let allDataSeries: Highcharts.SeriesOptionsType[] = [];

      if (isNewAnomaly(rawData)) {
        allDataSeries = await asyncGetNewAnomalyDataSeries(rawData, customerCurrency, setAlertData, theme);
      } else {
        allDataSeries = await asyncGetOldAnomalyDataSeries(rawData, customerCurrency, setAlertData, theme);
      }
      const cssCaption: Highcharts.CSSObject = theme.typography.caption as Highcharts.CSSObject;

      const options: Highcharts.Options = {
        chart: {
          height: isMobile ? 370 : 500,
          backgroundColor: "transparent",
          animation: false,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        exporting: {
          enabled: false,
        },
        xAxis: {
          type: "datetime",
          accessibility: {
            rangeDescription: "",
          },
          labels: {
            style: {
              color: theme.palette.text.secondary,
            },
          },
          crosshair: true,
        },
        yAxis: {
          title: {
            text: null,
          },
          labels: {
            formatter({ value }) {
              return formatCurrency(value, customerCurrency);
            },
            style: {
              color: theme.palette.text.secondary,
            },
          },
          reversedStacks: false,
          min: 0,
        },
        tooltip: {
          shared: true,
          valueSuffix: "",
          valueDecimals: 2,
          formatter() {
            if (isNewAnomaly(rawData)) {
              const dateFormat = rawData.metadata.frequency === "HOURLY" ? DateTime.DATETIME_MED : DateTime.DATE_HUGE;
              return `${this.points?.reduce(
                (s, currentPoint) =>
                  `${s}<br/><span style="color:${currentPoint.color as string};">\u25CF</span> ${
                    currentPoint.series.name
                  }: <b>${getChartPointValue(this.points ?? [], currentPoint)}</b>`,
                DateTime.fromMillis(parseInt(this.x!.toString(), 10)).toLocaleString(dateFormat)
              )}<br/>Total cost: <b>${(
                (this.points?.[0].total ?? 0) -
                (this.points?.[1]?.series.name === dataSeriesLabels.adjustmentSinceTimeOfDetection
                  ? this.points[1].y!
                  : 0)
              ).toFixed(2)}</b>`;
            }
            return this.points?.reduce(
              (s, point) =>
                `${s}<br/><span style="color:${point.color as string};">\u25CF</span> ${point.series.name}: <b>${point.y!.toFixed(
                  2
                )}</b>`,
              DateTime.fromMillis(parseInt(this.x!.toString())).toLocaleString(DateTime.DATE_HUGE)
            );
          },
        },
        series: allDataSeries,
        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
            events: {
              legendItemClick() {
                return false;
              },
            },
          },
          column: {
            stacking: "normal",
            borderWidth: isMobile ? 0.25 : 0,
            maxPointWidth: isMobile ? 12 : 25,
            groupPadding: 0.05,
            pointPadding: 0.025,
          },
          spline: {
            marker: {
              enabled: true,
            },
          },
        },
        annotations: [
          {
            draggable: "",
            labels: [
              {
                point: "anomalyPoint",
                text: isNewAnomaly(rawData) ? "Anomaly detected" : "Anomaly",
                verticalAlign: isNewAnomaly(rawData) ? "middle" : "bottom",
                ...(isNewAnomaly(rawData) ? {} : { distance: 38 }),
                x: -75,
                y: 0,
              },
              {
                point: "inactivePoint",
                text: "Anomaly inactive",
                verticalAlign: "middle",
                x: 75,
                y: 0,
              },
              {
                point: "anomalyPoint",
                formatter(this: Highcharts.Point) {
                  if (!this.y) {
                    return "";
                  }
                  return formatCurrency(this.y, customerCurrency);
                },
                backgroundColor: "transparent",
                borderWidth: 0,
                distance: 10,
                style: {
                  color: theme.palette.text.primary,
                },
              },
            ],
          },
        ],
        legend: {
          layout: "horizontal",
          enabled: true,
          itemStyle: {
            color: theme.palette.text.secondary,
          },
          itemHoverStyle: {
            color: theme.palette.text.primary,
          },
          navigation: {
            enabled: true,
            activeColor: theme.palette.action.active,
            inactiveColor: theme.palette.action.disabled,
            style: {
              ...cssCaption,
              color: theme.palette.text.secondary,
            },
          },
          labelFormatter(this: Highcharts.Point | Highcharts.Series) {
            return isMobile || isNewAnomaly(rawData) ? this.name : `${rawData.metadata.service_name} - ${this.name}`;
          },
        },
      };
      setGraphOptions(options);
    };

    getAlert();
  }, [
    customer,
    alertId,
    assetId,
    theme,
    isMobile,
    whatTheme,
    customerCurrency,
    color9,
    color10,
    color11,
    color3,
    color7,
    color6,
    color4,
    color5,
    rawData,
  ]);

  const handleCreateReport = useCallback(async () => {
    if (!alertData) {
      return;
    }

    mixpanel.track("anomalies.detail.full-report.view");

    const cloudProvider = alertData.metadata.platform ?? AssetTypeGoogleCloud;
    let startTime = getAnomalyStartTime(alertData.metadata);
    if (cloudProvider === AssetTypeGoogleCloud) {
      startTime = startTime.setZone("America/Los_Angeles");
    }

    let timeInterval;
    let customTimeRange;

    const timeSettings: TimeSettingsConfig = {
      mode: TimeSettingsMode.Fixed,
    } as any;

    if (alertData.metadata.context === "RISING_DAILY_COSTS" || alertData.metadata.frequency === "DAILY") {
      timeInterval = TimeInterval.DAY;
      customTimeRange = {
        to: startTime.plus({ days: 15 }),
        from: startTime.minus({ days: 30 }),
      };
    } else if (!!alertData.metadata.context || alertData.metadata.frequency === "HOURLY") {
      timeInterval = TimeInterval.HOUR;
      customTimeRange = {
        to: startTime.plus({ days: 2 }),
        from: startTime.minus({ days: 5 }),
      };
    } else if (alertData.metadata.frequency === "WEEKLY") {
      timeInterval = TimeInterval.WEEK;
      customTimeRange = {
        to: startTime.plus({ weeks: 2 }),
        from: startTime.minus({ weeks: 5 }),
      };
    }

    const additionalFields: ConfigField[] = [];

    if (cloudProvider !== AssetTypeAmazonWebServices && alertData.metadata.billing_account_id) {
      additionalFields.push({
        type: Metadata.FIXED,
        id: FixedFilters.ACCOUNT,
        values: [alertData.metadata.billing_account_id],
      });
    }

    if (alertData.metadata.project_id) {
      additionalFields.push({
        type: Metadata.FIXED,
        id: FixedFilters.PROJECT,
        values: [String(alertData.metadata.project_id)],
      });
    }

    if (cloudProvider === AssetTypeAmazonWebServices) {
      additionalFields.push({
        type: Metadata.FIXED,
        id: FixedFilters.CREDIT,
        values: ["[Credit N/A]"],
      });
    }

    const attributionId = getAttributionId(cloudProvider, alertData.attribution);
    if (attributionId) {
      additionalFields.push({
        type: Metadata.ATTRIBUTION,
        id: Metadata.ATTRIBUTION,
        values: [attributionId],
      });
    }

    const createReportPayload: createReportParams = {
      name: `${alertData.metadata.service_name} Cost Anomaly on ${startTime.toFormat("dd LLLL yyyy")}`,
      description: "",
      config: {
        fields: [
          {
            type: Metadata.FIXED,
            id: FixedFilters.CLOUD,
            values: [cloudProvider],
          },
          {
            type: Metadata.FIXED,
            id: FixedFilters.SERVICE,
            values: alertData.metadata.service_name ? [alertData.metadata.service_name] : [],
          },
          {
            type: Metadata.FIXED,
            id: FixedFilters.SKU,
            groupBy: true,
            limit: 10,
            values: alertData.metadata.sku_name ? [alertData.metadata.sku_name] : [],
          },
          ...additionalFields,
        ],
        timeInterval,
        customTimeRange,
        timeSettings,
      },
    };

    const newReportId = await generateReport(createReportPayload);
    const reportUrl = `/customers/${customer.id}/analytics/reports/${newReportId}`;
    history.push(reportUrl);
  }, [alertData, customer, history, generateReport]);

  // zone for preview report data:
  const { isMobile: smDown } = useFullScreen();
  const api = useApiContext();
  const [queryRunning, setQueryRunning] = useState(false);
  const [reportData, setReportData] = useState<AnomalyPreviewTableRow[] | undefined>(undefined);
  const [reportError, setReportError] = useState(null);
  const [showOperation, setShowOperation] = useState(false);
  useEffect(() => {
    const isS3Service = alertData?.metadata.service_name === s3ServiceName;
    setShowOperation(isS3Service);
  }, [alertData]);
  const handleRun = useCallback(async () => {
    if (!alertData) {
      return;
    }
    setQueryRunning(true);
    try {
      if (alertData.metadata.source === "real-time") {
        if (!alertData.resource_data) {
          setQueryRunning(false);
          setReportError({
            code: cloudAnalyticsText.PREVIEW.NO_PREVIEW,
          } as any);
          return;
        }

        const rows = alertData.resource_data?.map((row) => ({
          sku: row.sku_description,
          resource: row.resource_id,
          cost: row.cost,
        }));
        setReportData(rows);
        setQueryRunning(false);
        return;
      }

      const request = getPreviewQueryRequestPayload(customer.id, alertData);
      if (!request) {
        setQueryRunning(false);
        setReportError({
          code: cloudAnalyticsText.PREVIEW.NO_PREVIEW,
        } as any);
        return;
      }
      const response = await executeQueryRequest(api, customer.id, request);
      if (!response) {
        setQueryRunning(false);
        setReportError({
          code: cloudAnalyticsText.PREVIEW.NO_PREVIEW,
        } as any);
        return;
      }
      const metricOffset = request.rows.length + request.cols.length;
      const vals = [metricOffset, metricOffset + 1];
      const numMetrics = MetricOptions.length;
      const anomalyPreviewTableRows = getAnomalyPreviewTableRows(
        alertData,
        new ReportData({
          data: response.data.rows,
          rows: request.rows,
          cols: request.cols,
          vals,
          numMetrics,
        }),
        request
      );
      if (!anomalyPreviewTableRows || anomalyPreviewTableRows.length === 0) {
        const e = {
          code: cloudAnalyticsText.PREVIEW.NO_PREVIEW,
        } as any;
        setReportError(e);
        return e;
      }
      setReportData(anomalyPreviewTableRows);
    } catch (error: any) {
      if (error.name === "CanceledError") {
        const e = error ?? {
          code: cloudAnalyticsText.PREVIEW.ERR_CANCELED,
        };
        setReportError(e);
        return e;
      }
      consoleErrorWithSentry(error);
      setQueryRunning(false);
      const e = error ?? {
        code: cloudAnalyticsText.PREVIEW.ERR_UKNOWN,
      };
      setReportError(e);
      return e;
    }
    setReportError(null);
    setQueryRunning(false);
  }, [alertData, customer.id, api]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (isMounted) {
        await handleRun();
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [handleRun]);
  const plot = useMemo(() => {
    const title = (
      <Typography variant="h4" sx={{ mr: "auto", fontWeight: "medium", fontSize: "1.125rem" }} data-cy="title">
        Resources contributing to this anomaly
      </Typography>
    );
    const previewHeight = smDown ? "300px" : "500px";
    if (queryRunning && !reportError) {
      return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 2 }}>
          <Typography variant="h6" sx={{ position: "absolute" }} color="textSecondary">
            Fetching Cost Anomaly Preview Report
          </Typography>
          <Skeleton animation="pulse" variant="rectangular" height={previewHeight} width="100%" />
        </Box>
      );
    }
    if (reportError) {
      return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 2 }}>
          <Alert variant="standard" severity="error" sx={{ width: { md: "100%" } }}>
            <AlertTitle>Error</AlertTitle>
            Error loading Cost Anomaly Preview Report
          </Alert>
        </Box>
      );
    }
    if (reportData) {
      return (
        <FilterTable<AnomalyPreviewTableRow>
          rowComponent={ResourcesRowComponent}
          tableItems={reportData.filter((x) =>
            alertData?.metadata.sku_name ? x.sku === alertData.metadata.sku_name : true
          )}
          headerColumns={showOperation ? anomalyPreviewHeadersWithOperation : anomalyPreviewHeaders}
          filterColumns={showOperation ? anomalyPreviewColumnsWithOperation : anomalyPreviewColumns}
          showFilterBar={false}
          defaultSortingColumnValue="cost"
          defaultSortDirection="desc"
          fillEmptySpace={false}
          toolbarProps={{
            title,
          }}
        />
      );
    }
  }, [alertData?.metadata.sku_name, queryRunning, reportData, reportError, showOperation, smDown]);

  const showWarning = () => {
    if (alertData) {
      const alertDate = getAnomalyStartTime(alertData.metadata, {
        zone: "America/Los_Angeles",
      }).toUTC();

      const startDate = DateTime.fromISO("2023-08-02", { zone: "utc" });
      const endDate = DateTime.fromISO("2023-09-08", { zone: "utc" });
      const service = alertData.metadata.service_name;
      return service === "Compute Engine" && alertDate >= startDate && alertDate <= endDate;
    }
    return false;
  };

  return (
    <>
      <CardHeader
        avatar={
          <IconButton
            aria-label="Back"
            component={Link}
            to={`/customers/${customer.id}/anomaly`}
            size="large"
            sx={{ marginRight: -2 }}
          >
            <BackIcon color="primary" />
          </IconButton>
        }
        title={<Typography variant="h3">Anomaly details</Typography>}
        sx={{
          "& .MuiCardHeader-action": {
            alignSelf: "center",
          },
        }}
        action={
          <>
            {alertData && !isMobile && (
              <Stack direction="row" spacing={1.25}>
                {(isDoitEmployee || userRoles.cloudAnalytics) && (
                  <Button onClick={handleCreateReport} variant="text" color="primary">
                    <OpenInNewIcon fontSize="small" sx={{ marginRight: 1 }} /> {anomalyDetailsText.OPEN_IN_REPORT}
                  </Button>
                )}
                <AnomalySidePanel date={alertData.timestamp} explainer={alertData.explainer} />
              </Stack>
            )}
          </>
        }
      />
      {alertData && (
        <>
          {showWarning() && (
            <Alert severity="warning">
              We are seeing an increase in the amount of cost anomalies related to the removal of Flexsave CUDs. We do
              expect the incremental costs reported to eventually decrease as Sustained Use Discount (SUD) go into
              effect at the one, two, three, and four week marks. Note, not all machine types are eligible for SUD.
            </Alert>
          )}
          <AnomalyDetailsContent alertData={alertData} />
        </>
      )}
      {graphOptions && (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="chart"
          options={graphOptions}
          updateArgs={[true]}
          allowChartUpdate={true}
        />
      )}
      <CardContent sx={{ pt: 0, pb: 1, marginTop: "3rem" }}>{plot}</CardContent>
    </>
  );
};

export default CostAnomalyDetails;
