import { type SyntheticEvent, useEffect, useState } from "react";

import { type MSTeamsTarget, NotificationProviderType, type SlackChannel } from "@doitintl/cmp-models";
import { Autocomplete, Box, Chip, Stack, TextField, Typography } from "@mui/material";
import isEmail from "is-email";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { SlackChannelSelection } from "../../../../Components/Slack/ChannelSelection";
import { isProduction } from "../../../../constants";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { WizardTitle } from "./components/WizardTitle";
import { MSTeamsSelection } from "./MSTeamsSelection";
import { type ChannelConfig, type ChannelType, isMSTeamsChannelType, isSlackChannelType } from "./types";
import { getTitleDescriptionStep } from "./utils";

type Props = {
  channelConfig?: ChannelConfig;
  updateChannelConfig: <K extends keyof ChannelConfig>(key: K, value: ChannelConfig[K]) => void;
  viewOnly: boolean;
};

export const NotificationsSelectChannels = ({ channelConfig, updateChannelConfig, viewOnly }: Props) => {
  const { customer } = useCustomerContext();
  const [channels, setChannels] = useState<ChannelType>([]);

  useEffect(() => {
    if (channelConfig?.providerType === NotificationProviderType.SLACK) {
      setChannels(channelConfig?.providerTarget?.[NotificationProviderType.SLACK] || []);
    } else if (channelConfig?.providerType === NotificationProviderType.MSTEAMS) {
      setChannels(channelConfig?.providerTarget?.[NotificationProviderType.MSTEAMS] || []);
    }
  }, [channelConfig]);

  const onChannelSelectionChange = (recipientsSlackChannels: SlackChannel[]) => {
    setChannels(recipientsSlackChannels);
    updateChannelConfig("providerTarget", { [`${channelConfig?.providerType}`]: recipientsSlackChannels });
  };

  const onEmailSelectionChange = (_: SyntheticEvent, emails: string[]) => {
    if (emails.length === 0) {
      updateChannelConfig("providerTarget", { [`${channelConfig?.providerType}`]: [] });
      return;
    }

    const currentEmail = emails[emails.length - 1];
    if (!isEmail(currentEmail)) {
      return;
    }

    // Duplicates will be removed from the notification, so we will remove them from this interface too to reflect that.
    const normalisedEmails = new Set(emails.map((email) => email.toLowerCase()));
    updateChannelConfig("providerTarget", { [`${channelConfig?.providerType}`]: [...normalisedEmails] });
  };

  const channelTargets = channelConfig?.providerTarget?.[channelConfig.providerType] || [];
  const appName = isProduction ? "DoiT International" : "DoiT International (dev)";

  const { title, description } = getTitleDescriptionStep(channelConfig);

  return (
    <Box>
      <WizardTitle title={title} description={description} />
      {channelConfig?.providerType === NotificationProviderType.SLACK &&
        isSlackChannelType(channelConfig?.providerType, channels) && (
          <>
            <SlackChannelSelection
              customerId={customer.id}
              disabled={channelConfig.disableTargetUpdate || viewOnly}
              channels={channels}
              setChannels={onChannelSelectionChange}
            />
            <Stack spacing={2} direction="column" sx={{ mt: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                }}
              >
                Can't find your private Slack channels?
              </Typography>
              <Typography variant="body1" color="textSecondary">
                To send notifications to private Slack channels, run the following command inside the channel you want
                to use:
              </Typography>
              <CopyCodeBlock base={`/invite @${appName}`} />
            </Stack>
          </>
        )}
      {channelConfig?.providerType === NotificationProviderType.EMAIL && (
        <Autocomplete
          multiple
          freeSolo
          disabled={channelConfig.disableTargetUpdate || viewOnly}
          data-cy="billing-account-select"
          value={channelTargets}
          options={[]}
          disableCloseOnSelect
          onChange={onEmailSelectionChange}
          size="small"
          limitTags={4}
          renderTags={(value, props) =>
            value.map((option, index) => (
              <Chip size="small" label={option} {...props({ index })} key={index.toString()} />
            ))
          }
          renderInput={(params) =>
            channelConfig.disableTargetUpdate ? (
              <TextField
                {...params}
                variant="outlined"
                helperText="You can only edit billing profile emails on the Billing profiles page"
              />
            ) : (
              <TextField {...params} variant="outlined" helperText="Press enter to add an email" />
            )
          }
        />
      )}
      {channelConfig?.providerType === NotificationProviderType.MSTEAMS &&
        isMSTeamsChannelType(channelConfig?.providerType, channels) && (
          <MSTeamsSelection
            savedChannels={channels}
            updateChannels={(channels: MSTeamsTarget[]) => {
              updateChannelConfig("providerTarget", { [channelConfig?.providerType]: channels });

              setChannels(channels);
            }}
          />
        )}
    </Box>
  );
};
