import { type JSX, useLayoutEffect, useMemo, useRef, useState } from "react";

import { Paper, Stack } from "@mui/material";
import Box from "@mui/material/Box";

import { useFullScreen } from "../../../utils/dialog";

type Props = {
  menu: JSX.Element;

  operations: JSX.Element;
  children: JSX.Element;
};

export function DashboardLayout({ menu, operations, children }: Props) {
  const [offset, setOffset] = useState<number | undefined>();
  const ref = useRef<HTMLDivElement>(null);

  const { isMobile } = useFullScreen();

  useLayoutEffect(() => {
    function calculateOffset() {
      const topOffset = ref?.current?.getBoundingClientRect()?.top ?? 0;
      setOffset(topOffset);
    }
    calculateOffset();
    window.addEventListener("resize", calculateOffset);

    return () => {
      window.removeEventListener("resize", calculateOffset);
    };
  }, [isMobile]);

  const minHeight = useMemo(() => `calc(100vh - ${offset}px)`, [offset]);

  if (isMobile) {
    return (
      <Paper elevation={0}>
        <Stack
          direction="column"
          sx={{
            justifyContent: {
              sm: "center",
            },
          }}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              pt: 2,
              px: 2,
              mb: 2,
            }}
          >
            {menu}
            {operations}
          </Stack>

          <Box
            ref={ref}
            sx={{
              p: {
                xs: 0,
                sm: 2,
              },
              minHeight,
            }}
          >
            {children}
          </Box>
        </Stack>
      </Paper>
    );
  }

  return (
    <Stack direction="row" ref={ref}>
      <Box sx={{ width: 208, maxHeight: minHeight, overflowY: "auto" }}>{menu}</Box>
      <Paper
        variant="outlined"
        sx={{
          borderRadius: 0,
          borderTop: 0,
          borderRight: 0,
          borderBottom: 0,
          minHeight,
        }}
      >
        <Stack
          direction="column"
          sx={{ px: 2, width: "calc(100vw - 208px)", maxHeight: minHeight, overflowY: "scroll" }}
        >
          {children}
        </Stack>
      </Paper>
    </Stack>
  );
}
