import { Box, CardHeader, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import ButtonCreateRampPlan from "../../../../Pages/RampPlans/components/ButtonCreateRampPlan";
import { type RampPlanModel } from "../../types";
import { isRampPlanComplete } from "../../utils";
import RampPlanCard from "./RampPlanCard";

type RampListNewProps = {
  rampPlans: RampPlanModel[];
  takenNamesArr: string[]; // names of existing ramp plans, passed from query in parent component, to prevent duplication
};

const RampListNew = ({ rampPlans, takenNamesArr }: RampListNewProps) => {
  const activeRampPlans: RampPlanModel[] = [];
  const inactiveRampPlans: RampPlanModel[] = [];

  rampPlans.forEach((rampPlan) => {
    if (isRampPlanComplete(rampPlan)) {
      inactiveRampPlans.push(rampPlan);
    } else {
      activeRampPlans.push(rampPlan);
    }
  });

  return (
    <Grid
      container
      data-cy="rampplanslist"
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        size={{
          xl: 8,
          md: 10,
          sm: 12,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <CardHeader title="Ramp plans" titleTypographyProps={{ variant: "h1" }} sx={{ mb: -1 }} />
            <Typography variant="body1" color="textSecondary" sx={{ ml: 2 }}>
              Track your progress towards your committed spend
            </Typography>
          </Box>
          <Box>
            <ButtonCreateRampPlan takenNamesArr={takenNamesArr} />
          </Box>
        </Box>
        <Box
          data-cy="activeRampPlans"
          sx={{
            alignItems: "column",
            ml: 2,
          }}
        >
          {activeRampPlans.map((plan, index) => (
            <RampPlanCard key={plan.id} rampPlan={plan} index={index} />
          ))}
        </Box>
        {inactiveRampPlans.length > 0 && (
          <>
            <Box>
              <Typography variant="h4" sx={{ ml: 2, mt: 6, mb: 3 }} data-cy="inactiveRampPlansHeader">
                Inactive ramp plans
              </Typography>
            </Box>
            <Box
              data-cy="inactiveRampPlans"
              sx={{
                alignItems: "column",
                ml: 2,
              }}
            >
              {inactiveRampPlans.map((plan, index) => (
                <RampPlanCard key={plan.id} rampPlan={plan} index={index} />
              ))}
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default RampListNew;
