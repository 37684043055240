import { type DiscountPeriod } from "@doitintl/cmp-models";
import { Box, Stack, Typography } from "@mui/material";

import DataCouplet from "../../../../Components/DataCouplet";
import { getDiscountPeriodDateTooltip } from "../../utils";

type Props = {
  discountPeriods: DiscountPeriod[];
  "data-cy"?: string;
};

export const DiscountPeriodsSection = (props: Props) => (
  <Box component="section" data-cy={props["data-cy"]}>
    <Typography variant="h2">Discounts</Typography>
    <Stack component="dl" spacing={2}>
      {props.discountPeriods.map((period, index) => (
        <DataCouplet
          key={`discount-period-${index}`}
          field={`Discount period ${++index}`}
          value={`${period.discount}%`}
          helperText={getDiscountPeriodDateTooltip(period)}
        />
      ))}
    </Stack>
  </Box>
);
