import { type Dispatch, type SetStateAction } from "react";

import { type CommitmentPeriodType, type ContractStateType, type VendorContract } from "../../types";
import { hasCommitmentPeriodDiscount } from "../../utils";

const commitmentValidator = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>,
  isVendorContract: boolean
): boolean => {
  const commitmentPeriods = isVendorContract
    ? (state.vendorContract?.commitmentPeriods as CommitmentPeriodType[])
    : state.commitmentPeriods;

  if (commitmentPeriods.length === 0) {
    return false;
  }

  const startDate = isVendorContract ? state.vendorContract?.startDate : state.startDate;
  const endDate = isVendorContract ? state.vendorContract?.endDate : state.endDate;
  const firstCommitmentPeriod = commitmentPeriods[0];
  const lastCommitmentPeriod = commitmentPeriods[commitmentPeriods.length - 1];
  let isValid = true;

  if (firstCommitmentPeriod.startDate?.toMillis() !== startDate?.toMillis()) {
    firstCommitmentPeriod.errors.startDate = true;
    isValid = false;
  }

  if (lastCommitmentPeriod.endDate?.toMillis() !== endDate?.toMillis()) {
    lastCommitmentPeriod.errors.endDate = true;
    isValid = false;
  }

  commitmentPeriods.forEach((cp, i) => {
    const prevCp = commitmentPeriods[i - 1];

    if (!cp.startDate?.isValid || (prevCp && prevCp.endDate?.toMillis() !== cp.startDate?.toMillis())) {
      cp.errors.startDate = true;
      isValid = false;
    }

    if (!cp.endDate?.isValid || cp.endDate.toMillis() <= cp.startDate.toMillis()) {
      cp.errors.endDate = true;
      isValid = false;
    }

    if (cp.value === null) {
      cp.errors.value = true;
      isValid = false;
    }

    if (hasCommitmentPeriodDiscount(state.type) && cp.discount === null) {
      cp.errors.discount = true;
      isValid = false;
    }
  });

  if (isVendorContract) {
    setState({ ...state, vendorContract: { ...(state.vendorContract as VendorContract), commitmentPeriods } });
  } else {
    setState({ ...state, commitmentPeriods });
  }

  return isValid;
};

export const validateCommitmentStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => commitmentValidator(state, setState, false);
export const validateVendorCommitmentStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => commitmentValidator(state, setState, true);
