import { type Dispatch, memo, type SetStateAction } from "react";

import { type ComparativeFeature } from "@doitintl/cmp-models";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";

import { reportTxt } from "../../../../assets/texts/CloudAnalytics";
import { useReportConfig } from "../../Context";
import { type PopoverAnchorEl } from "../../report/types";
import { type ColKeySort } from "../../ReportData";
import { type ErrorCode, isTable } from "../../utilities";
import AnalyticsRendererContent from "./AnalyticsRendererContent";
import CountFieldMissing from "./CountFieldMissing";
import ErrorAlerts from "./ErrorAlerts";

type Props = {
  cacheReport: () => void;
  cancelReportRun: () => void;
  colKeySort?: ColKeySort | null;
  countFieldMissing: boolean;
  disableCacheBtn: boolean;
  forceRender: boolean;
  formatter: (value: any, short?: any, comparative?: ComparativeFeature) => any;
  isReportCachedBtnLoading: boolean;
  leftDrawerTransitioning: boolean;
  renderKey: number;
  reportError?: { code: ErrorCode; message?: string };
  reportReady: boolean;
  reportQueryRunning: boolean;
  setColKeySort: Dispatch<SetStateAction<ColKeySort | undefined>>;
  setPopoverAnchorEl: (anchorEl: PopoverAnchorEl | null) => void;
  showForecast: boolean;
  showReportCacheBtn: boolean;
};

const PlotRenderer = ({
  cacheReport,
  cancelReportRun,
  colKeySort,
  countFieldMissing,
  disableCacheBtn,
  forceRender,
  formatter,
  isReportCachedBtnLoading,
  leftDrawerTransitioning,
  renderKey,
  reportError,
  reportReady,
  reportQueryRunning,
  setColKeySort,
  setPopoverAnchorEl,
  showForecast,
  showReportCacheBtn,
}: Props) => {
  const {
    reportConfig: { renderer },
    reportData,
    reportDataWForecasts,
  } = useReportConfig();

  const isTableRenderer = isTable(renderer);
  if (leftDrawerTransitioning && !isTableRenderer) {
    return null;
  }

  if (countFieldMissing) {
    return <CountFieldMissing setPopoverAnchorEl={setPopoverAnchorEl} />;
  }

  if (reportQueryRunning) {
    return (
      <Stack
        sx={{
          alignItems: "center",
          pt: 20,
          pb: 5,
        }}
      >
        <CircularProgress size={34} />
        <Typography
          variant="subtitle2"
          sx={{
            mt: 1,
          }}
        >
          {reportTxt.LOADING}
        </Typography>
        <Button sx={{ mt: 6 }} onClick={cancelReportRun}>
          {reportTxt.STOP_GENERATING_REPORT}
        </Button>
      </Stack>
    );
  }

  if (reportError) {
    return (
      <ErrorAlerts
        cacheReport={cacheReport}
        disableCacheBtn={disableCacheBtn}
        errorCode={reportError.code}
        errorMessage={reportError?.message}
        isReportCachedBtnLoading={isReportCachedBtnLoading}
        showReportCacheBtn={showReportCacheBtn}
      />
    );
  } else if (reportReady) {
    const forecastStart = reportData?.current?.getForecastStartIndex() || 0;
    const isForecast = showForecast && !!reportDataWForecasts;
    const dataProp = isForecast && reportDataWForecasts.current ? reportDataWForecasts.current : reportData.current;
    if (!dataProp) {
      return null;
    }
    return (
      <AnalyticsRendererContent
        colKeySort={colKeySort}
        dataProp={dataProp}
        forceRender={forceRender}
        forecastStart={forecastStart}
        formatter={formatter}
        isForecast={isForecast}
        renderKey={renderKey}
        setColKeySort={setColKeySort}
      />
    );
  }

  return null;
};

export default memo(PlotRenderer);
