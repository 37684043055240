import { useEffect, useMemo, useState } from "react";

import { type FieldInputProps, getIn, useFormikContext } from "formik";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FieldCommonProps<T = any> = ReturnType<typeof useFieldCommonProps<T>>;

export function useFieldCommonProps<T = any>({ value, name }: FieldInputProps<T>, label: string, required: boolean) {
  const formikProps = useFormikContext();
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    const isTouched = getIn(formikProps.touched, name) === true;
    const errorMessage = getIn(formikProps.errors, name);
    setErrorMessage(isTouched && typeof errorMessage === "string" ? errorMessage : undefined);
    setShowError(errorMessage !== undefined && isTouched);
  }, [name, formikProps.touched, formikProps.errors, errorMessage]);

  return useMemo(
    () => ({
      value,
      label,
      name,
      onChange: formikProps.handleChange,
      onBlur: formikProps.handleBlur,
      required,
      helperText: errorMessage,
      error: showError,
      autoComplete: "off",
    }),
    [errorMessage, formikProps.handleBlur, formikProps.handleChange, label, name, required, showError, value]
  );
}

export function useCheckboxCommonProps(fieldProps: FieldInputProps<boolean>, label: string) {
  const { setFieldValue } = useFormikContext();
  const commonFieldProps = useFieldCommonProps(fieldProps, label, false);

  return useMemo(
    () => ({
      ...commonFieldProps,
      value: "on",
      checked: fieldProps.value ?? false,
      onChange: (_, checked) => setFieldValue(fieldProps.name, checked),
    }),
    [commonFieldProps, fieldProps.name, fieldProps.value, setFieldValue]
  );
}
