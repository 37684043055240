import Box from "@mui/material/Box";

import { CloudSpendSummaryCards } from "../../../../Components/Dashboard/CloudSpendSummaryCards";
import { WidgetsGrid } from "./WidgetsGrid";
import type { Widget } from "../../../../Context/useCurrentDashboardContext";

type Props = {
  widgets: Widget[];
  disableAutoRefreshing: boolean;
  showSummaryCards: boolean;
};

export function WidgetsGridPageWrapper({ widgets, showSummaryCards, disableAutoRefreshing }: Props) {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          mb: { xs: 2, lg: 0 },
        }}
      >
        {showSummaryCards && <CloudSpendSummaryCards />}
      </Box>

      <WidgetsGrid widgets={widgets} disableAutoRefreshing={disableAutoRefreshing} />
    </>
  );
}
