import { type Cluster } from "@doitintl/cmp-models";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";

interface DeleteClusterDialogProps {
  cluster: Cluster;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteClusterDialog = ({ cluster, open, onClose, onDelete }: DeleteClusterDialogProps) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Remove cluster</DialogTitle>
    <DialogContent>
      <Stack direction="column" spacing={2}>
        <Typography variant="body2">
          You are about to remove the cluster <strong>{cluster.name}</strong>
        </Typography>
        <Typography variant="body2">
          You can reconnect this cluster at any time in the future. Are you sure you want to remove this cluster?
        </Typography>
      </Stack>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button variant="contained" color="error" onClick={onDelete}>
        Remove cluster
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteClusterDialog;
