import { useCallback, useMemo } from "react";

import { CloudAnalyticsModel, type CloudAnalyticsModelWidgetModel, Feature, type Key } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";

import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useCachedHook } from "../../../Context/DynamicContext/useCachedHook";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import ReportData, { type ColKeySort } from "../../../Pages/CloudAnalytics/ReportData";
import { getMetricsCount } from "../../../Pages/CloudAnalytics/utilities";
import { CSPCustomerID, getUserOrgID } from "../../../utils/common";
import { getWidgetDocId } from "../../../utils/widgets";
import { useCustomerId } from "../../hooks/useCustomerId";
import { createWidgetWithExtraData, filterDataByType, getVals } from "./utils";

type EnrichedReportData = {
  reportId: string;
  description: string;
  rawRows: any[];
  rawForecastRows: any[];
};

export type WidgetWithExtraData = CloudAnalyticsModelWidgetModel & EnrichedReportData;
export type WidgetWithExtraDataWrapper = {
  data: {
    title: string;
    description: string;
    rawReport: WidgetWithExtraData | null;
    updateTime: Date;
  } | null;
  loading: boolean;
};

function useNonCachedWidgetData(widgetId: string): WidgetWithExtraDataWrapper {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { userOrganization, customerOrPresentationModeCustomer, customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(customer);
  const [customerId, reportId] = widgetId.split("_");
  const orgId = getUserOrgID(isDoitEmployee, userOrganization);

  const getDocRef = useCallback(
    (id: string) =>
      getCollection(CloudAnalyticsModel)
        .doc("widgets")
        .collection("cloudAnalyticsWidgets")
        .doc(getWidgetDocId(id, reportId, orgId)),
    [reportId, orgId]
  );

  const [customerWidgetData, loadingCustomerWidgetData] = useDocumentData(getDocRef(customerId));

  const refForPresentationMode = useMemo(() => {
    if (
      isPresentationMode &&
      ((!loadingCustomerWidgetData && !customerWidgetData) || customerWidgetData?.type === "preset")
    ) {
      return getDocRef(customerOrPresentationModeCustomer.id);
    }
  }, [
    isPresentationMode,
    loadingCustomerWidgetData,
    customerWidgetData,
    getDocRef,
    customerOrPresentationModeCustomer.id,
  ]);

  const [presentationWidgetData] = useDocumentData(refForPresentationMode);

  const widgetData = useMemo(() => {
    if (isPresentationMode && presentationWidgetData) {
      return presentationWidgetData;
    } else {
      return customerWidgetData;
    }
  }, [customerWidgetData, isPresentationMode, presentationWidgetData]);

  return useMemo(() => {
    if (widgetData) {
      const widgetDataExtraData: WidgetWithExtraData = {
        ...widgetData,
        reportId,
        description: widgetData.description ? widgetData.description : "cloud cost and usage analytics",
        rawRows: widgetData.data.rows ? Object.values(widgetData.data.rows) : [],
        rawForecastRows: widgetData.data.forecastRows ? Object.values(widgetData.data.forecastRows) : [], // firestore does not support nested arrays, this restores the data to be like BQ rows
      };
      return {
        data: {
          title: widgetData.name,
          description: widgetData.description,
          rawReport: widgetDataExtraData,
          updateTime: new Date(),
        },
        loading: false,
      };
    } else {
      return { data: null, loading: loadingCustomerWidgetData };
    }
  }, [widgetData, reportId, loadingCustomerWidgetData]);
}

type ReportDataOptions = {
  isCachedReport?: boolean;
  cloudAnalyticsKey?: Key;
};

export const useCreateWidgetReportData = (
  rawReport: WidgetWithExtraData | undefined | null,
  colKeySort: ColKeySort | undefined,
  selectedFilter: string,
  reportDataOptions?: ReportDataOptions
): ReportData | null => {
  const { isCachedReport = false, cloudAnalyticsKey } = reportDataOptions || {};
  const { transforms } = useCloudAnalyticsContext();
  const customerId = useCustomerId();

  const memoizedColKeySort = useMemo(() => (isCachedReport ? undefined : colKeySort), [colKeySort, isCachedReport]);

  return useMemo(() => {
    if (!rawReport) {
      return null;
    }

    let rows = rawReport.config?.rows;
    let rawRows = rawReport.rawRows;

    if (cloudAnalyticsKey) {
      [rawRows, rows] = filterDataByType(rawReport, cloudAnalyticsKey, selectedFilter);
    }

    const { config, rawForecastRows } = rawReport;

    const { aggregator, colOrder, cols, comparative, features, metric, rowOrder, count, forecastSettings } =
      config ?? {};

    const showForecast = !!features?.includes(Feature.FORECAST) || !!forecastSettings;

    const isCSP = customerId === CSPCustomerID;

    const vals = getVals(
      {
        cols,
        metric,
        rows,
        count,
      },
      customerId
    );
    const numMetrics = getMetricsCount(isCSP, metric, !!count);
    const reportDataProps = {
      data: rawRows,
      forecastRows: rawForecastRows,
      aggregator,
      rows,
      cols,
      rowOrder,
      colOrder,
      vals,
      transforms,
      features,
      numMetrics,
      comparative,
      forecastSettings,
    };
    let newReportData = new ReportData(reportDataProps);

    if (showForecast && rawForecastRows?.length > 0) {
      const startIndex = newReportData?.rowStartIndex;
      const fullData = [...rawRows, ...rawForecastRows.slice(startIndex)];
      newReportData = new ReportData({
        ...reportDataProps,
        data: fullData,
      });
    }

    if (memoizedColKeySort) {
      newReportData?.setSort(rowOrder, colOrder, memoizedColKeySort);
    }

    return newReportData;
  }, [rawReport, customerId, transforms, cloudAnalyticsKey, selectedFilter, memoizedColKeySort]);
};

export function useWidgetData(widgetId: string) {
  return useCachedHook(useNonCachedWidgetData, {
    key: widgetId,
    args: [widgetId],
  });
}

export const useEnhancedWidgetData = (
  widgetId: string,
  widgetWithData?: CloudAnalyticsModelWidgetModel
): WidgetWithExtraDataWrapper => {
  const { data, loading: widgetLoading } = useWidgetData(widgetId);

  const enhancedData = useMemo(() => {
    if (!data && widgetWithData?.data) {
      const res = createWidgetWithExtraData(widgetWithData);
      return res.data;
    } else {
      return data;
    }
  }, [data, widgetWithData]);

  return { data: enhancedData, loading: widgetLoading };
};
