import { useCallback, useState } from "react";

import { useHistory } from "react-router";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Container, Divider, Stack, Typography } from "@mui/material";

import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import LoadingButton from "../../../Components/LoadingButton";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type AnalyticsResourcesAttributionGroups } from "../analyticsResources/types";
import { type CloudAnalyticsHistoryState } from "../types";
import { AllocationDragAndDrop } from "./AllocationDragGroup";
import { type AttributionGroupWithRef } from "./types";
import { getAttributionsIDsFromAG } from "./utils";

export const Allocation = ({
  selectedAttributionGroup,
}: {
  selectedAttributionGroup?: AttributionGroupWithRef | AnalyticsResourcesAttributionGroups | undefined;
  footerMaxWidth?: number;
  relativeFooter?: boolean;
}) => {
  const { filteredAttributions: attributions } = useAttributionsContext();
  const history = useHistory<CloudAnalyticsHistoryState>();
  const { customer } = useCustomerContext();
  const [dragAndDropGroups, setDragAndDropGroups] = useState<Record<string, string[]>>({
    main: getAttributionsIDsFromAG(selectedAttributionGroup),
  });

  const handleClose = useCallback(() => {
    history.push(history.location?.state?.prevPage ?? `/customers/${customer.id}/analytics/allocations`);
  }, [history, customer.id]);

  const handleDragAndDropChange = useCallback(
    (newGroups: Record<string, string[]>) => {
      setDragAndDropGroups(newGroups);
    },
    [setDragAndDropGroups]
  );

  return (
    <Box sx={{ height: "calc(100vh - 110px)", display: "grid", gridTemplateRows: "1fr 1px 60px" }}>
      <Container maxWidth="md" sx={{ overflow: "auto", mt: 4 }}>
        <Stack justifyContent="flex-start" alignItems="flex-start" rowGap={1}>
          <Button
            startIcon={<BackIcon />}
            color="inherit"
            variant="text"
            onClick={handleClose}
            size="large"
            sx={{ ml: "-35px" }}
          >
            <Typography variant="h4">{allocationTxt.ALLOCATION_TITLE}</Typography>
          </Button>
          <Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              {allocationTxt.ALLOCATION_DESCRIPTION}
            </Typography>
          </Box>
          <AllocationDragAndDrop
            attributions={attributions}
            attributionsInGroup={dragAndDropGroups}
            setAttributionsInGroup={handleDragAndDropChange}
          />
        </Stack>
      </Container>

      <Divider />
      <Container maxWidth="md">
        <Stack direction="row" justifyContent="flex-end" spacing={2} p={1}>
          <Button color="primary" onClick={handleClose} variant="text" size="large" sx={{ mr: 3 }}>
            {allocationTxt.ALLOCATION_CANCEL}
          </Button>

          <LoadingButton
            color="primary"
            loading={false}
            onClick={() => {
              // TODO: remove this and replace with proper save callback handler
            }}
            variant="contained"
            mixpanelEventId="allocation.save"
            data-cy="stepper-complete-button"
            size="large"
          >
            {allocationTxt.ALLOCATION_SAVE}
          </LoadingButton>
        </Stack>
      </Container>
    </Box>
  );
};
