import { useMemo } from "react";

import { type BudgetConfig } from "@doitintl/cmp-models";
import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";

import { type FirestoreTimestamp, TimestampFromDate } from "../../../../utils/firebase";

export type EqualityCheckProps = {
  current: { name: string; description: string; config: BudgetConfig };
  initial: { name: string; description: string; config: BudgetConfig };
  draft: boolean;
};

export const compareDates = (
  value1: string | Date | FirestoreTimestamp,
  value2: string | Date | FirestoreTimestamp
) => {
  if (!value1 || !value2) {
    return value1 === value2;
  }
  if (typeof value1 === "string" || value1 instanceof Date) {
    value1 = TimestampFromDate(new Date(value1));
  }
  if (typeof value2 === "string" || value2 instanceof Date) {
    value2 = TimestampFromDate(new Date(value2));
  }

  return value1.isEqual(value2);
};

export const getEqualityCheck = ({ current, initial, draft }: EqualityCheckProps): boolean => {
  const validate = (name: keyof typeof current | keyof typeof initial) => current[name] === initial[name];

  return (
    validate("name") &&
    validate("description") &&
    Object.entries(current.config).every(([key, value]) =>
      isEqualWith(value, initial.config[key], (vv1, vv2) => {
        switch (key) {
          case "recipients":
          case "collaborators":
          case "renderer":
            return true;
          case "alerts":
          case "filters":
            return Object.keys(vv1).every((key) =>
              Object.keys(vv1[key]).every((k) => (k === "amount" ? true : isEqual(vv1[key][k], vv2[key][k])))
            );
          case "scope":
            return isEqual(
              vv1.map((v) => v.id),
              vv2.map((v) => v.id)
            );
          case "startPeriod":
          case "endPeriod":
            if (draft && (!vv1 || !vv2)) {
              return true;
            }
            return compareDates(vv1, vv2);
          case "amount":
          case "metric":
          case "timeInterval":
          case "growthPerPeriod":
          case "type":
          case "originalAmount":
          case "currency":
          case "usePrevSpend":
          case "allowGrowth":
          case "dataSource":
          case "rollover":
            return isEqualWith(vv1, vv2);
          default:
            return false;
        }
      })
    )
  );
};

const useEqualityCheck = ({ current, initial, draft }: EqualityCheckProps) =>
  useMemo(
    () =>
      getEqualityCheck({
        current,
        initial,
        draft,
      }),
    [current, initial, draft]
  );

export default useEqualityCheck;
