import { useCallback, useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router";
import { type Cluster } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { Stepper } from "../../../Components/Stepper";
import { customersRoute } from "../../../Navigation/core/utils";
import { useClusters } from "../hooks";
import { type Feature } from "./features";
import { AccessEntryStep, EnableFeaturesStep, SelectClustersStep, TestConnectionStep } from "./Steps";
import { type StepConfig, useSteps } from "./utils";

const ClusterActivation = () => {
  const history = useHistory();
  const location = useLocation();
  const customerId = useCustomerId();
  const successSnackbar = useSuccessSnackbar();

  const [clusters] = useClusters();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  const [selectedCluster, setSelectedCluster] = useState<Cluster | null>(null);
  const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>([]);
  const [yamlFileHasBeenDownloaded, setYamlFileHasBeenDownloaded] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const accountId = queryParams.get("accountId");
    const clusterId = queryParams.get("clusterId");
    const startingStep = parseInt(queryParams.get("startingStep") ?? "0", 10);
    const cluster = clusters?.find((c) => c.id === clusterId);

    if (!cluster || cluster.projectId !== accountId) {
      return;
    }

    if (accountId) setSelectedAccountId(accountId);
    if (cluster) setSelectedCluster(cluster);
    if (startingStep) setCurrentStep(startingStep);
  }, [location.search, clusters]);

  const stepConfig: StepConfig[] = [
    {
      label: "Select clusters",
      isComplete: !!selectedCluster,
      children: (
        <SelectClustersStep
          selectedAccountId={selectedAccountId}
          selectedCluster={selectedCluster}
          onSelectAwsAccount={setSelectedAccountId}
          onSelectCluster={setSelectedCluster}
        />
      ),
    },
    {
      label: "Access entry",
      children: <AccessEntryStep accountId={selectedAccountId} cluster={selectedCluster} />,
    },
    {
      label: "Enable features",
      isComplete: selectedFeatures.length > 0 && yamlFileHasBeenDownloaded,
      children: (
        <EnableFeaturesStep
          accountId={selectedAccountId}
          cluster={selectedCluster}
          selectedFeatures={selectedFeatures}
          setSelectedFeatures={setSelectedFeatures}
          onDownloadYaml={() => {
            setYamlFileHasBeenDownloaded(true);
          }}
        />
      ),
    },
    {
      label: "Test connection",
      children: (
        <TestConnectionStep
          accountId={selectedAccountId}
          cluster={selectedCluster}
          selectedFeatures={selectedFeatures}
        />
      ),
    },
  ];

  const steps = useSteps({ stepConfig, currentStep });

  const goBack = useCallback(() => {
    history.push(`${customersRoute(customerId)}/assets/kubernetes`);
  }, [history, customerId]);

  const handleSubmit = () => {
    successSnackbar("EKS cluster successfully activated");
    goBack();
  };

  return (
    <Box
      sx={{
        pt: 4,
        pb: 5,
      }}
    >
      <Stepper
        submitButtonLabel="Done"
        steps={steps}
        loading={false}
        onCancel={goBack}
        onSubmit={handleSubmit}
        overrideStep={currentStep}
        getCurrentStep={setCurrentStep}
        footerMaxWidth={830}
        maxWidth={1000}
        contentSx={{
          mt: 3,
          mb: 3,
          width: 1000,
        }}
      />
    </Box>
  );
};

export default ClusterActivation;
