import { type ReactNode, type SyntheticEvent, useCallback, useMemo, useRef, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export const NodeMenu = ({
  onDelete,
  onEdit,
  httpOperationLoading,
  isPublished,
}: {
  onDelete?: () => void;
  onEdit?: () => void;
  httpOperationLoading?: boolean;
  isPublished: boolean;
}) => {
  const anchorEl = useRef<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const menuOptions = useMemo(() => {
    const options: { label: ReactNode; key: string; action: () => void; disabled: boolean }[] = [];
    if (onEdit) {
      options.push({
        label: <Typography>Edit</Typography>,
        key: "edit",
        action: onEdit,
        disabled: false,
      });
    }

    if (onDelete) {
      options.push({
        label: <Typography color="error">Delete</Typography>,
        key: "delete",
        action: onDelete,
        disabled: httpOperationLoading || isPublished,
      });
    }

    return options;
  }, [onEdit, onDelete, httpOperationLoading, isPublished]);

  const handleClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setOpen(true);
  }, []);

  const handleCloseMenu = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    setOpen(false);
  }, []);

  if (!menuOptions.length) {
    return null;
  }

  return (
    <>
      <Box ref={anchorEl}>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Box>

      <Menu
        open={open}
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
      >
        {menuOptions.map((option) => (
          <MenuItem key={option.key} onClick={option.action} disabled={!!option.disabled}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
