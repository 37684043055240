import * as Yup from "yup";

import { type MetadataOption } from "../../types";

const step0 = Yup.object({
  contractType: Yup.string().required("Provider selection is required"),
  planName: Yup.string().required("Plan name is required"),
  selectedContracts: Yup.array(),
  currency: Yup.string().required("Currency is required"),
});
const step1 = Yup.object({
  startDate: Yup.string().required("Start date is required"),
  periods: Yup.array().of(
    Yup.object().shape({
      periodLength: Yup.number().max(100, "Value must be under 100").required("Period length is required"),
      commitmentValue: Yup.number().typeError("Must be a number").required("Commitment value is required"),
      spend: Yup.number().typeError("Must be a number").required("Marketplace spend value is required"),
    })
  ),
  excess: Yup.boolean(),
  excessRollOverPercentage: Yup.number()
    .typeError("Must be a number")
    .when("excess", {
      is: true,
      then: (schema) => schema.required("Excess value is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  shortfall: Yup.boolean(),
  shortfallRollOverPercentage: Yup.number()
    .typeError("Must be a number")
    .when("shortfall", {
      is: true,
      then: (schema) => schema.required("Shortfall value is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
});
const step2 = Yup.object({});

export const validationSchema = [step0, step1, step2];

export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "short", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};

export const addMonths = (date: Date, months: number) => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + months);
  return newDate;
};

export const getFieldName = (selected: MetadataOption) => {
  const label = selected.data.label ?? selected.data.field;
  const plural = selected.data.plural ?? label;
  return plural;
};
