import {
  type CurrencyCode,
  type CurrencyCodes,
  type GSuiteAssetModel,
  type GSuitePlanName,
  type OldCurrency,
} from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { type LegacyDataFormat } from "../../../../../Components/Catalog/Catalog.context";
import { type Asset, type Contract, type Contracts } from "../../../../../types";
import { consoleErrorWithSentry } from "../../../../../utils";
import sku from "../../../../../utils/gsuite";

type AnnualPriceDates = {
  totalDays: number;
  remainingDays: number;
  endDate: DateTime;
  startDate: DateTime;
};

export type NewAssetValues = {
  gsuiteSubscription: Asset<GSuiteAssetModel>;
  plan: string;
  quantity: number;
  service: LegacyDataFormat;
};

const oldPricingDate = DateTime.utc(2021, 1, 16);
const priceAdjustment = {
  USD: { monthly: 300 / 12, annual: 300 },
  EUR: { monthly: 276 / 12, annual: 276 },
  GBP: { monthly: 240 / 12, annual: 240 },
  AUD: { monthly: 408 / 12, annual: 408 },
};

const getMonthlyPrice = (
  currency: OldCurrency,
  creationDate: DateTime,
  now: DateTime,
  catalogItem?: LegacyDataFormat,
  specialDiscount?: number
): number => {
  if (!catalogItem) {
    consoleErrorWithSentry(new Error("No catalog item was found for building price estimate; defaulting to zero."));
    return 0;
  }

  let price = catalogItem.data.price[currency];

  if (
    catalogItem.data.plan === "ANNUAL" &&
    catalogItem.type === "g-suite" &&
    !!catalogItem.data.prevPrice?.[currency] &&
    !!catalogItem.data.prevPriceEndDate &&
    creationDate?.isValid &&
    creationDate < DateTime.fromJSDate(catalogItem.data.prevPriceEndDate.toDate())
  ) {
    price = catalogItem.data.prevPrice[currency];
  }

  // Pricing change for Google workspace ent. plus (G Suite ent.)
  if (catalogItem.data.skuId === sku.GSuiteEnterprise) {
    if (creationDate?.isValid && creationDate < oldPricingDate) {
      price = priceAdjustment[currency].monthly;
    }
  }

  if (price === undefined) {
    return 0;
  }

  // Cloud identity premium - 1 year discount
  if (catalogItem.data.skuId === sku.CloudIdentityPremium) {
    const creationDateAnniversary = creationDate.plus({ years: 1 });
    if (creationDateAnniversary?.isValid) {
      if (now < creationDateAnniversary) {
        price *= 0.665;
      }
    }
  }

  // If there's a special promo for this contract
  if (catalogItem.data.plan === "ANNUAL") {
    if (specialDiscount) {
      price *= 1 - specialDiscount * 0.01;
    }
  }

  return price;
};

const getAnnualPrice = ({
  currency,
  creationDate,
  now,
  catalogItem,
  specialDiscount,
  dates,
}: {
  currency: OldCurrency;
  creationDate: DateTime;
  now: DateTime;
  catalogItem?: LegacyDataFormat;
  specialDiscount: number;
  dates: AnnualPriceDates;
}): number => {
  if (!catalogItem) {
    consoleErrorWithSentry(new Error("No catalog item was found for building price estimate; defaulting to zero."));
    return 0;
  }

  let price = catalogItem.data.price[currency];

  if (
    catalogItem.data.plan === "ANNUAL" &&
    catalogItem.type === "g-suite" &&
    !!catalogItem.data.prevPriceEndDate &&
    !!catalogItem.data.prevPrice?.[currency] &&
    creationDate?.isValid &&
    creationDate < DateTime.fromJSDate(catalogItem.data.prevPriceEndDate.toDate())
  ) {
    price = catalogItem.data.prevPrice[currency];
  }

  // Old pricing
  if (catalogItem.data.skuId === sku.GSuiteEnterprise) {
    if (creationDate?.isValid && creationDate < oldPricingDate) {
      price = priceAdjustment[currency].annual;
    }
  }

  if (price === undefined) {
    return 0;
  }

  // Cloud identity premium - 1 year discount
  if (catalogItem.data.skuId === sku.CloudIdentityPremium) {
    const creationDateAnniversary = creationDate.plus({ years: 1 });
    if (creationDateAnniversary?.isValid) {
      if (now < creationDateAnniversary) {
        price *= 0.665;
      }
    }
  }

  // Prorated pricing
  if (dates.totalDays > 0) {
    price *= dates.remainingDays / dates.totalDays;
  }

  // If subscription is not a 1-year plan
  if (dates.endDate > dates.startDate.plus({ years: 1 })) {
    const yearDays = dates.startDate.plus({ years: 1 }).diff(dates.startDate, "days").days;
    price *= dates.totalDays / yearDays;
  }

  // If there's a special promo for this contract
  if (specialDiscount) {
    price *= 1 - specialDiscount * 0.01;
  }

  return price;
};

/**
 * License update totals information.
 * For the same functionality as the old `getTotal()`, use the `amount` field in the returned object.
 *
 * @param asset The asset for which the licenses are being updated
 * @param quantity The ∆ of the number of licenses
 * @param planName The plan name
 * @param payment The payment frequency
 * @param catalogItem The catalogItem for the licenses
 * @param contract? Contract under which the asset is held
 */
export const getTotal = ({
  asset,
  quantity,
  planName,
  payment,
  catalogItem,
  contract,
}: {
  asset: Asset<GSuiteAssetModel>;
  quantity: number;
  planName: GSuitePlanName;
  payment: "MONTHLY" | "YEARLY";
  catalogItem?: LegacyDataFormat;
  contract?: Contract;
}): { amount: number; discount: number; currency: CurrencyCode } => {
  const monthlyPay = payment === "MONTHLY";
  let currency: CurrencyCode = "USD";

  if (quantity <= 0) {
    return { amount: 0, currency, discount: 0 };
  }

  const now = DateTime.utc().startOf("day");
  let total = 0;
  let discount = 0;
  let specialDiscount;

  if (contract) {
    discount = contract.discount;
    specialDiscount = contract.properties?.specialDiscount;
  }

  if (asset.data.properties.settings?.currency) {
    currency = asset.data.properties.settings.currency;
  }

  let startDate;
  let endDate;
  const creationDate = DateTime.fromMillis(asset.data.properties.subscription.creationTime).toUTC().startOf("day");

  if (planName === "ANNUAL") {
    if (asset.data.properties.settings?.plan?.isCommitmentPlan) {
      const { plan } = asset.data.properties.settings;
      startDate = DateTime.fromMillis(plan.commitmentInterval.startTime).toUTC().startOf("day");
      endDate = DateTime.fromMillis(plan.commitmentInterval.endTime).toUTC().startOf("day");
    }
    if (asset.data.properties.subscription.plan.isCommitmentPlan) {
      const { plan } = asset.data.properties.subscription;
      startDate = DateTime.fromMillis(plan.commitmentInterval.startTime).toUTC().startOf("day");
      endDate = DateTime.fromMillis(plan.commitmentInterval.endTime).toUTC().startOf("day");
    }
  }

  if (monthlyPay) {
    const price = getMonthlyPrice(currency as OldCurrency, creationDate, now, catalogItem, specialDiscount);
    total = quantity * price;
  } else {
    // YEARLY PAYMENT
    if (startDate?.isValid && endDate?.isValid) {
      const remainingDays = endDate.diff(now, "days").days;
      const totalDays = endDate.diff(startDate, "days").days;
      const dates = { remainingDays, totalDays, endDate, startDate };
      const price = getAnnualPrice({
        currency: currency as OldCurrency,
        creationDate,
        now,
        catalogItem,
        specialDiscount,
        dates,
      });
      total = quantity * price;
    }
  }

  total = total * (1 - discount * 0.01);

  return {
    amount: total,
    currency: currency as CurrencyCodes,
    discount,
  };
};

const extractContract = (contracts: Contracts, subscriptionEntityId = ""): Contract | undefined => {
  const today = DateTime.utc().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  return contracts.find((c) => {
    if (!c.entity || !subscriptionEntityId) {
      return false;
    }
    if (c.entity.id !== subscriptionEntityId) {
      return false;
    }
    if (!c.endDate) {
      return true;
    }
    const endDate = DateTime.fromJSDate(c.endDate.toDate()).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    return today <= endDate;
  });
};

/**
 * Returns limited totals information similar to {@link getTotal} but for a new asset and narrower in scope.
 * @param values The form values
 * @param contracts The contracts for the customer
 * @returns The total amount, currency, and discount applied
 */
export const getNewAssetTotal = (values: NewAssetValues, contracts: Contracts) => {
  const entityId = values.gsuiteSubscription.data.entity?.id;
  const contract = extractContract(contracts, entityId);

  let total: number;
  let discount = 0;
  if (contract?.discount) {
    discount = contract.discount;
  }

  if (
    values.service.data.skuId !== sku.GoogleChromeEnterprise &&
    values.service.data.skuId !== sku.CloudIdentityPremium &&
    values.service.data.plan === "ANNUAL" &&
    values.service.data.payment === "YEARLY"
  ) {
    // Chrome ent. is a real annual subscription, show full price for it.
    // plan=ANNUAL, payment=YEARLY
    let startDate;
    let endDate;

    // set start and end date if possible
    if (values.gsuiteSubscription.data.properties.settings?.plan) {
      const { plan } = values.gsuiteSubscription.data.properties.settings;
      if (plan.isCommitmentPlan) {
        startDate = DateTime.fromMillis(plan.commitmentInterval.startTime).toUTC().startOf("day");
        endDate = DateTime.fromMillis(plan.commitmentInterval.endTime).toUTC().startOf("day");
      }
    } else {
      const { plan } = values.gsuiteSubscription.data.properties.subscription;
      if (plan.isCommitmentPlan) {
        startDate = DateTime.fromMillis(plan.commitmentInterval.startTime).toUTC().startOf("day");
        endDate = DateTime.fromMillis(plan.commitmentInterval.endTime).toUTC().startOf("day");
      }
    }

    if (startDate?.isValid && endDate?.isValid) {
      const now = DateTime.utc().startOf("day");
      const remainingDays = endDate.diff(now, ["days"]).days;
      const totalDays = endDate.diff(startDate, ["days"]).days;
      if (totalDays > 0) {
        total = (values.quantity * values.service.data.price.USD * remainingDays) / totalDays;
      } else {
        total = values.quantity * values.service.data.price.USD;
      }
    } else {
      total = values.quantity * values.service.data.price.USD;
    }
  } else {
    total = values.quantity * values.service.data.price.USD;
  }

  return { total: total * (1 - discount * 0.01), discount, contract };
};

/**
 * Converts the raw renewal type into a friendly label
 * @param renewalType raw renewal type from asset data
 */
export const getRenewalTypeLabel = (renewalType: string | null | undefined): string => {
  switch (renewalType) {
    case "SWITCH_TO_PAY_AS_YOU_GO":
      return "Switch to Flexible";
    case "AUTO_RENEW":
      return "Auto Renew";
    case "AUTO_RENEW_MONTHLY_PAY":
      return "Auto Renew";
    case "AUTO_RENEW_YEARLY_PAY":
      return "Auto Renew";
    case "CANCEL":
      return "Cancel Subscription";
    case "RENEW_CURRENT_USERS":
      return "Auto Renew";
    case "RENEW_CURRENT_USERS_MONTHLY_PAY":
      return "Auto Renew";
    case "RENEW_CURRENT_USERS_YEARLY_PAY":
      return "Auto Renew";
    default:
      return renewalType ?? "";
  }
};
