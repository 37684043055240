import { useEffect, useState } from "react";

import { getModelByPath, isPathRelative, isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import { ModelType, NodeTransformationType, type ReferencedNodeValue } from "@doitintl/cmp-models";
import { Stack, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import * as yup from "yup";

import { ReferencedFieldStandalone } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldStandalone";
import { type NodeWitOutputModel } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useFieldCommonProps } from "../../../ApiActionParametersForm/useFieldCommonProps";
import { FieldSectionHeader } from "../../../Common/FieldSectionHeader";
import { useTransformationNodeSchemaContext } from "./TransformationNodeForm";

const newFieldNameLabel = "New field name";

const requiredReferencedNodeSchema = yup
  .object({
    referencedNodeId: yup.string().default(""),
    referencedField: yup.array().of(yup.string().required()).default([]),
  })
  .test("referenced-field-required", ({ label }) => `${label} is a required field`, isDefinedReferencedNodeValue);

const optionalSchema = yup.mixed().optional();

function isDefinedReferencedNodeValue(value: unknown) {
  return isReferencedNodeValue(value) && value.referencedNodeId !== "";
}

function areReferencedNodeValuesEqual(a: ReferencedNodeValue, b: ReferencedNodeValue) {
  return (
    isDefinedReferencedNodeValue(a) &&
    isDefinedReferencedNodeValue(b) &&
    a.referencedNodeId === b.referencedNodeId &&
    a.referencedField.length === b.referencedField.length &&
    a.referencedField.every((aToken, idx) => aToken === b.referencedField[idx])
  );
}

function getReferencedNodeValueModel(value: unknown, referenceableNodes: NodeWitOutputModel[]) {
  if (!isReferencedNodeValue(value)) {
    return null;
  }
  const referencedNodeModel = referenceableNodes.find(({ id }) => id === value.referencedNodeId)?.outputModel;
  if (referencedNodeModel === undefined) {
    return null;
  }
  return getModelByPath(referencedNodeModel, value.referencedField);
}

function isComplexModelType(type: ModelType) {
  return [ModelType.LIST, ModelType.STRUCTURE, ModelType.MAP, ModelType.UNION].includes(type);
}

function getRequiredPrimitiveReferencedNodeSchema(referenceableNodes: NodeWitOutputModel[]) {
  return requiredReferencedNodeSchema.test(
    "referenced-field-primitive",
    ({ label }) => `${label} must reference a primitive field`,
    (value) => {
      const maybeModel = getReferencedNodeValueModel(value, referenceableNodes);
      if (!maybeModel) {
        return false;
      }
      return !isComplexModelType(maybeModel.type);
    }
  );
}

function doesDataToJoinReferenceTheSameNode(payload: unknown, dataSourceNode: ReferencedNodeValue) {
  return isReferencedNodeValue(payload) && payload.referencedNodeId === dataSourceNode.referencedNodeId;
}

function generateJoinActionSchema(referenceableNodes: NodeWitOutputModel[], dataSourceNode: ReferencedNodeValue) {
  return yup.object({
    type: yup.string().default(NodeTransformationType.JOIN),
    newFieldName: yup.string().default("").required().label(newFieldNameLabel).trim(),
    payload: requiredReferencedNodeSchema.label("Data to join").test(
      "payload-model",
      ({ label }) => `${label} must not reference the same data as data source`,
      (value) =>
        !doesDataToJoinReferenceTheSameNode(value, dataSourceNode) ||
        !areReferencedNodeValuesEqual(value, dataSourceNode)
    ),
    primaryKey: yup.mixed().when("payload", ([payload]) => {
      if (doesDataToJoinReferenceTheSameNode(payload, dataSourceNode)) {
        return optionalSchema;
      }

      return getRequiredPrimitiveReferencedNodeSchema(referenceableNodes).label("Primary key");
    }),
    foreignKey: yup.mixed().when(["payload", "primaryKey"], ([payload, primaryKey]) => {
      if (doesDataToJoinReferenceTheSameNode(payload, dataSourceNode)) {
        return optionalSchema;
      }
      return getRequiredPrimitiveReferencedNodeSchema(referenceableNodes)
        .label("Foreign key")
        .test(
          "foreign-key-model",
          ({ label }) => `${label} must match primary key type`,
          (value) => {
            const maybeForeignKeyModel = getReferencedNodeValueModel(value, referenceableNodes);
            const maybePrimaryKeyModel = getReferencedNodeValueModel(primaryKey, referenceableNodes);
            if (!maybeForeignKeyModel || !maybePrimaryKeyModel) {
              return false;
            }
            return maybeForeignKeyModel.type === maybePrimaryKeyModel.type;
          }
        );
    }),
  });
}

export const JoinTransformationForm = () => {
  const { setTransformationSchema, referenceableNodes } = useTransformationNodeSchemaContext();
  const { getFieldProps, setFieldValue } = useFormikContext();

  const { value: rootReferencedNodeValue } = getFieldProps<ReferencedNodeValue>("referencedNodeField");

  const { value: transformationFieldValue } = getFieldProps<NodeTransformationType>("transformation");

  const newFieldNameCommonProps = useFieldCommonProps<string>(
    getFieldProps("transformation.newFieldName"),
    newFieldNameLabel,
    true
  );
  const payloadCommonProps = useFieldCommonProps<ReferencedNodeValue>(
    getFieldProps("transformation.payload"),
    "Data to join",
    true
  );
  const primaryKeyCommonProps = useFieldCommonProps<ReferencedNodeValue>(
    getFieldProps("transformation.primaryKey"),
    "Primary key",
    true
  );
  const foreignKeyCommonProps = useFieldCommonProps<ReferencedNodeValue>(
    getFieldProps("transformation.foreignKey"),
    "Foreign key",
    true
  );

  const [validationSchema, setValidationSchema] = useState<yup.AnyObjectSchema>(
    generateJoinActionSchema(referenceableNodes, rootReferencedNodeValue)
  );

  const shouldShowKeys =
    isDefinedReferencedNodeValue(payloadCommonProps.value) &&
    !doesDataToJoinReferenceTheSameNode(payloadCommonProps.value, rootReferencedNodeValue);

  const payloadOnChange = (e: React.ChangeEvent<{ value: ReferencedNodeValue }>): void => {
    payloadCommonProps.onChange(e);
    const foreignKeyRoot = e.target.value;

    if (
      // when user switches from other node to the same node joint, clear keys values
      !isReferencedNodeValue(foreignKeyRoot) ||
      doesDataToJoinReferenceTheSameNode(foreignKeyRoot, rootReferencedNodeValue)
    ) {
      setFieldValue(primaryKeyCommonProps.name, undefined);
      setFieldValue(foreignKeyCommonProps.name, undefined);
    } else {
      // when user switches from the same node to other node joint, set the root for primary key
      if (!isReferencedNodeValue(primaryKeyCommonProps.value)) {
        setFieldValue(primaryKeyCommonProps.name, rootReferencedNodeValue);
      }
      // when user switches from the same node to other node joint or changes the data-to-join root, (re)set the root for foreign key
      if (
        !isReferencedNodeValue(foreignKeyCommonProps.value) ||
        !isPathRelative(foreignKeyRoot.referencedField, foreignKeyCommonProps.value.referencedField)
      ) {
        setFieldValue(foreignKeyCommonProps.name, foreignKeyRoot);
      }
    }
  };

  useEffect(() => {
    const validationSchema = generateJoinActionSchema(referenceableNodes, rootReferencedNodeValue);
    setValidationSchema(validationSchema);
    setTransformationSchema(validationSchema);
  }, [referenceableNodes, rootReferencedNodeValue, setTransformationSchema]);

  // TODO: this effect is common for all transformation actions, it can be hoisted to the main component
  useEffect(() => {
    if (!transformationFieldValue) {
      setFieldValue("transformation", validationSchema.getDefault());
    }
  }, [setFieldValue, validationSchema, transformationFieldValue, rootReferencedNodeValue]);

  return (
    <Stack spacing={2} pb={4}>
      <TextField fullWidth variant="outlined" size="small" {...newFieldNameCommonProps} />
      <FieldSectionHeader
        title="How to use join"
        subtitle="Select a piece of data you would like to join to the referenced field. Joint data will be attached as a new field."
      />
      <ReferencedFieldStandalone
        {...payloadCommonProps}
        onChange={payloadOnChange}
        referenceableNodes={referenceableNodes}
      />
      {shouldShowKeys && (
        <>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Define how data should be joined by specifying primary and foreign keys:
          </Typography>
          <ReferencedFieldStandalone
            {...primaryKeyCommonProps}
            referenceableNodes={referenceableNodes}
            rootReferencedNodeValue={rootReferencedNodeValue}
          />

          <ReferencedFieldStandalone
            {...foreignKeyCommonProps}
            referenceableNodes={referenceableNodes}
            rootReferencedNodeValue={payloadCommonProps.value}
          />
        </>
      )}
    </Stack>
  );
};
