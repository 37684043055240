import { useState } from "react";

import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import k8sLogo from "../../assets/kubernetes-logo.svg";
import ClusterDialog from "./ClusterDialog/ClusterDialog";

const ClusterPageHeader = () => {
  const [clusterDialogIsOpen, setClusterDialogIsOpen] = useState(false);

  const openClusterDialog = () => {
    setClusterDialogIsOpen(true);
  };

  return (
    <>
      <ClusterDialog open={clusterDialogIsOpen} setOpen={setClusterDialogIsOpen} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: "8px 0" }}>
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
          <img src={k8sLogo} width={24} alt="Kubernetes" title="Kubernetes" />
          <Typography variant="h1" display="inline">
            Kubernetes
          </Typography>
        </Stack>
        <Stack spacing={2} direction="row">
          <Button onClick={openClusterDialog} variant="contained" color="primary">
            Connect self-managed cluster
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default ClusterPageHeader;
