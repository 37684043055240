import { type Dispatch, type SetStateAction } from "react";

import {
  AssetTypeAmazonWebServices,
  AssetTypeApigee,
  AssetTypeAwsPpaCloudfront,
  AssetTypeAwsPpaDto,
  AssetTypeAwsPpaEc2,
  AssetTypeAwsPpaPinpoint,
  AssetTypeAwsPpaS3,
  AssetTypeAwsPpaSes,
  AssetTypeAwsPpaShield,
  AssetTypeAwsSaasConsole,
  AssetTypeAwsStandalone,
  AssetTypeBetterCloud,
  AssetTypeBeyondCorp,
  AssetTypeChronicleSecurity,
  AssetTypeDoiTCloudIntelligence,
  AssetTypeDoiTCloudNavigator,
  AssetTypeDoiTCloudOneTimeServiceFee,
  AssetTypeDoiTCloudSolve,
  AssetTypeGcpPartnerLedPremiumSupport,
  AssetTypeGcpSaasConsole,
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudAnthos,
  AssetTypeGoogleCloudMandiant,
  AssetTypeGoogleCloudPremiumSupport,
  AssetTypeGoogleCloudProjectStandalone,
  AssetTypeGoogleCloudStandalone,
  AssetTypeGoogleCloudWebRisk,
  AssetTypeGoogleGeolocationServices,
  AssetTypeGSuite,
  AssetTypeLooker,
  AssetTypeLookerStudioPro,
  AssetTypeMicrosoftAzure,
  AssetTypeOffice365,
  AssetTypeRecaptchaEnterprise,
  AssetTypeSecurityCommandCenter,
  AssetTypeVirusTotal,
  AssetTypeZendesk,
} from "@doitintl/cmp-models";

import { type ContractStateType } from "../types";
import AwsSupportStep from "./AwsSupportStep/AwsSupportStep";
import { validateAwsSupportStep } from "./AwsSupportStep/awsSupportStepValidator";
import { CommitmentStep } from "./CommitmentStep/CommitmentStep";
import { validateCommitmentStep, validateVendorCommitmentStep } from "./CommitmentStep/commitmentStepValidator";
import { VendorCommitmentStep } from "./CommitmentStep/VendorCommitmentStep";
import { ContractDiscountStep, VendorContractDiscountStep } from "./ContractDiscountStep/ContractDiscountStep";
import { validateDiscountStep } from "./ContractDiscountStep/validateDiscountStep";
import { ContractStep } from "./ContractStep/ContractStep";
import { validateContractStep } from "./ContractStep/contractStepValidator";
import { DciSubscriptionStep } from "./DciSubscriptionStep/DciSubscriptionStep";
import { validateDciSubscriptionStep } from "./DciSubscriptionStep/dciSubscriptionStepValidator";
import { DocumentsStep } from "./DocumentsStep/DocumentsStep";
import { validateDocumentsStep } from "./DocumentsStep/documentStepValidator";
import { GcpPLPSStep } from "./GcpPartnerLedPremiumSupport/GcpPLPSStep";
import { validateGcpPLPSStep } from "./GcpPartnerLedPremiumSupport/gcpPLPSValidator";
import { GeneralStep } from "./GeneralStep/GeneralStep";
import { validateGeneralStep } from "./GeneralStep/generalStepValidator";
import { LookerContractSKUsStep } from "./LookerContractSKUsStep/LookerContractSKUsStep";
import { validateLookerContractSKUs } from "./LookerContractSKUsStep/lookerContractSKUsValidator";
import { LookerSubscriptionStep } from "./LookerSubscriptionStep/LookerSubscriptionStep";
import { validateLookerSubscriptionStep } from "./LookerSubscriptionStep/lookerSubscriptionStepValidator";
import { validateOneTimeServiceDetailsStep } from "./OneTimeServiceDetailsStep/oneTimeServiceDetailsStepValidator";
import { OneTimeServiceFeeDetailsStep } from "./OneTimeServiceDetailsStep/OneTimeServiceFeeDetailsStep";
import { SubscriptionStep } from "./SubscriptionStep/SubscriptionStep";
import { validateSubscriptionStep } from "./SubscriptionStep/subscriptionStepValidator";
import { VendorContractStep } from "./VendorContractStep/VendorContractStep";
import { validateVendorContract } from "./VendorContractStep/vendorContractStepValidator";

export type StepConfig = {
  title: string;
  component: React.ReactNode;
  isValid: (state: ContractStateType, setState: Dispatch<SetStateAction<ContractStateType>>) => boolean;
  skip?: () => boolean;
};

export const getStepsConfig = (state: ContractStateType): StepConfig[] => {
  let steps: StepConfig[];

  switch (state.type) {
    case AssetTypeAmazonWebServices:
      steps = [
        { title: "Customer contract", component: <ContractStep />, isValid: validateContractStep },
        { title: "Pricing", component: <AwsSupportStep />, isValid: validateAwsSupportStep },
        {
          title: "Customer commitment",
          component: <CommitmentStep />,
          skip: () => !state.isCommitment,
          isValid: validateCommitmentStep,
        },
        { title: "Discount", component: <ContractDiscountStep />, isValid: validateDiscountStep(false) },
        {
          title: "Vendor contract",
          component: <VendorContractStep />,
          skip: () => !state.isCommitment,
          isValid: validateVendorContract,
        },
        {
          title: "Vendor commitment",
          component: <VendorCommitmentStep />,
          skip: () => !state.isCommitment || !state.vendorContract?.commitmentType,
          isValid: validateVendorCommitmentStep,
        },
        {
          title: "Vendor discount",
          component: <VendorContractDiscountStep />,
          skip: () => !state.isCommitment || !state.vendorContract?.commitmentType,
          isValid: validateDiscountStep(true),
        },
      ];
      break;

    case AssetTypeGoogleCloud:
      steps = [
        { title: "Customer contract", component: <ContractStep />, isValid: validateContractStep },
        {
          title: "Customer commitment",
          component: <CommitmentStep />,
          skip: () => !state.isCommitment,
          isValid: validateCommitmentStep,
        },
        { title: "Discount", component: <ContractDiscountStep />, isValid: validateDiscountStep(false) },
        {
          title: "Vendor contract",
          component: <VendorContractStep />,
          skip: () => !state.isCommitment,
          isValid: validateVendorContract,
        },
        {
          title: "Vendor commitment",
          component: <VendorCommitmentStep />,
          skip: () => !state.isCommitment || !state.vendorContract?.commitmentType,
          isValid: validateVendorCommitmentStep,
        },
        {
          title: "Vendor discount",
          component: <VendorContractDiscountStep />,
          skip: () => !state.isCommitment || !state.vendorContract?.commitmentType,
          isValid: validateDiscountStep(true),
        },
      ];
      break;

    case AssetTypeLooker:
      steps = [
        { title: "Looker contract SKUs", component: <LookerContractSKUsStep />, isValid: validateLookerContractSKUs },
        {
          title: "Looker contract subscription",
          component: <LookerSubscriptionStep />,
          isValid: validateLookerSubscriptionStep,
        },
      ];
      break;

    case AssetTypeGcpPartnerLedPremiumSupport:
      steps = [{ title: "Customer contract", component: <GcpPLPSStep />, isValid: validateGcpPLPSStep }];
      break;

    case AssetTypeDoiTCloudSolve:
    case AssetTypeDoiTCloudNavigator:
      steps = [
        { title: "Subscription contract", component: <SubscriptionStep />, isValid: validateSubscriptionStep },
        // { title: "Discount", component: <ContractDiscountStep />, isValid: validateDiscountStep },
      ];
      break;

    case AssetTypeDoiTCloudIntelligence:
      steps = [
        { title: "Subscription contract", component: <DciSubscriptionStep />, isValid: validateDciSubscriptionStep },
      ];
      break;

    case AssetTypeDoiTCloudOneTimeServiceFee:
      steps = [
        { title: "Details", component: <OneTimeServiceFeeDetailsStep />, isValid: validateOneTimeServiceDetailsStep },
      ];
      break;

    case AssetTypeAwsPpaCloudfront:
    case AssetTypeAwsPpaS3:
    case AssetTypeApigee:
    case AssetTypeBetterCloud:
    case AssetTypeBeyondCorp:
    case AssetTypeChronicleSecurity:
    case AssetTypeAwsStandalone:
    case AssetTypeGoogleCloudProjectStandalone:
    case AssetTypeGoogleCloudAnthos:
    case AssetTypeGoogleCloudPremiumSupport:
    case AssetTypeGoogleCloudWebRisk:
    case AssetTypeGoogleGeolocationServices:
    case AssetTypeGSuite:
    case AssetTypeLookerStudioPro:
    case AssetTypeGoogleCloudMandiant:
    case AssetTypeMicrosoftAzure:
    case AssetTypeOffice365:
    case AssetTypeAwsSaasConsole:
    case AssetTypeGcpSaasConsole:
    case AssetTypeSecurityCommandCenter:
    case AssetTypeVirusTotal:
    case AssetTypeZendesk:
    case AssetTypeRecaptchaEnterprise:
    case AssetTypeGoogleCloudStandalone:
    case AssetTypeAwsPpaSes:
    case AssetTypeAwsPpaPinpoint:
    case AssetTypeAwsPpaDto:
    case AssetTypeAwsPpaEc2:
    case AssetTypeAwsPpaShield:
      steps = [
        { title: "Customer contract", component: <ContractStep />, isValid: validateContractStep },
        {
          title: "Customer commitment",
          component: <CommitmentStep />,
          isValid: validateCommitmentStep,
          skip: () => !state.isCommitment,
        },
      ];
      break;

    default:
      steps = [];
  }

  return [
    { title: "General", component: <GeneralStep />, isValid: validateGeneralStep },
    ...steps,
    { title: "Finalize", component: <DocumentsStep />, isValid: validateDocumentsStep },
  ];
};
