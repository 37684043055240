import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";

const InfoBlock = ({ title, children }: { title: string; children: React.ReactNode }) => {
  const isDarkMode = useDarkThemeCheck();
  return (
    <Grid
      sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
      size={{
        xs: 12,
        md: 3,
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: isDarkMode ? "#2D2D44" : grey[50],
          border: "1px solid",
          borderColor: "divider",
          borderRadius: "4px 4px 0 0",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Stack
        sx={{
          gap: 1.25,
          p: 2,
          borderWidth: "1px",
          borderStyle: "none solid solid solid",
          borderRadius: "0 0 4px 4px",
          borderColor: "divider",
          flexGrow: 1,
        }}
      >
        {children}
      </Stack>
    </Grid>
  );
};

export default InfoBlock;
