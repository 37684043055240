import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent, Skeleton } from "@mui/material";

import { useAWSAccounts } from "../../../../Settings/AWS/hooks";
import { AccountStatus } from "../../../../Settings/AWS/types";

interface AwsAccountSelectorProps {
  selectedAccountId: string | null;
  onSelect: (accountId: string) => void;
}
const AwsAccountSelector = ({ selectedAccountId, onSelect }: AwsAccountSelectorProps) => {
  const [accounts, _, accountsAreLoading] = useAWSAccounts();

  // Filter out duplicate healthy account IDs
  const uniqueHealthyAccounts = [
    ...new Map(
      accounts.filter((a) => a.status === AccountStatus.healthy).map((account) => [account.accountId, account])
    ).values(),
  ];

  const handleChange = (e: SelectChangeEvent<string | null>) => {
    onSelect(e.target.value as string);
  };

  if (accountsAreLoading) {
    return <Skeleton variant="rectangular" width={350} height={56} />;
  }

  return (
    <FormControl required>
      <InputLabel id="aws-account-selector-label">AWS account</InputLabel>
      <Select
        id="aws-account-selector"
        labelId="aws-account-selector-label"
        label="AWS account"
        value={selectedAccountId ?? ""}
        onChange={handleChange}
        sx={{ width: 350 }}
      >
        {uniqueHealthyAccounts.map((account) => (
          <MenuItem key={account.id} value={account.accountId}>
            {account.accountId}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AwsAccountSelector;
