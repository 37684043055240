import {
  type Dispatch,
  type JSX,
  type MouseEvent,
  type ReactElement,
  type RefObject,
  type SetStateAction,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { type ButtonBaseActions } from "@mui/material";
import { type AxiosError } from "axios";

import { loginTexts } from "../../assets/texts";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import useMountEffect from "../../Components/hooks/useMountEffect";
import useQueryString from "../../Components/hooks/useQueryString";
import {
  useErrorSnackbar,
  useInfoSnackbar,
  useSnackbar,
  useSuccessSnackbar,
} from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { routes } from "../../constants/navigation";
import { consoleErrorWithSentry } from "../../utils";
import { isDoitEmployeeEmail, isTestEmail } from "../../utils/email";
import useStoryblok from "../../utils/storyblok/hooks";
import { StoryblokContentType } from "../../utils/storyblok/types";
import { useQuery } from "../../utils/useQuery";
import { getAuthConfig, handleEmailCompleteSignup, handleEmailSignup } from "./api";
import {
  isSignInWithEmailLink,
  sendEmailVerification,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithRedirect,
} from "./auth";
import AgreementDialog from "./components/AgreementDialog";
import Preview from "./components/Preview";
import {
  AuthErrors,
  completeSignupCheckInboxQueryParam,
  completeSignupEmailQueryParam,
  CompleteSignupErrorParam,
  completeSignupErrorQueryParam,
  completeSignupProviderQueryParam,
  dynamicContentPathByLoginType,
  emailLocalStorageKey,
  EmailVerificationParamStatus,
  emailVerificationStatusParam,
  emailVerificationSuuccessMessage,
  getErrorMessageFromCompleteSignupErrorParam,
  getErrorMessageFromEmailVerificationStatus,
  handleEmailExistsRedirect,
  isSsoSignInAttempt,
  paths,
  postSignIn,
  showUserNotFoundSnackbar,
} from "./helpers";
import {
  type EmailPasswordSubmit,
  type EmailSubmit,
  type FormType,
  type LoginHookAction,
  LoginHookActionType,
  type LoginState,
  type LoginType,
  type SupportedAuthProviders,
  type UserAuthConfig,
} from "./types";

export const useStoredEmail = (storageKey = emailLocalStorageKey) => {
  const storeEmail = useCallback(
    (e) => {
      window.localStorage.setItem(storageKey, e);
    },
    [storageKey]
  );
  const getEmail = useCallback(() => window.localStorage.getItem(storageKey), [storageKey]);
  const removeEmail = useCallback(() => {
    window.localStorage.removeItem(storageKey);
  }, [storageKey]);

  return { storeEmail, getEmail, removeEmail };
};

export const useGetAuthConfigErrorHandler = ({ dispatch }: { dispatch: Dispatch<LoginHookAction> }) => {
  const showSnackbar = useSnackbar();
  const history = useHistory();

  return useCallback(
    (error: AxiosError) => {
      if (error.response?.status === 432) {
        // if no such user, redirect to sign up
        dispatch({ type: LoginHookActionType.setFormType, formType: "signup" });
        return true;
      }
      if (error.response?.status === 404) {
        // if no such customer - show meaningful error
        showUserNotFoundSnackbar({ showSnackbar, dispatch, history });
        return true;
      }

      return false;
    },
    [dispatch, history, showSnackbar]
  );
};

export const useSendEmailSignInLink = ({
  dispatch,
  state,
  handleLoading,
}: {
  dispatch: Dispatch<LoginHookAction>;
  state: LoginState;
  handleLoading: boolean;
}) => {
  const { storeEmail } = useStoredEmail();
  const showErrorSnackbar = useErrorSnackbar();
  const authConfigErrorHandler = useGetAuthConfigErrorHandler({ dispatch });

  return useCallback(
    async (
      authConfig?: UserAuthConfig,
      formikHelpers?: {
        setSubmitting: (isSubmitting: boolean) => void;
        setFieldError: (field: string, message: string) => void;
      }
    ) => {
      try {
        if (handleLoading) {
          dispatch({ type: LoginHookActionType.initLoading });
        }
        if (!state.email) {
          throw new Error("Email is required");
        }
        const email = state.email.toLowerCase();
        if (!authConfig) {
          authConfig = (await getAuthConfig(email, false)).data;
        }

        if (
          isSsoSignInAttempt(authConfig.providerId) ||
          (!authConfig.isPartner && authConfig.allowedProvider && authConfig.allowedProvider !== "password")
        ) {
          // In case SSO should be enforced or in case the customer has a specific provider configured and it's not password - don't send email
          if (formikHelpers?.setFieldError) {
            formikHelpers.setFieldError("username", loginTexts.NO_MAGICLINK_FOR_ACCOUNT);
          } else {
            showErrorSnackbar(loginTexts.NO_MAGICLINK_FOR_ACCOUNT);
          }
          return;
        }

        await sendSignInLinkToEmail(email, authConfig.tenantId);
        storeEmail(email);
        dispatch({ type: LoginHookActionType.setFormType, formType: "checkEmailSuccess" });
      } catch (error: any) {
        if (!authConfigErrorHandler(error)) {
          consoleErrorWithSentry(error);
          showErrorSnackbar(error.response?.data ?? error.message);
        }
      } finally {
        if (handleLoading) {
          dispatch({ type: LoginHookActionType.stopLoading });
        }
        formikHelpers?.setSubmitting?.(false);
      }
    },
    [handleLoading, state.email, storeEmail, dispatch, showErrorSnackbar, authConfigErrorHandler]
  );
};

export const useHandleSignInWithEmailLink = ({
  dispatch,
  state,
}: {
  dispatch: Dispatch<LoginHookAction>;
  state: LoginState;
}) => {
  const showErrorSnackbar = useErrorSnackbar();
  const { removeEmail, getEmail } = useStoredEmail();
  const history = useHistory();
  const authConfigErrorHandler = useGetAuthConfigErrorHandler({ dispatch });

  return useCallback(async () => {
    const storedEmail = getEmail();

    if (!state.email && storedEmail) {
      dispatch({ type: LoginHookActionType.setEmail, email: storedEmail });
    }
    const linkEmail = state.email || storedEmail;

    if (!linkEmail) {
      dispatch({ type: LoginHookActionType.setFormType, formType: "verifyEmailForEmailLinkSignIn" });
      dispatch({ type: LoginHookActionType.stopLoading });
      return;
    }
    try {
      dispatch({ type: LoginHookActionType.initLoading });
      const authConfig = (await getAuthConfig(linkEmail, false)).data;
      const userCred = await signInWithEmailLink(linkEmail, authConfig.tenantId);
      await postSignIn({ userCred, history });
    } catch (error: any) {
      if (authConfigErrorHandler(error)) {
        return;
      }
      if (error.code === "auth/invalid-action-code") {
        showErrorSnackbar(loginTexts.INVALID_SIGN_IN_MAGIC_LINK);
        return;
      }

      consoleErrorWithSentry(error);
      showErrorSnackbar(error.response?.data ?? error.message);
    } finally {
      removeEmail();
      dispatch({ type: LoginHookActionType.stopLoading });
    }
  }, [authConfigErrorHandler, dispatch, getEmail, history, removeEmail, showErrorSnackbar, state.email]);
};

export const useHandleSignIn = ({ dispatch, state }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const showErrorSnackbar = useErrorSnackbar();
  const showSnackbar = useSnackbar();
  const history = useHistory();

  return useCallback(
    async ({ email, password }, { setSubmitting, setFieldError }) => {
      dispatch({ type: LoginHookActionType.initLoading });
      try {
        const userCred = await signInWithEmailAndPassword(email, password, state.tenantId);

        await postSignIn({ userCred, history });
      } catch (error: any) {
        switch (error.code) {
          case AuthErrors.wrongPassword:
            setFieldError("password", " ");
            showErrorSnackbar(loginTexts.SIGNIN_FAILED_INCORRECT_CREDS);
            break;
          case AuthErrors.tooManyRequests:
            showErrorSnackbar(loginTexts.SIGNIN_FAILED_ACCOUNT_DISABLED);
            break;
          case AuthErrors.userNotFound:
            showUserNotFoundSnackbar({ showSnackbar, dispatch, history });
            break;
          default:
            showErrorSnackbar(error.message);
        }
      } finally {
        dispatch({ type: LoginHookActionType.stopLoading });
        setSubmitting(false);
      }
    },
    [dispatch, history, showErrorSnackbar, showSnackbar, state.tenantId]
  );
};

export const useHandleSignUp = ({ dispatch }: { dispatch: Dispatch<LoginHookAction> }): EmailSubmit => {
  const showErrorSnackbar = useErrorSnackbar();
  const history = useHistory();

  return useCallback(
    async (
      { email, firstName, lastName }: { email: string; firstName?: string; lastName?: string },
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
      dispatch({ type: LoginHookActionType.initLoading });

      try {
        await handleEmailSignup({ email, fssaFirstName: firstName, fssaLastName: lastName });

        dispatch({
          type: LoginHookActionType.setLoginType,
          loginType: "completeSignup",
          formType: "checkEmailSuccess",
        });
        const toUrl = new URL("/complete-signup", window.location.origin);

        history.push(toUrl.pathname + toUrl.search);
      } catch (error: any) {
        consoleErrorWithSentry(error);
        if (typeof error.response?.data === "string") {
          showErrorSnackbar(error.response.data);
        } else {
          showErrorSnackbar(loginTexts.SIGNUP_FAILED_TRY_AGAIN);
        }
        if (error.response?.status === 409) {
          handleEmailExistsRedirect(dispatch, email, history);
        }
      } finally {
        dispatch({ type: LoginHookActionType.stopLoading });
        setSubmitting(false);
      }
    },
    [dispatch, history, showErrorSnackbar]
  );
};

type UseOnCompleteSignupErrorHook = (
  dispatch: Dispatch<LoginHookAction>,
  errSnackbar: (message: string) => void
) => (err: string) => string | undefined;

/** @description showing a meaningful error message and returning the user to where he started from */
export const useOnCompleteSignupError: UseOnCompleteSignupErrorHook = (dispatch, errSnackbar) => {
  const onError = useCallback(
    (err: string) => {
      errSnackbar(getErrorMessageFromCompleteSignupErrorParam(err));

      if (err !== CompleteSignupErrorParam.UseTheSameEmail) {
        dispatch({
          type: LoginHookActionType.setLoginType,
          loginType: "login",
          formType: "checkEmail",
        });

        return paths.login;
      }
    },
    [dispatch, errSnackbar]
  );

  return onError;
};

/** @description complete signup hook for email and password provider */
export const useHandleCompleteSignUp = ({ dispatch }: { dispatch: Dispatch<LoginHookAction> }): EmailPasswordSubmit => {
  const showErrorSnackbar = useErrorSnackbar(15);
  const showSuccessSnackbar = useSuccessSnackbar();
  const history = useHistory();
  const onCompleteSignupError = useOnCompleteSignupError(dispatch, showErrorSnackbar);
  const query = useQuery();

  return useCallback(
    async (
      { email, password }: { email: string; password: string },
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
      dispatch({ type: LoginHookActionType.initLoading });

      try {
        const response = await handleEmailCompleteSignup({ email, password });
        const userCred = await signInWithEmailAndPassword(email, password, response.data?.tenantId);

        // Send verification email
        if (!userCred?.user?.emailVerified) {
          await sendEmailVerification(userCred?.user);
          showSuccessSnackbar(loginTexts.CHECK_INBOX_VERIFICATION);
          history.push("/");
          return;
        }

        const redirectQuery = query.get("redirect");
        const pushToOnSuccess = new URL("/", window.location.origin);
        if (redirectQuery) {
          pushToOnSuccess.searchParams.set("redirect", redirectQuery);
        }
        history.push(pushToOnSuccess.pathname + pushToOnSuccess.search);
      } catch (error: any) {
        const defaultErrMsgHandler = () => {
          if (typeof error.response?.data === "string") {
            showErrorSnackbar(error.response.data);
          } else {
            showErrorSnackbar(loginTexts.SIGNUP_FAILED_TRY_AGAIN);
          }
        };

        consoleErrorWithSentry(error);

        switch (error.response?.status) {
          case 400:
            if (Object.values(CompleteSignupErrorParam).includes(error.response?.data)) {
              const pushTo = onCompleteSignupError(error.response?.data);
              if (pushTo) {
                history.push(routes.signin);
              }
            } else {
              defaultErrMsgHandler();
            }
            break;
          case 404:
            showErrorSnackbar(loginTexts.SIGNUP_FAILED_NO_INVITE(email));
            break;
          case 409:
            handleEmailExistsRedirect(dispatch, email, history);
            defaultErrMsgHandler();
            break;
          default:
            defaultErrMsgHandler();
        }
      } finally {
        dispatch({ type: LoginHookActionType.stopLoading });
        setSubmitting(false);
      }
    },
    [dispatch, query, history, showSuccessSnackbar, showErrorSnackbar, onCompleteSignupError]
  );
};

type Helpers = { setSubmitting: (submitting: boolean) => void };

type Values = { email: string };

export const useCheckEmail = ({ dispatch, state }: { dispatch: Dispatch<LoginHookAction>; state: LoginState }) => {
  const showErrorSnackbar = useErrorSnackbar();
  const history = useHistory();
  const sendEmailSignInLink = useSendEmailSignInLink({ dispatch, state, handleLoading: false });
  const handleSignInWithEmailLink = useHandleSignInWithEmailLink({ dispatch, state });
  const authConfigErrorHandler = useGetAuthConfigErrorHandler({ dispatch });

  return useCallback(
    async ({ email }: Values, { setSubmitting }: Helpers) => {
      dispatch({ type: LoginHookActionType.initLoading });

      try {
        const { data } = await getAuthConfig(email.toLowerCase(), false);
        dispatch({ type: LoginHookActionType.setTenantId, tenantId: data.tenantId });
        dispatch({ type: LoginHookActionType.setEmail, email });

        if (state.formType === "verifyEmailForEmailLinkSignIn") {
          await handleSignInWithEmailLink();
        } else if (isDoitEmployeeEmail(email) && !isTestEmail(email)) {
          history.push(paths.authGoogle);
          setTimeout(() => {
            window.location.reload();
          });
        } else if (data.hasPasswordProvider) {
          dispatch({ type: LoginHookActionType.setFormType, formType: "enterPassword" });
        } else if (isSsoSignInAttempt(data.providerId)) {
          // Redirect to the SSO provider in some cases like when the customer enabled SSO and user is not an admin
          try {
            await signInWithRedirect({
              providerId: data.providerId,
              tenantId: data.tenantId,
              provider: data.provider,
            });
          } catch (error: any) {
            showErrorSnackbar(error.message);
          }
        } else if (data.isPartner) {
          // enforcing sign in with email link for partners
          await sendEmailSignInLink(data);
        } else {
          dispatch({ type: LoginHookActionType.setFormType, formType: "signup" });
        }
      } catch (error: any) {
        if (!authConfigErrorHandler(error)) {
          consoleErrorWithSentry(error);
          showErrorSnackbar(error.response?.data ?? error.message);
        }
      } finally {
        dispatch({ type: LoginHookActionType.stopLoading });
        setSubmitting(false);
      }
    },
    [
      authConfigErrorHandler,
      dispatch,
      handleSignInWithEmailLink,
      history,
      sendEmailSignInLink,
      showErrorSnackbar,
      state.formType,
    ]
  );
};

export const useDynamicSignupTitle = ({ path, defaultTitle }): string => {
  const { data, isError } = useStoryblok(StoryblokContentType.title, path);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (data?.content?.type === StoryblokContentType.title) {
      setTitle(data?.content?.title);
    }

    if (isError) {
      setTitle(defaultTitle);
    }
  }, [data, defaultTitle, isError]);

  return title;
};

export const useDynamicContent = ({ loginType = "login" }: { loginType?: LoginType }): JSX.Element => {
  const { data, isError, isLoading } = useStoryblok(
    StoryblokContentType.content,
    dynamicContentPathByLoginType[loginType]
  );
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    if (data?.content?.type === StoryblokContentType.content) {
      setContent(data?.content?.content ?? null);
    }

    if (isError) {
      setContent(null);
    }
  }, [data, isError]);

  return <Preview content={content} isLoading={isLoading} />;
};

export const initState = {
  isLoading: false,
  formType: "checkEmail" as FormType,
  tenantId: null,
  email: null,
  loginType: "login" as LoginType,
};

export const createLoginReducer =
  (loginType: LoginType, formType: FormType) =>
  (state: LoginState, action: LoginHookAction): LoginState => {
    switch (action.type) {
      case LoginHookActionType.initLoading:
        return { ...state, isLoading: true };
      case LoginHookActionType.stopLoading:
        return { ...state, isLoading: false };
      case LoginHookActionType.setTenantId:
        return { ...state, tenantId: action.tenantId };
      case LoginHookActionType.setFormType:
        return { ...state, formType: action.formType };
      case LoginHookActionType.setEmail:
        return { ...state, email: action.email };
      case LoginHookActionType.setPassword:
        return { ...state, password: action.password };
      case LoginHookActionType.setLoginType:
        return { ...state, loginType: action.loginType, formType: action.formType };
      case LoginHookActionType.back:
        return {
          ...initState,
          loginType,
          formType,
        };
    }
  };

export const useLoginForm = ({
  loginType = "login",
}: {
  loginType?: LoginType;
}): {
  state: LoginState;
  dispatch: Dispatch<LoginHookAction>;
} => {
  const location = useLocation();
  const showErrorSnackbar = useErrorSnackbar();
  const qs = useQueryString();
  const history = useHistory();

  let formType: FormType;
  switch (loginType) {
    case "signup":
      formType = "signup";
      break;
    case "completeSignup":
      formType = "completeSignup";
      break;
    default:
      formType = "checkEmail";
  }

  const [state, dispatch]: [LoginState, Dispatch<LoginHookAction>] = useReducer(
    createLoginReducer(loginType, formType),
    {
      ...initState,
      formType,
      loginType,
    }
  );
  const handleSignInWithEmailLink = useHandleSignInWithEmailLink({ state, dispatch });

  const handleIdpInitiatedFlow = async () => {
    try {
      const res = await getAuthConfig("", true, qs?.idp_tenant_id as string);
      const { providerId } = res.data;

      if (!providerId) {
        consoleErrorWithSentry(new Error("providerId not found"));
        return;
      }

      await signInWithRedirect(res.data);
    } catch (e: any) {
      consoleErrorWithSentry(e);
      showErrorSnackbar(e.response?.data as string);
    }
  };

  useMountEffect(() => {
    if ([paths.loginError, paths.signupError].includes(location?.pathname)) {
      if (qs?.error) {
        showErrorSnackbar(qs.error as string);
      } else {
        history.push(paths.login);
      }
    }

    if (isSignInWithEmailLink()) {
      handleSignInWithEmailLink().catch(consoleErrorWithSentry);
    }

    if (location?.pathname === paths.loginSuccess) {
      dispatch({ type: LoginHookActionType.initLoading });
    }

    if (qs?.idp_tenant_id) {
      handleIdpInitiatedFlow().catch(consoleErrorWithSentry);
    }

    history.listen((location, action) => {
      if (action === "POP") {
        dispatch({ type: LoginHookActionType.back });
      }
    });
  });

  return { state, dispatch };
};

export const useSignUpForm = (): {
  policyAgreementActionRef: RefObject<ButtonBaseActions> | null;
  policyAgreementRef: RefObject<HTMLInputElement> | null;
  policyAgreementCallback: (isDisabled: boolean) => (e: MouseEvent) => void;
  isAuthProvidersPolicyDialogOpen: boolean;
  setIsAuthProvidersPolicyDialogOpen: (value: boolean) => void;
  clickedAuthProvider: SupportedAuthProviders | undefined;
  setClickedAuthProvider: Dispatch<SetStateAction<SupportedAuthProviders | undefined>>;
} => {
  const policyAgreementActionRef = useRef<ButtonBaseActions>(null);
  const policyAgreementRef = useRef<HTMLInputElement>(null);
  const [isAuthPolicyDialogOpen, setIsAuthPolicyDialogOpen] = useState(false);
  const [clickedAuthProvider, setClickedAuthProvider] = useState<SupportedAuthProviders | undefined>(undefined);

  const setIsAuthProvidersPolicyDialogOpen = useCallback((value: boolean) => {
    setIsAuthPolicyDialogOpen(value);
    if (!value) {
      setClickedAuthProvider(undefined);
    }
  }, []);

  const policyAgreementCallback = useCallback(
    (isDisabled) => (e) => {
      if (isDisabled) {
        e.preventDefault();
        setIsAuthProvidersPolicyDialogOpen(true);
      }
    },
    [setIsAuthProvidersPolicyDialogOpen]
  );

  return {
    policyAgreementActionRef,
    policyAgreementRef,
    policyAgreementCallback,
    isAuthProvidersPolicyDialogOpen: isAuthPolicyDialogOpen,
    setIsAuthProvidersPolicyDialogOpen,
    clickedAuthProvider,
    setClickedAuthProvider,
  };
};

export const useResetEmailForm = ({ dispatch, state }: { dispatch: Dispatch<LoginHookAction>; state: LoginState }) => {
  const showErrorSnackbar = useErrorSnackbar();
  const showInfoSnackbar = useInfoSnackbar();

  return useCallback(
    async ({ email }, { setSubmitting }) => {
      try {
        if (email) {
          await sendPasswordResetEmail(email, state.tenantId);
          showInfoSnackbar(loginTexts.EMAIL_RESET_PASSWORD(email));
          dispatch({ type: LoginHookActionType.setFormType, formType: "enterPassword" });
        }
      } catch (error: any) {
        if (error.code === AuthErrors.invalidEmail || error.code === AuthErrors.userNotFound) {
          showErrorSnackbar(loginTexts.INVALID_EMAIL);
        } else {
          showErrorSnackbar(error.message);
        }
      } finally {
        setSubmitting(false);
      }
    },
    [dispatch, showErrorSnackbar, showInfoSnackbar, state.tenantId]
  );
};

export const useSsoSignIn = ({ dispatch }: { dispatch: Dispatch<LoginHookAction> }) => {
  const showErrorSnackbar = useErrorSnackbar();

  return useCallback(
    async ({ email }, { setSubmitting, setFieldError }) => {
      dispatch({ type: LoginHookActionType.initLoading });
      try {
        const res = await getAuthConfig(email, true);
        const { providerId } = res.data;

        if (!providerId) {
          setFieldError("username", loginTexts.NO_SSO_FOR_ACCOUNT);
          return;
        }

        await signInWithRedirect(res.data);
      } catch (error: any) {
        if (error.response?.data) {
          showErrorSnackbar(error.response.data);
        }
        consoleErrorWithSentry(error);
      } finally {
        dispatch({ type: LoginHookActionType.stopLoading });
        setSubmitting(false);
      }
    },
    [dispatch, showErrorSnackbar]
  );
};

export const useFormSwitch = ({ state, dispatch }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const history = useHistory();

  const { t } = useTranslation("login");

  let formSwitchConfig: {
    formType: FormType;
    buttonText: string;
    caption: string;
  };

  if (state.loginType === "completeSignup") {
    formSwitchConfig = {
      formType: "checkEmail",
      buttonText: t("signin"),
      caption: t("signup.alreadyHaveAccount"),
    };
  } else {
    formSwitchConfig =
      state.formType === "signup"
        ? {
            formType: "checkEmail",
            buttonText: t("signin"),
            caption: t("signup.alreadyHaveAccount"),
          }
        : {
            formType: "signup",
            buttonText: t("create.account"),
            caption: t("dont.have.account"),
          };
  }

  const handleClick = useCallback(() => {
    if (state.loginType === "completeSignup") {
      dispatch({ type: LoginHookActionType.setLoginType, loginType: "login", formType: "checkEmail" });
      history.push(paths.login);
      return;
    }

    if (window.location.pathname === "/signup") {
      history.push(paths.login);
    }
    dispatch({ type: LoginHookActionType.setFormType, formType: formSwitchConfig.formType });
  }, [dispatch, formSwitchConfig.formType, state.loginType, history]);

  return { formSwitchConfig, handleClick };
};

export const useAgreementDialog = (): [boolean, (e: MouseEvent) => void, () => ReactElement] => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const preventFetching = !isOpen || !!content;
  const { data, isError, isLoading } = useStoryblok(
    StoryblokContentType.agreement,
    "login/policy-agreement",
    preventFetching
  );

  useEffect(() => {
    if (data?.content?.type === StoryblokContentType.agreement) {
      setContent(data?.content?.content ?? null);
      setTitle(data?.content?.title ?? null);
    }

    if (isError) {
      setContent(null);
      setTitle(null);
    }
  }, [data, isError]);

  return [
    isLoading,
    (e) => {
      e.preventDefault();
      setIsOpen(true);
    },
    () => (
      <AgreementDialog
        isLoading={isLoading}
        isOpen={isOpen}
        content={content}
        title={title}
        onCancel={() => {
          setIsOpen(false);
        }}
        onConfirm={() => {
          setIsOpen(false);
        }}
      />
    ),
  ];
};

export function useHandleEmailVerificationStatusFromQuery() {
  const successSnackbar = useSuccessSnackbar();
  const errSnackbar = useErrorSnackbar(10);
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const emailVerificationStatus = query.get(emailVerificationStatusParam);
    if (!emailVerificationStatus) {
      return;
    }

    if (emailVerificationStatus === EmailVerificationParamStatus.Success) {
      successSnackbar(emailVerificationSuuccessMessage);
    } else if (emailVerificationStatus) {
      errSnackbar(getErrorMessageFromEmailVerificationStatus(emailVerificationStatus));
    }

    // removing the emailVerificationStatusParam so it doesn't show up in the url and keep showing the snackbar
    const newLocationUrl = new URL(history.location.pathname, window.location.origin);
    newLocationUrl.search = history.location.search;
    newLocationUrl.searchParams.delete(emailVerificationStatusParam);
    history.replace(newLocationUrl.pathname + newLocationUrl.search);
  }, [errSnackbar, successSnackbar, history, query]);
}

export function useCompleteSignupQueryParams({
  onEmail,
  onProvider,
  onError,
  onCheckInbox,
}: {
  onEmail: (email: string) => void;
  onProvider: (provider: string) => string | undefined;
  onError: (err: string) => string | undefined;
  onCheckInbox: () => void;
}) {
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const err = query.get(completeSignupErrorQueryParam);
    const email = query.get(completeSignupEmailQueryParam);
    const provider = query.get(completeSignupProviderQueryParam);
    const isCheckInbox = query.get(completeSignupCheckInboxQueryParam);

    if (!err && !email && !provider && !isCheckInbox) {
      return;
    }

    const newLocationUrl = new URL(history.location.pathname, window.location.origin);
    newLocationUrl.search = history.location.search;

    let providerAuthUrl: string | undefined = undefined;
    let errHistoryToPush: string | undefined = undefined;
    if (provider) {
      providerAuthUrl = onProvider(provider);
      newLocationUrl.searchParams.delete(completeSignupProviderQueryParam);
    }
    if (email) {
      onEmail(email);
      newLocationUrl.searchParams.delete(completeSignupEmailQueryParam);
    }
    if (err) {
      errHistoryToPush = onError(err);
      newLocationUrl.searchParams.delete(completeSignupErrorQueryParam);
    }
    if (isCheckInbox === "true") {
      onCheckInbox();
      newLocationUrl.searchParams.delete(completeSignupCheckInboxQueryParam);
    }
    if (errHistoryToPush) {
      history.push(errHistoryToPush);
    } else if (providerAuthUrl) {
      window.open(providerAuthUrl.toString(), "_self");
    } else {
      history.replace(newLocationUrl.pathname + newLocationUrl.search);
    }
  }, [history, query, onEmail, onProvider, onError, onCheckInbox]);
}

export function useGetSigninIconColors() {
  const isDarkMode = useDarkThemeCheck();
  return isDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.6)";
}
