import { useMemo } from "react";

import { useAnalyticsReportsContext } from "../Pages/CloudAnalytics/Context/useAnalyticsReportsContext";
import { useCustomerContext } from "./CustomerContext";
import type { WidgetDescription } from "./useDashboardsContext";

export const useCloudReportsWidgets = (): [WidgetDescription[], boolean] => {
  const { customer } = useCustomerContext();

  const {
    reports: [_reportsList, filteredReports, reportsLoading],
  } = useAnalyticsReportsContext();

  return useMemo(() => {
    if (reportsLoading) {
      return [[], true];
    }

    const reportsWidgets = filteredReports.map((snap) => {
      const docData = snap.data;

      const report = {
        ...docData,
        reportID: snap.snapshot.id,
        customerID: customer.ref.id,
      };

      const owner = (report.collaborators ?? []).find((c) => c.role === "owner");

      return {
        title: report.name,
        description: report.description ? report.description : "cloud cost and usage analytics",
        name: `cloudReports::${customer.id}_${report.reportID}`,
        type: report.type,
        reportType: report.config?.renderer,
        owner: owner?.email,
        timeUpdated: report.timeModified?.toDate(),
      };
    });

    return [reportsWidgets, false];
  }, [customer.id, customer.ref.id, filteredReports, reportsLoading]);
};
