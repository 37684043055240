export type OnIdeal = (
  name: string,
  { interval, triggers, startTime }: { interval: number; triggers: number; startTime: Date }
) => void;

class IdealHooks {
  private timer: NodeJS.Timeout | null = null;

  private triggers: number = 0;

  private startTime = new Date();

  constructor(
    private readonly name: string,
    private readonly interval: number,
    private readonly onIdeal: OnIdeal,
    private readonly options: { once: boolean; onClear?: () => void } = { once: false }
  ) {
    this.startTimer();
  }

  private startTimer() {
    this.timer = setInterval(() => {
      this.onIdeal(this.name, { interval: this.interval, triggers: this.triggers, startTime: this.startTime });
      this.triggers = 0;
      if (this.options.once && this.timer) {
        clearInterval(this.timer);
        this.timer = null;
        this.options.onClear?.();
      }
    }, this.interval);
  }

  public resetTimer() {
    if (this.triggers === 0) {
      this.startTime = new Date();
    }

    this.triggers += 1;
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.startTimer();
  }
}

const idealOperations: Record<string, IdealHooks | null> = {};

export const addIdealFirestoreHook = (name: string, interval: number, onIdeal: OnIdeal) => {
  idealOperations[name] = new IdealHooks(name, interval, onIdeal);

  return () => {
    idealOperations[name] = null;
  };
};

export const addIdealFirestoreHookOnce = (name: string, interval: number, onIdeal: OnIdeal) => {
  idealOperations[name] = new IdealHooks(name, interval, onIdeal, {
    once: true,
    onClear: () => {
      idealOperations[name] = null;
    },
  });
};

export const triggerOperations = () => {
  for (const name in idealOperations) {
    idealOperations[name]?.resetTimer();
  }
};
