import { type Widget } from "../Context/useCurrentDashboardContext";

export const getWidgetDocId = (customerId: string, reportId: string, orgId: string | null): string => {
  if (orgId && orgId !== "root") {
    return `${customerId}_${orgId}_${reportId}`;
  }
  return `${customerId}_${reportId}`;
};

export const filterExcludeWidgetsIfStandaloneCustomer = (
  widgetName: string,
  isProductOnlyCustomer: boolean
): boolean => {
  if (!isProductOnlyCustomer) {
    return true;
  }

  const standaloneCustomersCantUseTheseWidgetsByName = ["entities"];
  return !standaloneCustomersCantUseTheseWidgetsByName.includes(widgetName);
};

export const isTemplateWidget = (widget: Widget) => widget.name.includes("::");

export const getWidgetBaseName = (widget: Widget) =>
  isTemplateWidget(widget) ? widget.name.substring(0, widget.name.indexOf("::")) : widget.name;

export const getWidgetId = (widget: Widget) =>
  isTemplateWidget(widget) ? widget.name.substring(widget.name.lastIndexOf(":") + 1) : widget.name;
