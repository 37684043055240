import { type Dispatch, memo, type SetStateAction, useCallback } from "react";

import { Aggregator, type ComparativeFeature, Metric, Renderer } from "@doitintl/cmp-models";

import { useFullScreen } from "../../../../utils/dialog";
import { useReportConfig } from "../../Context";
import { type ColKeySort } from "../../ReportData";
import { isTable, updateColKeySort } from "../../utilities";
import ChartsRenderer from "../ChartsRenderer";
import TableRenderer from "../TableRenderer/TableRenderer";
import type ReportData from "../../../../Pages/CloudAnalytics/ReportData";

type Props = {
  colKeySort?: ColKeySort | null;
  dataProp: ReportData;
  forceRender: boolean;
  forecastStart: number;
  formatter: (value: number | string | undefined, short?: boolean, comparative?: ComparativeFeature) => string;
  isForecast: boolean;
  renderKey: number;
  setColKeySort: Dispatch<SetStateAction<ColKeySort | undefined>>;
};

const AnalyticsRendererContent = ({
  colKeySort,
  dataProp,
  forecastStart,
  formatter,
  isForecast,
  renderKey,
  setColKeySort,
}: Props) => {
  const { isMobile: smDown } = useFullScreen();
  const {
    reportConfig: { includeSubtotals, comparative, logScale, renderer, metric, aggregator },
  } = useReportConfig();

  const isTableRenderer = isTable(renderer);
  const showSubtotals = includeSubtotals && isTableRenderer;
  const heatmapReverseColor = metric === Metric.SAVINGS || metric === Metric.MARGIN;
  const totalsColHidden = aggregator === Aggregator.COUNT;

  const sortByCol = useCallback(
    (colKey) => {
      updateColKeySort(setColKeySort, colKey);
    },
    [setColKeySort]
  );

  if (isTableRenderer) {
    const heatmap = () => {
      switch (renderer) {
        case Renderer.TABLE:
          return "";
        case Renderer.HEATMAP:
          return "full";
        case Renderer.ROW_HEATMAP:
          return "row";
        case Renderer.COL_HEATMAP:
          return "col";
      }
    };
    return (
      <TableRenderer
        data={dataProp}
        isForecast={isForecast}
        forecastStart={forecastStart}
        formatter={formatter}
        heatmap={heatmap()}
        height="100%"
        reverseColors={heatmapReverseColor}
        smDown={smDown}
        sortByCol={sortByCol}
        colKeySort={colKeySort}
        totalsColHidden={totalsColHidden}
        showSubtotals={showSubtotals}
      />
    );
  }

  return (
    <ChartsRenderer
      key={`charts-renderer-key${renderKey}`}
      comparative={comparative ?? undefined}
      data={dataProp}
      formatter={formatter}
      logScale={logScale}
      type={renderer}
    />
  );
};

export default memo(AnalyticsRendererContent);
