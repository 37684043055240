import { useMemo, useState } from "react";

import { CloudAnalyticsModel, type ReportConfig } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";

import { useAuthContext } from "../../../Context/AuthContext";

export const useDefaultReportsConfig = () => {
  const { userId } = useAuthContext({ mustHaveUser: true });
  const [newConfig, setNewConfig] = useState<ReportConfig | null>(null);

  const defaultConfigRef = useMemo(
    () =>
      getCollection(CloudAnalyticsModel).doc("reports").collection("cloudAnalyticsReportsDefaultConfigs").doc(userId),
    [userId]
  );

  const [defaultConfigDoc, loading] = useDocumentDataOnce(defaultConfigRef);

  const handleSaveDefaultReportConfig = async (config: ReportConfig) => {
    await defaultConfigRef.set({ config });
    setNewConfig(config);
  };

  return {
    handleSaveDefaultReportConfig,
    defaultConfig: newConfig ?? defaultConfigDoc?.config ?? null,
    loading,
  };
};
