import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  type Collaborators,
  type CustomerModelOrganizationModel,
  type PublicAccess,
  Roles,
} from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import cloneDeep from "lodash/cloneDeep";
import concat from "lodash/concat";
import pullAll from "lodash/pullAll";
import uniq from "lodash/uniq";
import psl from "psl";
import { string as YupString } from "yup";

import { globalText, reportText } from "../../../../assets/texts";
import useAnalyticsUsers from "../../../../Components/hooks/cloudAnalytics/useAnalyticsUsers";
import { useCAOwnerAssignerCheck } from "../../../../Components/hooks/useCAOwnerAssignerCheck";
import LoadingButton from "../../../../Components/LoadingButton";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../utils";
import { preventOnCloseOnBackdropClick } from "../../../../utils/dialog";
import {
  checkAllowedSubdomains,
  CloudAnalyticsEntities,
  isUserEditor,
  isUserOwner,
  mergeCollaboratorsLists,
  mergePublicAccessList,
  validateUsersFromOrg,
} from "../../utilities";
import AccessDropdown from "./AccessDropdown";

const useStyles = makeStyles((theme) => ({
  collaboratorsList: {
    paddingTop: 0,
    height: 270,
  },
  roleSelect: {
    width: 104,
    marginRight: theme.spacing(1),
  },
  roleSecondaryAction: {
    right: 0,
  },
  selectOutlined: {
    "&$outlined": {
      ...theme.typography.body2,
      padding: theme.spacing(0.75, 1.5),
    },
  },
  outlined: {},
}));

export type ShareableEntity = {
  name: string;
  collaborators: Collaborators;
  public: PublicAccess;
};

export type ShareDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  entity: CloudAnalyticsEntities;
  handleChangeSharing: (collaborators: Collaborators, role: PublicAccess) => void;
  loading: boolean;
  predefinedCollaborators?: string[];
  organization?: ModelReference<CustomerModelOrganizationModel> | null;
  availableRoles?: Array<Roles | undefined>;
  defaultRole?: Roles;
  alwaysPublic?: boolean;
  shareEntities: ShareableEntity[];
  showPresetExcludedAlert?: boolean;
};

const ShareDialog = ({
  open,
  onClose,
  title,
  entity,
  handleChangeSharing,
  predefinedCollaborators,
  loading,
  organization,
  availableRoles = [Roles.VIEWER, Roles.EDITOR, Roles.OWNER, undefined],
  defaultRole = Roles.VIEWER,
  alwaysPublic,
  shareEntities,
  showPresetExcludedAlert = false,
}: ShareDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery<boolean>(theme.breakpoints.down("md"));
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
  const [values, setValues] = useState<string[]>(predefinedCollaborators || []);
  const [role, setRole] = useState<Roles>(defaultRole);
  const [inputValue, setInputValue] = useState<string>("");
  const { isDoitPartner, currentUser } = useAuthContext({ mustHaveUser: true });
  const [collaborators, setCollaborators] = useState(cloneDeep(mergeCollaboratorsLists(shareEntities)));
  const [publicAccessRole, setPublicAccessRole] = useState<PublicAccess>(
    mergePublicAccessList(shareEntities.map((x) => x.public))
  );
  const [invalidEmailErrorMsg, setInvalidEmailErrorMsg] = useState<string | undefined>();
  const autoCompleteRef = useRef<HTMLElement>();
  const addCollaboratorsMode = values.length !== 0;
  const { userOrganization, customer } = useCustomerContext();
  const isCAOwnershipAssigner = useCAOwnerAssignerCheck();
  const isCurrentUserOwner = shareEntities.every(
    (entity) => isUserOwner(currentUser.email, { collaborators: entity.collaborators }) || isCAOwnershipAssigner
  );
  const isCurrentUserEditor = shareEntities.every((entity) =>
    isUserEditor(currentUser.email, { collaborators: entity.collaborators, publicAccess: entity.public })
  );

  const showMultipleOwnersWarning = useMemo(
    () => !collaborators.some((collaborator) => collaborator.role === Roles.OWNER) && !isCurrentUserOwner,
    [isCurrentUserOwner, collaborators]
  );

  const { invites, userEmails } = useAnalyticsUsers();
  const options = useMemo(() => {
    const shareEntities = concat(userEmails, invites);

    return uniq(
      pullAll(
        shareEntities,
        collaborators?.map((collaborator) => collaborator.email)
      )
    );
  }, [collaborators, userEmails, invites]);

  useEffect(() => {
    if (!open) {
      setCollaborators(cloneDeep(mergeCollaboratorsLists(shareEntities)));
      setInvalidEmailErrorMsg(undefined);
      setInputValue("");
      setDisableSaveButton(true);
      setValues([]);
      setRole(defaultRole);
      setPublicAccessRole(mergePublicAccessList(shareEntities.map((x) => x.public)));
    }
    if (open && predefinedCollaborators) {
      setValues(predefinedCollaborators);
    }
  }, [currentUser.email, defaultRole, open, predefinedCollaborators, shareEntities]);

  const handleChangeRole = useCallback(
    (email, role) => {
      const newCollaborators = collaborators.slice();
      if (role === reportText.MAKE_OWNER) {
        role = Roles.OWNER;
      }

      if (role === Roles.OWNER) {
        const ownerIndex = newCollaborators.findIndex((collaborator) => collaborator.role === Roles.OWNER);
        if (ownerIndex > -1) {
          newCollaborators[ownerIndex].role = Roles.EDITOR;
        }
      }

      const index = newCollaborators.findIndex((collaborator) => collaborator.email === email);
      if (role === "remove") {
        newCollaborators.splice(index, 1);
      } else {
        newCollaborators[index].role = role;
      }
      setDisableSaveButton(!newCollaborators.length);
      setCollaborators(newCollaborators);
    },
    [collaborators, setCollaborators]
  );

  const areUsersFromOrg = useMemo(() => {
    if (entity === CloudAnalyticsEntities.REPORT) {
      return validateUsersFromOrg(options, values, organization, !!userOrganization);
    }
    return [];
  }, [entity, options, values, organization, userOrganization]);

  const validateInput = useCallback(
    (newInput: string) => {
      const domain = newInput.split("@")[1];
      /* Allow creating option:
        1. The customer's domains/subdomains, and the user has Users manager permission
        2. A slack or teams subdomain (allowed subdomains)
        3. doit email
      */
      if (customer?.domains.some((d) => d === domain || psl.get(d) === domain) || checkAllowedSubdomains(newInput)) {
        if (
          collaborators.findIndex((c) => c.email === newInput) === -1 &&
          values.findIndex((email) => email === newInput) === -1
        ) {
          return true;
        } else {
          setInvalidEmailErrorMsg(`"${newInput}" is already included`);
        }
      } else {
        setInvalidEmailErrorMsg(`"${domain}" is not a valid domain`);
      }
      return false;
    },
    [collaborators, customer?.domains, values]
  );
  const dropDownRoles = [
    { role: Roles.VIEWER, text: Roles.VIEWER },
    { role: Roles.EDITOR, text: Roles.EDITOR },
    { role: Roles.OWNER, text: Roles.OWNER },
  ];

  const validateEmail = useCallback(
    async (value: string) => {
      if (areUsersFromOrg.length > 0) {
        setInvalidEmailErrorMsg(reportText.USER_NOT_IN_ORG(userOrganization?.data.name));
        return;
      }
      try {
        await YupString().email().validate(value);
      } catch (error) {
        // the added value is not a valid email address
        setInvalidEmailErrorMsg(`"${value}" is not a valid email address`);
        return;
      }

      // Check if the user is a registered user of the current domain
      const isExistingUser = userEmails.includes(value) || invites.includes(value);
      if (!isExistingUser && !validateInput(value)) {
        return;
      }

      setInvalidEmailErrorMsg(undefined);
      setInputValue("");
      return value;
    },
    [areUsersFromOrg.length, userOrganization?.data.name, validateInput, userEmails, invites]
  );

  return (
    <Dialog
      open={open}
      onClose={preventOnCloseOnBackdropClick(onClose)}
      aria-labelledby="dialog-title"
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            mb: 1.5,
          }}
        >
          <AccessDropdown
            roles={dropDownRoles}
            value={role}
            onChange={(e) => {
              setRole(e.target.value as Roles);
            }}
            condition={isCurrentUserEditor || isCurrentUserOwner}
            isCurrentUserOwner={isCurrentUserOwner}
            isCurrentUserEditor={isCurrentUserEditor}
            availableRoles={availableRoles}
          >
            <Autocomplete
              multiple
              freeSolo
              openOnFocus
              filterSelectedOptions
              fullWidth
              options={options}
              value={values}
              ref={autoCompleteRef}
              onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
              }}
              inputValue={inputValue.trim()}
              onKeyDown={() => {
                setInvalidEmailErrorMsg(undefined);
              }}
              onChange={async (_, newValues, reason) => {
                switch (reason) {
                  // there are cases where we need to validate selectOption
                  case "selectOption":
                  case "createOption": {
                    const addedValue = await validateEmail(newValues[newValues.length - 1]);
                    if (!addedValue) {
                      return;
                    }
                  }
                }
                setValues(newValues);
              }}
              onBlur={async (event: any) => {
                if (event.target?.value) {
                  const addedValue = await validateEmail(event.target.value);
                  if (addedValue) {
                    setValues((vals) => vals.concat(addedValue));
                  }
                }
              }}
              size="small"
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    color={areUsersFromOrg.includes(option) ? "secondary" : "primary"}
                    variant="outlined"
                    size="small"
                    label={option}
                    {...getTagProps({ index })}
                    key={index}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!invalidEmailErrorMsg}
                  helperText={invalidEmailErrorMsg}
                  margin="dense"
                  variant="outlined"
                  placeholder="Add people"
                  slotProps={{
                    htmlInput: {
                      ...params.inputProps,
                      onKeyDown: async (e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter" || e.key === " " || e.key === ",") {
                          e.stopPropagation();
                          e.preventDefault();
                          const target = e.target as HTMLInputElement;
                          if (target.value) {
                            const addedValue = await validateEmail(target.value);
                            if (addedValue) {
                              setValues((vals) => vals.concat(addedValue));
                              setInputValue("");
                            }
                          }
                        }
                      },
                    },
                  }}
                />
              )}
            />
          </AccessDropdown>
        </Box>
      </DialogContent>
      <DialogContent dividers className={classes.collaboratorsList}>
        {showPresetExcludedAlert && (
          <Alert severity="info" sx={{ marginTop: 3 }}>
            Preset reports were excluded from the selection as you can’t share them.
          </Alert>
        )}
        {!isDoitPartner && !alwaysPublic && (
          <Box
            sx={{
              mt: 1.5,
            }}
          >
            <AccessDropdown
              isCurrentUserOwner={isCurrentUserOwner}
              isCurrentUserEditor={isCurrentUserEditor}
              availableRoles={availableRoles}
              condition={isCurrentUserOwner}
              value={publicAccessRole || undefined}
              roles={[
                { role: Roles.EDITOR, text: Roles.EDITOR },
                { role: Roles.VIEWER, text: Roles.VIEWER },
                { role: Roles.MIXED, text: Roles.MIXED },
                { role: undefined, text: globalText.NONE },
              ]}
              onChange={(e) => {
                const r = e.target.value === globalText.NONE ? undefined : e.target.value;
                if (publicAccessRole !== (r as PublicAccess)) {
                  setDisableSaveButton(false);
                }
                setPublicAccessRole(r as PublicAccess);
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  display: "inline",
                }}
              >
                Anyone at <strong>{customer?.primaryDomain}</strong>
              </Typography>
            </AccessDropdown>
          </Box>
        )}
        <List disablePadding>
          {collaborators?.map((collaborator, index) => (
            <ListItem key={`collaborator-${index}`} disableGutters sx={{ flexDirection: "column" }}>
              <AccessDropdown
                {...{
                  isCurrentUserOwner,
                  isCurrentUserEditor,
                  availableRoles,
                  value: collaborator.role,
                  onChange: (e) => {
                    handleChangeRole(collaborator.email, e.target.value);
                  },
                  owner: collaborator.role === Roles.OWNER,
                  remove: true,
                  roles: [
                    { role: Roles.VIEWER, text: Roles.VIEWER },
                    { role: Roles.EDITOR, text: Roles.EDITOR },
                    { role: Roles.MIXED, text: Roles.MIXED },
                    {
                      role: Roles.OWNER,
                      text:
                        collaborator.role === Roles.OWNER && isCurrentUserOwner ? Roles.OWNER : reportText.MAKE_OWNER,
                    },
                  ],
                }}
              >
                <Typography variant="subtitle1">{collaborator.email}</Typography>
              </AccessDropdown>
            </ListItem>
          ))}
        </List>
        {showMultipleOwnersWarning && (
          <Alert variant="standard" severity="warning" sx={{ width: "100%", marginTop: 2, alignItems: "center" }}>
            The selected {entity}s belong to different owners. You can only change ownership for your {entity}s. To
            perform this action please update your selection to only contain {entity}s you own.
          </Alert>
        )}
      </DialogContent>
      {isCurrentUserEditor || isCurrentUserOwner ? (
        <DialogActions>
          <Button variant="text" onClick={onClose} data-testid="cancelButton" disabled={loading}>
            {globalText.CANCEL}
          </Button>

          {addCollaboratorsMode ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                let newCollaborators = values.map((v) => ({
                  email: v,
                  role,
                }));

                if (role === Roles.OWNER) {
                  newCollaborators = newCollaborators.map((c, i) => {
                    if (i) {
                      return {
                        ...c,
                        role: Roles.EDITOR,
                      };
                    }

                    return c;
                  });

                  const ownerIndex = collaborators.findIndex((collaborator) => collaborator.role === Roles.OWNER);
                  if (ownerIndex > -1) {
                    collaborators[ownerIndex].role = Roles.EDITOR;
                  }
                }
                setCollaborators([...collaborators, ...newCollaborators]);
                setRole(defaultRole);
                setValues([]);
                setDisableSaveButton(false);
              }}
              data-testid="addButton"
              disabled={areUsersFromOrg.length > 0}
            >
              {globalText.ADD}
            </Button>
          ) : (
            <LoadingButton
              onClick={() => {
                try {
                  handleChangeSharing(collaborators, publicAccessRole || null);
                } catch (e) {
                  consoleErrorWithSentry(e);
                }
              }}
              color="primary"
              variant="contained"
              loading={loading}
              disabled={loading || disableSaveButton}
              data-testid="saveButton"
              mixpanelEventId="analytics.share-dialog.save"
            >
              {globalText.SAVE}
            </LoadingButton>
          )}
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={onClose} variant="text">
            {globalText.CANCEL}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ShareDialog;
