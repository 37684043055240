import { type Cluster } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { OpenAWSConsole } from "../../../EKS/Components/OpenAWSConsole";
import { DOIT_EKS_GROUP } from "../rbac";
import SelectedAccountAndCluster from "./components/SelectedAccountAndCluster";

interface AccessEntryStepProps {
  accountId: string | null;
  cluster: Cluster | null;
}

const baseCommand = `aws eks create-access-entry \\
  --cluster-name $CLUSTER_NAME \\
  --principal arn:aws:iam::$ACCOUNT_ID:role/doitintl-cmp \\
  --kubernetes-group ${DOIT_EKS_GROUP}
`;

const AccessEntryStep = ({ accountId, cluster }: AccessEntryStepProps) => (
  <Stack spacing={4}>
    <Stack spacing={2}>
      <Typography variant="h1">Create an access entry</Typography>
      <SelectedAccountAndCluster accountId={accountId} cluster={cluster} />
    </Stack>

    <Stack spacing={2}>
      <Typography variant="body2">
        1. Run the following commands on the AWS Console or a method of your choice
      </Typography>
      <OpenAWSConsole region={cluster?.region ?? ""} />
    </Stack>

    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary">
        Create a new access entry to associate the specified IAM principal with the EKS cluster
      </Typography>
      <CopyCodeBlock
        base={baseCommand}
        variables={{
          $CLUSTER_NAME: { value: cluster?.name ?? "", name: "Cluster Name" },
          $ACCOUNT_ID: { value: accountId ?? "", name: "Account ID" },
        }}
      />
    </Stack>
  </Stack>
);

export default AccessEntryStep;
