import { type ConditionExpression } from "@doitintl/cmp-models";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { Form, Formik } from "formik";
import omit from "lodash/omit";

import { cloudflowTexts } from "../../../../../assets/texts";
import { type NodeWitOutputModel } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { FilterDialogForm } from "./FilterDialogForm";
import { type FilterDialogFormValues, useFilterFormSchema } from "./useFilterFormSchema";

type FilterDialogBaseProps = {
  mode: "add" | "edit";
  open: boolean;
  selectedNode: NodeWitOutputModel;
  referenceableNodes: NodeWitOutputModel[];
  handleClose: () => void;
  condition?: ConditionExpression;
  handleAction: (condition: ConditionExpression) => void;
};

export const FilterDialog: React.FC<FilterDialogBaseProps> = ({
  open,
  handleClose,
  handleAction,
  selectedNode,
  referenceableNodes,
  condition,
  mode,
}) => {
  const [schema, initialValues] = useFilterFormSchema(selectedNode, referenceableNodes, condition);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{mode === "add" ? cloudflowTexts.ADD_NEW_FILTER : cloudflowTexts.EDIT}</DialogTitle>
      <Formik<FilterDialogFormValues>
        initialValues={initialValues}
        validationSchema={schema}
        validateOnBlur
        validateOnChange
        onSubmit={(values) => {
          const condition = omit(schema.cast(values), "fieldReference");
          handleAction(condition);
          handleClose();
        }}
      >
        {({ isValid }) => (
          <Form>
            <DialogContent>
              <FilterDialogForm selectedNode={selectedNode} referenceableNodes={referenceableNodes} schema={schema} />
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {cloudflowTexts.CANCEL}
              </Button>
              <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
                {mode === "add" ? cloudflowTexts.ADD_FILTER : cloudflowTexts.EDIT_FILTER}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
