import { useCallback } from "react";

import { type MasterPayerAccountsModel } from "@doitintl/cmp-models";

import { useUpdateLogin } from "../../../Components/hooks/useOnePassword";
import { consoleErrorWithSentry } from "../../../utils";
import { serverTimestamp } from "../../../utils/firebase";
import { mpaVaultId } from "../consts";
import useGoogleGroup from "../hooks/useGoogleGroup";
import usePortfolioShare from "../hooks/usePortfolioShare";
import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";
import NewMpaDialog, { type MpaSubmitObject } from "./NewMpaDialog";

export class UpdatePendingMasterPayerAccountError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UpdatePendingMasterPayerAccountError";
  }
}

export function useUpdatePendingMasterPayerAccount() {
  const updateLogin = useUpdateLogin();
  const { updateGoogleGroup } = useGoogleGroup();
  const { shareAWSPortfolio } = usePortfolioShare();

  return useCallback(
    async (
      masterPayerAccount: MasterPayerAccountsModelSnapshot,
      mpaActivationData: Pick<
        MasterPayerAccountsModel,
        "customerId" | "support" | "features" | "expectedOnboardingDate" | "domain" | "rootEmail" | "accountNumber"
      >
    ) => {
      if (masterPayerAccount.status === "pending") {
        await updatePendingMasterPayerAccount(masterPayerAccount, mpaActivationData);
      } else if (masterPayerAccount.status === "active") {
        await updateActiveMasterPayerAccount(masterPayerAccount, mpaActivationData);
      }

      const failureMessages: string[] = [];

      // if domain has changed
      if (
        masterPayerAccount.domain &&
        mpaActivationData.domain &&
        masterPayerAccount.rootEmail &&
        masterPayerAccount.domain !== mpaActivationData.domain
      ) {
        if (mpaActivationData.rootEmail) {
          try {
            await updateGoogleGroup(masterPayerAccount.rootEmail, {
              domain: mpaActivationData.domain,
              rootEmail: mpaActivationData.rootEmail,
            });
          } catch (e) {
            consoleErrorWithSentry(e);
            failureMessages.push("Failed to update Google Group");
          }
        }

        if (masterPayerAccount.onePasswordItemId) {
          const title = `${mpaActivationData.domain} | ${masterPayerAccount.name}`;
          const username = masterPayerAccount.rootEmail.replace(
            masterPayerAccount.rootEmail.split(".")[1].split(/[+@]/)[0],
            mpaActivationData.domain.replaceAll(".", "-")
          );

          try {
            await updateLogin({
              vaultId: mpaVaultId,
              itemId: masterPayerAccount.onePasswordItemId,
              title,
              username,
            });
          } catch (e) {
            consoleErrorWithSentry(e);
            failureMessages.push("Failed to update OnePassword");
          }
        }
      }

      if (mpaActivationData.accountNumber) {
        try {
          await shareAWSPortfolio(mpaActivationData.accountNumber);
        } catch (e) {
          consoleErrorWithSentry(e);
          failureMessages.push("Failed to share AWS Portfolio");
        }
      }

      if (failureMessages.length > 0) {
        throw new UpdatePendingMasterPayerAccountError(failureMessages.join(", "));
      }
    },
    [updateLogin, updateGoogleGroup, shareAWSPortfolio]
  );
}

async function updatePendingMasterPayerAccount(
  masterPayerAccount: MasterPayerAccountsModelSnapshot,
  mpaActivationData: MpaSubmitObject
): Promise<void> {
  try {
    await masterPayerAccount.ref.update({
      customerId: mpaActivationData.customerId,
      domain: mpaActivationData.domain,
      features: mpaActivationData.features,
      support: mpaActivationData.support,
      rootEmail: mpaActivationData.rootEmail,
      accountNumber: mpaActivationData.accountNumber,
      lastUpdated: serverTimestamp(),
      expectedOnboardingDate: mpaActivationData.expectedOnboardingDate ?? null,
      services: mpaActivationData.services,
    });
  } catch (exception) {
    consoleErrorWithSentry(exception);
    throw exception;
  }
}
async function updateActiveMasterPayerAccount(
  masterPayerAccount: MasterPayerAccountsModelSnapshot,
  mpaActivationData: MpaSubmitObject
): Promise<void> {
  try {
    await masterPayerAccount.ref.update({
      support: mpaActivationData.support,
      lastUpdated: serverTimestamp(),
      expectedOnboardingDate: mpaActivationData.expectedOnboardingDate ?? null,
    });
  } catch (exception) {
    consoleErrorWithSentry(exception);
    throw exception;
  }
}

export type EditPendingMPADialogProps = {
  masterPayerAccount: MasterPayerAccountsModelSnapshot | null;
  successMessage: string;
  onClose: () => void;
};

export const EditPendingMPADialog = ({ masterPayerAccount, successMessage, onClose }: EditPendingMPADialogProps) => {
  const updateMasterPayerAccount = useUpdatePendingMasterPayerAccount();
  return (
    <>
      {masterPayerAccount && (
        <NewMpaDialog
          masterPayerAccount={masterPayerAccount}
          open
          onCancel={onClose}
          onSubmit={async (updatedMPAData) => {
            await updateMasterPayerAccount(masterPayerAccount, updatedMPAData);
            onClose();
          }}
          successMessage={successMessage}
          failMessage="Failed to update MPA"
        />
      )}
    </>
  );
};
