import { type Cluster } from "@doitintl/cmp-models";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import AwsAccountSelector from "./components/AwsAccountSelector";
import ClusterSelector from "./components/ClusterSelector";

interface SelectClustersStepProps {
  selectedAccountId: string | null;
  selectedCluster: Cluster | null;
  onSelectAwsAccount: (accountId: string) => void;
  onSelectCluster: (cluster: Cluster) => void;
}

const SelectClustersStep = ({
  selectedAccountId,
  selectedCluster,
  onSelectAwsAccount,
  onSelectCluster,
}: SelectClustersStepProps) => (
  <Stack spacing={4}>
    <Stack spacing={2}>
      <Typography variant="h1" sx={{ mb: 2 }}>
        Select which clusters you want to activate
      </Typography>
      <Typography variant="body1">You need the following permissions to activate EKS clusters</Typography>
      <ul>
        <li>
          <Typography variant="body1" color="text.secondary">
            <code>AdministratorAccess</code>
          </Typography>
        </li>
      </ul>
    </Stack>

    <Stack spacing={2}>
      <Typography variant="body1">
        1. Select which account you want to activate clusters from. To activate clusters from a different AWS account,
        repeat this process once you are done
      </Typography>
      <AwsAccountSelector selectedAccountId={selectedAccountId} onSelect={onSelectAwsAccount} />
    </Stack>

    <Stack spacing={2}>
      <Typography variant="body1">2. Select which cluster you want to activate</Typography>
      <ClusterSelector selectedAccountId={selectedAccountId} onSelect={onSelectCluster} value={selectedCluster} />
    </Stack>
  </Stack>
);

export default SelectClustersStep;
