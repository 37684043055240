import { type ChangeEvent, useCallback, useMemo } from "react";

import { CloudAnalyticsModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";
import { Box, Switch, Tooltip, Typography } from "@mui/material";

import { useApiContext } from "../../../api/context";
import { isProduction } from "../../../constants";
import { useAuthContext } from "../../../Context/AuthContext";
import { consoleErrorWithSentry } from "../../../utils";

export const CloudAnalyticsDataTablesSwitch = () => {
  const api = useApiContext();
  const { isDoitEmployee, userId } = useAuthContext({ mustHaveUser: true });

  const [userConfig] = useDocumentData(
    getCollection(CloudAnalyticsModel).doc("configs").collection("cloudAnalyticsUserConfigs").doc(userId)
  );

  const isUsingProdData = useMemo<boolean>(() => userConfig?.useProdData ?? false, [userConfig?.useProdData]);

  const toggleProdData = useCallback(
    async ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      try {
        await api.post(`/v1/analytics/use-prod-tables`, {
          useProdData: checked,
        });
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [api]
  );

  if (!isDoitEmployee || isProduction) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" py={0.4}>
      <Typography sx={{ color: "navigation.subItemColor" }} variant="body2">
        Analytics prod tables
      </Typography>
      <Tooltip
        title={isUsingProdData ? "Using analytics production data tables" : "Using analytics development data tables"}
        placement="bottom"
      >
        <Box pl={1} display="flex" alignItems="center">
          <Switch color="primary" size="small" checked={isUsingProdData} onChange={toggleProdData} />
        </Box>
      </Tooltip>
    </Box>
  );
};
