import { useApiContext } from "../../api/context";
import useTransforms from "../../Components/hooks/cloudAnalytics/useTransforms";
import { useCustomerId } from "../../Components/hooks/useCustomerId";
import { useSuccessSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../utils";
import { type Commitment, type CommitmentFormValues, type ContractType } from "./types";

export const useGetLabelText = (filter) => {
  const [transforms] = useTransforms();

  const getLabelText = (filterValue) => {
    const labelAux = transforms?.[filter.id]?.(filterValue);
    const labelText = labelAux ?? filterValue;
    if (labelText.length > 20) {
      return `${labelText.slice(0, 20)}...`;
    }
    return labelText;
  };

  return getLabelText;
};

export const useCreateCommitmentApiRequest = () => {
  const api = useApiContext();
  const customerId = useCustomerId();
  const successSnackbar = useSuccessSnackbar();

  const handleSubmit = async (values: CommitmentFormValues) => {
    const commitment: Commitment = {
      planName: values.planName,
      customerId,
      contracts: values.selectedContracts.map((contract) => contract.id),
      contractType: values.contractType as ContractType,
      currency: values.currency,
      periods: values.periods,
      excessRollOverPercentage: values.excess ? parseFloat(values.excessRollOverPercentage) / 100 : undefined,
      shortfallRollOverPercentage: values.shortfall ? parseFloat(values.shortfallRollOverPercentage) / 100 : undefined,
      billingAccountFilter: values.billingAccountFilter,
      serviceFilter: values.serviceFilter,
      skuFilter: values.skuFilter,
    };
    try {
      const response = await api.post("/commitment", commitment);
      if (response.status === 200) {
        successSnackbar("You successfully created your commitment plan");
      } else {
        consoleErrorWithSentry(`Error: ${response.statusText}`);
      }
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  };

  return handleSubmit;
};
