import { useState } from "react";

import { TierPackageTypes } from "@doitintl/cmp-models";
import CheckIcon from "@mui/icons-material/Check";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Link, Stack, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { useApiContext } from "../../../api/context";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { sendUpsellUpgradeNotification } from "../api";
import { sendInsightsUpsellEvent, type SendInsightsUpsellEventArgs } from "../metrics";

const alertText = "Thanks for your interest. We’ll get back to you within one business day.";

export const UpgradeOptions = ({
  open,
  onClose,
  upsellEventProperties,
}: {
  open: boolean;
  onClose: () => void;
  upsellEventProperties: SendInsightsUpsellEventArgs;
}) => {
  const theme = useTheme();
  const { customer } = useCustomerContext({ allowNull: true });
  const api = useApiContext();
  const [notificationSent, setNotificationSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getCustomerTier } = useTier();
  const navigatorCustomerTier = getCustomerTier(TierPackageTypes.NAVIGATOR);
  const showError = useErrorSnackbar();

  const sendUpgradeRequestNotification = async () => {
    setLoading(true);

    try {
      await sendUpsellUpgradeNotification({ api, customerID: customer?.id });
      setNotificationSent(true);
      sendInsightsUpsellEvent("Upsell Notification Clicked", upsellEventProperties);
    } catch (error) {
      showError("An error occurred while sending the upgrade request notification.");
    }

    setLoading(false);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <Stack gap={2} mb={4} px={3} pt={3}>
        <DialogTitle sx={{ p: 0 }}>Upgrade options</DialogTitle>
        <Typography variant="body1" color="text.primary" fontSize={16} lineHeight="24px">
          Your current plan is <b>{navigatorCustomerTier?.displayName}</b>. Upgrade to the <b>Enhanced</b> or{" "}
          <b>Enterprise</b> plans to unlock access to Insights and more advanced FinOps intelligence features.&nbsp;
          <Link
            color="primary.main"
            sx={{ display: "inline", alignItems: "center", gap: "3px", cursor: "pointer", textDecoration: "none" }}
            href="https://www.doit.com/pricing/"
            target="_blank"
          >
            Learn more&nbsp;
            <OpenInNewIcon sx={{ height: "14px", width: "14px", verticalAlign: "middle", marginBottom: "1px" }} />
          </Link>
        </Typography>
        <Typography variant="h4" fontWeight={500} color="text.primary" fontSize={18} lineHeight="26px">
          We’re here to help you
        </Typography>
        <Typography variant="body1" color="text.primary" fontSize={16} lineHeight="24px">
          Your account manager is ready to provide more information about upgrading and getting you started.
        </Typography>
        {notificationSent ? (
          <Alert
            icon={<CheckIcon fontSize="inherit" sx={{ color: "#2E7D32" }} />}
            sx={{
              background: "transparent",
              color: "#2E7D32",
              padding: 0,
              "& .MuiAlert-message": {
                background: "none",
              },
            }}
            severity="success"
          >
            {alertText}
          </Alert>
        ) : (
          <LoadingButton
            variant="contained"
            loading={loading}
            sx={{ background: "primary.main", width: "221px", whiteSpace: "nowrap" }}
            size="large"
            onClick={sendUpgradeRequestNotification}
          >
            Contact me about upgrading
          </LoadingButton>
        )}
      </Stack>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
        <Button autoFocus onClick={onClose} sx={{ color: "primary.main" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
