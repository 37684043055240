import { type Dispatch, type SetStateAction } from "react";

import { type ContractStateType } from "../../types";

export const validateOneTimeServiceDetailsStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  const errors: Record<string, boolean> = {};
  const fields = ["typeContext", "startDate", "endDate", "chargePerTerm", "estimatedFunding"];

  errors.typeContext = !state.typeContext;
  errors.startDate = !state.startDate;
  errors.endDate = !state.endDate || state.endDate < state.startDate;
  errors.chargePerTerm = !state.chargePerTerm;
  errors.estimatedFunding = !!state.showEstimatedFunding && !state.estimatedFunding;

  setState({ ...state, errors });

  return !fields.some((field) => errors[field]);
};
