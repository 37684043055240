import { createContext } from "react";

import Box from "@mui/material/Box";

import { useFullScreen } from "../../../../utils/dialog";
import { DraggableWidgetsGrid } from "./Grid/DraggableWidgetsGrid";
import { StaticWidgetsGrid } from "./Grid/StaticWidgetsGrid";
import { useWidth } from "./hooks/useWidth";
import type { Widget } from "../../../../Context/useCurrentDashboardContext";

export const WidgetsContext = createContext<[Widget[], number, boolean]>([[], 0, false]);

type Props = {
  widgets: Widget[];
  disableAutoRefreshing: boolean;
};

export function WidgetsGrid({ widgets, disableAutoRefreshing }: Props) {
  const [rowsParentRef, totalWidth] = useWidth();
  const { isMobile } = useFullScreen();

  const staticGrid = totalWidth > 0 && totalWidth < 900;

  return (
    <WidgetsContext.Provider value={[widgets, totalWidth, disableAutoRefreshing || staticGrid]}>
      <Box
        ref={rowsParentRef}
        sx={{
          width: "100%",
        }}
      />

      {staticGrid ? <StaticWidgetsGrid singleWidgetInRow={isMobile} /> : <DraggableWidgetsGrid />}
    </WidgetsContext.Provider>
  );
}
