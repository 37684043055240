import { useHistory } from "react-router";
import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";

import { FooterBar } from "./FooterBar";

type Props = {
  isLastStep: boolean;
  onNextClicked: () => void;
  onBackClicked: () => void;
  allowNext: boolean;
  formId?: string;
  loading?: boolean;
  finalStep?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  hideBackButton?: boolean;
};

export const ButtonsFooter = ({
  isLastStep,
  onNextClicked,
  onBackClicked,
  allowNext,
  formId,
  loading,
  finalStep = "Finish",
  maxWidth = "md",
  hideBackButton,
}: Props) => {
  const history = useHistory();
  return (
    <FooterBar>
      <Stack maxWidth={maxWidth} direction="row" justifyContent="space-between" width="100%">
        {hideBackButton ? (
          <div />
        ) : (
          <Button variant="outlined" color="primary" onClick={onBackClicked}>
            Back
          </Button>
        )}
        <Stack direction="row" spacing={1}>
          <Button variant="text" color="primary" onClick={history.goBack}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            key={formId}
            form={formId}
            type={formId ? "submit" : "button"}
            loading={loading}
            onClick={() => {
              if (!formId) {
                onNextClicked();
              }
            }}
            disabled={!allowNext}
          >
            {isLastStep ? finalStep : "Next"}
          </LoadingButton>
        </Stack>
      </Stack>
    </FooterBar>
  );
};
