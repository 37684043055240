import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { CATEGORY_FRIENDLY_NAMES, CategoryStatus } from "@doitintl/cmp-models";
import { Alert, Link as MUILink } from "@mui/material";
import filter from "lodash/filter";

import { useCloudConnectContext } from "../../../Context/customer/CloudCOnnectContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";

const bigqueryAdvancedPermission = "bigquery-finops-advanced";

const MissingPermissionsBanner = () => {
  const { customerOrPresentationModeCustomer: customer, customer: originalCustomer } = useCustomerContext();
  const isInPresentationMode =
    isCustomerInPresentationMode(originalCustomer) || originalCustomer.presentationMode?.isPredefined;
  const cloudConnect = useCloudConnectContext();
  const [missingPermissions, setMissingPermissions] = useState<string[]>([]);
  const url = `/customers/${customer.id}/settings/gcp`;

  useEffect(() => {
    const gcpServices = filter(cloudConnect, { cloudPlatform: "google-cloud" });
    if (gcpServices.length > 0) {
      const gcpCloudConnection = gcpServices[0];
      const { categoriesStatus } = gcpCloudConnection;

      if (isInPresentationMode) {
        setMissingPermissions([]);
        return;
      }

      if (categoriesStatus) {
        const missing = Object.entries(categoriesStatus)
          .filter(
            ([category, status]) =>
              category.startsWith("bigquery") &&
              category !== bigqueryAdvancedPermission &&
              status !== CategoryStatus.Healthy
          )
          .map(([category]) => CATEGORY_FRIENDLY_NAMES[category] || category);

        setMissingPermissions(missing);
      }
    }
  }, [cloudConnect, isInPresentationMode]);

  if (missingPermissions.length === 0) {
    return null;
  }

  const formattedPermissions = new Intl.ListFormat("en", { style: "long", type: "conjunction" }).format(
    missingPermissions
  );

  return (
    <Alert severity="error">
      Your Google Cloud service account is missing permissions for the following categories:{" "}
      <span style={{ fontWeight: 500 }}>{formattedPermissions}</span>.{" "}
      <MUILink to={url} component={Link} underline="hover">
        Click here
      </MUILink>{" "}
      to see details of BigQuery Lens Editions configuration.
    </Alert>
  );
};

export default MissingPermissionsBanner;
