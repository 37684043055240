import { Box } from "@mui/material";

import { useIsCloudflowPublished } from "./hooks/useIsCloudflowPublished";

const DisablePublishedFlowGuard = ({ children }) => {
  const isPublished = useIsCloudflowPublished();

  if (!isPublished) {
    return children;
  }
  return (
    <Box
      role="group"
      aria-disabled="true"
      sx={{
        opacity: 0.5,
        pointerEvents: "none",
      }}
    >
      {children}
    </Box>
  );
};

export default DisablePublishedFlowGuard;
