import { type AwsMarketplaceAccountState, AwsMarketplaceAccountStatus } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { stringifyTimestamp } from "../../../../../utils/common";
import { type FirestoreTimestamp } from "../../../../../utils/firebase";
import { type accountAndCustomerType, type entitlementsTableRowType } from "../../types";

const NAVIGATOR_PRODUCT_CODE = "93fstry5uc26r32s97c8mnwvr";
const DCI_PRODUCT_CODE = "4i7x5vj2qm9kge4gwf54jebmq";
const emptyChar = "-";

export function constructTableData(data: accountAndCustomerType[] | undefined): entitlementsTableRowType[] {
  if (!data?.length) {
    return [];
  }

  return data.map((item) => ({
    accountId: item?.accountRef?.id || emptyChar,
    product: stringifyProduct(item?.account?.awsAccountData?.ProductCode),
    customerId: item?.account?.customer?.id || emptyChar,
    customerPrimaryDomain: item?.customer?.primaryDomain || emptyChar,
    customerName: item?.customer?.primaryDomain || item?.customer?.name || item?.customer?._name || emptyChar,
    state: stringifyAccountStatus(item?.account?.status),
    createdAt: stringifyTimestamp({ timeStamp: item?.account?.createdAt, fallback: emptyChar }),
    updatedAt: stringifyTimestamp({
      timeStamp: item?.account?.updatedAt || item?.account?.createdAt,
      fallback: emptyChar,
    }),
    updatedAtDate: dateTimeFromTimestamp(item?.account?.updatedAt || item?.account?.createdAt),
    createdAtDate: dateTimeFromTimestamp(item?.account?.createdAt),
  }));
}

function stringifyAccountStatus(status: AwsMarketplaceAccountState) {
  switch (status) {
    case AwsMarketplaceAccountStatus.ACTIVE:
      return "Active";
    case AwsMarketplaceAccountStatus.PENDING:
      return "Pending";
    case AwsMarketplaceAccountStatus.INACTIVE:
      return "Inactive";
    default:
      return emptyChar;
  }
}

function stringifyProduct(code: string | undefined) {
  switch (code) {
    case NAVIGATOR_PRODUCT_CODE:
      return "Navigator";
    case DCI_PRODUCT_CODE:
      return "DCI";
    case undefined:
      return "None";
    default:
      return "Unknown";
  }
}

function dateTimeFromTimestamp(timestamp: Pick<FirestoreTimestamp, "toDate"> | null | undefined) {
  if (!timestamp) {
    return DateTime.now();
  }
  const date = DateTime.fromJSDate(timestamp.toDate());
  return date.isValid ? date : DateTime.now();
}
