import { useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsDataSource, Metadata } from "@doitintl/cmp-models";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { globalText } from "../../../assets/texts";
import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import { DraggableItemHolder } from "../../../Components/DragAndDrop/DraggableItemHolder";
import { ExpandMore } from "../../../Components/ExpendMore";
import { useAnalyticsDimensions } from "../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions";
import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { type AttributionWRef } from "../../../types";
import useAllocationCreateSelector from "../attributions/AttributionCreate/useAllocationCreateSelector";
import useAttributionCreateFormula from "../attributions/AttributionCreate/useAttributionCreateFormula";

type AllocationDraggableItemProps = {
  replaceAttributionInGroup: (newAttributionId: string, oldAttributionId: string) => void;
  attributionNumber?: number;
  attributions: AttributionWRef[];
  disabledAttributions: string[];
  grabbing?: boolean;
  initialAttributionID: string;
  removeAttributionFromGroup: (initialAttributionID: string) => void;
};

const CONTENT_PADDING_LEFT = "56px";

export const AllocationDraggableItem = ({
  attributionNumber,
  attributions,
  grabbing,
  initialAttributionID,
}: AllocationDraggableItemProps) => {
  const attribution = attributions.find((a) => a.ref.id === initialAttributionID) || null;

  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = (e) => {
    e.preventDefault();
    setExpanded((prev) => !prev);
  };
  const { metadata: metadataSnapshots, fetchMetadata, hasDataHub } = useCloudAnalyticsContext();

  const displayNumber = attributionNumber !== undefined ? attributionNumber + 1 : undefined;
  const [selectedAllocationType, setSelectedAllocationType] = useState(allocationTxt.CREATE_NEW_ALLOCATION);
  const { dimensions = [], handleDimensionLabelsChange } = useAnalyticsDimensions({
    metadataSnapshots,
    defaultDataSource: hasDataHub ? AnalyticsDataSource.BILLING_DATAHUB : AnalyticsDataSource.BILLING,
  });

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  const filteredDimensions = useMemo(() => {
    const filtered = dimensions?.slice();
    filtered?.forEach((d) => {
      if (d.data.type === Metadata.DATETIME) {
        d._visible = false;
      } else if (d.data.type === Metadata.ATTRIBUTION) {
        const index = filtered.indexOf(d);
        if (index !== -1) {
          filtered.splice(index, 1);
        }
      }
    });
    return filtered;
  }, [dimensions]);

  const onRemoveRule = useCallback(() => {}, []);

  const excludeSelectMetadataIds = undefined;
  const { AttributionCreateSelector, filters } = useAllocationCreateSelector({
    initialFilters: attribution?.data?.filters ?? [],
    dimensions: filteredDimensions,
    handleDimensionLabelsChange,
    excludeSelectMetadataIds,
  });

  const { AttributionCreateFormula } = useAttributionCreateFormula({
    formula: attribution?.data.formula,
    filters,
    allocationMode: true,
  });

  return (
    <Stack gap={2} sx={{ mb: 2 }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>{`Rule ${displayNumber}`}</Typography>
      <DraggableItemHolder
        grabbing={grabbing}
        height="auto"
        id={attribution?.ref.id ?? ""}
        key={attribution?.ref.id ?? ""}
        alignItems="flex-start"
      >
        <Box sx={{ p: 1, pb: 2, ml: -5 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "space-between",
              width: "100%",
              pl: CONTENT_PADDING_LEFT,
            }}
          >
            <FormControl>
              <RadioGroup
                row
                value={selectedAllocationType}
                onChange={(e) => {
                  setSelectedAllocationType(e.target.value);
                }}
              >
                <FormControlLabel
                  value={allocationTxt.CREATE_NEW_ALLOCATION}
                  control={<Radio />}
                  label={allocationTxt.CREATE_NEW_ALLOCATION}
                />
                <FormControlLabel
                  value={allocationTxt.USE_EXISTING_ALLOCATION}
                  control={<Radio />}
                  label={allocationTxt.USE_EXISTING_ALLOCATION}
                />
              </RadioGroup>
            </FormControl>

            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
              }}
            >
              <IconButton onClick={() => {}} size="medium">
                <ContentCopyIcon fontSize="small" color="action" sx={{ pointerEvents: "none" }} />
              </IconButton>

              <Tooltip title={globalText.REMOVE}>
                <IconButton onClick={onRemoveRule} size="medium">
                  <DeleteIcon fontSize="small" color="action" sx={{ pointerEvents: "none" }} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <Grid
            container
            spacing={1}
            sx={{
              mt: 2,
            }}
          >
            <Grid>
              <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                <ExpandMoreIcon />
              </ExpandMore>
            </Grid>
            <TextField
              label={allocationTxt.NAME_YOUR_ALLOCATION}
              sx={{ marginLeft: 1, width: "100%", maxWidth: 540 }}
              margin="none"
              fullWidth
            />
          </Grid>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box
              sx={{
                pl: CONTENT_PADDING_LEFT,
                mt: 2,
              }}
            >
              {AttributionCreateSelector}
              {AttributionCreateFormula}
            </Box>
          </Collapse>
        </Box>
      </DraggableItemHolder>
    </Stack>
  );
};
