import { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import InputAdornment from "@mui/material/InputAdornment";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/EditRounded";
import CheckIcon from "@mui/icons-material/CheckRounded";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import InfoIcon from "@mui/icons-material/InfoRounded";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import { DatePicker } from "@mui/x-date-pickers";
import LoadingButton from "../LoadingButton";
import { globalText } from "../../assets/texts";
import { useAuthContext } from "../../Context/AuthContext";
import { consoleErrorWithSentry } from "../../utils";
import BillingRuleForm from "./BillingRuleForm";

const dateFormat = "yyyy-MM-dd";

const formatBasicBillingRule = (basicBillingRule) => {
  switch (basicBillingRule.billingRuleType) {
    case "fixedRate":
      return `$${basicBillingRule.billingAdjustment} Fixed Rate`;
    case "percentDiscount":
      return `${basicBillingRule.billingAdjustment}% Discount`;
    case "percentIncrease":
      return `${basicBillingRule.billingAdjustment}% Markup`;
    default:
      return basicBillingRule;
  }
};

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(6),
  },
  paper: {
    width: "100%",
    display: "flex",
    padding: theme.spacing(0, 3),
  },
  list: {
    width: "100%",
    height: 183,
    maxHeight: 183,
    overflow: "auto",
    borderColor: theme.palette.general.divider,
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: "solid",
    borderTopWidth: 0,
  },
  infoIcon: {
    marginRight: theme.spacing(0.5),
  },
  listHeader: {
    width: "100%",
    borderColor: theme.palette.general.divider,
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: "solid",
    padding: theme.spacing(0, 0),
  },
  listTitleText: {
    marginTop: theme.spacing(-0.5),
  },
  listTitleWArrows: {
    padding: theme.spacing(0, 2),
  },
  listTitle: {
    padding: theme.spacing(2, 2),
  },
  arrows: {
    padding: theme.spacing(0),
  },
  rightArrow: {
    marginRight: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
}));

const PricebookTitle = (props) => {
  const [bookName, setBookName] = useState(props.bookName);
  const [touched, setTouched] = useState(false);
  const [comment, setComment] = useState(props.comment);
  const isNameValid = bookName && bookName.length > 0;

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "Escape":
        props.onCancel();
        break;
      case "Enter":
        if (isNameValid) {
          props.onSubmit(bookName, comment);
        }
        break;
      default:
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <TextField
          label="Pricebook Name"
          variant="outlined"
          margin="dense"
          value={bookName}
          onChange={(event) => {
            setBookName(event.target.value);
            setTouched(true);
          }}
          onKeyDown={handleKeyDown}
          error={touched && !isNameValid}
          autoFocus
          fullWidth
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Save name"
                    onClick={() => props.onSubmit(bookName, comment)}
                    disabled={!isNameValid}
                    size="small"
                  >
                    <CheckIcon />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      </Grid>
      <Grid size={12}>
        <TextField
          label="Pricebook Notes"
          variant="outlined"
          margin="dense"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          onKeyDown={handleKeyDown}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

// AWS pricebook
export default function Pricebook(props) {
  const classes = useStyles();
  const { isDoitOwner } = useAuthContext();
  const [pricebook, setPricebook] = useState();
  const [renameMode, setRenameMode] = useState(!pricebook?.bookName);
  const [expanded, setExpanded] = useState(Array(props.pricebook.specification.ruleGroups.length).fill(false));
  const [billingRuleForm, setBillingRuleForm] = useState({
    open: false,
    ruleGroup: null,
    billingRule: null,
  });
  const handleCloseBillingRule = () => {
    setBillingRuleForm({
      open: false,
      ruleGroup: null,
      billingRule: null,
    });
  };

  useEffect(() => {
    handleCloseBillingRule();
    setRenameMode(!props.pricebook.bookName);
    try {
      if (props?.pricebook) {
        setPricebook(JSON.parse(JSON.stringify(props?.pricebook)));
      }
    } catch (error) {
      consoleErrorWithSentry(error);
    }
    const expanded = Array(props.pricebook.specification.ruleGroups.length).fill(false);
    if (expanded.length > 0) {
      expanded[0] = true;
    }
    setExpanded(expanded);
  }, [props?.pricebook]);

  const handleExpand = (index) => (_event, isExpanded) => {
    setExpanded((expanded) => {
      const newExpanded = expanded.slice();
      newExpanded[index] = isExpanded;
      return newExpanded;
    });
  };

  const handleOpenBillingRule = (ruleGroup, billingRule) => () => {
    setBillingRuleForm({
      open: true,
      ruleGroup,
      billingRule,
    });
  };
  const handleDeleteBillingRule = () => {
    if (billingRuleForm.ruleGroup !== null && billingRuleForm.billingRule !== null) {
      const updatedPricebook = { ...pricebook };
      updatedPricebook.specification.ruleGroups[billingRuleForm.ruleGroup].billingRules.splice(
        billingRuleForm.billingRule,
        1
      );
      handleCloseBillingRule();
      setPricebook(updatedPricebook);
    }
  };
  const handleDeleteBillingRulesGroup = (i) => () => {
    const updatedPricebook = { ...pricebook };
    updatedPricebook.specification.ruleGroups.splice(i, 1);
    setPricebook(updatedPricebook);
  };
  const onReset = () => {
    setPricebook(cloneDeep(props.pricebook));
  };

  const onAddBillingRulesGroup = () => {
    const updatedPricebook = { ...pricebook };
    const ruleGroup = { enabled: true, billingRules: [], startDate: "", endDate: "" };
    updatedPricebook.specification.ruleGroups.splice(updatedPricebook.specification.ruleGroups.length, 0, ruleGroup);
    setPricebook(updatedPricebook);
    setExpanded((expanded) => {
      const newExpanded = expanded.slice();
      newExpanded.push(true);
      return newExpanded;
    });
    handleOpenBillingRule(updatedPricebook.specification.ruleGroups.length - 1, null)();
  };

  const onEditBillingRule = (values) => {
    const updatedPricebook = { ...pricebook };
    if (billingRuleForm.ruleGroup !== null && billingRuleForm.billingRule !== null) {
      const billingRule = {
        ...updatedPricebook.specification.ruleGroups[billingRuleForm.ruleGroup].billingRules[
          billingRuleForm.billingRule
        ],
        ...values,
      };
      updatedPricebook.specification.ruleGroups[billingRuleForm.ruleGroup].billingRules.splice(
        billingRuleForm.billingRule,
        1,
        billingRule
      );
    } else if (billingRuleForm.ruleGroup !== null) {
      const billingRule = {
        includeDataTransfer: true,
        ...values,
      };
      updatedPricebook.specification.ruleGroups[billingRuleForm.ruleGroup].billingRules.splice(
        updatedPricebook.specification.ruleGroups[billingRuleForm.ruleGroup].billingRules.length,
        0,
        billingRule
      );
    }
    setPricebook(updatedPricebook);
  };

  const onChangeDate = (ruleGroup, field) => (date) => {
    const updatedPricebook = { ...pricebook };
    updatedPricebook.specification.ruleGroups[ruleGroup][field] = date ? date.toFormat(dateFormat) : null;
    setPricebook(updatedPricebook);
  };

  const onChangeBookName = (bookName, comment) => {
    const updatedPricebook = { ...pricebook };
    updatedPricebook.bookName = bookName;
    updatedPricebook.specification.comment = comment;
    setPricebook(updatedPricebook);
    setRenameMode(false);
  };

  const isUnchanged = useMemo(() => {
    if (!props?.pricebook || !pricebook) {
      return false;
    }
    for (const field of Object.keys(pricebook)) {
      if (field === "ref") {
        continue;
      }
      if (!isEqual(pricebook?.[field], props.pricebook?.[field])) {
        return false;
      }
    }
    return true;
  }, [pricebook, props?.pricebook]);

  const isValid = () => {
    if (pricebook && pricebook.bookName && pricebook.specification.ruleGroups) {
      for (const ruleGroup of pricebook.specification.ruleGroups) {
        if (ruleGroup.billingRules.length <= 0) {
          return false;
        }
      }
      return true;
    }

    return false;
  };

  const showRulesArrows = (i) => pricebook.specification.ruleGroups[i].billingRules.length > 1;

  const onItemsMove = (direction, groupIdx, ruleIdx) => {
    const movingGroups = ruleIdx === undefined;

    const updatedPricebook = { ...pricebook };

    const items = movingGroups
      ? updatedPricebook.specification.ruleGroups
      : updatedPricebook.specification.ruleGroups[groupIdx].billingRules;
    const i = movingGroups ? groupIdx : ruleIdx;

    if (direction === "fwd") {
      if (i >= items.length - 1) {
        return;
      }
      [items[i + 1], items[i]] = [items[i], items[i + 1]];
      if (movingGroups) {
        [expanded[i + 1], expanded[i]] = [expanded[i], expanded[i + 1]];
      }
    } else {
      if (i <= 0) {
        return;
      }
      [items[i - 1], items[i]] = [items[i], items[i - 1]];
      if (movingGroups) {
        [expanded[i - 1], expanded[i]] = [expanded[i], expanded[i - 1]];
      }
    }
    setPricebook(updatedPricebook);
  };

  if (!pricebook) {
    return null;
  }

  return (
    <Card className={classes.card} elevation={1}>
      <BillingRuleForm
        open={billingRuleForm.open}
        onClose={handleCloseBillingRule}
        onDelete={handleDeleteBillingRule}
        onSubmit={onEditBillingRule}
        billingRule={
          billingRuleForm.ruleGroup !== null &&
          billingRuleForm.billingRule !== null &&
          pricebook.specification.ruleGroups[billingRuleForm.ruleGroup].billingRules[billingRuleForm.billingRule]
        }
        options={props.options}
      />
      <CardHeader
        avatar={
          <Tooltip title={isUnchanged ? "" : "There are unsaved changes"}>
            <span>
              <IconButton
                aria-label="Back"
                component={Link}
                to="/pricebooks/amazon-web-services"
                disabled={!isUnchanged}
                size="large"
              >
                {isUnchanged ? (
                  <BackIcon color="primary" />
                ) : (
                  <Badge color="primary" variant="dot">
                    <BackIcon />
                  </Badge>
                )}
              </IconButton>
            </span>
          </Tooltip>
        }
        title={
          renameMode ? (
            <PricebookTitle
              bookName={pricebook.bookName}
              comment={pricebook.specification.comment}
              onCancel={() => setRenameMode(false)}
              onSubmit={onChangeBookName}
            />
          ) : (
            pricebook.bookName
          )
        }
        subheader={!renameMode && pricebook.specification.comment}
        action={
          pricebook.editable &&
          !renameMode && (
            <Tooltip title="Edit pricebook name and notes">
              <span>
                <IconButton
                  aria-label="Rename pricebook"
                  onClick={() => setRenameMode(true)}
                  disabled={!isDoitOwner || props.loading}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          )
        }
      />
      <CardContent>
        <Grid container direction="row" spacing={1}>
          <Grid size={12}>
            {pricebook.specification.ruleGroups.map((ruleGroup, i) => (
              <Accordion key={`ruleGroup-${i}`} expanded={expanded[i]} onChange={handleExpand(i)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {pricebook.specification.ruleGroups.length > 1 && (
                    <>
                      <Tooltip title="Upgrade billing rule group">
                        <>
                          <IconButton
                            disabled={!isDoitOwner || i <= 0}
                            className={classes.arrows}
                            onClick={(event) => {
                              event.stopPropagation();
                              onItemsMove("bwd", i);
                            }}
                            size="large"
                          >
                            <ArrowDropUp />
                          </IconButton>
                        </>
                      </Tooltip>
                      <Tooltip title="Downgrade billing rule group">
                        <>
                          <IconButton
                            disabled={!isDoitOwner || i >= pricebook.specification.ruleGroups.length - 1}
                            className={clsx(classes.arrows, classes.rightArrow)}
                            onClick={(event) => {
                              event.stopPropagation();
                              onItemsMove("fwd", i);
                            }}
                            size="large"
                          >
                            <ArrowDropDown />
                          </IconButton>
                        </>
                      </Tooltip>
                    </>
                  )}
                  <Typography>
                    Billing Rules Group {ruleGroup.startDate && `from ${ruleGroup.startDate}`}{" "}
                    {ruleGroup.endDate && `until ${ruleGroup.endDate}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1} direction="column">
                    <Grid container spacing={1}>
                      <Grid
                        size={{
                          xs: 6,
                          md: 3,
                          lg: 2,
                        }}
                      >
                        <DatePicker
                          label="Start Date"
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              margin="dense"
                              fullWidth
                              helperText="Optional, Inclusive"
                              {...params}
                            />
                          )}
                          value={ruleGroup.startDate ? DateTime.fromFormat(ruleGroup.startDate, dateFormat) : null}
                          onChange={onChangeDate(i, "startDate")}
                          disabled={!isDoitOwner || props.loading || !pricebook.editable}
                          inputFormat="dd LLLL, yyyy"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          clearable
                        />
                      </Grid>

                      <Grid
                        size={{
                          xs: 6,
                          md: 3,
                          lg: 2,
                        }}
                      >
                        <DatePicker
                          label="End Date"
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              margin="dense"
                              fullWidth
                              helperText="Optional, Inclusive"
                              {...params}
                              slotProps={{
                                inputLabel: {
                                  shrink: true,
                                },
                              }}
                            />
                          )}
                          value={ruleGroup.endDate ? DateTime.fromFormat(ruleGroup.endDate, dateFormat) : null}
                          onChange={onChangeDate(i, "endDate")}
                          disabled={!isDoitOwner || props.loading || !pricebook.editable}
                          inputFormat="dd LLLL, yyyy"
                          clearable
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid
                        container
                        size={12}
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          title={
                            "Pricebook process billing rules in left-right, top-down order." +
                            " The first applicable rule that satisfies the constraint for a line item is used." +
                            " No subsequent rules are used for that line item."
                          }
                        >
                          <InfoIcon fontSize="small" color="action" className={classes.infoIcon} />
                        </Tooltip>
                        <Typography
                          variant="body1"
                          sx={{
                            display: "inline",
                          }}
                        >
                          Billing Rules
                        </Typography>
                      </Grid>

                      {ruleGroup.billingRules.map((billingRule, j) => (
                        <Grid
                          key={`billingRule-${j}`}
                          size={{
                            xs: 12,
                            md: 6,
                            lg: 4,
                          }}
                        >
                          <List className={classes.listHeader} dense disablePadding>
                            {showRulesArrows(i) && (
                              <ListItem dense className={classes.arrows}>
                                {j > 0 && (
                                  <Tooltip title="Upgrade billing rule">
                                    <IconButton
                                      className={classes.arrows}
                                      onClick={() => onItemsMove("bwd", i, j)}
                                      disabled={!isDoitOwner}
                                      size="large"
                                    >
                                      <ArrowLeft />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <div className={classes.grow} />
                                {j < pricebook.specification.ruleGroups[i].billingRules.length - 1 && (
                                  <Tooltip title="Downgrade billing rule">
                                    <IconButton
                                      className={classes.arrows}
                                      onClick={() => onItemsMove("fwd", i, j)}
                                      disabled={!isDoitOwner}
                                      size="large"
                                    >
                                      <ArrowRight />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </ListItem>
                            )}
                            <ListItem
                              dense
                              className={clsx({
                                [classes.listTitle]: !showRulesArrows(i),
                                [classes.listTitleWArrows]: showRulesArrows(i),
                              })}
                              secondaryAction={
                                pricebook.editable && (
                                  <Tooltip title="Edit billing rule">
                                    <span>
                                      <IconButton
                                        aria-label="Edit billing rule"
                                        size="small"
                                        onClick={handleOpenBillingRule(i, j)}
                                        disabled={!isDoitOwner || props.loading}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                )
                              }
                            >
                              <ListItemText
                                primary={`${j + 1}. ${billingRule.name}`}
                                primaryTypographyProps={{ variant: "subtitle1" }}
                                secondary={formatBasicBillingRule(billingRule.basicBillingRule)}
                                secondaryTypographyProps={{ variant: "subtitle2" }}
                                className={classes.listTitleText}
                              />
                            </ListItem>
                          </List>

                          <List className={classes.list} dense disablePadding>
                            {billingRule.product?.productName && (
                              <ListItem dense divider>
                                <ListItemText primary={`Product : "${billingRule.product.productName}"`} />
                              </ListItem>
                            )}
                            {(billingRule.product?.usageType ?? []).map((usageType) => (
                              <ListItem
                                dense
                                divider
                                key={`product-${billingRule.product.productName}-usageType-${usageType.name}`}
                              >
                                <ListItemText primary={`Usage Type : "${usageType.name}"`} />
                              </ListItem>
                            ))}
                            {(billingRule.product?.operation ?? []).map((operation) => (
                              <ListItem
                                dense
                                divider
                                key={`product-${billingRule.product.productName}-operation-${operation.name}`}
                              >
                                <ListItemText primary={`Operation : "${operation.name}"`} />
                              </ListItem>
                            ))}
                            {(billingRule.product?.region ?? []).map((region) => (
                              <ListItem
                                dense
                                divider
                                key={`product-${billingRule.product.productName}-region-${region.name}`}
                              >
                                <ListItemText primary={`Region : "${region.name}"`} />
                              </ListItem>
                            ))}
                            {billingRule.product.lineItemDescription &&
                              billingRule.product.lineItemDescription.map((lineItemDescription, i) => (
                                <ListItem dense divider key={`product-${billingRule.product.productName}-${i}`}>
                                  {lineItemDescription.startsWith && (
                                    <ListItemText
                                      primary={`Line item description starts with "${lineItemDescription.startsWith}"`}
                                    />
                                  )}
                                  {lineItemDescription.contains && (
                                    <ListItemText
                                      primary={`Line item description contains "${lineItemDescription.contains}"`}
                                    />
                                  )}
                                  {lineItemDescription.matchesRegex && (
                                    <ListItemText
                                      primary={`Line item description matches regexp "${lineItemDescription.matchesRegex}"`}
                                    />
                                  )}
                                </ListItem>
                              ))}
                          </List>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </AccordionDetails>

                {isDoitOwner && pricebook.editable && (
                  <>
                    <Divider />

                    <AccordionActions>
                      <Button
                        size="small"
                        color="primary"
                        variant="text"
                        disabled={ruleGroup.billingRules.length > 0}
                        onClick={handleDeleteBillingRulesGroup(i)}
                      >
                        Delete group
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        variant={ruleGroup.billingRules.length > 0 ? "outlined" : "contained"}
                        onClick={handleOpenBillingRule(i, null)}
                        disabled={props.loading}
                      >
                        New billing rule
                      </Button>
                    </AccordionActions>
                  </>
                )}
              </Accordion>
            ))}
          </Grid>
          <Grid size={12} />
        </Grid>
      </CardContent>
      {props.variant === "edit" && (
        <CardContent>
          <Typography variant="caption">
            {`updated by ${pricebook.updatedBy} on ${DateTime.fromISO(pricebook.updatedAt).toLocaleString({
              year: "numeric",
              month: "short",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })}`}
          </Typography>
        </CardContent>
      )}
      {isDoitOwner && pricebook.editable && (
        <Grid
          container
          wrap="nowrap"
          sx={{
            justifyContent: "space-between",
          }}
        >
          <CardActions>
            {props.variant === "create" ? (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                aria-label="Cancel"
                disabled={props.loading}
                component={Link}
                to="/pricebooks/amazon-web-services"
              >
                {globalText.CANCEL}
              </Button>
            ) : (
              <Fade in={!isUnchanged}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  disabled={props.loading || isUnchanged}
                  onClick={onReset}
                >
                  Reset changes
                </Button>
              </Fade>
            )}
          </CardActions>

          <CardActions>
            <Button
              size="small"
              color="primary"
              variant={pricebook.specification.ruleGroups.length > 0 ? "outlined" : "contained"}
              onClick={onAddBillingRulesGroup}
              disabled={props.loading}
            >
              New billing rules group
            </Button>
            <LoadingButton
              size="small"
              color="primary"
              variant="contained"
              disabled={props.loading || !isValid() || renameMode || (isUnchanged && props.variant !== "create")}
              loading={props.loading}
              onClick={() => props.onSave(pricebook)}
              mixpanelEventId="pricebook.save"
            >
              {globalText.SAVE}
            </LoadingButton>
          </CardActions>
        </Grid>
      )}
    </Card>
  );
}
