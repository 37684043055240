import { useCallback } from "react";

import { Metadata, TimeInterval, type TimeSettingsConfig, TimeSettingsMode } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { BudgetTypes, FixedFilters } from "../../../../Pages/CloudAnalytics/utilities";
import { type DraftBudget } from "../../../../types";
import useGenerateReport from "../../useGenerateReport";

const getTimeSettingsConfig = (interval: TimeInterval, budgetType: BudgetTypes): TimeSettingsConfig => {
  if (budgetType === BudgetTypes.FIXED) {
    return {
      unit: interval,
      mode: TimeSettingsMode.Fixed,
      includeCurrent: false,
      amount: 0,
    };
  }

  const timeSettings: TimeSettingsConfig = {
    unit: interval,
    mode: TimeSettingsMode.Last,
    includeCurrent: true,
    amount: 0,
  };

  switch (interval) {
    case TimeInterval.DAY:
      timeSettings.amount = 7;
      break;
    case TimeInterval.WEEK:
      timeSettings.amount = 8;
      break;
    case TimeInterval.MONTH:
      timeSettings.amount = 6;
      break;
    case TimeInterval.QUARTER:
      timeSettings.amount = 4;
      break;
    case TimeInterval.YEAR:
      timeSettings.amount = 2;
      break;
  }

  return timeSettings;
};

const getForecastSettingsField = (
  type: BudgetTypes,
  timeSettingsAmount: number,
  startPeriodDate: DateTime,
  endPeriodDate: DateTime | null
) => {
  let forecastSettingsField = {};

  if (type === BudgetTypes.FIXED && endPeriodDate) {
    const now = DateTime.utc();
    const futureTimeIntervals = Math.ceil(endPeriodDate.diff(now, "days").days);
    const historicalTimeIntervals = Math.ceil(now.diff(startPeriodDate, "days").days);

    if (futureTimeIntervals > 0 && historicalTimeIntervals > 0) {
      forecastSettingsField = {
        forecastSettings: {
          futureTimeIntervals,
          historicalTimeIntervals,
          mode: "totals",
        },
      };
    }
  } else if (timeSettingsAmount > 0) {
    forecastSettingsField = {
      forecastSettings: {
        futureTimeIntervals: timeSettingsAmount,
        historicalTimeIntervals: timeSettingsAmount,
        mode: "totals",
      },
    };
  }

  return forecastSettingsField;
};

const useBudgetInvestigate = (budget: DraftBudget) => {
  const { customer } = useCustomerContext();
  const generateReport = useGenerateReport();

  return useCallback(async () => {
    const {
      name,
      config: { timeInterval, scope, currency, filters, startPeriod, endPeriod, type },
    } = budget.data;

    const startPeriodDate = DateTime.fromJSDate(startPeriod.toDate()).toUTC();
    const endPeriodDate = endPeriod ? DateTime.fromJSDate(endPeriod.toDate()).toUTC() : null;

    const fields =
      scope?.length > 0
        ? [
            {
              type: Metadata.FIXED,
              id: FixedFilters.SERVICE,
              values: [],
              groupBy: true,
            },
            {
              type: Metadata.ATTRIBUTION,
              id: Metadata.ATTRIBUTION,
              values: scope.map((a) => a.id),
            },
          ]
        : (filters?.map((filter) => ({
            type: filter.type,
            id: filter.key,
            values: filter.values,
            groupBy: true,
          })) ?? []);

    const timeSettings = getTimeSettingsConfig(timeInterval, type);
    let customTimeRangeField = {};

    if (type === BudgetTypes.FIXED && endPeriodDate) {
      customTimeRangeField = {
        customTimeRange: {
          from: startPeriodDate,
          to: endPeriodDate,
        },
      };
    }

    const forecastSettingsField = getForecastSettingsField(type, timeSettings.amount, startPeriodDate, endPeriodDate);

    const params = {
      name: `Explore Cloud Analytics report: ${name}`,
      description: "",
      config: {
        currency,
        fields,
        timeInterval: type === BudgetTypes.FIXED ? TimeInterval.DAY : timeInterval,
        timeSettings,
        ...customTimeRangeField,
        ...forecastSettingsField,
      },
    };

    const reportId = await generateReport(params, false);
    return `/customers/${customer.id}/analytics/reports/${reportId}?run-on-open=true`;
  }, [budget.data, customer.id, generateReport]);
};

export default useBudgetInvestigate;
