import React, { useState } from "react";

import { Box, Divider, Tab, Tabs as MuiTabs } from "@mui/material";

export interface TabConfig {
  label: string;
  content: React.ReactNode;
  key: string;
}

interface TabsComponentProps {
  tabs: TabConfig[];
}

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
  <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
    {value === index && <Box>{children}</Box>}
  </div>
);

const Tabs: React.FC<TabsComponentProps> = ({ tabs }) => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <MuiTabs value={value} onChange={handleChange} aria-label="dynamic tabs">
        {tabs.map((tab) => (
          <Tab sx={{ textTransform: "none" }} key={tab.key} label={tab.label} />
        ))}
      </MuiTabs>
      <Divider />
      {tabs.map((tab, index) => (
        <TabPanel key={tab.key} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default Tabs;
