import { useEffect } from "react";

import { useCustomerContext } from "../CustomerContext";
import { DynamicCachedHooksLoaderList } from "./DynamicProvidersLoadersList";
import { useDynamicStore } from "./dynamicStore";

export function DynamicCachedHooksLoader() {
  const { hooks, reset } = useDynamicStore();

  const customerContext = useCustomerContext({ allowNull: true });
  const customer = customerContext?.customer;

  useEffect(() => {
    if (!customer) {
      reset();
    }
  }, [reset, customer]);

  if (!customer) {
    return;
  }

  return <DynamicCachedHooksLoaderList customerId={customer.id} loadedHooks={hooks} />;
}
