import { type JSX } from "react";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useCustomerIntegrations } from "../../../Pages/CloudAnalytics/utilities";
import { useIsEntitledToCurrentDashboardType } from "../hooks/useIsEntitledByDashboardType";
import { Loader } from "../Loader";
import { DatadogLensEnticement } from "./DatadogLensEnticement";

type Props = {
  children: JSX.Element;
};

export const DatadogLensGuard = ({ children }: Props) => {
  const isEntitled = useIsEntitledToCurrentDashboardType();
  const { customerIntegrations, loading } = useCustomerIntegrations();
  const { customer } = useCustomerContext();

  if (loading || !customerIntegrations) {
    return <Loader />;
  }

  if (customer.presentationMode?.enabled || customer.presentationMode?.isPredefined) {
    return children;
  }

  const hasDatadogIntegration = customerIntegrations.includes("datadog");

  if (isEntitled && hasDatadogIntegration) {
    return children;
  }

  return <DatadogLensEnticement state={isEntitled ? "activate" : "upgrade"} />;
};
