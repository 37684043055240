import { Box, Stack, Typography } from "@mui/material";

import DoitLogo from "../../../assets/doit-logo-hero-square.svg";
import type { Insight } from "../types";

type Props = {
  insight: Insight;
};

const CustomInsightSourceLabel = ({ insight }: Props) => (
  <Typography
    sx={{
      color: "text.secondary",
      mt: 1.5,
      fontSize: "14px",
      fontWeight: "500",
    }}
    color="text.secondary"
    variant="body1"
  >
    Recommended to you by{" "}
    {insight.customInsightAttributes?.publishingUserEmail &&
    insight.customInsightAttributes?.showPublishingUser &&
    insight.publishingUser?.displayName
      ? insight.publishingUser.displayName
      : "DoiT experts"}
  </Typography>
);

const AutomatedInsightSourceLabel = () => (
  <Box sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
    <Typography
      variant="body1"
      sx={{
        color: "text.secondary",
        fontSize: "14px",
        fontWeight: "500",
      }}
    >
      Monitored by
    </Typography>
    <Typography
      sx={{
        ml: 0.5,
        fontSize: "14px",
        fontWeight: "500",
        borderBottom: "1px dotted",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        lineHeight: 1.25,
      }}
      color="text.secondary"
      variant="body1"
    >
      DoiT Cloud Intelligence
    </Typography>
  </Box>
);

export const InsightSourceLabel = ({ insight }: Props) => (
  <Stack
    direction="row"
    spacing={0.5}
    sx={{
      alignItems: "center",
      mt: 2,
    }}
  >
    <Box component="img" src={DoitLogo} sx={{ height: "20px", width: "20px" }} />
    {insight.providerId === "custom" ? <CustomInsightSourceLabel insight={insight} /> : <AutomatedInsightSourceLabel />}
  </Stack>
);
