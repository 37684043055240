import { useEffect, useRef, useState } from "react";

import { addIdealFirestoreHook, addIdealFirestoreHookOnce, type OnIdeal } from "@doitintl/models-firestore";

export const useIsHeadless = () => {
  const [isHeadless, setIsHeadless] = useState<boolean>();

  useEffect(() => {
    const userAgent = navigator.userAgent || "";
    setIsHeadless(userAgent.includes("HeadlessChrome"));
  }, []);

  return isHeadless;
};

const useFirestoreIdeal = (
  name: string,
  interval: number,
  onIdeal: OnIdeal,
  addHookFn: (name: string, interval: number, onIdeal: OnIdeal) => void,
  condition: boolean
) => {
  const isHeadless = useIsHeadless();
  const onIdealRef = useRef<OnIdeal>(onIdeal);

  useEffect(() => {
    if (!condition) {
      return;
    }

    if (isHeadless === true || isHeadless === undefined) {
      return;
    }

    addHookFn(name, interval, onIdealRef.current);
  }, [isHeadless, name, interval, onIdeal, addHookFn, condition]);
};

// Specific hooks
export const useFirestoreIdealHook = (name: string, interval: number, onIdeal: OnIdeal, condition = true) => {
  useFirestoreIdeal(name, interval, onIdeal, addIdealFirestoreHook, condition);
};

export const useFirestoreIdealHookOnce = (name: string, interval: number, onIdeal: OnIdeal, condition = true) => {
  useFirestoreIdeal(name, interval, onIdeal, addIdealFirestoreHookOnce, condition);
};
