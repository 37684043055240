import { type JSX, useCallback, useEffect, useState } from "react";

import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  PointerSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { DraggableItemsContext } from "./Context";

type Props = {
  children: JSX.Element[] | JSX.Element;
  values: Record<string, string[]>;
  onItemsOrderChanged: (items: Record<string, string[]>) => void;
  renderDraggingItem: (id: string) => JSX.Element | null;
  mouseSensorOptions?: { activationConstraint: { delay: number; tolerance: number } };
  disabledDrop?: (id: string, section: string) => boolean;
};

/**
 * The root container of the dragging area. This component will manage the order of the ids that can be draggable.
 * Supports multiple dragging groups.
 * Manages an object state that holds groups (the keys) of arrays of ids
 * For example:
 * If controller access a state
 * {
 *   group1: ["id1", "id2"]
 *   group2: ["id3", "id4"]
 * }
 * It will allow to drag item with id === id1 from group1 into group2, then the state will be changed to
 * {
 *   group1: ["id2"]
 *   group2: ["id1", "id3", "id4"]
 * }
 * @param children - should accept one or more DraggableGroup component
 * @param values - initial state of the ids, shouldn't be changed with renderDraggingItem, onItemsOrderChanged actions, can be changed from outside
 * @param renderDraggingItem - will be called every time an item is during dragging
 * @param onItemsOrderChanged - will be called once (with a new state of the ids) when a dragging is done
 * @param mouseSensorOptions - to add a delay before the drag start event and a tolerance (distance of motion in px that is tolerated before the drag operation is aborted)
 * @param disabledDrop - function which accepts the id of the item that is being dragged and the container over which it is dragged. Returns a bool if the item can be dropped in the container
 */
export const DraggableContainer = ({
  children,
  values,
  renderDraggingItem,
  onItemsOrderChanged,
  mouseSensorOptions,
  disabledDrop,
}: Props) => {
  const [items, setItems] = useState(values);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const sensors = useSensors(useSensor(PointerSensor), useSensor(MouseSensor, mouseSensorOptions));

  useEffect(() => {
    if (values) {
      setItems(values);
    }
  }, [values]);

  const findContainer = useCallback(
    (id) => {
      if (id in items) {
        return id;
      }

      return Object.keys(items).find((key) => items[key].includes(id));
    },
    [items]
  );

  const handleDragStart = useCallback((event) => {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }, []);

  const handleDragOver = useCallback(
    (event) => {
      const { active, over } = event;
      const { id } = active;
      if (!over) {
        return;
      }
      const { id: overId } = over;

      // Find the containers
      const activeContainer = findContainer(id);
      const overContainer = findContainer(overId);

      if (disabledDrop?.(id, overContainer)) {
        return;
      }

      if (!activeContainer || !overContainer || activeContainer === overContainer) {
        return;
      }

      setItems((items) => {
        const activeItems = items[activeContainer];
        const overItems = items[overContainer];
        const overIndex = overItems.indexOf(overId);
        const activeIndex = activeItems.indexOf(active.id);

        let newIndex: number;

        if (overId in items) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top > (over.rect.top as number) + (over.rect.height as number);

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        return {
          ...items,
          [activeContainer]: items[activeContainer].filter((item) => item !== active.id),
          [overContainer]: [
            ...items[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...items[overContainer].slice(newIndex, items[overContainer].length),
          ],
        };
      });
    },
    [findContainer, disabledDrop]
  );

  const handleDragEnd = useCallback(
    (event) => {
      const { active, over } = event;
      const { id } = active;
      if (!over) {
        return;
      }
      const { id: overId } = over;

      const activeContainer = findContainer(id);
      const overContainer = findContainer(overId);

      if (!activeContainer || !overContainer || activeContainer !== overContainer) {
        return;
      }

      const activeIndex = items[activeContainer].indexOf(active.id);
      const overIndex = items[overContainer].indexOf(overId);

      const newItems = {
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
      };

      setItems(newItems);
      onItemsOrderChanged(newItems);
      setActiveId(null);
    },
    [findContainer, items, onItemsOrderChanged]
  );

  return (
    <div data-cy="draggable-container">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <DraggableItemsContext.Provider value={items}>{children}</DraggableItemsContext.Provider>
        <DragOverlay>{activeId ? renderDraggingItem(activeId.toString()) : null}</DragOverlay>
      </DndContext>
    </div>
  );
};
