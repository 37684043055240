import React, { type ReactNode, useState } from "react";

import { type AvaFeedback } from "@doitintl/cmp-models";
import { CheckCircleOutline } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import { InputFeedback } from "./InputFeedback";
import { ToggleLikeDislikeButton } from "./ToggleLikeDislikeButton";

type Props = {
  feedback?: AvaFeedback;
  onFeedbackClicked: (feedback: AvaFeedback) => void;
  disableAnimation: boolean;
  children: ReactNode;
};

export const Feedback = ({ feedback, onFeedbackClicked, disableAnimation = false, children }: Props) => {
  const [hideFeedbackDialog, setHideFeedbackDialog] = useState(true);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const handleFeedbackButtonsChanged = (positive: boolean) => {
    setHideFeedbackDialog(positive);

    if (positive) {
      setFeedbackSubmitted(true);
      onFeedbackClicked({
        positive: true,
      });
    }
  };

  const handleNegativeFeedbackSubmitted = (value: string) => {
    setFeedbackSubmitted(true);

    onFeedbackClicked({
      positive: false,
      text: value,
    });
  };

  return (
    <Stack width="100%">
      <Stack width="100%" direction="row" justifyContent="space-between" pt={1} alignItems="center">
        <Stack direction="row" alignItems="center">
          <Typography mr={1} variant="body2" color={feedbackSubmitted ? "text.disabled" : ""}>
            Was this response helpful?
          </Typography>

          <ToggleLikeDislikeButton
            feedback={feedback}
            onClick={handleFeedbackButtonsChanged}
            disabled={feedbackSubmitted}
            disableAnimation={disableAnimation}
          />
          {feedbackSubmitted && (
            <Typography sx={{ alignItems: "center", display: "flex" }} variant="caption">
              <CheckCircleOutline fontSize="small" sx={{ color: "success.light", ml: 3, mr: 1.5 }} />
              Thank you for your feedback
            </Typography>
          )}
        </Stack>
        <Box>{children}</Box>
      </Stack>
      {!hideFeedbackDialog && !feedbackSubmitted && (
        <InputFeedback onSubmit={handleNegativeFeedbackSubmitted} disabled={feedbackSubmitted} />
      )}
    </Stack>
  );
};
