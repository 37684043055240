import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useCurrentDashboardContext } from "../../Context/useCurrentDashboardContext";
import { useAuthContext } from "../../Context/AuthContext";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";

const useStyles = makeStyles(() => ({
  cardContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  icon: {
    fontSize: 40,
  },
}));

export default function ErrorMessageWidgetCard({ error, widget }) {
  const classes = useStyles();
  const theme = useTheme();
  const { isDoitEmployee } = useAuthContext();
  const { widgetHeight } = useCurrentDashboardContext();

  return (
    <Card>
      <WidgetCardHeader title={widget?.name} />
      <CardContent style={{ height: widgetHeight }} className={classes.cardContent}>
        <ErrorIcon className={classes.icon} />
        <Typography variant="subtitle1">Oops! Something went wrong...</Typography>
        {isDoitEmployee && <pre style={{ backgroundColor: theme.palette.background.default }}>{error.message}</pre>}
      </CardContent>
    </Card>
  );
}
