import { type AccessLevel, AccessLevels, type CloudConnectCategory } from "@doitintl/cmp-models";

export const getScopedCategoryPermissions = (category: CloudConnectCategory, scope: AccessLevel) => {
  switch (scope) {
    // For projects, remove the org level only permissions
    case AccessLevels.PROJECT: {
      // If there's no org level only permissions, return all permissions
      if (!category.orgLevelOnlyPermissions) {
        return category.permissions;
      }

      // If there's any org level only permissions, filter
      return category.permissions.filter((permission) => !category.orgLevelOnlyPermissions!.includes(permission));
    }

    // For organizations, remove the project level only permissions
    case AccessLevels.ORGANIZATION: {
      // If there's no project level only permissions, return all permissions
      if (!category.projectLevelOnlyPermissions) {
        return category.permissions;
      }

      // If there's any project level only permissions, filter
      return category.permissions.filter((permission) => !category.projectLevelOnlyPermissions!.includes(permission));
    }

    // In other cases, return all permissions
    default: {
      return category.permissions;
    }
  }
};
