import { useCallback, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router";
import { ContractModel, EntityModel, VendorContractModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { serverTimestamp, type Unsubscribe } from "firebase/firestore";

import { useApiContext } from "../../api/context";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../Context/AuthContext";
import { arrayFromDocChange } from "../../Context/customer/arrayFromDocChange";
import { useEntitiesContext } from "../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTiers } from "../../Context/TierProvider";
import { useUserContext } from "../../Context/UserContext";
import { type Contracts, type CustomerRef } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import { useContractTypesContext } from "../ContractsTabs/ContractsTypesContext";
import { cancelContract, deleteContract } from "./api";
import { type ContractStateType } from "./ContractForm/types";
import { type FormattedContract } from "./ContractsList/types";
import { formatContract } from "./ContractsList/utils";

const doitTrialTiers = ["5RXtgHNfAcD4QJy2E2Fn", "icw4tbhsBksNEDQf1pNY"];

type AllCustomerContractsType = {
  contracts: FormattedContract[];
  loading: boolean;
};

export const useCustomerContracts = (): AllCustomerContractsType => {
  const { contractProductTypes } = useContractTypesContext();
  const { customer, entities } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();
  const [tiers] = useTiers();

  const [contracts, setContracts] = useState<FormattedContract[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let contractListener: Unsubscribe;
    const loadContracts = () => {
      setLoading(true);
      const customerContractsQuery = getCollection(ContractModel).where("customer", "==", customer.ref);
      contractListener = customerContractsQuery.onSnapshot((snapshot) => {
        const handleSnapshot = async () => {
          const queryContractsPromises = snapshot.docs.map(async (doc) => {
            const contract = doc.asModelData();

            let vendorContract;
            if (contract.vendorContract && isDoitEmployee) {
              const vendorContractSnap = await contract.vendorContract.get();
              vendorContract = vendorContractSnap.data();
            }

            return formatContract({
              contract,
              id: doc.id,
              vendorContract,
              vendorContractId: contract.vendorContract?.id,
              entities,
              productTypes: contractProductTypes,
              tiers,
            });
          });

          const queryContracts = await Promise.all(queryContractsPromises);

          const filteredContracts = queryContracts.filter(
            (contract) => isDoitEmployee || !doitTrialTiers.includes(contract.data.tier?.id ?? "")
          );

          setContracts(filteredContracts);
          setLoading(false);
        };

        handleSnapshot();
      });
    };

    if (customer.ref) {
      loadContracts();
    } else {
      setLoading(false);
    }

    return () => {
      if (typeof contractListener === "function") {
        contractListener();
      }
    };
  }, [customer.ref, entities, isDoitEmployee, contractProductTypes, tiers]);

  return { contracts, loading };
};

type SpecificContractType = {
  contract: ContractModel | undefined;
  loading: boolean;
};

/**
 * Simpler version of the above, designed for one contract to be viewed in the viewer.
 * Vendor information is not required.
 * @param contractId The ID of the contract to be viewed
 */
export const useCustomerContract = (contractId: string): SpecificContractType => {
  const { customer } = useCustomerContext();

  const [contract, setContract] = useState<ContractModel>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const contractRef = getCollection(ContractModel).doc(contractId);
    return contractRef.onSnapshot((snapshot) => {
      const contract = snapshot.asModelData();

      // Ensure we can't return a contract that doesn't belong to the customer
      if (contract?.customer.id === customer.ref.id) {
        setContract(contract);
      }

      setLoading(false);
    });
  }, [contractId, customer.ref]);

  return { contract, loading };
};

type ExtendedContracts = {
  extendedContracts: Contracts;
  extendedContractsLoading: boolean;
};

/**
 * Hook that returns all active customer contracts, regardless of start date.
 * @param customerRef The reference to the customer whose contracts are being retrieved
 */
export const useExtendedCustomerContracts = (customerRef: CustomerRef): ExtendedContracts => {
  const { entities } = useEntitiesContext();
  const { userRoles } = useUserContext();

  const [extendedContracts, setExtendedContracts] = useState<Contracts>([]);
  const [extendedContractsLoading, setExtendedContractsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!customerRef) {
      setExtendedContractsLoading(false);
      return;
    }

    // Ensuring correct permissions just like contracts context.
    if (!userRoles?.assetsManager && !userRoles?.contractsViewer && !userRoles?.doitEmployee) {
      setExtendedContractsLoading(false);
      return;
    }

    const query = getCollection(ContractModel).where("customer", "==", customerRef).where("active", "==", true);

    const entitiesIds = entities.map((entity) => entity.id);

    const listeners = entitiesIds.map((entityId) =>
      query.where("entity", "==", getCollection(EntityModel).doc(entityId)).onSnapshot((querySnapshot) => {
        setExtendedContracts((prevExtendedContracts) => {
          const updatedExtendedContracts = [...(prevExtendedContracts ?? [])];
          arrayFromDocChange(updatedExtendedContracts, querySnapshot, (doc) => ({
            ...doc.asModelData(),
            id: doc.id,
          }));

          return updatedExtendedContracts;
        });

        setExtendedContractsLoading(false);
      })
    );

    return () => {
      listeners.forEach((listener) => {
        listener();
      });
    };
  }, [customerRef, entities, userRoles?.assetsManager, userRoles?.contractsViewer, userRoles?.doitEmployee]);

  return { extendedContracts, extendedContractsLoading };
};

const useCancelContract = () => {
  const api = useApiContext();

  return useCallback(
    async (contractId: string, onSuccess: () => void, onError: () => void) => {
      try {
        await cancelContract({ api, contractId });
        onSuccess();
      } catch (error) {
        onError();
        consoleErrorWithSentry(error);
      }
    },
    [api]
  );
};

const useDeleteContract = () => {
  const api = useApiContext();

  return useCallback(
    async (contractId: string, onSuccess: () => void, onError: () => void) => {
      try {
        await deleteContract({ api, contractId });
        onSuccess();
      } catch (error) {
        onError();
        consoleErrorWithSentry(error);
      }
    },
    [api]
  );
};

export const useBackToList = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const history = useHistory();

  return useCallback(() => {
    history.push(`/customers/${customerId}/contracts/contracts-list`);
  }, [customerId, history]);
};

const useUpdateContractStatus = (state: ContractStateType) => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { contractId } = useParams<{ contractId: string }>();
  const snackbar = useSnackbar();
  const handleBackToList = useBackToList();

  return useCallback(
    async (active: boolean, terminated: boolean) => {
      try {
        await getCollection(ContractModel)
          .doc(contractId)
          .update({
            active,
            terminated,
            updatedBy: { name: currentUser.displayName, email: currentUser.email },
            timestamp: serverTimestamp(),
          });

        if (state.vendorContract?.id) {
          await getCollection(VendorContractModel)
            .doc(state.vendorContract?.id)
            .update({
              active,
              terminated,
              updatedBy: { name: currentUser.displayName, email: currentUser.email },
              timestamp: serverTimestamp(),
            });
        }

        handleBackToList();
      } catch (error) {
        snackbar.onOpen({
          message: "Failed to update status",
          variant: "error",
          autoHideDuration: 5000,
          withClose: true,
        });
        consoleErrorWithSentry(error);
      }
    },
    [contractId, currentUser.displayName, currentUser.email, handleBackToList, snackbar, state.vendorContract?.id]
  );
};

// needed for cypress tests
export default { useCancelContract, useDeleteContract, useUpdateContractStatus };
