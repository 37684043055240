import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Typography } from "@mui/material";

const cloudFormationURI =
  "https://console.aws.amazon.com/cloudformation/home?account=[##ACCOUNT_ID##]/stacks/create/review?templateURL=https://doit-support.s3.amazonaws.com/doit-support.json&stackName=doit-support-gateway";

type ConcedefyProps = {
  accountId: string;
};

export default function ConcedefyStep({ accountId }: ConcedefyProps) {
  const deployRole = () => {
    window.open(cloudFormationURI.replace("[##ACCOUNT_ID##]", accountId), "_blank", "noopener");
  };
  const { t } = useTranslation("services");

  return (
    <Box>
      <Button color="primary" onClick={deployRole} variant="contained" size="small">
        <OpenInNewIcon sx={{ mr: 1 }} />
        {t("ConcedefyStep.deploy")}
      </Button>

      <Typography sx={{ mt: 1 }} component="div" variant="caption">
        {t("ConcedefyStep.grantPermission1")} <strong>{t("ConcedefyStep.grantPermission2")}</strong>{" "}
        {t("ConcedefyStep.grantPermission3")} {accountId}
        {t("ConcedefyStep.grantPermission4")}
      </Typography>
    </Box>
  );
}
