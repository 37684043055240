import { useMemo } from "react";

import { type Step, type StepState } from "../../../Components/Stepper";

export type StepConfig = {
  label: string;
  children: React.JSX.Element;
  isComplete?: boolean;
  hasError?: boolean;
};

const useStepperState = (
  currentStep: number,
  totalSteps: number,
  {
    getIsStepComplete,
    getStepHasError,
  }: {
    getIsStepComplete?: (stepIndex: number) => boolean;
    getStepHasError?: (stepIndex: number) => boolean;
  }
) =>
  useMemo<StepState[]>(() => {
    const steps: StepState[] = Array.from({ length: totalSteps }, (_, index) => {
      const stepHasError = getStepHasError ? getStepHasError(index) : false;
      if (stepHasError) {
        return "error";
      }

      const stepIsComplete = getIsStepComplete ? getIsStepComplete(index) : true;
      if (index <= currentStep) {
        return stepIsComplete ? "complete" : "editing";
      }

      return "incomplete";
    });

    return steps;
  }, [currentStep, totalSteps, getIsStepComplete, getStepHasError]);

export const useSteps = ({ stepConfig, currentStep }: { stepConfig: StepConfig[]; currentStep: number }): Step[] => {
  const getIsStepComplete = (stepIndex: number) => stepConfig[stepIndex].isComplete ?? true;
  const getStepHasError = (stepIndex: number) => stepConfig[stepIndex].hasError ?? false;

  const stepperState = useStepperState(currentStep, stepConfig.length, {
    getIsStepComplete,
    getStepHasError,
  });

  return stepConfig.map((step, index) => ({
    ...step,
    order: index,
    state: stepperState[index],
    required: true,
  }));
};
