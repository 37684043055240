import { type Dispatch, type MouseEventHandler, useEffect, useLayoutEffect, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Button,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { ellipsisSx } from "./ReferencedFieldChip";
import { type MenuItemData } from "./useReferencedFieldMenuItems";
import { useResizeObserverSignal } from "./useResizeObserverSignal";

export const ReferencedFieldMenu = ({
  anchorEl,
  open,
  menuItems,
  hideBackButton,
  onClose,
  onAppendMenuItem,
  onPopMenuItem,
}: {
  anchorEl: HTMLElement | null;
  open: boolean;
  menuItems: MenuItemData[];
  hideBackButton: boolean;
  onClose: MouseEventHandler<HTMLElement>;
  onAppendMenuItem: Dispatch<MenuItemData>;
  onPopMenuItem: () => void;
}) => {
  const [filteredMenuItems, setFilteredMenuItems] = useState<MenuItemData[]>([]);
  const [filter, setFilter] = useState("");
  const [showNavButtons, setShowNavButtons] = useState(false);
  const [menuWidth, setMenuWidth] = useState(0);
  const resizeSignal = useResizeObserverSignal(anchorEl);

  useEffect(() => {
    setFilter("");
  }, [menuItems]);
  useEffect(() => {
    setShowNavButtons(menuItems.length === 0 || menuItems.some(({ type }) => type === "token"));
  }, [menuItems]);

  useEffect(() => {
    setFilteredMenuItems(
      menuItems.filter(({ name }) => name.toLocaleLowerCase().startsWith(filter.toLocaleLowerCase()))
    );
  }, [filter, menuItems]);

  useLayoutEffect(() => {
    if (!anchorEl) {
      return;
    }
    setMenuWidth(anchorEl.offsetWidth);
  }, [anchorEl, resizeSignal]);

  useEffect(() => {
    if (!open) {
      setFilter("");
    }
  }, [open]);

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        ".MuiPaper-root": {
          minWidth: menuWidth,
        },
      }}
    >
      <Stack
        spacing={1}
        sx={{
          alignItems: "stretch",
        }}
      >
        <Box sx={{ pt: 1, px: 2 }}>
          <TextField
            label="Search"
            fullWidth
            value={filter}
            onChange={({ target: { value } }) => {
              setFilter(value);
            }}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
          />
        </Box>
        <Divider />
        {showNavButtons && (
          <Stack
            direction="row"
            sx={{
              px: 1,
            }}
          >
            {!hideBackButton && (
              <Button variant="text" sx={{ width: "unset" }} onClick={onPopMenuItem} startIcon={<ArrowBackIcon />}>
                Back
              </Button>
            )}
            <Box
              sx={{
                flex: 1,
              }}
            />
            <Button variant="text" sx={{ width: "unset" }} onClick={onClose}>
              Done
            </Button>
          </Stack>
        )}
      </Stack>
      <MenuList>
        {filteredMenuItems.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
              }}
            >
              {menuItems.length === 0 ? "No more options" : "No results found"}
            </Typography>
          </Box>
        )}
        {filteredMenuItems.map((listItem) => (
          <MenuItem
            key={listItem.type === "node" ? listItem.nodeId : listItem.name}
            disabled={listItem.disabled}
            onClick={(event) => {
              event.stopPropagation();
              onAppendMenuItem(listItem);
            }}
            selected={listItem.selected}
          >
            <ListItemText
              primary={listItem.name}
              primaryTypographyProps={{ sx: ellipsisSx }}
              secondary={listItem.caption}
              secondaryTypographyProps={{ sx: { pl: 1 } }}
              sx={{ display: "flex", alignItems: "baseline" }}
            />
            {listItem.hasContinuation && <NavigateNextIcon sx={{ ml: 2 }} />}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
