import { Fragment, useCallback, useMemo, useState } from "react";

import { type CloudFlowNodeType, type ConditionExpression, type ConditionExpressionsGroup } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Stack, Typography, useTheme } from "@mui/material";

import { cloudflowTexts } from "../../../../assets/texts";
import { type NodeWitOutputModel } from "../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";
import FilterCondition from "./FilterCondition";
import { FilterDialog } from "./FilterDialog";

type FilterGroupProps = {
  selectedNode: NodeWitOutputModel | undefined;
  groupIndex: number;
  referenceableNodes: NodeWitOutputModel[];
  onDeleteConditionGroup: (groupIndex: number) => void;
  texts: {
    groupText: string;
    addNewText: string;
  };
};

const FilterGroupComp = ({
  selectedNode,
  groupIndex,
  onDeleteConditionGroup,
  referenceableNodes,
  texts: { groupText, addNewText },
}: FilterGroupProps) => {
  const [addConditionOpen, setAddConditionOpen] = useState(false);
  const theme = useTheme();
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.FILTER>();

  const conditionGroups = nodeConfig.parameters.conditionGroups;
  const group = conditionGroups[groupIndex];
  const conditions = useMemo(() => (group ? group.conditions : []), [group]);

  const onConfirmAddCondition = useCallback(
    async (newCondition: ConditionExpression) => {
      const updatedConditions = [...conditions, newCondition];

      const updatedGroup: ConditionExpressionsGroup = {
        ...group,
        conditions: updatedConditions,
      };

      const updatedConditionGroups = [...conditionGroups];
      updatedConditionGroups[groupIndex] = updatedGroup;

      updateNode((prevNode) => ({
        parameters: {
          ...prevNode.parameters!,
          conditionGroups: updatedConditionGroups,
        },
      }));

      setAddConditionOpen(false);
    },
    [conditions, conditionGroups, group, groupIndex, updateNode]
  );

  return (
    <>
      {selectedNode && (
        <FilterDialog
          open={addConditionOpen}
          selectedNode={selectedNode}
          mode="add"
          handleClose={() => {
            setAddConditionOpen(false);
          }}
          handleAction={onConfirmAddCondition}
          referenceableNodes={referenceableNodes}
        />
      )}
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          pb: 0.25,
          width: "100%",
        }}
      >
        <Typography variant="body2">
          {groupText} {groupIndex + 1}
        </Typography>
        <IconButton
          onClick={() => {
            onDeleteConditionGroup(groupIndex);
          }}
          sx={{ p: 0.5 }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Stack
        sx={{
          alignItems: "flex-start",
          gap: 1,
          border: "1px solid",
          borderColor: theme.palette.general.outlineBorder,
          borderRadius: 1,
          padding: 1,
          width: "100%",
        }}
      >
        {conditions.map((condition, conditionIndex) => (
          <Fragment key={conditionIndex}>
            <FilterCondition
              selectedNode={selectedNode}
              condition={condition}
              referenceableNodes={referenceableNodes}
              groupIndex={groupIndex}
              conditionIndex={conditionIndex}
            />
            {conditionIndex + 1 !== conditions.length && (
              <Typography variant="caption">{cloudflowTexts.AND}</Typography>
            )}
          </Fragment>
        ))}
        <Button
          startIcon={<AddIcon fontSize="small" />}
          onClick={() => {
            setAddConditionOpen(true);
          }}
          color="primary"
          sx={{ textAlign: "left" }}
        >
          {addNewText}
        </Button>
      </Stack>
    </>
  );
};

export default FilterGroupComp;
