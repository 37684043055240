import { useCallback, useEffect, useMemo, useState } from "react";

import { Link as RouterLink, useHistory } from "react-router-dom";
import { CustomerModel, RoleModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import AddIcon from "@mui/icons-material/AddBoxRounded";
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import { rolesTxt } from "../../assets/texts/IAM/roles";
import { DeleteButton } from "../../Components/DeleteButton";
import { FilterContextProvider } from "../../Components/FilterTable/Context";
import EnhancedTableFilter from "../../Components/FilterTable/EnhancedTableFilter";
import Hide from "../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../Components/hooks/useRouteMatchURL";
import useTableState from "../../Components/hooks/useTableState";
import { Loader } from "../../Components/Loader";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type Role, RoleCustomerType } from "../../types";
import { type DataColumns } from "../../types/FilterTable";
import { sanitizeDate } from "../../utils/common";
import { dateFormatDayMonthYear } from "../../utils/dateTimeFormats";
import { useFullScreen } from "../../utils/dialog";
import { serverTimestamp } from "../../utils/firebase";
import mixpanel from "../../utils/mixpanel";
import { getDefaultRoleRefByCustomer } from "../../utils/permissions";
import { CUSTOM, PRESET, UNTITLED_ROLE } from "./consts";
import { EnhancedTableHead } from "./EnhancedTableHead";

const findMaxUntitledRoleCounter = (roles: readonly Role[]) =>
  roles.reduce((currentMaxRoleCounter, role) => {
    if (!role.name.includes(UNTITLED_ROLE)) {
      return currentMaxRoleCounter;
    }
    const currentRoleCounter = Number(role.name.split(" ")[2]);
    return currentMaxRoleCounter < currentRoleCounter ? currentRoleCounter : currentMaxRoleCounter;
  }, 0);

const filterColumns: DataColumns<Role> = [
  {
    path: "name",
    label: "Name",
  },
  {
    path: "description",
    label: "Description",
    comparators: ["contains"],
  },
  {
    path: "type",
    label: "Type",
    toOption: (value) => ({ value, label: capitalize(value) }),
  },
  {
    path: "timeModified",
    label: "Last Updated",
    type: "DateTime",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    transform: (value) => sanitizeDate(DateTime.fromFormat(value, "yyyy-LL-dd", { zone: "utc" })),
    validate: (value) => DateTime.fromFormat(value, "yyyy-LL-dd")?.isValid,
    toOption: (value) => {
      const strValue = DateTime.fromJSDate(value).toFormat("yyyy-LL-dd");
      return {
        value: strValue,
        label: strValue,
      };
    },
  },
];

const headCells = [
  { id: "name", numeric: false, showMobile: true, label: "Role Name", disablePadding: true },
  {
    id: "description",
    numeric: false,
    showMobile: false,
    label: "Description",
    disablePadding: true,
  },
  { id: "type", numeric: false, showMobile: true, label: "Type", disablePadding: true },
  {
    id: "timeModified",
    numeric: false,
    showMobile: false,
    label: "Last Updated",
    disablePadding: true,
  },
] as const;

const useStyles = makeStyles((theme) => ({
  tableToolbar: {
    padding: theme.spacing(1),
  },
}));

type Props = {
  roles: readonly Role[];
};

export default function Roles({ roles }: Props) {
  const history = useHistory();
  const { customer } = useCustomerContext();
  const routeMatchURL = useRouteMatchURL();
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile: smDown } = useFullScreen();
  const [selected, setSelected] = useState<Pick<Role, "inUse" | "type" | "ref" | "id">[]>([]);

  const {
    tableState,
    filteredRows,
    rows,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleRequestFilter,
  } = useTableState(roles, {
    sort: "name",
    persistenceKey: "roles",
  });

  useEffect(() => {
    const rolesIds = roles.reduce((acc, role) => {
      acc[role.id] = role;
      return acc;
    }, new Set<string>());

    setSelected((prevSelected) => prevSelected.filter((selectedRole) => rolesIds.has(selectedRole.id)));
  }, [roles]);

  const createCustomRole = useCallback(async () => {
    const maxUntitledRoleCounter = findMaxUntitledRoleCounter(roles);
    const name = `${UNTITLED_ROLE} ${maxUntitledRoleCounter + 1}`;
    const newRole = getCollection(RoleModel).newDoc();
    await newRole.set({
      customer: getCollection(CustomerModel).doc(customer.id),
      name,
      description: "",
      inUse: 0,
      permissions: [],
      timeCreated: serverTimestamp(),
      timeModified: serverTimestamp(),
      type: CUSTOM,
      customerType: RoleCustomerType.CONSOLIDATED,
    });
    setSelected((prevSelected) => prevSelected.concat([{ inUse: 0, type: CUSTOM, ref: newRole, id: newRole.id }]));
    mixpanel.track("iam.roles.create", {
      name,
    });
    const newRolePage = `/customers/${customer.id}/iam/roles/${newRole.id}`;
    history.push(newRolePage);
  }, [customer.id, history, roles]);

  const isSelectedRolesContainsPreset = useMemo(
    () => selected.some((selectedRole) => selectedRole.type === PRESET),
    [selected]
  );

  const isSelectedRolesInUse = useMemo(() => selected.some((selectedRole) => selectedRole.inUse > 0), [selected]);

  const isSelectedRolesEligibleForDelete = !isSelectedRolesContainsPreset && !isSelectedRolesInUse;

  const handleDeleteRole = useCallback(() => {
    Promise.all(selected.map((selectedRole) => selectedRole.ref.delete())).then(() => {
      setSelected([]);
    });
  }, [selected]);

  const defaultRoleId = getDefaultRoleRefByCustomer(customer)?.id;

  const defaultRoleNotation = useMemo(
    () => (
      <>
        <Tooltip title="This is the default role for your organization">
          <Typography
            variant="body2"
            sx={{
              textDecoration: "none",
              color: theme.palette.text.secondary,
              marginLeft: theme.spacing(1.25),
            }}
            component="span"
          >
            (Default)
          </Typography>
        </Tooltip>
      </>
    ),
    [theme]
  );

  const title = useMemo(() => {
    if (isSelectedRolesContainsPreset) {
      return "Preset roles cannot be deleted";
    }
    return isSelectedRolesInUse ? "This role has been assigned to users and cannot be deleted" : "Delete role";
  }, [isSelectedRolesContainsPreset, isSelectedRolesInUse]);

  return (
    <Loader loading={roles.length === 0}>
      <FilterContextProvider columns={filterColumns} persistenceKey="roles_filter">
        <Toolbar disableGutters className={classes.tableToolbar}>
          <Grid
            container
            spacing={1}
            sx={{
              alignItems: "center",
            }}
            size="grow"
          >
            <Grid size="grow">
              <EnhancedTableFilter
                data={roles}
                columns={filterColumns}
                onFilter={handleRequestFilter}
                placeholder="Filter roles"
                limitTags={smDown ? 1 : -1}
                defaultValue={undefined}
              />
            </Grid>
            <Grid>
              {selected.length > 0 ? (
                <>
                  <Tooltip key="delete-role" title={title}>
                    <span>
                      <DeleteButton onDelete={handleDeleteRole} disabled={!isSelectedRolesEligibleForDelete} />
                    </span>
                  </Tooltip>
                </>
              ) : (
                <Hide mdDown>
                  <Button variant="contained" color="primary" onClick={createCustomRole} startIcon={<AddIcon />}>
                    {rolesTxt.CREATE_NEW_ROLE}
                  </Button>
                </Hide>
              )}
            </Grid>
          </Grid>
        </Toolbar>
        <TableContainer>
          <Table>
            <EnhancedTableHead
              onRequestSort={handleRequestSort}
              headCells={headCells}
              tableState={tableState}
              isAllSelected={rows.length === selected.length}
              isSomeSelected={selected.length > 0}
            />
            <TableBody>
              {rows.map((role) => (
                <TableRow hover key={role.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={!!selected.find((selectedRole) => selectedRole.id === role.id)}
                      onClick={() => {
                        setSelected((prevSelected) => {
                          const selectedIndex = prevSelected.findIndex((selectedRole) => role.id === selectedRole.id);

                          if (selectedIndex > -1) {
                            const updatedSelected = [...prevSelected];
                            updatedSelected.splice(selectedIndex, 1);
                            return updatedSelected;
                          }

                          return [...prevSelected, role];
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" padding="normal">
                    <Box sx={{ width: "max-content" }}>
                      <Typography
                        component={RouterLink}
                        to={`${routeMatchURL}/${role.id}`}
                        color="inherit"
                        variant="body2"
                      >
                        {role.name}
                      </Typography>
                      {defaultRoleId === role.id && defaultRoleNotation}
                    </Box>
                  </TableCell>
                  <Hide mdDown>
                    <TableCell align="left" padding="normal">
                      {role.description}
                    </TableCell>
                  </Hide>
                  <TableCell align="left" padding="normal">
                    {capitalize(role.type)}
                  </TableCell>
                  <Hide mdDown>
                    <TableCell align="left" padding="normal">
                      {role.timeModified
                        ? DateTime.fromJSDate(role.timeModified.toDate()).toFormat(dateFormatDayMonthYear)
                        : ""}
                    </TableCell>
                  </Hide>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, { value: -1, label: "All" }]}
                  count={filteredRows.length}
                  rowsPerPage={tableState.rowsPerPage}
                  labelRowsPerPage="Per page"
                  page={tableState.page}
                  slotProps={{
                    actions: {
                      previousButton: {
                        "aria-label": "Previous Page",
                      },
                      nextButton: {
                        "aria-label": "Next Page",
                      },
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </FilterContextProvider>
    </Loader>
  );
}
