import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Link, Stack, Typography } from "@mui/material";

import { helpURLs } from "../../../assets/urls";
import { CopyCodeBlock } from "../../../Components/CopyCodeBlock/CopyCodeBlock";
import { type AccessPlatform, Platform, type Severity } from "../../types";
import { getSlaPolicyTime } from "../../utils";
import ConcedefyStep from "./ConcedefyStep";

const defaultConcedefyRole = "roles/viewer";

type props = {
  severity?: Severity | null;
  classification: string;
  platform: string;
  slas: any[];
  gcp: AccessPlatform;
  aws: AccessPlatform;
  concedefyDisabled: boolean;
};

export const AccessStep = ({ classification, platform, severity, slas, gcp, aws, concedefyDisabled }: props) => {
  const { t } = useTranslation("services");
  const time = getSlaPolicyTime(classification, platform, severity?.value, slas, t);

  const concedefyRole = useMemo(
    () => (gcp?.concedefyRole && gcp?.concedefyRole !== "" ? gcp?.concedefyRole : defaultConcedefyRole),
    [gcp?.concedefyRole]
  );

  const goToPermissionsDocs = useCallback(() => {
    window.open(helpURLs.GRANT_GCP_ACCESS);
  }, []);

  const slaText = useCallback(() => {
    if (time === null) {
      return "";
    }
    return `${t("AccessStep.sla1")} ${time} ${severity?.value !== "urgent" ? t("AccessStep.sla2") : ""}`;
  }, [severity?.value, time, t]);

  return (
    <Box sx={{ p: 1 }}>
      <Typography
        variant="h4"
        sx={{
          color: "text.primary",
          mb: 1,
          mt: 6,
        }}
      >
        {slaText()}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: "text.secondary",
          mt: 2,
        }}
      >
        {t("AccessStep.readAccesss1")} <strong>{t("AccessStep.readAccesss2")}</strong> {t("AccessStep.readAccesss3")}
        <br />
        <Link sx={{ cursor: "pointer" }} onClick={goToPermissionsDocs}>
          {t("AccessStep.learnMore")} <OpenInNewIcon sx={{ mb: -0.5 }} fontSize="small" />
        </Link>
      </Typography>
      {Platform.gcp === platform && !concedefyDisabled && (
        <Box sx={{ mt: 5 }}>
          <Typography variant="body2">{t("AccessStep.runCode")}</Typography>
          <Stack
            direction="row"
            sx={{
              alignItems: "flex-start",
            }}
          >
            <CopyCodeBlock
              base="gcloud projects add-iam-policy-binding $GCProjectName --member=group:$concedefyReadOnlyEmail --role=$concedefyRole --condition=None"
              variables={{
                $GCProjectName: { value: gcp.projectName, name: "Project Name" },
                $concedefyReadOnlyEmail: { value: gcp.concedefyEmail, name: "Group" },
                $concedefyRole: { value: concedefyRole, name: "Role" },
              }}
              callbackClick={() => {}}
            />
          </Stack>
        </Box>
      )}
      {Platform.aws === platform && (
        <Box sx={{ mt: 5 }}>
          <ConcedefyStep accountId={aws.projectName} />
        </Box>
      )}
    </Box>
  );
};
