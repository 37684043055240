import { Frequencies, type NodeConfigScheduleTriggerParameters } from "@doitintl/cmp-models";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { DateTime } from "luxon";

type Props = { time: DateTime | null | undefined } & Omit<NodeConfigScheduleTriggerParameters, "startDate" | "time">;

export function ScheduleInfo({ time, frequency, ...rest }: Props) {
  if (!time?.isValid || !frequency) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        p: 1,
        borderRadius: 1,
        backgroundColor: theme.palette.action.hover,
      })}
    >
      <Stack direction="row" spacing={1.5}>
        <ScheduleIcon sx={{ fontSize: 20 }} />
        <Typography variant="body2">{formatSchedule({ time, frequency, ...rest })}</Typography>
      </Stack>
    </Box>
  );
}

type formatScheduleInput = { time: DateTime<true> } & Omit<NodeConfigScheduleTriggerParameters, "startDate" | "time">;

function formatSchedule({ time, frequency, timeZone, customFrequencyAmount, customFrequency }: formatScheduleInput) {
  const dateTime = time.setZone(timeZone, { keepLocalTime: true });

  if (!dateTime.isValid) {
    return "Invalid time or timezone";
  }

  const formattedTime = dateTime.toLocal().toLocaleString(DateTime.TIME_24_SIMPLE);

  if ([Frequencies.Daily, Frequencies.Weekly, Frequencies.Monthly].includes(frequency)) {
    return `Scheduled to send ${frequency.toLowerCase()} at ${formattedTime}`;
  }

  if (frequency === Frequencies.Custom && customFrequency && customFrequencyAmount) {
    const plural = customFrequencyAmount > 1 ? "s" : "";
    const customFreqDisplay = `${customFrequencyAmount} ${customFrequency.toLowerCase()}${plural}`;
    return `Scheduled to send every ${customFreqDisplay} at ${formattedTime}`;
  }

  return `Scheduled to send at ${formattedTime}`;
}
