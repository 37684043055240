import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNewRounded";
import { type ButtonBaseActions, Checkbox, CircularProgress, FormControlLabel, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { loginTexts } from "../../../assets/texts";
import SimpleDialog from "../../../Components/SimpleDialog";
import { useUtmParams } from "../../../utils/utmParams/hooks";
import { constructAuthProviderRoute, getAuthProvidersButtonText } from "../helpers";
import { type SupportedAuthProviders } from "../types";

type propsType = {
  policyAgreementActionRef: React.RefObject<ButtonBaseActions> | null;
  policyAgreementRef: React.RefObject<HTMLInputElement> | null;
  isAuthProvidersPolicyDialogOpen: boolean;
  setIsAuthProvidersPolicyDialogOpen: (value: boolean) => void;
  clickedAuthProvider: SupportedAuthProviders;
  isPolicyAgreed?: boolean;
  loading?: boolean;
  openDialogHandler?: (e: React.MouseEvent) => void;
  onAuthProvidersPolicyCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const AuthProvidersPolicyDialog = ({
  policyAgreementActionRef,
  policyAgreementRef,
  setIsAuthProvidersPolicyDialogOpen,
  isAuthProvidersPolicyDialogOpen,
  clickedAuthProvider,
  isPolicyAgreed,
  loading,
  onAuthProvidersPolicyCheckboxChange,
}: propsType) => {
  const theme = useTheme();
  const utmParams = useUtmParams();
  const loginType = "signup";
  const { t } = useTranslation("login");
  return (
    <SimpleDialog
      title={t("signup.authProviderDialog.title")}
      open={isAuthProvidersPolicyDialogOpen}
      onCancel={() => {
        setIsAuthProvidersPolicyDialogOpen(false);
        // focus on policy agreement checkbox
        policyAgreementActionRef?.current?.focusVisible();
        policyAgreementRef?.current?.focus();
      }}
      onConfirm={() => {
        setIsAuthProvidersPolicyDialogOpen(false);
      }}
      confirmButtonText={getAuthProvidersButtonText(
        {
          loginType,
          provider: clickedAuthProvider,
        },
        t
      )}
      showCancel={true}
      cancelButtonText={t("signup.authProviderDialog.cancel")}
      cancelButtonSx={{ mr: 1 }}
      disableConfirm={!isPolicyAgreed}
      confirmButtonHref={constructAuthProviderRoute({
        provider: clickedAuthProvider,
        utmParams,
      })}
    >
      <Typography sx={{ margin: 3 }}>
        <Typography sx={{ mb: 2 }}>{t("signup.authProviderDialog.content")}</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!isPolicyAgreed}
              value={isPolicyAgreed}
              onChange={onAuthProvidersPolicyCheckboxChange}
              name="policyAgreement"
              size="small"
              action={policyAgreementActionRef}
              inputRef={policyAgreementRef}
            />
          }
          label={
            <Typography>
              {`${t("signup.authProviderDialog.agree")} `}
              <Link
                type="button"
                underline="none"
                href={loginTexts.NAVIGATOR_AGREEMENT_POLICY_URL}
                target="_blank"
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                {t("signup.authProviderDialog.terms")}
                <OpenInNewIcon sx={{ fontSize: 16, marginLeft: 1 }} />
              </Link>
              {loading && (
                <CircularProgress
                  disableShrink
                  color="primary"
                  size={theme.typography.body1.fontSize}
                  thickness={4.8}
                />
              )}
            </Typography>
          }
        />
      </Typography>
    </SimpleDialog>
  );
};
