import { type Customer } from "../../types";
import { formatNumber } from "../../utils/common";
import { type SegmentEventProperties } from "../../utils/useSegmentTrackEvent";
import {
  type CustomInsightProperties,
  type Insight,
  type InsightProperties,
  type InsightsUpsellPageViewedProperties,
  type NoAccessInsight,
} from "./types";
import { calculateTotalSavingsForTimePeriod, totalCustomProblems } from "./utils";

export function sendInsightEvent({
  insight,
  eventName,
  trackEvent,
  isFirstRender,
  savingsPeriod,
  insightTotalSavingsString,
}: {
  insight: Insight;
  eventName: string;
  trackEvent: (eventName: string, properties: SegmentEventProperties) => void;
  isFirstRender: boolean;
  savingsPeriod: string;
  insightTotalSavingsString?: string;
}) {
  const computedCustomProblemsTotal = totalCustomProblems(insight);
  const customProblemsTotal =
    computedCustomProblemsTotal && computedCustomProblemsTotal > 0 ? computedCustomProblemsTotal : undefined;

  const customProblemsUnit = insight.results?.customProblems?.unitLongPlural || undefined;

  const daiilySavings = calculateTotalSavingsForTimePeriod([insight], "daily");

  const eventProperties: InsightProperties = {
    pageType: "Governance",
    pageSubType: "Insight",
    feature: "Insights",
    insightKey: insight.key,
    insightTitle: insight.title,
    isOptimized: !(
      !insight.results?.customProblems?.isOptimized && !insight.results?.potentialDailySavings?.isOptimized
    ),
    isRelevant: insight.results?.isRelevant || false,
    insightProvider: insight.providerId,
    isCustomInsight: !!insight.customInsightAttributes,
    isPageOpenEvent: isFirstRender,
    insightLastUpdated: insight.lastUpdated,
    insightCloudTags: insight.cloudTags,
    insightCategories: insight.categories,
    insightSupportCategory: insight.supportCategory,
    ...(insight.results?.potentialDailySavings?.breakdown !== undefined && {
      insightDailySavings: daiilySavings,
    }),
    ...(insight.userStatusChanges?.status !== undefined && { insightUserStatus: insight.userStatusChanges?.status }),
    ...(insightTotalSavingsString !== undefined && { insightTotalSavingsString }),
    ...(savingsPeriod !== undefined && { insightSavingsPeriod: savingsPeriod }),
    ...(customProblemsTotal !== undefined && { customProblemsTotal }),
    ...(customProblemsUnit && { customProblemsUnit }),
  };

  if (insight.customInsightAttributes) {
    const customProps: CustomInsightProperties = {
      ...eventProperties,
      insightPublished: insight.customInsightAttributes.published,
      insightPublishingUser: insight.customInsightAttributes.publishingUserEmail,
      insightIsPersonalized: insight.customInsightAttributes.showPublishingUser,
    };

    trackEvent(eventName, customProps);
  } else {
    trackEvent(eventName, eventProperties);
  }
}

export interface SendInsightsUpsellEventArgs {
  trackEvent: (eventName: string, properties: InsightsUpsellPageViewedProperties) => void;
  noAccessResults: NoAccessInsight[];
  customer: Customer;
  dciCost: number;
  potentialSavings: number;
  customerCurrencySymbol: string;
  showCustom: boolean;
}

export function sendInsightsUpsellEvent(
  eventName: string,
  {
    trackEvent,
    noAccessResults,
    customer,
    dciCost,
    potentialSavings,
    customerCurrencySymbol,
    showCustom,
  }: SendInsightsUpsellEventArgs
) {
  if (!noAccessResults || !customer) return;

  const totalYearlySavings = `$${customerCurrencySymbol}${formatNumber(potentialSavings, 0)}`;

  const monthlyCloudSpend = customer.customerSegment?.monthlyCloudSpend || 0;

  let insightsHighestSavings = 0;
  if (noAccessResults.length > 0) {
    insightsHighestSavings = Math.max(
      ...noAccessResults.map((insight) => insight.potentialDailySavings?.value || 0 * 365)
    );
  }

  const actionableInsights = noAccessResults.filter(
    (insight) => insight.isRelevant && insight.potentialDailySavings?.isOptimized === false
  ).length;

  const optimizedInsights = noAccessResults.filter(
    (insight) => insight.potentialDailySavings?.isOptimized === true
  ).length;

  const irrelevantInsights = noAccessResults.filter(
    (insight) => !insight.isRelevant && insight.potentialDailySavings?.isOptimized === false
  ).length;

  trackEvent(eventName, {
    pageType: "Governance",
    feature: "Insights",
    totalYearlySavings,
    dciTotalCost: dciCost,
    monthlyCloudSpend,
    insightsTotalSavings: potentialSavings,
    insightsHighestSavings,
    actionableInsights,
    optimizedInsights,
    irrelevantInsights,
    hasCustomInsights: showCustom,
  } as InsightsUpsellPageViewedProperties);
}
