import { useCallback, useState } from "react";

import { useAvaContext, useTrpcContext } from "@doitintl/ava-components";
import { type Renderer } from "@doitintl/cmp-models";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type createReportParams, type createReportPayload } from "../../Pages/CloudAnalytics/generateReport/types";
import { type QueryResponse } from "../../Pages/CloudAnalytics/report/ReportQuery";
import { verifyAndUpdateReportConfig } from "../Ava/reportDB";
import { type AvaQueryRequest, type AvaWidgetData } from "../Ava/types";
import { constructReportPayloadFromAvaResponse, constructWidgetFromAvaResponse } from "../Ava/utils";
import { useGenerateReportWithCustomer } from "../hooks/useGenerateReport";
import { useErrorSnackbar } from "../SharedSnackbar/SharedSnackbar.context";

export const useAvaReports = () => {
  const { addReportLinkAndChartTypeToAvaConfig } = useTrpcContext();
  const { conversationId, dashboardId } = useAvaContext();

  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const { userOrganization, customer } = useCustomerContext({ allowNull: true });
  const [widgetData, setWidgetData] = useState<AvaWidgetData>();
  const [reportCreationLoading, setReportCreationLoading] = useState<boolean>(false);

  const showErrorSnackbar = useErrorSnackbar();

  const generateReport = useGenerateReportWithCustomer(
    customer?.ref ?? null,
    userOrganization?.snapshot.ref ? userOrganization?.snapshot.ref : null
  );

  const createReportCallback = useCallback(
    async (payload: createReportPayload | createReportParams, widgetKey: string): Promise<string> => {
      let reportId = "";
      let reportLink = "";
      if (widgetData?.draftReportLink) {
        try {
          const parts = widgetData.draftReportLink.split("/");
          const reportIdWQuery = parts[parts.indexOf("reports") + 1];
          const id = reportIdWQuery.split("?")[0];
          const existsAndUpToDate = await verifyAndUpdateReportConfig(id, payload);
          if (existsAndUpToDate) {
            return widgetData.draftReportLink;
          }
        } catch (e) {
          showErrorSnackbar("Sorry, Unable to create a report, please try again...");
          return "";
        }
      }
      try {
        reportId = await generateReport(payload, true);
      } catch (e) {
        showErrorSnackbar("Sorry, Unable to create a report, please try again...");
        return "";
      }
      if (reportId && widgetData) {
        if (!customer) {
          return reportLink;
        }

        reportLink = `/customers/${customer?.id}/analytics/reports/${reportId}?run-on-open=true`;
        setWidgetData({
          ...widgetData,
          draftReportLink: reportLink,
        });
      }
      if (!conversationId) {
        return reportLink;
      }
      try {
        await addReportLinkAndChartTypeToAvaConfig({
          conversationId,
          messageId: widgetKey,
          reportLink,
        });
      } catch (e) {
        showErrorSnackbar("Sorry, Unable to save the report link, please try again...");
      }
      return reportLink;
    },
    [generateReport, conversationId, addReportLinkAndChartTypeToAvaConfig, showErrorSnackbar, customer, widgetData]
  );

  const handleReportCreate = useCallback(
    async (widgetKey: string) => {
      if (!reportCreationLoading) {
        if (!widgetData) {
          return;
        }
        setReportCreationLoading(true);
        const newTab = window.open("", "_blank");
        let link = "";
        try {
          link = await createReportCallback(widgetData.reportCreationPayload, widgetKey);
        } finally {
          if (link && newTab) {
            newTab.location.href = dashboardId ? `${link}&dashboardId=${dashboardId}` : link;
          } else if (newTab) {
            // close the tab if no link was returned
            newTab.close();
          }
          setReportCreationLoading(false);
        }
      }
    },
    [widgetData, createReportCallback, dashboardId, reportCreationLoading]
  );
  const onWidgetRendererChange = useCallback(
    async (rendererOption: Renderer, widgetKey: string) => {
      if (!widgetData) {
        return;
      }
      const widget = widgetData.widget;
      const reportPayload = widgetData.reportCreationPayload;
      if (widget && widget.config.renderer !== rendererOption) {
        const updatedWidget = { ...widget, config: { ...widget.config, renderer: rendererOption } };
        if (reportPayload.config?.renderer) {
          reportPayload.config.renderer = rendererOption;
        }

        setWidgetData({
          ...widgetData,
          widget: updatedWidget,
          height: 300,
          reportCreationPayload: reportPayload,
        });
      }
      if (!conversationId) {
        return;
      }
      try {
        await addReportLinkAndChartTypeToAvaConfig({
          conversationId,
          messageId: widgetKey,
          chartType: rendererOption,
        });
      } catch (e) {
        showErrorSnackbar("Sorry, unable to change the chart type, please try again later...");
      }
    },
    [widgetData, conversationId, addReportLinkAndChartTypeToAvaConfig, showErrorSnackbar]
  );

  const onAvaReportResponse = useCallback(
    (report: string) => {
      const regex = /{.*}/s;
      const match = regex.exec(report);
      const extractedContent = match ? match[0].replace(/\\"/g, '"') : "";

      type Report = {
        reportConfig: AvaQueryRequest;
        reportResult: QueryResponse;
        reportLink: string;
      };

      let parsedReport: Report;
      try {
        parsedReport = JSON.parse(extractedContent) as Report;
      } catch (e) {
        // catch error to avoid crashing the entire client for bad json. instead - simply to not show report widget
        return;
      }
      const queryRequest: AvaQueryRequest = parsedReport.reportConfig;
      const queryResponse: QueryResponse = parsedReport.reportResult;
      if (!customer || !queryRequest || !queryResponse) {
        return;
      }
      const w = constructWidgetFromAvaResponse(queryRequest, queryResponse, customer, currentUser.email);
      const draftReport = constructReportPayloadFromAvaResponse(queryRequest, customer);
      const widgetData = {
        widget: w,
        height: 300,
        draftReportLink: parsedReport.reportLink ?? "",
        reportCreationPayload: draftReport,
      };

      setWidgetData(widgetData);
    },
    [currentUser.email, customer]
  );

  return { handleReportCreate, onAvaReportResponse, onWidgetRendererChange, widgetData };
};
