import { Fragment } from "react";

import { Link } from "react-router-dom";
import { AssetTypeOffice365, type Office365AssetModel } from "@doitintl/cmp-models";
import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";
import { type Theme } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { amber } from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { createStyles, makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { type Asset } from "../../../types";
import { sanitizeDate } from "../../../utils/common";
import Office365AssetCard from "../Cards/Office365AssetCard";
import { type AssetsTabPropsOuterProps } from "../types";
import NoAssets from "./components/NoAssets";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsArea: {
      padding: 0,
      marginTop: 5,
    },
    cardActions: {
      paddingTop: theme.spacing(2),
      justifyContent: "flex-end",
    },
    warningIcon: {
      color: amber[500],
    },
  })
);

type Props = AssetsTabPropsOuterProps<Office365AssetModel>;

const Office365AssetsTab = ({ assets, contracts, onRemoveAsset, highlight, onAssignToEntity, onAddTag }: Props) => {
  const classes = useStyles();
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { entities } = useCustomerContext();
  const routeMatchURL = useRouteMatchURL();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });

  if (assets === undefined) {
    return null;
  }

  if (assets.length === 0) {
    return (
      <NoAssets assetType={AssetTypeOffice365}>
        {userRoles.assetsManager ? (
          <Button variant="contained" component={Link} to={`${routeMatchURL}/create`}>
            New subscription
          </Button>
        ) : (
          <></>
        )}
      </NoAssets>
    );
  }

  const now = sanitizeDate(DateTime.utc());

  const getEntity = (asset: Asset<Office365AssetModel>) => entities.find((e) => asset.data.entity?.id === e.id);

  const getContract = (asset: Asset<Office365AssetModel>) => {
    const contract = (contracts ?? []).find((contractItem) => {
      if (!contractItem.entity || !asset.data.entity) {
        return false;
      }
      if (contractItem.entity.id !== asset.data.entity.id) {
        return false;
      }
      if (!contractItem.endDate) {
        return true;
      }
      const endDate = sanitizeDate(DateTime.fromJSDate(contractItem.endDate.toDate()));
      return now <= endDate;
    });

    return contract;
  };

  return (
    <Fragment>
      <CardActions className={classes.cardActions}>
        {userRoles?.assetsManager && [
          <Button
            key="add-office-365-subscription"
            color="primary"
            variant="contained"
            size="small"
            aria-label="Add New Subscription"
            component={Link}
            to={`${routeMatchURL}/create`}
            data-cy="office365AssetsNewButton"
          >
            New subscription
          </Button>,
        ]}
      </CardActions>
      <CardContent className={classes.cardsArea}>
        <Grid container spacing={2} direction="row">
          {assets.map((asset) => {
            const highlightAsset = asset.id === highlight;
            const unassignedAction =
              isDoitEmployee && !asset.data.entity ? (
                <Tooltip title="Asset is not assigned to an entity">
                  <IconButton onClick={onAssignToEntity(asset)} size="large">
                    <ErrorIcon className={classes.warningIcon} />
                  </IconButton>
                </Tooltip>
              ) : undefined;

            const entity = getEntity(asset);
            const contract = getContract(asset);

            return (
              <Grid
                key={asset.ref.path}
                size={{
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 4,
                  xl: 4,
                }}
              >
                <Office365AssetCard
                  asset={asset}
                  entity={entity}
                  contract={contract}
                  unassignedAction={unassignedAction}
                  highlight={highlightAsset}
                  onAssignToEntity={onAssignToEntity(asset)}
                  onRemoveAsset={onRemoveAsset(asset)}
                  onAddTag={() => {
                    onAddTag(asset);
                  }}
                  widget={false}
                  isCustomizeMode={false}
                  fallbackComponent={null}
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default Office365AssetsTab;
