import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router";
import { CloudFlowNodeType } from "@doitintl/cmp-models";
import {
  addEdge,
  type Connection,
  type Edge,
  type EdgeMouseHandler,
  getOutgoers,
  type Node,
  type OnConnect,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import { v4 as uuidv4 } from "uuid";

import { useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import { CHOICE_OPTIONS } from "../../Dialog/ManageIfNodeDialog";
import {
  useCreateOrUpdateNode,
  useDeleteNode,
  usePublishCloudflow,
  useTriggerCloudflow,
  useUnpublishCloudflow,
  useUpdateCloudflow,
  useUpdateCloudflowNodes,
} from "../../hooks";
import { type CHANGE_TRIGGER_OPTIONS, type NodeEdgeManagerConfig, NodeOperationType, type RFNode } from "../../types";
import { type BaseCloudflowHit } from "../algolia/types";
import { useCloudflowNodes } from "../hooks/useCloudflowNodes";
import utils from "../utils/conditionNodeUtils";
import { applyGraphLayout } from "../utils/layoutUtils";
import { mergeCloudflowNodes } from "../utils/nodeTransformUtils";
import {
  createTransitionPayload,
  findNodeById,
  getCreateActionNodePayload,
  getIncomerNode,
  getTreeOfOutgoers,
  getTriggerNodePayload,
  getUpdateActionNodePayload,
  initializeNode,
  isGhostNode,
} from "../utils/nodeUtils";
import { getTriggerByOption } from "../utils/triggerUtils";

const NodeEdgeManagerContext = createContext<NodeEdgeManagerConfig>({} as NodeEdgeManagerConfig);
export const useNodeEdgeManager = () => {
  const context = useContext(NodeEdgeManagerContext);
  if (!context) {
    throw new Error("useNodeEdgeManager must be used within a NodeEdgeManagerProvider");
  }
  return context;
};

type Props = {
  setIsTriggerDialogVisible: (show: boolean) => void;
  isTriggerDialogVisible: boolean;
  children: ReactNode;
};

export const NodeEdgeManagerProvider = ({ children, setIsTriggerDialogVisible, isTriggerDialogVisible }: Props) => {
  const { flowId, customerId } = useParams<{ customerId: string; flowId: string }>();
  const [nodes, setNodes, onNodesChange] = useNodesState<Node<RFNode>>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const { cloudflowNodes, cloudflowEdges, cloudflowNodesLoading } = useCloudflowNodes(flowId);

  const [createOrUpdate, createOrUpdateLoading] = useCreateOrUpdateNode();
  const [remove, removeLoading] = useDeleteNode();
  const [updateNodes, updateNodesLoading] = useUpdateCloudflowNodes();
  const [updateCloudflow, updateCloudflowLoading] = useUpdateCloudflow();
  const [publishCloudflow, publishCloudflowLoading] = usePublishCloudflow();
  const [unpublishCloudflow, unpublishCloudflowLoading] = useUnpublishCloudflow();
  const [triggerCloudflow, triggerCloudflowLoading] = useTriggerCloudflow();

  const httpOperationLoading =
    cloudflowNodesLoading ||
    createOrUpdateLoading ||
    removeLoading ||
    updateNodesLoading ||
    updateCloudflowLoading ||
    publishCloudflowLoading ||
    unpublishCloudflowLoading ||
    triggerCloudflowLoading;

  const activeNode: Node<RFNode> | undefined = nodes.find((node) => node.selected) as Node<RFNode>;
  const { getEdges, getNodes } = useReactFlow<Node<RFNode>>();
  const [focusedNodeId, setFocusedNodeId] = useState<string>();
  const showSuccess = useSuccessSnackbar(3);
  const [showModal, setShowModal] = useState(false);
  const [algoliaOperationType, setalgoliaOperationType] = useState<NodeOperationType>(NodeOperationType.CREATE);
  const [manageIfActionsId, setManageIfActionsId] = useState<string>("");
  const [deleteIfNodeId, setDeleteIfNodeId] = useState<string>("");
  const [interactionEnabled, setInteractionEnabled] = useState<boolean>(true);
  const createNodeLocks = useRef(new Map());

  const selectNode = useCallback(
    (nodeId: string | null) => {
      setNodes((prev) =>
        prev.map((node) => ({
          ...node,
          selected: nodeId === node.id,
        }))
      );
    },
    [setNodes]
  );

  const applyLayoutAndSetState = useCallback(
    (nodes: Node<RFNode<CloudFlowNodeType>>[], edges: Edge[]) => {
      const { positionedNodes, positionedEdges } = applyGraphLayout(nodes, edges);
      setNodes(positionedNodes);
      setEdges(positionedEdges);
    },
    [setEdges, setNodes]
  );

  const handleEditNode = useCallback(
    (node: Node<RFNode<CloudFlowNodeType>>) => {
      selectNode(node.id);
    },
    [selectNode]
  );

  const handleDeleteNode = useCallback(
    async (nodeId: string) => {
      selectNode(null);
      const nodeToDelete = findNodeById(getNodes(), nodeId);
      if (!nodeToDelete) return;

      if (nodeToDelete.type === CloudFlowNodeType.CONDITION) {
        setDeleteIfNodeId(nodeId);
        return;
      }
      try {
        await remove(customerId, flowId, nodeId);
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [selectNode, getNodes, remove, customerId, flowId]
  );

  const getLabelForTargetNode = useCallback(
    (targetNodeId: string): string | undefined => {
      const targetNodeEdge = getEdges().find((edge) => edge.target === targetNodeId);
      return targetNodeEdge?.data?.label as string;
    },
    [getEdges]
  );

  const addNewCloudflowNode = useCallback(
    async (nodeType: CloudFlowNodeType, nodeId: string, targetNodeId: string) => {
      const targetedNode = findNodeById(getNodes(), targetNodeId);
      if (!targetedNode) {
        return;
      }
      const incomer = getIncomerNode(targetedNode, getNodes(), getEdges());
      const targetNodeLabel =
        incomer.type === CloudFlowNodeType.CONDITION ? getLabelForTargetNode(targetNodeId) : undefined;
      const newNode = initializeNode(nodeType, nodeId);
      const newNodeRequestData = createTransitionPayload(incomer.id, targetNodeLabel, newNode, targetedNode);

      try {
        await createOrUpdate(customerId, flowId, newNodeRequestData);
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [getNodes, getEdges, getLabelForTargetNode, createOrUpdate, customerId, flowId]
  );

  const addNewTriggerNode = useCallback(
    async (nodeType: CloudFlowNodeType, nodeId: string) => {
      const triggerNode = findNodeById(getNodes(), nodeId);
      if (!triggerNode) {
        return;
      }
      const { triggerNodeWithTransitions, updateNodesPayload } = getTriggerNodePayload(triggerNode, nodeType, flowId);
      const updatedNodes = getNodes().map((node: Node<RFNode>) =>
        node.id === nodeId
          ? {
              ...triggerNodeWithTransitions,
              data: {
                ...triggerNodeWithTransitions.data,
                onEditNode: () => {
                  handleEditNode(triggerNodeWithTransitions);
                },
                onDeleteNode: () => handleDeleteNode(nodeId),
              },
              selected: true,
            }
          : node
      );
      applyLayoutAndSetState(updatedNodes, getEdges());
      try {
        await updateNodes(customerId, updateNodesPayload);
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [applyLayoutAndSetState, customerId, flowId, getEdges, getNodes, handleDeleteNode, handleEditNode, updateNodes]
  );

  const handleAddNode = useCallback(
    async (nodeType: CloudFlowNodeType, nodeId: string) => {
      if (nodeType === CloudFlowNodeType.TRIGGER || nodeType === CloudFlowNodeType.MANUAL_TRIGGER) {
        await addNewTriggerNode(nodeType, nodeId);
        return;
      }

      if (nodeType === CloudFlowNodeType.ACTION) {
        setFocusedNodeId(nodeId);
        setalgoliaOperationType(NodeOperationType.CREATE);
        setShowModal(true);
        return;
      }

      const currentTargetNode = findNodeById(getNodes(), nodeId);
      if (!currentTargetNode) {
        return;
      }

      if (nodeType === CloudFlowNodeType.CONDITION && !isGhostNode(currentTargetNode)) {
        setManageIfActionsId(nodeId);
        return;
      }
      const newNodeId = uuidv4();
      await addNewCloudflowNode(nodeType, newNodeId, nodeId);
    },
    [addNewCloudflowNode, addNewTriggerNode, getNodes]
  );

  const restoreNodeToInitialState = useCallback(
    (nodeType: CloudFlowNodeType, nodeId: string) => {
      const reinitializedNode = initializeNode(nodeType, nodeId);
      const updatedNodes = getNodes().map((node) => {
        if (node.id === reinitializedNode.id) {
          return {
            ...reinitializedNode,
            data: {
              ...reinitializedNode.data,
              nodeData: {
                ...reinitializedNode.data.nodeData,
                transitions: node.data.nodeData.transitions,
                display: node.data.nodeData.display,
              },
              onAddNode: handleAddNode,
              onDeleteNode: () => handleDeleteNode(nodeId),
              onEditNode: () => {
                handleEditNode(reinitializedNode);
              },
            },
          };
        }
        return node;
      });

      applyLayoutAndSetState(updatedNodes, getEdges());
    },
    [applyLayoutAndSetState, getEdges, getNodes, handleAddNode, handleDeleteNode, handleEditNode]
  );

  const onConfirmDeleteIfNode = useCallback(async () => {
    selectNode(null);
    const nodeToDelete = findNodeById(getNodes(), deleteIfNodeId);
    if (!nodeToDelete) return;
    try {
      const { nodes: outnodes } = getTreeOfOutgoers(getNodes(), getEdges(), deleteIfNodeId);
      const updateNodesPayload = utils.handleDeleteIfNode(flowId, nodeToDelete, outnodes);
      await updateNodes(customerId, updateNodesPayload);
    } catch (error) {
      consoleErrorWithSentry(error);
    }
    setDeleteIfNodeId("");
    showSuccess("Step successfully deleted");
  }, [selectNode, getNodes, deleteIfNodeId, showSuccess, getEdges, flowId, updateNodes, customerId]);

  const handleEdgeClick = useCallback(
    async (edgeData: Edge) => {
      setEdges((prevEdges: Edge[]) =>
        prevEdges.map((edge) => {
          if (edge.id === edgeData.id) {
            return {
              ...edge,
              data: {
                ...edge.data,
                handleAddNode,
                setInteractionEnabled,
              },
            };
          }
          return edge;
        })
      );
    },
    [setEdges, handleAddNode]
  );

  useEffect(() => {
    const localStateNodes = getNodes();
    const uiEnrichedNodes = mergeCloudflowNodes(
      cloudflowNodes,
      localStateNodes,
      handleAddNode,
      handleEditNode,
      handleDeleteNode
    );
    applyLayoutAndSetState(uiEnrichedNodes, cloudflowEdges);
    createNodeLocks.current.clear();
  }, [
    cloudflowNodes,
    cloudflowEdges,
    handleAddNode,
    handleDeleteNode,
    setNodes,
    setEdges,
    handleEditNode,
    applyLayoutAndSetState,
    getNodes,
  ]);

  useEffect(() => {
    setNodes((prevNodes) =>
      prevNodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          httpOperationLoading,
          nodeData: {
            ...node.data.nodeData,
          },
        },
      }))
    );
  }, [httpOperationLoading, setNodes]);

  const handleDeleteActions = useCallback(
    async (manageIfActionsId: string) => {
      const currentNode = findNodeById(getNodes(), manageIfActionsId);
      if (!currentNode) {
        return;
      }
      const { nodes: outnodes } = getTreeOfOutgoers(getNodes(), getEdges(), manageIfActionsId);
      const incomer = getIncomerNode(currentNode, getNodes(), getEdges());
      const updateNodesPayload = utils.handleDeleteActions(flowId, currentNode, incomer, outnodes);
      try {
        await updateNodes(customerId, updateNodesPayload);
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [customerId, flowId, getEdges, getNodes, updateNodes]
  );

  const handleMoveActions = useCallback(
    async (manageIfActionsId: string, moveToTrue: boolean) => {
      const currenTargetNode = findNodeById(getNodes(), manageIfActionsId);
      if (!currenTargetNode) {
        return;
      }
      try {
        const incomer = getIncomerNode(currenTargetNode, getNodes(), getEdges());
        const updateNodesPayload = utils.handleMoveActions(flowId, currenTargetNode, moveToTrue, incomer);
        await updateNodes(customerId, updateNodesPayload);
      } catch (error) {
        consoleErrorWithSentry(error);
      }
      selectNode(currenTargetNode.id);
    },
    [customerId, flowId, getEdges, getNodes, selectNode, updateNodes]
  );

  const onSaveManageIfActionsDialog = useCallback(
    async (choice: string) => {
      const currentNode = findNodeById(getNodes(), manageIfActionsId);

      if (!currentNode) {
        return;
      }

      const { type: nodeType, id: nodeId, position } = currentNode;

      if (!nodeType || !nodeId || !position) {
        return;
      }

      switch (choice) {
        case CHOICE_OPTIONS.MOVE_ACTIONS_TO_TRUE:
          await handleMoveActions(manageIfActionsId, true);
          break;
        case CHOICE_OPTIONS.MOVE_ACTIONS_TO_FALSE:
          await handleMoveActions(manageIfActionsId, false);
          break;
        case CHOICE_OPTIONS.DELETE_ACTIONS:
          await handleDeleteActions(manageIfActionsId);
          break;
      }

      setManageIfActionsId("");
    },
    [getNodes, handleDeleteActions, handleMoveActions, manageIfActionsId]
  );

  const onEdgeClick: EdgeMouseHandler<Edge> = useCallback(
    (_event, edgeData) => {
      const sourceNode = findNodeById(getNodes(), edgeData.source);
      const targetNode = findNodeById(getNodes(), edgeData.target);
      if (!createOrUpdateLoading && sourceNode && targetNode) {
        handleEdgeClick(edgeData);
      }
    },
    [getNodes, handleEdgeClick, createOrUpdateLoading]
  );
  const handleNodeOperation = useCallback(
    async (nodeId: string, item: BaseCloudflowHit, operation: NodeOperationType) => {
      const currentNode = findNodeById(getNodes(), nodeId);
      if (!currentNode) {
        return;
      }

      const parentNode = getIncomerNode(currentNode, getNodes(), getEdges());

      const targetNode = getOutgoers(currentNode, getNodes(), getEdges())[0];

      /*
      Prevent multiple requests for the same node
      TODO: Investigate why this callback is being called two times
      */
      if (createNodeLocks.current.has(nodeId)) {
        return;
      }

      createNodeLocks.current.set(nodeId, nodeId);

      const incomingEdgeLabel =
        parentNode.type === CloudFlowNodeType.CONDITION ? getLabelForTargetNode(nodeId) : undefined;

      setShowModal(false);

      try {
        switch (operation) {
          case NodeOperationType.CREATE:
            {
              const newNodeRequestData = getCreateActionNodePayload(item, parentNode, incomingEdgeLabel, currentNode);
              await createOrUpdate(customerId, flowId, newNodeRequestData);
            }
            break;
          case NodeOperationType.UPDATE: {
            const newNodeRequestData = getUpdateActionNodePayload(
              nodeId,
              currentNode.data.nodeData.transitions,
              item,
              targetNode
            );
            const updatedNodes = getNodes().map((node) => {
              if (node.id === nodeId) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    nodeData: {
                      ...node.data.nodeData,
                      ...newNodeRequestData.node,
                    },
                  },
                };
              }
              return node;
            });

            applyLayoutAndSetState(updatedNodes, getEdges());

            await createOrUpdate(customerId, flowId, newNodeRequestData);
            break;
          }
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [getNodes, getEdges, getLabelForTargetNode, createOrUpdate, customerId, flowId, applyLayoutAndSetState]
  );

  const onConfirmChangeTrigger = useCallback(
    async (choice: CHANGE_TRIGGER_OPTIONS) => {
      const targetCloudFlowNodeType: CloudFlowNodeType.TRIGGER | CloudFlowNodeType.MANUAL_TRIGGER | undefined =
        getTriggerByOption(choice);

      if (!targetCloudFlowNodeType || !activeNode) {
        return;
      }

      const { triggerNodeWithTransitions, updateNodesPayload } = getTriggerNodePayload(
        activeNode,
        targetCloudFlowNodeType,
        flowId
      );

      const updatedNode: Node<RFNode> = {
        ...triggerNodeWithTransitions,
        data: {
          ...triggerNodeWithTransitions.data,
          touched: true,
        },
      };

      const updatedNodes: Node<RFNode>[] = getNodes().map((node: Node<RFNode>) =>
        node.id === activeNode.id ? updatedNode : node
      );

      applyLayoutAndSetState(updatedNodes, getEdges());

      setIsTriggerDialogVisible(false);

      await updateNodes(customerId, updateNodesPayload);
    },
    [activeNode, flowId, getNodes, applyLayoutAndSetState, getEdges, setIsTriggerDialogVisible, updateNodes, customerId]
  );

  const closeChangeTriggerDialog = useCallback(() => {
    setIsTriggerDialogVisible(false);
  }, [setIsTriggerDialogVisible]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const onConnect: OnConnect = useCallback(
    (params: Connection | Edge) => {
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  const onChangeActiveNode = useCallback(
    (nodeType: CloudFlowNodeType, nodeId: string) => {
      selectNode(null);
      setFocusedNodeId(nodeId);
      if (nodeType === CloudFlowNodeType.ACTION) {
        setalgoliaOperationType(NodeOperationType.UPDATE);
        setShowModal(true);
        return;
      }
      restoreNodeToInitialState(nodeType, nodeId);
    },
    [selectNode, restoreNodeToInitialState]
  );

  const onChangeTriggerType = useCallback(() => {
    setIsTriggerDialogVisible(true);
  }, [setIsTriggerDialogVisible]);

  const data = useMemo(
    () => ({
      nodes,
      edges,
      getEdges,
      getNodes,
      onChangeTriggerType,
      focusedNodeId,
      handleEditNode,
      onConnect,
      onEdgeClick,
      showModal,
      closeModal,
      isTriggerDialogVisible,
      closeChangeTriggerDialog,
      onConfirmDeleteIfNode,
      deleteIfNodeId,
      setDeleteIfNodeId,
      manageIfActionsId,
      setManageIfActionsId,
      onSaveManageIfActionsDialog,
      handleAddNode,
      onChangeActiveNode,
      selectNode,
      setNodes,
      httpOperationLoading,
      updateNodes,
      interactionEnabled,
      onConfirmChangeTrigger,
      algoliaOperationType,
      activeNode,
      handleNodeOperation,
      updateCloudflow,
      publishCloudflow,
      unpublishCloudflow,
      triggerCloudflow,
      onNodesChange,
      onEdgesChange,
    }),
    [
      onChangeTriggerType,
      nodes,
      edges,
      getEdges,
      getNodes,
      focusedNodeId,
      onNodesChange,
      onEdgesChange,
      handleEditNode,
      onConnect,
      onEdgeClick,
      showModal,
      closeModal,
      isTriggerDialogVisible,
      closeChangeTriggerDialog,
      onConfirmDeleteIfNode,
      deleteIfNodeId,
      manageIfActionsId,
      onSaveManageIfActionsDialog,
      handleAddNode,
      onChangeActiveNode,
      selectNode,
      setNodes,
      httpOperationLoading,
      updateNodes,
      interactionEnabled,
      onConfirmChangeTrigger,
      algoliaOperationType,
      activeNode,
      handleNodeOperation,
      updateCloudflow,
      publishCloudflow,
      unpublishCloudflow,
      triggerCloudflow,
    ]
  );

  return <NodeEdgeManagerContext.Provider value={data}>{children}</NodeEdgeManagerContext.Provider>;
};
