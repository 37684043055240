import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Redirect, useHistory, useParams } from "react-router-dom";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import UploadIcon from "@mui/icons-material/Upload";
import { Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { datahubTxt } from "../../../assets/texts/DataHub/datahub";
import DeleteDialog from "../../../Components/DeleteDialog";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import useGenerateReport from "../../../Components/hooks/useGenerateReport";
import { CircularProgressLoader } from "../../../Components/Loader";
import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useDataHubContext } from "../../../Context/DataHubContext";
import { TimestampFromDate } from "../../../utils/firebase";
import ImportCSVStepper from "../CSVImport/ImportCSVStepper";
import ImportDataDialog from "../ImportDataDialog";
import {
  buildConfig,
  deleteProcessingMinutes,
  isAnySelectedUpdatedWithinLastXMinutes,
  metadataProcessingMinutes,
} from "../utils";
import { filterColumns, headers } from "./DatasetDetailsColumns";
import DatasetDetailsRow from "./DatasetDetailsRow";

export type BatchItem = {
  batch: string;
  origin: string;
  records: number;
  createdBy: string;
  createdAt: string;
  eventsSubmitted: number;
  eventsProcessed: number;
  eventsDeleted: number;
};

const NoBatches = ({ onButtonClick }: { onButtonClick: () => void }) => (
  <Stack
    sx={{
      alignItems: "center",
      borderTop: "1px solid",
      borderColor: "divider",
    }}
  >
    <Stack
      sx={{
        alignItems: "center",
        pt: 10,
        maxWidth: 650,
      }}
    >
      <Typography variant="h1">{datahubTxt.DATASET_DETAILS_EMPTY_TITLE}</Typography>
      <Typography
        variant="body1"
        sx={{
          mt: 2,
          mb: 4,
          textAlign: "center",
        }}
      >
        {datahubTxt.DATASET_DETAILS_EMPTY_DESC}
      </Typography>
      <Button variant="contained" startIcon={<UploadIcon />} onClick={onButtonClick}>
        {datahubTxt.IMPORT_DATA}
      </Button>
    </Stack>
  </Stack>
);

const ToolbarTitle = ({ datasetName, onBack }: { datasetName: string; onBack: () => void }) => (
  <Stack direction="row" spacing={2}>
    <IconButton aria-label="Back" size="large" onClick={onBack} data-cy="back-icon">
      <BackIcon fontSize="medium" color="action" />
    </IconButton>
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="subtitle1">{datasetName}</Typography>
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
        }}
      >
        {datahubTxt.DATASET_DETAILS}
      </Typography>
    </Box>
  </Stack>
);

const DatasetDetails = () => {
  const history = useHistory();
  const customerId = useCustomerId();
  const { datasetName } = useParams<{ customerId: string; datasetName: string }>();
  const {
    datahubItems,
    loading: loadingDatasets,
    datasetBatches,
    loadingDatasetBatches,
    fetchDatasetBatches,
    deleteDatasetBatches,
  } = useDataHubContext();
  const generateReport = useGenerateReport();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const [selected, setSelected] = useState<BatchItem[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openImportDataDialog, setOpenImportDataDialog] = useState(false);
  const [showImportStepper, setShowImportStepper] = useState(false);
  const [loadingOpenInReports, setLoadingOpenInReports] = useState(false);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const hasBatches = datasetBatches.length !== 0;
  const dataset = datahubItems.find((item) => item.dataset === datasetName);
  const init = useRef(false);

  useEffect(() => {
    init.current = true;
    fetchDatasetBatches(datasetName, false);
  }, [datasetName, fetchDatasetBatches]);

  const handleOpenDeleteDialog = (batch: BatchItem) => {
    setSelected([batch]);
    setOpenDeleteDialog(true);
  };

  const deleteBatchDisabled = useMemo(
    () => isAnySelectedUpdatedWithinLastXMinutes(selected, "createdAt", deleteProcessingMinutes),
    [selected]
  );

  const openInReportsLimited = useMemo(
    () => isAnySelectedUpdatedWithinLastXMinutes(selected, "createdAt", metadataProcessingMinutes),
    [selected]
  );

  const deleteTooltipTitle = useMemo(() => {
    if (selected.length === 0) {
      return datahubTxt.DELETE_DISABLED_NO_SELECT_BATCH;
    } else if (deleteBatchDisabled) {
      return datahubTxt.DATASET_RECENTLY_INGESTED;
    } else {
      return datahubTxt.DELETE_TITLE;
    }
  }, [deleteBatchDisabled, selected.length]);

  const openInReportsTooltipTitle = useMemo(() => {
    if (dataset?.records === 0) {
      return datahubTxt.DATASET_NO_RECORDS;
    } else if (openInReportsLimited) {
      return datahubTxt.BATCH_REPORT_DATA_UNAVAILABLE;
    } else {
      return datahubTxt.EXPLORE_DATASETS_REPORT;
    }
  }, [dataset?.records, openInReportsLimited]);

  const handleDeleteBatches = useCallback(async () => {
    const selectedWithinLast90Minutes = isAnySelectedUpdatedWithinLastXMinutes(
      selected,
      "createdAt",
      deleteProcessingMinutes
    );
    if (selectedWithinLast90Minutes) {
      errorSnackbar(datahubTxt.BATCH_RECENTLY_INGESTED);
      return;
    }

    const res = await deleteDatasetBatches(
      datasetName,
      selected.map((item) => item.batch)
    );
    if (res.status === 200) {
      successSnackbar(datahubTxt.BATCHES_DELETED_SUCCESSFULLY);
      setSelected([]);
    } else if (res?.response?.data?.error === datahubTxt.BATCH_RECENTLY_INGESTED) {
      errorSnackbar(datahubTxt.BATCH_RECENTLY_INGESTED);
    } else {
      errorSnackbar(datahubTxt.BATCHES_FAILED_DELETE);
    }
  }, [datasetName, deleteDatasetBatches, errorSnackbar, selected, successSnackbar]);

  const handleOpenInReports = useCallback(async () => {
    setLoadingOpenInReports(true);
    const config = buildConfig([datasetName]);
    const newReportId = await generateReport(
      {
        name: `${datahubTxt.EXPLORE} ${datasetName}`,
        config,
        draft: true,
        expireBy: TimestampFromDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)),
      },
      true
    );
    setLoadingOpenInReports(false);
    history.push(`/customers/${customerId}/analytics/reports/${newReportId}?run-on-open=true`);
  }, [customerId, datasetName, generateReport, history]);

  const handleRefresh = useCallback(async () => {
    setIsRefreshLoading(true);
    await fetchDatasetBatches(datasetName, true);
    setTimeout(() => {
      setIsRefreshLoading(false);
    }, 10000);
  }, [datasetName, fetchDatasetBatches]);

  const DatasetDetailsRowWrapper = useCallback(
    ({ row }: { row: BatchItem }) => <DatasetDetailsRow data={row} handleDelete={handleOpenDeleteDialog} />,
    []
  );

  if (!isRefreshLoading && (loadingDatasets || loadingDatasetBatches || !init.current)) {
    return <CircularProgressLoader />;
  }

  if (!loadingDatasets && !dataset) {
    return <Redirect to="." />;
  }

  if (showImportStepper) {
    return (
      <ImportCSVStepper
        onClose={() => {
          setShowImportStepper(false);
        }}
        datasetName={datasetName}
      />
    );
  }

  return (
    <>
      <FilterTable<BatchItem>
        tableItems={datasetBatches}
        showRowsSelection={true}
        onRowsSelected={setSelected}
        rowComponent={DatasetDetailsRowWrapper}
        headerColumns={headers}
        filterColumns={filterColumns}
        filterBarPlaceholder={datahubTxt.FILTER_BATCHES_PLACEHOLDER}
        persistenceKey="dataset_details_tab"
        itemUniqIdentifierField="batch"
        defaultSortingColumnValue="batch"
        toolbarProps={{
          title: (
            <ToolbarTitle
              datasetName={datasetName}
              onBack={() => {
                history.push(`/customers/${customerId}/datasets`);
              }}
            />
          ),
          customSlot: hasBatches ? (
            <Stack
              direction="row"
              sx={{
                gap: 2,
              }}
            >
              <Tooltip title={datahubTxt.REFRESH_DATASET}>
                <Box>
                  <LoadingButton
                    variant="outlined"
                    onClick={handleRefresh}
                    loading={isRefreshLoading}
                    endIcon={<AutorenewIcon />}
                    mixpanelEventId="datahub.datasets.batch.refresh"
                    sx={{ pl: 0, py: 0.8 }}
                  >
                    <Box
                      sx={{
                        my: 0,
                        mr: 0.75,
                        p: 0,
                      }}
                    />
                  </LoadingButton>
                </Box>
              </Tooltip>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenImportDataDialog(true);
                }}
                startIcon={<UploadIcon />}
              >
                {datahubTxt.IMPORT_DATA}
              </Button>
              <Tooltip title={openInReportsTooltipTitle}>
                <Box>
                  <LoadingButton
                    variant="contained"
                    onClick={handleOpenInReports}
                    loading={loadingOpenInReports}
                    mixpanelEventId="datahub.datasets.batch.explore"
                    disabled={dataset?.records === 0}
                  >
                    {datahubTxt.OPEN_IN_REPORTS}
                  </LoadingButton>
                </Box>
              </Tooltip>
            </Stack>
          ) : undefined,
        }}
        emptyTableComponent={
          !hasBatches ? (
            <NoBatches
              onButtonClick={() => {
                setOpenImportDataDialog(true);
              }}
            />
          ) : undefined
        }
        emptyTableCellBorder={false}
        showFilterBar={hasBatches}
        showHeader={hasBatches}
      >
        <Tooltip title={deleteTooltipTitle}>
          <Grid
            sx={{
              ml: 1,
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setOpenDeleteDialog(true);
              }}
              disabled={selected.length === 0 || deleteBatchDisabled}
            >
              {datahubTxt.DELETE}
            </Button>
          </Grid>
        </Tooltip>
      </FilterTable>
      {openDeleteDialog && (
        <DeleteDialog
          open={openDeleteDialog}
          title={datahubTxt.DELETE_BATCHES_TITLE}
          message={datahubTxt.DELETE_BATCHES_CONFIRM}
          onDelete={handleDeleteBatches}
          onClose={() => {
            setOpenDeleteDialog(false);
          }}
          mixpanelEventId="datahub.datasets.batch.delete"
        />
      )}
      {openImportDataDialog && (
        <ImportDataDialog
          onClose={() => {
            setOpenImportDataDialog(false);
          }}
          onOpenImportStepper={() => {
            setShowImportStepper(true);
          }}
        />
      )}
    </>
  );
};

export default DatasetDetails;
