import { type JSX } from "react";

import {
  type AssetType,
  AssetTypeAmazonWebServices,
  AssetTypeAwsStandalone,
  AssetTypeBetterCloud,
  AssetTypeDoiTCloudIntelligence,
  AssetTypeDoiTCloudNavigator,
  AssetTypeDoiTCloudOneTimeServiceFee,
  AssetTypeDoiTCloudSolve,
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudDirect,
  AssetTypeGoogleCloudProject,
  AssetTypeGoogleCloudProjectStandalone,
  AssetTypeGoogleCloudStandalone,
  AssetTypeGSuite,
  AssetTypeKubernetes,
  AssetTypeLooker,
  AssetTypeMicrosoftAzure,
  AssetTypeMicrosoftAzureStandalone,
  AssetTypeOffice365,
  AssetTypeSnowflake,
  AssetTypeZendesk,
} from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import noAssetLogoDark from "../../../../assets/no-assets-dark-mode.svg";
import noAssetLogoLight from "../../../../assets/no-assets-light-mode.svg";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";

type NoAssetsProps = {
  assetType: AssetType;
  children?: JSX.Element;
};

type NoAssetPageOptions = {
  displayAdditionalSentence: boolean;
  displayChildren: boolean;
};

export const cyIds = {
  image: "no-assets-image",
  header: "no-assets-header",
  text: "no-assets-text",
  additional: "no-assets-additional-sentence",
};

/**
 * This component is used to display a message when there are no assets.
 * The call-to-action button to add an asset should be the direct and only child of this component.
 * @param assetType - The type of asset that the tab/table is attempting to show
 * @param children - The call-to-action button to add an asset
 */
const NoAssets = ({ assetType, children }: NoAssetsProps) => {
  const isDarkMode = useDarkThemeCheck();

  const assetTypeAttributes: Record<AssetType, NoAssetPageOptions> = {
    [AssetTypeAmazonWebServices]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeGSuite]: { displayAdditionalSentence: true, displayChildren: true },
    [AssetTypeGoogleCloud]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeGoogleCloudProject]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeGoogleCloudDirect]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeMicrosoftAzure]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeMicrosoftAzureStandalone]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeOffice365]: { displayAdditionalSentence: true, displayChildren: true },
    [AssetTypeLooker]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeSnowflake]: { displayAdditionalSentence: false, displayChildren: true },
    [AssetTypeGoogleCloudProjectStandalone]: { displayAdditionalSentence: false, displayChildren: false },
    // ↓ These asset types ↓ aren't going to come up at this time, but are added for future completeness.
    [AssetTypeAwsStandalone]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeGoogleCloudStandalone]: { displayAdditionalSentence: false, displayChildren: false },
    "amazon-web-services-reseller": { displayAdditionalSentence: false, displayChildren: false },
    "google-cloud-reseller": { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeBetterCloud]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeZendesk]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeDoiTCloudNavigator]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeDoiTCloudSolve]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeDoiTCloudOneTimeServiceFee]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeDoiTCloudIntelligence]: { displayAdditionalSentence: false, displayChildren: false },
    [AssetTypeKubernetes]: { displayAdditionalSentence: false, displayChildren: false },
  };

  const { displayAdditionalSentence, displayChildren } = assetTypeAttributes[assetType];

  const additionalSentenceText = (
    <Typography variant="inherit" component="span" data-cy={cyIds.additional}>
      <br />
      Why not add a new subscription?
    </Typography>
  );

  return (
    <Stack
      direction="column"
      spacing={2.5}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
      }}
    >
      <img
        src={isDarkMode ? noAssetLogoDark : noAssetLogoLight}
        width="100"
        alt="no-assets-image"
        aria-hidden
        data-cy={cyIds.image}
      />
      <Typography variant="h1" data-cy={cyIds.header}>
        No assets
      </Typography>
      <Typography
        color="textSecondary"
        data-cy={cyIds.text}
        sx={{
          textAlign: "center",
        }}
      >
        You don't have any assets associated with this account.
        {displayAdditionalSentence && additionalSentenceText}
      </Typography>
      {displayChildren && children}
    </Stack>
  );
};

export default NoAssets;
