import { Link, Stack, TableCell } from "@mui/material";
import Typography from "@mui/material/Typography";
import { type Timestamp } from "firebase/firestore";
import camelCase from "lodash/camelCase";
import { DateTime } from "luxon";

import { cloudflowTexts } from "../../../assets/texts";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { type CloudflowWSnap } from "../../../types/Cloudflow";
import { StatusChip } from "../CloudflowBuilder/Topbar/StatusChip";

export type CloudflowRowProps = {
  row: CloudflowWSnap;
  actions: {
    onViewRunHistory: (row?: CloudflowWSnap) => void;
    onEdit: (id: string) => void;
    onUnpublish: (id: string) => void;
    onDelete: (id: string) => void;
    onNameClick: (link: string) => void;
  };
};

const CloudflowRow = ({ row, actions: { onEdit, onViewRunHistory, onDelete, onNameClick } }: CloudflowRowProps) => {
  const routeMatchURL = useRouteMatchURL();

  const menuOptions: ThreeDotsMenuOption[] = [
    {
      label: cloudflowTexts.VIEW_RUN_HISTORY,
      key: camelCase(cloudflowTexts.VIEW_RUN_HISTORY),
      action: () => {
        onViewRunHistory(row);
      },
      dataCy: "viewRunHistory",
    },
    {
      label: cloudflowTexts.EDIT_CLOUDFLOW,
      key: camelCase(cloudflowTexts.EDIT_CLOUDFLOW),
      action: () => {
        onEdit(row.ref.id);
      },
      dataCy: "editCloudflow",
    },
    {
      label: <Typography color="error">{cloudflowTexts.DELETE_CLOUDFLOW}</Typography>,
      key: camelCase(cloudflowTexts.DELETE_CLOUDFLOW),
      action: () => {
        onDelete(row.ref.id);
      },
      dataCy: "deleteCloudflow",
    },
  ];

  const linkDestination = `${routeMatchURL}/edit/${row.ref.id}`;

  return (
    <>
      <TableCell>
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            onNameClick(linkDestination);
          }}
          color="inherit"
          variant="body2"
        >
          {row.data.name}
        </Link>
      </TableCell>
      <TableCell>{row.data.owner}</TableCell>
      <TableCell>
        <StatusChip status={row.data.status} />
      </TableCell>
      <TableCell>{formatLastUpdatedTime(row.data.lastExecuted)}</TableCell>
      <TableCell padding="none">
        <Stack
          sx={{
            alignItems: "end",
          }}
        >
          <ThreeDotsMenu options={menuOptions} />
        </Stack>
      </TableCell>
    </>
  );
};

function formatLastUpdatedTime(time: Timestamp | undefined) {
  if (!time) {
    return "";
  }

  const date = DateTime.fromJSDate(time.toDate());
  return date.isValid ? date.toLocaleString(DateTime.DATETIME_MED) : "";
}
export default CloudflowRow;
