import { useCallback, useState } from "react";

import { type GSuiteAssetModel } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

import { useApiContext } from "../../../../../api/context";
import { type LegacyDataFormat } from "../../../../../Components/Catalog/Catalog.context";
import { useSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { type Entity } from "../../../../../Context/customer/EntitiesContext";
import { useUserContext } from "../../../../../Context/UserContext";
import { type Asset, type Contract } from "../../../../../types";
import { formatCurrency } from "../../../../../utils/common";
import sku from "../../../../../utils/gsuite";
import mixpanel from "../../../../../utils/mixpanel";
import { buildBillingProfileString, buildExpiryDate } from "../../../assetUtils";
import { RemoveDialog } from "../../../Cards/components/RemoveDialog";
import LicenseChangeDialog from "../../../components/LicenseChangeDialog";
import AutomationDialog from "../../../Dialogs/AutomationDialog";
import ChangeGVoiceDialog from "../../../Dialogs/ChangeGVoiceDialog";
import { NotApplicable } from "../typographics";
import { getRenewalTypeLabel, getTotal } from "./gSuiteAssetUtils";

export type GsuiteAssetRowData = {
  id: string;
  asset: Asset<GSuiteAssetModel>;
  assetType: string;
  domain: string;
  licenses: number;
  seatsUsed: number;
  expiryDate: DateTime;
  expiryDateString: string;
  reseller: string;
  commitment: boolean;
  billingProfile: string;
  renewalStatus?: string;
};

type GSuiteAssetRowProps = {
  data: GsuiteAssetRowData;
  catalog: LegacyDataFormat[];
  onRemoveAsset: (asset: Asset<GSuiteAssetModel>) => void;
  contract?: Contract;
};

export const cyIds = {
  cells: {
    type: "gsuiteTableCell-type",
    domain: "gsuiteTableCell-domain",
    licenses: {
      summary: "gsuiteTableCell-licenses",
      editLicenses: "gsuiteTableCell-editLicenses",
    },
    expiry: "gsuiteTableCell-expiryDate",
    renewal: "gsuiteTableCell-renewal",
    reseller: "gsuiteTableCell-reseller",
    threeDots: "gsuiteTableCell-threeDotsMenu",
    billingProfile: "gsuiteTableCell-billingProfile",
  },
  menuItems: {
    editAsset: "gsuiteAssetMenu-editAsset",
    editAutoconfigure: "gsuiteAssetMenu-editAuto",
    editLicenses: "gsuiteAssetMenu-editLicenses",
    editGVoice: "gsuiteAssetMenu-editGVoice",
    remove: "gsuiteAssetMenu-remove",
  },
};

function buildLicenseSummary(isChromeEnt: boolean, data: GsuiteAssetRowData, seatBasedSKU: boolean) {
  const licenseSummary = isChromeEnt
    ? `${data.licenses} licenses`
    : `${data.seatsUsed} seats / ${data.licenses} licenses`;
  return seatBasedSKU ? licenseSummary : NotApplicable;
}

/**
 * Converts asset into row data for {@link GSuiteAssetRowProps}
 * @param asset gsuite asset for the row to represent
 * @param entities map of customer Entities by id
 */
export const buildGSuiteAssetRowData = (
  asset: Asset<GSuiteAssetModel>,
  entities: Record<string, Entity>
): GsuiteAssetRowData => {
  const { subscription } = asset.data.properties;
  const { plan } = subscription;
  const commitment = plan.isCommitmentPlan;

  let expiryDate = DateTime.fromMillis(0);
  // Unable to use the `commitment` or `plan` vars here because TS won't recognise the presence of commitment interval if I do.
  if (asset.data.properties.subscription.plan.isCommitmentPlan) {
    const { commitmentInterval } = asset.data.properties.subscription.plan;
    expiryDate = DateTime.fromMillis(commitmentInterval.endTime).toUTC().startOf("day");
  }

  const seats = { used: asset.data.properties.subscription.seats?.licensedNumberOfSeats ?? 0, currentMax: 0 };
  seats.currentMax = commitment
    ? (asset.data.properties.subscription.seats?.numberOfSeats ?? 0)
    : (asset.data.properties.subscription.seats?.maximumNumberOfSeats ?? 0);

  const entityModel = asset.data.entity?.id ? entities[asset.data.entity.id] : null;
  const billingProfile = buildBillingProfileString(entityModel);

  return {
    id: asset.id,
    asset,
    assetType: subscription.skuName,
    domain: asset.data.properties.customerDomain,
    licenses: seats.currentMax,
    seatsUsed: seats.used,
    expiryDate, // Only used for sorting. For display, the expiryDateString is used.
    expiryDateString: buildExpiryDate(commitment, expiryDate),
    renewalStatus: commitment ? getRenewalTypeLabel(subscription.renewalSettings?.renewalType) : "Auto Renew",
    reseller: asset.data.properties.reseller,
    billingProfile,
    commitment,
  };
};

const GSuiteAssetRow = ({ data, contract, catalog, onRemoveAsset }: GSuiteAssetRowProps) => {
  const { properties } = data.asset.data;
  const { settings, subscription } = properties;
  const skuId = subscription.skuId;
  const planName = (settings?.plan?.planName ?? subscription.plan.isCommitmentPlan) ? "ANNUAL" : "FLEXIBLE";
  const payment = (settings?.payment ?? planName === "FLEXIBLE") ? "MONTHLY" : "YEARLY";

  const catalogItem = catalog.find(
    (item) => item.data.skuId === skuId && item.data.plan === planName && item.data.payment === payment
  );

  const api = useApiContext();
  const snackbar = useSnackbar();
  const { isDoitOwner } = useAuthContext();
  const { userRoles } = useUserContext();

  const [autoIncreaseDialogOpen, setAutoIncreaseDialogOpen] = useState<boolean>(false);
  const [licenseChangeDialogOpen, setLicenseChangeDialogOpen] = useState<boolean>(false);
  const [ackRemoveDialog, setAckRemoveDialog] = useState<boolean>(false);
  const [changeGVoice, setChangeGVoice] = useState<boolean>(false);
  const [changeLicensesLoading, setChangeLicensesLoading] = useState<boolean>(false);

  const handleChangeLicensesOpen = useCallback(() => {
    setLicenseChangeDialogOpen(true);
  }, []);
  const handleChangeLicensesClose = useCallback(() => {
    setLicenseChangeDialogOpen(false);
  }, []);
  const handleAutoIncreaseOpen = useCallback(() => {
    setAutoIncreaseDialogOpen(true);
  }, []);
  const handleAutoIncreaseClose = useCallback(() => {
    setAutoIncreaseDialogOpen(false);
  }, []);
  const handleAckRemoveDialogOpen = useCallback(() => {
    setAckRemoveDialog(true);
  }, []);
  const handleAckRemoveDialogClose = useCallback(() => {
    setAckRemoveDialog(false);
  }, []);
  const handleChangeGVoiceOpen = useCallback(() => {
    setChangeGVoice(true);
  }, []);
  const handleChangeGVoiceClose = useCallback(() => {
    setChangeGVoice(false);
  }, []);

  const handleRemoveDialogOK = useCallback(() => {
    onRemoveAsset(data.asset);
    handleAckRemoveDialogClose();
  }, [data.asset, handleAckRemoveDialogClose, onRemoveAsset]);

  const confirmChangeLicenses = useCallback(
    async (currentMaxSeats, newMaxSeats) => {
      setChangeLicensesLoading(true);
      const quantity = newMaxSeats - currentMaxSeats;
      const { amount, currency } = getTotal({
        asset: data.asset,
        quantity,
        planName,
        payment,
        catalogItem,
        contract,
      });
      const total = formatCurrency(amount, currency);
      let maximumNumberOfSeats = subscription.seats?.maximumNumberOfSeats ?? 0;
      const licensedNumberOfSeats = subscription.seats?.licensedNumberOfSeats ?? 0;
      const oldNumberOfSeats = subscription.seats?.numberOfSeats ?? 0;
      let numberOfSeats = oldNumberOfSeats;

      if (data.commitment) {
        numberOfSeats = newMaxSeats;
        if (numberOfSeats < oldNumberOfSeats) {
          numberOfSeats = oldNumberOfSeats;
        }
      } else {
        maximumNumberOfSeats = newMaxSeats;
        if (maximumNumberOfSeats < licensedNumberOfSeats) {
          maximumNumberOfSeats = licensedNumberOfSeats;
        }
        if (maximumNumberOfSeats <= 0) {
          maximumNumberOfSeats = 0;
        }
      }

      try {
        await api.post(
          `/licenses/gsuite/customers/${properties.customerId}/subscriptions/${subscription.subscriptionId}/changeSeats`,
          {
            reseller: properties.reseller,
            seats: {
              licensedNumberOfSeats,
              maximumNumberOfSeats,
              numberOfSeats,
            },
            total,
            payment,
          }
        );

        mixpanel.track(`assets.gsuite.${quantity > 0 ? "increase" : "decrease"}`, {
          reseller: properties.reseller,
          quantity,
          sku: subscription.skuId,
          domain: properties.customerDomain,
          subscriptionId: subscription.subscriptionId,
        });

        snackbar.onOpen({
          message: "Order completed successfully",
          variant: "success",
          autoHideDuration: 20000,
          action: [
            <IconButton key="close" aria-label="Close" color="inherit" onClick={snackbar.onClose} size="large">
              <CloseIcon />
            </IconButton>,
          ],
        });

        handleChangeLicensesClose();
      } catch (e) {
        snackbar.onOpen({
          message: "Order failed",
          variant: "error",
          autoHideDuration: 20000,
          action: [
            <IconButton key="close" aria-label="Close" color="inherit" onClick={snackbar.onClose} size="large">
              <CloseIcon />
            </IconButton>,
          ],
        });
      }
      setChangeLicensesLoading(false);
    },
    [
      api,
      catalogItem,
      contract,
      data.asset,
      data.commitment,
      handleChangeLicensesClose,
      payment,
      planName,
      properties.customerDomain,
      properties.customerId,
      properties.reseller,
      snackbar,
      subscription.seats?.licensedNumberOfSeats,
      subscription.seats?.maximumNumberOfSeats,
      subscription.seats?.numberOfSeats,
      subscription.skuId,
      subscription.subscriptionId,
    ]
  );

  const menuOptions: ThreeDotsMenuOption[] = [
    {
      key: `${data.id}-editAsset`,
      label: <Typography variant="body1">Edit asset</Typography>,
      dataCy: cyIds.menuItems.editAsset,
    },
    {
      key: `${data.id}-editLicense`,
      label: <Typography variant="body1">Edit licenses</Typography>,
      action: handleChangeLicensesOpen,
      dataCy: cyIds.menuItems.editLicenses,
    },
  ];

  const seatBasedSKU = ![sku.GoogleMeetGlobalDialing, sku.CloudIdentityFree].includes(skuId);
  const showChangeSeats = subscription.billingMethod === "ONLINE" && seatBasedSKU;
  const isChromeEnt = skuId === sku.GoogleChromeEnterprise;
  const allowAutoConfigure = !!userRoles?.assetsManager && showChangeSeats && !isChromeEnt;
  if (allowAutoConfigure) {
    menuOptions.splice(1, 0, {
      key: `${data.id}-autoIncrease`,
      label: <Typography variant="body1">Configure auto-increase</Typography>,
      action: handleAutoIncreaseOpen,
      dataCy: cyIds.menuItems.editAutoconfigure,
    });
  }

  const allowChangeGVoice = sku.isVoice(skuId);
  if (allowChangeGVoice) {
    menuOptions.push({
      key: `${data.id}-voice`,
      label: <Typography variant="body1">Change Google Voice edition</Typography>,
      action: handleChangeGVoiceOpen,
      dataCy: cyIds.menuItems.editGVoice,
    });
  }

  if (isDoitOwner) {
    menuOptions.push({
      key: `${data.id}-remove`,
      label: (
        <Typography variant="body1" color="error">
          Remove asset
        </Typography>
      ),
      action: handleAckRemoveDialogOpen,
      dataCy: cyIds.menuItems.remove,
    });
  }

  return (
    <>
      {sku.isVoice(skuId) && changeGVoice && catalogItem && (
        <ChangeGVoiceDialog
          onClose={handleChangeGVoiceClose}
          asset={data.asset}
          contract={contract}
          catalogItem={catalogItem}
        />
      )}
      {isDoitOwner && (
        <RemoveDialog
          open={ackRemoveDialog}
          onCancel={handleAckRemoveDialogClose}
          onAccept={handleRemoveDialogOK}
          name={data.asset.data.properties.subscription.skuName}
        />
      )}
      {allowAutoConfigure && autoIncreaseDialogOpen && (
        <AutomationDialog onClose={handleAutoIncreaseClose} asset={data.asset} />
      )}
      <LicenseChangeDialog
        open={licenseChangeDialogOpen}
        onClose={handleChangeLicensesClose}
        onConfirm={confirmChangeLicenses}
        asset={data.asset}
        contract={contract}
        catalogItem={catalogItem}
        seats={{ max: data.licenses, used: data.seatsUsed }}
        loading={changeLicensesLoading}
      />
      <TableCell data-cy={cyIds.cells.type}>
        <Typography variant="body2" component="span">
          {data.assetType}
        </Typography>
      </TableCell>
      <TableCell data-cy={cyIds.cells.domain}>
        <Typography variant="body2" component="span">
          {data.domain}
        </Typography>
      </TableCell>
      <TableCell
        data-cy={cyIds.cells.licenses}
        sx={{
          display: {
            xs: "none",
            sm: "table-cell",
          },
        }}
      >
        <Stack>
          <Typography variant="body2" component="span" noWrap data-cy={cyIds.cells.licenses.summary}>
            {buildLicenseSummary(isChromeEnt, data, seatBasedSKU)}
          </Typography>
          {showChangeSeats && (
            <Typography
              color="primary"
              variant="body2"
              component="span"
              role="button"
              onClick={handleChangeLicensesOpen}
              sx={{ cursor: "pointer" }}
              data-cy={cyIds.cells.licenses.editLicenses}
            >
              Edit licenses
            </Typography>
          )}
        </Stack>
      </TableCell>
      <TableCell
        data-cy={cyIds.cells.expiry}
        sx={{
          display: {
            xs: "none",
            md: "table-cell",
          },
        }}
      >
        <Typography variant="body2" component="span" noWrap>
          {data.expiryDateString}
        </Typography>
      </TableCell>
      <TableCell
        data-cy={cyIds.cells.renewal}
        sx={{
          display: {
            xs: "none",
            lg: "table-cell",
          },
        }}
      >
        <Typography variant="body2" component="span">
          {data.renewalStatus}
        </Typography>
      </TableCell>
      <TableCell
        data-cy={cyIds.cells.reseller}
        sx={{
          display: {
            xs: "none",
            xl: "table-cell",
          },
        }}
      >
        <Typography variant="body2" component="span">
          {data.reseller}
        </Typography>
      </TableCell>
      <TableCell
        data-cy={cyIds.cells.billingProfile}
        sx={{
          display: {
            xs: "none",
            xl: "table-cell",
          },
        }}
      >
        <Typography variant="body2" component="span">
          {data.billingProfile}
        </Typography>
      </TableCell>
      <TableCell padding="checkbox" data-cy={cyIds.cells.threeDots}>
        {!!menuOptions?.length && <ThreeDotsMenu options={menuOptions} size="small" />}
      </TableCell>
    </>
  );
};

export default GSuiteAssetRow;
