import { useMemo } from "react";

import { useHistory } from "react-router";
import { Button } from "@mui/material";

import illustration from "../../../assets/GKE-empty-illustration.svg";
import { lensEnticementTexts, lensUrls } from "../../../assets/texts";
import { useCustomerId } from "../../hooks/useCustomerId";
import { LensEnticement } from "../../LensEnticement/LensEnticement";
import { type EnticementState } from "../../LensEnticement/types";
import useFetchAmTamEmail from "../../NoEntitlement/hooks";
import { ContactSalesButton } from "../../NoEntitlement/NoEntitlement";

export const SnowflakeLensEnticement = ({ state }: { state: EnticementState }) => {
  const history = useHistory();
  const customerId = useCustomerId();
  const { accountManagers, loading } = useFetchAmTamEmail();

  const button = useMemo(() => {
    if (state === "activate") {
      return (
        <Button
          variant="contained"
          sx={{ width: "fit-content" }}
          onClick={() => {
            history.push(`/customers/${customerId}/cloud-onboarding-snowflake`);
          }}
        >
          Connect your Snowflake
        </Button>
      );
    }

    if (state === "upgrade") {
      return <ContactSalesButton sendTo={accountManagers} loading={loading} />;
    }
  }, [accountManagers, customerId, history, loading, state]);

  return (
    <LensEnticement
      image={illustration}
      title={lensEnticementTexts.SNOWFLAKE_LENS_TITLE}
      listItems={lensEnticementTexts.SNOWFLAKE_LENS_LIST}
      actionButton={button}
      learnMoreUrl={lensUrls.SNOWFLAKE_LENS_LEARN_MORE}
    />
  );
};
