import { useState } from "react";

import { useFirestoreIdealHook } from "./hooks/useFirestoreIdeal";

export const FirestoreRequestOverlay = () => {
  const [count, setCount] = useState(0);

  useFirestoreIdealHook("requestOverlay", 10000, (_name, { triggers }) => {
    if (triggers === 0) {
      return;
    }

    setCount(triggers);
  });

  const overlayStyle = {
    position: "fixed",
    bottom: 10,
    left: 10,
    zIndex: 9999,
    radius: "10px",
    backgroundColor: "rgba(255, 0, 0, 0.7)",
    color: "white",
    padding: "10px",
    borderRadius: "0 0 0 10px",
    fontFamily: "Arial, sans-serif",
    fontSize: "24px",
  } as const;

  return <div style={overlayStyle}>Requests: {count}</div>;
};
