import { useCustomerRouteNavigation } from "../../Components/hooks/useCustomerRouteNavigation";
import { useCustomerContext } from "../../Context/CustomerContext";
import { DashboardProcessingContextProvider } from "../../Context/DashboardProcessingContext";
import { CustomerDashboards } from "./CustomerDashboards";

export default function CustomerViewForPartner() {
  const { customer } = useCustomerContext({ allowNull: true });
  const navigateToCustomerRoute = useCustomerRouteNavigation();

  if (customer?.partnerOfRecord && !customer?.enabledFlexsave?.GCP) {
    navigateToCustomerRoute("flexsave-gcp");
    return <></>;
  }

  return (
    <DashboardProcessingContextProvider>
      <CustomerDashboards />
    </DashboardProcessingContextProvider>
  );
}
