import { type ChangeEvent, useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router";
import { type CloudConnectCategory } from "@doitintl/cmp-models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  capitalize,
  Card,
  CardContent,
  Container,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";

import { useApiContext } from "../../../../api/context";
import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import LoadingButton from "../../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../utils";
import mixpanel from "../../../../utils/mixpanel";
import { InnerScreenHeader } from "../../Components/InnerScreenHeader";
import { getOrgLevelCommands, getProjectLevelCommands } from "../commands";
import { BigQueryDatasetLocationSelect } from "./BigQueryDatasetLocationSelect";
import { FeaturesTable } from "./FeaturesTable";
import { usePermissionCategoriesByType, useSelectItemList } from "./hooks";

type CreateResponse = {
  isValid: boolean;
  clientId: string;
};

export const NewConnectionPage = () => {
  const { customer } = useCustomerContext();
  const { type } = useParams<{ type: "organization" | "project" }>();
  const history = useHistory();
  const api = useApiContext();

  const showSuccess = useSuccessSnackbar();
  const showError = useErrorSnackbar();

  const availableCategories = usePermissionCategoriesByType(type);

  const requiredCategoryIds = availableCategories
    .filter((category) => category.isDefault)
    .map((category) => category.id);

  const [selectedFeatures, onFeatureSelect] = useSelectItemList<CloudConnectCategory["id"]>([]);

  const [selectedDatasetLocation, setSelectedDatasetLocation] = useState<string | null>(null);

  // After categories are loaded, select all of them by default
  useEffect(() => {
    availableCategories.forEach((category) => {
      onFeatureSelect(category.id, true);
    });
  }, [availableCategories, onFeatureSelect]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const uploadFileRef = useRef<HTMLInputElement>(null);

  const backToSettings = () => {
    history.push(`/customers/${customer.id}/settings/gcp`);
  };

  // Based on the available categories and selected features, get a list of unique permissions required
  const permissionsToAdd = availableCategories
    .filter((category) => selectedFeatures.includes(category.id))
    .flatMap((category) => category.permissions)
    .filter((permission, index, self) => self.indexOf(permission) === index);

  // Commands depend on which type of connection we are setting up
  const commands =
    type === "project" ? getProjectLevelCommands(permissionsToAdd) : getOrgLevelCommands(permissionsToAdd);

  // Depending on which permissions are selected, we need additional info
  const requireDatasetLocation = permissionsToAdd.includes("bigquery.datasets.create");

  // If we require dataset location, we need to make sure it's selected
  const canSubmitFile = !requireDatasetLocation || !!selectedDatasetLocation;

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    setIsUploadingFile(true);

    const formData = new FormData();
    formData.append("service_key", file);

    if (requireDatasetLocation && selectedDatasetLocation) {
      formData.append("location", selectedDatasetLocation);
    }

    try {
      const response = await api.request<CreateResponse>({
        method: "post",
        url: `/v1/customers/${customer.id}/cloudconnect/google-cloud${type === "project" ? "/partial" : ""}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Track completion
      mixpanel.track("credentials.google-cloud.set");

      // Run some onboarding tasks - we need these only for organizations, and they are not critical
      if (type === "organization") {
        try {
          await Promise.allSettled([
            api.request({
              method: "get",
              url: `/v1/superquery/finops-process?client=${response.data?.clientId}`,
            }),
            api.request({
              method: "get",
              url: `/v1/customers/${customer.id}/google-cloud/service-limits`,
            }),
            api.request({
              method: "post",
              url: `/v1/customers/${customer.id}/recommender/update`,
            }),
            api.request({
              method: "get",
              url: `/v1/customers/${customer.id}/attachDashboard`,
            }),
          ]);
        } catch (error) {
          consoleErrorWithSentry(error);
        }
      }

      setIsUploadingFile(false);
      setIsDrawerOpen(false);
      backToSettings();
      showSuccess(`${capitalize(type)} successfully connected`);
    } catch (error) {
      consoleErrorWithSentry(error);
      setIsUploadingFile(false);
      showError(`An error occurred while connecting the ${type}`);
    }
  };

  return (
    <>
      <Card>
        <InnerScreenHeader title={`Connect ${type}`} subtitle={customer.name} backPageCallback={backToSettings} />
        <CardContent>
          <Container maxWidth="md" sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" mb={3}>
              Select features you'd like to enable for your {type}
            </Typography>

            <FeaturesTable
              type={type}
              selectedFeatures={selectedFeatures}
              onFeatureSelect={onFeatureSelect}
              forceIncludeCategoryIds={requiredCategoryIds}
            />

            <Button
              variant="contained"
              sx={{ my: 5, alignSelf: "flex-end" }}
              disabled={selectedFeatures.length === 0}
              onClick={() => {
                setIsDrawerOpen(true);

                // TODO(laura): check if this is still valid - leftovers from V1
                mixpanel.track("gcp.link-account");
                if (selectedFeatures.some((feature) => feature === "core")) {
                  mixpanel.track("gcp.link-account.quotas");
                }
              }}
            >
              Generate gcloud commands
            </Button>
          </Container>
        </CardContent>
      </Card>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
        PaperProps={{ sx: { maxWidth: "650px" } }}
      >
        <DialogTitle>Create Google Cloud service account</DialogTitle>
        <DialogContent>
          {requireDatasetLocation ? (
            <BigQueryDatasetLocationSelect
              location={selectedDatasetLocation}
              setLocation={setSelectedDatasetLocation}
            />
          ) : null}

          <Typography variant="body1">
            Run these gcloud commands in Google Cloud Shell to create a service account and role with selected
            permissions.
          </Typography>

          <Button
            variant="outlined"
            size="medium"
            startIcon={<OpenInNewIcon />}
            target="_blank"
            href="https://shell.cloud.google.com/?fromcloudshell=true&show=terminal"
            sx={{ my: 3 }}
          >
            Open Google Cloud Shell
          </Button>

          {commands.map((command) => (
            <Box key={command.header} mb={2} data-cy="command-block">
              <Typography variant="body1" sx={{ opacity: 0.6 }} mb={1}>
                {command.header}
              </Typography>
              {command.body ? <CopyCodeBlock base={command.body} /> : null}
            </Box>
          ))}

          <input type="file" ref={uploadFileRef} style={{ display: "none" }} onChange={handleFileSelect} />

          <LoadingButton
            variant="contained"
            loading={isUploadingFile}
            disabled={!canSubmitFile}
            mixpanelEventId="settings.gcp-platform-configuration.upload-key"
            onClick={() => {
              if (!uploadFileRef.current) {
                return;
              }

              uploadFileRef.current.click();
            }}
          >
            Upload file
          </LoadingButton>
        </DialogContent>
      </Drawer>
    </>
  );
};
