import { create } from "zustand";

export type CachedHookExtra = {
  key?: string;
  args?: any[];
  hookFunc: HookFunction;
};

export type HookFunction = (...args: any[]) => unknown;

export type DynamicStore = {
  [key: string]: unknown;
  setState: (key: string, value: unknown) => void;
  hooks: Map<string, CachedHookExtra>;
  loadHook: (hookName: string, extra: CachedHookExtra) => void;
  reset: () => void;
};

export const useDynamicStore = create<DynamicStore>((set) => ({
  hooks: new Map<string, CachedHookExtra>(),
  setState: (key, value) => {
    set((state) => ({ ...state, [key]: value }));
  },
  loadHook: (hookName, extra) => {
    set((state) => {
      const newHooks = new Map(state.hooks);
      newHooks.set(hookName, extra);
      return { ...state, hooks: newHooks };
    });
  },
  reset: () => {
    set((state) => {
      const newState: Partial<DynamicStore> = { ...state };
      Object.keys(newState).forEach((key) => {
        if (typeof newState[key] !== "function") {
          newState[key] = undefined;
        }
      });

      return { ...newState, hooks: new Map() };
    });
  },
}));
