import { Fragment, useState } from "react";

import { Link, useRouteMatch } from "react-router-dom";
import { AssetTypeGSuite, DoitRole, type GSuiteAssetModel } from "@doitintl/cmp-models";
import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import { type Theme } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { amber } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { createStyles, makeStyles } from "@mui/styles";
import orderBy from "lodash/orderBy";

import { useApiContext } from "../../../api/context";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { type Asset } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import sku from "../../../utils/gsuite";
import GSuiteAssetCard from "../Cards/GSuiteAssetCard";
import { type AssetsTabPropsOuterProps } from "../types";
import GSuiteAssetTable from "./components/gsuite/GSuiteAssetTable";
import NoAssets from "./components/NoAssets";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsArea: {
      padding: 0,
      marginTop: 5,
    },
    cardActions: {
      paddingTop: theme.spacing(2),
      justifyContent: "flex-end",
    },
    warningIcon: {
      color: amber[500],
    },
  })
);

type PathParamsType = {
  url: string;
};

type Props = AssetsTabPropsOuterProps<GSuiteAssetModel>;

const GSuiteAssetsTab = ({ assets, contracts, onRemoveAsset, highlight, onAssignToEntity, onAddTag }: Props) => {
  const classes = useStyles();
  const { entities } = useCustomerContext();
  const match = useRouteMatch<PathParamsType>();
  const [loading, setLoading] = useState(false);
  const isDoitPreviewAssets = useDoitRoleCheck(DoitRole.PreviewAssets);

  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const [previewMode, setPreviewMode] = useState(isDoitPreviewAssets);

  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { customer } = useCustomerContext();
  const api = useApiContext();

  const handleRefresh = async () => {
    try {
      setLoading(true);
      await api.request({
        method: "get",
        url: `/v1/customers/${customer.id}/refresh/g-suite`,
      });
    } catch (error) {
      consoleErrorWithSentry(error);
    } finally {
      setLoading(false);
    }
  };

  const getEntity = (asset: Asset<GSuiteAssetModel>) => entities.find((e) => asset.data.entity?.id === e.id);

  const getContract = (asset: Asset<GSuiteAssetModel>) =>
    contracts ? contracts.find((c) => asset.data.contract?.id === c.id) : contracts;

  const filteredAssets = assets
    ? assets.filter(
        (asset) =>
          !sku.isDrive(asset.data.properties.subscription.skuId) ||
          asset.data.properties.subscription.seats?.maximumNumberOfSeats ||
          asset.data.properties.subscription.seats?.numberOfSeats
      )
    : assets;

  const refreshButton = isDoitEmployee && (
    <Tooltip title="Refresh Google Workspace Assets">
      <span>
        <IconButton onClick={handleRefresh} disabled={loading} size="large" data-cy="gSuiteRefresh">
          <RefreshIcon />
        </IconButton>
      </span>
    </Tooltip>
  );

  if (filteredAssets === undefined || assets === undefined) {
    return null;
  }

  if (filteredAssets.length === 0) {
    return (
      <>
        <CardActions className={classes.cardActions}>{refreshButton}</CardActions>
        <NoAssets assetType={AssetTypeGSuite}>
          {userRoles.assetsManager ? (
            <Button variant="contained" component={Link} to={`${match.url}/create`}>
              New subscription
            </Button>
          ) : (
            <></>
          )}
        </NoAssets>
      </>
    );
  }

  return (
    <Fragment>
      <CardActions className={classes.cardActions}>
        {refreshButton}
        {filteredAssets.length > 0 && userRoles.assetsManager && (
          <Button
            key="add-g-suite-subscription"
            color="primary"
            aria-label="Add New Asset"
            component={Link}
            to={`${match.url}/create`}
            variant="contained"
            size="small"
            data-cy="gSuiteNewSubscription"
          >
            New subscription
          </Button>
        )}
        {previewMode && (
          <FormControlLabel
            control={
              <Switch
                title="Preview new table"
                onChange={(_event, checked) => {
                  setPreviewMode(checked);
                }}
                checked={previewMode}
              />
            }
            label="Toggle V2 Preview"
            sx={{ ml: 1 }}
          />
        )}
      </CardActions>
      {previewMode && (
        <GSuiteAssetTable
          assets={filteredAssets}
          contracts={contracts}
          loading={loading}
          onRemoveAsset={onRemoveAsset}
        />
      )}
      {!previewMode && !!assets.length && (
        <CardContent className={classes.cardsArea}>
          <Grid container spacing={2} direction="row">
            {orderBy(assets, (asset) => [asset.data.properties.customerDomain], ["asc"]).map((asset) => {
              const highlightAsset = asset.id === highlight;
              const unassignedAction =
                isDoitEmployee && !asset.data.entity ? (
                  <Tooltip title="Asset is not assigned to an entity">
                    <IconButton onClick={onAssignToEntity(asset)} size="large">
                      <ErrorIcon className={classes.warningIcon} />
                    </IconButton>
                  </Tooltip>
                ) : undefined;

              const entity = getEntity(asset);
              const contract = getContract(asset);

              return (
                <Grid
                  key={asset.ref.path}
                  size={{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    xl: 4,
                  }}
                >
                  <GSuiteAssetCard
                    asset={asset}
                    entity={entity}
                    contract={contract}
                    unassignedAction={unassignedAction}
                    highlight={highlightAsset}
                    onAssignToEntity={onAssignToEntity(asset)}
                    onRemoveAsset={onRemoveAsset(asset)}
                    onAddTag={() => {
                      onAddTag(asset);
                    }}
                    widget={false}
                    isCustomizeMode={false}
                    widgetHeight={1}
                    fallbackComponent={null}
                  />
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      )}
    </Fragment>
  );
};

export default GSuiteAssetsTab;
