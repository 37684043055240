import { type FC, useState } from "react";

import { Container, Stack } from "@mui/material";
import { Form, Formik, type FormikHelpers, type FormikValues } from "formik";
import { type AnyObject, type ObjectSchema } from "yup";

import { stepLabels } from "../../Pages/CommitmentManager/consts";
import { ButtonsFooter } from "../ButtonsFooter/ButtonsFooter";
import { FullWidthStepper } from "./FullWidthStepper";
import { useWizardForm } from "./hooks";

type Props<T extends FormikValues & AnyObject> = {
  steps: FC[];
  onSubmit: (values: T) => Promise<void>;
  initialValues: T;
  validationSchema: ObjectSchema<Partial<T>>[];
};

export const WizardForm = <T extends FormikValues & AnyObject>({
  steps,
  onSubmit,
  initialValues,
  validationSchema,
}: Props<T>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { currentStep, next, back, StepComponent } = useWizardForm(steps);

  const handleSubmit = async (values: T, { setTouched }: FormikHelpers<T>) => {
    if (currentStep !== steps.length - 1) {
      setTouched({});
      next();
    } else {
      setIsSubmitting(true);
      await onSubmit(values);
      setIsSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema[currentStep]} onSubmit={handleSubmit}>
      <Form id="wizard-form" noValidate>
        <Container maxWidth="md" sx={{ my: 4, pb: 10 }} disableGutters>
          <FullWidthStepper steps={stepLabels} activeStep={currentStep} />
          <Stack mt={7}>
            <StepComponent />
          </Stack>
          <ButtonsFooter
            isLastStep={currentStep === steps.length - 1}
            onBackClicked={back}
            onNextClicked={next}
            allowNext={true}
            maxWidth="md"
            formId="wizard-form"
            hideBackButton={currentStep === 0}
            loading={isSubmitting}
          />
        </Container>
      </Form>
    </Formik>
  );
};
