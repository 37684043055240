import { type Cluster } from "@doitintl/cmp-models";
import { Divider, Stack, Typography } from "@mui/material";

interface SelectedAccountAndClusterProps {
  accountId: string | null;
  cluster: Cluster | null;
}

const SelectedAccountAndCluster = ({ accountId, cluster }: SelectedAccountAndClusterProps) => (
  <Stack direction="row" spacing={2}>
    <Typography variant="body2" color="text.secondary">
      Account ID: <strong style={{ fontWeight: 500 }}>{accountId}</strong>
    </Typography>
    <Divider orientation="vertical" flexItem />
    <Typography variant="body2" color="text.secondary">
      Cluster ID: <strong style={{ fontWeight: 500 }}>{cluster?.id}</strong>
    </Typography>
  </Stack>
);

export default SelectedAccountAndCluster;
