import React, { type SyntheticEvent, useEffect, useRef, useState } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { InfoOutlined } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Card, Divider, Stack, Tab, Tabs, Tooltip, Typography, useMediaQuery } from "@mui/material";
import sum from "lodash/sum";
import { DateTime } from "luxon";

import { cmpBaseColors } from "../../../cmpBaseColors";
import { useAsyncCurrency } from "../../../Components/hooks/useCurrency";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { ThemeModes } from "../../../muiThemeTypes";
import { formatDecimalNumber, formatNumber } from "../../../utils/common";
import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import { InsightsContextProvider } from "../../Insights/context";
import { useInsights } from "../api";
import { sendInsightsUpsellEvent, type SendInsightsUpsellEventArgs } from "../metrics";
import { CloudProvidersIcons } from "../Preview/CloudProvidersIcons";
import { InsightsList } from "./../InsightsList/InsightsList";
import { Insight } from "./Insight";
import { type InsightByCloud } from "./types";
import { UpgradeOptions } from "./UpgradeOptions";
import { calculateROI, partitionByCloudTags } from "./utils";

export const InsightsUpsell: React.FC = () => {
  const { noAccessResults, insights, isLoading } = useInsights();
  const [insightsByCloud, setInsightsByCloud] = useState<InsightByCloud>({});
  const [showUpgradeOptions, setShowUpgradeOptions] = useState(false);
  const [upsellEventProperties, setUpsellEventProperties] = useState<SendInsightsUpsellEventArgs>(
    {} as SendInsightsUpsellEventArgs
  );
  const { customer } = useCustomerContext();
  const [dciCost, setDciCost] = useState<number>(0);
  const { asyncConvertCurrency, customerCurrencySymbol } = useAsyncCurrency();
  const [supportCategoryToServiceMapping, setSupportCategoryToServiceMapping] = useState<
    Record<string, string> | undefined
  >();
  const [isSavingsComputed, setIsSavingsComputed] = useState(false);

  const [potentialSavings, setPotentialSavings] = useState<number>(0);

  const { isDoitEmployee } = useAuthContext();
  const showCustom = isDoitEmployee || insights?.some((r) => r.providerId === "custom");

  const [selectedTab, setSelectedTab] = useState<string>("overview");

  const below1244 = useMediaQuery("(max-width:1244px)");
  const isMobile = useMediaQuery("(max-width:700px)");
  const lowestRes = useMediaQuery("(max-width:532px)");

  const handleTabChange = (_: SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    sendInsightsUpsellEvent("Insights Upsell List Tab Clicked", upsellEventProperties);
  };

  const { trackEvent } = useSegmentTrackEvent();

  const isPageViewEventSentRef = useRef(false);

  useEffect(() => {
    getCollection(AppModel)
      .doc("support")
      .collection("services")
      .where("blacklisted", "==", false)
      .get()
      .then((querySnapshot) => {
        const categoryMap: Record<string, string> = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          categoryMap[data?.id] = data?.name;
        });
        setSupportCategoryToServiceMapping(categoryMap);
      });
  }, []);

  useEffect(() => {
    if (isLoading || !noAccessResults || potentialSavings !== 0) {
      return;
    }

    const potentialSavingsPerInsight = noAccessResults.map(
      (result) => (result.potentialDailySavings?.value || 0) * 365
    );

    const newPotentialSavings = sum(potentialSavingsPerInsight);
    setPotentialSavings(newPotentialSavings);

    setIsSavingsComputed(true);
  }, [isLoading, noAccessResults, potentialSavings]);

  useEffect(() => {
    const fetchCurrencyConversion = async () => {
      if (!customer) {
        return;
      }

      const monthlySpend = Math.max((customer.customerSegment?.monthlyCloudSpend || 0) * 0.03, 3500);
      const valueConverted = await asyncConvertCurrency(monthlySpend * 12);

      setDciCost(valueConverted);

      setIsSavingsComputed(true);
    };

    fetchCurrencyConversion();
  }, [customer, asyncConvertCurrency]);

  useEffect(() => {
    if (!noAccessResults || !supportCategoryToServiceMapping) {
      return;
    }
    const partitionedInsights = partitionByCloudTags(
      noAccessResults,
      supportCategoryToServiceMapping,
      customerCurrencySymbol
    );
    setInsightsByCloud(partitionedInsights);
  }, [noAccessResults, supportCategoryToServiceMapping, customerCurrencySymbol]);

  useEffect(() => {
    if (
      !isLoading &&
      noAccessResults &&
      customer &&
      dciCost !== 0 &&
      isSavingsComputed &&
      potentialSavings &&
      !isPageViewEventSentRef.current
    ) {
      const newUpsellEventProperties = {
        trackEvent,
        noAccessResults,
        customer,
        dciCost,
        potentialSavings,
        customerCurrencySymbol: customerCurrencySymbol as string,
        showCustom: showCustom || false,
      };

      setUpsellEventProperties(newUpsellEventProperties);

      sendInsightsUpsellEvent("Upsell Page Viewed", newUpsellEventProperties);

      isPageViewEventSentRef.current = true;
    }
  }, [
    upsellEventProperties,
    isLoading,
    noAccessResults,
    customer,
    dciCost,
    potentialSavings,
    customerCurrencySymbol,
    showCustom,
    isSavingsComputed,
    trackEvent,
  ]);

  const showSnowflake = insightsByCloud.snowflake?.length > 0;
  const showGcp = insightsByCloud.gcp?.length > 0;
  const showAws = insightsByCloud.aws?.length > 0;

  const yourSavings = potentialSavings - dciCost;

  const percentageSavings = (potentialSavings / dciCost) * 100;
  const showDCIBlock = percentageSavings > 75;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        sx={({ palette }) => ({
          background:
            palette.mode === ThemeModes.LIGHT ? cmpBaseColors.backgroundLight : palette.general.backgroundDark,
          px: lowestRes ? 2 : 12,
          py: lowestRes ? 3.125 : 5,
          mx: "-16px",
          mt: "-8px",
        })}
      >
        <Stack
          px={0}
          width="100%"
          maxWidth="1096px"
          justifyContent="space-between"
          direction={below1244 ? "column" : "row"}
          alignItems="center"
          gap={below1244 ? (lowestRes ? 0 : 5) : 0}
        >
          <Stack
            width={below1244 ? (lowestRes ? "100%" : 500) : showDCIBlock ? "auto" : "1100px"}
            flexDirection="column"
            gap={3}
          >
            <Typography
              data-cy="upsell-potential-savings"
              width={showDCIBlock && !lowestRes ? 469 : "100%"}
              variant="h1"
              color="text.primary"
              fontSize={24}
              lineHeight="32px"
            >
              Upgrade and save {customerCurrencySymbol}
              {formatNumber(potentialSavings, 1)} a year on your cloud infrastructure
            </Typography>
            <Typography
              width={below1244 ? "100%" : showDCIBlock ? 562 : "100%"}
              variant="body1"
              color="text.primary"
              fontSize={16}
              lineHeight="24px"
            >
              Unlock deep savings on your cloud infrastructure and maximize the impact of every cloud dollar with DoiT
              Cloud Intelligence™. On top of deep <i>human-curated</i> Insights, you also get:
            </Typography>
            <ul style={{ margin: 0 }}>
              <li>
                <Typography variant="body1" color="text.primary" fontSize={16} lineHeight="24px">
                  Kubernetes Observability (including EKS, and GKE)
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.primary" fontSize={16} lineHeight="24px">
                  Cloud Unit Economics with DoiT DataHub
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.primary" fontSize={16} lineHeight="24px">
                  Drive team accountability with DoiT Threads
                </Typography>
              </li>
              <li>
                <Typography variant="body1" color="text.primary" fontSize={16} lineHeight="24px">
                  Automate routine cloud tasks with DoiT CloudFlow
                </Typography>
              </li>
            </ul>

            <Stack direction="row" gap={1.25}>
              {!lowestRes && (
                <Button
                  data-cy="upsell-view-upgrade-options"
                  variant="contained"
                  sx={{ background: "primary.main", width: "199px", whiteSpace: "nowrap" }}
                  size="large"
                  onClick={() => {
                    setShowUpgradeOptions(true);
                    sendInsightsUpsellEvent("Upgrade Options Button Clicked", upsellEventProperties);
                  }}
                >
                  View upgrade options
                </Button>
              )}
              {!lowestRes && (
                <Button
                  sx={{
                    height: "43px",
                  }}
                  href="https://help.doit.com/docs/governance/insights"
                  target="_blank"
                  variant="outlined"
                  startIcon={<OpenInNewIcon />}
                  size="large"
                >
                  Learn more
                </Button>
              )}
            </Stack>
          </Stack>

          {showDCIBlock && (
            <Card sx={{ width: lowestRes ? "100%" : "500px", p: lowestRes ? 2 : 4 }} variant="elevation">
              <Stack direction="column" gap={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography
                    variant={lowestRes ? "h4" : "body1"}
                    fontWeight={lowestRes ? 400 : 500}
                    color="text.primary"
                    fontSize={lowestRes ? 14 : 18}
                    lineHeight="28px"
                  >
                    DoiT Cloud Intelligence Enhanced
                  </Typography>
                  <Stack direction="row" alignItems="center" gap="3px">
                    <Typography variant="h4" fontWeight={500} color="text.primary" fontSize={18} lineHeight="28px">
                      {`${customerCurrencySymbol}${formatDecimalNumber(dciCost, 0)}/year`}
                    </Typography>
                    <Tooltip title={"Based on 3% of monthly cloud spend (with minimum of $3,500/month)"}>
                      <InfoOutlined sx={{ color: "text.secondary", height: 16, width: 16 }} />
                    </Tooltip>
                  </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography
                    variant={lowestRes ? "h4" : "body1"}
                    fontWeight={lowestRes ? 400 : 500}
                    color="text.primary"
                    fontSize={lowestRes ? 14 : 18}
                    lineHeight="28px"
                  >
                    Potential savings from Insights
                  </Typography>
                  <Stack direction="row" alignItems="center" gap="3px">
                    <Typography variant="h4" fontWeight={500} color="text.primary" fontSize={18} lineHeight="28px">
                      {customerCurrencySymbol}
                      {`${formatDecimalNumber(potentialSavings, 0)}`}/year
                    </Typography>
                    <Tooltip title={"Savings may vary depending on your architecture choices."}>
                      <InfoOutlined sx={{ color: "text.secondary", height: 16, width: 16 }} />
                    </Tooltip>
                  </Stack>
                </Stack>
                {yourSavings > 0 && (
                  <>
                    <Divider />
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        variant={lowestRes ? "h4" : "body1"}
                        fontWeight={lowestRes ? 400 : 500}
                        color="text.primary"
                        fontSize={lowestRes ? 14 : 18}
                        lineHeight="28px"
                      >
                        Your savings
                      </Typography>
                      <Typography variant="h4" fontWeight={500} color="text.primary" fontSize={18} lineHeight="28px">
                        {customerCurrencySymbol}
                        {formatDecimalNumber(yourSavings, 0)}/year
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        variant={lowestRes ? "h4" : "body1"}
                        fontWeight={lowestRes ? 400 : 500}
                        color="success.main"
                        fontSize={lowestRes ? 14 : 18}
                        lineHeight="28px"
                      >
                        Return on Investment (ROI)
                      </Typography>
                      <Typography variant="h4" fontWeight={500} color="success.main" fontSize={18} lineHeight="28px">
                        {`${formatDecimalNumber(calculateROI(potentialSavings, dciCost), 0)}%`}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            </Card>
          )}

          {lowestRes && (
            <Button
              data-cy="upsell-view-upgrade-options"
              variant="contained"
              sx={{ background: "primary.main", width: "100%", whiteSpace: "nowrap", mt: 3 }}
              size="large"
              onClick={() => {
                setShowUpgradeOptions(true);
                sendInsightsUpsellEvent("Upgrade Options Button Clicked", upsellEventProperties);
              }}
            >
              View upgrade options
            </Button>
          )}
          {lowestRes && (
            <Button
              sx={{
                height: "43px",
                marginTop: "12px",
                width: "100%",
              }}
              href="https://help.doit.com/docs/governance/insights"
              target="_blank"
              variant="outlined"
              startIcon={<OpenInNewIcon />}
              size="large"
            >
              Learn more
            </Button>
          )}
        </Stack>
      </Stack>

      <Stack width="100%" maxWidth="1244px" alignItems="center" pt={5} mx="auto" px={isMobile ? 0 : 9.25}>
        {showCustom && (
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab label="Overview" value="overview" sx={{ textTransform: "none" }} />
              <Tab label="Insights list" value="list" sx={{ textTransform: "none" }} />
            </Tabs>
          </Box>
        )}

        {selectedTab !== "list" && (
          <Stack maxWidth={791} textAlign="center" mt={3.375}>
            <Typography variant="h4" fontWeight={500} fontSize={18} lineHeight="26px">
              See below your potential savings breakdown, as of {DateTime.now().toFormat("dd LLL yyyy")}:
            </Typography>
          </Stack>
        )}
      </Stack>

      {selectedTab !== "list" && (
        <>
          <Box maxWidth="1244px" mx="auto">
            <Divider sx={{ my: below1244 ? 6 : 2, mx: isMobile ? 0 : 9.25 }} />

            {showAws && (
              <Box sx={{ mb: 4, px: isMobile ? 0 : 9.25 }}>
                <Stack direction="row" mb={4}>
                  <CloudProvidersIcons cloudTags={["aws"]} style={{ height: 32, padding: "5px 0" }} />
                  <Typography
                    ml={1.5}
                    variant="h4"
                    fontWeight={500}
                    color="text.primary"
                    fontSize={18}
                    lineHeight={below1244 ? "26px" : "32px"}
                  >
                    Amazon Web Services
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    display: below1244 ? "flex" : "grid",
                    flexDirection: below1244 ? "column" : "none",
                    gridTemplateColumns: "2fr 3fr 2fr 1fr",
                  }}
                >
                  <Insight service="Service" title="Insight" potentialSavings="Potential savings" headerRow />

                  {insightsByCloud.aws?.map((service) => (
                    <Insight key={service.title} {...service} easyWinDescription={service.easyWinDescription} />
                  ))}
                </Box>
              </Box>
            )}

            {showGcp && (
              <Box sx={{ mb: 4, px: isMobile ? 0 : 9.25, mt: 6 }}>
                <Stack direction="row" mb={4}>
                  <CloudProvidersIcons cloudTags={["gcp"]} style={{ height: 32, padding: "5px 0" }} />
                  <Typography
                    ml={1.5}
                    variant="h4"
                    fontWeight={500}
                    color="text.primary"
                    fontSize={18}
                    lineHeight={below1244 ? "26px" : "32px"}
                  >
                    Google Cloud
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    display: below1244 ? "flex" : "grid",
                    flexDirection: below1244 ? "column" : "none",
                    gridTemplateColumns: "2fr 3fr 2fr 1fr",
                  }}
                >
                  {<Insight service="Service" title="Insight" potentialSavings="Potential savings" headerRow />}
                  {insightsByCloud.gcp?.map((service) => (
                    <Insight key={service.title} {...service} easyWinDescription={service.easyWinDescription} />
                  ))}
                </Box>
              </Box>
            )}

            {showSnowflake && (
              <Box sx={{ px: isMobile ? 0 : 9.25, mt: 6 }}>
                <Stack direction="row" mb={4}>
                  <CloudProvidersIcons cloudTags={["snowflake"]} style={{ height: 32, padding: "5px 0" }} />
                  <Typography
                    ml={1.5}
                    variant="h4"
                    fontWeight={500}
                    color="text.primary"
                    fontSize={18}
                    lineHeight={below1244 ? "26px" : "32px"}
                  >
                    Snowflake
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    display: below1244 ? "flex" : "grid",
                    flexDirection: below1244 ? "column" : "none",
                    gridTemplateColumns: "2fr 3fr 2fr 1fr",
                  }}
                >
                  {<Insight service="Service" title="Insight" potentialSavings="Potential savings" headerRow />}

                  {insightsByCloud.snowflake?.map((service) => (
                    <Insight key={service.title} {...service} easyWinDescription={service.easyWinDescription} />
                  ))}
                </Box>
              </Box>
            )}
          </Box>

          <Stack my={2} alignItems="center">
            <Button
              variant="contained"
              sx={{ background: "primary.main", width: isMobile ? "100%" : "199px", whiteSpace: "nowrap" }}
              size="large"
              onClick={() => {
                setShowUpgradeOptions(true);
                sendInsightsUpsellEvent("Upgrade Options Button Clicked", upsellEventProperties);
              }}
            >
              View upgrade options
            </Button>
          </Stack>
        </>
      )}

      {selectedTab === "list" && (
        <Box px={isMobile ? 0 : 9.25} maxWidth={below1244 ? "none" : "1244px"} mx="auto">
          <InsightsContextProvider>
            <InsightsList hideTitle hideFilters />
          </InsightsContextProvider>
        </Box>
      )}

      <UpgradeOptions
        open={showUpgradeOptions}
        onClose={() => {
          setShowUpgradeOptions(false);
        }}
        upsellEventProperties={upsellEventProperties}
      />
    </>
  );
};
