import { useCallback, useMemo } from "react";

import { type DateTime } from "luxon";

import { useContractFormContext } from "../../ContractsFormContext";
import { type DiscountPeriodType, type VendorContract } from "../../types";

export type UseDiscountPeriodsType = {
  handleAddPeriod: () => void;
  handlePeriodChange: (
    index: number
  ) => (property: keyof DiscountPeriodType, value: DateTime | number | boolean | null) => void;
  handleDeletePeriod: (index: number) => void;
  discountPeriods: DiscountPeriodType[];
  errors: Record<string, boolean>[];
};

export const useDiscountPeriods = (isVendorContract: boolean = false) => {
  const { state, setState } = useContractFormContext();

  const discountPeriods = useMemo(() => {
    if (isVendorContract) {
      return state.vendorContract?.discountPeriods ?? [];
    }
    return state.discountPeriods ?? [];
  }, [isVendorContract, state.discountPeriods, state.vendorContract]);

  const errors = useMemo(() => {
    if (isVendorContract) {
      return state.vendorContract?.errors.discountPeriods ?? [];
    }
    return state.errors.discountPeriods ?? [];
  }, [isVendorContract, state.errors.discountPeriods, state.vendorContract]);

  const updatePeriods = useCallback(
    (newDiscounts: DiscountPeriodType[], newErrors: Record<string, boolean>[]) => {
      if (isVendorContract) {
        setState((prev) => ({
          ...prev,
          vendorContract: {
            ...(prev.vendorContract as VendorContract),
            discountPeriods: newDiscounts,
            errors: { discountPeriods: newErrors },
          },
        }));

        return;
      }

      setState((prev) => ({
        ...prev,
        discountPeriods: newDiscounts,
        errors: { ...prev.errors, discountPeriods: [] },
      }));
    },
    [isVendorContract, setState]
  );

  const handlePeriodChange = useCallback(
    (index: number) => (property: keyof DiscountPeriodType, value: DateTime | number | boolean | null) => {
      const newDiscounts = [...discountPeriods];
      newDiscounts[index] = { ...newDiscounts[index], [property]: value };
      const newErrors = [...(errors ?? [])];
      newErrors[index] = {
        ...newErrors[index],
        [property]: false,
      };

      if (property === "hasNoEndDate") {
        newDiscounts[index].endDate = value ? null : newDiscounts[index].endDate;
        newErrors[index].endDate = false;
      }
      updatePeriods(newDiscounts, newErrors);
    },
    [discountPeriods, errors, updatePeriods]
  );

  const handleDeletePeriod = useCallback(
    (index: number) => {
      const newDiscounts = [...discountPeriods];
      newDiscounts.splice(index, 1);
      const newErrors = [...errors];
      newErrors.splice(index, 1);
      updatePeriods(newDiscounts, newErrors);
    },
    [discountPeriods, errors, updatePeriods]
  );

  const handleAddPeriod = useCallback(() => {
    const newDiscounts = [...discountPeriods];
    if (newDiscounts.length) {
      newDiscounts.push({
        startDate:
          discountPeriods[discountPeriods.length - 1].endDate ?? discountPeriods[discountPeriods.length - 1].startDate,
        endDate: null,
        discount: 0,
        hasNoEndDate: false,
      });
    } else {
      const contractStartDate = isVendorContract ? state.vendorContract!.startDate : state.startDate;
      const contractEndDate = isVendorContract ? state.vendorContract!.endDate : state.endDate;

      newDiscounts.push({ startDate: contractStartDate, endDate: contractEndDate, discount: 0, hasNoEndDate: false });
    }

    updatePeriods(newDiscounts, errors);
  }, [discountPeriods, updatePeriods, errors, isVendorContract, state.vendorContract, state.startDate, state.endDate]);

  return {
    handleAddPeriod,
    handlePeriodChange,
    handleDeletePeriod,
    discountPeriods: discountPeriods ?? [],
    errors: errors ?? [],
  };
};
