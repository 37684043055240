import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

export const snowflakeAssetRowCyIds = {
  accountId: "snowflake-asset-account-id",
  orgName: "snowflake-asset-org-name",
  statusActive: "snowflake-asset-status-active",
  statusInactive: "snowflake-asset-status-inactive",
};
export type SnowflakeAssetRowData = {
  accountId: string;
  orgName: string;
  status: "Active" | "Inactive";
};

export type SnowflakeAssetRowProps = { data: SnowflakeAssetRowData };

const SnowflakeAssetRow = ({ data }: SnowflakeAssetRowProps) => (
  <>
    <TableCell>
      <Typography variant="body2" component="span" data-cy="snowflake-asset-org-name">
        {data.orgName}
      </Typography>
    </TableCell>
    <TableCell
      sx={{
        display: {
          xs: "none",
          sm: "table-cell",
        },
      }}
    >
      <Typography data-cy="snowflake-asset-account-id" variant="body2">
        {data.accountId}
      </Typography>
    </TableCell>
    <TableCell
      sx={{
        display: {
          xs: "none",
          md: "table-cell",
        },
      }}
    >
      {data.status === "Active" ? (
        <Chip size="small" label="Active" color="success" data-cy="snowflake-asset-status-active" />
      ) : (
        <Chip size="small" label="Inactive" color="error" data-cy="snowflake-asset-status-inactive" />
      )}
    </TableCell>
  </>
);
export default SnowflakeAssetRow;
