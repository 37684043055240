import { useCallback, useEffect, useMemo, useState } from "react";

import { Aggregator, type ForecastSettings as ForecastSettingsType } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

import { forecastTxt } from "../../../../../assets/texts/CloudAnalytics/report";
import mixpanel from "../../../../../utils/mixpanel";
import { useReportConfig, useReportDimensionsContext } from "../../../Context";
import { ReportConfigKind } from "../../../reducers/ReportConfigReducer";
import { isTimeSeriesReport } from "../../../utilities";
import ForecastForm from "./ForecastForm";
import { getDefaultForecastSettings, getTimeDimensionsTooltipText } from "./utils";

const ForecastSettings = () => {
  const [formOpen, setFormOpen] = useState(false);
  const {
    reportConfig: { timeInterval, timeRangeOption, aggregator, forecastSettings },
    dispatchReportConfig,
  } = useReportConfig();

  const { dimensions = [] } = useReportDimensionsContext();

  const formDefaultSettings = useMemo(
    () => getDefaultForecastSettings(timeRangeOption, timeInterval),
    [timeInterval, timeRangeOption]
  );

  const timeSeriesReport = useMemo(() => isTimeSeriesReport(dimensions, timeInterval), [dimensions, timeInterval]);

  const forecastDisabled = useMemo(
    () => !timeSeriesReport || aggregator !== Aggregator.TOTAL,
    [aggregator, timeSeriesReport]
  );

  const tooltipText = useMemo(() => {
    if (!forecastDisabled) {
      return "";
    }
    if (!timeSeriesReport) {
      return getTimeDimensionsTooltipText(timeInterval);
    }
    if (aggregator !== Aggregator.TOTAL) {
      return forecastTxt.FORECAST_DISABLED_AGGREGATION_TOOLTIP;
    }
  }, [aggregator, forecastDisabled, timeInterval, timeSeriesReport]);

  const forecastApplied = !!forecastSettings;

  const [expanded, setExpanded] = useState(forecastApplied);

  const removeForecast = useCallback(() => {
    dispatchReportConfig({ type: ReportConfigKind.REMOVE_FEATURES_FORECAST });
  }, [dispatchReportConfig]);

  useEffect(() => {
    if (forecastDisabled && forecastApplied) {
      removeForecast();
    }
  }, [forecastApplied, forecastDisabled, removeForecast]);

  const closeForm = useCallback(() => {
    setFormOpen(false);
  }, []);

  const openForm = useCallback(() => {
    setFormOpen(true);
  }, []);

  const handleApply = useCallback(
    (settings: ForecastSettingsType) => {
      dispatchReportConfig({ payload: { forecastSettings: settings } });
      closeForm();
      setExpanded(true);
      mixpanel.track("forecast.apply", settings);
    },
    [dispatchReportConfig, closeForm]
  );

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation();
      removeForecast();
      setExpanded(false);
      mixpanel.track("forecast.remove");
    },
    [removeForecast]
  );

  return (
    <>
      <Tooltip
        title={tooltipText}
        data-cy="forecast-tooltip"
        slotProps={{
          tooltip: {
            sx: {
              width: "200px",
            },
          },
        }}
      >
        <Accordion
          disableGutters
          disabled={forecastDisabled}
          expanded={expanded}
          elevation={0}
          square
          sx={{
            marginBottom: "1.5rem",
            "&:before": {
              display: "none",
            },
            "& > .MuiAccordionSummary-root": {
              padding: 0,
              minHeight: "1.5rem",
              height: "1.5rem",
              marginBottom: "0.5rem",
              cursor: "default",
              "&.Mui-focusVisible": {
                backgroundColor: "unset",
              },
              "& > .MuiAccordionSummary-content": {
                margin: 0,
                alignItems: "center",
              },
            },
            "& .MuiAccordionDetails-root": {
              padding: 0,
              cursor: "pointer",
            },
            "&.MuiAccordion-root.Mui-disabled": {
              backgroundColor: "transparent",
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              forecastApplied &&
              !forecastDisabled && (
                <ExpandMoreIcon
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                />
              )
            }
            data-cy="forecast-menu"
            sx={{
              padding: 0,
              minHeight: "1.5rem",
              height: "1.5rem",
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              cursor: "pointer",
              "&.Mui-focusVisible": {
                backgroundColor: "unset",
              },
              "& > .MuiAccordionSummary-content": {
                margin: 0,
                alignItems: "center",
              },
            }}
          >
            <Box sx={{ justifyContent: "space-between", display: "flex", width: "100%" }}>
              <Typography sx={{ py: 1 }} variant="subtitle2" data-cy="forecast-menu-label">
                {forecastTxt.FORECAST_MENU_LABEL}
              </Typography>
              {!forecastApplied && (
                <Tooltip title={forecastTxt.ADD_FORECAST_TOOLTIP}>
                  <IconButton size="small" onClick={openForm} data-cy="add-forecast-button">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </AccordionSummary>
          {forecastApplied && !forecastDisabled && (
            <AccordionDetails>
              <Paper
                variant="outlined"
                data-cy="forecast-details"
                onClick={openForm}
                sx={{ display: "flex", px: 1, my: 0.5, verticalAlign: "middle", justifyContent: "space-between" }}
              >
                <Typography sx={{ py: 1 }} variant="subtitle2" data-cy="forecast-details-title">
                  {forecastTxt.FORECAST_DETAILS_TITLE}
                </Typography>
                <Box sx={{ py: 0.5 }}>
                  <IconButton onClick={handleDelete} size="small" data-cy="delete-forecast-button">
                    <ClearIcon fontSize="small" color="action" sx={{ pointerEvents: "none" }} />
                  </IconButton>
                </Box>
              </Paper>
            </AccordionDetails>
          )}
        </Accordion>
      </Tooltip>
      {formOpen && (
        <ForecastForm
          forecastSettings={forecastSettings ?? formDefaultSettings}
          handleApply={handleApply}
          handleClose={closeForm}
          timeInterval={timeInterval}
        />
      )}
    </>
  );
};

export default ForecastSettings;
