import { Box, Button, Link, Tooltip, Typography } from "@mui/material";

type Props = {
  awsAccountId?: string;
};

const OnboardingReadOnlyAccessButton = ({ awsAccountId }: Props) => {
  if (!awsAccountId) {
    return <></>;
  }

  return (
    <Tooltip
      title={
        <Typography>
          Click here to install IAM permissions to give DoiT read-only access.{" "}
          <Link
            sx={{ textDecoration: "none" }}
            href="https://help.doit.com/docs/services/consulting-support/aws-access"
          >
            Learn more
          </Link>
        </Typography>
      }
    >
      <Box>
        <Button
          variant="outlined"
          href={`https://console.aws.amazon.com/cloudformation/home?account=${awsAccountId}/stacks/create/review?templateURL=https://doit-support.s3.amazonaws.com/doit-support.json&stackName=doit-support-gateway`}
          data-cy="sauronButton"
        >
          DoiT Read Only Access onboarding
        </Button>
      </Box>
    </Tooltip>
  );
};

export default OnboardingReadOnlyAccessButton;
