import { type CachedHookExtra, useDynamicStore } from "./dynamicStore";

export type CachedHookArgs = Omit<CachedHookExtra, "hookFunc">;

export function useCachedHook<T>(hook: (...args: never[]) => T, extra?: CachedHookArgs): T {
  const storeKey = hook.name + (extra?.key ?? "");
  const value = useDynamicStore((state) => state[storeKey] as T);
  const loadHook = useDynamicStore((state) => state.loadHook);

  if (!value) {
    throw new Promise((resolve) => {
      setTimeout(() => {
        const extraWithHookFunc = { ...extra, hookFunc: hook };
        loadHook(storeKey, extraWithHookFunc);
        resolve(true);
      }, 0);
    });
  }

  return value;
}
