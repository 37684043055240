import { type FC } from "react";

import { type ListApiServiceModelDescriptor, type Member, ModelType } from "@doitintl/cmp-models";
import { FormHelperText, Stack } from "@mui/material";
import { FieldArray, type FieldInputProps, getIn, isString, useFormikContext } from "formik";

import { GenericForm } from "../ApiActionParametersForm";
import { getInitialValueForModel } from "../useApiActionParametersSchema";
import { AppendButton } from "./common/AppendButton";
import { GuidingLineConnector } from "./common/GuidingLineConnector";
import { Fieldset } from "./wrappers/Fieldset";

export const ListParam: FC<{
  fieldPath: string;
  fieldProps: FieldInputProps<unknown[]>;
  label: string;
  onRemove?: () => void;
  inputModel: ListApiServiceModelDescriptor<Member>;
  isFormItem?: boolean;
}> = ({ fieldPath, fieldProps, label, onRemove, inputModel, isFormItem }) => {
  const { errors } = useFormikContext();
  const errorMessage = getIn(errors, fieldProps.name);

  if (!Array.isArray(fieldProps.value)) {
    return null;
  }

  return (
    <Fieldset label={`List of ${label}`} onRemove={onRemove} nestedInList isFormItem={isFormItem}>
      <FieldArray
        name={fieldProps.name}
        render={(arrayHelpers) => (
          <Stack>
            {fieldProps.value.map((_, index) => (
              <Stack key={fieldPath + index.toString()} direction={"column"}>
                {![ModelType.STRUCTURE, ModelType.LIST, ModelType.MAP, ModelType.UNION].includes(
                  inputModel.member.model.type
                ) && <GuidingLineConnector />}
                <GenericForm
                  key={fieldPath + index.toString()}
                  inputModel={inputModel.member.model}
                  fieldPath={`${fieldPath}[${index}]`}
                  label={`${label} ${index + 1}`}
                  isListItem={true}
                  onRemove={() => arrayHelpers.remove(index)}
                />
              </Stack>
            ))}
            {isString(errorMessage) && <FormHelperText error>{errorMessage}</FormHelperText>}
            <AppendButton
              onClick={() => {
                arrayHelpers.push(getInitialValueForModel(inputModel.member.model));
              }}
            >
              Append {inputModel.memberName}
            </AppendButton>
          </Stack>
        )}
      />
    </Fieldset>
  );
};
