import {
  APP_KEY,
  CloudFlowNodeType,
  type CustomerModel,
  NODE_STATUS,
  type NodeModel,
  type NodeTransition,
} from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  type QueryDocumentSnapshotModel,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import { type FirebaseModelReference } from "@doitintl/models-firestore/src/core";
import { type Edge, getConnectedEdges, type Node } from "@xyflow/react";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

import { EDGE_TYPE, type NodeConfigs, type RFNode } from "../../types";
import { createEdge, createFalsePathEdge, createTruePathEdge } from "./edgeUtils";
import { getPreviousNodesPath } from "./getPreviousNodesPath";
import { isGhostNode } from "./nodeUtils";

export type CloudFlowNode<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> = {
  id: string;
  data: NodeModel<TNodeType>;
};

export const transformNodeData = (
  data: WithFirebaseModel<NodeModel>,
  snapshot: QueryDocumentSnapshotModel<NodeModel> | DocumentSnapshotModel<NodeModel>,
  customerRef: FirebaseModelReference<CustomerModel>
) => ({
  data: { ...data, customer: customerRef },
  id: snapshot.id,
  ref: snapshot.ref,
});

export const isLeafNode = (node: CloudFlowNode): boolean =>
  !node.data.transitions || node.data.transitions.length === 0;

export const isNodeBranching = (node: CloudFlowNode): boolean => node.data.transitions?.length === 2;

const createGhostNode = (id: string): CloudFlowNode =>
  ({
    data: {
      type: CloudFlowNodeType.GHOST,
    },
    id,
  }) as unknown as CloudFlowNode;

export const getGhostNodes = (node: CloudFlowNode) => {
  const ghostNodes: CloudFlowNode[] = [];
  if (node.data.type === CloudFlowNodeType.CONDITION) {
    ghostNodes.push(createGhostNode(`${node.id}-true-ghost`), createGhostNode(`${node.id}-false-ghost`));
  } else {
    ghostNodes.push(createGhostNode(`${node.id}-ghost`));
  }
  return ghostNodes;
};

export const getGhostTransitions = (node: CloudFlowNode) => {
  if (node.data.type === CloudFlowNodeType.CONDITION) {
    const trueTransition = {
      targetNodeId: `${node.id}-true-ghost`,
      label: "True",
    };
    const falseTransition = {
      targetNodeId: `${node.id}-false-ghost`,
      label: "False",
    };
    return [trueTransition, falseTransition];
  }

  return [
    {
      targetNodeId: `${node.id}-ghost`,
    },
  ];
};

export const mapLeafNodesWithGhosts = (cloudflowNodes: CloudFlowNode[] = [], firstNodeId: string): CloudFlowNode[] => {
  const filterOrphanNodes = cloudflowNodes.filter((node) => {
    const previousNodes = getPreviousNodesPath(node.id, cloudflowNodes);
    const isOrphanNode = previousNodes.length === 0 && node.id !== firstNodeId;
    return !isOrphanNode;
  });

  const nodesWithGhosts: CloudFlowNode[] = filterOrphanNodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      transitions: node.data.transitions ? [...node.data.transitions] : [],
    },
  }));

  nodesWithGhosts.forEach((node: CloudFlowNode) => {
    if (isLeafNode(node) && !isGhostNode(node.data)) {
      nodesWithGhosts.push(...getGhostNodes(node));
      node.data.transitions = [...(node.data.transitions || []), ...getGhostTransitions(node)];
    } else if (node.data.type === CloudFlowNodeType.CONDITION && !isNodeBranching(node)) {
      const exitingTransitionLabel = node.data.transitions?.[0].label;
      const ghostNodeId = exitingTransitionLabel === "True" ? `${node.id}-false-ghost` : `${node.id}-true-ghost`;
      const ghostLabel = exitingTransitionLabel === "True" ? "False" : "True";

      nodesWithGhosts.push(createGhostNode(ghostNodeId));
      node.data.transitions = [
        ...(node.data.transitions || []),
        {
          targetNodeId: ghostNodeId,
          label: ghostLabel,
        },
      ];
    }
  });

  return nodesWithGhosts;
};

export const mapCloudFlowNodes = (cloudflowNodes?: CloudFlowNode[]): Node<RFNode>[] =>
  cloudflowNodes?.map((node: CloudFlowNode) => ({
    id: node.id,
    type: node.data.type || CloudFlowNodeType.ACTION,
    position: node.data.display?.position || { x: 0, y: 0 },
    data: {
      httpOperationLoading: false,
      touched: false,
      nodeData: node.data,
    },
  })) || [];

// Maps Cloudflow transitions to edges
export const mapTransitionsToEdges = (cloudflowNodes?: CloudFlowNode[]) =>
  cloudflowNodes?.reduce((edges: Edge[], node: CloudFlowNode) => {
    if (node.data.transitions) {
      const nodeEdges = node.data.transitions.map((transition: NodeTransition) => {
        if (node.data.type === CloudFlowNodeType.CONDITION) {
          return transition.label === "True"
            ? createTruePathEdge(node.id, transition.targetNodeId)
            : createFalsePathEdge(node.id, transition.targetNodeId);
        }
        const childNode = cloudflowNodes.find((n) => n.id === transition.targetNodeId);

        const edgeType = childNode && isGhostNode(childNode?.data) ? EDGE_TYPE.GHOST : EDGE_TYPE.CUSTOM;
        return createEdge(node.id, transition.targetNodeId, edgeType);
      });
      return edges.concat(nodeEdges);
    }
    return edges;
  }, []);

export const sortEdgesByHandle = (edges: Edge[]): Edge[] => {
  const getHandleSuffix = (handle?: string | null) => {
    if (!handle) return "";
    if (handle.endsWith("-true")) return "true";
    if (handle.endsWith("-false")) return "false";
    return "none";
  };

  const order = { true: 1, false: 2, none: 3 };

  return edges.sort((a, b) => {
    const suffixA = getHandleSuffix(a.sourceHandle);
    const suffixB = getHandleSuffix(b.sourceHandle);

    return (order[suffixA] || 0) - (order[suffixB] || 0);
  });
};

export const mapEdgesToTransitions = (node: NodeConfigs, edges: Edge[]) => {
  const transitions = node.transitions || [];
  const connectedEdges = getConnectedEdges([node as unknown as Node<RFNode>], edges);
  const edgeTransitions = connectedEdges
    .filter((edge) => edge.target !== node.id)
    .map((edge) => ({
      targetNodeId: edge.target,
      label: edge.data?.label || null,
    }));
  return [...transitions, ...edgeTransitions];
};

export const mapToCreateNodePayload = <TNodeType extends CloudFlowNodeType>(
  node: Node<RFNode<CloudFlowNodeType>>,
  targetNode?: Node<RFNode>
) => ({
  id: node.id,
  type: node.type as TNodeType,
  name: node.data.nodeData.name,
  appKey: node.data.nodeData.appKey || APP_KEY.INTERNAL,
  status: node.data.nodeData.status || NODE_STATUS.PENDING,
  errorMessages: node.data.nodeData.errorMessages,
  display: node.data.nodeData.display,
  parameters: node.data.nodeData.parameters,
  transitions:
    targetNode && isGhostNode(targetNode)
      ? null
      : targetNode
        ? [
            {
              targetNodeId: targetNode.id,
            },
          ]
        : null,
});

export const mapToUpdateNodePayload = (node: Node<RFNode<CloudFlowNodeType>>) => {
  // Remove references 'createdBy' and 'action' from update payload
  const { createdBy, ...rest } = node.data.nodeData;
  const transitions = rest.transitions || [];

  // Filter out transitions pointing to ghost nodes
  const validTransitions = transitions.filter((t) => !t.targetNodeId.includes("-ghost"));

  return {
    id: node.id,
    ...rest,
    transitions: validTransitions.length ? validTransitions : null,
  };
};

export const mergeCloudflowNodes = (
  cloudflowNodes: Node<RFNode<CloudFlowNodeType>>[],
  localStateNodes: Node<RFNode<CloudFlowNodeType>>[],
  handleAddNode: (nodeType: CloudFlowNodeType, nodeId: string) => void,
  handleEditNode: (node: Node<RFNode<CloudFlowNodeType>>) => void,
  handleDeleteNode: (nodeId: string) => void
) =>
  cloudflowNodes.map((node) => {
    const nodeLocalState = localStateNodes.find((n) => n.id === node.id);
    const isInitialLoading = localStateNodes.length === 0;

    if (nodeLocalState) {
      const keysToOmit = ["transitions", "createdAt", "updatedAt", "createdBy", "customer"];

      const relevantLocalNodeData = omit(nodeLocalState.data.nodeData, keysToOmit);
      const relevantCloudflowNodeData = omit(node.data.nodeData, keysToOmit);

      // If values are matching, then use the firestore node
      if (isEqual(relevantLocalNodeData, relevantCloudflowNodeData)) {
        return {
          ...node,
          selected: nodeLocalState.selected,
          data: {
            ...node.data,
            onAddNode: handleAddNode,
            onDeleteNode: () => {
              handleDeleteNode(node.id);
            },
            onEditNode: () => {
              handleEditNode(node);
            },
          },
        };
      } else {
        // Merge the cloudflow node with the local state node
        return {
          ...nodeLocalState,
          data: {
            ...node.data,
            ...nodeLocalState.data,
            nodeData: {
              ...node.data.nodeData,
              ...nodeLocalState.data.nodeData,
              transitions: node.data.nodeData.transitions,
            },
          },
        };
      }
    }
    // append the node to the local state
    return {
      ...node,
      selected:
        node.type !== CloudFlowNodeType.START_STEP && node.type !== CloudFlowNodeType.GHOST && !isInitialLoading,
      selectable: node.type !== CloudFlowNodeType.GHOST,
      focusable: node.type !== CloudFlowNodeType.START_STEP,
      data: {
        ...node.data,
        onAddNode: handleAddNode,
        onDeleteNode: () => {
          handleDeleteNode(node.id);
        },
        onEditNode: () => {
          handleEditNode(node);
        },
      },
    };
  });
