import { type Cluster } from "@doitintl/cmp-models";

export const splitActiveAndInactiveClusters = (clusters?: Cluster[]) => {
  const activeClusters: Cluster[] = [];
  const inactiveClusters: Cluster[] = [];

  clusters?.forEach((cluster) => {
    (cluster.isActivated ? activeClusters : inactiveClusters).push(cluster);
  });

  return { activeClusters, inactiveClusters };
};
