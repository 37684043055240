import { useCallback, useEffect } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import { type DateTime } from "luxon";

import { contractsText } from "../../../../../../assets/texts";
import { type CommitmentPeriodType } from "../../../types";
import CommitmentPeriod from "./CommitmentPeriod";

type Props = {
  commitmentPeriods: CommitmentPeriodType[];
  onChange: (commitmentPeriods: CommitmentPeriodType[]) => void;
  contractStartDate: DateTime;
  contractEndDate: DateTime | null;
  disabled: boolean;
  showDiscount: boolean;
};

export const CommitmentPeriodsList = ({
  commitmentPeriods,
  onChange,
  contractStartDate,
  contractEndDate,
  disabled,
  showDiscount,
}: Props) => {
  const handleAddCommitmentPeriod = useCallback(() => {
    let startDate = contractStartDate;
    let endDate = contractEndDate;

    if (commitmentPeriods.length > 0) {
      const prevPeriod = commitmentPeriods[commitmentPeriods.length - 1];
      startDate = prevPeriod.endDate;
      endDate = prevPeriod.endDate.isValid ? prevPeriod.endDate.plus({ years: 1 }) : null;

      if (contractEndDate && endDate && endDate > contractEndDate) {
        endDate = contractEndDate;
      }
    }

    onChange([
      ...commitmentPeriods,
      {
        value: null,
        discount: null,
        errors: {},
        startDate,
        endDate: endDate as DateTime,
      },
    ]);
  }, [commitmentPeriods, contractEndDate, contractStartDate, onChange]);

  const handleRemoveCommitmentPeriod = useCallback(
    (index: number) => () => {
      const newCommitmentPeriods = commitmentPeriods.slice();
      newCommitmentPeriods.splice(index, 1);
      onChange(newCommitmentPeriods);
    },
    [commitmentPeriods, onChange]
  );

  const handleUpdateCommitmentPeriod = useCallback(
    (index: number, data: CommitmentPeriodType) => {
      const newCommitmentPeriods = commitmentPeriods.slice();
      newCommitmentPeriods.splice(index, 1, data);
      onChange(newCommitmentPeriods);
    },
    [commitmentPeriods, onChange]
  );

  useEffect(() => {
    if (commitmentPeriods.length === 0) {
      handleAddCommitmentPeriod();
    }
  }, [commitmentPeriods.length, handleAddCommitmentPeriod]);

  return (
    <>
      {commitmentPeriods.map((data, i) => {
        const minDate = i === 0 ? contractStartDate : commitmentPeriods[i - 1]?.endDate;
        const maxDate = contractEndDate;
        return (
          <CommitmentPeriod
            key={i}
            index={i}
            data={data}
            onRemove={handleRemoveCommitmentPeriod}
            onChange={handleUpdateCommitmentPeriod}
            minDate={minDate}
            maxDate={maxDate}
            showDiscount={showDiscount}
            disabled={disabled}
            disableDelete={commitmentPeriods.length === 1}
          />
        );
      })}
      <Grid
        container
        sx={{
          justifyContent: "flex-end",
          mt: 2,
        }}
        size="grow"
      >
        <Grid size={5}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            fullWidth
            onClick={handleAddCommitmentPeriod}
            disabled={
              (commitmentPeriods.length > 0 &&
                contractEndDate &&
                commitmentPeriods[commitmentPeriods.length - 1]?.endDate >= contractEndDate) ||
              disabled
            }
          >
            {contractsText.ADD_COMMITMENT_PERIOD_BUTTON_TEXT}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
