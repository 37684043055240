import { type FC, useCallback, useState } from "react";

import { type Member, ModelType, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { Stack } from "@mui/system";
import { type FieldInputProps, useFormikContext } from "formik";

import { AddParametersButton } from "../AddParametersButton";
import { GenericForm } from "../ApiActionParametersForm";
import { getInitialValueForModel } from "../useApiActionParametersSchema";
import { GuidingLineConnector } from "./common/GuidingLineConnector";
import { Fieldset } from "./wrappers/Fieldset";

export const StructureParam: FC<{
  fieldPath: string;
  fieldProps: FieldInputProps<string>;
  label: string;
  onRemove?: () => void;
  inputModel: StructureApiServiceModelDescriptor<Member>;
  isListItem?: boolean;
}> = ({ fieldPath, fieldProps, label, onRemove, inputModel, isListItem }) => {
  const formikProps = useFormikContext();
  const [membersSortOrder, setMembersSortOrder] = useState<string[]>([]);
  const [hasVisibleButton, setHasVisibleButton] = useState(false);

  const membersSortOrderComparator = useCallback(
    (a, b) => membersSortOrder.indexOf(a) - membersSortOrder.indexOf(b),
    [membersSortOrder]
  );

  const membersToRender = Object.entries(fieldProps.value)
    .filter(([, value]) => value !== undefined)
    .map(([memberName]) => memberName)
    .sort(membersSortOrderComparator);

  const getMemberPath = (memberName: string) => (fieldPath ? `${fieldPath}.${memberName}` : memberName);

  const createRemoveFn = (memberName: string) => () => {
    setMembersSortOrder((currentSortOrder) => currentSortOrder.filter((sortedMember) => sortedMember !== memberName));
    formikProps.setFieldValue(getMemberPath(memberName), undefined);
  };

  return (
    <Fieldset label={label} onRemove={onRemove} nestedInList={hasVisibleButton} isListItem={isListItem}>
      <Stack>
        {membersToRender.map((memberName) => {
          const memberPath = getMemberPath(memberName);
          const onRemove = inputModel.requiredMembers?.includes(memberName) ? undefined : createRemoveFn(memberName);
          const requiredMembers = inputModel.requiredMembers?.length || 0 > 1;
          const isStructModel = inputModel.members[memberName].model.type === ModelType.STRUCTURE;
          const isNestedField = [ModelType.STRUCTURE, ModelType.LIST, ModelType.MAP, ModelType.UNION].includes(
            inputModel.members[memberName].model.type
          );
          const showConnector = hasVisibleButton && fieldPath;
          const isIndented = !isNestedField && requiredMembers && !hasVisibleButton;
          return (
            <Stack
              key={memberName}
              direction={"column"}
              ml={isIndented && fieldPath ? -2 : 0}
              sx={{
                pt: 1,
                "&:first-child": {
                  pt: 0,
                },
              }}
            >
              {showConnector && <GuidingLineConnector sx={{ top: isStructModel ? 24 : 36 }} />}
              <GenericForm
                key={memberName}
                inputModel={inputModel.members[memberName].model}
                fieldPath={memberPath}
                label={memberName}
                onRemove={onRemove}
              />
            </Stack>
          );
        })}
        <AddParametersButton
          alreadyAdded={membersToRender}
          label={label}
          members={inputModel.members}
          onMembersAdd={(membersToAdd) => {
            setMembersSortOrder((currentSortOrder) => currentSortOrder.concat(membersToAdd));
            membersToAdd.forEach((memberNameToAdd) => {
              const memberToAddPath = getMemberPath(memberNameToAdd);
              const memberToAddDefaultValue = getInitialValueForModel(inputModel.members[memberNameToAdd].model);
              formikProps.setFieldValue(memberToAddPath, memberToAddDefaultValue);
            });
          }}
          onButtonStateChange={setHasVisibleButton}
        />
      </Stack>
    </Fieldset>
  );
};
