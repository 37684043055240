import { useEffect, useState } from "react";

import { getNodeOutputModel, type NodeModelWithId } from "@doitintl/cloudflow-commons";
import { CloudFlowNodeType, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { type Node, useReactFlow } from "@xyflow/react";

import { type NodeModelWithIdAndStep, type RFNode } from "../../../types";
import { usePromiseStateSetter } from "./usePromiseStateSetter";
import { getOutputModelByOperationPointer } from "./useUnwrappedApiActionModel";

type NodeWitOutputModel = {
  id: string;
  name: string;
  stepNumber: number;
  outputModel: UnwrappedApiServiceModelDescriptor;
};

function getPreviousNodes(allNodes: NodeModelWithId[], currentNodeId: string) {
  const previousNodes: NodeModelWithIdAndStep[] = [];
  let parentNode: NodeModelWithId | undefined = allNodes.find(({ id }) => currentNodeId === id);

  while (
    (parentNode = allNodes.find(({ transitions }) =>
      transitions?.some(({ targetNodeId }) => targetNodeId === parentNode?.id)
    ))
  ) {
    if (
      [
        CloudFlowNodeType.ACTION,
        CloudFlowNodeType.FILTER,
        CloudFlowNodeType.CONDITION,
        CloudFlowNodeType.TRANSFORMATION,
      ].includes(parentNode.type)
    ) {
      previousNodes.unshift(parentNode);
    }
  }

  return previousNodes;
}

function isNodeWithOutputModel(node: {
  id: string;
  name: string;
  outputModel: UnwrappedApiServiceModelDescriptor | null;
}): node is NodeWitOutputModel {
  return node.outputModel !== null;
}

function getActionNodeModel(
  node: NodeModelWithId<CloudFlowNodeType.ACTION>
): Promise<UnwrappedApiServiceModelDescriptor | null> {
  return getOutputModelByOperationPointer(node.parameters.operation);
}

export function useReferenceableNodes(currentNodeId: string): [NodeWitOutputModel[], boolean] {
  const { getNodes } = useReactFlow<Node<RFNode, CloudFlowNodeType>>();
  const setPromiseState = usePromiseStateSetter();
  const [loading, setLoading] = useState(true);
  const [referenceableNodes, setReferenceableNodes] = useState<NodeWitOutputModel[]>([]);

  useEffect(() => {
    setLoading(true);
    const allNodes = getNodes().map(
      (node): NodeModelWithIdAndStep => ({
        id: node.id,
        stepNumber: node.data.stepNumber,
        name: node.data.nodeData.name,
        parameters: node.data.nodeData.parameters!,
        transitions: node.data.nodeData.transitions,
        type: node.data.nodeData.type,
      })
    );

    const previousNodes = getPreviousNodes(allNodes, currentNodeId);

    const referenceableNodes = (async () =>
      (
        await Promise.all(
          previousNodes.map(async (node) => ({
            id: node.id,
            name: node.name,
            stepNumber: node.stepNumber,
            outputModel: await getNodeOutputModel(getActionNodeModel, allNodes, node.id),
          }))
        )
      ).filter(isNodeWithOutputModel))();

    return setPromiseState(referenceableNodes, (nodes) => {
      setReferenceableNodes(nodes);
      setLoading(false);
    });
  }, [currentNodeId, getNodes, setPromiseState]);

  return [referenceableNodes, loading];
}
