import React, { useEffect, useState } from "react";

import { Stack, Typography, useMediaQuery } from "@mui/material";

import { useAsyncCurrency } from "../../../Components/hooks/useCurrency";
import HtmlTooltip from "../../../Components/Tooltip/HtmlTooltip";
import { formatDecimalNumber } from "../../../utils/common";
import { useInsightsContext } from "../context";
import { EasyWinChip } from "../EasyWin/EasyWin";
import { type Insight, type SavingsPeriod } from "../types";
import { totalCustomProblems, totalPotentialDailySavings } from "../utils";

export const savingsPeriodToLabel = {
  daily: "day",
  monthly: "month",
  yearly: "year",
};

export const PotentialSavingsPreview = ({
  insight,
  savingsPeriod,
}: {
  insight: Insight;
  savingsPeriod: SavingsPeriod;
}) => {
  const { calculateSavingsForSelectedPeriod } = useInsightsContext();

  const { asyncConvertCurrency, customerCurrencySymbol } = useAsyncCurrency();

  const [convertedAndFormatted, setConvertedAndFormatted] = useState<string>();

  const potentialDailySavings = totalPotentialDailySavings(insight);

  useEffect(() => {
    if (!potentialDailySavings) {
      return;
    }

    const potentialDailySavingsForPeriod = calculateSavingsForSelectedPeriod(potentialDailySavings);

    asyncConvertCurrency(potentialDailySavingsForPeriod).then((convertedSavings) => {
      setConvertedAndFormatted(`${customerCurrencySymbol}${formatDecimalNumber(convertedSavings, 0)}`);
    });
  }, [asyncConvertCurrency, calculateSavingsForSelectedPeriod, customerCurrencySymbol, insight, potentialDailySavings]);

  const customProblems = totalCustomProblems(insight);

  const isMobile = useMediaQuery("(max-width:650px)");

  if (!potentialDailySavings && !customProblems) {
    return null;
  }

  const additionalBenefitsCount = insight.customInsightAttributes
    ? insight.results?.customProblems?.breakdown?.dimensions.length
    : (customProblems || 0) > 0
      ? 1
      : 0;

  if (!potentialDailySavings && customProblems) {
    return (
      <Stack direction="column" spacing={1.5} alignItems={isMobile ? "flex-start" : "flex-end"}>
        <Typography
          variant="body2"
          fontSize={14}
          lineHeight="22px"
          color="text.secondary"
          sx={{ whiteSpace: "nowrap" }}
        >
          {customProblems === 1
            ? insight.results?.customProblems?.unitLongSingular
            : insight.results?.customProblems?.unitLongPlural}
        </Typography>
        <Stack direction="row" alignItems="flex-end" sx={{ mt: "0!important" }}>
          <Typography variant="h2" color="text.primary" fontSize={22} lineHeight="30px" sx={{ mt: "0!important" }}>
            {formatDecimalNumber(customProblems, 0)}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  const customProblemsList = totalCustomProblems
    ? insight.results?.customProblems?.breakdown?.data.map((problem) => {
        const customProblemsTotal = insight.customInsightAttributes ? problem.value : totalCustomProblems(insight);

        const customProblemsUnit = insight.customInsightAttributes
          ? problem.dimensionValues[0]
          : insight.results?.customProblems?.unitLongPlural;

        return (
          <li key={`${customProblemsTotal}-${customProblemsUnit}`}>
            <Typography mb={2} sx={{ fontSize: "14px" }}>
              {customProblemsTotal} {customProblemsUnit}
            </Typography>
          </li>
        );
      })
    : null;

  return (
    <Stack
      direction="column"
      spacing={1.5}
      alignItems={isMobile ? "flex-start" : "flex-end"}
      order={isMobile ? "initial" : 1}
    >
      <Typography variant="body2" fontSize={14} lineHeight="22px" color="text.secondary" sx={{ whiteSpace: "nowrap" }}>
        Potential savings
      </Typography>

      <Stack direction="row" alignItems="flex-end" sx={{ mt: "0!important" }}>
        <Typography
          data-cy="potential-saving"
          variant="h2"
          color="text.primary"
          fontSize={22}
          lineHeight="30px"
          sx={{ mt: "0!important" }}
        >
          {convertedAndFormatted}
        </Typography>
        <Typography variant="subtitle2" fontSize={14} lineHeight="25px" color="text.primary" sx={{ display: "inline" }}>
          /{savingsPeriodToLabel[savingsPeriod]}
        </Typography>
      </Stack>

      {potentialDailySavings && insight.easyWinDescription && (
        <EasyWinChip tooltipText={insight.easyWinDescription} tooltipPlacement={"left-start"} />
      )}

      {customProblemsList && (
        <HtmlTooltip
          slotProps={{
            tooltip: {
              sx: {
                padding: "16px 16px 16px 10px",
              },
            },
          }}
          title={<ul style={{ paddingInlineStart: "20px", margin: 0 }}>{customProblemsList}</ul>}
          placement="bottom"
        >
          <Typography
            variant="caption"
            fontSize={12}
            lineHeight="20px"
            color="text.secondary"
            mt="0!important"
            sx={{ borderBottom: "1px dotted" }}
          >
            + {additionalBenefitsCount} additional benefits
          </Typography>
        </HtmlTooltip>
      )}
    </Stack>
  );
};
