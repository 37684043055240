import { CloudAnalyticsModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";

import { useCachedHook } from "../../../Context/DynamicContext/useCachedHook";

function useNonCachedDataHubMetrics(customerId: string) {
  return useDocumentDataOnce(
    getCollection(CloudAnalyticsModel).doc("metrics").collection("datahubMetrics").doc(customerId)
  );
}

export function useDataHubMetrics(customerId: string) {
  return useCachedHook(useNonCachedDataHubMetrics, {
    key: customerId,
    args: [customerId],
  });
}
