import { useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsResourceType } from "@doitintl/cmp-models";
import { Box, Button, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { globalText } from "../../../assets/texts";
import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import { helpURLs } from "../../../assets/urls";
import { LearnMoreAlert } from "../../../Components/Alerts";
import { DoitConsoleTitle } from "../../../Components/DoitConsoleTitle";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import Hide from "../../../Components/HideChildren/Hide";
import { useAttributionGroups } from "../../../Components/hooks/cloudAnalytics/attributionGroups/useAttributionGroups";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useAuthContext } from "../../../Context/AuthContext";
import { useIsFeatureEntitled, useTier } from "../../../Context/TierProvider";
import { useUserContext } from "../../../Context/UserContext";
import { type AttributionWRef } from "../../../types";
import mixpanel from "../../../utils/mixpanel";
import { useUserEmailNotification } from "../../UserView/UserViewTabs/useUserEmailNotification";
import { type AttributionGroupWithRef } from "../attributionGroups/types";
import { useLabels } from "../labels/hooks";
import { isOwner, useCanEditSelectedObjectsPermissions } from "../utilities";
import { filters, headerColumns } from "./AllocationBrowserColumns";
import { AllocationRow } from "./AllocationBrowserRow";
import { useCreateAllocationHandler } from "./hooks";

export type AllocationWRefRowItem =
  | (AttributionGroupWithRef & {
      anomalyDetectionText?: string;
      subscribed?: boolean;
      owner?: string;
      type?: string;
    })
  | (AttributionWRef & {
      anomalyDetectionText?: string;
      subscribed?: boolean;
      owner?: string;
      type?: string;
    });

const { attributions: cypressAttributionIds } = cloudAnalytics;

export const AttributionInfo = ({ onHide }: { onHide?: () => void }) => (
  <Hide mdDown>
    <LearnMoreAlert
      text={allocationTxt.LEARN_MORE_ALERT}
      url={helpURLs.CLOUD_ANALYTICS_ATTRIBUTIONS}
      onClose={onHide}
    />
  </Hide>
);

const isUserOwner = (selected: AllocationWRefRowItem[], email: string) =>
  selected.every((s) => isOwner(email, s.data) && s.data.type === AnalyticsResourceType.CUSTOM);

const AllocationBrowser = () => {
  const { filteredAttributions: filteredAttributionsByTier, attributionsLoading: loading } = useAttributionsContext();

  const { hasEKSData } = useCloudAnalyticsContext();
  const { getFeatureKey } = useTier();

  const isEntitledAnalytics = useIsFeatureEntitled("analytics:attributions");
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { userModel } = useUserContext({ allowNull: false });
  const userDailyDigests = useMemo(() => userModel.dailyDigests?.map((d) => d.id) || [], [userModel.dailyDigests]);
  const [labels, labelsLoading] = useLabels();
  const [selected, setSelected] = useState<AllocationWRefRowItem[]>([]);
  const [attributionGroups] = useAttributionGroups();
  const routeMatchURL = useRouteMatchURL();

  // TODO: Disabled until further implementation of functionality
  // const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // const [shareDialogOpen, setShareDialogOpen] = useState(false);
  // const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const handleShare = useCallback(
    (row) => () => {
      setSelected([row]);
      // TODO: Disabled until further implementation of functionality
      // setShareDialogOpen(true);
    },
    []
  );

  const handleDeleteDialogOpen = useCallback(() => {
    // TODO: Disabled until further implementation of functionality
    // setDeleteDialogOpen(true);
  }, []);

  const handleShareDialogOpen = useCallback(() => {
    // TODO: Disabled until further implementation of functionality
    // setShareDialogOpen(true);
  }, []);

  const handleSettings = useCallback(
    (row) => () => {
      setSelected([row]);
      // TODO: Disabled until further implementation of functionality
      // setIsSettingsModalOpen(true);
    },
    []
  );

  const filteredAttributions = useMemo(
    () =>
      filteredAttributionsByTier.filter((attr) => {
        const entitlementsKeys = attr.data.entitlements?.map((entitlement) => getFeatureKey(entitlement)) ?? [];
        const isEntitlementAttribution = attr.data.entitlements && attr.data.entitlements.length > 0;
        const isEKSAttribution = entitlementsKeys.includes("pdi:eks");
        const hasGKEEntitlement = entitlementsKeys.includes("pdi:gke:costAllocation");
        return (hasEKSData && isEKSAttribution) || hasGKEEntitlement || !isEntitlementAttribution;
      }),
    [filteredAttributionsByTier, getFeatureKey, hasEKSData]
  );

  const { updateUserEmailNotification, userEmailNotification } = useUserEmailNotification(userModel.ref);

  useEffect(() => {
    mixpanel.track("analytics.allocations.list");
  }, []);

  const RowWrapper = useCallback(
    ({ row }: { row: AllocationWRefRowItem }) => (
      <AllocationRow
        row={row}
        labels={labels}
        handleShare={handleShare(row)}
        handleSettings={handleSettings(row)}
        userEmailNotification={userEmailNotification}
        updateUserEmailNotification={updateUserEmailNotification}
      />
    ),
    [labels, handleShare, handleSettings, userEmailNotification, updateUserEmailNotification]
  );

  const getOwner = useCallback(
    (attrData: AllocationWRefRowItem["data"]) =>
      attrData.collaborators.find((collaborator) => collaborator.role === "owner")?.email || "",
    []
  );

  const isGroup = useCallback((value) => {
    if (value) {
      return value.attributions || value.anomalyDetection;
    }
  }, []);

  const tableAllocations = useMemo<AllocationWRefRowItem[]>(() => {
    const allocations: (AttributionGroupWithRef | AttributionWRef)[] = [...attributionGroups, ...filteredAttributions];
    return allocations.map((attr) => ({
      ...attr,
      anomalyDetectionText: isGroup(attr.data) ? "Active" : globalText.NA,
      subscribed: userDailyDigests.includes(attr.ref.id),
      type: isGroup(attr.data) ? "Group" : "Single",
      owner: getOwner(attr.data),
    }));
  }, [attributionGroups, filteredAttributions, getOwner, isGroup, userDailyDigests]);

  const notOwner = !isUserOwner(selected, currentUser.email);

  const deleteButtonTooltip = useMemo(() => {
    if (notOwner) {
      return allocationTxt.CANNOT_DELETE;
    }
    if (selected.map((s) => s.data.type).some((s) => s === AnalyticsResourceType.PRESET)) {
      return allocationTxt.ALLOCATION_PRESET_NOT_DELETE;
    }
    return "";
  }, [notOwner, selected]);

  const disableDeleteAttribution = useMemo<boolean>(
    () => notOwner || !selected.length || selected?.some((s) => s.data.type === AnalyticsResourceType.PRESET),
    [notOwner, selected]
  );

  const attributionsWithoutPresets = useMemo(() => selected.filter((x) => x.data.type !== "preset"), [selected]);

  const canEditPermissions = useCanEditSelectedObjectsPermissions(
    currentUser.email,
    attributionsWithoutPresets.map((x) => x.data)
  );

  const handleNewAllocation = useCreateAllocationHandler({
    baseUrl: routeMatchURL,
    mixpanelEventName: "analytics.allocations.new",
  });

  if (labelsLoading) {
    return (
      <Box
        sx={{
          p: 1,
        }}
      >
        <FilterTableSkeleton />
      </Box>
    );
  }

  if (!labels) {
    return null;
  }

  return (
    <>
      <DoitConsoleTitle pageName="Allocations" />
      <Grid
        container
        sx={{
          alignItems: "center",
        }}
      >
        {loading ? (
          <Box
            sx={{
              p: 1,
            }}
          >
            <FilterTableSkeleton />
          </Box>
        ) : (
          <FilterTable<AllocationWRefRowItem>
            showRowsSelection={true}
            onRowsSelected={setSelected}
            tableItems={tableAllocations}
            rowComponent={RowWrapper}
            headerColumns={headerColumns}
            filterColumns={filters}
            filterBarPlaceholder={allocationTxt.FILTER_ALLOCATIONS}
            persistenceKey="cloud_analytics_allocations_v1"
            itemUniqIdentifierField="ref.id"
            defaultSortingColumnValue="data.timeModified"
            toolbarProps={{
              title: "Allocations",
              allowToEditColumns: true,
            }}
          >
            <Grid>
              <Tooltip title={deleteButtonTooltip}>
                <span>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteDialogOpen}
                    disabled={disableDeleteAttribution}
                    data-cy={cypressAttributionIds.browser.delete}
                  >
                    {globalText.DELETE}
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid>
              <Button variant="text" onClick={handleShareDialogOpen} disabled={!canEditPermissions}>
                {globalText.EDIT_PERMISSIONS}
              </Button>
            </Grid>
            <Grid>
              <Hide mdDown>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNewAllocation}
                  data-cy={cypressAttributionIds.browser.newAttribution}
                  disabled={!isEntitledAnalytics}
                >
                  {allocationTxt.CREATE_NEW_ALLOCATION}
                </Button>
              </Hide>
            </Grid>
          </FilterTable>
        )}
      </Grid>
    </>
  );
};

export default AllocationBrowser;
