import { type JSX } from "react";

import { Link as RouterLink } from "react-router-dom";
import {
  CurrencyCodes,
  DoitRole,
  FlexsavePayerType,
  type MasterPayerAccountStatus,
  type MpaRevenue,
} from "@doitintl/cmp-models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import capitalize from "lodash/capitalize";
import pick from "lodash/pick";
import { DateTime } from "luxon";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { menuItemKey, ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { useIsPLESCustomer } from "../../../Support/Components/TicketSteps/hooks";
import { formatCurrency, sanitizeDate, statusStyles } from "../../../utils/common";
import { type FirestoreTimestamp } from "../../../utils/firebase";
import { NotApplicable, NotAvailable } from "../../Assets/Tabs/components/typographics";
import { useAccessAccount } from "../hooks/useConcedefy";
import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";

export const mpaStatusOrdinals = { pending: 0, active: 1, retired: 2 };
export const payerTypeTexts: Record<FlexsavePayerType, string> = {
  [FlexsavePayerType.resold]: "Resold",
  [FlexsavePayerType.standalone]: "Standalone",
};

const cyData = (selector: string): string => `mpaTableData-${selector}`;
const cyMenu = (selector: string): string => `mpaTableMenu-${selector}`;

export const cyIds = {
  columns: {
    domain: cyData("domainLink"),
    payerNumber: cyData("payerNumber"),
    number: cyData("accountNumber"),
    model: cyData("supportModel"),
    tier: cyData("supportTier"),
    status: cyData("status"),
    org: cyData("orgImport"),
    nra: cyData("noRootAccess"),
    requester: cyData("requester"),
    type: cyData("payerType"),
    menu: cyData("threeDotsMenu"),
    ticketId: cyData("ticketId"),
    ticketAssignee: cyData("ticketAssignee"),
    ppaMargin: cyData("ppaMargin"),
    edpMargin: cyData("edpMargin"),
    supportMargin: cyData("supportMargin"),
    lastRefreshed: cyData("lastRefreshed"),
    billingStatus: cyData("billingHealth"),
    totalMargin: cyData("totalMargin"),
    billingStartMonth: cyData("billingStartMonth"),
  },
  menu: {
    activate: cyMenu("activate"),
    edit: cyMenu("edit"),
    updateSupport: cyMenu("updateSupport"),
    active: cyMenu("editActiveMpa"),
    delete: cyMenu("deleteMpa"),
    retire: cyMenu("retireMpa"),
    accessBillingAndSupport: cyMenu("accessBillingAndSupport"),
    accessAdmin: cyMenu("accessAdmin"),
    sauron: cyMenu("openSauron"),
  },
};

const hasIssue = (revenue?: number): boolean => (revenue ? revenue < 0 || isNaN(revenue) : revenue !== 0);

/**
 * Turns a master payer account into useful row data for the MPA table.
 * @param item The master payer account that will be represented in the row
 * @param payerTypes Map of account IDs and their payer types taken from Flexsave Payer Configs in Integrations.
 * @param selectedMonth The month that will be used to display the revenue data.
 */
export const buildMpaRowData = (
  item: MasterPayerAccountsModelSnapshot,
  payerTypes: Record<string, FlexsavePayerType>,
  selectedMonth: string
): MpaTableRowData => {
  const payerNumMatch = item?.name ? item.name.match(/(\d+)/) : null;
  const payerNumber = payerNumMatch?.length ? Number(payerNumMatch?.[0]) : 0;
  const revenuesByMonth: RevenuesByMonth = item?.revenues
    ? item.revenues.reduce((acc, revenue) => {
        acc[revenue.month] = pick(revenue, ["lastUpdated", "ppa", "edp", "support", "total"]);
        return acc;
      }, {})
    : {};

  const { ppa, edp, support, total }: { ppa?: number; edp?: number; support?: number; total?: number } =
    revenuesByMonth?.[selectedMonth] || {};

  const activeBillingStatus =
    hasIssue(ppa) || hasIssue(edp) || hasIssue(support) || hasIssue(total) ? "Issue" : "Healthy";
  const billingStatus = item.status === "active" ? activeBillingStatus : "Pending";

  return {
    customerId: item.customerId,
    snapshot: item,
    domain: item.domain,
    requester: item.requestedBy ?? "",
    payerNumber,
    number: item.accountNumber,
    root: item?.rootEmail ?? NotAvailable,
    supportModel: item.support?.model ?? NotAvailable,
    supportTier: item.support?.tier,
    statusOrdinal: item?.status && mpaStatusOrdinals[item?.status],
    status: item.status,
    orgImport: item?.features?.["import-org"] ? "Yes" : "No",
    nra: item?.features?.["no-root-access"] ? "Yes" : "No",
    payerType: payerTypeTexts[payerTypes[item.accountNumber]] || NotApplicable,
    ticketId: item.onboardingTicketId ? Number(item.onboardingTicketId) : undefined,
    ticketAssignee: item.ticketAssignee ?? "",
    revenuesByMonth,
    billingStatus,
    ppa,
    edp,
    support,
    total,
    lastRefreshedMillis: revenuesByMonth[selectedMonth]?.lastUpdated.seconds
      ? revenuesByMonth[selectedMonth].lastUpdated.seconds * 1000
      : 0,
    lastRefreshed: revenuesByMonth[selectedMonth]?.lastUpdated
      ? DateTime.fromJSDate(revenuesByMonth[selectedMonth].lastUpdated.toDate())
      : NotAvailable,
    billingStartMonth: item.features?.["org-billing-start-month"],
  };
};

type BillingStatus = "Healthy" | "Issue" | "Pending";
export type RevenueMonthEntry = Omit<MpaRevenue, "month"> & { billingStatus?: BillingStatus };

export type RevenuesByMonth = Record<string, RevenueMonthEntry>;

export type MpaTableRowData = {
  customerId: string;
  snapshot: MasterPayerAccountsModelSnapshot;
  domain?: string;
  payerNumber: number;
  number: string;
  root: string;
  supportModel: string;
  supportTier: string;
  statusOrdinal?: number;
  status?: string;
  orgImport: "Yes" | "No";
  nra: "Yes" | "No";
  requester: string;
  payerType: string;
  ticketAssignee: string;
  ticketId?: number;
  revenuesByMonth: RevenuesByMonth; // The month omitted from the value becomes the key
  billingStatus?: BillingStatus;
  ppa?: number | typeof NotAvailable;
  edp?: number | typeof NotAvailable;
  support?: number | typeof NotAvailable;
  total?: number | typeof NotAvailable;
  lastRefreshed?: DateTime | typeof NotAvailable;
  lastRefreshedMillis: number;
  billingStartMonth?: FirestoreTimestamp;
};

export type Props = {
  row: MpaTableRowData;
  onEditOrActivateMPA: () => void;
  onEditPendingMPA: () => void;
  onDeletePendingMPA: () => void;
  onRetireMPA: () => void;
  onOpenSupport: () => Promise<void>;
  selectedMonth: string;
};

const MpaTableRow = ({
  row,
  onEditOrActivateMPA,
  onEditPendingMPA,
  onDeletePendingMPA,
  onRetireMPA,
  onOpenSupport,
}: Props) => {
  const dark = useDarkThemeCheck();
  const isMpaOpsAdmin = useDoitRoleCheck(DoitRole.MasterPayerAccountOpsAdmin);
  const { roles, syncRoles, onAccessAccount } = useAccessAccount();

  const isPlesCustomer = useIsPLESCustomer(row.customerId);

  const baseStatusStyles = statusStyles(dark);
  const mpaStatusStyles = {
    active: baseStatusStyles.green,
    pending: baseStatusStyles.yellow,
    retired: baseStatusStyles.red,
  };

  const statusChip: Record<MasterPayerAccountStatus, JSX.Element> = {
    pending: <Chip size="small" label="Pending" sx={mpaStatusStyles.pending} data-cy={cyIds.columns.status} />,
    active: <Chip size="small" label="Active" sx={mpaStatusStyles.active} data-cy={cyIds.columns.status} />,
    retired: <Chip size="small" label="Retired" sx={mpaStatusStyles.retired} data-cy={cyIds.columns.status} />,
  };

  const billingStatusChip: Record<BillingStatus, JSX.Element> = {
    Healthy: <Chip size="small" label="Healthy" sx={baseStatusStyles.green} />,
    Issue: <Chip size="small" label="Issue" sx={baseStatusStyles.red} />,
    Pending: statusChip.pending,
  };

  const handleThreeDotMenuClick = () => {
    if (!roles && row.status !== "retired" && row.number !== "") {
      syncRoles(row.customerId);
    }
  };

  const strategicRole = roles ? roles["aws-mpa-strategic"] : undefined;
  const adminRole = roles ? roles["aws-mpa-administrator"] : undefined;
  const billingAndSupportRole = roles ? roles["aws-mpa-billing-and-support"] : undefined;

  const accountAccessMenuOptions: ThreeDotsMenuOption[] = [
    {
      label: (
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body2">Access MPA as Strategic</Typography>
          <OpenInNewIcon
            sx={{
              width: 14,
              height: 14,
            }}
          />
        </Stack>
      ),
      key: menuItemKey("strategic", row.number),
      action: () => onAccessAccount("aws-mpa-strategic", row.number, row.customerId),
      dataCy: cyIds.menu.accessBillingAndSupport,
      disabled: !strategicRole?.allowed,
      tooltip: !strategicRole?.allowed ? strategicRole?.notAllowedReason.split(": ")[1] : undefined,
    },
    {
      label: (
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body2">Access MPA as Admin</Typography>
          <OpenInNewIcon
            sx={{
              width: 14,
              height: 14,
            }}
          />
        </Stack>
      ),
      key: menuItemKey("admin", row.number),
      action: () => onAccessAccount("aws-mpa-administrator", row.number, row.customerId),
      dataCy: cyIds.menu.accessAdmin,
      disabled: !adminRole?.allowed,
      tooltip: !adminRole?.allowed ? adminRole?.notAllowedReason.split(": ")[1] : undefined,
    },
    {
      label: (
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="body2">Access MPA as Billing and support</Typography>
          <OpenInNewIcon
            sx={{
              width: 14,
              height: 14,
            }}
          />
        </Stack>
      ),
      key: menuItemKey("billing-and-support", row.number),
      action: () => onAccessAccount("aws-mpa-billing-and-support", row.number, row.customerId),
      dataCy: cyIds.menu.accessBillingAndSupport,
      disabled: !billingAndSupportRole?.allowed,
      tooltip: !billingAndSupportRole?.allowed ? billingAndSupportRole?.notAllowedReason.split(": ")[1] : undefined,
    },
  ];

  const menuOptions: ThreeDotsMenuOption[] = [];
  switch (row.status) {
    case "pending":
      menuOptions.push(
        {
          label: <Typography variant="body2">Activate MPA</Typography>,
          key: menuItemKey("ActivateMpa", row.number),
          action: onEditOrActivateMPA,
          dataCy: cyIds.menu.activate,
          disabled: !isMpaOpsAdmin,
          tooltip: !isMpaOpsAdmin ? "Only DoiT MPA Ops Admins can activate an MPA" : undefined,
          tooltipPlacement: "left",
        },
        {
          label: <Typography variant="body2">Edit MPA</Typography>,
          key: menuItemKey("EditPendingMpa", row.number),
          action: onEditPendingMPA,
          dataCy: cyIds.menu.edit,
        },
        {
          label: (
            <Typography variant="body2" color="error">
              Delete MPA
            </Typography>
          ),
          key: menuItemKey("DeletePendingMpa", row.number),
          action: onDeletePendingMPA,
          dataCy: cyIds.menu.delete,
        }
      );
      if (row.number !== "") {
        menuOptions.push(...accountAccessMenuOptions);
      }
      break;
    case "active":
      menuOptions.push(
        {
          label: <Typography variant="body2">Edit activation details</Typography>,
          key: menuItemKey("EditActiveMpa", row.number),
          action: onEditOrActivateMPA,
          dataCy: cyIds.menu.active,
          disabled: !isMpaOpsAdmin,
          tooltip: !isMpaOpsAdmin
            ? "Only DoiT MPA Ops Admins can edit the details of an already activated MPA"
            : undefined,
          tooltipPlacement: "left",
        },
        {
          label: <Typography variant="body2">Update Support model/tier</Typography>,
          key: menuItemKey("UpdateSupportModelTier", row.number),
          action: onEditPendingMPA,
          dataCy: cyIds.menu.updateSupport,
          disabled: !isMpaOpsAdmin,
          tooltip: !isMpaOpsAdmin
            ? "Only DoiT MPA Ops Admins can update the support details of an active MPA"
            : undefined,
          tooltipPlacement: "left",
        },
        {
          label: <Typography variant="body2">Retire MPA </Typography>,
          key: menuItemKey("RetireMpa", row.number),
          action: onRetireMPA,
          dataCy: cyIds.menu.retire,
          disabled: !isMpaOpsAdmin,
          tooltip: !isMpaOpsAdmin ? "Only DoiT MPA Ops Admins can retire an active MPA" : undefined,
          tooltipPlacement: "left",
        },
        {
          label: (
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="body2">Go to AWS support</Typography>
              <OpenInNewIcon
                sx={{
                  width: 14,
                  height: 14,
                }}
              />
            </Stack>
          ),
          key: menuItemKey("OpenSupport", row.number),
          type: "link",
          action: onOpenSupport,
          dataCy: cyIds.menu.sauron,
          tooltip: "Go to AWS support",
          tooltipPlacement: "left",
          disabled: row.supportModel === "partner-led" && !isPlesCustomer,
        }
      );
      if (row.number !== "") {
        menuOptions.push(...accountAccessMenuOptions);
      }
      break;
  }

  return (
    <CellsWrapper>
      <TableCell>
        <Typography variant="body2" component="span" noWrap>
          <Typography
            component={RouterLink}
            variant="body2"
            to={`/customers/${row.customerId}/`}
            color="inherit"
            data-cy={cyIds.columns.domain}
          >
            {row.domain}
          </Typography>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.payerNumber}>
          {`doitintl-payer-${row.payerNumber}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.billingStatus}>
          {row.billingStatus ? billingStatusChip[row.billingStatus] : NotAvailable}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          component="span"
          width="fitContent"
          color={typeof row.ppa === "number" && row.ppa < 0 ? "error" : "inherit"}
          sx={
            typeof row.ppa === "number" && row.ppa < 0
              ? { backgroundColor: "rgba(239, 80, 83, 0.2)", borderRadius: 1, p: 0.5 }
              : {}
          }
          noWrap
          data-cy={cyIds.columns.ppaMargin}
        >
          {typeof row.ppa === "number" ? formatCurrency(row.ppa, CurrencyCodes.USD, 2) : NotAvailable}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          component="span"
          width="fitContent"
          color={typeof row.edp === "number" && row.edp < 0 ? "error" : "inherit"}
          sx={
            typeof row.edp === "number" && row.edp < 0
              ? { backgroundColor: "rgba(239, 80, 83, 0.2)", borderRadius: 1, p: 0.5 }
              : {}
          }
          noWrap
          data-cy={cyIds.columns.edpMargin}
        >
          {typeof row.edp === "number" ? formatCurrency(row.edp, CurrencyCodes.USD, 2) : NotAvailable}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          component="span"
          width="fitContent"
          color={typeof row.support === "number" && row.support < 0 ? "error" : "inherit"}
          sx={
            typeof row.support === "number" && row.support < 0
              ? { backgroundColor: "rgba(239, 80, 83, 0.2)", borderRadius: 1, p: 0.5 }
              : {}
          }
          noWrap
          data-cy={cyIds.columns.supportMargin}
        >
          {typeof row.support === "number" ? formatCurrency(row.support, CurrencyCodes.USD, 2) : NotAvailable}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          component="span"
          width="fitContent"
          color={typeof row.total === "number" && row.total < 0 ? "error" : "inherit"}
          sx={
            typeof row.total === "number" && row.total < 0
              ? { backgroundColor: "rgba(239, 80, 83, 0.2)", borderRadius: 1, p: 0.5 }
              : {}
          }
          noWrap
          data-cy={cyIds.columns.totalMargin}
        >
          {typeof row.total === "number" ? formatCurrency(row.total, CurrencyCodes.USD, 2) : NotAvailable}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.lastRefreshed}>
          {row.lastRefreshed?.toLocaleString(DateTime.DATETIME_MED) || NotAvailable}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.number}>
          {row.number}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.model}>
          {capitalize(row.supportModel)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.tier}>
          {capitalize(row.supportTier)}
        </Typography>
      </TableCell>
      <TableCell>{!!row.status && statusChip[row.status]}</TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.billingStartMonth}>
          {(row.billingStartMonth &&
            sanitizeDate(DateTime.fromJSDate(row.billingStartMonth.toDate()).setZone("utc")).toFormat("MMM yy")) ??
            NotAvailable}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.org}>
          {row.orgImport}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.nra}>
          {row.nra}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.requester}>
          {row.requester}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.type}>
          {row.payerType}
        </Typography>
      </TableCell>
      <TableCell>
        {!!row.ticketId && (
          <Link
            variant="body2"
            noWrap
            data-cy={cyIds.columns.ticketId}
            href={`https://doitintl.zendesk.com/agent/tickets/${row.ticketId}`}
            target="_blank"
            color="text.primary"
            gap="5px"
            display="flex"
            alignItems="center"
          >
            {row.ticketId || ""}
            <OpenInNewIcon
              sx={{
                width: 14,
                height: 14,
              }}
            />
          </Link>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" noWrap data-cy={cyIds.columns.ticketAssignee}>
          {row.ticketAssignee}
        </Typography>
      </TableCell>
      <TableCell padding="checkbox" data-cy={cyIds.columns.menu} onClick={handleThreeDotMenuClick}>
        {!!menuOptions.length && <ThreeDotsMenu options={menuOptions} size="small" />}
      </TableCell>
    </CellsWrapper>
  );
};

export default MpaTableRow;
