import { useEffect, useMemo } from "react";

import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { useAnalyticsDimensions } from "../../hooks/cloudAnalytics/useAnalyticsDimensions";

export const useMetadata = () => {
  const { metadata: metadataSnapshots, fetchMetadata } = useCloudAnalyticsContext();

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  return metadataSnapshots;
};

export const useDimension = (dimensionId) => {
  const metadataSnapshots = useMetadata();
  const { dimensions } = useAnalyticsDimensions({
    metadataSnapshots,
  });

  const dimension = useMemo(() => dimensions?.find((m) => m.id === dimensionId), [dimensions, dimensionId]);

  return dimension;
};
