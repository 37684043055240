import { type Dispatch } from "react";

import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { loginTexts } from "../../../assets/texts";
import { type LoginHookAction, type LoginState } from "../types";
import CheckInbox from "./CheckInbox";
import { CompleteSignupForm } from "./CompleteSignupForm";
import { EmailLinkLoginForm } from "./EmailLinkLoginForm";
import EnterPasswordForm from "./EnterPasswordForm";
import ForgotPassword from "./ForgotPassword";
import { SignInForm } from "./SignInForm";
import SignUpForm from "./SignUpForm";
import SsoLoginForm from "./SsoLoginForm";
import { VerifyEmailForMagiclink } from "./VerifyEmailForMagiclink";

export const AllAuthForms = ({ state, dispatch }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const { t } = useTranslation("login");

  switch (state.formType) {
    case "enterPassword":
      return (
        <>
          <Typography
            variant="h1"
            sx={{
              alignSelf: "flex-start",
            }}
          >
            {t("signin")}
          </Typography>
          <EnterPasswordForm state={state} dispatch={dispatch} />
        </>
      );
    case "signup":
      return (
        <>
          <Typography
            variant="h1"
            sx={{
              alignSelf: "flex-start",
            }}
          >
            {t("signup.label")}
          </Typography>
          <SignUpForm state={state} dispatch={dispatch} />
        </>
      );
    case "completeSignup":
      return (
        <>
          <Typography
            variant="h1"
            sx={{
              alignSelf: "flex-start",
            }}
          >
            {loginTexts.COMPLETE_SIGNUP}
          </Typography>
          <CompleteSignupForm state={state} dispatch={dispatch} />
        </>
      );
    case "verifyEmailForEmailLinkSignIn":
      return <VerifyEmailForMagiclink state={state} dispatch={dispatch} />;
    case "sso":
      return <SsoLoginForm state={state} dispatch={dispatch} />;
    case "enterEmailForEmailLink":
      return <EmailLinkLoginForm state={state} dispatch={dispatch} />;
    case "forgotPassword":
      return (
        <>
          <Typography
            variant="h1"
            sx={{
              alignSelf: "flex-start",
            }}
          >
            {loginTexts.FORGOT_PASSWORD}
          </Typography>
          <ForgotPassword state={state} dispatch={dispatch} />
        </>
      );
    case "checkEmailSuccess":
      return <CheckInbox email={state.email || ""} loginType={state.loginType} />;
    case "checkEmail":
    default:
      return <SignInForm state={state} dispatch={dispatch} />;
  }
};
