import { NodeTransformationType } from "@doitintl/cmp-models";
import { Stack, TextField } from "@mui/material";

import { ReferencedFieldStandalone } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldStandalone";
import { FieldSectionHeader } from "../../../Common/FieldSectionHeader";
import { useStringTransformationForm } from "./hooks";

export const StringTransformationForm = ({
  type,
}: {
  type: NodeTransformationType.UPPER_CASE | NodeTransformationType.LOWER_CASE;
}) => {
  const { newFieldNameCommonProps, referenceableNodes, getFieldProps, payloadCommonProps } =
    useStringTransformationForm(type);
  const { value: rootReferencedNodeValue } = getFieldProps("referencedNodeField");
  const fieldSectionHeaderText =
    type === NodeTransformationType.UPPER_CASE
      ? {
          title: "Uppercase",
          subtitle: "Select a string value to convert to uppercase",
        }
      : { title: "Lowercase", subtitle: "Select a string value to convert to lowercase" };
  return (
    <Stack spacing={2} pb={4}>
      <TextField fullWidth variant="outlined" size="small" {...newFieldNameCommonProps} />
      <FieldSectionHeader title={fieldSectionHeaderText.title} subtitle={fieldSectionHeaderText.subtitle} />
      <ReferencedFieldStandalone
        {...payloadCommonProps}
        referenceableNodes={referenceableNodes}
        rootReferencedNodeValue={rootReferencedNodeValue}
      />
    </Stack>
  );
};
