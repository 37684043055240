import { Slide } from "react-awesome-reveal";
import { type MessageUser } from "@doitintl/ava-components";
import { ContentWithAvatar } from "@doitintl/ava-components/src/Messenger/ContentWithAvatar";
import { Avatar, Box, Typography } from "@mui/material";

import { useAuthContext } from "../../Context/AuthContext";

type PropsUser = {
  message: MessageUser;
  disableAnimation: boolean;
};

export const MessageUserComponent = ({ message, disableAnimation }: PropsUser) => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const { content } = message;

  return (
    <Slide direction="up" triggerOnce duration={disableAnimation ? 0 : undefined}>
      <Box
        borderTop={1}
        borderBottom={1}
        borderColor="divider"
        sx={{
          px: 15,
          backgroundColor: "background.paper",
        }}
      >
        <ContentWithAvatar avatar={<Avatar sx={{ height: 32, width: 32 }} src={currentUser.photoURL ?? undefined} />}>
          <Typography py={1.5} variant="subtitle2">
            {content}
          </Typography>
        </ContentWithAvatar>
      </Box>
    </Slide>
  );
};
