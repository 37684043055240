import { useMemo, useState } from "react";

import EditIcon from "@mui/icons-material/EditRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Autocomplete,
  type AutocompleteProps,
  type AutocompleteRenderInputParams,
  Box,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  type TextFieldProps,
  Typography,
} from "@mui/material";

import { globalText } from "../../../assets/texts";
import { AlertsTxt } from "../../../assets/texts/CloudAnalytics";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import {
  createDefaultMDOption,
  createFilterOptions,
  getTypeString,
  limitResultsIds,
  processOptionGroups,
} from "../../../Pages/CloudAnalytics/utilities";
import { type MetadataOption } from "../../../types";

type BasicAutoCompleteProps = Omit<
  AutocompleteProps<MetadataOption, undefined, boolean, undefined>,
  "renderInput" | "options"
>;

type DimensionSelectorProps = {
  basicAutoCompleteProps?: BasicAutoCompleteProps;
  dimensions: MetadataOption[];
  disabled?: boolean;
  excludeSelectMetadataIds?: Set<string>;
  handleSelectOption: (option: MetadataOption | null) => void;
  onChange?: () => void;
  onEdit?: () => void;
  selectedDimension: MetadataOption | null;
  textFieldProps?: TextFieldProps;
  showCreateNewAttributionOnDropDown?: boolean;
  handleNewAttribution?: () => Promise<void>;
  required?: boolean;
  placeholder?: string;
};

export const DimensionSelector = ({
  basicAutoCompleteProps,
  dimensions,
  disabled,
  excludeSelectMetadataIds,
  handleSelectOption,
  onChange,
  onEdit,
  selectedDimension,
  textFieldProps,
  showCreateNewAttributionOnDropDown,
  handleNewAttribution,
  required,
}: DimensionSelectorProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const isDarkMode = useDarkThemeCheck();
  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };
  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  const dimensionsWithDefaultOption = useMemo(() => {
    const mdOptions = dimensions?.filter((md) => md._visible) ?? [];
    // add default option to the list of options, used for sorting
    return [...mdOptions, createDefaultMDOption()];
  }, [dimensions]);

  const filteredOptions = useMemo(() => {
    const filtered = dimensionsWithDefaultOption.filter(
      (md) => !limitResultsIds.includes(md.id) && !excludeSelectMetadataIds?.has(md.id)
    );

    filtered.forEach((md) => {
      md.typeLabel = getTypeString(md);
    });

    return processOptionGroups(filtered);
  }, [dimensionsWithDefaultOption, excludeSelectMetadataIds]);

  const filterOptions = createFilterOptions<MetadataOption>(
    {
      trim: true,
      ignoreAccents: true,
      ignoreCase: true,
      matchFrom: "any",
    },
    true
  );

  return (
    <Autocomplete
      fullWidth
      options={filteredOptions}
      filterSelectedOptions={true}
      getOptionLabel={(option) => option.data.label}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      groupBy={(option) => option.typeLabel ?? ""}
      filterOptions={filterOptions}
      size="small"
      onChange={(_, newValue, reason) => {
        switch (reason) {
          case "clear":
            handleSelectOption(null);
            break;
          case "selectOption":
            handleSelectOption(newValue);
            break;
        }
        onChange?.();
      }}
      // disable clearable when no option selected.
      disableClearable={
        basicAutoCompleteProps?.disableClearable !== null
          ? basicAutoCompleteProps?.disableClearable
          : !selectedDimension?.id
      }
      renderInput={(params: AutocompleteRenderInputParams) => {
        if (textFieldProps?.slotProps?.input) {
          textFieldProps.slotProps.input = {
            ...params.InputProps,
            ...textFieldProps.slotProps.input,
          };
        }

        return (
          <TextField
            {...params}
            required={required}
            label={globalText.SELECT}
            variant="outlined"
            margin="dense"
            size={showCreateNewAttributionOnDropDown ? "small" : "medium"}
            {...textFieldProps}
            slotProps={{
              input: {
                ...params.InputProps,
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      {onEdit && (isMouseOver || isFocused) && selectedDimension ? (
                        <IconButton aria-label="Edit" onClick={onEdit} size="small" disabled={disabled}>
                          <EditIcon sx={{ fontSize: 17 }} />
                        </IconButton>
                      ) : undefined}
                    </InputAdornment>
                    {params.InputProps.endAdornment}
                  </>
                ),
              },

              inputLabel: {
                shrink: true,
              },
            }}
          />
        );
      }}
      value={selectedDimension}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      disabled={disabled}
      {...(showCreateNewAttributionOnDropDown && {
        ListboxComponent: (props) => (
          <Box
            {...props}
            sx={{
              pb: "0 !important",
            }}
            component="ul"
          >
            {props.children}

            <Stack
              component="li"
              direction="row"
              spacing={0.5}
              sx={{
                color: "primary",
                justifyContent: "center",
                alignContent: "center",
                position: "sticky",
                bottom: 0,
                px: 2,
                py: 1,
                backgroundColor: !isDarkMode ? "#FAFAFA" : "#2D2D39",
                zIndex: 10,
                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              <Typography variant="body1" color="primary">
                {AlertsTxt.NEED_ADVANCED_LOGIC}
              </Typography>
              <Link
                sx={{
                  alignItems: "center",
                  display: "flex",
                  gap: 1,
                }}
                color="primary"
                variant="body1"
                underline="none"
                href="#"
                onClick={handleNewAttribution}
              >
                {AlertsTxt.CREATE_NEW_ATTRIBUTION}
                <OpenInNewIcon fontSize="inherit" />
              </Link>
            </Stack>
          </Box>
        ),
      })}
      {...basicAutoCompleteProps}
    />
  );
};
