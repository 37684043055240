import {
  type DocumentSnapshotModel,
  type ModelData,
  type ModelReference,
  type QueryDocumentSnapshotModel,
} from "@doitintl/models-firestore";
import { getApp } from "firebase/app";
import { type Auth, getAuth } from "firebase/auth";
import {
  arrayRemove,
  arrayUnion,
  deleteField,
  type DocumentData,
  documentId,
  increment,
  serverTimestamp,
  Timestamp as FirestoreTimestamp,
} from "firebase/firestore";
import { isSupported } from "firebase/messaging";
import { getStorage, ref } from "firebase/storage";
import chunk from "lodash/chunk";

import { customAuthApp } from "./initFirestore";

export const storage = getStorage(customAuthApp);

export let auth: Auth;

let rootAuthInstance: Auth | undefined = undefined;
export const rootAuth = () => {
  if (rootAuthInstance === undefined) {
    rootAuthInstance = getAuth();
  }

  return rootAuthInstance;
};

export const customAuth = customAuthApp ? getAuth(customAuthApp) : undefined;

export const initFirebaseAuth = () => {
  const useSsoWithoutProxy = window.localStorage.getItem("use-sso-without-proxy");

  if (customAuth && !useSsoWithoutProxy && !getAuth().currentUser) {
    auth = customAuth;
    return;
  }

  auth = getAuth();
  window.localStorage.removeItem("use-sso-without-proxy");
};

export const bucket = (url: string) => ref(getStorage(customAuthApp ?? getApp()), url);
export const firestoreTimestamp = FirestoreTimestamp.now;
export const isMessagingSupported = isSupported;

// Firestore Timestamps
export const TimestampNow = FirestoreTimestamp.now;
export const TimestampFromDate = FirestoreTimestamp.fromDate;

export const FirestoreMaxInOperator = 30;

export const loadDocumentsByQueriesIfPossible = async <
  TModel extends DocumentData,
  IDField extends string | undefined = undefined,
  RefField extends string | undefined = undefined,
  SnapshotField extends string | undefined = undefined,
  UseData extends boolean = false,
>(
  docRefs: ModelReference<TModel>[],
  {
    useQueryToRetrieveDocs,
    idField,
    refField,
    snapshotField,
    useDataField,
  }: {
    useQueryToRetrieveDocs: boolean;
    resourceVariableUsedInFirestoreRules?: boolean;
    runningQueryConcurrencyLimit?: number;
    idField?: IDField;
    refField?: RefField;
    snapshotField?: SnapshotField;
    useDataField?: UseData;
  }
): Promise<(ModelData<TModel, IDField, RefField, SnapshotField, UseData> | undefined)[]> => {
  if (docRefs.length === 0) {
    return [];
  }

  const appendIdRefFields = (
    data: any,
    doc: DocumentSnapshotModel<any> | QueryDocumentSnapshotModel<any>
  ): ModelData<TModel, IDField, RefField, SnapshotField, UseData> => {
    const actualData: any = useDataField ? { data } : data;

    if (idField) {
      actualData[idField] = doc.id;
    }

    if (refField) {
      actualData[refField] = doc.ref;
    }

    if (snapshotField) {
      actualData[snapshotField] = doc.snapshot;
    }

    return actualData;
  };

  if (useQueryToRetrieveDocs) {
    const docRefsChunks = chunk(
      docRefs.map((ref) => ref.id),
      FirestoreMaxInOperator
    );

    const parentRef = docRefs[0].parentModel();

    const promises = docRefsChunks.map((chunks) => parentRef.where(documentId(), "in", chunks).get());

    const docs = (await Promise.all(promises)).flatMap((querySnapshot) => querySnapshot.docs);

    const orderIndex = Object.fromEntries(docs.map((doc, index) => [doc.id, index]));

    return docRefs.map((ref) => {
      const doc = docs[orderIndex[ref.id]];
      if (!doc) {
        return undefined;
      }

      const data = doc.asModelData();

      return appendIdRefFields(data, doc);
    });
  }

  const promises = docRefs.map((ref) => ref.get());
  return Promise.all(promises).then((docs) =>
    docs.map((doc) => {
      const data = doc.asModelData();

      if (!data) {
        return undefined;
      }

      appendIdRefFields(data, doc);

      return data as ModelData<TModel, IDField, RefField, SnapshotField, UseData>;
    })
  );
};

export { arrayRemove, arrayUnion, deleteField, documentId, FirestoreTimestamp, increment, serverTimestamp };
