import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import { IncludedWithPlanInfo } from "../../../Components/IncludedWithPlanInfo/IncludedWithPlanInfo";
import { ContactSalesButton } from "../../../Components/NoEntitlement/NoEntitlement";
import { useIsFeatureEntitledMultiple } from "../../../Context/TierProvider";
import { entitlementsToContactSupport } from "../../../Support/consts";
import mixpanel from "../../../utils/mixpanel";
import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import { sendInsightEvent } from "../metrics";
import { type Insight } from "../types";
import { AgentProfileCard } from "./AgentProfileCard";

export const ContactSupport = (props: {
  insight: Insight;
  newSupportTicketUrl: string;
  mixpanelInsight: string;
  savingsPeriod: string;
  totalSavings: string;
}) => {
  // If the customer has any of the relevant entitlements that would allow them to contact support for this insight
  const isEntitledToContactSupportMap = useIsFeatureEntitledMultiple(entitlementsToContactSupport);
  const isEntitledToContactSupport = entitlementsToContactSupport.some((key) => isEntitledToContactSupportMap[key]);

  const { trackEvent } = useSegmentTrackEvent();
  const isPersonalized = props.insight.publishingUser !== null;

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          mb: 1,
          fontWeight: 500,
        }}
      >
        Get expert advice from DoiT
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mb: 2,
          display: "inline",
        }}
      >
        {isPersonalized
          ? "This recommendation was hand-picked by one of our cloud experts who can help you implement it."
          : "Our Cloud Reliability Engineers can help you implement these changes."}
      </Typography>
      {isEntitledToContactSupport ? (
        <>
          <IncludedWithPlanInfo isIncluded={true} />
          {isPersonalized ? <AgentProfileCard agent={props.insight.publishingUser!} /> : null}
          <Button
            component={Link}
            to={props.newSupportTicketUrl}
            onClick={() => {
              mixpanel.track("insights.details.contact-support", {
                insight: props.mixpanelInsight,
              });

              sendInsightEvent({
                insight: props.insight,
                eventName: "Insight Support Contacted",
                trackEvent,
                isFirstRender: false,
                savingsPeriod: props.savingsPeriod,
                insightTotalSavingsString: props.totalSavings,
              });
            }}
            variant="contained"
            size="medium"
            color="primary"
            fullWidth
            sx={{ mb: 1 }}
          >
            Contact an expert
          </Button>
        </>
      ) : (
        <>
          <IncludedWithPlanInfo isIncluded={false} />
          <ContactSalesButton variant="contained" fullWidth />
        </>
      )}
    </>
  );
};
