import { useState } from "react";

import { useAvaContext, useTrpcContext } from "@doitintl/ava-components";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormControl, type SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { AvaHistorySelect } from "../Ava/Messenger/AvaHistorySelect";
import { AvaIcon } from "../Ava/Messenger/AvaIcon";
import { useAvaConversationRefs } from "../Ava/useAvaConversationRefs";

type Props = {
  readonly onCloseClicked: () => void;
};

export function ChatToolbar({ onCloseClicked }: Props) {
  const [expandedQuery, setExpandedQuery] = useState<boolean>(false);

  const [allConversations] = useAvaConversationRefs(expandedQuery);

  const { deleteConversation } = useTrpcContext();

  const { setConversationId, conversationId } = useAvaContext();

  const handleDeleteChat = async (conversationId: string) => {
    await deleteConversation(conversationId);
    setConversationId(undefined);
  };

  const handleChatHistoryChanged = (event: SelectChangeEvent): void => {
    setConversationId(event.target.value);
  };

  const newChatMode =
    Boolean(!conversationId) || allConversations.find((ref) => ref.conversationId === conversationId) === undefined;

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack
        direction="row"
        spacing={1.5}
        sx={{
          alignItems: "center",
        }}
      >
        <AvaIcon />
        <Typography variant="h6" sx={{ flex: 1 }}>
          Ava - AI assistant
        </Typography>
      </Stack>
      <Stack spacing={2} direction="row" sx={{ alignItems: "center" }}>
        <IconButton
          size="small"
          sx={{
            borderRadius: 2,
            border: "1px solid",
            borderColor: "error",
          }}
          color="error"
          onClick={() => {
            if (conversationId) {
              handleDeleteChat(conversationId);
            }
          }}
          disabled={newChatMode}
        >
          <DeleteIcon />
        </IconButton>

        <Box
          sx={{
            ml: 2,
          }}
        >
          <FormControl size="small">
            <AvaHistorySelect
              conversations={allConversations}
              selectedConversationId={conversationId}
              newChatMode={newChatMode}
              handleChatHistoryChanged={handleChatHistoryChanged}
              expandedQuery={expandedQuery}
              setExpandedQuery={setExpandedQuery}
            />
          </FormControl>
        </Box>
        <Button
          variant="outlined"
          onClick={() => {
            setConversationId(undefined);
          }}
          disabled={newChatMode}
        >
          Start new chat
        </Button>
        <IconButton onClick={onCloseClicked}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}
