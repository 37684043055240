import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import orderBy from "lodash/orderBy";

import { lighten } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import { TablePagination } from "@mui/material";
import { blue, orange } from "@mui/material/colors";
import { getCollection } from "@doitintl/models-firestore";
import { CustomerModel } from "@doitintl/cmp-models";
import { assetTypeName, formatCurrency } from "../../utils/common";
import { useUserContext } from "../../Context/UserContext";
import { arrayFromDocChange } from "../../Context/customer/arrayFromDocChange";
import { useCustomerContext } from "../../Context/CustomerContext";
import { SkeletonCard } from "./SkeletonCard";
import { CARD_HEADER_HEIGHT, HEADER_PADDING } from "./Analytics/cloudCardStyle";
import { extendCredit } from "./Actions";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { useWidgetTablePageSize } from "./WidgetCards/useWidgetTablePageSize";

const today = DateTime.utc().startOf("day");

const useProgressBarStyles = makeStyles({
  root: {
    height: 20,
    backgroundColor: (props) => (props.type === "google-cloud" ? lighten(blue[500], 0.8) : lighten(orange[500], 0.8)),
  },
  bar: {
    backgroundColor: (props) => (props.type === "google-cloud" ? blue[500] : orange[500]),
  },
});

function CreditProgressBar(props) {
  const { ...other } = props;
  const classes = useProgressBarStyles(props);
  return <LinearProgress classes={{ root: classes.root, bar: classes.bar }} {...other} />;
}

const useStyles = makeStyles(() => ({
  cardContent: {
    paddingBottom: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  typography: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

export default function CreditsCard({ raised, fallbackComponent, widgetHeight = 200 }) {
  const classes = useStyles();
  const { customer } = useCustomerContext();
  const [page, setPage] = useState(0);
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const rowsPerPage = useWidgetTablePageSize(widgetHeight, 90);
  const [credits, setCredits] = useState();

  useEffect(() => {
    if (!userRoles.invoicesViewer) {
      return;
    }

    return getCollection(CustomerModel)
      .doc(customer.id)
      .collection("customerCredits")
      .onSnapshot((querySnapshot) => {
        setCredits((prevData) => {
          const newData = [...(prevData ?? [])];
          arrayFromDocChange(newData, querySnapshot, (doc) =>
            extendCredit({
              data: doc.data(),
              snapshot: doc,
            })
          );

          return newData.filter(
            (credit) =>
              DateTime.fromJSDate(credit.data.startDate.toDate()).minus({ months: 1 }) <= today &&
              (!credit.data.depletionDate ||
                DateTime.fromJSDate(credit.data.depletionDate.toDate()).plus({ months: 1 }) >= today) &&
              DateTime.fromJSDate(credit.data.endDate.toDate()).plus({ days: 60 }) >= today
          );
        });
      });
  }, [customer.id, userRoles.invoicesViewer]);

  if (credits && credits.length === 0) {
    return fallbackComponent;
  }

  if (credits === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  const orderedCredits = orderBy(credits, ["data._status", "data._remaining"], ["asc", "asc"]);
  const creditsPage = orderedCredits.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, credits?.length - page * rowsPerPage);

  return (
    <Card raised={raised}>
      <WidgetCardHeader title="Credits" subheader="Active credits for your company" />
      <CardContent sx={{ height: widgetHeight - CARD_HEADER_HEIGHT }}>
        <Grid container direction="column" spacing={1}>
          {creditsPage.map((credit) => {
            let value = 0;
            if (credit.data._remaining > 0) {
              value = 100 * (credit.data._remaining / credit.data.amount);
            }

            const startDate = DateTime.fromJSDate(credit.data.startDate.toDate());
            const endDate = DateTime.fromJSDate(credit.data.endDate.toDate());

            let subtitle = null;
            if (credit.data.depletionDate) {
              subtitle = (
                <>
                  {"Depleted on "}
                  {DateTime.fromJSDate(credit.data.depletionDate.toDate()).toLocaleString(DateTime.DATE_MED)}
                </>
              );
            } else if (today > endDate) {
              subtitle = (
                <>
                  {formatCurrency(credit.data._remaining, credit.data.currency, 0)}
                  {" expired on "}
                  {DateTime.fromJSDate(credit.data.endDate.toDate()).toLocaleString(DateTime.DATE_MED)}
                </>
              );
            } else if (startDate > today) {
              subtitle = (
                <>
                  {formatCurrency(credit.data._remaining, credit.data.currency, 0)}
                  {" starting from "}
                  {startDate.toLocaleString(DateTime.DATE_MED)}
                </>
              );
            } else {
              subtitle = (
                <>
                  {formatCurrency(credit.data._remaining, credit.data.currency, 0)}
                  {" remaining until "}
                  {endDate.toLocaleString(DateTime.DATE_MED)}
                </>
              );
            }

            return (
              <Grid key={credit.snapshot.id} container direction="column">
                <Grid container>
                  <Typography variant="caption" className={classes.typography}>
                    {assetTypeName(credit.data.type)} | {credit.data.name}
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="subtitle2">{subtitle}</Typography>
                  <Typography variant="caption">
                    {formatCurrency(credit.data.amount, credit.data.currency, 0)}
                  </Typography>
                </Grid>
                <CreditProgressBar variant="determinate" type={credit.data.type} value={value > 100 ? 100 : value} />
              </Grid>
            );
          })}
        </Grid>
        {emptyRows > 0 && (
          <Grid style={{ height: 32 * emptyRows }}>
            <Grid colSpan={12} />
          </Grid>
        )}
        <TablePagination
          component="div"
          className={classes.tablePagination}
          rowsPerPageOptions={[rowsPerPage]}
          count={orderedCredits.length}
          rowsPerPage={rowsPerPage}
          page={page}
          slotProps={{
            actions: {
              previousButton: {
                "aria-label": "Previous Page",
                size: "small",
              },
              nextButton: {
                "aria-label": "Next Page",
                size: "small",
              },
            },
          }}
          onPageChange={(_event, page) => setPage(page)}
        />
      </CardContent>
    </Card>
  );
}
