import { useCallback, useEffect, useMemo, useState } from "react";

import { type UserNotificationModel } from "@doitintl/cmp-models";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";

import LoadingButton from "../../../Components/LoadingButton";
import { useDashboardsContext } from "../../../Context/useDashboardsContext";
import { consoleErrorWithSentry } from "../../../utils";
import { preventOnCloseWhile, useFullScreen } from "../../../utils/dialog";
import { getUserNotifications } from "../db";
import { BulkUpdateUsersBody, type BulkUpdateUsersBodyProps } from "./BulkUpdateUsersBody";
import { DONT_UPDATE_OPTION_LABEL, text } from "./consts";

const dontUpdateUsersRole = {
  id: -1,
  name: DONT_UPDATE_OPTION_LABEL,
};

const dontUpdateUsersDashboards = {
  id: "-1",
  name: DONT_UPDATE_OPTION_LABEL,
};

type BulkUpdateUsersFormProps = {
  open: boolean;
  onClose: () => void;
  roles: BulkUpdateUsersBodyProps["roles"];
  emails: string[];
  onUpdate: (data: {
    role: any;
    jobFunction: number;
    userNotifications: number[];
    notificationsNotToOverride: number[];
    selectedDashboard: string;
  }) => Promise<void>;
  usersNotifications: number[][];
  onDelete: () => Promise<void>;
};

export const BulkUpdateUsersForm = ({
  open,
  onClose,
  roles = [],
  onUpdate,
  usersNotifications,
  onDelete,
}: BulkUpdateUsersFormProps) => {
  const { dashboards } = useDashboardsContext();
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedJobFunction, setSelectedJobFunction] = useState(0);
  const [selectedRole, setSelectedRole] = useState(dontUpdateUsersRole.name);
  const [selectedDashboard, setSelectedDashboard] = useState(dontUpdateUsersDashboards.name);
  const [selectedNotificationsValues, setSelectedNotificationsValues] = useState<number[]>([]);
  const [notificationsNotToOverride, setNotificationsNotToOverride] = useState<number[]>([]);
  const [userNotificationsOptions, setUserNotificationsOptions] = useState<UserNotificationModel[]>([]);

  const { isMobile } = useFullScreen();

  // @ts-expect-error
  const rolesOptions = useMemo(() => roles.concat(dontUpdateUsersRole), [roles]);

  const commonDashboards = useMemo(
    () => dashboards.map((dashboard) => ({ name: dashboard.name, id: dashboard.id })),
    [dashboards]
  );
  const dashboardOptions = useMemo(() => commonDashboards.concat(dontUpdateUsersDashboards), [commonDashboards]);

  const handleNotificationSelect = (notificationValue: number) => {
    const isNotificationAlreadySelected = selectedNotificationsValues.includes(notificationValue);
    setSelectedNotificationsValues(
      isNotificationAlreadySelected
        ? selectedNotificationsValues.filter((value) => value !== notificationValue)
        : selectedNotificationsValues.concat(notificationValue)
    );
    if (!isNotificationAlreadySelected) {
      setNotificationsNotToOverride(
        notificationsNotToOverride.filter((notification) => notification !== notificationValue)
      );
    }
  };

  const handleUpdate = async () => {
    const role = roles.find((role) => role.name === selectedRole)?.ref;
    setLoading(true);
    await onUpdate({
      role,
      jobFunction: selectedJobFunction,
      userNotifications: selectedNotificationsValues,
      notificationsNotToOverride,
      selectedDashboard: selectedDashboard === dontUpdateUsersDashboards.name ? "" : selectedDashboard,
    });
    setLoading(false);
  };

  const handleDelete = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
    } else {
      setLoading(true);
      await onDelete();
      setLoading(false);
      setConfirmDelete(false);
    }
  };

  const fetchUserNotifications = useCallback(async () => {
    const userNotificationsData = (await getUserNotifications()) as UserNotificationModel[];
    setUserNotificationsOptions(userNotificationsData);
  }, []);

  useEffect(() => {
    fetchUserNotifications().catch(consoleErrorWithSentry);
  }, [fetchUserNotifications]);

  useEffect(() => {
    const commonUserNotificationsValues = userNotificationsOptions
      .filter(
        (notificationOption) =>
          usersNotifications.length > 0 &&
          usersNotifications.every((userNotifications) => userNotifications.includes(notificationOption.value))
      )
      .map((notificationOption) => notificationOption.value);
    setSelectedNotificationsValues(commonUserNotificationsValues);
  }, [usersNotifications, userNotificationsOptions]);

  useEffect(() => {
    const diffNotifications = userNotificationsOptions
      .filter(
        (notificationOption) =>
          usersNotifications.some((userNotifications) => userNotifications?.includes(notificationOption.value)) &&
          !usersNotifications.every((userNotifications) => userNotifications?.includes(notificationOption.value))
      )
      .map((notificationOption) => notificationOption.value);
    setNotificationsNotToOverride(diffNotifications);
  }, [usersNotifications, userNotificationsOptions]);

  useEffect(() => {
    setSelectedJobFunction(0);
    setSelectedRole(dontUpdateUsersRole.name);
    setSelectedDashboard(dontUpdateUsersDashboards.name);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={preventOnCloseWhile(loading, onClose)}
      scroll="body"
      aria-labelledby="form-dialog-title"
      fullScreen={isMobile}
    >
      <DialogTitle id="form-dialog-title">{text.TITLE}</DialogTitle>
      <DialogContent>
        <BulkUpdateUsersBody
          roles={rolesOptions}
          dashboards={dashboardOptions}
          selectedJobFunction={selectedJobFunction}
          onJobFunctionSelect={setSelectedJobFunction}
          selectedRole={selectedRole}
          onRoleSelect={setSelectedRole}
          selectedDashboard={selectedDashboard}
          onDashboardSelect={setSelectedDashboard}
          selectedNotificationsValues={selectedNotificationsValues}
          notificationsNotToOverride={notificationsNotToOverride}
          onNotificationSelect={handleNotificationSelect}
          userNotificationsOptions={userNotificationsOptions}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading && confirmDelete}
          onClick={handleDelete}
          disabled={loading}
          mixpanelEventId="iam.bulk-update-users.confirm"
        >
          {confirmDelete ? text.CONFIRM_DELETE_BUTTON_TEXT : text.DELETE_BUTTON_TEXT}
        </LoadingButton>
        <div style={{ flex: "1 0 0" }} />
        <Button onClick={onClose} color="primary" variant="text">
          {text.CANCEL_ACTION}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading && !confirmDelete}
          onClick={handleUpdate}
          disabled={loading}
          mixpanelEventId="iam.bulk-update-users.update"
        >
          {text.UPDATE_ACTION}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
