import { Fragment, useMemo } from "react";

import { type AttributionFilter, type Positions } from "@doitintl/cmp-models";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";

import { allocationTxt } from "../../../../assets/texts/CloudAnalytics/allocation";
import FilterDialog from "../../../../Components/Dialogs/CloudAnalytics/FilterDialog";
import DimensionSelectorWithOperator from "../../../../Components/DimensionSelectorWithOperator/DimensionSelectorWithOperator";
import { type MetadataOption } from "../../../../types";
import { useFullScreen } from "../../../../utils/dialog";
import { getLetterForIndex } from "../../../../utils/string";
import { type KeyTypeValues } from "../../api";
import useAttributionCreate from "./useAttributionCreate";

const LETTER_SIZE_BIG = 30;
const LETTER_SIZE_SMALL = 22;

const styles = {
  letterStyleAttribution: {
    width: LETTER_SIZE_SMALL,
    fontWeight: "bold",
  },
  letterStyleGuidedExperience: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: LETTER_SIZE_BIG,
    height: LETTER_SIZE_BIG,
    fontWeight: "bold",
    background: "rgba(0, 0, 0, 0.12)",
    borderRadius: "50%",
  },
};

type Props = {
  initialFilters?: AttributionFilter[];
  dimensions?: MetadataOption[];
  handleDimensionLabelsChange: (
    values: KeyTypeValues[],
    filter?: AttributionFilter,
    position?: Positions
  ) => Promise<MetadataOption | null>;
  excludeSelectMetadataIds?: Set<string>;
  guidedExperienceMode?: boolean;
};

const useAllocationCreateSelector = ({
  initialFilters,
  dimensions = [],
  handleDimensionLabelsChange,
  excludeSelectMetadataIds,
  guidedExperienceMode = false,
}: Props) => {
  const {
    filters,
    fetchNewMetadataValue,
    handleSelectExistingOption,
    handleChangeFilter,
    setEditFilter,
    editFilter,
    selectedMetadata,
    onCloseDialog,
  } = useAttributionCreate({
    initialFilters,
    handleDimensionLabelsChange,
  });
  const { isMobile: smDown } = useFullScreen();
  const letterStyle = guidedExperienceMode ? styles.letterStyleGuidedExperience : styles.letterStyleAttribution;

  const variablesArray = useMemo(() => filters.map((_, idx) => getLetterForIndex(idx)), [filters]);
  const options = useMemo(
    () => dimensions.filter((md) => md._visible || filters.findIndex((f) => f.id === md.id) > -1) ?? [],
    [dimensions, filters]
  );

  const AttributionCreateSelector = (
    <>
      <Grid container spacing={1} alignContent="flex-start">
        {filters.map((f, i) => {
          const md = dimensions.find((md) => md.id === f.id);
          if (!md) {
            return null;
          }
          return (
            <Fragment key={`filter-row-${variablesArray[i]}`}>
              <Grid
                container
                sx={{
                  alignItems: "center",
                }}
                size={12}
              >
                <Grid>
                  <Box sx={letterStyle}>{variablesArray[i]}</Box>
                </Grid>
                <Grid
                  size={{
                    xs: "grow",
                    md: "auto",
                  }}
                >
                  <DimensionSelectorWithOperator
                    handleSelectOption={handleSelectExistingOption(i)}
                    selectedDimension={md}
                    dimensions={options}
                    disabled={false}
                    excludeSelectMetadataIds={excludeSelectMetadataIds}
                    onEditDimension={() => {
                      const mdOption = dimensions.find((m) => m.id === f.id);
                      if (mdOption) {
                        fetchNewMetadataValue(mdOption, f);
                        setEditFilter(i);
                      }
                    }}
                    filter={f}
                  />
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
        <Grid
          container
          spacing={filters.length > 0 ? 1 : 0}
          wrap="nowrap"
          sx={{
            alignItems: "center",
          }}
          size={12}
        >
          {!smDown && !guidedExperienceMode && filters.length > 0 && (
            <Grid
              sx={{
                width: letterStyle.width - 8,
              }}
            />
          )}
          <Grid
            size={{
              xs: "grow",
              md: "auto",
            }}
          >
            <DimensionSelectorWithOperator
              handleSelectOption={(md) => md && fetchNewMetadataValue(md)}
              selectedDimension={editFilter !== null ? null : selectedMetadata}
              dimensions={options}
              disabled={false}
              placeholder={allocationTxt.ADD_DIMENSION}
              onEditDimension={() => {}}
              excludeSelectMetadataIds={excludeSelectMetadataIds}
              label={allocationTxt.ADD_DIMENSION}
            />
          </Grid>
        </Grid>
      </Grid>

      {selectedMetadata && (
        <FilterDialog open={true} onClose={onCloseDialog} selected={selectedMetadata} onSave={handleChangeFilter} />
      )}
    </>
  );

  return { AttributionCreateSelector, filters };
};

export default useAllocationCreateSelector;
