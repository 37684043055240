import { APP_KEY, CloudFlowNodeType, NODE_STATUS } from "@doitintl/cmp-models";
import { v4 as uuidv4 } from "uuid";

import { type BaseNodeData } from "../../types";

export const startStepNodeId = uuidv4();

export const initialNodes: BaseNodeData[] = [
  {
    id: startStepNodeId,
    type: CloudFlowNodeType.START_STEP,
    display: {
      position: { x: 0, y: 0 },
    },
    name: "What should start your flow?",
    appKey: APP_KEY.INTERNAL,
    status: NODE_STATUS.PENDING,
    transitions: null,
  },
];
