import React from "react";

import { type TextFieldProps } from "@mui/material";
import { type FormikErrors, type FormikTouched } from "formik";

import { CustomTextField } from "./CustomTextField";
import { type CommitmentPeriod } from "./types";

type Props = TextFieldProps & {
  onChange?: (value: string) => void;
  errors: FormikErrors<CommitmentPeriod> | string | undefined;
  touched: FormikTouched<CommitmentPeriod> | string | undefined;
  name: string;
  value?: string;
};

export const NumberInput = ({ errors, touched, name, value, onChange }: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    // Replace all periods with nothing (to handle thousands separator)
    // and commas with a dot (to normalize decimal handling)
    inputValue = inputValue.replace(/\./g, "").replace(",", ".");

    // Allow only numeric input with one decimal point
    if (/^\d*\.?\d*$/.test(inputValue)) {
      onChange?.(inputValue); // Keep raw input in a string to preserve decimals
    }
  };

  const getDisplayValue = () => {
    if (!value) return ""; // Handle empty input
    // Format the numeric part for display (remove trailing decimal if present)
    const [integerPart, decimalPart] = value.split(".");
    const formattedInteger = new Intl.NumberFormat("de-DE").format(Number(integerPart));
    return decimalPart !== undefined ? `${formattedInteger},${decimalPart}` : formattedInteger;
  };

  return (
    <CustomTextField
      value={getDisplayValue()} // Show the formatted value
      onChange={handleChange}
      errors={errors}
      touched={touched}
      name={name}
    />
  );
};
