import { type ReactNode } from "react";

import { Box, Link, MenuItem, TextField } from "@mui/material";
import { type FormikErrors } from "formik";

import { type CreateBillingProfileData } from "../../BillingProfileForm.models";
import { CustomInput } from "../common/CustomInput";
import { PanelWrapper } from "../common/PanelWrapper";

type Props = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: CreateBillingProfileData;
  setFieldValue: (field: string, value: any) => void;
  errors: FormikErrors<CreateBillingProfileData>;
  children?: ReactNode;
};

export const GeneralPanel = ({ values, setFieldValue, errors, handleChange, children }: Props) => (
  <PanelWrapper title="Company details" subtitle="Company name and registration details" paddingTop={9}>
    {children}

    <CustomInput
      data-cy="companyName"
      name="companyName"
      label="Company name"
      helperText="Legal company name that will appear on invoices"
      value={values.companyName}
      onChange={handleChange}
      fullWidth
      error={Boolean(errors.companyName)}
      maxLength={48}
      required
    />

    <CustomInput
      data-cy="taxId"
      name="taxId"
      label={values.country !== "Israel" ? "Company Tax ID" : "Company number"}
      helperText={
        values.country !== "Israel" ? (
          `Company Tax ID that will appear on invoices`
        ) : (
          <Box>
            Company number registered under{" "}
            <Link
              href="https://ica.justice.gov.il/GenericCorporarionInfo/SearchCorporation?unit=8"
              target="_blank"
              color="inherit"
            >
              ICA
            </Link>
          </Box>
        )
      }
      value={values.taxId}
      onChange={(e) => {
        const newTaxId = e.target.value;
        if (newTaxId.startsWith("51") && values.vatRegistrationType === null) {
          setFieldValue("vatRegistrationType", "2");
        }
        setFieldValue("taxId", newTaxId);
      }}
      fullWidth
      error={Boolean(errors.taxId)}
      maxLength={48}
      required={values.country !== "United States"}
    />
    {values.country === "Israel" && (
      <TextField
        data-cy="vatRegistrationType"
        select
        label="VAT registration type"
        name="vatRegistrationType"
        value={values.vatRegistrationType}
        onChange={(e) => {
          setFieldValue("vatRegistrationType", e.target.value);
        }}
        variant="outlined"
        size="medium"
        key={values.vatRegistrationType}
        fullWidth
        required
      >
        <MenuItem key="1" value="1">
          Self-employed (עצמאי)
        </MenuItem>
        <MenuItem key="2" value="2">
          Company (חברה)
        </MenuItem>
        <MenuItem key="3" value="3">
          Licensed dealer (עוסק מורשה)
        </MenuItem>
      </TextField>
    )}
  </PanelWrapper>
);
