import { type AttributionWRef } from "../../../types";
import { AllocationDraggableItem } from "./AllocationDraggableItem";

type AllocationDraggableGroupContentProps = {
  replaceAttributionInGroup: (newAttributionId: string, oldAttributionId: string) => void;
  attributions: AttributionWRef[];
  disabledAttributions: string[];
  items: string[];
  removeAttributionFromGroup: (id: string) => void;
};

export const AllocationDraggableGroupContent = ({
  replaceAttributionInGroup,
  attributions,
  disabledAttributions,
  items,
  removeAttributionFromGroup,
}: AllocationDraggableGroupContentProps) =>
  items.map((id, index) => (
    <AllocationDraggableItem
      replaceAttributionInGroup={replaceAttributionInGroup}
      attributionNumber={index}
      attributions={attributions}
      disabledAttributions={disabledAttributions}
      initialAttributionID={id}
      key={id}
      removeAttributionFromGroup={removeAttributionFromGroup}
    />
  ));
