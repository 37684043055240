import { type MouseEvent, useState } from "react";

import { Link } from "react-router-dom";
import { type ReceiptModel } from "@doitintl/cmp-models";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import Hide from "../../Components/HideChildren/Hide";
import { type Item } from "../../Components/hooks/usePaginateQuery";
import { formatCurrency } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";

const InvoiceMenu = ({ row }: { row: Item<ReceiptModel> }) => {
  const [anchorEl, setAnchorEl] = useState<any>();

  if (!row.data.INVOICES || row.data.INVOICES.length <= 1) {
    return null;
  }

  return (
    <div>
      <IconButton
        style={{ position: "absolute", marginTop: -5 }}
        key="close"
        size="small"
        color="inherit"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <ArrowDropDownIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {row.data.INVOICES.map((invoice, index) => (
          <MenuItem
            key={`${index}`}
            component={Link}
            to={`/customers/${row.data.customer.id}/invoices/${row.data.entity.id}/${invoice.id.substring(
              invoice.id.indexOf("-", 10) + 1
            )}`}
          >
            {invoice.id.substring(invoice.id.indexOf("-", 10) + 1)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const headRows = [
  { id: "name", numeric: false, disablePadding: true, isShowMobile: true, label: "Customer" },
  { id: "PAYDATE", numeric: false, disablePadding: false, isShowMobile: false, label: "Date" },
  {
    id: "entity",
    numeric: false,
    disablePadding: false,
    isShowMobile: false,
    label: "Billing Profile",
  },
  {
    id: "invoiceId",
    numeric: false,
    disablePadding: false,
    isShowMobile: false,
    label: "Invoice ID",
  },
  { id: "USDTOTAL", numeric: false, disablePadding: false, isShowMobile: true, label: "Amount" },
] as const;

type EnhancedTableHeadProps = {
  order: "desc" | "asc";
  orderBy: keyof ReceiptModel;
  onRequestSort: (event: MouseEvent, property: keyof ReceiptModel) => void;
};

const EnhancedTableHead = ({ order, orderBy, onRequestSort }: EnhancedTableHeadProps) => {
  const { isMobile } = useFullScreen();

  const createSortHandler = (property: string & keyof ReceiptModel) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <Hide key={row.id} mdDown={!row.isShowMobile}>
            <TableCell
              align={row.numeric && !isMobile ? "right" : "left"}
              padding="normal"
              sortDirection={orderBy === row.id ? order : false}
            >
              {row.id === "PAYDATE" || row.id === "USDTOTAL" ? (
                <TableSortLabel active={orderBy === row.id} direction={order} onClick={createSortHandler(row.id)}>
                  {row.label}
                </TableSortLabel>
              ) : (
                row.label
              )}
            </TableCell>
          </Hide>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = ({ title }: { title: string }) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h1" id="tableTitle">
          {title}
        </Typography>
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    // minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

type Prop = {
  title: string;
  onSortBy: (property: keyof ReceiptModel, order: "asc" | "desc") => void;
  changeRowsPerPage: (rowsPerPage: number) => void;
  changePage: (page: number) => void;
  rows: Item<ReceiptModel>[] | null | undefined;
  rowsPerPage: number;
};

const TableServerPaging = ({ title, rows, changePage, changeRowsPerPage, onSortBy, rowsPerPage }: Prop) => {
  const classes = useStyles();
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [orderBy, setOrderBy] = useState<string & keyof ReceiptModel>("PAYDATE");
  const [page, setPage] = useState(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(rowsPerPage);

  const handleRequestSort = (_event, property: string & keyof ReceiptModel) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
    onSortBy(property, isDesc ? "asc" : "desc");
    setPage(0);
  };

  function handleChangePage(_event, newPage: number) {
    changePage(newPage);
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    changeRowsPerPage(+event.target.value);
    setCurrentRowsPerPage(+event.target.value);
    setPage(0);
  }

  const emptyRows = Math.max(0, currentRowsPerPage - (rows?.length ?? 0));

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar title={title} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {rows?.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover tabIndex={-1} key={row.ref.id}>
                    <TableCell component="th" id={labelId} scope="row" padding="normal">
                      <Typography
                        component={Link}
                        to={`/customers/${row.data.customer.id}`}
                        variant="body2"
                        color="inherit"
                      >
                        {row.data.metadata.customer.name}
                      </Typography>
                    </TableCell>
                    <Hide mdDown>
                      <TableCell align="left">
                        {DateTime.fromJSDate(row.data.PAYDATE.toDate()).toLocaleString(DateTime.DATE_MED)}
                      </TableCell>
                      <TableCell align="left">{row.data.metadata.entity.name}</TableCell>
                      <TableCell align="left">
                        {row.data.INVOICES && row.data.INVOICES.length > 0 && (
                          <div style={{ display: "flex" }}>
                            <Typography
                              component={Link}
                              to={`/customers/${row.data.customer.id}/invoices/${
                                row.data.entity.id
                              }/${row.data.INVOICES[0].id.substring(row.data.INVOICES[0].id.indexOf("-", 10) + 1)}`}
                              variant="body2"
                              color="inherit"
                            >
                              {row.data.INVOICES[0].id.substring(row.data.INVOICES[0].id.indexOf("-", 10) + 1)}
                            </Typography>
                            <InvoiceMenu row={row} />
                          </div>
                        )}
                      </TableCell>
                    </Hide>
                    <TableCell align="left">{formatCurrency(row.data.USDTOTAL, "USD", 0)}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          labelDisplayedRows={({ page }) => page}
          count={-1}
          rowsPerPage={currentRowsPerPage}
          page={page}
          slotProps={{
            actions: {
              previousButton: {
                "aria-label": "Previous Page",
              },
              nextButton: {
                "aria-label": "Next Page",
              },
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Per page:"
        />
      </Paper>
    </div>
  );
};

export default TableServerPaging;
