import { useCallback } from "react";

import { useHistory, useLocation } from "react-router-dom";

export const useGotoParentUrl = (backUrl: string) => {
  const location = useLocation<{ prevPage?: string }>();
  const history = useHistory();

  return useCallback(() => {
    if (
      location.state?.prevPage === backUrl ||
      (location.pathname.includes("reports") && location.state?.prevPage?.includes("dashboards"))
    ) {
      // If the previous page was the list page, use the browser back functionality except for reports to allow return to dashboard)
      history.goBack();
    } else {
      // Otherwise, navigate to the provided url
      history.push(backUrl);
    }
  }, [location, backUrl, history]);
};
