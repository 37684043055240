import React, { type JSX } from "react";

import { CardContent, Stack, Typography } from "@mui/material";

import { SafeHtml } from "../../../SafeHtml";

type Props = {
  widgetHeight: number;
  title?: string;
  message: string | JSX.Element;
  extra?: JSX.Element;
};

export function WidgetMessageCardContent({ widgetHeight, title, message, extra }: Props) {
  return (
    <CardContent sx={{ height: widgetHeight }}>
      <Stack alignItems="center" justifyContent="center" height="100%">
        <Typography fontWeight={500} textAlign="center">
          {title}
        </Typography>
        {typeof message === "string" ? (
          <Typography component="span" sx={{ maxWidth: "559px" }} color="textSecondary" textAlign="center">
            <SafeHtml html={message} />
          </Typography>
        ) : (
          <>{message}</>
        )}
        {extra}
      </Stack>
    </CardContent>
  );
}
