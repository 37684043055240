import { useCallback, useEffect, useRef, useState } from "react";

export function useFullHeight() {
  const ref = useRef<HTMLDivElement | null>(null);
  const [offsetTop, setOffsetTop] = useState<number | null>(null);

  const updateOffsetTop = useCallback(() => {
    if (ref.current) {
      const currentOffsetTop = ref.current.getBoundingClientRect().top;
      if (offsetTop !== currentOffsetTop) {
        setOffsetTop(currentOffsetTop);
      }
    }
  }, [offsetTop]);

  if (!offsetTop) {
    updateOffsetTop();
  }

  useEffect(() => {
    window.addEventListener("resize", updateOffsetTop);
    return () => {
      window.removeEventListener("resize", updateOffsetTop);
    };
  }, [updateOffsetTop]);

  return { ref, height: offsetTop !== null ? `calc(100vh - ${offsetTop}px)` : undefined };
}
