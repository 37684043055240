import { type JSX } from "react";

import { Box } from "@mui/material";

import { type RowsBoundaries } from "./TableRenderer";

type Props = {
  rowsBoundaries: RowsBoundaries;
  numberOfRows: number;
  rowHeight: number;
  children: JSX.Element[];
};

export function RowsVirtualization({ rowsBoundaries, rowHeight, numberOfRows, children }: Props) {
  return (
    <>
      {rowsBoundaries.upper > 0 && <Box height={`${rowsBoundaries.upper * rowHeight}px`} />}
      {children}
      {rowsBoundaries.bottom < numberOfRows && (
        <Box height={`${(numberOfRows - rowsBoundaries.bottom - 1) * rowHeight}px`} />
      )}
    </>
  );
}
