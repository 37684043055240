import { type JSX, useCallback, useMemo } from "react";

import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Button, Card, CardContent, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../Context/CustomerContext";
import { useDashboardsContext } from "../../Context/useDashboardsContext";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { jsUcfirst } from "../../utils/common";
import { SafeHtml } from "../SafeHtml";

type Props = {
  widgetHeight: number;
  name: string;
  message?: string;
  url?: string;
  title?: string;
  subheader?: string;
  action?: JSX.Element;
};

export default function InfoMessageWidgetCard({ widgetHeight, name, title, subheader, message, url, action }: Props) {
  const { customer } = useCustomerContext();
  const { widgetsConfig } = useDashboardsContext();

  const widgetConfig = useMemo(() => widgetsConfig[name] ?? {}, [name, widgetsConfig]);

  const fullUrl = useMemo((): string => {
    const buttonLink = url ?? widgetConfig?.emptyState?.buttonLink ?? "";

    if (buttonLink.includes("{{")) {
      return buttonLink.replace("{{customerId}}", customer.id);
    }
    return buttonLink ?? "";
  }, [url, widgetConfig?.emptyState?.buttonLink, customer.id]);

  const learnMoreButton = useCallback(
    (fallbackText: string, customText?: string) => (
      <Box sx={{ mt: 1 }}>
        <Button
          onClick={() => {
            window.open(fullUrl, "_blank");
          }}
          endIcon={!fullUrl.startsWith(window.location.origin) ? <OpenNewIcon /> : undefined}
        >
          {customText ?? fallbackText}
        </Button>
      </Box>
    ),
    [fullUrl]
  );

  const standardEmptyWidgetOutput = (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <Typography component="div" fontWeight={500} pb={0.5}>
        {widgetConfig?.emptyState?.message ? widgetConfig.emptyState.message : "No data available"}
      </Typography>
      {!!widgetConfig?.emptyState?.description && (
        <Typography component="span" variant="body2" color="textSecondary" textAlign="center">
          {widgetConfig.emptyState.description}
        </Typography>
      )}
      {Boolean(widgetConfig?.emptyState?.buttonLink) &&
        url &&
        learnMoreButton("Learn more", widgetConfig?.emptyState?.buttonText)}
    </Stack>
  );

  const emptyWidgetWithCustomMessage = (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <Typography component="span" sx={{ maxWidth: "559px" }} color="textSecondary" textAlign="center">
        <SafeHtml html={message} />
      </Typography>
      {url && learnMoreButton("Learn more")}
    </Stack>
  );

  const subheaderContent = useMemo(
    () => jsUcfirst(subheader ?? widgetConfig?.cardSubheader),
    [subheader, widgetConfig?.cardSubheader]
  );

  return (
    <Card>
      <WidgetCardHeader title={title ?? widgetConfig?.cardTitle} subheader={subheaderContent} action={action} />
      <CardContent sx={{ height: widgetHeight }}>
        {message ? emptyWidgetWithCustomMessage : standardEmptyWidgetOutput}
      </CardContent>
    </Card>
  );
}
