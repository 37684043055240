import { Fragment, useMemo } from "react";

import { type AttributionFilter, type Positions } from "@doitintl/cmp-models";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { globalText } from "../../../../assets/texts";
import { attributionTxt } from "../../../../assets/texts/CloudAnalytics";
import FilterDialog from "../../../../Components/Dialogs/CloudAnalytics/FilterDialog";
import { DimensionSelector } from "../../../../Components/Selects/CloudAnalytics/DimensionSelector";
import { type MetadataOption } from "../../../../types";
import { useFullScreen } from "../../../../utils/dialog";
import { getLetterForIndex } from "../../../../utils/string";
import { type KeyTypeValues } from "../../api";
import { getTypeString } from "../../utilities";
import AttributionBuilderChips from "../attributionBuilder/AttributionBuilderChips";
import useAttributionCreate from "./useAttributionCreate";

const LETTER_SIZE_BIG = 30;
const LETTER_SIZE_SMALL = 22;
const LETTER_PADDING = 8;
const PROPERTY_FIELD_WIDTH = 250;

const styles = {
  letterStyleAttribution: {
    width: LETTER_SIZE_SMALL,
    fontWeight: "bold",
  },
  letterStyleGuidedExperience: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: LETTER_SIZE_BIG,
    height: LETTER_SIZE_BIG,
    fontWeight: "bold",
    background: "rgba(0, 0, 0, 0.12)",
    borderRadius: "50%",
  },
};

type Props = {
  initialFilters?: AttributionFilter[];
  dimensions?: MetadataOption[];
  handleDimensionLabelsChange: (
    values: KeyTypeValues[],
    filter?: AttributionFilter,
    position?: Positions
  ) => Promise<MetadataOption | null>;
  excludeSelectMetadataIds?: Set<string>;
  guidedExperienceMode?: boolean;
};

const useAttributionCreateSelector = ({
  initialFilters,
  dimensions = [],
  handleDimensionLabelsChange,
  excludeSelectMetadataIds,
  guidedExperienceMode = false,
}: Props) => {
  const {
    filters,
    fetchNewMetadataValue,
    handleSelectExistingOption,
    handleChangeFilter,
    setEditFilter,
    editFilter,
    selectedMetadata,
    onCloseDialog,
  } = useAttributionCreate({
    initialFilters,
    handleDimensionLabelsChange,
  });
  const { isMobile: smDown } = useFullScreen();
  const letterStyle = guidedExperienceMode ? styles.letterStyleGuidedExperience : styles.letterStyleAttribution;

  const variablesArray = useMemo(() => filters.map((_, idx) => getLetterForIndex(idx)), [filters]);
  const options = useMemo(
    () => dimensions.filter((md) => md._visible || filters.findIndex((f) => f.id === md.id) > -1) ?? [],
    [dimensions, filters]
  );

  const AttributionCreateSelector = (
    <>
      <Grid container spacing={1}>
        {!guidedExperienceMode && <Typography variant="h4">{attributionTxt.SELECT_PROPERTIES}</Typography>}
        {filters.map((f, i) => {
          const md = dimensions.find((md) => md.id === f.id);
          if (!md) {
            return null;
          }
          return (
            <Fragment key={`filter-row-${variablesArray[i]}`}>
              <Grid
                container
                spacing={1}
                sx={{
                  alignItems: "center",
                }}
                size={12}
              >
                <Grid>
                  <Box sx={letterStyle}>{variablesArray[i]}</Box>
                </Grid>
                <Grid
                  size={{
                    xs: "grow",
                    md: "auto",
                  }}
                >
                  <DimensionSelector
                    handleSelectOption={handleSelectExistingOption(i)}
                    selectedDimension={md}
                    dimensions={options}
                    excludeSelectMetadataIds={excludeSelectMetadataIds}
                    basicAutoCompleteProps={{
                      forcePopupIcon: false,
                      getOptionDisabled: (option) => option?._disabled,
                    }}
                    onEdit={() => {
                      const mdOption = dimensions.find((m) => m.id === f.id);
                      if (mdOption) {
                        fetchNewMetadataValue(mdOption, f);
                        setEditFilter(i);
                      }
                    }}
                    textFieldProps={{
                      variant: "outlined",
                      margin: "dense",
                      size: "small",
                      sx: { width: { md: PROPERTY_FIELD_WIDTH } },
                      label: md ? getTypeString(md) : "",
                      error: !(f.values?.length || f.allowNull || f.regexp?.length),
                    }}
                  />
                </Grid>
                <Grid
                  sx={{ maxWidth: { md: "100%" } }}
                  size={{
                    xs: 12,
                    md: "auto",
                  }}
                >
                  <AttributionBuilderChips
                    filter={f}
                    nullFallback={md?.data?.nullFallback ?? null}
                    guidedExperienceMode={guidedExperienceMode}
                  />
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
        <Grid
          container
          spacing={filters.length > 0 ? 1 : 0}
          wrap="nowrap"
          sx={{
            alignItems: "center",
          }}
          size={12}
        >
          {!smDown && !guidedExperienceMode && filters.length > 0 && (
            <Grid
              sx={{
                width: letterStyle.width + LETTER_PADDING,
              }}
            />
          )}
          <Grid
            size={{
              xs: "grow",
              md: "auto",
            }}
          >
            <DimensionSelector
              handleSelectOption={(md) => md && fetchNewMetadataValue(md)}
              selectedDimension={editFilter !== null ? null : selectedMetadata}
              dimensions={options}
              excludeSelectMetadataIds={excludeSelectMetadataIds}
              textFieldProps={{
                sx: { width: { md: PROPERTY_FIELD_WIDTH } },
                size: "small",
                label: guidedExperienceMode ? "" : globalText.SELECT,
                placeholder: guidedExperienceMode ? attributionTxt.ADD_RULE : "",
              }}
              basicAutoCompleteProps={{
                getOptionDisabled: (option) => option?._disabled,
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {selectedMetadata && (
        <FilterDialog open={true} onClose={onCloseDialog} selected={selectedMetadata} onSave={handleChangeFilter} />
      )}
    </>
  );

  return { AttributionCreateSelector, filters };
};

export default useAttributionCreateSelector;
