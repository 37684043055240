export const isFullstoryEnabled = process.env.NODE_ENV === "test" || location.hostname !== "localhost";

export const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID as string;

export const autoHideDuration = 5000;

export const isProduction =
  process.env.NODE_ENV === "production" && process.env.REACT_APP_FIREBASE_PROJECT_ID === "me-doit-intl-com";

export const isLocalhost = location.hostname === "localhost";

export const isNordia = process.env.REACT_APP_FIREBASE_PROJECT_ID === "doit-nordia";

export const queryKeys = {
  bigQueryGetQuery: "big-query-get-query",
  payerConfigs: "payer-configs",
  customerPayerConfigs: "customer-payer-configs",
  insights: "insights",
  jiraIssue: "jiraIssue",
};

export const navigationId = "console-navigation";
export const billingProfileHeadingId = "billing-profile-heading";

export const avaAgentIdDev = 12993483622173;
export const avaAgentIdProd = 10224428291356;

export const essentialsEscalatePrice = 750;
