import { useEffect, useMemo, useState } from "react";

import {
  AccessLevels,
  AssetTypeGoogleCloud,
  type CloudConnectCategory,
  type CloudConnectGoogleCloud,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import { Avatar, Container, Stack, Typography } from "@mui/material";

import GoogleCloudIcon from "../../../assets/google-cloud.png";
import CloudConnectCard from "../../../Components/Dashboard/CloudConnectCard";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useToggle } from "../../../utils/useToggle";
import ActiveWidgetsList from "./ActiveWidgetsList";
import ConnectDialog from "./ConnectDialog";
import { ServiceAccountList } from "./ServiceAccountList";
import { ServiceAccountSelector } from "./ServiceAccountSelector";
import { useCloudConnectData } from "./useCloudConnectData";
import { useCloudConnectHealthCheck } from "./useCloudConnectHealthCheck";
import { usePermissionCategories } from "./usePermissionCategories";
import { WorkloadIdentityFederationStatus } from "./WorkloadIdentityFederationStatus";

export const ServiceAccountsPage = () => {
  const { customer } = useCustomerContext();
  const permissionsByCategory = usePermissionCategories();

  const [gcpCloudConnectData, hasConnectedServiceAccount] = useCloudConnectData(customer.id);
  const gcpCloudConnectionList: WithFirebaseModel<CloudConnectGoogleCloud>[] = useMemo(
    () => gcpCloudConnectData.map(({ data }) => data),
    [gcpCloudConnectData]
  );

  const [selectedGCPCloudConnectionIndex, setSelectedGCPCloudConnectionIndex] = useState(-1);
  const [inEditMode, setInEditMode] = useState(false);
  const [isConnectDialogOpened, openConnectDialog, closeConnectDialog] = useToggle(false);
  const [selectedCategories, setSelectedCategories] = useState<CloudConnectCategory[]>([]);
  const [accessLevel, setAccessLevel] = useState<"organization" | "project">(AccessLevels.ORGANIZATION);

  const runHealthCheck = useCloudConnectHealthCheck(customer.id);

  useEffect(() => {
    hasConnectedServiceAccount && setSelectedGCPCloudConnectionIndex(0);
  }, [hasConnectedServiceAccount]);

  useEffect(() => {
    setInEditMode(selectedGCPCloudConnectionIndex !== -1);
  }, [selectedGCPCloudConnectionIndex]);

  return (
    <Container maxWidth="lg">
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          mt: 5,
          mb: 6,
        }}
      >
        <Avatar
          src={GoogleCloudIcon}
          variant="square"
          sx={{ width: 24, height: 24 }}
          slotProps={{
            img: { sx: { objectFit: "contain" } },
          }}
        />
        <Typography
          variant="h1"
          sx={{
            ml: 1,
          }}
        >
          Google Cloud settings
        </Typography>
      </Stack>
      <Typography
        variant="h4"
        sx={{
          mb: 1,
        }}
      >
        Connected organizations
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          mb: 1,
        }}
      >
        A list of organizations connected to the DoiT Console
      </Typography>
      <ServiceAccountList cloudConnectData={gcpCloudConnectData} />
      <Typography
        variant="h4"
        sx={{
          mb: 1,
          mt: 6,
        }}
      >
        Features
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          mb: 2,
        }}
      >
        Manage or enable features on your organization connected via this service account
      </Typography>
      <ServiceAccountSelector
        selectedGCPCloudConnectionIndex={selectedGCPCloudConnectionIndex}
        onSelectionChange={setSelectedGCPCloudConnectionIndex}
        gcpCloudConnectData={gcpCloudConnectData}
      />
      <ActiveWidgetsList
        onOpenDialog={openConnectDialog}
        permissionsByCategory={permissionsByCategory}
        activeCategories={gcpCloudConnectionList}
        onChangeCategory={setSelectedCategories}
        selectedOrg={selectedGCPCloudConnectionIndex}
        isEditMode={inEditMode}
        accessLevel={accessLevel}
        setAccessLevel={setAccessLevel}
        additionalWidgets={
          gcpCloudConnectData[selectedGCPCloudConnectionIndex] !== undefined && (
            <>
              <WorkloadIdentityFederationStatus serviceAccount={gcpCloudConnectData[selectedGCPCloudConnectionIndex]} />
            </>
          )
        }
      />
      <ConnectDialog
        selectedCategories={selectedCategories}
        onCloseDialog={closeConnectDialog}
        open={isConnectDialogOpened}
        isEditRole={inEditMode}
        accessLevel={accessLevel}
      >
        <Stack
          sx={{
            alignItems: "flex-start",
          }}
        >
          <CloudConnectCard
            data={gcpCloudConnectData}
            settings={true}
            type={AssetTypeGoogleCloud}
            closeDialog={runHealthCheck}
            isDialogOpen={true}
            selectedCategories={selectedCategories}
            onDeleteServiceAccount={() => {}}
            selectedOrg={selectedGCPCloudConnectionIndex}
            accessLevel={accessLevel}
          />
        </Stack>
      </ConnectDialog>
    </Container>
  );
};
