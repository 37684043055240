import { useMemo } from "react";

import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Button, Stack, Typography } from "@mui/material";

import illustration from "../../../assets/GKE-empty-illustration.svg";
import { cloudAnalyticsText, lensEnticementTexts, lensUrls } from "../../../assets/texts";
import { LensEnticement } from "../../LensEnticement/LensEnticement";
import { type EnticementState } from "../../LensEnticement/types";
import useFetchAmTamEmail from "../../NoEntitlement/hooks";
import { ContactSalesButton } from "../../NoEntitlement/NoEntitlement";

export const GKELensEnticement = ({ state }: { state: EnticementState }) => {
  const { accountManagers, loading } = useFetchAmTamEmail();

  const actionButton = useMemo(() => {
    if (state === "activate") {
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="body1">Get started by enabling GKE Cost Allocation.</Typography>
          <Button
            href={cloudAnalyticsText.GKE_COST_ALLOCATION_ENABLE_ALERT_HREF}
            sx={{ width: "fit-content" }}
            target="_blank"
            variant="contained"
            endIcon={<OpenNewIcon />}
          >
            Learn more
          </Button>
        </Stack>
      );
    }
    if (state === "upgrade") {
      return <ContactSalesButton sendTo={accountManagers} loading={loading} />;
    }
  }, [accountManagers, loading, state]);

  return (
    <LensEnticement
      image={illustration}
      title={lensEnticementTexts.GKE_LENS_TITLE}
      listItems={lensEnticementTexts.GKE_LENS_LIST}
      actionButton={actionButton}
      learnMoreUrl={state === "upgrade" ? lensUrls.GKE_LENS_LEARN_MORE : undefined}
    />
  );
};
