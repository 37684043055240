import { useMemo, useState } from "react";

import { Autocomplete, Box, TextField } from "@mui/material";

import { sortProjectProps, type SupportAssetSummary, type SupportPlatformDescriptor } from "../utils";

type Props = {
  selectedPlatform: SupportPlatformDescriptor;
  assetsList: SupportAssetSummary[];
  onChangeValue: (value?: SupportAssetSummary) => void;
  platforms: SupportPlatformDescriptor[];
  assetAccountId: string;
};

const SelectMpaFromResource = ({ onChangeValue, selectedPlatform, platforms, assetsList, assetAccountId }: Props) => {
  const [selectedAsset, setSelectedAsset] = useState<SupportAssetSummary>();

  const assetsListWMpaFlag = useMemo(() => assetsList.map((asset) => ({ ...asset, preferMpaId: true })), [assetsList]);

  const isPlatformSelected = selectedPlatform?.details.asset;

  let categoryList: SupportAssetSummary[] = sortProjectProps(platforms, assetsListWMpaFlag);

  if (isPlatformSelected) {
    categoryList = categoryList.filter(
      (cat) => cat.asset.replace("-standalone", "") === selectedPlatform.details.asset
    );
  } else {
    categoryList = [];
  }

  const categoryListOptions = useMemo(
    () =>
      categoryList?.filter((category) => {
        const regex = /doitintl-fs/g;
        return category?.identifier?.match(regex) ? false : category;
      }),
    [categoryList]
  );

  return (
    <Box>
      {!selectedPlatform?.resourceDisabled && categoryList.length > 0 && (
        <Autocomplete
          id="combo-box-demo"
          options={categoryListOptions}
          getOptionLabel={(option) => option.label || option.identifier || ""}
          fullWidth
          size="medium"
          sx={{ mt: 2, mb: 2 }}
          onChange={(event, newValue) => {
            const saveValue = newValue ?? undefined;

            if (selectedPlatform?.resourceDisabled && assetAccountId !== "") {
              onChangeValue(saveValue);
              setSelectedAsset(undefined);
              return;
            }
            onChangeValue(saveValue);
            setSelectedAsset(saveValue);
          }}
          value={selectedAsset ?? null}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              size="medium"
              helperText={
                selectedAsset?.mpa ? `Selected asset's MPA: ${selectedAsset?.mpa}` : "No MPA associated with this asset"
              }
              label={selectedPlatform?.label}
              required
              variant="outlined"
              error={selectedAsset && !selectedAsset?.mpa}
            />
          )}
        />
      )}
    </Box>
  );
};

export default SelectMpaFromResource;
