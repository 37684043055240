import { Box, Typography } from "@mui/material";

import maintenanceDark from "../../../assets/maintenance-dark.svg";
import maintenanceLight from "../../../assets/maintenance-light.svg";
import { useDarkThemeCheck } from "../../hooks/useDarkThemeCheck";

export const PriorityMaintenance = () => {
  const isDarkMode = useDarkThemeCheck();
  const imgSrc = isDarkMode ? maintenanceDark : maintenanceLight;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "60vh",
      }}
    >
      <img src={imgSrc} width="100" alt="maintenance" aria-hidden />
      <Typography
        variant="h4"
        sx={{
          m: 2,
        }}
      >
        This page is temporarily unavailable
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          textAlign: "center",
        }}
      >
        This page is temporarily unavailable due to a scheduled system update. Please check back later.
        <br />
        We apologize for any inconvenience.
      </Typography>
    </Box>
  );
};
